// import { Alert } from "bootstrap";
import React, { useState } from "react";
import { useTable, usePagination } from "react-table";

const DataTable = ({ columns, data, filterData }) => {

  // const [capsLock, setCapsLock] = useState(false);

  // const handleKeyPress = (event) => {
  //   // Check if the key combination is Shift + T
  //   if (event.shiftKey && event.key === "D") {
  //     // Call your function here
  //     showdatefilter();
  //   }
  //   // Check if the key combination is Shift + S
  //   if (event.shiftKey && event.key === "S") {
  //     // Call another function here
  //     showdatefilter1();
  //   }
  //   if (event.shiftKey && event.key === "N") {
  //     if (inputname.current) {
  //       inputname.current.focus();
  //     }
  //   }
  //   if (event.shiftKey && event.key === "C") {
  //     if (inputcity.current) {
  //       inputcity.current.focus();
  //     }
  //   }
  //   if (event.shiftKey && event.key === "A") {
  //     if (inputage.current) {
  //       inputage.current.focus();
  //     }
  //   }

  //   // Add more key combinations as needed
  // };
  // const handleCapsLockChange = (event) => {
  //   setCapsLock(event.getModifierState('CapsLock'));
  // };

  // useEffect(() => {
  //   // Attach the event listener for Caps Lock change
  //   document.addEventListener('keydown', handleCapsLockChange);

  //   // Attach the event listener when the component mounts
  //   document.addEventListener('keydown', handleKeyPress);

  //   // Detach the event listener when the component unmounts
  //   return () => {
  //     document.removeEventListener('keydown', handleCapsLockChange);
  //     document.removeEventListener('keydown', handleKeyPress);
  //   };
  // }, [capsLock]);

  const [showbox, setShowbox] = useState(false);

  const showdatefilter = () => {
    setShowbox((prevShowbox) => !prevShowbox);
  };
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of 'rows', we'll use 'page'
    state: { pageIndex, pageSize },
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 15 }, // Set the initial page index and page size
    },
    usePagination // Add the usePagination plugin
  );

  const [selectedDateFilter, setSelectedDateFilter] = useState("Today");

  const handleDateFilterClick = (option) => {
    setSelectedDateFilter(option);
  };

  return (
    <div className="panel panel-default">
      <div className="panel-body">
        {/* ... Search panel code ... */}
        <div className="col-sm-12" id="pdfContent">
          <div>
            {/* <button onClick={handleExportToExcel}>Btn</button> */}
            <table className="tableList-main" {...getTableProps()}>
              <thead className="tablelist-input-box" style={{ height: "35px" }}>
                <tr>
                  {filterData.map((data, index) => (
                    <th key={index} style={{ width: "max-content" }}>
                      {data.Header}
                    </th>
                  ))}
                  <th>
                    <div className="tablelist-datefilter-box">
                      <button
                        className="tablelist-main-search-button"
                        onClick={showdatefilter}
                      >
                        {selectedDateFilter}
                      </button>
                      {showbox && (
                        <div className="tablelist-datefilter">
                          <div className="tablelist-datefilter-1">
                            <div
                              className={`tablelist-datefilter-option ${selectedDateFilter === "Today"
                                ? "tablelist-datefilter-option-selected"
                                : ""
                                }`}
                              onClick={() => handleDateFilterClick("Today")}
                            >
                              Today
                            </div>
                            <div
                              className={`tablelist-datefilter-option ${selectedDateFilter === "Yesterday"
                                ? "tablelist-datefilter-option-selected"
                                : ""
                                }`}
                              onClick={() => handleDateFilterClick("Yesterday")}
                            >
                              Yesterday
                            </div>
                            <div
                              className={`tablelist-datefilter-option ${selectedDateFilter === "This Week"
                                ? "tablelist-datefilter-option-selected"
                                : ""
                                }`}
                              onClick={() => handleDateFilterClick("This Week")}
                            >
                              This Week
                            </div>
                            <div
                              className={`tablelist-datefilter-option ${selectedDateFilter === " Last 7 Days"
                                ? "tablelist-datefilter-option-selected"
                                : ""
                                }`}
                              onClick={() => handleDateFilterClick(" Last 7 Days")}
                            >
                              Last 7 Days
                            </div>
                            <div
                              className={`tablelist-datefilter-option ${selectedDateFilter === "This Month"
                                ? "tablelist-datefilter-option-selected"
                                : ""
                                }`}
                              onClick={() => handleDateFilterClick("This Month")}
                            >
                              This Month
                            </div>
                            <div
                              className={`tablelist-datefilter-option ${selectedDateFilter === "Previous Month"
                                ? "tablelist-datefilter-option-selected"
                                : ""
                                }`}
                              onClick={() => handleDateFilterClick("Previous Month")}
                            >
                              Previous Month
                            </div>
                          </div>
                          <div className="tablelist-datefilter-2">
                            <p style={{ margin: "0", textAlign: "start" }}>Custom</p>
                            <div className="tablelist-datefilter-date">
                              <input type="date" name="" id="" /> to
                              <input type="date" name="" id="" />
                            </div>
                          </div>
                          <button
                            className="tablelist-datefilter-button"
                            onClick={showdatefilter}
                          >
                            Apply
                          </button>
                        </div>
                      )}
                    </div>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <thead id="pdfContent" className="table-head-main">
                {headerGroups.map((headerGroup, index) => (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    id={`header-row-${index}`}
                  >
                    {headerGroup.headers.map((column, columnIndex) => (
                      <th
                        {...column.getHeaderProps()}
                        id={`header-${index}-${columnIndex}`}
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody id="" className="table-body-main" {...getTableBodyProps()}>
                {page.map((row, rowIndex) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} id={`row-${rowIndex}`}>
                      {row.cells.map((cell, cellIndex) => (
                        <React.Fragment key={cellIndex}>
                          <td
                            {...cell.getCellProps()}
                            id={`cell-${rowIndex}-${cellIndex}`}
                          >
                            {cell.render("Cell")}
                          </td>
                        </React.Fragment>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="table-list-button-box">
              <div className="shortcutkeys-header">
                {columns.map((data, index) => {
                  if (index !== columns.length - 3 && index !== columns.length - 2 && index !== columns.length - 1) {
                    return (
                      <p key={index} style={{ fontSize: "13px" , color:"#212e4f" }}>
                        Press ALT + {index + 1} to open {columns[index + 2]?.Header || 'unknown'} filter
                      </p>
                    );
                  }
                  return null;
                })}
                {/* <p>Press ALT+ 1 to open ({columns[2].Header}) filter</p>
                <p>Press ALT+ 2 to open ({columns[3].Header}) filter</p>
                <p>Press ALT+ 3 to open ({columns[4].Header}) filter</p>
                <p>Press ALT+ 4 to open ({columns[5].Header}) filter</p> */}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "25%",
                  margin:"5px 0px"
                }}
              >
                <button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                  className="table-list-button"
                >
                  <i className="fa-solid fa-chevron-left"></i>
                </button>
                <span className="table-list-page-txt">
                  Page -
                  <strong style={{ margin: "0px 3px" }}>
                    {pageIndex + 1} of {Math.ceil(data.length / pageSize)}
                  </strong>
                </span>
                <button
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                  className="table-list-button"
                >
                  <i className="fa-solid fa-chevron-right"></i>
                </button>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataTable;
