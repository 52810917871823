import DateTime from "../../Inventory/Components/DateTime";
import React, { useState, useEffect, useContext, useRef } from "react";
import PageTabs from "../../Inventory/Components/PageTabs";
import MainButton from "../../Inventory/Props/MainButton";
import MyContext from "../../Inventory/MyContext";
import AlertBox from "../../Inventory/Props/AlertBox";
import Loader from "../../Inventory/Props/Loader";
import FeedbackSettingApi from "../../API/APIServices/FeedbackSetting";
import { color } from "framer-motion";

function FeedbackSetting() {
    /***********title***************/
    useState(() => {
        document.title = "Feedback Setting";
    }, []);

    const { OpenAlertBox, alertBox } = useContext(MyContext);

    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState();
    const [data, setData] = useState([]);
    const [ServiceType, setServiceType] = useState(751)

    useEffect(() => {
        console.log(data)
        console.log(inputValue);
    }, [data, inputValue])

    //******************{modal box}******************//
    const [setModalOpen] = useState(false);
    const showModal = () => {
        setModalOpen(true);
    };

    const getFeedbackdetails = async (event) => {
        // debugger
        setServiceType(event?.target?.value)
        var filters = [
            {
                "filter": [
                    {
                        "fieldName": "serviceTypeId",
                        "operatorName": "equal",
                        "compareValue": event?.target?.value || 751
                    }
                ],
                "order": [
                    {
                        "propertyName": "string",
                        "ascending": true
                    }
                ],
                "pageNumber": 0,
                "pageSize": 0
            }
        ]

        // debugger
        var data = await FeedbackSettingApi.getFeedbackSetting(filters)
        setInputValue(data.length)
        const data1 = Array.from({ length: parseInt(data.length) }, (_, index) => ({
            parameter: `Parameter ${index + 1}`,
            feedbackCaption: data[index].feedbackCaption,
            serviceTypeId: data[index].serviceTypeId,
            maxFeedbackValue: data[index].maxFeedbackValue,
        }));
        // debugger
        console.log(data1);
        setData(data1);

    }

    useEffect(() => {
        getFeedbackdetails()
    }, [])


    /**************{No of Parameter}********************/


    const createAttributeControles = (event) => {
        const value = event.target.value;
        //console.log(`value - ${value}`);
        handleInputChange(value);
        if (value === "") {
            setInputValue(value)
        } else {
            setInputValue(value)
            fetchAttribute1(value)
        }
    };

    const fetchAttribute1 = async (value) => {

        var filters = [
            {
                "filter": [
                    {
                        "fieldName": "serviceTypeId",
                        "operatorName": "equal",
                        "compareValue": ServiceType || 751
                    }
                ],
                "order": [
                    {
                        "propertyName": "string",
                        "ascending": true
                    }
                ],
                "pageNumber": 0,
                "pageSize": 0
            }
        ]

        try {
            var data1 = await FeedbackSettingApi.getFeedbackSetting(filters)
            if (data1) {
                console.log("update data", data1);
                // setInputValue(data1.length);
                handleInputChange(value);

                const newData1 = Array.from({ length: parseInt(value) }, (_, index) => {
                    if (index < data1.length) {
                        return {
                            parameter: `Parameter ${index + 1}`,
                            feedbackCaption: data1[index].feedbackCaption,
                            serviceTypeId: data1[index].serviceTypeId,
                            maxFeedbackValue: data1[index].maxFeedbackValue,
                        };
                    } else {
                        return {
                            parameter: `Parameter ${index + 1}`,
                            feedbackCaption: '',
                            serviceTypeId: ServiceType || 751,
                            maxFeedbackValue: '',
                        };
                    }
                });


                console.log(newData1)
                setData(newData1)
            } else {
                console.log("error");
            }
        } catch (error) {
            console.log(error);
        }
    }




    const handleInputChange = (value) => {
        setInputValue(value);

        const data = Array.from({ length: parseInt(value) }, (_, index) => ({
            parameter: `Parameter ${index + 1}`,
            feedbackCaption: '',
            serviceTypeId: ServiceType || 751,
            maxFeedbackValue: '',
        }));

        setData(data);
        if (value != 0) {
            // getFeedbackdetails()
        }
    };

    // ***************************** Handle Input Values Change ***************
    const handleInputValuesChange = (event, index) => {
        const { name, value } = event.target;
        // console.log(`Changing ${name} to ${value} for index ${index}`);

        setData(prevData => {
            const newData = [...prevData];
            newData[index] = { ...newData[index], [name]: value };
            return newData;
        });
    };

    const deleteRow = (index) => {
        setData(prevData => prevData.filter((_, i) => i !== index));
    }


    // const [inputValue, setInputValue] = useState("");
    // const [data, setData] = useState([]);

    // const handleInputChange = (event) => {
    //     const value = event.target.value;
    //     setInputValue(value);

    //     const newData = Array.from({ length: parseInt(value) }, (_, index) => ({

    //         parameter: `Parameter ${index + 1}`,
    //         input1: "",
    //         input2: "",
    //         input3: false,
    //         input8: "",
    //     }));

    //     if (newData.length > 15) {
    //         window.alert("You can add only 15 optional fields!!!");
    //     } else {
    //         setData(newData);
    //     }
    // };


    // **********************{Press Enter Button}************************

    const input1Ref = useRef(null);
    const input2Ref = useRef(null);


    const handleKeyPress = (event, nextInputRef) => {
        if (event.key === "Enter") {
            event.preventDefault();
            if (nextInputRef.current) {
                nextInputRef.current.focus();
            }
        }
    };

    const SaveBtn = () => {
        setLoading(true);
        const isSuccess = FeedbackSettingApi.insertFeedbackSetting(data);
        if (isSuccess) {
            setLoading(false);
            OpenAlertBox();
        } else {
            alert("Error inserting data");
        }
    }

    return (
        <div>
            <PageTabs />
            <div className="panel-heading">
                <div className="panel-head-title">
                    <h2 className="panel-title">Feedback Setting</h2>
                </div>
                <MainButton SaveButton={SaveBtn} />
                <DateTime />
            </div>
            <div className="content_box">
                <div className="company-info-top-im">
                    <div className="tab-head">
                        <div id="tabs">
                            <div className="tab-content-box">
                                <div className="tab-content-1">
                                    <div className="company-info-top-right control_flex_m1">
                                        <div className="control_radius">
                                            <div className="company_info-text-im">
                                                Feedback Field
                                            </div>
                                            <div className="company_control">
                                                <div className="stock-im-dep1">
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <input ref={input1Ref} onKeyDown={(event) => handleKeyPress(event, input2Ref)}
                                                                className="modify_im_input mode_category1"
                                                                type="text"
                                                                required
                                                                value={inputValue}
                                                                onChange={createAttributeControles}
                                                            />
                                                            {/* {errorMsg && <p style={{ color: 'red' }}>{errorMsg}</p>} */}
                                                            <label class="modify_p_text2">
                                                                Enter Number of Feedback Field to be Created
                                                            </label>
                                                        </div>

                                                    </div>
                                                </div>
                                                <select className="modify_im_input mode_category"
                                                    name="serviceTypeId" id="" onChange={(event) => getFeedbackdetails(event)}
                                                // value={row.serviceTypeId}
                                                >
                                                    <option value="751">Table Order</option>
                                                    <option value="752">Home delivery</option>
                                                    <option value="753">Pick Up</option>
                                                    <option value="754">POS</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="company_info-text-im">Feedback List</div>
                                        <div
                                            className="table-container-s1"
                                            style={{ height: "auto" }}
                                        >
                                            <table className="table-scrol2" style={{ width: "100%" }}>
                                                <tbody
                                                    className="table-container-attribute AddNewItem_scroll addItemTable Field-attmargintop"
                                                    style={{}}
                                                >
                                                    {data.map((row, index) => (
                                                        <tr className="Table-Attribute-Field feedback-flex" key={index}>
                                                            <td
                                                                className="table-th-serialno srno-flex-attribute priority-width"
                                                                style={{ textAlign: "right" }}
                                                            >
                                                                <div className="serialno_icon">{index + 1}</div>
                                                            </td>
                                                            <td className="mb_4_attributevalue">
                                                                <input
                                                                    className="table-input-Barcode Attribute_1_txtcenter atrbt-val-outline"
                                                                    type="text"
                                                                    name={`feedbackCaption`}
                                                                    placeholder="Feedback Caption"
                                                                    onChange={(event) => handleInputValuesChange(event, index)} // Pass index here
                                                                    value={row.feedbackCaption}
                                                                />
                                                            </td>

                                                            <td className="mb_10 priority-feed-width">
                                                                <input
                                                                    className="table-input-Barcode pd_1 border_barcode"
                                                                    type="number"
                                                                    name={`maxFeedbackValue`}
                                                                    placeholder=" Max Feedback Value"
                                                                    onChange={(event) => handleInputValuesChange(event, index)} // Pass index here
                                                                    value={row.maxFeedbackValue}
                                                                />
                                                            </td>
                                                            <td onClick={() => deleteRow(index)}><button style={{ color: "red", backgroundColor: "white", padding: "3px 8px", cursor: "pointer", fontSize: "16px" }}><i class="fa-regular fa-trash-can"></i></button></td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ***************************** Mobile View ******************* */}
                <div>
                    <div className="buttom-box">
                        <div className="button-btn-1">
                            <button className="btn-1">Save</button>
                        </div>
                        <div className="button-btn-2">
                            <button className="btn-2">Reset</button>
                        </div>
                        <div className="button-btn-3">
                            <button className="btn-3" onClick={showModal}>
                                <i class="fa-solid fa-gear "></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>


            {alertBox && (
                <AlertBox
                    HeadingTop="Saved"
                    Heading="Your Department is Saved"
                    onYesClick={OpenAlertBox}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBox}
                />
            )}

            {loading && <Loader />}
        </div>
    );
}


export default FeedbackSetting;
