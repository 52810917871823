import DateTime from "../../Components/DateTime";
import PageTabs from "../../Components/PageTabs";
import React, { useState, useEffect } from "react";
import Joyride from 'react-joyride';
import 'react-quill/dist/quill.snow.css';
import ProductList from "../../Props/ProductList";
import BillPaymentInvoice from "../../Props/BillPaymentInvoice";
import AccountMaster from '../../Master/AccountMaster/AccountMaster';
import PreviousOrder from "../../Dashboard/PreviousOrder";
import MainButton from "../../Props/MainButton";

function SaleInvoice(props) {
    /***********title***************/
    useState(() => {
        document.title = 'Sale Invoice';
    }, []);


    ////************Image add******************///
    useEffect(() => {
        const handleContextMenu = (event) => {
            event.preventDefault();
        };

        window.addEventListener('contextmenu', handleContextMenu);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('contextmenu', handleContextMenu);
        };
    }, []);

    //  /* *******************Tab****************** */

    const [tabcontent1, setTabContent1] = useState(true);
    const [tabcontent2, setTabContent2] = useState(false);
    const [tabcontent3, setTabContent3] = useState(false);
    const [tabcontent4, setTabContent4] = useState(false);
    const [tabcontent5, setTabContent5] = useState(false);
    const [tabcontent6, setTabContent6] = useState(false);


    const showtabs1 = () => {
        setTabContent1(true)
        setTabContent2(false)
        setTabContent3(false)
        setTabContent4(false)
        setTabContent5(false)
        setTabContent6(false)
    }

    const showtabs2 = () => {
        setTabContent1(false)
        setTabContent2(true)
        setTabContent3(false)
        setTabContent4(false)
        setTabContent5(false)
        setTabContent6(false)
    }

    const showtabs3 = () => {
        setTabContent1(false)
        setTabContent2(false)
        setTabContent3(true)
        setTabContent4(false)
        setTabContent5(false)
        setTabContent6(false)
    }
    const showtabs4 = () => {
        setTabContent1(false)
        setTabContent2(false)
        setTabContent3(false)
        setTabContent4(true)
        setTabContent5(false)
        setTabContent6(false)
    }
    const showtabs5 = () => {
        setTabContent1(false)
        setTabContent2(false)
        setTabContent3(false)
        setTabContent4(false)
        setTabContent5(true)
        setTabContent6(false)
    }
    const showtabs6 = () => {
        setTabContent1(false)
        setTabContent2(false)
        setTabContent3(false)
        setTabContent4(false)
        setTabContent5(false)
        setTabContent6(true)
    }

    const [activeTab, setActiveTab] = useState(0); // Initialize the active tab index

    const handleTabClick = (index) => {
        setActiveTab(index);
        if (index !== 5) {
            var firstTabElement = document.getElementById('firstTab');
            firstTabElement.classList.remove('active1');
            console.log(index);
        }
    };


    //  /* *******************Tab****************** */

    const [tabcontent11, setTabContent11] = useState(true);
    const [tabcontent22, setTabContent22] = useState(false);
    //const [tabcontent33, setTabContent33] = useState(false);
    const [tabcontent44, setTabContent44] = useState(false);

    const showtabs11 = () => {
        setTabContent11(true)
        setTabContent22(false)
        //setTabContent33(false)
        setTabContent44(false)
    }
    const showtabs22 = () => {
        setTabContent11(false)
        setTabContent22(true)
        setTabContent44(false)
    }
    const showtabs44 = () => {
        setTabContent11(false)
        setTabContent22(false)
        setTabContent44(true)
    }

    // {/* *******************Tab****************** */}

    //************modal box************/ /

    const [isModalOpen1, setModalOpen1] = useState(false);

    const showModal1 = () => {
        setModalOpen1(true);
    };
    const hideModal1 = () => {
        setModalOpen1(false);
    };

    const [isModalOpen2, setModalOpen2] = useState(false);
    const showModal2 = () => {
        setModalOpen2(true);
    };
    const hideModal2 = () => {
        setModalOpen2(false);
    };
    //**********************{modal box}**********************/ /

    const handleModalClick = (e) => {
        // Prevent the event from propagating to parent elements
        e.stopPropagation();
    };
    
    ///*****************Onboarding code Open************************///

    const [{ run, steps },] = useState({
        run: true,
        steps: [
            {
                content: <h2>Let's begin our journey!</h2>,
                locale: { skip: <strong>SKIP</strong> },
                placement: "center",
                target: "body"
            },
            {
                content: <h2>Upload Item Image!</h2>,
                placement: "bottom",
                target: "#step-1",
                title: "First step"
            },
            {
                content: <h2>Here is second step!</h2>,
                placement: "bottom",
                target: "#step-2",
                title: "Second step"
            },
            {
                content: <h2>Here is third step!</h2>,
                placement: "bottom",
                target: "#step-3",
                title: "Third step"
            },
            {
                content: <h2>Here is fourth step!</h2>,
                placement: "bottom",
                target: "#step-4",
                title: "Fourth step"
            },
            {
                content: <h2>Here is fifth step!</h2>,
                placement: "bottom",
                target: "#step-5",
                title: "Fifth step"
            },
            {
                content: <h2>Here is six step!</h2>,
                placement: "bottom",
                target: "#step-6",
                title: "Six step"
            },
        ]
    });

    ///*****************Onboarding code Close************************//

    // *************************** Lens ***************************************//
    //const [rows1, setRows1] = useState(Array(3).fill({}));

    // const handleAddRow1 = () => {
    //     setRows([...rows1, {}]);
    // };

    // const handleInputChange12 = (rowIndex, e) => {
    //     // Handle input changes and update the corresponding row data
    //     const { name, value } = e.target;
    //     const updatedRows1 = [...rows1];
    //     updatedRows1[rowIndex][name] = value;
    //     setRows1(updatedRows1);
    // };

    // const handleDeleteRow1 = (rowIndex) => {
    //     // Create a copy of the rows1 array without the row to be deleted
    //     const updatedRows = rows1.filter((_, index) => index !== rowIndex);

    //     // Update the state with the modified rows array
    //     setRows1(updatedRows);
    // }


    // const [isHovered3, setIsHovered3] = useState(false);
    // const [isHovered4, setIsHovered4] = useState(false);
    // const [isHovered5, setIsHovered5] = useState(false);
    // const [isHovered6, setIsHovered6] = useState(false);
    // const [isHovered7, setIsHovered7] = useState(false);
    // const [isHovered8, setIsHovered8] = useState(false);
    const [isHovered9, setIsHovered9] = useState(false);
    const [isHovered10, setIsHovered10] = useState(false);

    const [PartyDetailModal, setPartyDetailModal] = useState(false);
    const OpenPartyModal = () => {
        setPartyDetailModal(true);
    };
    const ClosePartyModal = () => {
        setPartyDetailModal(false);
    };

    const [EyeModal, setEyeModal] = useState(false);
    const OpenEyeModal = () => {
        setEyeModal(true);
    };
    const CloseEyeModal = () => {
        setEyeModal(false);
    };

    const handleSliderButton = (index) => {
        const val = CheckBoxData[index];
        const isSelected = selectedData.some(item => item.id === val.id);
        if (!isSelected) {
            setSelectedData(prevSelectedData => [...prevSelectedData, val]);
        } else {
            setSelectedData(prevSelectedData =>
                prevSelectedData.filter(item => item.id !== val.id)
            );
        }
        console.log(selectedData);
    };

    const handleSliderButton1 = () => {
        const title = document.getElementById('NameTitle').style.display;
        if (title === 'none') {
            document.getElementById('NameTitle').style.display = "block";
            document.getElementById('PartyInput').style.width = "80%";
        }
        else {
            document.getElementById('NameTitle').style.display = "none";
            document.getElementById('PartyInput').style.width = "100%";
        }
    };
    const handleSliderButton2 = (val) => {
        document.getElementById('saleRemarkPart').style.display = "block";
        const title = document.getElementById(val).style.display;
        if (title === 'none') {
            document.getElementById(val).style.display = "block";
        }
        else {
            document.getElementById(val).style.display = "none";
        }
    };

    const [CheckBoxData] = useState([
        {
            id: 3,
            name: 'Date of birth',
            type: "date",
            idName: "DateOfBirth",
        },
        {
            id: 4,
            name: 'Anniversary Date',
            type: "date",
            idName: "AnniversaryDate",
        },
        {
            id: 6,
            name: 'POS',
            type: "text",
            idName: "POS",
        },
        {
            id: 7,
            name: 'A/c Bal.',
            type: "text",
            idName: "AccBalance",
        },
        {
            id: 8,
            name: 'Credit Limit',
            type: "text",
            idName: "CreditLimit",
        },
        {
            id: 9,
            name: 'Referred By',
            type: "text",
            idName: "ReferredBy",
        },
    ])
    const [selectedData, setSelectedData] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
        console.log(event.target.value)
        if (event.target.value === "Format1") {
            document.getElementById('LensFormat1').style.display = "block";
            document.getElementById('LensFormat2').style.display = "none";
        }
        else if (event.target.value === "Format2") {
            document.getElementById('LensFormat1').style.display = "none";
            document.getElementById('LensFormat2').style.display = "block";
        }
    };
    const [MobileSettingSlider, setMobileSettingSlider] = useState(false)

    const bottomSliderOpen = () => {
        setMobileSettingSlider(true)
    }
    const closeBottomSlider = () => {
        setMobileSettingSlider(false)
    }

    const [couponCode, setCouponCode] = useState('');

    const handleInputChange = (e) => {
        setCouponCode(e.target.value);
    };


    const [CouponModal, setCouponModal] = useState(false)

    const openCouponModal = () => {
        setCouponModal(true)
    }
    const closeCouponModal = () => {
        setCouponModal(false)
    }

    const [value, setValue] = useState(0);

    const handleDays = (e) => {
        const inputValue = parseInt(e.target.value, 10);
        if (!isNaN(inputValue) && inputValue >= 0) {
            setValue(inputValue);
        }
    };

    const ApplyTaxClick = () => {
        const val = document.getElementById('BillType').value;
        console.log(val);
        //  deleteTaxRow(1);
        var newRows;
        if (val === "12% GST(L)") {
            newRows = [
                { id: 1, TaxName: "CGST", Percent: "6%", Amount: "500" },
                { id: 2, TaxName: "SGST", Percent: "6%", Amount: "500" }
            ];
            setTaxRows([...newRows]);
        }
        else if (val === "12% GST(I)") {
            newRows =
                [{ id: 1, TaxName: "IGST", Percent: "12%", Amount: "1000" }];
            setTaxRows([...newRows]);
        }
        // const TaxName = taxRows[0].TaxName;
        // if (TaxName === "" || TaxName === null) {
        //     deleteTaxRow(0);
        // }
        // else{
        // }
    }

    const [taxRows, setTaxRows] = useState([{ id: 1, TaxName: "", Percent: "", Amount: "" }]);

    const handleCellKeyDown = (event, rowIndex) => {
        if (event.key === 'Enter') {
            const newRow = { id: taxRows.length + 1, content: '' };

            setTaxRows([...taxRows, newRow]);

            const nextRowIndex = rowIndex + 1;
            const nextRowCellId = `cell-${nextRowIndex}-0`;
            const nextRowCell = document.getElementById(nextRowCellId);

            if (nextRowCell) {
                nextRowCell.focus();
            }
        }
    };

    const handleCellChange = (event, rowIndex, colIndex) => {
        const updatedRows = [...taxRows];
        updatedRows[rowIndex] = {
            ...updatedRows[rowIndex],
            content: event.target.value,
        };
        setTaxRows(updatedRows);
    };
    const deleteTaxRow = (rowIndex) => {
        const updatedRows = taxRows.filter((_, index) => index !== rowIndex);
        setTaxRows(updatedRows);
    }

    const [AccountMasterModal, setAccountMasterModal] = useState(false)

    const openAccountMasterModal = (val) => {
        if (val === 'Add') {
            setAccountMasterModal(true)
            document.getElementById('PartySelectBox').value = "";
        }
    }
    const closeAccountMasterModal = () => {
        setAccountMasterModal(false)
    }

    const options = ['Aadhya', 'Sriti'];

    // }
    const [isSliderOpen, setSliderOpen] = useState(false);

    const toggleSlider = () => {
        setSliderOpen(!isSliderOpen);
    };

    //*********************{Button Script}*********************//


    return (
        <div>
            <div>
                <div>
                    <div className="rightmaster-company">
                        {/* <TopNamePage heading="ModifyProduct"/> */}
                        <div>
                            {/* <Joyride continuous callback={() => { }} run={run} steps={steps} hideCloseButton scrollToFirstStep showSkipButton showProgress /> */}
                            {
                                [].map((item) => {
                                    return (
                                        <div
                                            key={item}
                                            id={`step-${item}`}
                                            style={{
                                                border: "1px solid white",
                                                width: "100px",
                                                height: "100px",
                                                background: "#0c1d2b",
                                                borderRadius: "8px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            {item}
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <div className="panel-heading" style={{ backgroundColor: "#212e4f" }}>
                            <div className="panel-head-title">
                                <h2 className="panel-title" style={{ color: "white", fontWeight: "600" }}>Sale Invoice</h2>
                            </div >
                            {/* *******************Top Buttons****************** */}
                          
                            {/* *******************Modal Box Open****************** */}

                            {/* *******************{Top Buttons}****************** */}
                            <MainButton
                             isBtnSaleInvoiceSetting={true} OpenPartyModal={OpenPartyModal}
                            isBtnOrder={true} isBtnChallan={true} showModal1={showModal1}
                            showModal2={showModal2}
                            />
                        </div>
                        {/* *******************Modal Box End****************** */}
                        <div>
                            <DateTime />
                        </div>
                        <div className="content_box">
                            <div className="company-info-top-im">
                                <div className="tab-head">
                                    <div id="tabs">
                                        <div className="tab-content-box" style={{ height: "93vh" }}>
                                            <div className="tab-content-1">   {/*height25vh*/}
                                                <div className="company-info-top-left table-left-padding" style={{ padding: "5px" }}>
                                                    <div className="">
                                                        <div className="input-box-head">
                                                            {/*************************** Bill Data ***************/}
                                                            <div className="mt-1">
                                                                <div className="input-box-SI input-height2" style={{ width: "100%" }}>
                                                                    <div className="input-containerEffect mt-1" style={{ margin: "1%" }}>
                                                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" required />
                                                                        <label id="input1" className="labelEffect1">Bill Series</label>
                                                                    </div>
                                                                    <div className="input-containerEffect mt-1" style={{ margin: "1%" }}>
                                                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" required />
                                                                        <label id="input1" className="labelEffect1">Bill No</label>
                                                                    </div>
                                                                </div>
                                                                <div className="input-box-SI input-height2" style={{ width: "100%" }}>
                                                                    <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "50%" }}>
                                                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="date" required style={{ fontSize: "12px" }} />
                                                                        <label id="input1" className="labelEffectFixCode">Date</label>
                                                                    </div>
                                                                    <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "50%" }}>
                                                                        {/* <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" required /> */}
                                                                        <select id="BillType" className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" style={{ fontSize: "14px" }} required>
                                                                            <option>Select</option>
                                                                            <option>12% GST(L)</option>
                                                                            <option>12% GST(I)</option>
                                                                        </select>
                                                                        <label id="input1" className="labelEffect1">Bill Types</label>
                                                                    </div>
                                                                </div>
                                                                <div className="input-box-SI input-height2" style={{ width: "100%" }}>
                                                                    <div className="input-containerEffect mt-1" id="Godown" style={{ margin: "1%", display: "none" }}>
                                                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" required />
                                                                        <label id="input1" className="labelEffect1">Godown</label>
                                                                    </div>
                                                                    <div className="input-containerEffect mt-1" id="BookedBy" style={{ margin: "1%", display: "none" }}>
                                                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" required />
                                                                        <label id="input1" className="labelEffect1">Booked By</label>
                                                                    </div>
                                                                </div>
                                                                <div className="input-box-SI input-height2" style={{ width: "100%", justifyContent: "center" }}>
                                                                    <div className="input-containerEffect mt-1" style={{ margin: "1%" }}>
                                                                        {/* <Link to="/PreviousOrder"> */}
                                                                        {/* <button className="btn-lavender" onClick={openPreviousOrderModal}>Previous Orders</button> */}
                                                                        {/* </Link> */}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {/*************************** Party Details ***************/}
                                                            <div className="mt-1">
                                                                <div className="input-box-SI input-height2" style={{ width: "100%" }}>
                                                                    <select className="form-control nameTitle" id="NameTitle" style={{ display: "none", width: "20%" }}>
                                                                        <option>Mr.</option>
                                                                        <option>Mrs.</option>
                                                                        <option>Ms.</option>
                                                                    </select>

                                                                    <div className="input-containerEffect mt-1" id="PartyInput" style={{ margin: "1%", width: "100%" }}>
                                                                        {/* <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" onDoubleClick={openAccountMasterModal} style={{}} type="text" required /> */}
                                                                        <select className="form-control inputEffect modify_im_input mode_limit mode_-category input-s1" id="PartySelectBox" required onChange={(e) => openAccountMasterModal(e.target.value)} onDoubleClick={() => openAccountMasterModal("Add")} style={{ fontSize: "14px" }}>
                                                                            {options.map((option, index) => (
                                                                                <option key={index} value={option}>
                                                                                    {option}
                                                                                </option>
                                                                            ))}
                                                                            <option value="Add" className="optionBtn" ><button className="" style={{ textAlign: "center" }}>Add New</button></option>
                                                                        </select>
                                                                        {/* <select className="form-control inputEffect modify_im_input mode_limit mode_-category input-s1" required>
                                                                            <option>Aadhya</option>
                                                                            <option>Sriti</option>
                                                                            <option><button onDoubleClick={openAccountMasterModal}>Add New</button></option>
                                                                        </select> */}
                                                                        <label id="input1" className="labelEffect1">Part<span style={{ color: "red" }}>y</span> A/c</label>
                                                                    </div>
                                                                    <div onClick={toggleSlider} className="btn-lavender mt-1">
                                                                        <i className="fa-solid fa-right-from-bracket" onClick={toggleSlider}></i>
                                                                    </div>
                                                                </div>
                                                                <div className="input-box-SI input-height2" style={{ width: "100%" }}>
                                                                    <div className="input-containerEffect mt-1" style={{ margin: "1%" }}>
                                                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" required />
                                                                        <label id="input1" className="labelEffect1">Address</label>
                                                                    </div>
                                                                    <div className="input-containerEffect mt-1" style={{ margin: "1%" }}>
                                                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" required />
                                                                        <label id="input1" className="labelEffect1">Mobile</label>
                                                                    </div>
                                                                </div>
                                                                {/***********/}
                                                                <div className="input-box-SI input-height2" style={{ width: "100%" }}>
                                                                    <div className="addedGridView" style={{ marginTop: "-5px" }}>
                                                                        {(() => {
                                                                            if (selectedData.length != null) {
                                                                                //console.log(selectedData);
                                                                                return (
                                                                                    selectedData.map((data) => (
                                                                                        <div className="input-containerEffect mt-2" style={{ margin: "1%" }} key={data.id}>
                                                                                            <input id={data.idName} className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" style={{ width: "98%" }} required />
                                                                                            <label id="input1" className="labelEffect1">{data.name}</label>
                                                                                        </div>
                                                                                    ))
                                                                                )
                                                                            }
                                                                            return console.log("not found");;
                                                                        })()}
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                                                                                    

                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/*********************** Order List ********************/}
                                                        {isModalOpen1 && (
                                                            <div className="modal" >
                                                                <div className="modal-content1">
                                                                    <div className="modal-title">
                                                                        <h3 className="modal-h">Order List</h3>
                                                                        <span className="close" onClick={hideModal1}>
                                                                            &times;
                                                                        </span>
                                                                    </div>
                                                                    <div className="modal-content-im">
                                                                        <div className="table-container" >
                                                                            <table className="table-t" >
                                                                                <thead className="thead-groupmaster">
                                                                                    <tr>
                                                                                        <th className="table-th table-sn">SN</th>
                                                                                        <th className="table-th">Bill No</th>
                                                                                        <th className="table-th">Date</th>
                                                                                        <th className="table-th ">Bill Type</th>
                                                                                        <th className="table-th">Blnc Qty</th>
                                                                                        <th className="table-th">Total Amt</th>
                                                                                        <th className="table-th">Status</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody className="table-overflow" style={{ overflowY: 'scroll', maxHeight: '50px' }}>
                                                                                    <tr>
                                                                                        <td>1</td>
                                                                                        <td>1343534</td>
                                                                                        <td>10/01/2023</td>
                                                                                        <td>GST</td>
                                                                                        <td>Amount</td>
                                                                                        <td>1000</td>
                                                                                        <td>Pending</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}

                                                        <PageTabs />

                                                        {/*********************** Challan List ********************/}
                                                        {isModalOpen2 && (
                                                            <div className="modal" >
                                                                <div className="modal-content1">
                                                                    <div className="modal-title">
                                                                        <h3 className="modal-h">Challan List</h3>
                                                                        <span className="close" onClick={hideModal2}>
                                                                            &times;
                                                                        </span>
                                                                    </div>
                                                                    <div className="modal-content-im">
                                                                        <div className="table-container" >
                                                                            <table className="table-t" >
                                                                                <thead className="thead-groupmaster">
                                                                                    <tr>
                                                                                        <th className="table-th table-sn">SN</th>
                                                                                        <th className="table-th">Bill No</th>
                                                                                        <th className="table-th">Date</th>
                                                                                        <th className="table-th ">Bill Type</th>
                                                                                        <th className="table-th">Blnc Qty</th>
                                                                                        <th className="table-th">Total Amt</th>
                                                                                        <th className="table-th">Status</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody className="table-overflow" style={{ overflowY: 'scroll', maxHeight: '50px' }}>
                                                                                    <tr>
                                                                                        <td>1</td>
                                                                                        <td>1343534</td>
                                                                                        <td>10/01/2023</td>
                                                                                        <td>GST</td>
                                                                                        <td>Amount</td>
                                                                                        <td>1000</td>
                                                                                        <td>Pending</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                {/**************************Lens Part ***************************/}
                                                <div className="table-right-padding" style={{ backgroundColor: "white", borderLeft: "1px solid lightgrey" }} >
                                                    <div className="tabs-main" >
                                                        <div id="tabs " className="si-size-1">
                                                            <div className="tab-btn-box1 tab-b2" style={{ paddingBottom: "25px", paddingTop: "0px", backgroundColor: "rgba(255, 166, 2, 0.86)" }}>
                                                                <div id="firstTab" className={`tab-b1 tab-1 active1 ${activeTab === 5 ? 'active1' : ''}`}
                                                                    onClick={() => { handleTabClick(5); showtabs1() }} > <h6 className="mt-1 fw-600 fw-12">Lens</h6>
                                                                </div>
                                                                <div className={`tab-b1 tab-1 ${activeTab === 1 ? 'active1' : ''}`}
                                                                    onClick={() => { handleTabClick(1); showtabs2() }} > <h6 className="mt-1 fw-600 fw-12">Cont</h6>
                                                                </div>
                                                                <div className={`tab-b1 tab-1 ${activeTab === 2 ? 'active1' : ''}`}
                                                                    onClick={() => { handleTabClick(2); showtabs3() }} > <h6 className="mt-1 fw-600 fw-12">Trans</h6>
                                                                </div>
                                                                <div className={`tab-b1 tab-1 ${activeTab === 3 ? 'active1' : ''}`}
                                                                    onClick={() => { handleTabClick(3); showtabs4() }} > <h6 className="mt-1 fw-600 fw-12">AR</h6>
                                                                </div>
                                                                <div className={`tab-b1 tab-1 ${activeTab === 6 ? 'active1' : ''}`}
                                                                    onClick={() => { handleTabClick(6); showtabs6() }} > <h6 className="mt-1 fw-600 fw-12">Im</h6>
                                                                </div>
                                                                <div
                                                                    className={`tab-b1 tab-1 ${activeTab === 4 ? 'active1' : ''}`}
                                                                    onClick={() => { handleTabClick(4); showtabs5() }} ><h6 className="mt-1 fw-600 fw-12">Prevs</h6>
                                                                </div>
                                                                {/* <div className={`tab-b1 tab-1 ${activeTab === 6 ? 'active1' : ''}`}
                                                                    onClick={() => { handleTabClick(6); showtabs5() }} >
                                                                    <i className="fa-solid fa-arrows-rotate" style={{ fontSize: "16px" }}></i>
                                                                </div> */}
                                                            </div>

                                                            <div className="tab-content-box1 col-s5" >
                                                                {tabcontent1 && (
                                                                    <div className="tab-content-1">
                                                                        <div className="input-box-top-head" >
                                                                            <div className="input-box-head-left1">
                                                                                <div className="input-box-head-1">
                                                                                    <div className="space-evenly input-box-SI-1 input-height2 bottm-01">
                                                                                        <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "30%" }}>
                                                                                            <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" required />
                                                                                            <label id="input1" className="labelEffect1">Right</label>
                                                                                        </div>
                                                                                        <div className="input-containerEffect mt-1 " style={{ margin: "1%", width: "30%" }}>
                                                                                            <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" required />
                                                                                            <label id="input1" className="labelEffect1">Left</label>
                                                                                        </div>
                                                                                        <i onClick={OpenEyeModal} id="step-2" className="fa-solid fa-eye im-icon-2 InvoiceLensSettingicon" ></i>
                                                                                    </div>
                                                                                    {/******************* First Format ********************/}
                                                                                    <div id="LensFormat1" style={{ display: "block" }}>
                                                                                        <div className="input-box-SI-2 padding-1 ">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2">
                                                                                                    <label className="col-sm-3 ">PWR</label>
                                                                                                    <label className="col-sm-3 ">SPH</label>
                                                                                                    <label className="col-sm-3 ">CYL</label>
                                                                                                    <label className="col-sm-3 ">AXIS</label>
                                                                                                    <label className="col-sm-3 " style={{ marginLeft: "5px" }} >V/N</label>
                                                                                                    <label className="col-sm-3 ">SPH</label>
                                                                                                    <label className="col-sm-3 ">CYL</label>
                                                                                                    <label className="col-sm-3 ">AXIS</label>
                                                                                                    <label className="col-sm-3 ">V/N</label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="input-box-SI-2 ">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2">
                                                                                                    <label className="col-sm-3 ">D.V.</label>
                                                                                                    <input className="pwrInput col-sm-3 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="pwrInput col-sm-3 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="pwrInput col-sm-3 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="pwrInput col-sm-3 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="pwrInput col-sm-3 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="pwrInput col-sm-3 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="pwrInput col-sm-3 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="pwrInput col-sm-3 table-input box-size1 m-r-2" type="text" required />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="input-box-SI-2 ">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2">
                                                                                                    <label className="col-sm-3 ">N.V.</label>
                                                                                                    <input className="pwrInput col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="pwrInput col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="pwrInput col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="pwrInput col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="pwrInput col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="pwrInput col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="pwrInput col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="pwrInput col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="input-box-SI-2 ">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2 row-m1">
                                                                                                    <label className="col-sm-3 ">Add</label>
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <label className="col-sm-3 p2 m-r-2">P.B</label>
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <label className="col-sm-3 p2 m-r-2">P.B</label>
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {/******************* Second Format ********************/}
                                                                                    <div id="LensFormat2" style={{ display: "none" }}>
                                                                                        <div className="input-box-SI-2" >
                                                                                            <div className="input-box-m6">
                                                                                                <div className=" space-evenly row mt-2" style={{ width: "100%" }}>
                                                                                                    {/* <label className="col-sm-1"></label> */}
                                                                                                    <label className="col-sm-3" style={{ width: "16.5%" }}>&nbsp;SPH</label>
                                                                                                    <label className="col-sm-3" style={{ width: "16.5%" }}>CYL</label>
                                                                                                    <label className="col-sm-3" style={{ width: "16.5%" }}>AXIS</label>
                                                                                                    <label className="col-sm-3" style={{ width: "16.5%" }}>V/N</label>
                                                                                                    <label className="col-sm-3" style={{ width: "16.5%" }}>Add</label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="input-box-SI-2" style={{ width: "100%" }}>
                                                                                            <div className="input-box-m6">
                                                                                                <div className="space-evenly row mt-2" style={{ width: "100%" }}>
                                                                                                    <label className="col-sm-3 "> R</label>
                                                                                                    <input className="pwrInput col-sm-3 table-input table-input1 box-size1 m-r-2" style={{ width: "16.5%" }} type="text" required />
                                                                                                    <input className="pwrInput col-sm-3 table-input table-input1 box-size1 m-r-2" style={{ width: "16.5%" }} type="text" required />
                                                                                                    <input className="pwrInput col-sm-3 table-input table-input1 box-size1 m-r-2" style={{ width: "16.5%" }} type="text" required />
                                                                                                    <input className="pwrInput col-sm-3 table-input table-input1 box-size1 m-r-2" style={{ width: "16.5%" }} type="text" required />
                                                                                                    <input className="pwrInput col-sm-3 table-input table-input1 box-size1 m-r-2" style={{ width: "16.5%" }} type="text" required />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="input-box-SI-2" >
                                                                                            <div className="input-box-m6">
                                                                                                <div className="space-evenly row mt-2" style={{ width: "100%" }}>
                                                                                                    <label className="col-sm-3 "> L</label>
                                                                                                    <input className="pwrInput col-sm-4 table-input table-input1 box-size1 m-r-2" style={{ width: "16.5%" }} type="text" required />
                                                                                                    <input className="pwrInput col-sm-4 table-input table-input1 box-size1 m-r-2" style={{ width: "16.5%" }} type="text" required />
                                                                                                    <input className="pwrInput col-sm-4 table-input table-input1 box-size1 m-r-2" style={{ width: "16.5%" }} type="text" required />
                                                                                                    <input className="pwrInput col-sm-4 table-input table-input1 box-size1 m-r-2" style={{ width: "16.5%" }} type="text" required />
                                                                                                    <input className="pwrInput col-sm-4 table-input table-input1 box-size1 m-r-2" style={{ width: "16.5%" }} type="text" required />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {/********************/}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {/********************************* For Cont *************************/}
                                                                {tabcontent2 && (
                                                                    <div className="tab-content-2">
                                                                        <div className="LensOverflow">
                                                                            <div className="input-box-top-head" >
                                                                                <div className="input-box-head-left1">
                                                                                    <div className="input-box-head-1">
                                                                                        <div className="space-evenly input-box-SI-1 input-height2 bottm-01">
                                                                                            <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "30%" }}>
                                                                                                <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" required />
                                                                                                <label id="input1" className="labelEffect1">Right</label>
                                                                                            </div>
                                                                                            <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "30%" }}>
                                                                                                <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" required />
                                                                                                <label id="input1" className="labelEffect1">Left</label>
                                                                                            </div>
                                                                                            <i onClick={OpenEyeModal} id="step-2" className="fa-solid fa-eye im-icon-2 InvoiceLensSettingicon" ></i>
                                                                                        </div>
                                                                                        <div className="input-box-SI-2 padding-1 ">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2">
                                                                                                    <label className="col-sm-3 ">PWR</label>
                                                                                                    <label className="col-sm-3 ">SPH</label>
                                                                                                    <label className="col-sm-3 ">CYL</label>
                                                                                                    <label className="col-sm-3 ">AXIS</label>
                                                                                                    <label className="col-sm-3 " style={{ marginLeft: "5px" }} >V/N</label>
                                                                                                    <label className="col-sm-3 ">SPH</label>
                                                                                                    <label className="col-sm-3 ">CYL</label>
                                                                                                    <label className="col-sm-3 ">AXIS</label>
                                                                                                    <label className="col-sm-3 ">V/N</label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="input-box-SI-2 ">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2">
                                                                                                    <label className="col-sm-3 ">D.V.</label>
                                                                                                    <input className="col-sm-3 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="col-sm-3 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="col-sm-3 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="col-sm-3 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="col-sm-3 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="col-sm-3 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="col-sm-3 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="col-sm-3 table-input box-size1 m-r-2" type="text" required />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="input-box-SI-2 ">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2">
                                                                                                    <label className="col-sm-3 ">N.V.</label>
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="input-box-SI-2 ">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2 row-m1">
                                                                                                    <label className="col-sm-3 ">Add</label>
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <label className="col-sm-3 p2 m-r-2">B&D</label>
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <label className="col-sm-3 p2 m-r-2">B&D</label>
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="input-box-SI-2 ">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2 row-m1">
                                                                                                    <label style={{ marginLeft: "5px" }} className="col-sm-3  p2">&nbsp;Rem.</label>
                                                                                                    <input className="col-sm-6  input-s2 m-r-2 cont_prins" type="text" required />
                                                                                                    <input className="col-sm-6  input-s2 m-r-2 cont_prins" type="text" required />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {/************************************ For Trans *********************************/}
                                                                {tabcontent3 && (
                                                                    <div className="tab-content-3">
                                                                        <div className="input-box-top-head" >
                                                                            <div className="input-box-head-left1">
                                                                                <div className="input-box-head-1">
                                                                                    <div className="">
                                                                                        <div className="space-evenly input-box-SI-1 input-height2 bottm-01">
                                                                                            <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "30%" }}>
                                                                                                <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" disabled style={{ backgroundColor: "white" }} type="text" required />
                                                                                                <label id="input1" className="labelEffect1">Right</label>
                                                                                            </div>
                                                                                            <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "30%" }}>
                                                                                                <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" disabled style={{ backgroundColor: "white" }} type="text" required />
                                                                                                <label id="input1" className="labelEffect1">Left</label>
                                                                                            </div>
                                                                                            <i onClick={OpenEyeModal} id="step-2" className="fa-solid fa-eye im-icon-2 InvoiceLensSettingicon" ></i>
                                                                                        </div>

                                                                                    </div>
                                                                                    <div className="input-box-SI-2 padding-1 ">
                                                                                        <div className="input-box-m6">
                                                                                            <div className="row mt-2">
                                                                                                <label className="col-sm-3 ">PWR</label>
                                                                                                <label className="col-sm-3 ">SPH</label>
                                                                                                <label className="col-sm-3 ">CYL</label>
                                                                                                <label className="col-sm-3 ">AXIS</label>
                                                                                                <label className="col-sm-3 " style={{ marginLeft: "5px" }} >V/N</label>
                                                                                                <label className="col-sm-3 ">SPH</label>
                                                                                                <label className="col-sm-3 ">CYL</label>
                                                                                                <label className="col-sm-3 ">AXIS</label>
                                                                                                <label className="col-sm-3 ">V/N</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="input-box-SI-2 ">
                                                                                        <div className="input-box-m6">
                                                                                            <div className="row mt-2">
                                                                                                <label className="col-sm-3 ">D.V.</label>
                                                                                                <input className="col-sm-3 table-input box-size1 m-r-2" type="text" required />
                                                                                                <input className="col-sm-3 table-input box-size1 m-r-2" type="text" required />
                                                                                                <input className="col-sm-3 table-input box-size1 m-r-2" type="text" required />
                                                                                                <input className="col-sm-3 table-input box-size1 m-r-2" type="text" required />
                                                                                                <input className="col-sm-3 table-input box-size1 m-r-2" type="text" required />
                                                                                                <input className="col-sm-3 table-input box-size1 m-r-2" type="text" required />
                                                                                                <input className="col-sm-3 table-input box-size1 m-r-2" type="text" required />
                                                                                                <input className="col-sm-3 table-input box-size1 m-r-2" type="text" required />

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="input-box-SI-2 ">
                                                                                        <div className="input-box-m6">
                                                                                            <div className="row mt-2">
                                                                                                <label className="col-sm-3 ">N.V.</label>
                                                                                                <input className="col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                <input className="col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                <input className="col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                <input className="col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                <input className="col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                <input className="col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                <input className="col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                <input className="col-sm-4 table-input box-size1 m-r-2" type="text" required />

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="input-box-SI-2 ">
                                                                                        <div className="input-box-m6">
                                                                                            <div className="row mt-2 row-m1">
                                                                                                <label className="col-sm-3">Add &nbsp;</label>
                                                                                                <input className="col-sm-3 table-input box-size1 m-r-2" type="text" required />
                                                                                                <div className="col-sm-3"></div>
                                                                                                <div className="col-sm-3"></div>
                                                                                                <div className="col-sm-3"></div>&nbsp;
                                                                                                <input className="col-sm-3 table-input box-size1 prs-ad" type="text" required />
                                                                                                {/* <label className="col-sm-3" style={{ textAlign: "start" }}></label> */}
                                                                                                {/* <input className="col-sm-4 table-input box-size1" type="text" required /> */}
                                                                                                {/* <input className="col-sm-4 table-input box-size1" type="text" required /> */}
                                                                                                {/*<input className="col-sm-4 table-input" type="text" required />*/}
                                                                                                {/*<input className="col-sm-4 table-input input-j1" type="text" required />*/}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {/*************************************** AR *******************************************/}
                                                                {tabcontent4 && (
                                                                    <div className="tab-content-4">
                                                                        <div className="">
                                                                            <div className="input-box-top-head" >
                                                                                <div className="input-box-head-left1">
                                                                                    <div className="input-box-head-1">
                                                                                        <div className="space-evenly input-box-SI-1 input-height2 bottm-01">
                                                                                            <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "30%" }}>
                                                                                                <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" disabled style={{ backgroundColor: "white" }} type="text" required />
                                                                                                <label id="input1" className="labelEffect1">Right</label>
                                                                                            </div>
                                                                                            <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "30%" }}>
                                                                                                <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" disabled style={{ backgroundColor: "white" }} type="text" required />
                                                                                                <label id="input1" className="labelEffect1">Left</label>
                                                                                            </div>
                                                                                            <i onClick={OpenEyeModal} id="step-2" className="fa-solid fa-eye im-icon-2 InvoiceLensSettingicon" ></i>
                                                                                        </div>
                                                                                        <div className="input-box-SI-2 padding-1 ">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2">
                                                                                                    <label className="col-sm-3 ">PWR</label>
                                                                                                    <label className="col-sm-3 ">SPH</label>
                                                                                                    <label className="col-sm-3 ">CYL</label>
                                                                                                    <label className="col-sm-3 ">AXIS</label>
                                                                                                    <label className="col-sm-3 " style={{ marginLeft: "5px" }} >V/N</label>
                                                                                                    <label className="col-sm-3 ">SPH</label>
                                                                                                    <label className="col-sm-3 ">CYL</label>
                                                                                                    <label className="col-sm-3 ">AXIS</label>
                                                                                                    <label className="col-sm-3 ">V/N</label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="input-box-SI-2 ">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2">
                                                                                                    <label className="col-sm-3 ">D.V.</label>
                                                                                                    <input className="col-sm-3 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="col-sm-3 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="col-sm-3 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="col-sm-3 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="col-sm-3 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="col-sm-3 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="col-sm-3 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="col-sm-3 table-input box-size1 m-r-2" type="text" required />

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="input-box-SI-2 ">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2">
                                                                                                    <label className="col-sm-3 ">N.V.</label>
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="col-sm-4 table-input box-size1 m-r-2" type="text" required />

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="input-box-SI-2 ">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2 row-m1">
                                                                                                    <label className="col-sm-3 ">Add &nbsp;</label>
                                                                                                    <input className="col-sm-3 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <div className="col-sm-3"></div>
                                                                                                    <div className="col-sm-3"></div>
                                                                                                    <div className="col-sm-3"></div>
                                                                                                    <input className="col-sm-3 table-input box-size1 m-r-2 AR-Prs" type="text" required />
                                                                                                    {/* <label className="col-sm-3" style={{ textAlign: "start" }}></label> */}
                                                                                                    {/* <input className="col-sm-4 table-input box-size1" type="text" required /> */}
                                                                                                    {/* <input className="col-sm-4 table-input box-size1" type="text" required /> */}
                                                                                                    {/*<input className="col-sm-4 table-input" type="text" required />*/}
                                                                                                    {/*<input className="col-sm-4 table-input input-j1" type="text" required />*/}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {/***************************************** Prevs **********************************/}
                                                                {tabcontent5 && (
                                                                    <div className="tab-content-5">
                                                                        <div className="">
                                                                            <div className="input-box-m8">
                                                                                <table className="table-scrol1" style={{ width: "95%" }}>
                                                                                    <thead className="table-tbody">
                                                                                        <tr>
                                                                                            <th className="lensTableHeader" rowSpan="2">SN.</th>
                                                                                            <th className="lensTableHeader" rowSpan="2">Vch No Date</th>
                                                                                            <th className="lensTableHeader" colSpan="4">Right Lens</th>
                                                                                            <th className="lensTableHeader" colSpan="4">Left Lens</th>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <th className="lensTableHeader">SPH</th>
                                                                                            <th className="lensTableHeader">CYL</th>
                                                                                            <th className="lensTableHeader">AXIS</th>
                                                                                            <th className="lensTableHeader">ADD</th>
                                                                                            <th className="lensTableHeader">SPH</th>
                                                                                            <th className="lensTableHeader">CYL</th>
                                                                                            <th className="lensTableHeader">AXIS</th>
                                                                                            <th className="lensTableHeader">ADD</th>
                                                                                        </tr>
                                                                                        {/* <tr className="table-tr5">
                                                                                            <th className="table-th-1">SN</th>
                                                                                            <th className=" td-col4">Vch No Date</th>
                                                                                            <th className="table-th-2">
                                                                                                <div> Right Lens </div>
                                                                                                <div className="direct_coll-1">
                                                                                                    <div>SPH</div>
                                                                                                    <div>CYL</div>
                                                                                                    <div>AXIS</div>
                                                                                                    <div>ADD</div>
                                                                                                </div>
                                                                                            </th>
                                                                                            <th className="table-th-2">
                                                                                                <div> Left Lens </div>
                                                                                                <div className="direct_coll-2">
                                                                                                    <div>SPH</div>
                                                                                                    <div>CYL</div>
                                                                                                    <div>AXIS</div>
                                                                                                    <div>ADD</div>
                                                                                                </div>
                                                                                            </th>
                                                                                        </tr> */}
                                                                                    </thead>
                                                                                    <tbody className="table-container-tbody" >
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {/***************************************** Im **********************************/}
                                                                {tabcontent6 && (
                                                                    <div className="tab-content-1">
                                                                        <div className="input-box-top-head" >
                                                                            <div className="input-box-head-left1">
                                                                                <div className="input-box-head-1">
                                                                                    <div className="space-evenly input-box-SI-1 input-height2 bottm-01">
                                                                                        <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "30%" }}>
                                                                                            <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" disabled style={{ backgroundColor: "white" }} type="text" required />
                                                                                            <label id="input1" className="labelEffect1">Right</label>
                                                                                        </div>
                                                                                        <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "30%" }}>
                                                                                            <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" disabled style={{ backgroundColor: "white" }} type="text" required />
                                                                                            <label id="input1" className="labelEffect1">Left</label>
                                                                                        </div>
                                                                                        <i onClick={OpenEyeModal} id="step-2" className="fa-solid fa-eye im-icon-2 InvoiceLensSettingicon" ></i>
                                                                                    </div>
                                                                                    {/**************************************/}
                                                                                    <div>
                                                                                        <div className="input-box-SI-2 padding-1 ">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2">
                                                                                                    <label className="col-sm-3 ">PWR</label>
                                                                                                    <label className="col-sm-3 ">SPH</label>
                                                                                                    <label className="col-sm-3 ">CYL</label>
                                                                                                    <label className="col-sm-3 ">AXIS</label>
                                                                                                    <label className="col-sm-3 " style={{ marginLeft: "5px" }} >V/N</label>
                                                                                                    <label className="col-sm-3 ">SPH</label>
                                                                                                    <label className="col-sm-3 ">CYL</label>
                                                                                                    <label className="col-sm-3 ">AXIS</label>
                                                                                                    <label className="col-sm-3 ">V/N</label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="input-box-SI-2 ">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2">
                                                                                                    <label className="col-sm-3 ">D.V.</label>
                                                                                                    <input className="pwrInput col-sm-3 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="pwrInput col-sm-3 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="pwrInput col-sm-3 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="pwrInput col-sm-3 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="pwrInput col-sm-3 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="pwrInput col-sm-3 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="pwrInput col-sm-3 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="pwrInput col-sm-3 table-input box-size1 m-r-2" type="text" required />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="input-box-SI-2 ">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2">
                                                                                                    <label className="col-sm-3 ">N.V.</label>
                                                                                                    <input className="pwrInput col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="pwrInput col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="pwrInput col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="pwrInput col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="pwrInput col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="pwrInput col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="pwrInput col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                    <input className="pwrInput col-sm-4 table-input box-size1 m-r-2" type="text" required />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="input-box-SI-2 ">
                                                                                            <div className="input-box-m6">
                                                                                                <div className="row mt-2 row-m1">
                                                                                                    <label className="col-sm-3 ">Add &nbsp;</label>
                                                                                                    <input className="col-sm-3 table-input box-size1" type="text" required />
                                                                                                    <div className="col-sm-3"></div>
                                                                                                    <div className="col-sm-3"></div>
                                                                                                    <div className="col-sm-3"></div>
                                                                                                    <input className="col-sm-3 table-input box-size1 IM-Prs" type="text" required />
                                                                                                    {/* <label className="col-sm-3" style={{ textAlign: "start" }}></label> */}
                                                                                                    {/* <input className="col-sm-4 table-input box-size1" type="text" required /> */}
                                                                                                    {/* <input className="col-sm-4 table-input box-size1" type="text" required /> */}
                                                                                                    {/*<input className="col-sm-4 table-input" type="text" required />*/}
                                                                                                    {/*<input className="col-sm-4 table-input input-j1" type="text" required />*/}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    // <div className="tab-content-5">
                                                                    //     <div className="">
                                                                    //         <div className="company-info-top-im">

                                                                    //             <div className="company-info-top-right-tab5" >
                                                                    //                 <div className="company_info-text"></div>

                                                                    //                 <div className="input-box-Im">
                                                                    //                     <div className="input-box-m1">
                                                                    //                         <div className="company_info-text">List Of All Related Product</div>
                                                                    //                     </div>
                                                                    //                 </div>

                                                                    //             </div>
                                                                    //         </div>
                                                                    //     </div>
                                                                    // </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        {/************************************************************************/}
                                                        <div id="tabs si-size-2" className="si-size-2">
                                                            <div className="tab-btn-box2 tab-b2" style={{ paddingBottom: "25px", paddingTop: "0px", backgroundColor: "rgba(255, 166, 2, 0.86)" }}>
                                                                <div className="radio-btn-1" style={{ width: "100%" }}>
                                                                    <div className="flex" >
                                                                        <input type="radio" id="All" className="radio-box-1 box-size1" name="options" value="All" />
                                                                        <label For="All" style={{ fontWeight: "600" }}>&nbsp; All</label>
                                                                    </div>
                                                                    <div className="flex">
                                                                        <input type="radio" id="DV" className="radio-box-1 box-size1" name="options" value="DV" />
                                                                        <label For="DV" style={{ fontWeight: "600" }}>&nbsp; DV</label>
                                                                    </div>
                                                                    <div className="flex">
                                                                        <input type="radio" className="radio-box-1 box-size1" id="NV" name="options" value="NV" />
                                                                        <label For="NV" style={{ fontWeight: "600" }}>&nbsp; NV</label>
                                                                    </div>
                                                                    <div className="flex">
                                                                        <input type="radio" className="radio-box-1 box-size1" id="CL" name="options" value="CL" />
                                                                        <label For="CL" style={{ fontWeight: "600" }}>&nbsp; CL</label>
                                                                    </div>
                                                                    <div className="flex">
                                                                        <input type="radio" className="radio-box-1 box-size1" id="Trans" name="options" value="Trans" />
                                                                        <label For="Trans" style={{ fontWeight: "600" }}>&nbsp; Trans</label>
                                                                    </div>
                                                                    <div className="flex">
                                                                        <input type="checkbox" className="radio-box-1 box-size1" id="R" value="R" style={{ height: "25px" }} />
                                                                        <label For="R" style={{ fontWeight: "600" }}>&nbsp; R</label>
                                                                    </div>
                                                                    <div className="flex">
                                                                        <input type="checkbox" className="radio-box-1 box-size1" id="L" value="L" style={{ height: "25px" }} />
                                                                        <label className="padding-02" For="L" style={{ fontWeight: "600" }}>&nbsp; L</label>
                                                                    </div>
                                                                </div>
                                                                <div className="flex">
                                                                    <label className="radio-box-1 box-size1" style={{ fontWeight: "600", }}>Lens&nbsp;Price</label>
                                                                </div>
                                                            </div>

                                                            <div className="space-evenly" style={{ paddingRight: "10px" }}>
                                                                <div>
                                                                    <div className="input-containerEffect mt-3 w-130" style={{ margin: "1%" }}>
                                                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" required />
                                                                        <label id="input1" className="labelEffect1 fw-10">Lens&nbsp;Type</label>
                                                                    </div>
                                                                    <div className="input-containerEffect mt-2 w-130" style={{ margin: "1%" }}>
                                                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" required />
                                                                        <label id="input1" className="labelEffect1 fw-10">Prescribed&nbsp;By</label>
                                                                    </div>
                                                                    <div className="input-containerEffect mt-2 w-130" style={{ margin: "1%" }}>
                                                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" required />
                                                                        <label id="input1" className="labelEffect1 fw-10">Party&nbsp;Name</label>
                                                                    </div>
                                                                    <div className="input-containerEffect mt-2 w-130" style={{ margin: "1%" }}>
                                                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" required />
                                                                        <label id="input1" className="labelEffect1 fw-10">Frame&nbsp;Name</label>
                                                                    </div>
                                                                </div>

                                                                <div>
                                                                    <div className="input-box-m9 dis-flex mt-2" >
                                                                        <span className="label-box-si-12 sol1" style={{ textAlign: "right" }}>NV &nbsp;  &nbsp;</span>

                                                                        <div className="input-box-m1" style={{ width: "30%" }}>
                                                                            <select className="modify_im_input mode_limit mode_category input-s1 box-size1" type="text" required>
                                                                                <option Classname="form-control s-1" value="">NA</option>
                                                                                <option Classname="form-control s-1" value="1w">1W</option>
                                                                                <option Classname="form-control s-1" value="1m">1M</option>
                                                                                <option Classname="form-control s-1" value="3m">3M</option>
                                                                                <option Classname="form-control s-1" value="6m">6M</option>
                                                                                <option Classname="form-control s-1" value="1y">1Y</option>
                                                                            </select>
                                                                        </div>
                                                                        <input className="modify_im_input mode_category input-s1" type="DATE" required style={{ width: "70%" }} />
                                                                    </div>
                                                                    <div className="input-box-m9 dis-flex mt-2">
                                                                        <span className="label-box-si-12 sol1" style={{ textAlign: "right" }}>CLE &nbsp;  &nbsp;</span>
                                                                        <div className="input-box-m1" style={{ width: "30%" }}>
                                                                            <select className="modify_im_input mode_limit mode_category input-s1 box-size1" type="text" required>
                                                                                <option Classname="form-control s-1" value="">NA</option>
                                                                                <option Classname="form-control s-1" value="1w">1W</option>
                                                                                <option Classname="form-control s-1" value="1m">1M</option>
                                                                                <option Classname="form-control s-1" value="3m">3M</option>
                                                                                <option Classname="form-control s-1" value="6m">6M</option>
                                                                                <option Classname="form-control s-1" value="1y">1Y</option>
                                                                            </select>
                                                                        </div>
                                                                        <input className="modify_im_input mode_category input-s1" type="DATE" required style={{ width: "70%" }} />
                                                                    </div>

                                                                    <div className="input-box-m9 dis-flex mt-2" style={{}}>
                                                                        <div className="flex-end" style={{ width: "100%" }}>
                                                                            <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "24%" }}>
                                                                                <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" required />
                                                                                <label id="input1" className="label10px labelEffect1" style={{ fontSize: "12px" }}>PD RL</label>
                                                                            </div>
                                                                            <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "24%" }}>
                                                                                <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" required />
                                                                                <label id="input1" className=" label10px labelEffect1" style={{ fontSize: "12px" }}>PD R</label>
                                                                            </div>
                                                                            <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "24%" }}>
                                                                                <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" required />
                                                                                <label id="input1" className="label10px labelEffect1" style={{ fontSize: "12px" }}>PD L</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex-start" style={{ display: "flex", justifyContent: "end" }}>
                                                                        {/*<div className="input-box-m1 dis-flex width-20" style={{}}>*/}
                                                                        {/*    <span className="label-box-si-12 mt-1" >Delivery</span>*/}
                                                                        {/*</div>*/}
                                                                        <span className="label-box-si-12 mt-2" style={{ textAlign: "center" }}>&nbsp;</span>
                                                                        <div className="input-containerEffect mt-2 w-28" style={{ margin: "1%" }}>
                                                                            <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 inDays" type="number"
                                                                                value={value} onChange={handleDays} style={{ width: "88%" }} required />
                                                                            <label id="input1" className="label10px labelEffect1">Deli.Days </label>
                                                                        </div>
                                                                        <div className="input-containerEffect mt-2 w-28" style={{ margin: "1%" }}>
                                                                            <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 inTime" type="time" required />
                                                                            <label id="input1" className="labelEffect1" style={{ fontSize: "12px", padding: "0px" }}>Time </label>
                                                                        </div>
                                                                        <div className="input-box-s11 input-height2 mt-2">
                                                                            <div className="flex-end mt-1">
                                                                                <i className="fa-solid fa-square-plus" onMouseEnter={() => setIsHovered9(true)}
                                                                                    onMouseLeave={() => setIsHovered9(false)} style={{ color: "darkgreen" }}></i> &nbsp; &nbsp;
                                                                                {isHovered9 && <div className="hover-text1" style={{ top: "22%", right: "2%" }}>Add Lens</div>}<br />

                                                                                <i className="fa-solid fa-rotate-left" onMouseEnter={() => setIsHovered10(true)}
                                                                                    onMouseLeave={() => setIsHovered10(false)} style={{ color: "#ff4163" }}></i>
                                                                                {isHovered10 && <div className="hover-text1" style={{ top: "22%", right: "2%" }}>Reset</div>}<br />
                                                                                {/*<button className="panel-reset-btn" style={{ backgroundColor: "#2a9f79", color: "white", border: "1px solid darkgrey", fontWeight: "600" }}>Add Lens</button> &nbsp;*/}
                                                                                {/*<button className="panel-reset-btn" style={{ backgroundColor: "#ff4163", color: "white", border: "1px solid darkgrey", fontWeight: "600" }}>Reset</button>*/}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/*********************************/}
                                                            <div className="tab-content-box1 col-s6">
                                                                {/*<span className="label-box-si-12">Lens For</span>*/}
                                                                {/*<div className="input-box-v1">*/}
                                                                {/*    <div className="radio-btn-02">*/}
                                                                {/*        <input type="radio" id="All" className="radio-box-1 box-size1" name="options1" value="All" />*/}
                                                                {/*        <label For="All">Ready</label>*/}
                                                                {/*        <input type="radio" id="DV" className="radio-box-1 box-size1" name="options1" value="DV" />*/}
                                                                {/*        <label For="DV">Rx</label>*/}
                                                                {/*    </div>*/}
                                                                {/*</div>*/}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="tab-head-side-right">
                                                        <div className="">
                                                            <div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/****************************************************** Product Table **************************************************************************/}
                                            <div /*className="height30vh"*/ >
                                                <ProductList />
                                            </div>
                                            {/******************************************************* Total Details *************************************************************************/}
                                            <div className="tabs-main1 height30vh">
                                                <div id="tabs" className="tab_a1">
                                                    <div className="tab-btn-box1 tab-b3 bg-color" style={{ backgroundColor: "#5e709e" }}>
                                                        <div
                                                            className={`tab-b1 tab-2 active11 ${activeTab === 11 ? 'active' : ''}`}
                                                            onClick={() => { handleTabClick(11); showtabs11() }} >
                                                            <label className="c-w col-black">Sundry</label>
                                                        </div>
                                                        <div
                                                            className={`tab-b1 tab-2 ${activeTab === 22 ? 'active' : ''}`}
                                                            onClick={() => { handleTabClick(22); showtabs22() }} >
                                                            <label className="c-w col-black">GST</label>
                                                        </div>
                                                        {/*<div*/}
                                                        {/*    className={`tab-b1 tab-2 ${activeTab === 33 ? 'active' : ''}`}*/}
                                                        {/*    onClick={() => { handleTabClick(33); showtabs33() }} >*/}
                                                        {/*    <label className="c-white col-black">Coupon</label>*/}
                                                        {/*</div>*/}
                                                        <div
                                                            className={`tab-b1 tab-2 ${activeTab === 44 ? 'active' : ''}`}
                                                            onClick={() => { handleTabClick(44); showtabs44() }} >
                                                            <label className="c-w col-black">Loyality</label>
                                                        </div>
                                                    </div>

                                                    <div className="tab-content-box1 ">
                                                        {/*************** Sundry *****************************/}
                                                        {tabcontent11 && (
                                                            <div className="tab-content-1">
                                                                <div className="input-box-top-head" >
                                                                    <div className="">
                                                                        <div>
                                                                            <table className="table-scrol1">
                                                                                <thead className="table-tbody" style={{ backgroundColor: "#e9e5e5" }}>
                                                                                    <tr className="table-tr1">
                                                                                        <th className="table-th-1">SN</th>
                                                                                        <th className=" td-col2">Taxes & Othr Charge(Sundry)</th>
                                                                                        <th className="table-th-6">@%</th>
                                                                                        <th className="table-th-5">Amount</th>
                                                                                        <th className="table-th-4">Action</th>
                                                                                    </tr>
                                                                                </thead>

                                                                                <tbody className=" table-container-tbody" style={{ height: "auto", maxHeight: "155px", overflowY: "scroll" }} >
                                                                                    {taxRows.map((row, rowIndex) => (
                                                                                        <tr className="table-tr1" key={rowIndex} >
                                                                                            <td className="table-td-2" style={{ textAlign: "center" }} >
                                                                                                {rowIndex + 1}
                                                                                            </td>
                                                                                            <td className="td-col2">
                                                                                                <input
                                                                                                    className="table-input-13 input-box-td1"
                                                                                                    type="text"
                                                                                                    name={row.TaxName}
                                                                                                    value={row.TaxName}
                                                                                                />
                                                                                            </td>
                                                                                            <td className="table-td-2">
                                                                                                <input
                                                                                                    className="table-input-13 input-box-td2"
                                                                                                    type="text"
                                                                                                    name={row.Percent}
                                                                                                    value={row.Percent}
                                                                                                />
                                                                                            </td>
                                                                                            <td className="table-td-2">
                                                                                                <input
                                                                                                    className="table-input-13 input-box-td2"
                                                                                                    type="text"
                                                                                                    name={row.Amount}
                                                                                                    value={row.Amount}
                                                                                                    onChange={(e) => handleCellChange(e, rowIndex, 0)}
                                                                                                    onKeyDown={(e) => handleCellKeyDown(e, rowIndex)}
                                                                                                />
                                                                                            </td>
                                                                                            <td className="table-td-2" style={{ textAlign: "center" }}>
                                                                                                <button className="btn-table1" onClick={() => deleteTaxRow(rowIndex)}><i className="fa-solid fa-trash delete-btn"></i></button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    ))}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}

                                                        {/*************** GST *****************************/}
                                                        {tabcontent22 && (
                                                            <div className="tab-content-1 height-115">
                                                                <div className="input-box-top-head" >
                                                                    <div className="input-box-head-left1">
                                                                        <div className="input-box-head-1">
                                                                            <div className="input-box-SI-2 ">
                                                                                <div className="input-box-m7">
                                                                                    <div className="space-between">
                                                                                        <div className="button-col-s1">
                                                                                            <button type="button" className="button-col-s2">Bill Wise</button>
                                                                                        </div>
                                                                                        <div className="button-col-s1">
                                                                                            <button type="button" className="button-col-s2">Item Wise</button>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="button-col-SOTax flex-start">
                                                                                        <span className="color-col-s1 SaleTaxBtn"></span>
                                                                                        <label>Tax not calculated</label>
                                                                                    </div>
                                                                                    <div className="button-col-SOTax flex-start">
                                                                                        <span className="color-col-s4 SaleTaxBtn"></span>
                                                                                        <label>Tax not apply</label>
                                                                                    </div>
                                                                                    <div className="button-col-SOTax flex-start">
                                                                                        <span className="color-col-s3 SaleTaxBtn"></span>
                                                                                        <label>Tax value not apply</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}

                                                        {/*************** Coupon *****************************/}
                                                        {/* {tabcontent33 && (
                                                            <div className="tab-content-2">
                                                                <div className="">
                                                                    <div className="input-box-top-head" >
                                                                        <div className="input-box-head-left1">
                                                                            <div className="input-box-head-1">
                                                                                <div className="search-panel-col-1">
                                                                                    <div className="mt-1">
                                                                                        <div className="input-box-SI-1 input-height2">
                                                                                            <div className="input-box-m3">
                                                                                                <input className="modify_im_input mode_category input-s1" type="text" required />
                                                                                                <label className="modify_p_text2 m-22">Enter Coupon Name</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="panel-all-btn mt-1">
                                                                                        <div className="panel-col-1">
                                                                                            <button className="panel-search-btn" style={{ backgroundColor: "" }}> Search </button>
                                                                                        </div>
                                                                                        <div className="panel-col-2">
                                                                                            <button className="panel-reset-btn" style={{ backgroundColor: "" }}> Reset </button>
                                                                                        </div>
                                                                                        <div className="panel-col-3">
                                                                                            <button className="panel-buy-btn" style={{ backgroundColor: "" }}> Apply All Buy X Get Y Free </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <table className="mt-1">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th className="th" style={{ backgroundColor: "#eaeaea" }}>S.No.</th>
                                                                                            <th className="th" style={{ backgroundColor: "#eaeaea" }}>Coupon Details</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td className="td">1</td>
                                                                                            <td className="td">
                                                                                                Code: FRAMEFREE, Expire Date: 26-06-2024, Min. Amt: 200
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )} */}

                                                        {/************************* Loyality ***********************/}
                                                        {tabcontent44 && (
                                                            <div className="tab-content-3">
                                                                <div className="input-box-top-head" >
                                                                    <div className="input-box-head-left2">
                                                                        <div className="input-box-head-1">
                                                                            <table style={{ width: "100%" }}>
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th className="th" style={{ backgroundColor: "#eaeaea" }} >S.No.</th>
                                                                                        <th className="th" style={{ backgroundColor: "#eaeaea" }} >Item Details</th>
                                                                                        <th className="th" style={{ backgroundColor: "#eaeaea" }} >Loyalty Point</th>
                                                                                        <th className="th" style={{ backgroundColor: "#eaeaea" }}>Loyalty Amount</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td className="td">1</td>
                                                                                        <td className="td">Lens</td>
                                                                                        <td className="td">50</td>
                                                                                        <td className="td">50</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div className="space-between mt-1" style={{ padding: "5px" }}>
                                                            <button onClick={ApplyTaxClick}>Apply Tax</button>
                                                            <label>Total Sundry Amt. 0.00</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/***************************** Bill Payment Invoice **************************/}
                                                <div className="table-row1">
                                                    {/*<div className="col_58" style={{ backgroundColor:"#98a1b7", color:"black", borderLeft:"1px solid black"} }>Bill Payment Details</div>*/}
                                                    <div>
                                                        <BillPaymentInvoice />
                                                    </div>
                                                    <div>
                                                        {/*<div>*/}
                                                        {/*    <table className="table-scrol1">*/}
                                                        {/*        <thead className="table-tbody">*/}
                                                        {/*            <tr className="table-tr1" style={{ backgroundColor:"rgb(152, 161, 183)"} }>*/}
                                                        {/*                <th className="table-th-1 c-b">SN</th>*/}
                                                        {/*                <th className=" td-col2 c-b">Taxes & Othr Charge(Sundry)</th>*/}
                                                        {/*                <th className="table-th-2 c-b">@%</th>*/}
                                                        {/*                <th className="table-th-2 c-b">Amount</th>*/}
                                                        {/*                <th className="table-th-2 c-b">Action</th>*/}
                                                        {/*            </tr>*/}
                                                        {/*        </thead>*/}

                                                        {/*        <tbody className=" table-container-tbody" >*/}
                                                        {/*            {rows1.map((row, rowIndex) => (*/}
                                                        {/*                <tr className="table-tr1" key={rowIndex} >*/}
                                                        {/*                    <td className="table-td-2" style={{ textAlign: "center" }} >*/}
                                                        {/*                        {rowIndex + 1}*/}
                                                        {/*                    </td>*/}
                                                        {/*                    <td className="table-td-3">*/}
                                                        {/*                        <input*/}
                                                        {/*                            className="table-input-13 input-box-td1"*/}
                                                        {/*                            type="text"*/}
                                                        {/*                            name={`Sundry-${rowIndex}`}*/}
                                                        {/*                            value={row[`Sundry-${rowIndex}`] || ""}*/}
                                                        {/*                            onChange={(e) => handleInputChange12(rowIndex, e)}*/}
                                                        {/*                        />*/}
                                                        {/*                    </td>*/}
                                                        {/*                    <td className="table-td-2">*/}
                                                        {/*                        <input*/}
                                                        {/*                            className="table-input-13 input-box-td2"*/}
                                                        {/*                            type="text"*/}
                                                        {/*                            name={`percent-${rowIndex}`}*/}
                                                        {/*                            value={row[`percent-${rowIndex}`] || ""}*/}
                                                        {/*                            onChange={(e) => handleInputChange12(rowIndex, e)}*/}
                                                        {/*                        />*/}
                                                        {/*                    </td>*/}
                                                        {/*                    <td className="table-td-2">*/}
                                                        {/*                        <input*/}
                                                        {/*                            className="table-input-13 input-box-td2"*/}
                                                        {/*                            type="text"*/}
                                                        {/*                            name={`Amount-${rowIndex}`}*/}
                                                        {/*                            value={row[`Amount-${rowIndex}`] || ""}*/}
                                                        {/*                            onChange={(e) => handleInputChange12(rowIndex, e)}*/}
                                                        {/*                        />*/}
                                                        {/*                    </td>*/}
                                                        {/*                    <td className="table-td-2" style={{ textAlign: "center" }}>*/}
                                                        {/*                        <button className="btn-table1" onClick={() => handleDeleteRow1(rowIndex)}><i className="fa-solid fa-trash delete-btn"></i></button>*/}
                                                        {/*                    </td>*/}
                                                        {/*                </tr>*/}
                                                        {/*            ))}*/}
                                                        {/*        </tbody>*/}
                                                        {/*    </table>*/}
                                                        {/*</div>*/}
                                                    </div>
                                                </div>
                                                {/***************************** Total **************************/}
                                                <div className="table-row2">
                                                    <span className="table-ax-1"></span>
                                                    <div className="padding-20 totalBg-color" style={{ backgroundColor: "rgba(94, 112, 158, 0.12)", height: "100%" }}>
                                                        <div className="space-between mt-1">
                                                            <label className="c-b">Loyality</label>
                                                            <div className="flex-end" style={{ width: "50%" }}>
                                                                <div className="image-input-container" style={{ width: "100%" }}>
                                                                    <input type="text" placeholder="" className=" modify_im_input12 mode_limit mode_category" />
                                                                    <button className=" apply-button input-image" >Use</button> {/*onClick={handleApplyCoupon}*/}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="space-between mt-1">
                                                            <label className="c-b">Coupon</label>
                                                            <div className="flex-end" style={{ width: "50%" }}>
                                                                <div className="image-input-container" style={{ width: "100%" }}>
                                                                    <input type="text" value={couponCode} placeholder="Enter Coupon" onChange={handleInputChange} className=" modify_im_input12 mode_limit mode_category" />
                                                                    <button className=" apply-button input-image" onClick={openCouponModal}>View</button> {/*onClick={handleApplyCoupon}*/}
                                                                </div>
                                                            </div>
                                                        </div>
                                                       
                                                        <div className="space-between mt-1">
                                                            <label className="c-b">Taxable Value</label>
                                                            <input className="modify_im_input12 mode_limit mode_category" type="text" required style={{ width: "50%" }} />
                                                        </div>
                                                        <div className="space-between mt-1">
                                                            <label className="c-b">Gross Value</label>
                                                            <input className="modify_im_input12 mode_limit mode_category" type="text" required style={{ width: "50%" }} />
                                                        </div>
                                                        {/*<div className="space-between mt-1">*/}
                                                        {/*    <label className="c-b">Allow Loyality</label>*/}
                                                        {/*    <div className="col-d-1">*/}
                                                        {/*        <input onChange={handleToggleChangsi3} disabled={isCheckboxDisabledsi3} style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />*/}
                                                        {/*        <label className="modify_p_text_checkbox col-in-1 c-b"> Loyalty Amount</label>*/}
                                                        {/*    </div>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="space-between mt-1">*/}
                                                        {/*    <label className="c-b">Add Discount</label>*/}
                                                        {/*    <div className="col-d-1">*/}
                                                        {/*        <input style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />*/}
                                                        {/*        <label className="modify_p_text_checkbox col-in-1 c-b"> (Party Wise Catgry Disc)</label>*/}
                                                        {/*    </div>*/}
                                                        {/*</div>*/}
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="saleRemarkPart" style={{ display: "none" }}>
                                                <div className="space-evenly saleRemarkPart">
                                                    <div className="input-containerEffect mt-1" id="Remark" style={{ display: "none", margin: "1%", width: "20%" }}>
                                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" required />
                                                        <label id="input1" className="labelEffect1" style={{ fontSize: "12px" }}>Remark</label>
                                                    </div>
                                                    <div className="input-containerEffect mt-1" id="DeliveryDate" style={{ display: "none", margin: "1%", width: "20%" }}>
                                                        <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="date" required style={{ padding: "0px 7px" }} />
                                                        <label id="input1" className="labelEffect1" style={{ fontSize: "12px" }}>Delivery Date &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</label>
                                                    </div>
                                                    <div className="input-containerEffect mt-1" id="Status" style={{ display: "none", margin: "1%", width: "20%" }}>
                                                        <select className="modify_im_input mode_limit mode_category input-s1 width-100 inputEffect" type="text" required style={{ padding: "0px 7px", backgroundColor: "white" }}>
                                                            <option Classname="form-control s-1" value="">Select</option>
                                                            <option Classname="form-control s-1" value="Pending">Pending</option>
                                                            <option Classname="form-control s-1" value="Approved">Approved</option>
                                                            <option Classname="form-control s-1" value="Cancel">Cancel</option>
                                                            <option Classname="form-control s-1" value="Conv_Pur_Challan">Conv. to Pur Challan</option>
                                                            <option Classname="form-control s-1" value="Conv_Pur_Puechase">Conv. to Pur Puechase</option>
                                                            <option Classname="form-control s-1" value="Conv_Pur_Sale">Conv. to Pur Sale</option>
                                                            <option Classname="form-control s-1" value="Conv_Pur_Sale_Challan">Conv. to Pur Sale Challan</option>
                                                            <option Classname="form-control s-1" value="Delay">Delay</option>
                                                            <option Classname="form-control s-1" value="Delivered">Delivered</option>
                                                            <option Classname="form-control s-1" value="Done">Done</option>
                                                            <option Classname="form-control s-1" value="Done_with_Verified">Done with Verified</option>
                                                            <option Classname="form-control s-1" value="Exchange_In">Exchange In</option>
                                                            <option Classname="form-control s-1" value="Exchange_Out">Exchange Out</option>
                                                            <option Classname="form-control s-1" value="On_Process">On Process</option>
                                                            <option Classname="form-control s-1" value="Put_of_Shippment">Put of Shippment</option>
                                                            <option Classname="form-control s-1" value="Processing">Processing</option>
                                                            <option Classname="form-control s-1" value="Ready">Ready</option>
                                                        </select>
                                                        <label id="input1" className="labelEffect1" style={{ fontSize: "12px" }}>Status</label>
                                                    </div>
                                                    <div className="input-containerEffect mt-1" id="Remark1" style={{ display: "none", margin: "1%", width: "20%" }}>
                                                        <select className="modify_im_input mode_limit mode_category input-s1 inputEffect" type="text" required style={{ padding: "0px 7px", backgroundColor: "white" }}>
                                                            <option Classname="form-control s-1" value="">Select</option>
                                                            <option Classname="form-control s-1" value="AN">Special Customer</option>
                                                            <option Classname="form-control s-1" value="AP">Test1</option>
                                                            <option Classname="form-control s-1" value="AR">Wrong Order</option>
                                                            <option Classname="form-control s-1" value="AR">Conv. to Pur Puechase</option>
                                                        </select>
                                                        <label id="input1" className="labelEffect1" style={{ fontSize: "12px" }}>Remark1 &nbsp; &nbsp;</label>
                                                    </div>
                                                    <div className="input-containerEffect mt-1" id="Remark2" style={{ display: "none", margin: "1%", width: "20%" }}>
                                                        <select className="modify_im_input mode_limit mode_category input-s1 inputEffect" type="text" required style={{ padding: "0px 7px", backgroundColor: "white" }}>
                                                            <option Classname="form-control s-1" value="">TEST2</option>
                                                            <option Classname="form-control s-1" value="AN">CAMP CUSTOMER</option>
                                                        </select>
                                                        <label id="input1" className="labelEffect1" style={{ fontSize: "12px" }}>Remark2 &nbsp; &nbsp;</label>
                                                    </div>
                                                    <div className="input-containerEffect mt-1" id="Remark3" style={{ display: "none", margin: "1%", width: "20%" }}>
                                                        <select className="modify_im_input mode_limit mode_category input-s1 inputEffect" type="text" required style={{ padding: "0px 7px", backgroundColor: "white" }}>
                                                            <option Classname="form-control s-1" value="">Select</option>
                                                            <option Classname="form-control s-1" value="">CHARITY</option>
                                                        </select>
                                                        <label id="input1" className="labelEffect1" style={{ fontSize: "12px" }}>Remark3 &nbsp; &nbsp;</label>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="input-remark-01" >
                                                <div className="input-remark-left">
                                                    <div className="input-remark-m1">
                                                        <div className="input-remark-001 padding-03" id="Remark" style={{ display: "none" }}>
                                                            <span className=" sol4">Remarks:-</span>
                                                            <div className="input-box-v1 input-size-01">
                                                                <input className="modify_im_input mode_category input-s1 " type="text" required />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="input-remark-right">
                                                    <div className="input-remark-m1">
                                                        <div className="input-box-s11 input-height2">
                                                            <div className="input-box-m9 dis-flex padding-03 column-550" style={{ width: "100%" }}>
                                                                <div className="input-containerEffect mt-2" id="DeliveryDate" style={{ display: "none", margin: "1%", width: "100%" }}>
                                                                    <input className="inputEffect modify_im_input" type="date" id="GroupName" required />
                                                                    <label id="input1" className="labelEffect">Delivery Date &nbsp; &nbsp;</label>
                                                                </div>
                                                                <div className="flex" style={{ width: "100%" }}>
                                                                    <div className="input-box-v1 mt-2">
                                                                        <div className="input-remark-box-m1" id="Status" style={{ display: "none" }}>
                                                                            <select className="modify_im_input mode_limit mode_category input-s1 width-100" type="text" required>
                                                                                <option Classname="form-control s-1" value="Pending">Pending</option>
                                                                                <option Classname="form-control s-1" value="Approved">Approved</option>
                                                                                <option Classname="form-control s-1" value="Cancel">Cancel</option>
                                                                                <option Classname="form-control s-1" value="Conv_Pur_Challan">Conv. to Pur Challan</option>
                                                                                <option Classname="form-control s-1" value="Conv_Pur_Puechase">Conv. to Pur Puechase</option>
                                                                                <option Classname="form-control s-1" value="Conv_Pur_Sale">Conv. to Pur Sale</option>
                                                                                <option Classname="form-control s-1" value="Conv_Pur_Sale_Challan">Conv. to Pur Sale Challan</option>
                                                                                <option Classname="form-control s-1" value="Delay">Delay</option>
                                                                                <option Classname="form-control s-1" value="Delivered">Delivered</option>
                                                                                <option Classname="form-control s-1" value="Done">Done</option>
                                                                                <option Classname="form-control s-1" value="Done_with_Verified">Done with Verified</option>
                                                                                <option Classname="form-control s-1" value="Exchange_In">Exchange In</option>
                                                                                <option Classname="form-control s-1" value="Exchange_Out">Exchange Out</option>
                                                                                <option Classname="form-control s-1" value="On_Process">On Process</option>
                                                                                <option Classname="form-control s-1" value="Put_of_Shippment">Put of Shippment</option>
                                                                                <option Classname="form-control s-1" value="Processing">Processing</option>
                                                                                <option Classname="form-control s-1" value="Ready">Ready</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div className="input-box-m9 dis-flex padding-03 mt-2">
                                                                        <div className="input-remark-box-m1 " id="Remark1" style={{ display: "none" }}>
                                                                            <select className="modify_im_input mode_limit mode_category input-s1" type="text" required>
                                                                                <option Classname="form-control s-1" value="">REMARK1</option>
                                                                                <option Classname="form-control s-1" value="AN">Special Customer</option>
                                                                                <option Classname="form-control s-1" value="AP">Test1</option>
                                                                                <option Classname="form-control s-1" value="AR">Wrong Order</option>
                                                                                <option Classname="form-control s-1" value="AR">Conv. to Pur Puechase</option>
                                                                            </select>
                                                                        </div>
                                                                        <div className="input-remark-box-m1 " id="Test" style={{ display: "none" }}>
                                                                            <select className="modify_im_input mode_limit mode_category input-s1" type="text" required>
                                                                                <option Classname="form-control s-1" value="">TEST2</option>
                                                                                <option Classname="form-control s-1" value="AN">CAMP CUSTOMER</option>
                                                                            </select>
                                                                        </div>
                                                                        <div className="input-remark-box-m1 " id="Charity" style={{ display: "none" }}>
                                                                            <select className="modify_im_input mode_limit mode_category input-s1" type="text" required>
                                                                                <option Classname="form-control s-1" value="">CHARITY</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="buttom-box" style={{ backgroundColor: "#ececec", padding: "5px", borderTop: "2px solid lightgrey", borderRadius: "5px" }}>
                                <div className="flex" style={{ display: "flex", width: "100%" }}>
                                    <button className="greenBtn" style={{ width: "40%" }}>Save</button>
                                    <button className="redBtn" style={{ width: "40%" }}>Reset</button>
                                    <button style={{ width: "20%", margin: "5px" }}><i className="fa-solid fa-gear" onClick={bottomSliderOpen} ></i></button>
                                </div>
                                {/*<div className="buttom-box">*/}
                                {/*    <div className="button-btn-1">*/}
                                {/*        <button className="btn-1">Save</button>*/}
                                {/*    </div>*/}
                                {/*    <div className="button-btn-2">*/}
                                {/*        <button className="btn-2">Reset</button>*/}
                                {/*    </div>*/}
                                {/*    <div className="button-btn-3">*/}
                                {/*        <button className="btn-3" onClick={showModal}><i className="fa-solid fa-gear "></i></button>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/************************* Party Detail Modal *****************************/}
            {PartyDetailModal && (
                // <div className="modal-back">
                //     <div className="partyDetail-modal" style={{ height: "auto" }}>
                //         <div style={{ padding: "15px" }}>
                //             <div style={{ display: "flex", justifyContent: "space-between" }}>
                //                 <label>Select fields you want to display</label>
                //                 <label style={{ cursor: "pointer", fontSize: "24px" }} onClick={ClosePartyModal}>x</label>
                //             </div>
                //             <hr />
                //             <div className="CheckboxOptionOnHover" onClick={() => handleSliderButton1("Mx")}>
                //                 <div className="cl-toggle-switch">
                //                     <label className="cl-switch" >
                //                         <input type="checkbox" onChange={() => handleSliderButton1("Mx")} />
                //                         <span></span>
                //                     </label>
                //                     <label className="modify_p_text2">Mx</label>
                //                 </div>
                //             </div>
                //             {CheckBoxData.map((data, index) => (
                //                 <div className="CheckboxOptionOnHover" >
                //                     <div className="cl-toggle-switch">
                //                         <label className="cl-switch" key={data.id}>
                //                             <input type="checkbox" onChange={() => handleSliderButton(index)} />
                //                             <span></span>
                //                         </label>
                //                         <label className="modify_p_text2">{data.name}</label>
                //                     </div>
                //                     {/*<div style={{ display: "flex", justifyContent: "space-between" }}>*/}
                //                     {/*    <label>{data.name}</label>*/}
                //                     {/*    <label className="switch" key={data.id} style={{}} >*/}
                //                     {/*        <input type="checkbox" onChange={() => handleSliderButton(index)} />*/}
                //                     {/*        <span className="slider round"></span>*/}
                //                     {/*    </label>*/}
                //                     {/*</div>*/}
                //                     {/*<hr />*/}
                //                 </div>
                //             ))}
                //             <div className="CheckboxOptionOnHover" >
                //                 <div className="cl-toggle-switch">
                //                     <label className="cl-switch" >
                //                         <input type="checkbox" onChange={() => handleSliderButton2("Godown")} />
                //                         <span></span>
                //                     </label>
                //                     <label className="modify_p_text2">Godown</label>
                //                 </div>
                //             </div>
                //             <div className="CheckboxOptionOnHover" >
                //                 <div className="cl-toggle-switch">
                //                     <label className="cl-switch" >
                //                         <input type="checkbox" onChange={() => handleSliderButton2("BookedBy")} />
                //                         <span></span>
                //                     </label>
                //                     <label className="modify_p_text2">Booked By</label>
                //                 </div>
                //             </div>
                //             <div className="CheckboxOptionOnHover">
                //                 <div className="cl-toggle-switch">
                //                     <label className="cl-switch" >
                //                         <input type="checkbox" onChange={() => handleSliderButton2("Status")} />
                //                         <span></span>
                //                     </label>
                //                     <label className="modify_p_text2">Delivery Status</label>
                //                 </div>
                //             </div>
                //             <div className="CheckboxOptionOnHover">
                //                 <div className="cl-toggle-switch">
                //                     <label className="cl-switch" >
                //                         <input type="checkbox" onChange={() => handleSliderButton2("DeliveryDate")} />
                //                         <span></span>
                //                     </label>
                //                     <label className="modify_p_text2">Delivery Date</label>
                //                 </div>
                //             </div>
                //             <div className="CheckboxOptionOnHover">
                //                 <div className="cl-toggle-switch">
                //                     <label className="cl-switch" >
                //                         <input type="checkbox" onChange={() => handleSliderButton2("Remark")} />
                //                         <span></span>
                //                     </label>
                //                     <label className="modify_p_text2">Remark</label>
                //                 </div>
                //             </div>
                //             <div className="CheckboxOptionOnHover">
                //                 <div className="cl-toggle-switch">
                //                     <label className="cl-switch" >
                //                         <input type="checkbox" onChange={() => handleSliderButton2("Remark1")} />
                //                         <span></span>
                //                     </label>
                //                     <label className="modify_p_text2">Remark1</label>
                //                 </div>
                //             </div>
                //             <div className="CheckboxOptionOnHover">
                //                 <div className="cl-toggle-switch">
                //                     <label className="cl-switch" >
                //                         <input type="checkbox" onChange={() => handleSliderButton2("Remark2")} />
                //                         <span></span>
                //                     </label>
                //                     <label className="modify_p_text2">Remark2</label>
                //                 </div>
                //             </div>
                //             <div className="CheckboxOptionOnHover">
                //                 <div className="cl-toggle-switch">
                //                     <label className="cl-switch" >
                //                         <input type="checkbox" onChange={() => handleSliderButton2("Remark3")} />
                //                         <span></span>
                //                     </label>
                //                     <label className="modify_p_text2">Remark3</label>
                //                 </div>
                //             </div>
                //             <hr />
                //             <div className="flex-end">
                //                 <button className="close-btn1" onClick={ClosePartyModal}>Close</button>
                //             </div>
                //         </div>
                //     </div>
                // </div>

                <div className="modal" >
                    <div className="modal-content2">
                        <div className="modal-title">
                            <h3 className="modal-h">Invoice Setting</h3>
                            <span className="close" onClick={ClosePartyModal}>
                                &times;
                            </span>
                        </div>
                        <div className="ModalBoxItem_FullHeight">
                            <div className="ModalBoxItem_Left"  >
                                <div className="modal-content-im ">
                                    <div className="">
                                        <div className="input-box-m1 modal-2">
                                            <div className="cl-toggle-switch">
                                                <label className="cl-switch">
                                                    <input type="checkbox" onChange={() => handleSliderButton1("Mx")} />
                                                    <span></span>
                                                </label>
                                                <label className="ModalLabeltext2">Mx<i className="fa-solid fa-circle-info ml-10"></i></label>
                                            </div>
                                        </div>
                                    </div>
                                    {CheckBoxData.map((data, index) => (
                                        <div className="input-box-m1 modal-2" >
                                            <div className="cl-toggle-switch">
                                                <label className="cl-switch" key={data.id}>
                                                    <input type="checkbox" onChange={() => handleSliderButton(index)} />
                                                    <span></span>
                                                </label>
                                                <label className="ModalLabeltext2">{data.name}</label>
                                            </div>
                                        </div>
                                    ))}

                                </div>
                            </div>
                            <div className="ModalBoxItem_Right">
                                <div className="modal-content-im ">


                                    <div className="">
                                        <div className="input-box-m1 modal-2">
                                            <div className="cl-toggle-switch">
                                                <label className="cl-switch">
                                                    <input type="checkbox" onChange={() => handleSliderButton2("BookedBy")} />
                                                    <span></span>
                                                </label>
                                                <label className="ModalLabeltext2">Booked By<i className="fa-solid fa-circle-info ml-10"></i></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="input-box-m1 modal-2">
                                            <div className="cl-toggle-switch">
                                                <label className="cl-switch">
                                                    <input type="checkbox" onChange={() => handleSliderButton2("Godown")} />
                                                    <span></span>
                                                </label>
                                                <label className="ModalLabeltext2">Godown<i className="fa-solid fa-circle-info ml-10"></i></label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="">
                                        <div className="input-box-m1 modal-2">
                                            <div className="cl-toggle-switch">
                                                <label className="cl-switch">
                                                    <input type="checkbox" onChange={() => handleSliderButton2("Status")} />
                                                    <span></span>
                                                </label>
                                                <label className="ModalLabeltext2">Delivery Status<i className="fa-solid fa-circle-info ml-10"></i></label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="">
                                        <div className="input-box-m1 modal-2">
                                            <div className="cl-toggle-switch">
                                                <label className="cl-switch">
                                                    <input type="checkbox" onChange={() => handleSliderButton2("DeliveryDate")} />
                                                    <span></span>
                                                </label>
                                                <label className="ModalLabeltext2">Delivery Date<i className="fa-solid fa-circle-info ml-10"></i></label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="">
                                        <div className="input-box-m1 modal-2">
                                            <div className="cl-toggle-switch">
                                                <label className="cl-switch">
                                                    <input type="checkbox" onChange={() => handleSliderButton2("Remark")} />
                                                    <span></span>
                                                </label>
                                                <label className="ModalLabeltext2">Remark<i className="fa-solid fa-circle-info ml-10"></i></label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="">
                                        <div className="input-box-m1 modal-2">
                                            <div className="cl-toggle-switch">
                                                <label className="cl-switch">
                                                    <input type="checkbox" onChange={() => handleSliderButton2("Remark1")} />
                                                    <span></span>
                                                </label>
                                                <label className="ModalLabeltext2">Remark1<i className="fa-solid fa-circle-info ml-10"></i></label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="">
                                        <div className="input-box-m1 modal-2">
                                            <div className="cl-toggle-switch">
                                                <label className="cl-switch">
                                                    <input type="checkbox" onChange={() => handleSliderButton2("Remark2")} />
                                                    <span></span>
                                                </label>
                                                <label className="ModalLabeltext2">Remark2<i className="fa-solid fa-circle-info ml-10"></i></label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="">
                                        <div className="input-box-m1 modal-2">
                                            <div className="cl-toggle-switch">
                                                <label className="cl-switch">
                                                    <input type="checkbox" onChange={() => handleSliderButton2("Remark3")} />
                                                    <span></span>
                                                </label>
                                                <label className="ModalLabeltext2">Remark3<i className="fa-solid fa-circle-info ml-10"></i></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/**************************** Eye/Lens Modal ********************/}
            {EyeModal && (
                <div className="modal-back">
                    <div className="partyDetail-modal" style={{ height: "auto", width: "22%" }}>
                        <div style={{ padding: "15px" }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <label style={{ color: "white" }}>Select the format</label>
                                <label className="closeBtnhover " style={{ cursor: "pointer", fontSize: "24px" }} onClick={CloseEyeModal}>x</label>
                            </div>
                            <hr />
                            <div style={{ display: "grid", justifyContent: "center" }}>
                                <label className="radio-label">
                                    <input type="radio" value="Format1" checked={selectedOption === 'Format1'}
                                        onChange={handleOptionChange} className="large-radio" style={{ boxShadow: "none" }} />
                                    &nbsp; With NV
                                </label><br /><hr style={{ color: "black" }} />

                                <label className="radio-label">
                                    <input type="radio" value="Format2" checked={selectedOption === 'Format2'}
                                        onChange={handleOptionChange} className="large-radio" style={{ boxShadow: "none" }} />
                                    &nbsp; Without NV
                                </label>
                            </div>
                            <hr className="mt-2" />
                            <div className="flex-end">
                                <button className="close-btn1" onClick={CloseEyeModal}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/******************************* Bottom Slider*************************/}
            {MobileSettingSlider && (
                <div className="modal-back">
                    <div className="bottomSlider">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <label>Setting</label>
                            <label style={{ cursor: "pointer", fontSize: "24px" }} onClick={closeBottomSlider}>x</label>
                        </div>
                        <div className="space-between">
                            <label></label>
                        </div>
                    </div>
                </div>
            )}
            {/******************************* Coupon Modal*************************/}
            {CouponModal && (
                <div className="modal-back">
                    <div className="partyDetail-modal" style={{ height: "auto", width: "35%" }}>
                        <div style={{ padding: "15px" }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <label>View Coupon</label>
                                <label style={{ cursor: "pointer", fontSize: "24px" }} onClick={closeCouponModal}>x</label>
                            </div>
                            <hr />
                            <div style={{ margin: "10px", padding: "10px" }}>
                                <div className="couponBox">
                                    <div className="couponLeftSide">
                                        <label className="rotated-text" style={{ color: "white", fontWeight: "600" }}>20% OFF</label>
                                    </div>
                                    <div className="" style={{ padding: "10px", width: "100%" }}>
                                        <div className="space-between">
                                            <label className="couponName">FRAMEFREE</label>
                                            <button className="apply-button" style={{ padding: "5px" }}>Apply</button>
                                        </div>
                                        <div>
                                            <label style={{ color: "grey" }}>Save &#x20B9;50 on this order.</label><br />
                                            <label style={{ color: "grey" }}>Use code FRAMEFREE & get flat 20% off on this order.</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="couponBox mt-3">
                                    <div className="couponLeftSide">
                                        <label className="rotated-text" style={{ color: "white", fontWeight: "600" }}>20% OFF</label>
                                    </div>
                                    <div className="" style={{ padding: "10px", width: "100%" }}>
                                        <div className="space-between">
                                            <label className="couponName">FRAMEFREE</label>
                                            <button className="apply-button" style={{ padding: "5px" }}>Apply</button>
                                        </div>
                                        <div>
                                            <label style={{ color: "grey" }}>Save &#x20B9;50 on this order.</label><br />
                                            <label style={{ color: "grey" }}>Use code FRAMEFREE & get flat 20% off on this order.</label>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <hr className="mt-2" />
                            <div className="flex-end">
                                <button className="greenBtn" onClick={closeCouponModal} style={{ width: "20%" }}>Apply</button>
                                <button className="redBtn" onClick={closeCouponModal} style={{ width: "20%" }}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/******************************* Account Master Modal*************************/}
            {AccountMasterModal && (
                <div className="modal-back">
                    <div className="partyDetail-modal" style={{ height: "90%", width: "90%", backgroundColor: "white" }}>
                        <div style={{ padding: "15px" }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <label></label>
                                <label style={{ cursor: "pointer", fontSize: "24px" }} onClick={closeAccountMasterModal}>x</label>
                            </div>
                            <AccountMaster />
                        </div>
                    </div>
                </div>
            )}
            {/******************************* Previous order Modal*************************/}
            <div onClick={toggleSlider} className={`slider-container ${isSliderOpen ? 'open' : ''}`}>
                <div onClick={handleModalClick} style={{ padding: "15px" }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <label></label>
                        <label style={{ cursor: "pointer", fontSize: "24px" }} onClick={toggleSlider}>x</label>
                    </div>
                    <PreviousOrder />
                </div>
            </div>
        </div>
    );
}


export default SaleInvoice;

