import axios from '../axiosConfig'; // Adjust the import path accordingly

const express_server_url = process.env.REACT_APP_SERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);


const LensItemCreation = {

    insertLensItemCreation: async (PowerRangedata) => {
        debugger;
        try {
            const apiUrl = `${WebApiUrl}/api/LensItemCreation/LensItemCreation`;
            console.log(apiUrl);
            const response = await axios.post(apiUrl, {
                //lensPowerArray:processedData,
                lensPowerRang:PowerRangedata
            }, {
                
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return  response.data.isSuccess;
        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },


    // ************************* Get By Id ****************************
    getLensItemCreationById: async (data) => {
        debugger;
        try {
            const apiUrl = `${WebApiUrl}/api/LensItemCreation/GetLensPowers`;
            const response = await axios.post(apiUrl, 
                {
                    itemId: data.ItemId,
                    spH_Min: data.SPH_Min,
                    spH_Max: data.SPH_Max,
                    cyL_Min: data.CYL_Min,
                    cyL_Max: data.CYL_Max,
                    axis: data.Axis,
                    add_Min: data.Add_Min,
                    add_Max: data.Add_Max,
                    eye: data.Eye
                }, 
                {
                    withCredentials: true,
                });
            console.log('Response:', response.data.result);
            return response.data.result;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    GetLensItemMaster: async () => {
        try {
          debugger;
          const apiUrl = `${WebApiUrl}/api/ItemMaster/GetItemMasters`;
          const response = await axios.post(
            apiUrl,
            {
              "filter": [
                {
                "fieldName": "iAttributeParent",
                "operatorName": "equal",
                "compareValue": "0"
                }
              ],
              "order": [
              ],
              "pageNumber": 1,
              "pageSize": 50000
            },
            {
              withCredentials: true,
              credentials: 'include',
            }
          );
          console.log("Response:", response);
          return response.data.result;
        } catch (error) {
          console.error("Error:", error.message);
        }
      },
    


    // ************************* Delete ****************************
    deleteLensItemCreation: async (LensItemId) => {
        try {
            const apiUrl = `${WebApiUrl}/api/LensItemCreation?LensItemId=${LensItemId}`;
            const response = await axios.delete(apiUrl, {
                withCredentials: true,
            });
            console.log('Delete Response:', response.data.result);
            return true;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    // *************************** Update ***************************

    updateLensItemCreation: async (data) => {
        debugger;
        const processedData = data.map(loop => ({
            billSeries: loop.billSeries,  
            assignToShow: loop.assignToShow,    
            accountId: loop.accountId,  
            accountTitle: loop.accountTitle,
        }));

        var LensItemId = processedData[0].billSeries;
        try {
            const apiUrl = `${WebApiUrl}/api/LensItemCreation?LensItemId=${LensItemId}`;
            console.log(apiUrl);
            const response = await axios.put(apiUrl, {
                processedData
            }, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return response.data.isSuccess;
        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },
};

export default LensItemCreation;
