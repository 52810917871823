import DateTime from "../../Components/DateTime";
import PageTabs from "../../Components/PageTabs";
import React, { useState, useEffect, useRef, useContext } from "react";
import 'react-quill/dist/quill.snow.css'
import MainButton from "../../Props/MainButton";
import GodownApi from '../../../API/APIServices/Godown';
import AlertBox from "../../Props/AlertBox";
import MyContext from "../../MyContext";
import Loader from "../../Props/Loader";
import { useNavigate } from 'react-router-dom';

function GodowneMaster(props) {

    const [loading, setLoading] = useState(false);
    const { OpenAlertBox, alertBox, OpenAlertBoxEdit, alertBoxEdit } = useContext(MyContext);
    const navigate = useNavigate();

    useState(() => {
        document.title = 'Godown Master';
    }, []);

    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);
    const input4Ref = useRef(null);
    const input5Ref = useRef(null);
    const input6Ref = useRef(null);
    const input7Ref = useRef(null);

    const handleKeyPress = (event, nextInputRef) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            nextInputRef.current.focus();
        }
    };
    // **********************************************************************
    const [errorMessages, setErrorMessages] = useState({
        name: '',
        stockAmount: '',
        saleAmount: '',
        purchaseAmount: '',
    });

    const [inputValues, setInputValues] = useState({
        name: '',
        printName: '',
        stockAmount: 0,
        isReflectStockInBalanceSheet: false,
        saleAmount: 0,
        purchaseAmount: 0,
        address: '',
        isActive: true,
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        console.log(`Changing ${name} to ${value}`);

        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [name]: value,
            printName: name === 'name' ? value : prevInputValues.printName,
        }));
        setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));
    };

    const handleInputChangeActive = (e) => {
        const { name, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : e.target.value;

        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [name]: type === 'checkbox' ? !prevInputValues[name] : newValue,
        }));

        setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));
        console.log(inputValues);
    };

    const toFriendlyName = (fieldName) => {
        return fieldName
            .replace(/([A-Z])/g, ' $1') // Add space before capital letters
            .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
    };

    const validateInput = (fieldName) => {
        console.log(fieldName);
        const fieldValue = inputValues[fieldName];

        if (!fieldValue || (typeof fieldValue === 'string' && fieldValue.trim() === '')) {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                [fieldName]: `${toFriendlyName(fieldName)} field is required`,
            }));
            return false;
        }

        // If validation passes, clear the error message for the field
        setErrorMessages((prevErrors) => ({ ...prevErrors, [fieldName]: '' }));

        return true;
    };

    const fieldNames = ['name', 'stockAmount', 'saleAmount', 'purchaseAmount'];

    const SaveBtn = async () => {
        let allValid = true;
        fieldNames.forEach((fieldName) => {
            const isValid = validateInput(fieldName);
            if (!isValid) {
                allValid = false;
            }
        });
        if (allValid) {
            setLoading(true);
            if (Boolean(props.modelOpen) === true) {
                const isSuccess = await GodownApi.updateGodown(inputValues);
                if (isSuccess) {
                    OpenAlertBoxEdit();
                    setLoading(false);
                    props.fetchData()
                    props.setSliderCallBack(false);
                } else {
                    alert("error while updating");
                }
            }
            else {
                setLoading(true);
                const isSuccess = await GodownApi.insertGodown(inputValues);
                if (isSuccess) {
                    setLoading(false);
                    OpenAlertBox();
                } else {
                    alert("Error inserting data");
                }
            }
            setInputValues({
                name: '',
                printName: '',
                stockAmount: 0,
                isReflectStockInBalanceSheet: 0,
                saleAmount: 0,
                purchaseAmount: 0,
                address: '',
                isActive: true,
            });
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (props.editData) {
                    try {
                        const data = await props.editData;
                        console.log(data);
                        setInputValues(data);
                    } catch (error) {
                         console.error("Error fetching data:", error);
                    }
                }
            } catch (error) {
               console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [props.editData]);

    return (
        <div>
            < PageTabs locationTab={"/GodownMaster"} modelOpen={props.modelOpen} setSliderCallBack={props.setSliderCallBack} />
            <div className="panel-heading">
                <div className="panel-head-title">
                    <h2 className="panel-title">Godown Master</h2>
                </div >
                <MainButton SaveButton={SaveBtn} modelOpen={props.modelOpen} setSliderCallBack={props.setSliderCallBack} />
            </div>
            <div>
                < DateTime />
            </div>
            <div className="content_box">
                <div className="company-info-top-im">
                    <div className="tab-head">
                        <div id="tabs">
                            <div className="tab-content-box">
                                <div className="tab-content-1">
                                    <div className="company-info-top-left" >
                                        <div className="company_info-text-im td-1">General Details
                                            <div className="input-box-checkbox1 ">
                                                <div className=" td-2">
                                                    <div className="cl-toggle-switch td-3">
                                                        <label className="cl-switch">
                                                            <input type="checkbox" onChange={handleInputChangeActive} name="isActive" checked={inputValues.isActive} value={inputValues.isActive} />
                                                            <span></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="input-box-Im ">
                                                <div className="input-box-m2">
                                                    <input className="modify_im_input mode_category"
                                                        onChange={handleInputChange}
                                                        onKeyPress={(e) => handleKeyPress(e, input2Ref)}
                                                        ref={input1Ref} type="text" required
                                                        value={inputValues.name}
                                                        name="name" />
                                                    <label className="modify_p_text2">Name
                                                        <span className="StarClr">*
                                                            {errorMessages.name && <label className="errorMessage">{errorMessages.name}</label>}
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="input-box-Im ">
                                                <div className="input-box-m2">
                                                    <input className="modify_im_input mode_category"
                                                        onChange={handleInputChange}
                                                        onKeyPress={(e) => handleKeyPress(e, input3Ref)}
                                                        ref={input2Ref} type="text" required
                                                        value={inputValues.printName}
                                                        name="printName" />
                                                    <label className="modify_p_text2">Print Name</label>
                                                </div>
                                            </div>

                                            <div className="input-box-Im ">
                                                <div className="input-box-m2">
                                                    <input className="modify_im_input mode_category"
                                                        onChange={handleInputChange}
                                                        onKeyPress={(e) => handleKeyPress(e, input4Ref)}
                                                        ref={input3Ref}
                                                        type="text" required
                                                        value={inputValues.stockAmount}
                                                        name="stockAmount" />
                                                    <label className="modify_p_text2">Stock Amount
                                                        <span className="StarClr">*
                                                            {errorMessages.stockAmount && <label className="errorMessage">{errorMessages.stockAmount}</label>}
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="input-box-Im ">
                                                <div className="input-box-m2">
                                                    <select className="modify_im_input mode_category"
                                                        onChange={handleInputChange}
                                                        onKeyPress={(e) => handleKeyPress(e, input5Ref)}
                                                        ref={input4Ref}
                                                        type="text" required
                                                        value={inputValues.isReflectStockInBalanceSheet}
                                                        name="isReflectStockInBalanceSheet">
                                                        <option value="false">No</option>
                                                        <option value="true">Yes</option>
                                                    </select>
                                                    <label className="modify_lbl_text3">Reflect the stock in balance sheet</label>
                                                </div>
                                            </div>
                                            <div className="input-box-Im ">
                                                <div className="input-box-m2">
                                                    <input className="modify_im_input mode_category"
                                                        onChange={handleInputChange}
                                                        onKeyPress={(e) => handleKeyPress(e, input6Ref)}
                                                        ref={input5Ref} type="text" required
                                                        value={inputValues.saleAmount}
                                                        name="saleAmount" />
                                                    <label className="modify_p_text2">Sale Amount
                                                        <span className="StarClr">*
                                                            {errorMessages.saleAmount && <label className="errorMessage">{errorMessages.saleAmount}</label>}
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="input-box-Im ">
                                                <div className="input-box-m2">
                                                    <input className="modify_im_input mode_category"
                                                        onChange={handleInputChange}
                                                        onKeyPress={(e) => handleKeyPress(e, input7Ref)}
                                                        ref={input6Ref} type="text" required
                                                        value={inputValues.purchaseAmount}
                                                        name="purchaseAmount" />
                                                    <label className="modify_p_text2">Purchase Amount
                                                        <span className="StarClr">*
                                                            {errorMessages.purchaseAmount && <label className="errorMessage">{errorMessages.purchaseAmount}</label>}
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>

                                        </div>
                                        <div>
                                            <div className="company_info-text-im">Address Details</div>
                                            <div className="input-box-tab-head">
                                                <div className="input-box-m2">
                                                    <textarea className="modify_im_textarea mode_category2"
                                                        onChange={handleInputChange}
                                                        onKeyPress={(e) => handleKeyPress(e, input1Ref)}
                                                        ref={input7Ref} type="text" placeholder="" required
                                                        value={inputValues.address}
                                                        name="address" />
                                                    <label className="modify_p_text-fix">Address</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="buttom-box">
                        <div className="button-btn-1">
                            <button className="btn-1">Save</button>
                        </div>
                        <div className="button-btn-2">
                            <button className="btn-2">Reset</button>
                        </div>
                        <div className="button-btn-3">
                            <button className="btn-3" ><i className="fa-solid fa-gear "></i></button>
                        </div>
                    </div>
                </div>
            </div>
            {alertBox && (
                <AlertBox
                    HeadingTop="Saved"
                    Heading="Your Godown is Saved"
                    onYesClick={OpenAlertBox}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBox}
                />
            )}
            {alertBoxEdit && (
                <AlertBox
                    HeadingTop="Updated"
                    Heading="Your Godown is Updated"
                    onYesClick={OpenAlertBoxEdit}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBoxEdit}
                />
            )}
            {loading && <Loader />}
        </div>
    );
}


export default GodowneMaster;
