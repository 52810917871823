import DateTime from "../../Components/DateTime";
import PageTabs from "../../Components/PageTabs";
import React, { useState, useEffect, useRef, useContext } from "react";
import 'react-quill/dist/quill.snow.css'
import MainButton from "../../Props/MainButton";
import StationApi from '../../../API/APIServices/Station';
import ToolTipMaster from "../../Props/ToolTipMaster";
import AlertBox from "../../Props/AlertBox";
import MyContext from "../../MyContext";
import Loader from "../../Props/Loader";
import { useNavigate } from 'react-router-dom';

function StationMaster(props) {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { OpenAlertBox, alertBox, OpenAlertBoxEdit, alertBoxEdit } = useContext(MyContext);

    useState(() => {
        document.title = 'Station Master';
    }, []);

    const [errorMessages, setErrorMessages] = useState({
        name: '',
    });

    const [inputValues, setInputValues] = useState({
        name: '',
        printName: '',
        isActive: 'true'
    });

    const handleInputChangeActive = (e) => {
        const { name, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : e.target.value;

        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [name]: type === 'checkbox' ? !prevInputValues[name] : newValue,
        }));


        setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));
        console.log(inputValues);
    };

    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);


    const handleKeyPress = (event, nextInputRef) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            nextInputRef.current.focus();
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        console.log(`Changing ${name} to ${value}`);

        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [name]: value,
            printName: name === 'name' ? value : prevInputValues.printName,
        }));
        setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));
    };

    const toFriendlyName = (fieldName) => {
        return fieldName
            .replace(/([A-Z])/g, ' $1') // Add space before capital letters
            .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
    };

    const validateInput = (fieldName) => {
        console.log(fieldName);
        const fieldValue = inputValues[fieldName];

        if (!fieldValue || (typeof fieldValue === 'string' && fieldValue.trim() === '')) {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                [fieldName]: `${toFriendlyName(fieldName)} field is required`,
            }));
            return false;
        }

        return true;
    };
    const fieldNames = ['name', 'printName'];

    const SaveBtn = () => {
        let allValid = true;
        fieldNames.forEach((fieldName) => {
            const isValid = validateInput(fieldName);
            if (!isValid) {
                allValid = false;
            }
        });
        if (allValid) {
            setLoading(true);
            if (Boolean(props.modelOpen) === true) {
                const isSuccess = StationApi.updateStation(inputValues);
                if (isSuccess) {
                    OpenAlertBoxEdit();
                    setLoading(false);
                    props.fetchData()
                    props.setSliderCallBack(false);
                } else {
                    alert("error while updating");
                }
            }
            else {
                setLoading(true);
                const isSuccess = StationApi.insertStation(inputValues);
                if (isSuccess) {
                    setLoading(false);
                    OpenAlertBox();
                } else {
                    alert("Error inserting data");
                }
            }
            setInputValues({
                name: "",
                printName: "",
            });
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (props.editData) {
                    try {
                        const data = await props.editData; // Assuming props.editData is a Promise
                        console.log(data);
                        setInputValues(data);
                    } catch (error) {
                        console.error("Error fetching data:", error);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [props.editData]);

    return (
        <div>
            <PageTabs locationTab={"/StationMaster"} modelOpen={props.modelOpen} setSliderCallBack={props.setSliderCallBack} />
            <div className="panel-heading">
                <div className="panel-head-title">
                    <h2 className="panel-title">Station Master</h2>
                </div >
                {/* *******************Top Buttons****************** */}
                <MainButton SaveButton={SaveBtn} modelOpen={props.modelOpen} setSliderCallBack={props.setSliderCallBack} />
            </div>
            <div>
                <DateTime />
            </div>
            <div className="content_box">
                <div className="company-info-top-im">
                    <div className="tab-head">
                        <div id="tabs">
                            <div className="tab-content-box">
                                <div className="tab-content-1">
                                    <div className="company-info-top-left" >
                                        <div className="company_info-text-im td-1">General Details
                                            <div className="input-box-checkbox1 ">
                                                <div className=" td-2">
                                                    <div className="ToggleSwitchActive td-3">
                                                        <label className="cl-switch">
                                                            <input type="checkbox" onChange={handleInputChangeActive} name="isActive" checked={inputValues.isActive} value={inputValues.isActive} />
                                                            <span></span>
                                                        </label>
                                                    </div>
                                                    <ToolTipMaster isActiveInactive={true} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="input-box-Im ">
                                                <div className="input-box-m2">
                                                    <input className="modify_im_input mode_category"
                                                        onChange={handleInputChange}
                                                        onKeyPress={(e) => handleKeyPress(e, input2Ref)}
                                                        ref={input1Ref}
                                                        value={inputValues.name}
                                                        name="name"
                                                        type="text" required />
                                                    <label className="modify_p_text2">Station Name  <span className="StarClr">*
                                                        {errorMessages.name && <label className="errorMessage">{errorMessages.name}</label>}
                                                    </span></label>
                                                </div>
                                            </div>
                                            <div className="input-box-Im ">
                                                <div className="input-box-m2">
                                                    <input className="modify_im_input mode_category"
                                                        onChange={handleInputChange}
                                                        onKeyPress={(e) => handleKeyPress(e, input1Ref)}
                                                        ref={input2Ref}
                                                        value={inputValues.printName}
                                                        name="printName"
                                                        type="text" required />
                                                    <label className="modify_p_text2">Print Name
                                                        {/* <span className="StarClr">*
                                                            {errorMessages.name && <label className="errorMessage">{errorMessages.name}</label>}
                                                        </span> */}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="buttom-box">
                        <div className="button-btn-1">
                            <button className="btn-1">Save</button>
                        </div>
                        <div className="button-btn-2">
                            <button className="btn-2">Reset</button>
                        </div>
                        <div className="button-btn-3">
                            <button className="btn-3"><i className="fa-solid fa-gear "></i></button>
                        </div>
                    </div>
                </div>
            </div>
            {alertBox && (
                <AlertBox
                    HeadingTop="Saved"
                    Heading="Your Agent is Saved"
                    onYesClick={OpenAlertBox}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBox}
                />
            )}

            {alertBoxEdit && (
                <AlertBox
                    HeadingTop="Updated"
                    Heading="Your Agent is Updated"
                    onYesClick={OpenAlertBoxEdit}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBoxEdit}
                />
            )}
            {loading && <Loader />}
        </div >
    );
}


export default StationMaster;
