import ListSaveButton from "../../Components/ListSaveButton";
import PageTabs from "../../Components/PageTabs";
import React, { useState, useEffect, useContext } from "react";
import TableButton from "../../Props/TableButton";
import MainButton from "../../Props/MainButton";
import TableApi from "../../../API/APIServices/Table";
import TableGroupApi from '../../../API/APIServices/TableGroup';
import TableMaster from "./TableMaster";
import DataTable from "./DataTable";
import MyContext from "../../MyContext";
import AlertBox from "../../Props/AlertBox";
import Loader from "../../Props/Loader";
import { useNavigate } from 'react-router-dom';

function TableMasterList(props) {

    const {
        OpenAlertBoxDelete,
        alertBoxDelete,
        setAlertBoxDelete,
        input1,
        input2,
        input3,
        input4,
        input5,
    } = useContext(MyContext);

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    useState(() => {
        document.title = 'Table Master List';
    }, []);

    const [isModalOpen, setModalOpen] = useState(false);

    const showModal = () => {
        setModalOpen(true);
    };
    const hideModal = () => {
        setModalOpen(false);
    }

    // ********************************************List Part ******************************
    const [data1, setData1] = useState([]);

    const columns = [
        { Header: <input type="checkbox" />, accessor: "input" },
        { Header: "SNO", accessor: "sno" },
        { Header: "TABLE GROUP", accessor: "tableGroup" },
        { Header: "TABLE NAME", accessor: "tableName" },
        { Header: "TABLE NUMBER", accessor: "tableNumber" },
        { Header: "TABLE PRIORITY", accessor: "tablePriority" },
        { Header: "TABLE CAPACITY", accessor: "tableCapacity" },
        { Header: "ACTION", accessor: "action" },
    ];

    const filterData = [
        { Header: "", accessor: "" },
        { Header: "", accessor: "" },
        { Header: <input type="text" placeholder="Table Group" ref={input1} />, accessor: "TableGroup" },
        { Header: <input type="text" placeholder="Table Name" ref={input2} />, accessor: "TableName" },
        { Header: <input type="text" placeholder="Table Number" ref={input3} />, accessor: "TableNumber" },
        { Header: <input type="text" placeholder="Table Priority" ref={input4} />, accessor: "TablePriority" },
        { Header: <input type="text" placeholder="Table Capacity" ref={input5} />, accessor: "TableCapacity" },
    ];

    const fetchData = async () => {
        setLoading(true);
        try {
            const val = await TableApi.getTableFilter();
            console.log(val.length);

            const newData = val.map((item, i) => {
                return {
                    input: <input type="checkbox" />,
                    sno: i + 1,
                    tableGroup: item.tableGroup,
                    tableName: item.tableName,
                    tableNumber: item.tableNumber,
                    tablePriority: item.tablePriority,
                    tableCapacity: item.tableCapacity,
                    action: (
                        <div className="tablelist-action-box">
                            <i className="fa-regular fa-pen-to-square TableEditIcon" onClick={() => editTable(item.id)}></i>
                            <i className="fa-regular fa-trash-can TableDelIcon" onClick={() => DeleteTable(item.id)}></i>
                            <i className="fa-brands fa-square-whatsapp TableWhatsappcon"></i>
                            <i className="fa-solid fa-print TablePrint"></i>
                        </div>
                    ),
                };
            });
            setData1(newData);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const [editData, setEditData] = useState([]);
    const [editedDataModal, setEditedDataModal] = useState(false);

    const editTable = async (id) => {
        const dataById = TableApi.getTableById(id);
        console.log(dataById);
        setEditData(dataById);
        setEditedDataModal(true);
        showModal();
    }
    const [getdeleteId, setDeleteId] = useState(null);

    const DeleteTable = (id) => {
        setAlertBoxDelete(true);
        setDeleteId(id);
    }
    const DeleteTableConfirm = async () => {
        setLoading(true);
        try {
            const val = await TableApi.deleteTable(getdeleteId);
            if (Boolean(val) === true) {
                setAlertBoxDelete(false);
                setDeleteId(null);
                setLoading(false);
            } else {
                alert("Error Deleting Data");
            }
            fetchData();
        } catch (error) {
            console.error("Error deleting data:", error);
        }
    };

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'F2') {
                showModal();
            }
            if (event.key === 'Escape') {
                if (isModalOpen === true) {
                    hideModal();
                }
                else {
                    window.history.back();
                }
            }
        };
        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    });

    return (
        <div>
            {/* <PageTabs locationTab={"/TableMaster"} /> */}
            <div className="nav-1">
                <div className="panel-heading">
                    <h2 className="panel-title">Table Master List</h2>
                    <TableButton showModal={showModal} PageCreate={showModal} filterData={filterData} columns={columns} data={data1} />
                </div>
                <div className="panel panel-default">
                    <div className="col-sm-12">
                        <div className="table-resposive" style={{ height: 75, width: '100%' }}>
                            <DataTable filterData={filterData} columns={columns} data={data1} />
                        </div>
                    </div>
                </div>
            </div>
            {isModalOpen && (
                <div className="modal">
                    <div className="modalContentTable">
                        <div className="modal-title">
                            <h3 className="modalTableTitleColor"> </h3>
                            <span className="closeTableModal" onClick={hideModal}>
                                &times;
                            </span>
                        </div>
                        <TableMaster editData={editData} editedDataModal={editedDataModal} hideModal={hideModal} fetchData={fetchData} />
                        {/* <div>
                            <PageTabs />
                            <div className="panel-heading">
                                <div className="panel-head-title">
                                    <h2 className="panel-title">Add Table Master</h2>
                                </div >
                                <MainButton SaveButton={SaveBtn} hideModal={hideModal} />
                            </div>
                            <div className="content_box">
                                <div className="company-info-top-im">
                                    <div className="tab-head">
                                        <div id="tabs">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >
                        <div className="modal-content-im">
                            <div className="table_main_01">
                                <div className="input-box-Im ">
                                    <div className="input-box-m2">
                                        <input
                                            className="modify_im_input mode_category" type="text"
                                            onChange={handleGroupNameChange}
                                            required />
                                        <label className="modify_p_text2">Group Name</label>
                                    </div>
                                </div>
                                <div className="input-box-Im ">
                                    <div className="input-box-m2">
                                        <input className="modify_im_input mode_category"
                                            onChange={handleTableNumberChange}
                                            type="text" required />
                                        <label className="modify_p_text2">Table Number</label>
                                    </div>
                                </div>
                                <div className="input-box-Im ">
                                    <div className="input-box-m2">
                                        <input className="modify_im_input mode_category"
                                            onChange={handleTableNameChange}
                                            type="text" required />
                                        <label className="modify_p_text2">Table Name</label>
                                    </div>
                                </div>
                                <div className="input-box-Im ">
                                    <div className="input-box-m2">
                                        <input className="modify_im_input mode_category"
                                            onChange={handlePriorityChange}
                                            type="number" required />
                                        <label className="modify_p_text2">Priority</label>
                                    </div>
                                </div>
                                <div className="input-box-Im ">
                                    <div className="input-box-m2">
                                        <input className="modify_im_input mode_category"
                                            onChange={handleCapacityChange}
                                            type="number" required />
                                        <label className="modify_p_text2">Capacity</label>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            )}
            {/* ****************************************** Mobile View ************************************** */}
            <div className="wrapper">
                <div className="container">
                    <div className=" content-row-1">
                        <div className="class_control">
                            <div className="balance-1">
                                <div className="p-11">
                                    <p className="balance-p1"><i className="fa-solid fa-arrow-up icon_3"></i>Sale</p>
                                </div>
                                <div className="p-12">
                                    <p className="balance-p2"><i className="fa-solid fa-indian-rupee-sign rs_sign"></i>100</p>
                                </div>
                            </div>
                            <div className="balance-1">
                                <div className="p-11">

                                    <p className="balance-p1"> <i className="fa-solid fa-clipboard icon_3"></i> Total</p>
                                </div>
                                <div className="p-12">
                                    <p className="balance-p2"><i className="fa-solid fa-indian-rupee-sign rs_sign"></i>200</p>
                                </div>
                            </div>
                            <div className="balance-1">
                                <div className="p-11">
                                    <p className="balance-p1"><i className="fa-solid fa-arrow-down icon_3"></i>Return</p>
                                </div>
                                <div className="p-12">
                                    <p className="balance-p2"><i className="fa-solid fa-indian-rupee-sign rs_sign"></i>100</p>
                                </div>
                            </div>
                        </div>
                        <div className="class_control_2">
                            <div className="parti_1">
                                <div className="sub_parti_1">
                                    <p className="parti-p-1">Parties</p>
                                </div>
                            </div>
                            <div className="parti_1">
                                <div className="sub_parti_1">
                                    <p className="parti-p-1">Trasaction</p>
                                </div>
                            </div>
                            <div className="parti_1">
                                <div className="sub_parti_1">
                                    <p className="parti-p-1">Items</p>
                                </div>
                            </div>
                        </div>
                        <div className="class_control_4">
                            <div className="col_ms_1">
                                <p className="p_13" >Recent History</p>
                                <div className="con_input_1">
                                    <input type="text" className="input_box_2" placeholder="Search..." />
                                </div>
                            </div>
                            <div className="col_sm_11">
                                <div className="col_sm_21">
                                    <div className="col_sm_12">
                                        <div className="con_name_1">
                                            <p className="con_p_3">Akash kumar</p>
                                        </div>
                                        <div className="con_name_1">
                                            <p className="con_p_1">Sale</p>
                                        </div>
                                        <div className="con_name_1">
                                            <p className="con_p_2">Total</p>
                                        </div>
                                        <div className="con_name_1">
                                            <p className="con_p_4"><i className="fa-solid fa-indian-rupee-sign rs_sign1"></i>1000</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col_sm_13">
                                    <div className="col_sm_12">
                                        <div className="con_total_3">
                                            <p className="con_p_3">Balance</p>
                                        </div>
                                        <div className="con_total_3">
                                            <p className="con_price_p"><i className="fa-solid fa-indian-rupee-sign rs_sign1"></i>1000</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col_sm_14">
                                    <div className="col_sm_12">
                                        <div className="con_sale_2">
                                            <p className="con_p_3">Date</p>
                                        </div>
                                        <div className="con_sale_2">
                                            <p>#1</p>
                                        </div>
                                        <div className="con_name_1 con_name_7">
                                            <i className="fa-solid fa-print"></i>
                                            <i className="fa-solid fa-share"></i>
                                            <i className="fa-solid fa-ellipsis-vertical"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <ListSaveButton />
                        </div>
                    </div>
                </div>
            </div>
            {/* ********************************* */}
            {alertBoxDelete && (
                <AlertBox
                    HeadingTop="DELETE"
                    Heading="Are You sure you want to delete this"
                    OpenAlertBox={OpenAlertBoxDelete}
                    onYesClick={DeleteTableConfirm}
                />
            )}
            {loading && <Loader />}
        </div>
    );
}

export default TableMasterList;
