import AccountCategoryMaster from "../Master/AccountMaster/AccountCategoryMaster";
import AccountGroupMaster from "../Master/AccountMaster/AccountGroupMaster";
import DepartmentDetails from "../Master/Userdetails/DepartmentMaster";
import MiscellaneousMaster from "../Master/AccountMaster/MiscellaneousMaster";
import StationMaster from "../Master/AccountMaster/StationMaster";
import ItemGroupMaster from "../Master/InvertoryMaster/ItemGroupMaster";
import UnitMaster from "../Master/InvertoryMaster/UnitMaster";
import DesignationDetails from "../Master/Userdetails/DesignationMaster";
import ItemDepartmentMaster from "../Master/Userdetails/ItemDepartmentMaster";
import MealCourseMaster from "../ModalBox/MealCourseMaster";
import TransportMaster from '../Master/AccountMaster/TransportMaster';
import IncentiveMaster from "../Master/Userdetails/IncentiveMaster";

function ModalBoxMaster({ handleModalClick, hideModal3, isModalStationMaster, isModalOpen2, hideModal2, hideTransporter, transportModal,
    hideModalDepartmentDetails, isModalOpenDepartmentDetails,
    hideModalDesignationDetails, isModalOpenDesignationDetails, hideModalAccCategoryMaster,
    isModalOpenAccCategoryMaster, hideMiscellaneous, isModalMiscellaneous,
    hideUnit, isModalOpenUnit, hideGroupName, isModalOpenGroupName, showGroupName,
    hideDepartment, isDepartment,
    hideMeal, isMealCourse, controlType, deliveryIncentiveModal, hideDeliveryIncentive
}) {

    return (
        <div >
            {/* *****************************{Station Master}****************************** */}
            {isModalStationMaster && (
                <div onClick={hideModal3} className="modal-back">
                    <div onClick={handleModalClick} style={{ zIndex: "5" }} className="OpticalField-modal increase_modal">
                        <div style={{ padding: "15px" }}>
                            <div className="Modal-Display">
                                <label className="close_modal" onClick={hideModal3} >x</label>
                            </div>
                            <hr />
                            <StationMaster />
                        </div>
                    </div>
                </div>
            )}
            {/* *****************************{Account Group Master}****************************** */}
            {isModalOpen2 && (
                <div onClick={hideModal2} className="modal-back">
                    <div onClick={handleModalClick} style={{ zIndex: "5" }} className="OpticalField-modal increase_modal">
                        <div style={{ padding: "15px" }}>
                            <div className="Modal-Display">
                                <label className="close_modal" onClick={hideModal2} >x</label>
                            </div>
                            <hr />
                            <AccountGroupMaster />
                        </div>
                    </div>
                </div>
            )}
            {/******************************{Department Details}*******************************/}
            <div>
                {isModalOpenDepartmentDetails && (
                    <div onClick={hideModalDepartmentDetails} className="modal" >
                        <div onClick={handleModalClick} className="OpticalField-modal increase_modal">
                            <div className="modal-title">
                                <span className="close_modal" onClick={hideModalDepartmentDetails}>
                                    &times;
                                </span>
                            </div>
                            <div className="">
                                <div className="tab-content-modal">
                                    <div className="company-info-top-modal control_flex_modal item_scroll">
                                        <DepartmentDetails />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {/* *****************************{Designation Details}****************************** */}

            <div>
                {isModalOpenDesignationDetails && (
                    <div onClick={hideModalDesignationDetails} className="modal" >
                        <div onClick={handleModalClick} className="OpticalField-modal increase_modal">
                            <div className="modal-title">
                                <span className="close_modal" onClick={hideModalDesignationDetails}>
                                    &times;
                                </span>
                            </div>
                            <div className="">
                                <div className="tab-content-modal">
                                    <div className="company-info-top-modal control_flex_modal item_scroll">
                                        <DesignationDetails />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {/* *****************************{Account Category Master}****************************** */}

            <div>
                {isModalOpenAccCategoryMaster && (
                    <div onClick={hideModalAccCategoryMaster} className="modal-back">
                        <div onClick={handleModalClick} className="OpticalField-modal increase_modal">
                            <div style={{ padding: "15px" }}>
                                <div className="Modal-Display">
                                    <label className="close_modal" onClick={hideModalAccCategoryMaster} >x</label>
                                </div>
                                <hr />
                                <AccountCategoryMaster />
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {/* *****************************{Station Master}****************************** */}

            {isModalMiscellaneous && (
                <div onClick={hideMiscellaneous} className="modal-back">
                    <div onClick={handleModalClick} className="OpticalField-modal membercard_modal">
                        <div style={{ padding: "15px" }}>
                            <div className="Modal-Display">
                                <label className="close_modal" onClick={hideMiscellaneous} >x</label>
                            </div>
                            <hr />
                            <MiscellaneousMaster controlType={controlType} />
                        </div>
                    </div>
                </div>
            )}

            {/* *****************************{Station Master}****************************** */}

            {isModalOpenUnit && (
                <div onClick={hideUnit} className="modal" >
                    <div onClick={handleModalClick} className="OpticalField-modal increase_modal">
                        <div className="modal-title">
                            <span className="close_modal" onClick={hideUnit}>
                                &times;
                            </span>
                        </div>
                        <div className="">
                            <div className="tab-content-modal">
                                <div className="company-info-top-right control_flex_m1 item_scroll">
                                    <UnitMaster />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* *****************************{Item Group Master1}****************************** */}

            {isModalOpenGroupName && (
                <div onClick={hideGroupName} className="modal" >
                    <div onClick={handleModalClick} className="OpticalField-modal increase_modal">
                        <div className="modal-title">
                            {/* <h3 className="modal-box-m1">Add New Category</h3> */}
                            <span className="close_modal" onClick={hideGroupName}>
                                &times;
                            </span>
                        </div>
                        <div className="">
                            <div className="tab-content-modal">
                                <div className="company-info-top-modal control_flex_modal item_scroll">
                                    {/* <ItemGroupMaster /> */}
                                    <ItemGroupMaster />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* *****************************{Station Master}****************************** */}
            {isDepartment && (
                <div onClick={hideDepartment} className="modal" >
                    <div onClick={handleModalClick} className="OpticalField-modal increase_modal">
                        <div className="modal-title">
                            {/* <h3 className="modal-box-m1">Add New Category</h3> */}
                            <span className="close_modal" onClick={hideDepartment}>
                                &times;
                            </span>
                        </div>
                        <div className="">
                            <div className="tab-content-modal">
                                <div className="company-info-top-modal control_flex_modal item_scroll">
                                    <ItemDepartmentMaster />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* *****************************{Meal Course Master}****************************** */}

            {isMealCourse && (
                <div onClick={hideMeal} className="modal" >
                    <div onClick={handleModalClick} className="OpticalField-modal increase_modal">
                        <div className="modal-title">
                            {/* <h3 className="modal-box-m1">Add New Category</h3> */}
                            <span className="close_modal" onClick={hideMeal}>
                                &times;
                            </span>
                        </div>
                        <div className="">
                            <div className="tab-content-modal">
                                <div className="company-info-top-modal control_flex_modal item_scroll">
                                    <MealCourseMaster />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}


            {/* *****************************{Transport Master}****************************** */}
            {transportModal && (
                <div onClick={hideTransporter} className="modal-back">
                    <div onClick={handleModalClick} style={{ zIndex: "5" }} className="OpticalField-modal increase_modal">
                        <div style={{ padding: "15px" }}>
                            <div className="Modal-Display">
                                <label className="close_modal" onClick={hideTransporter} >x</label>
                            </div>
                            <hr />
                            <TransportMaster />
                        </div>
                    </div>
                </div>
            )}
            {/* *****************************{Incentive Master}****************************** */}
            {deliveryIncentiveModal && (
                <div onClick={hideDeliveryIncentive} className="modal-back">
                    <div onClick={handleModalClick} style={{ zIndex: "5" }} className="OpticalField-modal increase_modal">
                        <div style={{ padding: "15px" }}>
                            <div className="Modal-Display">
                                <label className="close_modal" onClick={hideDeliveryIncentive} >x</label>
                            </div>
                            <hr />
                            <IncentiveMaster/>
                        </div>
                    </div>
                </div>
            )}

            {/* *****************************{Station Master}****************************** */}

            {/* *****************************{Station Master}****************************** */}

            {/* *****************************{Station Master}****************************** */}
        </div>
    )
}

export default ModalBoxMaster;