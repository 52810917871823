import PageTabs from "../../Components/PageTabs";
import TableButton from "./../../Props/TableButton";
import APIConfigurationApi from "../../../API/APIServices/APIConfiguration";
import { useContext, useEffect, useState } from "react";
import DataTable from "../../Master/TableMaster/DataTable";
import APIConfigurationMaster from "./APIConfigurationMaster";
import MyContext from "../../MyContext";
import AlertBox from "../../Props/AlertBox";
import Loader from "../../Props/Loader";
// import Loader from "../../Props/Loader";
// import User from "../../../API/APIServices/User";

function APIConfigurationMasterList(props) {
  const {
    OpenAlertBoxDelete,
    alertBoxDelete,
    setAlertBoxDelete,
    input1,
    input2,
    input3,
    input4,
    input5,
  } = useContext(MyContext);

  const [loading, setLoading] = useState(true);
  
  const [mainData, setMainData] = useState([]);

  const [isSliderOpen, setSliderOpen] = useState(false);
  const [controlType, setControlType] = useState(78);

  // console.log(controlType)

  useEffect(() => {
    setControlType(controlType)
}, [controlType]);

  const changetablevalue = (e) => {
    setLoading(true);
    const value = e.target.value;
    console.log(value);
    setControlType(value);
    fetchData(value);
  };

  const toggleSlider = (value) => {
    // console.log(editData);
    setSliderOpen(value);
  };

  const fetchData = async (controlTypevalue) => {
    //
    try {
      const val = await APIConfigurationApi.getAPIConfiguration(controlTypevalue);
      const newData = val.map((item, i) => {
        return {
          input: <input type="checkbox" />,
          sno: i + 1,
          apiType: item.configType,
          formateName: item.formateName,
          workingKey: item.workingKey,
          accessCode: String(item.accessCode),
          isDefault: Boolean(item.isDefault),
          isActive: Boolean(item.isActive),

        //   parentGroupName: item.parentGroupName === null ? "-----" : item.parentGroupName,
        //   groupTypeName: item.groupTypeName === null ? "-----" : item.groupTypeName,
          action: (
            <div className="tablelist-action-box">
              <i
                className="fa-regular fa-pen-to-square TableEditIcon"
                onClick={() => EditAccount(item.id)}
              ></i>
              <i
                className="fa-regular fa-trash-can TableDelIcon"
                onClick={() => DeleteAccount(item.id)}
              ></i>
              <i className="fa-brands fa-square-whatsapp TableWhatsappcon"></i>
              <i className="fa-solid fa-print TablePrint"></i>
            </div>
          ),
        };
      });
      setMainData(newData);
      setLoading(false);
      // console.log(val);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData(controlType);
  }, []);

  const [getdeleteId, setDeleteId] = useState(null);

  const DeleteAccount = (id) => {
    // console.log("delete" ,id)
    setAlertBoxDelete(true);
    setDeleteId(id);
  };

  const DeleteAccountConfirm = async () => {
    setLoading(true);
    // console.log("Deleting row with ID:", getdeleteId);
    try {
      const val = await APIConfigurationApi.deleteAPIConfiguration(getdeleteId);
      if (Boolean(val) === true) {
        // alert("Delete data successfully", "Ok");
        setAlertBoxDelete(false);
        setDeleteId(null);
        setLoading(false);
      } else {
        alert("Error Deleting Data");
      }
      fetchData();
    } catch (error) {
      console.error("Error deleting account:", error);
    }
  };

  //  const[data , setData] = useState({})
  const [editData, setEditData] = useState("");
  const [editData1, setEditData1] = useState("");


  const EditAccount = async (id) => {
    //  debugger;
    try {
    const data = await APIConfigurationApi.getAPIConfigurationById(id);
    setEditData(data);

    const data1 = await APIConfigurationApi.getApiParameterNameAndValue(data.configType, id);
    setEditData1(data1);
    
    toggleSlider(true);

    }catch (error) {
    console.error("Error fetching data:", error);
  }

  };

  const columns = [
    { Header: "", accessor: "input" },
    { Header: "SNO", accessor: "sno" },
    { Header: "API Type", accessor: "apiType" },
    { Header: "Format Name", accessor: "formateName" },
    { Header: "Working Key", accessor: "workingKey" },
    { Header: "Access Code", accessor: "accessCode" },
    // { Header: "Group Type Name", accessor: "groupTypeName" },
    { Header: "Action", accessor: "action" },
  ];

  const filterData = [
    { Header: "", accessor: "" },
    {
      Header: (
        // <input type="text" placeholder="Name" ref={input1} />
        <select onChange={(e) => changetablevalue(e)} value={controlType}>
          <option value="62">SMS</option>
          <option value="78">Email</option>
          <option value="59">Whatsapp</option>
          <option value="60">Payment Gateway</option>
          <option value="61">Google Map</option>
        </select>
      ),
      accessor: "Name",
    },
    {
      Header: <input type="text" placeholder="API Type" ref={input1} />,
      accessor: "apiType",
    },
    {
      Header: <input type="text" placeholder="Format Name" ref={input2} />,
      accessor: "formateName",
    },
    {
      Header: <input type="text" placeholder="Working Key" ref={input3} />,
      accessor: "workingKey",
    },
    {
      Header: <input type="text" placeholder="Access Code" ref={input4} />,
      accessor: "accessCode",
    }, 
  ];
  return (
    <>
      <div className="">
        <PageTabs modelOpen={true} setSliderCallBack={setSliderOpen} locationTab={"/APIConfigurationMaster"} />
        <div className="nav-1">
          <div className="panel-heading">
            <h2 className="panel-title">API Configuration Master List</h2>
            <TableButton
              PageCreate={"/APIConfigurationMaster"}
              columns={columns}
              data={mainData}
              filterData={filterData}
            />
          </div>
        </div>
        <DataTable columns={columns} data={mainData} filterData={filterData} />
      </div>

      <div className={`slider-container100 ${isSliderOpen ? "open" : ""}`}>
        <div style={{ padding: "15px" }}>
          <APIConfigurationMaster
            data={editData}
            data1={editData1}
            modelOpen={true}
            setSliderCallBack={setSliderOpen}
            fetchData={fetchData}
           
          />
        </div>
      </div>
      {alertBoxDelete && (
        <AlertBox
          HeadingTop="DELETE"
          Heading="Are You sure you want to delete this"
          OpenAlertBox={OpenAlertBoxDelete}
          onYesClick={DeleteAccountConfirm}
        />
      )}
      {loading && <Loader />}
    </>
  );
}

export default APIConfigurationMasterList;
