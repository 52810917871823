import React, { useEffect, useState } from "react";
import ImageUploadWithPreview from "../../Components/ImagePreview";
import ItemMasterApi from "../.././../API/APIServices/ItemMaster";
import Loader from "../../Props/Loader";

const ProductVariation = (props) => {
  const {
    handleKeyPress,
    ItemMasterActive,
    isModalOpen2,
    hideModal2,
    numVariations,
    closeNewCategoryModal,
    handleSubmit,
    handleClick,
    inputRef,
    input133Ref,
    input134Ref,
    input135Ref,
    input136Ref,
    input137Ref,
    input138Ref,
    input139Ref,
    input140Ref,
    input141Ref,
    input142Ref,
    input143Ref,
    input144Ref,
    input145Ref,
    input146Ref,
    input147Ref,
    input148Ref,
    input149Ref,
    input150Ref,
    input151Ref,
    input152Ref,
    input153Ref,
    input154Ref,
    input155Ref,
    input156Ref,
    input157Ref,
    ItemMasterList
  } = props;
  const [imageIds, setImageIds] = useState([]);
  const [ItemImage, setItemImage] = useState([]);

  useEffect(() => {
    console.log("imageIds", imageIds);
    console.log("ItemImage", ItemImage);
  }, [ItemImage, imageIds])

  const ItemImageUpload = (e, indx) => {
    const newItemImage = [...ItemImage];
    // If indx is within bounds of the array, update it; otherwise, push it to the end
    if (indx >= 0 && indx < newItemImage.length) {
      newItemImage[indx] = e;
    } else {
      newItemImage.push(e);
    }
    setItemImage(newItemImage);
  };


  const upload_image = async (Id) => {
    debugger;
    const formData = new FormData();

    ItemImage.forEach((file) => {
      formData.append(`files`, file);
    });

    //formData.append(`files`, ItemImage);
    formData.append(`BranchId`, 1);
    formData.append(`D1ItemId`, Id);
    // console.log(formData);

    try {
      const imageUpload = await ItemMasterApi.UpdateItemMasterImage(formData);
      console.log(imageUpload);

      if (imageUpload) {
        console.log("image uploaded successfully");
      }
    } catch (error) {
      console.error("Error uploading images:", error);
    }
  };





  const [loading, setLoading] = useState(false);
  const [variationValues, setVariationValues] = useState({
    d1_Id: "",
    d2_Id: "",
    productId: "",
    name: "",
    sku: "",
    productNameDescription: "",
    stockCheck: 0,
    qty: 0,
    availableQty: 0,
    price: 0,
    oldPrice: 0,
    heightTitle: "",
    height: 0,
    widthTilte: "",
    width: 0,
    weightTitle: "",
    weight: 0,
    lengthTitle: "",
    length: 0,
    selectImage: "",
    showInHome: "",
    specifyIndexNo: 0,
    priorityPopulerCategory: 0,
    published: ""
  });


  const [itemToShow, setItemToShow] = useState([]);
  const [ItemMaster, setItemMaster] = useState([]);
  const [ItemVariation, setVariation] = useState([]);
  console.log(itemToShow);


  useEffect(() => {
    const fetchItemData = async () => {
      var list = await ItemMasterApi.EcomItemsVariation(props.data.d1_Id);
      if (list.length > 0) {
        setVariation(list);
        setLoading(false);
      }
    }
    setLoading(false);
    fetchItemData()


    const fetchAttributeData = async () => {
      setLoading(true);
      if (props.data.iAttributeParent === 0) {
        const filteredData = [
          {
            fieldName: "attributeParentId",
            operatorName: "equal",
            compareValue: props.data.d2_Id,
          },
        ];
        var list = await ItemMasterApi.GetItemMasterWithFilter(filteredData);
        if (list) {
          setItemMaster(list);
          setLoading(false);
        }
        setLoading(false);
      } else {
        const filteredData = [
          {
            fieldName: "attributeParentId",
            operatorName: "equal",
            compareValue: props.data.iAttributeParent,
          },
        ];
        var list = await ItemMasterApi.GetItemMasterWithFilter(filteredData);
        if (list) {
          setItemMaster(list);
          setLoading(false);
        }
        setLoading(false);
      }
    };
    setLoading(false);
    fetchAttributeData();
  }, []);


  useEffect(() => {
    let variationIds = ItemVariation.filter(variation => variation.useForVariation == true).map(variations => variations.d1_Id);
    let filteredItems = ItemMaster.filter(item => variationIds.includes(item.d1Id));
    setItemToShow(filteredItems)
  }, [ItemVariation, ItemMaster])

  const EditItem = async (id) => {
    setLoading(true);
    const data = await ItemMasterApi.GetItemMasterById(id);
    if (data) {
      setImageIds(data.images);

      setVariationValues({
        d1_Id: data.d1_Id || "",
        d2_Id: data.d2_Id || "",
        productId: data.d2_Id || "",
        name: data.productName || "",
        sku: data.sku || "",
        productNameDescription: data.sP_ProductNameDescription || "",
        stockCheck: data.stockCheck || "",
        qty: data.openingStock || "",
        availableQty: data.availableQuantity || "",
        price: data.salePrice || "",
        oldPrice: data.mrpPrice || "",
        heightTitle: data.heightTitle || "",
        height: data.height || "",
        widthTilte: data.widthTilte || "",
        width: data.width || "",
        weightTitle: data.weightTitle || "",
        weight: data.weight || "",
        lengthTitle: data.lengthTitle || "",
        length: data.length || "",
        selectImage: data.selectImage || "",
        showInHome: data.sP_ShowInMenu || "",
        specifyIndexNo: data.specifyIndexNo || "",
        priorityPopulerCategory: data.popularCategoryPriority || "",
        published: data.published || ""
      });
    }
    setLoading(false);
  };

  const DeleteItem = (id) => {
    alert(id);
  };

  const handleInputForVariation = (event) => {
    console.log(event.target.value);
    const { name, value } = event.target;
    console.log(`Changing ${name} to ${value}`);

    setVariationValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const saveItemVariationProduct = () => {
    var data = ItemMasterApi.EcomItemsVariationDetails(variationValues);
    if (data) {
      upload_image(variationValues.d1_Id)
      setItemImage([])
      alert("saved");
    } else {
      alert("error");
    }
  };

  return (
    <div>
      <div className="company-info-top-left-tab3" style={{ border: "none" }}>
        <div className="company_info-text"></div>
        <div className="">
          <div className="input-box-Im"></div>
          <div className="tabs-col-sm-1">
            <div className="table-resposive">
              <table
                style={{ margin: "auto" }}
                border={1}
                id="tblTransactionList"
                className="tabs-table table-bordered1"
                frame="box"
              >
                <thead
                  className="ProductAttributeTabHead"
                  style={{ backgroundColor: "e5f2ff" }}
                >
                  <tr className="variationtable-tr">
                    <th>S.No.</th>
                    <th>Product Name</th>
                    <th>Product Id</th>
                    <th>QTY</th>
                    <th>Price</th>
                    <th>Old Price</th>
                    <th>Select</th>
                  </tr>
                </thead>
                <tbody>
                  {itemToShow.length === 0 ? (
                    <tr>
                      <td
                        style={{
                          textAlign: "center",
                          fontSize: "16px",
                          padding: "10px",
                        }}
                        colSpan="7"
                      >
                        No attribute found
                      </td>
                    </tr>
                  ) : (
                    itemToShow.map((item, index) => (
                      <tr key={index} className="variationtable-tr">
                        <td>{index + 1}</td>
                        <td>{item.name}</td>
                        <td>#{item.d2Id}</td>
                        <td>{item.openingStock}</td>
                        <td>{item.mrpPrice}</td>
                        <td>{item.salePrice}</td>
                        <td>
                          <div className="tablelist-action-box">
                            <i
                              className="fa-regular fa-pen-to-square TableEditIcon"
                              onClick={() => EditItem(item.d2Id)}
                            ></i>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {isModalOpen2 && (
            <div className="modal">
              <div className="modal-content-attribute">
                <div className="modal-title">
                  <h3 className="modal-box-m1">Item Attribute</h3>
                  <span className="close-attribute" onClick={hideModal2}>
                    &times;
                  </span>
                </div>
                <div className="modal-content-im">
                  <div className="tabs-col-sm-1">
                    <div className="table-resposive">
                      <div className="table-attribative">
                        <div className="table-attribute-h1">
                          <h4 className="table-attribute-h3">
                            Enter Item Variations
                          </h4>
                        </div>
                        <div className="table-attribute-h4">
                          <input
                            onChange={handleInputForVariation}
                            ref={inputRef}
                            className="table-data-iv"
                            type="number"
                            value={numVariations}
                            // onChange={handleNumVariationsChange}
                            onClick={handleClick}
                          />
                        </div>
                      </div>
                      <div className="scroller">
                        <table
                          id="tblTransactionList"
                          className="tabs-table table-bordered1 table-modal"
                          frame="box"
                        >
                          <thead
                            style={{
                              textAlign: "center",
                              backgroundColor: "#e5f2ff",
                            }}
                          >
                            <tr>
                              <th>SrNo</th>
                              <th>Product Name</th>
                              <th>SKU</th>
                              <th>Qty</th>
                              <th>Price</th>
                              <th>Old Price</th>
                              <th>Select</th>
                            </tr>
                          </thead>
                          <tbody></tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="modal-input-button">
                    <div className="modal-b1">
                      <button className="save_btn" onClick={handleSubmit}>
                        Submit
                      </button>
                    </div>
                    <div className="">
                      <button
                        className="reset_btn"
                        onClick={closeNewCategoryModal}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="ButtonTabSave">
                <button
                  id="SaveBtnMain"
                  className={`save-button-main-save hovered`}
                >
                  Save/F10
                  <i
                    id="step-2"
                    className="fa-regular fa-floppy-disk im-icon-white"
                  ></i>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="tab-content-1">
        <div className="company-info-top-left">
          {/* *********************** {General Details} ********************** */}
          <div className="company_info-text-im gd-1">General Details</div>
          {ItemMasterActive && (
            <div className="">
              <div className="HeadContentTitle">
                {/* <div className="input-box-m11">
                  <select
                    name="AttributeValue"
                    value={variationValues.AttributeValue}
                    onChange={handleInputForVariation}
                    className="modify_im_input mode_category"
                    ref={input133Ref}
                    onKeyDown={(event) => handleKeyPress(event, input134Ref)}
                    type="text"
                    required
                  >
                    <option value="">--Select--</option>
                  </select>
                  <label className="modifyTextLabel5">
                    {" "}
                    Select Attribute Value *
                  </label>
                </div> */}

                <div className="input-box-m11">
                  <select
                    name="published"
                    value={variationValues.published}
                    onChange={handleInputForVariation}
                    className="modify_im_input mode_category"
                    ref={input133Ref}
                    onKeyDown={(event) => handleKeyPress(event, input134Ref)}
                    type="text"
                    required
                  >
                    <option value="">--Select--</option>
                    <option value="true">True</option>
                    <option value="false">False</option>
                  </select>
                  <label className="modifyTextLabel5">
                    {" "}
                    Published
                  </label>
                </div>

                <div className="input-box-m11 input-height">
                  <input
                    onChange={handleInputForVariation}
                    value={variationValues.d2_Id}
                    name="ProductId"
                    className="modify_im_input mode_category"
                    ref={input134Ref}
                    onKeyDown={(event) => handleKeyPress(event, input135Ref)}
                    type="text"
                    required
                  />
                  <label className="modifyTextLabel">
                    Product Id&nbsp;
                    <span className="StarClr">*</span>
                  </label>
                </div>

                <div className="input-box-m11 input-height ">
                  <input
                    onChange={handleInputForVariation}
                    value={variationValues.name}
                    name="name"
                    className="modify_im_input mode_limit mode_category"
                    ref={input135Ref}
                    onKeyDown={(event) => handleKeyPress(event, input136Ref)}
                    type="text"
                    required
                  />
                  <label className="modifyTextLabel">Name</label>
                </div>
                <div className="input-box-m11">
                  <input
                    onChange={handleInputForVariation}
                    value={variationValues.sku}
                    className="modify_im_input mode_category"
                    ref={input136Ref}
                    onKeyDown={(event) => handleKeyPress(event, input137Ref)}
                    name="sku"
                    type="text"
                    required
                  />
                  <label className="modifyTextLabel">SKU </label>
                </div>

                <div className="input-box-m11 input-height">
                  <input
                    onChange={handleInputForVariation}
                    value={variationValues.productNameDescription}
                    name="productNameDescription"
                    className="modify_im_input mode_category"
                    ref={input137Ref}
                    onKeyDown={(event) => handleKeyPress(event, input138Ref)}
                    type="text"
                    required
                  />
                  <label className="modifyTextLabel">
                    Product Name Description
                  </label>
                </div>
                <div className="input-box-m11">
                  <select
                    onChange={handleInputForVariation}
                    className="modify_im_input mode_category"
                    ref={input138Ref}
                    value={variationValues.stockCheck}
                    name="stockCheck"
                    onKeyDown={(event) => handleKeyPress(event, input139Ref)}
                    // onChange={handleCheckStock}
                    type="text"
                    required
                  >
                    <option>--Select--</option>
                    <option value="0">Virtual Qty</option>
                    <option value="1">Real Qty</option>
                  </select>
                  <label className="modifyTextLabel5"> Stock Check</label>
                </div>

                <div className="input-box-m11">
                  <input
                    onChange={handleInputForVariation}
                    value={variationValues.qty}
                    name="qty"
                    className="modify_im_input mode_category"
                    ref={input139Ref}
                    onKeyDown={(event) => handleKeyPress(event, input140Ref)}
                    type="number"
                    required
                  />
                  <label className="modifyTextLabel"> Qty</label>
                </div>
                <div className="input-box-m11">
                  <input
                    onChange={handleInputForVariation}
                    value={variationValues.availableQty}
                    name="availableQty"
                    className="modify_im_input mode_category"
                    ref={input140Ref}
                    onKeyDown={(event) => handleKeyPress(event, input141Ref)}
                    type="number"
                    required
                  />
                  <label className="modifyTextLabel">Available Qty</label>
                </div>
                <div className="input-box-m11">
                  <input
                    onChange={handleInputForVariation}
                    value={variationValues.price}
                    name="price"
                    className="modify_im_input mode_category"
                    ref={input141Ref}
                    onKeyDown={(event) => handleKeyPress(event, input142Ref)}
                    type="number"
                    required
                  />
                  <label className="modifyTextLabel"> Price </label>
                </div>
                <div className="input-box-m11">
                  <input
                    onChange={handleInputForVariation}
                    value={variationValues.oldPrice}
                    name="oldPrice"
                    className="modify_im_input mode_category"
                    ref={input142Ref}
                    onKeyDown={(event) => handleKeyPress(event, input143Ref)}
                    type="number"
                    required
                  />
                  <label className="modifyTextLabel"> Old Price</label>
                </div>
              </div>
              <div className="HeadContentTitle">
                <div className="input-box-m11 input-height">
                  <input
                    onChange={handleInputForVariation}
                    value={variationValues.heightTitle}
                    name="heightTitle"
                    className="modify_im_input mode_category"
                    ref={input144Ref}
                    onKeyDown={(event) => handleKeyPress(event, input145Ref)}
                    type="text"
                    required
                  />
                  <label className="modifyTextLabel">Height Title&nbsp;</label>
                </div>

                <div className="input-box-m11 input-height ">
                  <input
                    onChange={handleInputForVariation}
                    value={variationValues.height}
                    name="height"
                    className="modify_im_input mode_limit mode_category"
                    ref={input145Ref}
                    onKeyDown={(event) => handleKeyPress(event, input146Ref)}
                    type="number"
                    required
                  />
                  <label className="modifyTextLabel">Height</label>
                </div>
                <div className="input-box-m11">
                  <input
                    onChange={handleInputForVariation}
                    value={variationValues.widthTilte}
                    name="widthTilte"
                    className="modify_im_input mode_category"
                    ref={input146Ref}
                    onKeyDown={(event) => handleKeyPress(event, input147Ref)}
                    type="text"
                    required
                  />
                  <label className="modifyTextLabel">Width Title </label>
                </div>

                <div className="input-box-m11 input-height">
                  <input
                    onChange={handleInputForVariation}
                    value={variationValues.width}
                    name="width"
                    className="modify_im_input mode_category"
                    ref={input147Ref}
                    onKeyDown={(event) => handleKeyPress(event, input148Ref)}
                    type="number"
                    required
                  />
                  <label className="modifyTextLabel">Width</label>
                </div>

                <div className="input-box-m11">
                  <input
                    onChange={handleInputForVariation}
                    value={variationValues.weightTitle}
                    name="weightTitle"
                    className="modify_im_input mode_category"
                    type="text"
                    ref={input148Ref}
                    onKeyDown={(event) => handleKeyPress(event, input149Ref)}
                    required
                  />
                  <label className="modifyTextLabel">Weight Title</label>
                </div>

                <div className="input-box-m11">
                  <input
                    onChange={handleInputForVariation}
                    value={variationValues.weight}
                    name="weight"
                    className="modify_im_input mode_category"
                    ref={input149Ref}
                    onKeyDown={(event) => handleKeyPress(event, input150Ref)}
                    type="number"
                    required
                  />
                  <label className="modifyTextLabel"> Weight</label>
                </div>
                <div className="input-box-m11">
                  <input
                    onChange={handleInputForVariation}
                    value={variationValues.lengthTitle}
                    name="lengthTitle"
                    className="modify_im_input mode_category"
                    ref={input150Ref}
                    onKeyDown={(event) => handleKeyPress(event, input151Ref)}
                    type="text"
                    required
                  />
                  <label className="modifyTextLabel">Lenght Title</label>
                </div>
                <div className="input-box-m11">
                  <input
                    onChange={handleInputForVariation}
                    value={variationValues.length}
                    name="length"
                    className="modify_im_input mode_category"
                    ref={input151Ref}
                    onKeyDown={(event) => handleKeyPress(event, input152Ref)}
                    type="number"
                    required
                  />
                  <label className="modifyTextLabel"> Length </label>
                </div>
              </div>
              <div className="HeadContentTitle">
                <div className="input-box-m11">
                  <select
                    value={variationValues.selectImage}
                    name="selectImage"
                    className="modify_im_input mode_category"
                    ref={input152Ref}
                    onKeyDown={(event) => handleKeyPress(event, input153Ref)}
                    type="text"
                    required
                  >
                    <option value="">--Select--</option>
                    <option value="">Image 1</option>
                    <option value="">Image 2</option>
                    <option value="">Image 3</option>
                    <option value="">Image 4</option>
                  </select>
                  <label className="modifyTextLabel5"> Select Image</label>
                </div>

                <div className="input-box-m11 input-height">
                  <select
                    className="modify_im_input mode_category"
                    value={variationValues.showInHome}
                    onChange={handleInputForVariation}
                    name="showInHome"
                    ref={input153Ref}
                    onKeyDown={(event) => handleKeyPress(event, input154Ref)}
                    type="text"
                    required
                  >
                    <option value="false">No</option>
                    <option value="true">Yes</option>
                  </select>
                  <label className="modifyTextLabel5">Show In Home&nbsp;</label>
                </div>

                <div className="input-box-m11 input-height ">
                  <input
                    onChange={handleInputForVariation}
                    value={variationValues.specifyIndexNo}
                    name="specifyIndexNo"
                    className="modify_im_input mode_limit mode_category"
                    ref={input155Ref}
                    onKeyDown={(event) => handleKeyPress(event, input156Ref)}
                    type="number"
                    required
                  />
                  <label className="modifyTextLabel">Specify Index No</label>
                </div>
                <div className="input-box-m11">
                  <input
                    onChange={handleInputForVariation}
                    value={variationValues.priorityPopulerCategory}
                    name="priorityPopulerCategory"
                    className="modify_im_input mode_category"
                    ref={input156Ref}
                    onKeyDown={(event) => handleKeyPress(event, input157Ref)}
                    type="number"
                    required
                  />
                  <label className="modifyTextLabel">
                    Priority Populer Category{" "}
                  </label>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="company-info-top-right" style={{ width: "50%" }}>
          <div>
            <div className="company_info-text-im">Gallery Image Details</div>
            <div className="input-box-Im1">
              <p className="modify_p_2"></p>
              <div className="radio-buttons-container1 m2 overstack">
                <ImageUploadWithPreview
                  id={"Image1"}
                  ItemImageUpload={(event) =>
                    ItemImageUpload(event, ItemImage.length)
                  }
                  imageIds={imageIds}
                  imgIndex={0}
                  ItemImage={ItemImage}
                  setItemImage={setItemImage}
                />
                <ImageUploadWithPreview
                  id={"Image2"}
                  ItemImageUpload={(event) =>
                    ItemImageUpload(event, ItemImage.length)
                  }
                  imageIds={imageIds}
                  ItemImage={ItemImage}
                  setItemImage={setItemImage}
                  imgIndex={1}
                />
                <ImageUploadWithPreview
                  id={"Image3"}
                  ItemImageUpload={(event) =>
                    ItemImageUpload(event, ItemImage.length)
                  }
                  imageIds={imageIds}
                  imgIndex={2}
                  ItemImage={ItemImage}
                  setItemImage={setItemImage}
                />
                <ImageUploadWithPreview
                  id={"Image4"}
                  ItemImageUpload={(event) =>
                    ItemImageUpload(event, ItemImage.length)
                  }
                  imageIds={imageIds}
                  imgIndex={3}
                  ItemImage={ItemImage}
                  setItemImage={setItemImage}
                />
                <ImageUploadWithPreview
                  id={"Image5"}
                  ItemImageUpload={(event) =>
                    ItemImageUpload(event, ItemImage.length)
                  }
                  imageIds={imageIds}
                  imgIndex={4}
                  ItemImage={ItemImage}
                  setItemImage={setItemImage}
                />
                <ImageUploadWithPreview
                  id={"Image6"}
                  ItemImageUpload={(event) =>
                    ItemImageUpload(event, ItemImage.length)
                  }
                  imageIds={imageIds}
                  imgIndex={5}
                  ItemImage={ItemImage}
                  setItemImage={setItemImage}
                />
                <ImageUploadWithPreview
                  id={"Image7"}
                  ItemImageUpload={(event) =>
                    ItemImageUpload(event, ItemImage.length)
                  }
                  imgIndex={6}
                  imageIds={imageIds}
                  ItemImage={ItemImage}
                  setItemImage={setItemImage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ButtonTabSave">
        <button
          id="SaveBtnMain"
          onClick={saveItemVariationProduct}
          className={`save-button-main-save hovered`}
        >
          Save/F10
          <i
            id="step-2"
            className="fa-regular fa-floppy-disk im-icon-white"
          ></i>
        </button>
      </div>
      {loading && <Loader />}
    </div>
  );
};

export default ProductVariation;
