import { useContext, useEffect, useState } from "react";
import ListSaveButton from "../../Components/ListSaveButton";
import PageTabs from "../../Components/PageTabs";
import DataTable from "../TableMaster/DataTable";
import TableButton from "./../../Props/TableButton";
import ItemGroupMaster from "./ItemGroupMaster";
import MyContext from "../../MyContext";
import AlertBox from "../../Props/AlertBox";
import Loader from "../../Props/Loader";
import ItemGroupApi from "../../../API/APIServices/ItemGroup";
import { useNavigate } from 'react-router-dom';

function ItemGroupmasterList() {
  const {
    OpenAlertBoxDelete,
    alertBoxDelete,
    setAlertBoxDelete,
    input1,
    input2,
    input3,
    input4,
    input5,
  } = useContext(MyContext);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [mainData, setMainData] = useState([]);
  const [searchValue, setSearchValue] = useState("")
  const [isSliderOpen, setSliderOpen] = useState(false);

  const toggleSlider = (value) => {
    // console.log(editData);
    setSliderOpen(value);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const val = await ItemGroupApi.GetItemGroupFilter();
      // console.log(val.length);
      const newData = val.map((item, i) => {
        return {
          input: <input type="checkbox" />,
          sno: i + 1,
          ID: item.d2_Id,
          name: item.name,
          parentGrpName: item.parentGrpName === null ? "-----" : item.parentGrpName,
          primaryGroup: String(item.primaryGroup),
          menuName: item.menuName === null ? "-----" : item.menuName,
          action: (
            <div className="tablelist-action-box">
              <i
                className="fa-regular fa-pen-to-square TableEditIcon"
                onClick={() => EditItem(item.d1_Id)}
              ></i>
              <i
                className="fa-regular fa-trash-can TableDelIcon"
                onClick={() => DeleteItem(item.d1_Id)}
              ></i>
              <i className="fa-brands fa-square-whatsapp TableWhatsappcon"></i>
              <i className="fa-solid fa-print TablePrint"></i>
            </div>
          ),
        };
      });
      setMainData(newData);
      setLoading(false);
      // console.log(val);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [getdeleteId, setDeleteId] = useState(null);

  const DeleteItem = (id) => {
    // console.log("delete" ,id)
    setAlertBoxDelete(true);
    setDeleteId(id);
  };

  const DeleteAccountConfirm = async () => {
    setLoading(true);
    // console.log("Deleting row with ID:", getdeleteId);
    try {
      const val = await ItemGroupApi.deleteItemGroup(getdeleteId);
      if (Boolean(val) === true) {
        // alert("Delete data successfully", "Ok");
        setAlertBoxDelete(false);
        setDeleteId(null);
        setLoading(false);
      } else {
        alert("This item group is used as a parent group. You can't delete it.");
      }
      fetchData();
    } catch (error) {
       console.error("Error deleting :", error);
    }
  };

  //  const[data , setData] = useState({})
  const [editData, setEditData] = useState("");

  const EditItem = async (d2_Id) => {
    const data = await ItemGroupApi.GetItemGroupById(d2_Id);
    // console.log(data);
    setEditData(data);
    toggleSlider(true);
  };

  const searchFunction = async (e) => {
    setSearchValue(e.target.value);
    const filteredData = [
      {
        fieldName: e.target.name,
        operatorName: "contains",
        compareValue: e.target.value || "",
      },
    ];
    console.log(filteredData)
    const SearchItem = await ItemGroupApi.GetItemGroupWithFilter(filteredData)
    console.log(SearchItem)


    const newData = SearchItem.map((item, i) => {
      return {
        input: <input type="checkbox" />,
        sno: i + 1,
        ID: item.d2_Id,
        name: item.name,
        parentGrpName: item.parentGrpName === null ? "-----" : item.parentGrpName,
        primaryGroup: String(item.primaryGroup),
        menuName: item.menuName === null ? "-----" : item.menuName,
        action: (
          <div className="tablelist-action-box">
            <i
              className="fa-regular fa-pen-to-square TableEditIcon"
              onClick={() => EditItem(item.d1_Id)}
            ></i>
            <i
              className="fa-regular fa-trash-can TableDelIcon"
              onClick={() => DeleteItem(item.d1_Id)}
            ></i>
            <i className="fa-brands fa-square-whatsapp TableWhatsappcon"></i>
            <i className="fa-solid fa-print TablePrint"></i>
          </div>
        ),
      };
    });
    if (newData) {
      setMainData(newData)
    }

  }

  const columns = [
    { Header: "", accessor: "input" },
    { Header: "SNO", accessor: "sno" },
    { Header: "ID", accessor: "ID" },
    { Header: "Name", accessor: "name" },
    { Header: "Parent Group Name", accessor: "parentGrpName" },
    { Header: "Primary Group", accessor: "primaryGroup" },
    { Header: "Menu Name", accessor: "menuName" },
    { Header: "Action", accessor: "action" },
  ];

  const filterData = [
    { Header: "", accessor: "" },
    { Header: "", accessor: "" },
    {
      Header: <input type="text" placeholder="ID" ref={input1} name="d2_Id" onChange={searchFunction} />,
      accessor: "ID",
    },
    {
      Header: <input type="text" placeholder="Name" ref={input1} name="name" onChange={searchFunction} />,
      accessor: "Name",
    },
    {
      Header: <input type="text" placeholder="parentGrpName" ref={input2} name="parentGrpName" onChange={searchFunction} />,
      accessor: "Parent Group Name",
    },
    {
      Header: <input type="text" placeholder="Primary Group" ref={input3} name="primaryGroup" onChange={searchFunction} />,
      accessor: "primaryGroup",
    },
    {
      Header: <input type="text" placeholder="Menu Name" ref={input5} name="menuName" onChange={searchFunction} />,
      accessor: "menuName",
    }
  ];
  return (
    <div className="">
      <PageTabs locationTab={"/ItemGroupMaster"} modelOpen={true} setSliderCallBack={setSliderOpen} />
      <div className="nav-1">
        <div className="panel-heading">
          <h2 className="panel-title">Item Group Master List</h2>
          <TableButton PageCreate={"/ItemGroupMaster"} filterData={filterData} columns={columns} />
        </div>
        <DataTable columns={columns} data={mainData} filterData={filterData} />

        <div className={`slider-container100 ${isSliderOpen ? "open" : ""}`}>
          <div style={{ padding: "15px" }}>
            <ItemGroupMaster
              data={editData}
              modelOpen={true}
              setSliderCallBack={setSliderOpen}
              fetchData={fetchData}
            />
          </div>
        </div>
      </div>

      {alertBoxDelete && (
        <AlertBox
          HeadingTop="DELETE"
          Heading="Are You sure you want to delete this"
          OpenAlertBox={OpenAlertBoxDelete}
          onYesClick={DeleteAccountConfirm}
        />
      )}
      {loading && <Loader />}
    </div>
  );
}

export default ItemGroupmasterList;
