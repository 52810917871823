import React, { useEffect, useState } from "react";
import 'react-quill/dist/quill.snow.css';
import Loader from "../../Inventory/Props/Loader";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Order from "../../API/APIServices/Order";
import AppHeaderDetail from "../AppHeaderDetail";

function ChefDetailPage() {
    //*************************title Start***********************//
    useState(() => {
        document.title = 'Chef Detail';
    }, []);

    const navigate = useNavigate();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const status = searchParams.get('status');
    const d2ItemId = searchParams.get('d2ItemId');

    const [loading, setLoading] = useState(false);

    const handleTouchStart = (e) => {
    };

    const handleTouchMove = (e) => {
        setLoading(true)
    };

    const handleTouchEnd = () => {
        //fetchData();
        setLoading(false)
    };

    const [itemList, setItemList] = useState();
    const [totalQty, setTotalQty] = useState(0)

    const fetchData = async () => {
        //alert(d2ItemId, status)
        try {
            const data = await Order.getItemListForCook(d2ItemId, status);
            setItemList(data);
            console.log("data", data);
            const qty = data.reduce((total, item) => total + item.qty, 0);
            setTotalQty(qty);
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        fetchData();
    }, [])

    const [itemStatus, setItemStatus] = useState([]);

    const handleCheckBoxChange = (ord2Id) => {
        let data;
        if (status == "Complete")
            data = { ord2Id: ord2Id, kotStatus: "Pickup", cookStatus: "Pickup" }
        else if (status == "Process")
            data = { ord2Id: ord2Id, kotStatus: "Ready", cookStatus: "Complete" }
        else if (status == "Queue")
            data = { ord2Id: ord2Id, kotStatus: "Processing", cookStatus: "Process" }

        const existingIndex = itemStatus.findIndex(item => item.ord2Id === ord2Id
            //item.kotStatus === data.kotStatus && item.cookStatus === data.cookStatus
        );

        if (existingIndex !== -1) {
            const updatedItemStatus = [...itemStatus];
            updatedItemStatus.splice(existingIndex, 1);
            setItemStatus(updatedItemStatus);
        } else {
            setItemStatus(prevItemStatus => [...prevItemStatus, data]);
        }
        //console.log("itemStatus", itemStatus);
    }

    const PrepareItemClick = async () => {
        setLoading(true)
        for (var i = 0; i < itemStatus.length; i++) {
            const data = [
                {
                    operationType: 0,
                    path: "KotStatus",
                    op: "Add",
                    from: "string",
                    value: itemStatus[i].kotStatus
                },
                {
                    operationType: 0,
                    path: "CookStatus",
                    op: "Add",
                    from: "string",
                    value: itemStatus[i].cookStatus
                }
            ]
            await Order.updateItemPartially(data, itemStatus[i].ord2Id);
            alert("Updated")
            fetchData();
            setLoading(false)
        }
    }

    return (
        <div>
            <AppHeaderDetail title={`${status} Items`} setLoading={setLoading} fetchData={fetchData} />
            <div className="content_box" style={{ marginTop: "55px" }}>
                <div className="company-info-top-im">
                    <div className="tab-head">
                        <div id="tabs">
                            <div className="tab-content-box">
                                <div className="tab-content-1">
                                    <div className="company-info-top-left Company-Completed-width" >
                                        <div style={{ backgroundColor: "white" }}>
                                            <div className="detailTopDiv" style={{ marginBottom: "10px" }}>
                                                <div className="space-between Particulars-flex">
                                                    <div className="flex-start">
                                                        <label className="ques" style={{ color: "white" }}>Particulars</label>
                                                    </div>
                                                    <div className="flex-start">
                                                        <label className="ques" style={{ color: "white" }}>Qty : {totalQty}&nbsp;</label>
                                                    </div>
                                                </div>
                                            </div>
                                            {itemList && itemList.length > 0 ? (
                                                itemList.map((data, index) => (
                                                    console.log("data", data),
                                                    <div key={data.ord2Id || index} style={{ backgroundColor: "ghostwhite", marginTop: "0px", }}>
                                                        <div className="p-1">
                                                            <div className="listDiv">
                                                                <div className="space-between">
                                                                    <div className="flex-start">
                                                                        <label className="ques">{data.itemName} &nbsp;</label>
                                                                    </div>
                                                                    <div className="flex-start">
                                                                        <input type="checkbox" className="check_box_completed" onChange={() => handleCheckBoxChange(data.ord2Id)} name="" id="" />
                                                                    </div>
                                                                </div>
                                                                <div className="space-between">
                                                                    <div className="flex-start">
                                                                        <label className="ques">Order number : &nbsp;</label>
                                                                        <label className="ans" style={{ color: "#074def" }} >{data.orderNumber}</label>
                                                                    </div>
                                                                    <div className="flex-start">
                                                                        <label className="fs-121-MH Bg-0-elipse">{data.qty}</label>
                                                                    </div>
                                                                </div>
                                                                <div className="space-between">
                                                                    <div className="flex-start">
                                                                        <label className="ques">Cook Status : &nbsp;</label>
                                                                        <label className="ans" style={{ color: "#d50ad7" }} >{data.cookStatus}</label>
                                                                    </div>
                                                                </div>
                                                                <div className="space-between">
                                                                    <div className="flex-start">
                                                                        <label className="ques">Type : &nbsp;</label>
                                                                        <label className="ans" style={{ color: "#ed4a09" }}>{data.tableName}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <div style={{ display: "flex", justifyContent: "center", alignItems: "baseline" }}>
                                                    <label>No Item available.</label>
                                                </div>
                                            )}
                                            {/************************** Order Allocate Modal ***************/}
                                        </div>
                                        <div className="floating-button-container" style={{ width: "85%", bottom: "10px" }} onClick={PrepareItemClick}>
                                            <button className="blueBtn" onClick={PrepareItemClick}>Prepare Item</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loading && <Loader />}
        </div>
    );
}


export default ChefDetailPage;
