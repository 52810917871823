import React, { useEffect, useState } from "react";
import { useTable } from "react-table";
import DeliveryAndTimeSlot from "../../API/APIServices/DeliveryAndTimeSlot";

const YourDynamicTable = ({ columns, data }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <table
      {...getTableProps()}
      style={{
        borderCollapse: "collapse",
        width: "100%",
        border: "1px solid #ddd",
        fontSize: "14px",
      }}
    >
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps()}
                style={{
                  border: "1px solid #ddd",
                  textAlign: "center",
                  padding: "3px",
                  background: "aliceblue",
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "13px",
                }}
              >
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()} >   {/*style={{maxHeight:"250px"}}*/}
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <td
                  {...cell.getCellProps()}
                  style={{
                    border: "1px solid #ddd",
                    padding: "2px",
                    textAlign: "center",
                  }}
                >
                  {cell.render("Cell")}
                </td>
              ))}
              {/* <td>
              <i className="fa-solid fa-pen-to-square" onClick={() => handleEdit(row.original)} style={{ cursor: 'pointer' }}></i>
              <i className="fa-solid fa-trash-can" onClick={() => handleDelete(row.original)} style={{ cursor: 'pointer' }}></i>
              </td> */}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const TimeDateSlot = (props) => {
  //  console.log(props.data);
  
  const handleHeaderCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    const updatedCheckboxStates = {};
    props.data.forEach(item => {
      updatedCheckboxStates[item.id] = isChecked;
    });
    setCheckboxStates(updatedCheckboxStates);
  };
  
  const columns = [
    { Header: <input type="checkbox" onClick={handleHeaderCheckboxChange}/>, accessor: "sno" },
    { Header: "Delivery Time Slots From", accessor: "SlotStartTime" },
    { Header: "Delivery Time Slots To", accessor: "EndTime" },
  ];

  //   const filterData = [
  //     { Header: "", accessor: "" },
  //     { Header: "", accessor: "" },
  //     { Header: <input type="text" placeholder="Name" />, accessor: "Name" },
  //     { Header: <input type="text" placeholder="Address" />, accessor: "Address" },
  //     { Header: <input type="text" placeholder="State" />, accessor: "State" },
  //     { Header: <input type="text" placeholder="Email" />, accessor: "Email" },
  //   ];
 

  var dataItem = props.data;
  const [tableData, setTableData] = useState([]);
  const [checkboxStates, setCheckboxStates] = useState({});

  useEffect(() => {
    const data = [" "]
    setCheckboxStates(data);
    console.log("checkboxData", checkboxStates);
  }, [props.editData])

  //console.log("checkboxStates", checkboxStates);

  useEffect(() => {
    const GetDeliveryAndTimeSlotById = async () => {
      try {
        const data = await DeliveryAndTimeSlot.GetDeliveryAndTimeSlotById(props.editData);
        console.log("get delivery by id", data);
        const timeId = data.deliveryTimeSlots;
        console.log(timeId.length);
        for (var i = 0; i < timeId.length; i++) {
          //console.log(Number(timeId[i]));
          const id = timeId[i]
          setCheckboxStates((prevState) => ({
            ...prevState,
            [id]: true,
          }));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      //}
    };
    GetDeliveryAndTimeSlotById();
  }, [props.editData]);

  // useEffect(() => {
  //   if (Array.isArray(props.data)) {
  //     const data = props.data.map((item, i) => ({
  //       sno: <input type="checkbox" name={item.TimeDateSlot} onChange={(e) => handleCheckboxChange(e, i)} />,
  //       SlotStartTime: item.availableFrom,
  //       EndTime: item.availableTo,
  //     }));

  //     setTableData(data);
  //   }
  // }, [props.data]);


  useEffect(() => {
    if (Array.isArray(props.data)) {
      const data = props.data.map((item) => ({
        sno: <input type="checkbox" id={item.id} onChange={(e) => handleCheckboxChange(e, item.id)} />,
        SlotStartTime: item.availableFrom,
        EndTime: item.availableTo,
      }));
      console.log("data", data);
      setTableData(data);
    }
  }, [props.data]);

  useEffect(() => {
    const checkedIds = [];
    for (const id in checkboxStates) {
        if (checkboxStates[id]) {
            checkedIds.push(id);
        }
    }
    console.log("Checked checkbox IDs:", checkedIds);
    props.setCheckedIds(checkedIds);
}, [checkboxStates, props.setCheckedIds]);


  const handleCheckboxChange = (event, id) => {
    const { checked } = event.target;
 
    setCheckboxStates((prevState) => ({
      ...prevState,
      [id]: checked
    }));
  };
  // const handleCheckboxChange = (e, id) => {
  //   const updatedCheckboxStates = { ...checkboxStates, [id]: e.target.checked };
  //   setCheckboxStates(updatedCheckboxStates);
  // };

  useEffect(() => {
    if (Array.isArray(props.data)) {
      const data = props.data.map((item) => ({
        sno: <input type="checkbox" id={item.id} checked={checkboxStates[item.id] || false} onChange={(e) => handleCheckboxChange(e, item.id)} />,
        SlotStartTime: item.availableFrom,
        EndTime: item.availableTo,
      }));
      //console.log("data", data);
      setTableData(data);
    }
  },);

  return (
    <div>
      <YourDynamicTable columns={columns} data={tableData} />
    </div>
  );
};

export default TimeDateSlot;
