import React, { useEffect, useState } from "react";
import "./ShopBanner.css";
import shopBanner from "../../API/APIServices/Shopbanner";
import { Link } from "react-router-dom";
import FloatingLabelInput from "../Page4/InputBox";

function ShopBanner() {

  const [changelabel, setchangelabel] = useState("");
  // const [isClicked, setIsClicked] = useState(false);
  const [apiData, setApiData] = useState([])
  const [currentBannerId, setCurrentBannerId] = useState(null)
  const [reservedFields, setReservedFields] = useState({
    extraField1: " ",
    extraField2: " "
  });
  console.log(currentBannerId)
  console.log(reservedFields.extraField1)
  console.log(reservedFields.extraField2)



  const handleChange = (e) => {
    setchangelabel(e.target.value);
    console.log(e.target.value);
  };

  const handleUpdate = async (id) => {
    // alert(id)
    try {
      const response = await shopBanner.GetImageBannerNameById(id)
      setCurrentBannerId(id)

      console.log(response)
      if (response.isSuccess) {
        setchangelabel(response.result.imageBannerName)
        setReservedFields({
          extraField1: response.result.extraField1,
          extraField2: response.result.extraField2
        });
        // alert("fetched")
      }
    } catch (error) {
      throw new error("Unable to Fetch the data")
    }
  }


  const checkIfBannerHasChildren = async (id) => {
    try {
  
      const response = await shopBanner.GetShopBanners()
      console.log(response)
      const children = response; // Assuming the API returns an array of children
      const hasChildren = children.some(banner => banner.imageBannerNameId === id);
      console.log(hasChildren)
      return hasChildren
    } catch (error) {
      console.error('Error checking for child banners:', error.message);
      throw error;
    }
  };


  const handleDelete = async (id) => {
    // alert(id)
    const confirmDelete = window.confirm("Are you sure you want to delete this item?");
    if (!confirmDelete) {
      return; // If user cancels deletion, do nothing
    }
    try {
      const hasChildren = await checkIfBannerHasChildren(id);
      if (hasChildren) {
        alert(`Banner with ID ${id} has children. Unable To Delete`)
      } else {
        const response = await shopBanner.DeleteImageBannerNameById(id)
        if (response.isSuccess) {
          alert("Deleted Successfully")
          fetchAlldata()
        }
      }
    } catch (error) {
      console.error("Unable to Delete", error)
      alert(error)
    }
  }

  const senddata = async (data) => {
    try {
      if (data.id) {
        // If data has an id, it means we need to update
        const response = await shopBanner.UpdateImageBannerName(data.id, data);
        if (response.isSuccess) {
          alert("Updated Successfully");
          fetchAlldata();
          setchangelabel("");
          setReservedFields({
            extraField1: " ",
            extraField2: " "
          });
        } else {
          throw new Error("Update failed");
        }
      } else {
        // If no id in data, it means we need to create
        const response = await shopBanner.CreateAddImageBannerName(data);
        if (response.isSuccess) {
          alert("Created Successfully");
          fetchAlldata();
          setchangelabel("");
          setReservedFields({
            extraField1: " ",
            extraField2: " "
          });
        } else {
          throw new Error("Creation failed");
        }
      }
    } catch (error) {
      console.error("Unable to create", error)
    }
  }

  const handleSave = (id) => {
    const createData = {
      imageBannerName: changelabel,
      extraField1: reservedFields.extraField1,
      extraField2: reservedFields.extraField2
    };
    const updateData = {
      id: id,
      imageBannerName: changelabel,
      extraField1: reservedFields.extraField1,
      extraField2: reservedFields.extraField1
    };
    if (id) {
      senddata(updateData)
    } else {
      senddata(createData);
    }

  };


  const fetchAlldata = async () => {
    try {
      const response = await shopBanner.GetAllImageBannerName()
      if (response) {
        console.log("Response", response);
        setApiData(response)
      }
    } catch (error) {
      throw new error("Unable To fetch Data")
    }
  }
  useEffect(() => {
    fetchAlldata()
  }, [])

  return (
    <>
      <div className="main-div-shop">
        <h1 className="h1-main-div">Shop Banner Image</h1>
        {/* banner */}
        <section className={`section-shopbanner ${changelabel === "image" ? "change-border" : ""}`}>
         <FloatingLabelInput
            label="Banner and image name"
            name="Banner and image name"
            onChange={handleChange}
            value={changelabel} />
        </section>
        {/* extrafield */}

        <section className={` section-shopbanner ${changelabel === "extra" ? "change-border" : ""}`}>
          <FloatingLabelInput label="Extra Field 1"
            onChange={(e) => setReservedFields({ ...reservedFields, extraField1: e.target.value })}
            value={reservedFields.extraField1}
            name="Extra Field 1" />
        </section>
        <section className={` section-shopbanner ${changelabel === "extra1" ? "change-border" : ""}`}>
          <FloatingLabelInput label="Extra Field 2"
            onChange={(e) => setReservedFields({ ...reservedFields, extraField2: e.target.value })}
            value={reservedFields.extraField2}
            name="Extra Field 2" />
        </section>
        <div className="button-center">
          <button
            className="save-button-main-save"
            onClick={() => handleSave(currentBannerId)}
          >
            SAVE
          </button>
          <Link to={"/mainshopbanner"}>
            <button className="save-button-main-save">
              Jump To Master
            </button>
          </Link>
        </div>





        <div className="table-div">
          <table className="tableList-main">
            <thead className="table-head-main">
              <tr>
                <th>S.No</th>
                <th> Image Banner ID</th>
                <th>Image Banner Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="table-body-main">
              {apiData.map((item, index) => (
                <tr key={item.id}>
                  <td>{index + 1}</td>
                  <td>{item.id}</td>
                  <td>{item.imageBannerName}</td>
                  <td>
                    <i className="fa-regular fa-pen-to-square TableEditIcon" onClick={() => handleUpdate(item.id)} />
                    <i className="fa-regular fa-trash-can TableDelIcon" onClick={() => handleDelete(item.id)} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default ShopBanner;
