import DateTime from "../../Components/DateTime";
import PageTabs from "../../Components/PageTabs";
import React, { useState, useRef, useContext, useEffect } from "react";
import "react-quill/dist/quill.snow.css";
import EnterKeyPress from "../../Props/EnterKeyPress";
import ModalQueryName from "../../ModalBox/ModalQueryName";
import MiscellaneousMaster from "../../Master/AccountMaster/MiscellaneousMaster";
import Query from "../../../API/APIServices/Query";
import Loader from "../../Props/Loader";
import MyContext from "../../MyContext";
import AlertBoxError from "../../Props/AlertBoxError";
import AlertBox from "../../Props/AlertBox";
import MainButton from "../../Props/MainButton";
import SmsTemplate from "../../../API/APIServices/SmsTemplate";
import MiscellaneousMasterApi from "../../../API/APIServices/MiscellaneousMaster";

function SmsTemplate1(props) {
  /***********{title}***************/
  useState(() => {
    document.title = "SMS Template";
  }, []);

  const {
    OpenAlertBox,
    alertBox,
    OpenAlertBoxEdit,
    alertBoxEdit,
    alertBoxError,
    OpenAlertBoxError,
  } = useContext(MyContext);

  //**********{show hide}*****************//

  const [isInputVisiblam3, setInputVisiblam3] = useState(false);
  const handleToggleChangam3 = () => {
    setInputVisiblam3(!isInputVisiblam3);
  };

  //**********{show hide Y/N}*****************//
  const [isModalOpenEcom, setModalOpenEcom] = useState(false);

  const [QueryData, setQueryData] = useState({});

  const fetchdata = async () => {
    const data = await Query.getQuery();
    if (data) {
      setQueryData(data);
    } else {
      console.log(data);
    }
  };

  useEffect(() => {
    fetchdata();
  }, []);

  const [loading, setLoading] = useState(false);

  const showselectOption51 = () => {
    setModalOpenEcom(!isModalOpenEcom);
  };

  const [errorMessages, setErrorMessages] = useState({});

  const [inputValuesForQuery, setInputValuesForQuery] = useState({
    id: "",
    title: "",
    query: "",
    query2: "",
    query3: "",
    isActive: true,
  });

  const [inputValuesForSmS, setinputValuesForSmS] = useState({
    id: "",
    title: "",
    templateForSMSAPI: "",
    templateId: "",
    queryName: "",
    fieldName: "",
    smsTemplateBody: "",
    templateForWhatsappAPI: "",
    whatsappLanguage: "",
    messageHeaderSection: "",
    messageBodySection: "",
    smsType: "",
    templateForFormDetails: "",
    isActive: true,
  });


  const [inputValuesForMsgTemplate, setInputValuesForMsgTemplate] = useState({
    smsType: "",
    template: "",
    smsTypeName: "",
    templateName: "",
  });

  const [controlType] = useState(173);

  const toFriendlyName = (fieldName) => {
    return fieldName
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  };

  const validateInput = (fieldName) => {
    const fieldValue = inputValuesForQuery[fieldName];

    if (
      !fieldValue ||
      (typeof fieldValue === "string" && fieldValue.trim() === "")
    ) {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        [fieldName]: `${toFriendlyName(fieldName)} field is required`,
      }));
      return false;
    }

    return true;
  };
  const [SmsTemplateData, setSmsTemplateData] = useState({})

  const fetchTEmplateData = async () => {
    const templateData = await SmsTemplate.GetAssignTemplates()
    setSmsTemplateData(templateData)
  }

  useEffect(() => {
    fetchTEmplateData()
  }, [])

  const fieldNames = [];

  const SaveQuery = () => {
    let allValid = true;
    fieldNames.forEach((fieldName) => {
      const isValid = validateInput(fieldName);
      if (!isValid) {
        allValid = false;
      }
    });
    if (allValid) {
      setLoading(true);
      const isSuccess = Query.insertQuery(inputValuesForQuery);
      if (isSuccess) {
        OpenAlertBox();
        setLoading(false);
        setInputValuesForQuery({
          title: "",
          query: "",
          query2: "",
          query3: "",
          isActive: true,
        });
        alert("Your Query is saved");
        fetchdata();
      } else {
        alert("error while updating");
      }
    }
  };

  const updateQuery = () => {
    let allValid = true;
    fieldNames.forEach((fieldName) => {
      const isValid = validateInput(fieldName);
      if (!isValid) {
        allValid = false;
      }
    });
    if (allValid) {
      setLoading(true);
      const isSuccess = Query.updateQuery(inputValuesForQuery);
      if (isSuccess) {
        OpenAlertBox();
        setLoading(false);
        setInputValuesForQuery({
          title: "",
          query: "",
          query2: "",
          query3: "",
          isActive: true,
        });
        alert("Your Query is saved");
        fetchdata();
      } else {
        alert("error while updating");
      }
    }

    // else {
    //     setLoading(true);
    //     const isSuccess = Query.insertQuery(inputValuesForQuery);
    //     if (isSuccess) {
    //         setLoading(false);
    //         OpenAlertBox();
    //     } else {
    //         alert("Error inserting data");
    //     }
    // }
    // setInputValuesForQuery({
    //     name: "",
    //     printName: "",
    // });
  };

  const deleteQuery = () => {

    setLoading(true);
    const isSuccess = Query.deleteQuery(inputValuesForQuery);
    if (isSuccess) {
      OpenAlertBox();
      setLoading(false);
      setInputValuesForQuery({
        title: "",
        query: "",
        query2: "",
        query3: "",
        isActive: true,
      });
      alert("Your Query is saved");
      fetchdata();
    } else {
      alert("error while updating");
    }

  };

  const [SmsData, setSmsData] = useState([]);

  const SaveSms = () => {

    let allValid = true;
    fieldNames.forEach((fieldName) => {
      const isValid = validateInput(fieldName);
      if (!isValid) {
        allValid = false;
      }
    });
    if (allValid) {
      setLoading(true);
      
      let isSuccess = false;

      if (isSmsTemplateEdit == false) {
        isSuccess = SmsTemplate.insertSmsTemplate(inputValuesForSmS);
      }
      else{
        isSuccess = SmsTemplate.updateSmsTemplate(inputValuesForSmS);
      }

      if (isSuccess) {
        OpenAlertBox();
        setLoading(false);
        setinputValuesForSmS({
          title: "",
          templateForSMSAPI: "",
          templateId: "",
          queryName: "",
          fieldName: "",
          smsTemplateBody: "",
          templateForWhatsappAPI: "",
          whatsappLanguage: "",
          messageHeaderSection: "",
          messageBodySection: "",
          smsType: "",
          templateForFormDetails: "",
          isActive: true,
        });
        alert("Your Sms is saved");
        fetchdata();
      } else {
        alert("error while updating");
      }
      
    }
    
  };


  const saveMessageTemplate = async () => {
    const smssave = await SmsTemplate.MessageTemplate(inputValuesForMsgTemplate)
    console.log(smssave)
    if (smssave) {
      alert("Yout Template is saved")
      fetchTEmplateData()
    }
    else {
      alert("error")
    }

  }

  const [isSmsTemplateEdit, setIsSmsTemplateEdit] = useState(false);
  const [getByIdData, setgetByIdData] = useState({})


  const getSmsTypeByid = async (event) => {
    var id = event.target.value;
    var data = await SmsTemplate.getSmsTemplateById(id);
    console.log(data)

    if (data) {
      setinputValuesForSmS({
        id: data.id,
        title: data.title,
        templateForSMSAPI: data.templateForSMSAPI,
        templateId: data.templateId,
        queryName: data.queryName,
        fieldName: data.fieldName,
        smsTemplateBody: data.smsTemplateBody,
        templateForWhatsappAPI: data.templateForWhatsappAPI,
        whatsappLanguage: data.whatsappLanguage,
        messageHeaderSection: data.messageHeaderSection,
        messageBodySection: data.messageBodySection,
        smsType: data.smsType,
        templateForFormDetails: data.templateForFormDetails,
        isActive: true,
      });
      setgetByIdData(data);
      setIsSmsTemplateEdit(true);
      fetchSmsTemplate(data.queryName);
    } else {
      alert("data is not available");
      setinputValuesForSmS({
        id: "",
        title: "",
        templateForSMSAPI: "",
        templateId: "",
        queryName: "",
        fieldName: "",
        smsTemplateBody: "",
        templateForWhatsappAPI: "",
        whatsappLanguage: "",
        messageHeaderSection: "",
        messageBodySection: "",
        smsType: "",
        templateForFormDetails: "",
        isActive: true,
      });
    }
  }



  useEffect(() => {
    const fetchSmsTemplate1 = async () => {
      var SmsTemplates = await SmsTemplate.getSmsTemplate();
      console.log(SmsTemplates);
      if (SmsTemplates) {
        setSmsData(SmsTemplates);
      } else {
      }
    };
    fetchSmsTemplate1();
  }, []);



  const handleInputChangeForQuery = (event) => {
    const { name, value } = event.target;
    console.log(`Changing ${name} to ${value}`);

    setInputValuesForQuery((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };
  const [selectedFieldName, setSelectedFieldName] = useState()

  const handleInputChangeForSms1 = (event) => {
    const { name, value } = event.target;
    console.log(`Changing ${name} to ${value}`);

    setinputValuesForSmS((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setSelectedFieldName(value)
    setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: "" }));

  };
  const handleInputChangeForSms = (event) => {
    const { name, value } = event.target;
    console.log(`Changing ${name} to ${value}`);

    setinputValuesForSmS((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

    //alert(name)
    setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: "" }));
    if (name === "queryName")
      fetchSmsTemplate(value)
  };

  const handleInputChangeForMsgTemplate = (event) => {
    const { name, value } = event.target;
    console.log(`Changing ${name} to ${value}`);

    /*setInputValuesForMsgTemplate((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));*/

    // Find the selected option's text (name)
    const selectedText = event.target.options[event.target.selectedIndex].text;

    setInputValuesForMsgTemplate((prevValues) => {
      if (name === "smsType") {
        return {
          ...prevValues,
          smsType: value,
          smsTypeName: selectedText,
        };
      } else if (name === "template") {
        return {
          ...prevValues,
          template: value,
          templateName: selectedText,
        };
      }
      return prevValues;
    });

    setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };


  //*******************{modal box}******************/ /

  const [isModalOpen, setModalOpen] = useState(false);
  const showModal = () => {
    setModalOpen(true);
  };
  const hideModal = () => {
    setModalOpen(false);
  };

  const [isPrintName, setPrintName] = useState(false);
  const showPrintName = () => {
    setPrintName(!isPrintName);
  };

  const [isTaxCategory2, setTaxCategory2] = useState(false);
  const showTaxCategory2 = () => {
    setTaxCategory2(!isTaxCategory2);
  };

  //****************{modal box}***************/ /

  //*****************{Save Button}************************//

  const [isHovered, setHovered] = useState(false);
  const handleMouseEnter = () => {
    setHovered(true);
  };
  const handleMouseLeave = () => {
    setHovered(false);
  };
  // **********************{Press Enter Button}************************

  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);
  const input5Ref = useRef(null);
  const input6Ref = useRef(null);
  const input7Ref = useRef(null);

  const handleKeyPress = (event, nextInputRef) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (nextInputRef.current) {
        nextInputRef.current.focus();
      }
    }
  };

  //**********{query Name modal box}*****************//
  const [isModalOpenQuery, setModalOpenQuery] = useState(false);
  const showModalQuery = () => {
    setModalOpenQuery(true);
  };
  const hideModalQuery = () => {
    setModalOpenQuery(false);
  };

  //**********{Form modal box}*****************//
  const [isModalOpenForm, setModalOpenForm] = useState(false);
  const showModalForm = () => {
    setModalOpenForm(true);
  };
  const hideModalForm = () => {
    setModalOpenForm(false);
  };

  const [TimeSLot, setTimeSLot] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      {
        try {
          const controlType = 173;
          const data = await MiscellaneousMasterApi.GetMiscellaneousMaster(
            controlType
          );
          setTimeSLot(data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };
    fetchData();
  }, [isModalOpenForm]);



  const [FieldName, setFieldName] = useState([]);
  const fetchSmsTemplate = async (id) => {
    var FieldName = await Query.getQueryColumnName(id);
    console.log(FieldName);
    setFieldName(FieldName);
    if (FieldName) {
    } else {
    }
  };


  const changetablevalue = (e) => {
    const value = e.target.value;
    console.log(value)
    setFieldName(value);
    handleInputChangeForSms(e);

  };

  function handleAddBtnClick() {
    const currentText = inputValuesForSmS.smsTemplateBody || '';
    const newText = `${currentText.trim()} @${selectedFieldName}`;

    setinputValuesForSmS(prevState => ({
      ...prevState,
      smsTemplateBody: newText
    }));

    console.log("New text:", newText);
  }

  return (
    <div>
      <PageTabs />

      {/* < EnterKeyPress /> */}
      <div className="panel-heading">
        <div className="panel-head-title">
          <h2 className="panel-title">SMS Template</h2>
        </div>
        {/* *******************Save Button****************** */}
        <MainButton SaveButton={SaveSms} />
        
        {/* *******************Top Buttons****************** */}
        {/* <div className="panel-head-button">
          <div className="col_1">
            <div>
              <div className="button-m5">
                <button
                  id="SaveBtnMain"
                  className={`save-button-main-save ${
                    isHovered ? "hovered" : ""
                  }`}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  Save
                  <i
                    id="step-2"
                    className="fa-solid fa-file-export im-icon-white"
                  ></i>
                  {isHovered && (
                    <div className="hover-textSave">
                      <i className="fa-solid fa-sort-up ItemIconHover"></i>
                      Alt + Enter
                    </div>
                  )}
                </button>
              </div>
            </div>

            <div className="button-m5">
              <button className="save-button-main">
                Reset
                <i id="step-2" className="fa-solid fa-rotate im-icon-2"></i>
              </button>
            </div>
          </div>
        </div> */}

        {/* *******************Modal Box Open****************** */}
        <div>
          {isModalOpen && (
            <div className="modal">
              <div className="modal-content">
                <div className="modal-title">
                  <h3 className="modal-h">Item Group Setting</h3>
                  <span className="close" onClick={hideModal}>
                    &times;
                  </span>
                </div>
                <div className="modal-content-im">
                  <div className="">
                    <div className="input-box-m1 modal-1">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            type="checkbox"
                            onChange={showPrintName}
                            checked={isPrintName}
                          />
                          <span></span>
                        </label>
                        <div className="ModalLabeltext2">
                          Print Name
                          <i className="fa-solid fa-circle-info ml-10"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="input-box-m1 modal-1">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            type="checkbox"
                            onChange={showTaxCategory2}
                            checked={isTaxCategory2}
                          />
                          <span></span>
                        </label>
                        <div className="ModalLabeltext2">
                          Tax Category 2
                          <i className="fa-solid fa-circle-info ml-10"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="input-box-m1 modal-1">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            type="checkbox"
                            onChange={handleToggleChangam3}
                            checked={isInputVisiblam3}
                          />
                          <span></span>
                        </label>
                        <div className="ModalLabeltext2">
                          Tax Category Date Wise Details
                          <i className="fa-solid fa-circle-info ml-10"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="input-box-m1 modal-1">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            type="checkbox"
                            onChange={showselectOption51}
                            checked={isModalOpenEcom}
                          />
                          <span></span>
                        </label>
                        <div className="ModalLabeltext2">
                          Use in E-Commerce Details
                          <i className="fa-solid fa-circle-info ml-10"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* *******************Modal Box End****************** */}
      <div>
        <DateTime />
      </div>
      <div className="content_box">
        <div className="company-info-top-im">
          <div className="tab-head">
            <div id="tabs">
              <div className="tab-content-box">
                <div className="tab-content-1">
                  <div className="company-info-top-left">
                    <div className="company_info-text-im">SMS Details</div>
                    <div className="HeadContentItemgroup">
                      {(() => {
                        var val = isPrintName ? input2Ref : input3Ref;
                        return (
                          <div className="input-box-m11">
                            <input
                              ref={input1Ref}
                              onKeyDown={(event) => handleKeyPress(event, val)}
                              className="modify_im_input mode_limit mode_category"
                              type="text"
                              required
                              onChange={changetablevalue}
                              value={inputValuesForSmS.title}
                              name="title"
                            />
                            <label className="modifyTextLabel">Title</label>
                          </div>
                        );
                      })()}
                      {/* {isPrintName && ( */}
                      <div className="input-box-m11">


                        <select
                          name=""
                          id=""
                          ref={input2Ref}
                          onKeyDown={(event) =>
                            handleKeyPress(event, input3Ref)
                          }
                          className="modify_im_input mode_category"
                          type="text"
                          onChange={(e) => getSmsTypeByid(e)} s
                        >
                          <option value="">--Select--</option>
                          {Array.isArray(SmsData) && SmsData.length > 0 ? (
                            SmsData.map((sms) => (
                              <option value={sms.id} key={sms.id}>
                                {sms.title}
                              </option>
                            ))
                          ) : (
                            <option>Blank</option>
                          )}
                        </select>
                        <label className="ItemMasterLabelFix">Template</label>
                      </div>
                      {/* )} */}

                      <div className="input-box-m11">
                        <input
                          ref={input3Ref}
                          onKeyDown={(event) =>
                            handleKeyPress(event, input4Ref)
                          }
                          className="modify_im_input mode_category"
                          type="text"
                          onChange={handleInputChangeForSms}
                          value={inputValuesForSmS.templateForSMSAPI}
                          name="templateForSMSAPI"
                          required
                        />
                        <label className="modifyTextLabel">
                          Template For SMS API
                        </label>
                      </div>

                      <div className="input-box-m11">
                        <input
                          ref={input4Ref}
                          onKeyDown={(event) =>
                            handleKeyPress(event, input5Ref)
                          }
                          className="modify_im_input mode_limit mode_category"
                          type="text"
                          required
                          onChange={handleInputChangeForSms}
                          value={inputValuesForSmS.templateId}
                          name="templateId"
                        />
                        <label className="modifyTextLabel">Template ID</label>
                      </div>
                      <div className="input-box-m11 dm_3">
                        <select
                          ref={input5Ref}
                          onKeyDown={(event) =>
                            handleKeyPress(event, input6Ref)
                          }
                          className="modify_im_input mode_category"
                          type="text"
                          required
                          onChange={handleInputChangeForSms}
                          value={inputValuesForSmS.queryName}
                          name="queryName"
                        >
                          <option>--Select--</option>
                          {Array.isArray(QueryData) ? (
                            QueryData.map((Query) => (
                              <option value={Query.id} key={Query.id}>
                                {Query.title}
                              </option>
                            ))
                          ) : (
                            <option>Blank</option>
                          )}
                        </select>
                        <label className="ItemMasterLabelFix">Query Name</label>
                        <div onClick={showModalQuery}>
                          <i className="fa-solid fa-circle-plus dm_4"></i>
                        </div>
                      </div>

                      <div className="input-box-m11 dm_3">
                        <select
                          ref={input6Ref}
                          onKeyDown={(event) =>
                            handleKeyPress(event, input7Ref)
                          }
                          className="modify_im_input mode_category"
                          type="text"
                          required
                          onChange={handleInputChangeForSms1}
                          value={inputValuesForSmS.fieldName}
                          name="fieldName"
                        >
                          <option>--Select--</option>
                          <option>FirmName</option>
                          <option>FirmShortName</option>
                          <option>FirmPrintName</option>
                          <option>FirmAddress1</option>
                          <option>FirmAddress2</option>
                          <option>FirmAddress3</option>
                          <option>FirmMobileNo</option>
                          <option>FirmEmail</option>
                          <option>FirmWebsite</option>
                          <option>FirmGstTinNo</option>
                          <option>FirmCstNo</option>
                          <option>FirmPinCode</option>
                          <option>FirmTermsCondition</option>
                          {Array.isArray(FieldName) && FieldName.length > 0 ? (
                            FieldName.map((sms) => (
                              <option value={sms.id} key={sms.id}>
                                {sms}
                              </option>
                            ))
                          ) : (
                            <option>Blank</option>
                          )}

                        </select>
                        <label className="ItemMasterLabelFix">Field Name</label>

                        <div className="Sms_Save">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <button className="AddBtn_Sms" onClick={handleAddBtnClick}>Add</button>
                          </div>
                        </div>
                      </div>
                      <div className="input-box-Im">
                        <div className="input-box-m2">
                          <label
                            className=""
                            style={{ color: "red" }}
                          >
                            Please Do Not Use Any Special Characters In Text
                          </label>
                        </div>
                      </div>
                    </div>
                    {/* ********************{old}****************** */}

                    <div className="">
                      <div className="input-box-tab-head Sms-Text-Box">
                        <div className="input-box-m2">
                          <textarea
                            className="modify_im_textarea mode_category2 Sms-Template-box"
                            type="text"
                            placeholder=""
                            required
                            onChange={handleInputChangeForSms}
                            value={inputValuesForSmS.smsTemplateBody}
                            name="smsTemplateBody"
                          />
                          {/* <label className="modify_p_text-fix">Meta Title</label> */}
                        </div>
                      </div>
                      <div className="HeadContentItemgroup">
                        <div className="input-box-m11">
                          <input
                            className="modify_im_input mode_category"
                            type="text"
                            required
                            onChange={handleInputChangeForSms}
                            value={inputValuesForSmS.templateForWhatsappAPI}
                            name="templateForWhatsappAPI"
                          />
                          <label className="modifyTextLabel">
                            Template For Whatsapp API
                          </label>
                        </div>

                        <div className="input-box-m11">
                          <input
                            className="modify_im_input mode_limit mode_category"
                            type="text"
                            required
                            onChange={handleInputChangeForSms}
                            value={inputValuesForSmS.whatsappLanguage}
                            name="whatsappLanguage"
                          />
                          <label className="modifyTextLabel">Whatsapp</label>
                        </div>
                      </div>
                      <div className="input-box-tab-head Sms-Text-Box">
                        <div className="input-box-m2">
                          <textarea
                            className="modify_im_textarea mode_category2 sms-header-box"
                            type="text"
                            placeholder="Message Header Section"
                            required
                            value={inputValuesForSmS.messageHeaderSection}
                            onChange={handleInputChangeForSms}
                            name="messageHeaderSection"
                          />
                          {/* <label className="modify_p_text-fix">Meta Keywords</label> */}
                        </div>
                      </div>
                      <div className="input-box-tab-head Sms-Text-Box">
                        <div className="input-box-m2">
                          <textarea
                            className="modify_im_textarea mode_category2 sms-header-box"
                            type="text"
                            placeholder="Message Body Section"
                            required
                            onChange={handleInputChangeForSms}
                            value={inputValuesForSmS.messageBodySection}
                            name="messageBodySection"
                          />
                          {/* <label className="modify_p_text-fix">Meta Description</label> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="company-info-top-right"
                    style={{ width: "50%" }}
                  >
                    <div>
                      <div className="company_info-text-im">Form Details</div>
                      <div className="">
                        <div className="input-box-Im input-height2">
                          <div className="input-box-m11 dm_3">
                            <select
                              className="modify_im_input mode_category"
                              type="text"
                              required
                              value={inputValuesForMsgTemplate.smsType}
                              name="smsType"
                              onChange={handleInputChangeForMsgTemplate}
                            >
                              <option>--Select--</option>
                              {Array.isArray(TimeSLot) &&
                                TimeSLot.length > 0 ? (
                                TimeSLot.map((data) => (
                                  <option value={data.id} key={data.id}>
                                    {data.name || ""}
                                  </option>
                                ))
                              ) : (
                                <option>select</option>
                              )}
                            </select>
                            <label className="ItemMasterLabelFix">
                              SMS Type
                            </label>
                            <div onClick={showModalForm}>
                              <i className="fa-solid fa-circle-plus dm_4"></i>
                            </div>
                          </div>

                          <div className="input-box-m11 dm_3">
                            <select
                              className="modify_im_input mode_category"
                              type="text"
                              required
                              value={inputValuesForMsgTemplate.template}
                              name="template"
                              onChange={handleInputChangeForMsgTemplate}
                            >
                              <option>--Select--</option>
                              {Array.isArray(SmsData) && SmsData.length > 0 ? (
                                SmsData.map((sms) => (
                                  <option value={sms.id} key={sms.id}>
                                    {sms.title}
                                  </option>
                                ))
                              ) : (
                                <option>Blank</option>
                              )}
                            </select>
                            <label className="ItemMasterLabelFix">
                              Template
                            </label>
                            {/* <div>
                                  <i className="fa-solid fa-circle-plus dm_4"></i>
                              </div> */}
                            <div className="Sms_Save">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <button className="SaveBtn_Sms" onClick={saveMessageTemplate}>Save</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="table-resposive">
                          <table
                            id="tblTransactionList"
                            className="table_ord_mod table-bordered1"
                            frame="box"
                          >
                            <thead
                              textAlign="center"
                              className="thead_scroll_orderlist table-tbody"
                            >
                              <tr className="">
                                <th className="td_col6 td_width_sms">
                                  {" "}
                                  <div className="table_head_mmb ">
                                    <div
                                      className="table_head_mmb1"
                                      style={{ textAlign: "center" }}
                                    >
                                      S.No.
                                    </div>
                                  </div>{" "}
                                </th>
                                <th className="td_col6">
                                  {" "}
                                  <div className="table_head_OrderList">
                                    <div
                                      className="table_head_mmb1"
                                      style={{ textAlign: "center" }}
                                    >
                                      SMS Type
                                    </div>
                                  </div>{" "}
                                </th>
                                <th className="td_col6">
                                  {" "}
                                  <div className="table_head_OrderList">
                                    <div
                                      className="table_head_nnb1"
                                      style={{ textAlign: "center" }}
                                    >
                                      Template
                                    </div>
                                  </div>{" "}
                                </th>
                                <th className="td_col6 td_width_sms">Dlt</th>
                              </tr>
                            </thead>
                            <tbody className="tbody_body_m1">
                              {SmsTemplateData && SmsTemplateData.length > 0 ? (
                                SmsTemplateData.map((sms, index) => (
                                  <tr key={index} className="table_tbody_Order_List">
                                    <td className="td_col6">{index + 1}</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>{sms.smstypE_Name}</td>
                                    <td className="td_col6" style={{ textAlign: "center" }}>{sms.title}</td>
                                    <td className="" style={{ textAlign: "center" }}>
                                      {/* <button className="btn-table1"> */}
                                      <i className="fa-solid fa-trash delete-btn"></i>
                                      {/* </button> */}
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <></>
                              )}


                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div>
                        {isModalOpenQuery && (
                          <div className="modal">
                            <div className="AddNewItem-modal modal_level_ordlist">
                              <div className="modal-title">
                                <h3 className="modal-OrderLIst">
                                  Query Name Master
                                </h3>
                                <span
                                  className="close_modal"
                                  onClick={hideModalQuery}
                                >
                                  &times;
                                </span>
                              </div>
                              <div className="ModalContentModalSettingP ">
                                <ModalQueryName
                                  SaveQuery={SaveQuery}
                                  handleInputChangeForQuery={
                                    handleInputChangeForQuery
                                  }
                                  inputValuesForQuery={inputValuesForQuery}
                                  setInputValuesForQuery={
                                    setInputValuesForQuery
                                  }
                                  updateQuery={updateQuery}
                                  deleteQuery={deleteQuery}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div>
                        {isModalOpenForm && (
                          <div className="modal">
                            <div className="AddNewItem-modal modal_level_ordlist">
                              <div className="modal-title">
                                <h3 className="modal-OrderLIst">
                                  Miscellaneous Master
                                </h3>
                                <span
                                  className="close_modal"
                                  onClick={hideModalForm}
                                >
                                  &times;
                                </span>
                              </div>
                              <div className="ModalContentModalSettingP ">
                                <MiscellaneousMaster
                                  controlType={controlType}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="buttom-box">
            <div className="button-btn-1">
              <button className="btn-1">Save</button>
            </div>
            <div className="button-btn-2">
              <button className="btn-2">Reset</button>
            </div>
            <div className="button-btn-3">
              <button className="btn-3" onClick={showModal}>
                <i className="fa-solid fa-gear "></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      {alertBoxEdit && (
        <AlertBox
          HeadingTop="Updated"
          Heading="Your Item Master is Updated"
          onYesClick={OpenAlertBoxEdit}
          singleButton={true}
          OpenAlertBox={OpenAlertBoxEdit}
        />
      )}

      {alertBoxError && (
        <AlertBoxError
          HeadingTop="ERROR"
          Heading="Error While Updating Data"
          onYesClick={OpenAlertBoxError}
          singleButton={true}
          OpenAlertBox={OpenAlertBoxError}
        />
      )}
      {loading && <Loader />}
    </div>
  );
}

export default SmsTemplate1;
