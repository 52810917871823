import React from "react";

function AllowanceMaster(props) {

    const DepartmentSelected = () => {
        var name = document.getElementById('DepartmentName').value;
        if (name === 'IT') {
            document.getElementById('Table').style.display = "block";
        }
        else {
            document.getElementById('Table').style.display = "none";
        }
    }
    const editButonClick = (val) => {
        if (val === 'HOD Allowance' || val === 'Medical') {
            document.getElementById('AllowanceName').value = val;
            document.getElementById('A-save').style.display = "block";
        }
        else {
            document.getElementById('DeductionName').value = val;
            document.getElementById('D-save').style.display = "block";
        }
    }

    return (
        <div>
            <div>
                <div>
                    <div className="content_box" style={{ display: "flex" }}>
                        <div className="company-info-top-im">
                            <div className="tab-head">
                                <div id="tabs">
                                    <div className="tab-content-box">
                                        <div className="tab-content-1">
                                            <div className="company-info-top-left" >
                                                <div className="company_info-text-im" style={{ backgroundColor: "#b5bcce" }}>Salary Setup</div>
                                                <div className="">
                                                    <div className="space-between">
                                                        <div className="input-box-Im ">
                                                            <div className="input-box-m2">
                                                                <input className="modify_im_input mode_category"
                                                                    type="text" required id="AllowanceName" />
                                                                <label className="modify_p_text2">Allowance Name</label>
                                                            </div>
                                                            <i className="fa-solid fa-circle-plus mt-2" style={{ color: "green" }}></i>&nbsp; &nbsp;
                                                            <i className="fa-solid fa-floppy-disk mt-2" style={{ color: "red", display: "none" }} id="A-save"></i>
                                                        </div>
                                                        <div className="input-box-Im ">
                                                            <div className="input-box-m2">
                                                                <input className="modify_im_input mode_category"
                                                                    type="text" required id="DeductionName" />
                                                                <label className="modify_p_text2">Deduction Name</label>
                                                            </div>
                                                            <i className="fa-solid fa-circle-plus mt-2" style={{ color: "green" }}></i>&nbsp; &nbsp;
                                                            <i className="fa-solid fa-floppy-disk mt-2" style={{ color: "red", display: "none" }} id="D-save"></i>
                                                        </div>
                                                    </div>
                                                    <div className="space-evenly mt-2">
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th className="th" style={{ backgroundColor: "#ebeef3" }}>Allowance Id</th>
                                                                    <th className="th" style={{ backgroundColor: "#ebeef3" }}>Allowance Name</th>
                                                                    <th className="th" style={{ backgroundColor: "#ebeef3" }}>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td className="td">1</td>
                                                                    <td className="td">HOD Allowance</td>
                                                                    <td className="td"><i className="fa-solid fa-pencil" onClick={() => editButonClick('HOD Allowance')} style={{ color: "darkblue" }}></i></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="td">2</td>
                                                                    <td className="td">Medical</td>
                                                                    <td className="td"><i className="fa-solid fa-pencil" onClick={() => editButonClick('Medical')} style={{ color: "darkblue" }}></i></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        &nbsp; &nbsp;
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th className="th" style={{ backgroundColor: "#ebeef3" }}>Deduction Id</th>
                                                                    <th className="th" style={{ backgroundColor: "#ebeef3" }}>Deduction Name</th>
                                                                    <th className="th" style={{ backgroundColor: "#ebeef3" }}>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td className="td">1</td>
                                                                    <td className="td">Advance</td>
                                                                    <td className="td"><i className="fa-solid fa-pencil" onClick={() => editButonClick('Advance')} style={{ color: "darkblue" }}></i></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="td">2</td>
                                                                    <td className="td">Bus Fee</td>
                                                                    <td className="td"><i className="fa-solid fa-pencil" onClick={() => editButonClick('Bus Fee')} style={{ color: "darkblue" }}></i></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>

                                            {/*********************************/}
                                            <div className="company-info-top-right">
                                                <div className="company_info-text-im" style={{ backgroundColor: "#b5bcce" }}>Other Salary Setting</div>
                                                <div>
                                                    <div className="space-between">
                                                        <div className="input-box-Im mt-1">
                                                            <div className="input-box-m2">
                                                                <input className="modify_im_input mode_category"
                                                                    type="text" required />
                                                                <label className="modify_p_text2">PF Percent</label>
                                                            </div>
                                                        </div>
                                                        <div className="input-box-Im mt-1">
                                                            <div className="input-box-m2">
                                                                <input className="modify_im_input mode_category"
                                                                    type="text" required />
                                                                <label className="modify_p_text2">Limit of PF Deduction Upto</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="space-between">
                                                        <div className="input-box-Im mt-1">
                                                            <div className="input-box-m2">
                                                                <input className="modify_im_input mode_category"
                                                                    type="text" required />
                                                                <label className="modify_p_text2">ESIC Percent</label>
                                                            </div>
                                                        </div>
                                                        <div className="input-box-Im mt-1">
                                                            <div className="input-box-m2">
                                                                <input className="modify_im_input mode_category"
                                                                    type="text" required />
                                                                <label className="modify_p_text2">ESIC Limit</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="space-between">
                                                        <div className="input-box-Im mt-1">
                                                            <div className="input-box-m2">
                                                                <input className="modify_im_input mode_category"
                                                                    type="text" required />
                                                                <label className="modify_p_text2">ESIC Percent Employeer</label>
                                                            </div>
                                                        </div>
                                                        <div className="input-box-Im mt-1">
                                                            <div className="input-box-m2">
                                                                <input className="modify_im_input mode_category"
                                                                    type="text" required />
                                                                <label className="modify_p_text2">DA Percent</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="space-between">
                                                        <div className="input-box-Im mt-1">
                                                            <div className="input-box-m2">
                                                                <input className="modify_im_input mode_category"
                                                                    type="text" required />
                                                                <label className="modify_p_text2">TDS Deduct Percent</label>
                                                            </div>
                                                        </div>
                                                        <div className="input-box-Im mt-1">
                                                            <div className="input-box-m2">
                                                                <input className="modify_im_input mode_category"
                                                                    type="text" required />
                                                                <label className="modify_p_text2">Fixed DA Percent</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="input-box-Im mt-1">
                                                        <div className="input-box-m2">
                                                            <input className="modify_im_input mode_category"
                                                                type="text" required />
                                                            <label className="modify_p_text2">Limit of TDS Deduction</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*********************************/}
                                    <div className="company-info-top-bottom" >
                                        <div className="company_info-text-im" style={{ backgroundColor: "#b5bcce" }}>Leave Setting</div>
                                        <div className="flex-start">
                                            <label>Department &nbsp;</label>
                                            <select className="" id="DepartmentName" onChange={DepartmentSelected}>
                                                <option selected disabled>Select</option>
                                                <option value="IT">IT Department</option>
                                                <option value="Account Department">Account Department</option>
                                                <option value="Sales&Marketing Department">Sales & Marketing Department</option>
                                            </select>
                                        </div>
                                        <div className="mt-2" id="Table" style={{ display: "none" }}>
                                            <table style={{ width: "100%" }}>
                                                <thead>
                                                    <tr>
                                                        <th className="th" style={{ backgroundColor: "#ebeef3" }}>S.No</th>
                                                        <th className="th" style={{ backgroundColor: "#ebeef3" }}>Leave Name</th>
                                                        <th className="th" style={{ backgroundColor: "#ebeef3" }}>No. of Leave Available</th>
                                                        <th className="th" style={{ backgroundColor: "#ebeef3" }}>Carry forward from Previous</th>
                                                        <th className="th" style={{ backgroundColor: "#ebeef3" }}>Monthly Allow</th>
                                                        <th className="th" style={{ backgroundColor: "#ebeef3" }}>Input</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="td">1</td>
                                                        <td className="td">CL</td>
                                                        <td className="td">10</td>
                                                        <td className="td">02</td>
                                                        <td className="td">02</td>
                                                        <td className="td">
                                                            <select className="tableSelectBox form-control">
                                                                <option selected disabled>Select</option>
                                                                <option>Yearly</option>
                                                                <option>Monthly</option>
                                                            </select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="td">2</td>
                                                        <td className="td">EL</td>
                                                        <td className="td">05</td>
                                                        <td className="td">00</td>
                                                        <td className="td">01</td>
                                                        <td className="td">
                                                            <select className="form-control">
                                                                <option selected disabled>Select</option>
                                                                <option>Yearly</option>
                                                                <option>Monthly</option>
                                                            </select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="td">3</td>
                                                        <td className="td">HPL</td>
                                                        <td className="td">15</td>
                                                        <td className="td">03</td>
                                                        <td className="td">05</td>
                                                        <td className="td">
                                                            <select className="form-control">
                                                                <option selected disabled>Select</option>
                                                                <option>Yearly</option>
                                                                <option>Monthly</option>
                                                            </select>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default AllowanceMaster;
