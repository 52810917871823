import React from 'react'
import { AiOutlinePlus } from 'react-icons/ai';
import { FaBars, FaHome, FaLock, FaMoneyBill, FaUser } from "react-icons/fa";
const SmallSideBar = () => {
  return (
    <div className="smallsbaricon-container">
      <FaUser className='design-smallsbar' />
      <FaBars className='design-smallsbar' />
      <FaHome className='design-smallsbar' />
      <FaLock className='design-smallsbar' />
      <FaMoneyBill className='design-smallsbar' />
      <hr className='hr-design' />
      <AiOutlinePlus className="design-smallsbar" />
    </div>
  )
}

export default SmallSideBar