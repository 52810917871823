
import DateTime from "../../Components/DateTime";
import PageTabs from "../../Components/PageTabs";
import React, { useState } from "react";
import 'react-quill/dist/quill.snow.css'
import MainButton from "../../Props/MainButton";

function TableAllocation() {

    //***************** {title Start} ****************************//
    useState(() => {
        document.title = 'Table Allocation';
    }, []);

    //**************** {Show Hide Start} ***************************//


    //******************* {Show Hide End} **********************//


    //******************* {modal box Start} *******************//
    const [, setModalOpen] = useState(false);
    const showModal = () => {
        setModalOpen(true);
    };

    //******************* {modal box End} ******************//


    return (
        <div>
            <div className="panel-heading">
                <div className="panel-head-title">
                    <h2 className="panel-title">Table Allocation to User</h2>
                </div >
                < PageTabs />
                {/* *******************Save Button End****************** */}
                {/* *******************Top Buttons****************** */}
                <MainButton />
                <div>
                </div>
            </div>
            <div>
                < DateTime />
            </div>
            <div className="content_box">
                <div className="company-info-top-im">
                    <div className="tab-head">
                        <div id="tabs">
                            <div className="tab-content-box">
                                <div className="mmw-1">
                                    <div className="input-box-Im input-height2">
                                        <div className="input-box-m1">
                                            <select className="modify_im_input mode_category ss2" type="text" required>
                                                <option className=" s-1 form-control" value="">Select User</option>
                                                <option className=" s-1 form-control" value="India">Raju</option>
                                                <option className=" s-1 form-control" value="Afghanistan">Prakash</option>
                                                <option className=" s-1 form-control" value="Åland Islands">Yogesh</option>
                                                <option className=" s-1 form-control" value="Albania">Akash</option>
                                                <option className=" s-1 form-control" value="Algeria">Aman</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-content-1">
                                    <table style={{ width: "100%" }} className="styled-table">
                                        <tbody>
                                            <tr className="active-row">
                                                <td><div className="input-box-m1 ">
                                                    <div className="input-box4">
                                                        <input style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                                        <label className="TableAllocation_checkbox"> All products</label>
                                                    </div>
                                                </div></td>
                                                <td><div className="input-box-m1 ">
                                                    <div className="input-box4">
                                                        <input style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                                        <label className="TableAllocation_checkbox"> All products</label>
                                                    </div>
                                                </div></td>
                                                <td><div className="input-box-m1 ">
                                                    <div className="input-box4">
                                                        <input style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                                        <label className="TableAllocation_checkbox"> All products</label>
                                                    </div>
                                                </div></td>
                                                <td><div className="input-box-m1 ">
                                                    <div className="input-box4">
                                                        <input style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                                        <label className="TableAllocation_checkbox"> All products</label>
                                                    </div>
                                                </div></td>
                                            </tr>
                                            <tr className="active-row">
                                                <td><div className="input-box-m1 ">
                                                    <div className="input-box4">
                                                        <input style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                                        <label className="TableAllocation_checkbox"> All products</label>
                                                    </div>
                                                </div></td>
                                                <td><div className="input-box-m1 ">
                                                    <div className="input-box4">
                                                        <input style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                                        <label className="TableAllocation_checkbox"> All products</label>
                                                    </div>
                                                </div></td>
                                                <td><div className="input-box-m1 ">
                                                    <div className="input-box4">
                                                        <input style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                                        <label className="TableAllocation_checkbox"> All products</label>
                                                    </div>
                                                </div></td>
                                                <td><div className="input-box-m1 ">
                                                    <div className="input-box4">
                                                        <input style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                                        <label className="TableAllocation_checkbox"> All products</label>
                                                    </div>
                                                </div></td>
                                            </tr>
                                            <tr className="active-row">
                                                <td><div className="input-box-m1 ">
                                                    <div className="input-box4">
                                                        <input style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                                        <label className="TableAllocation_checkbox"> All products</label>
                                                    </div>
                                                </div></td>
                                                <td><div className="input-box-m1 ">
                                                    <div className="input-box4">
                                                        <input style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                                        <label className="TableAllocation_checkbox"> All products</label>
                                                    </div>
                                                </div></td>
                                                <td><div className="input-box-m1 ">
                                                    <div className="input-box4">
                                                        <input style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                                        <label className="TableAllocation_checkbox"> All products</label>
                                                    </div>
                                                </div></td>
                                                <td><div className="input-box-m1 ">
                                                    <div className="input-box4">
                                                        <input style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                                        <label className="TableAllocation_checkbox"> All products</label>
                                                    </div>
                                                </div></td>
                                            </tr>
                                            <tr className="active-row">
                                                <td><div className="input-box-m1 ">
                                                    <div className="input-box4">
                                                        <input style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                                        <label className="TableAllocation_checkbox"> All products</label>
                                                    </div>
                                                </div></td>
                                                <td><div className="input-box-m1 ">
                                                    <div className="input-box4">
                                                        <input style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                                        <label className="TableAllocation_checkbox"> All products</label>
                                                    </div>
                                                </div></td>
                                                <td><div className="input-box-m1 ">
                                                    <div className="input-box4">
                                                        <input style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                                        <label className="TableAllocation_checkbox"> All products</label>
                                                    </div>
                                                </div></td>
                                                <td><div className="input-box-m1 ">
                                                    <div className="input-box4">
                                                        <input style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />
                                                        <label className="TableAllocation_checkbox"> All products</label>
                                                    </div>
                                                </div></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="buttom-box">
                        <div className="button-btn-1">
                            <button className="btn-1">Save</button>
                        </div>
                        <div className="button-btn-2">
                            <button className="btn-2">Reset</button>
                        </div>
                        <div className="button-btn-3">
                            <button className="btn-3" onClick={showModal}><i className="fa-solid fa-gear "></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}


export default TableAllocation;
