import axios from "../axiosConfig"; // Adjust the import path accordingly

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);


const ItemMaster = {
  InsertItemMaster: async (
    data,
    AttributeListData,
    restoAvailableTime1,
    commaSeparatedTags1,
    isAttributeItemTF,
    checkedItemIds,
    shortDiscription,
    longDescription
  ) => {
    debugger

    //debugger;

    var attributeDataList = [];
    for (var i = 0; i < AttributeListData.length; i++) {
      var obj = {
        itmId: Number(AttributeListData[i].itmId),
        sn: Number(AttributeListData[i].sn),
        qty: Number(AttributeListData[i].qty),
        purchasePrice: Number(AttributeListData[i].purchasePrice),
        salePrice: Number(AttributeListData[i].salePrice),
        mrp: Number(AttributeListData[i].mrp),
        attributes: AttributeListData[i].attributes,
      };
      attributeDataList.push(obj);
    }

    console.log(data);
    console.log(restoAvailableTime1);
    console.log(AttributeListData);
    function parseBoolean(v) {
      if (typeof v === "boolean") {
        return v;
      } else if (typeof v === "string") {
        v = v.toLowerCase().trim();
        return v === "true";
      } else {
        console.log("error", v);
        return false;
      }
    }
    try {
      const apiUrl = `${WebApiUrl}/api/ItemMaster`;
      const response = await axios.post(
        apiUrl,
        {
          d1_Id: Number(data.D1_Id),
          d2_Id: Number(data.D2_Id),
          isFavorite: parseBoolean(data.IsFavorite),
          isActive: parseBoolean(data.IsActive),
          businessType: Number(data.BusinessType),
          productName: data.ProductName,
          productAlias: data.ProductAlias,
          printName: data.PrintName,
          parentGrp: Number(data.ParentGrp),
          parentGrp2: Number(data.parentGrp2),
          productUnit: Number(data.ProductUnit),
          productAltUnit: Number(data.ProductAltUnit),
          unitConvFactor: Number(data.UnitConvFactor),
          itemDepartment: Number(data.ItemDepartment),
          productType: Number(data.ProductType),
          sku: data.SKU,
          productCategory: Number(data.ProductCategory),
          enableBarcode: parseBoolean(data.EnableBarcode),
          defaultBarcode: data.DefaultBarcode,
          taxCategory1: Number(data.TaxCategory1),
          taxLimit: Number(data.TaxLimit),
          taxCategory2: Number(data.TaxCategory2),
          taxOnMRP: parseBoolean(data.TaxOnMRP),
          hSNCode: data.hsnCode,
          shortDescription: shortDiscription,
          longDescription: longDescription,
          displayOrder: data.DisplayOrder,
          preparationTime: Number(data.PreparationTime),
          mealCourseType: Number(data.MealCourseType),
          restoAvailableTime: restoAvailableTime1,
          openingStock: data.OpeningStock,
          openingStock_barcodeGodown: [
            {
              godownId: 0,
              qty: 0,
              stockValue: 0,
              barcode: "",
              costPrice: 0,
              sellingPrice: 0,
            },
          ],
          openingStockValue: Number(data.OpeningStockValue),
          purchasePrice: Number(data.PurchasePrice),
          costPrice: Number(data.CostPrice),
          saleProfit: Number(data.SaleProfit),
          salePrice: Number(data.SalePrice),
          saleDiscount: Number(data.SaleDiscount),
          mrpPrice: Number(data.MrpPrice),
          purchaseDiscount: Number(data.PurchaseDiscount),
          minSalePrice: Number(data.MinSalePrice),
          branchPrice: Number(data.BranchPrice),
          franchisePrice: Number(data.FranchisePrice),
          normalLoss: Number(data.NormalLoss),
          ecomSalePrice: Number(data.EcomSalePrice),
          stockCheck: Number(data.StockCheck),
          virtualQuantity: Number(data.VirtualQuantity),
          availableQuantity: Number(data.AvailableQuantity),
          minQtyToPurchase: Number(data.MinQtyToPurchase),
          maxQtyToPurchase: Number(data.MaxQtyToPurchase),
          incrementQty: Number(data.IncrementQty),
          iDefaultGodown: Number(data.IDefaultGodown),
          iCateRateA: Number(data.ICateRateA),
          iCateRateB: Number(data.ICateRateB),
          iCateRateC: Number(data.ICateRateC),
          iCateRateD: Number(data.ICateRateD),
          iCateRateE: Number(data.ICateRateE),
          iCateRateF: Number(data.ICateRateF),
          iCateRateG: Number(data.ICateRateG),
          iCateRateH: Number(data.ICateRateH),
          iCateRateI: Number(data.ICateRateI),
          iCateRateJ: Number(data.ICateRateJ),
          loyaltyPoint: Number(data.LoyaltyPoint),
          referalAmountDirect: Number(data.ReferalAmountDirect),
          referalAmountIndirect: Number(data.ReferalAmountIndirect),
          salesManItemIncentive: Number(data.SalesManItemIncentive),
          sellerCommision: Number(data.SellerCommision),
          buyX: Number(data.BuyX),
          getX: Number(data.GetX),
          offerTagInCategory: data.OfferTagInCategory,
          offerTagInProduct: data.OfferTagInProduct,
          schemeType: Number(data.SchemeType),
          enableSerialNo: parseBoolean(data.EnableSerialNo),
          enableBatchNo: parseBoolean(data.EnableBatchNo),
          location: data.Location,
          rackNo: data.RackNo,
          enableStockReorder: parseBoolean(data.EnableStockReorder),
          maxStockLevelQty: Number(data.MaxStockLevelQty),
          minStockLevelQty: Number(data.MinStockLevelQty),
          reorderLevelQty: Number(data.ReorderLevelQty),
          enableLowStockWarning: parseBoolean(data.EnableLowStockWarning),
          isReturnable: parseBoolean(data.IsReturnable),
          isConsumable: parseBoolean(data.IsConsumable),
          isStockable: parseBoolean(data.IsStockable),
          allowRawMaterial: parseBoolean(data.AllowRawMaterial),
          defaultVendor: Number(data.DefaultVendor),
          lens_SPH: Number(data.Lens_SPH),
          lens_CYL: Number(data.Lens_CYL),
          lens_AXIS: Number(data.Lens_AXIS),
          lens_Dia: data.Lens_Dia,
          lens_Add: Number(data.Lens_Add),
          attributeList: attributeDataList,
          lensAttributeList: [
            {
              barcode: 0,
              spH_frm: 0,
              spH_to: 0,
              spH_stp: 0,
              cyL_frm: 0,
              cyL_to: 0,
              cyL_stp: 0,
              axiS_frm: 0,
              axiS_to: 0,
              axiS_stp: 0,
              ad_frm: 0,
              ad_to: 0,
              ad_stp: 0,
              dia: 0,
            },
          ],
          isAttributeItem: parseBoolean(isAttributeItemTF),
          iAttributeParent: Number(data.IAttributeParent),
          drugCompany: data.DrugCompany,
          drugMFDB: data.DrugMFDB,
          drugSaltName: Number(data.DrugSaltName),
          drugManufacturer: Number(data.DrugManufacturer),
          drugStorageType: data.DrugStorageType,
          drugTBItem: Number(data.DrugTBItem),
          drugSchedule_H: parseBoolean(data.DrugSchedule_H),
          drugSchedule_H1: parseBoolean(data.DrugSchedule_H1),
          drugSchedule_C: parseBoolean(data.DrugSchedule_C),
          drugSchedule_C1: parseBoolean(data.DrugSchedule_C1),
          drugSchedule_G: parseBoolean(data.DrugSchedule_G),
          drugAnti_TB: parseBoolean(data.DrugAnti_TB),
          drugNorcotics: parseBoolean(data.DrugNorcotics),

          batchDetails: [
            {
              godownId: 0,
              batchNo: "",
              mfgDate: "2024-02-10",
              expDate: "2024-02-10",
              qty: 0,
              purPrice: 0,
              salePrice: 0,
              mrp: 0,
              costPrice: 0,
              opPack: "",
              opLoose: "",
              opValue: "",
            },
          ],
          useInEcom: parseBoolean(data.UseInEcom),
          availableDateTo: (data.AvailableDateTo),
          availableDatefrom: (data.AvailableDatefrom),
          showOnHomePage: parseBoolean(data.ShowOnHomePage),
          disableBuyButton: parseBoolean(data.DisableBuyButton),
          disableWishListButton: parseBoolean(data.DisableWishListButton),
          visibleIndividually: parseBoolean(data.VisibleIndividually),
          allowCustomerReviews: parseBoolean(data.AllowCustomerReviews),
          allowCustomerRemarks: parseBoolean(data.AllowCustomerRemarks),
          published: parseBoolean(data.Published),
          addasComplementaryItem: parseBoolean(data.AddasComplementaryItem),
          availableForHomeDelivery: parseBoolean(data.AvailableForHomeDelivery),
          availableForTableOrder: parseBoolean(data.AvailableForTableOrder),
          availableForPOS: parseBoolean(data.AvailableForPOS),
          itemAmtInComplementBonusAmt: parseBoolean(
            data.ItemAmtInComplementBonusAmt
          ),
          intermediate: parseBoolean(data.Intermediate),
          popularCategoryPriority: Number(data.PopularCategoryPriority),
          productTag: commaSeparatedTags1,
          specifyIndexNo: Number(data.SpecifyIndexNo),
          measurmentGuideTool: data.MeasurmentGuideTool,
          measurmentHeightImage: data.MeasurmentHeightImage,
          heightTitle: Number(data.HeightTitle),
          height: Number(data.Height),
          measurmentWidthImage: data.MeasurmentWidthImage,
          widthTilte: Number(data.WidthTilte),
          width: Number(data.Width),
          measurmentWeightImage: data.MeasurmentWeightImage,
          weightTitle: Number(data.WeightTitle),
          weight: Number(data.Weight),
          measurmentLengthImage: data.MeasurmentLengthImage,
          lengthTitle: Number(data.LengthTitle),
          length: Number(data.Length),
          enableShipping: parseBoolean(data.EnableShipping),
          cashOnDeliveryAvailable: parseBoolean(data.CashOnDeliveryAvailable),
          freeShipping: parseBoolean(data.FreeShipping),
          shipSeparately: parseBoolean(data.ShipSeparately),
          localShippingCharge: Number(data.LocalShippingCharge),
          zonalShippingCharge: Number(data.ZonalShippingCharge),
          nationalShippingCharge: Number(data.NationalShippingCharge),
          returnApplicationDays: Number(data.ReturnApplicationDays),
          returnPolicyNotes: data.ReturnPolicyNotes,
          tryAtHome: parseBoolean(data.TryAtHome),
          additionShippingCharge: Number(data.AdditionShippingCharge),
          ecomCategoryDetail: checkedItemIds,
          //   [0]
          metaKeywords: data.MetaKeywords,
          metaDescription: data.MetaDescription,
          metaTitle: data.MetaTitle,
          searchEnginFriendlyPageName: data.SearchEnginFriendlyPageName,
          isDeleted: parseBoolean(data.IsDeleted),
        },
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      console.log("Response:", response.data);
      return response.data;
      // setMainDataCallback(response.data.result);
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  GetItemMaster: async () => {
    try {
      const apiUrl = `${WebApiUrl}/api/ItemMaster/GetItemMasters`;
      const response = await axios.post(
        apiUrl,
        {
          filter: [],
          order: [
            {
              propertyName: "d1Id",
              ascending: false
            }
          ],
          pageNumber: 0,
          pageSize: 500000
        },
        {
          withCredentials: true,
          credentials: 'include',
        }
      );
      console.log("Response:", response);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },
  
    GetItemMasterWithPagination: async (payload) => {
    try {
      const apiUrl = `${WebApiUrl}/api/ItemMaster/GetItemMasters`;
      const response = await axios.post(
        apiUrl,
        // {
        //   filter: [],
        //   order: [
        //     {
        //       propertyName: "d1Id",
        //       ascending: false,
        //     },
        //   ],
        //   pageNumber: 0,
        //   pageSize: 500000,
        // }
        payload,
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      console.log("Response:", response);
      return response.data;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },


  ItemMasterImageUpload: async (data) => {
    console.log(data);
    try {
      // Assuming 'data' is a FormData object with appended fields
      const apiUrl = `${WebApiUrl}/api/ItemMaster/SaveImage`;

      const response = await axios.post(apiUrl, data, {
        withCredentials: true,
        credentials: 'include',
        // headers: {
        //   "Content-Type": "multipart/form-data", // Set Content-Type for FormData
        // },
      });

      console.log("Response:", response);
      return response.data.isSuccess;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  GetItemMasterById: async (ItemMasterId) => {
    try {
      const apiUrl = `${WebApiUrl}/api/ItemMaster?ItemMasterId=${ItemMasterId}`;
      const response = await axios.get(
        apiUrl,
        {},
        {
          withCredentials: true,
          credentials: 'include',
        }
      );
      console.log("Response:", response.data.result);
      //setEditCallBack(response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  GetItemMasterByD2Id: async (ItemMasterId) => {
    try {
      const apiUrl = `${WebApiUrl}/api/ItemMaster/GetItemByD2Id?ItemMasterId=${ItemMasterId}`;
      const response = await axios.get(
        apiUrl,
        {},
        {
          withCredentials: true,
          credentials: 'include',
        }
      );
      console.log("Response:", response.data.result);
      //setEditCallBack(response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  deleteItemMaster: async (ItemMasterId) => {
   
    console.log(ItemMasterId);
    try {
      console.log("delete");
      const apiUrl = `${WebApiUrl}/api/ItemMaster?ItemMasterId=${ItemMasterId}`;
      const response = await axios.delete(apiUrl, {
        withCredentials: true,
        credentials: 'include',
      });
      console.log("Delete Response:", response.data.result);
      return true;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },


  UpdateItemMasterImage: async (imageArray) => {
    debugger;
    try {
      console.log(imageArray);

      const apiUrl = `${WebApiUrl}/api/ItemMaster/UpdateItemMasterImage`;
      const response = await axios.post(apiUrl, imageArray, {
        withCredentials: true,
        credentials: 'include',
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("Response:", response.data.isSuccess);
      if (!response.data.isSuccess) {
        console.error("Update failed:", response.data.errorMessage);
      }

      await new Promise((resolve) => setTimeout(resolve, 1000)); // Use Promise-based delay

      // If you want to return something after all files are processed successfully, do it here
      return true;
    } catch (error) {
      console.error("Error:", error.message);
      // You might want to throw the error or handle it based on your application's requirements
      throw error;
    }
  },

  GetimageMasterByID: async (ItemID) => {
    debugger
    try {
      const apiUrl = `${WebApiUrl}/api/ItemMaster/${ItemID}`;
      const response = await axios.get(
        apiUrl,
        {},
        { withCredentials: true, credentials: "include" }
      );

      console.log("Response:", response.data.isSuccess);
      if (!response.data.isSuccess) {
        // Handle the failure based on your application's requirements
        console.error("Update failed:", response.data.errorMessage);
      }
    } catch (error) {
      console.error("Error:", error.message);
      // You might want to throw the error or handle it based on your application's requirements
      throw error;
    }
  },

  // GetimageMasterByID: async (ItemID) => {
  //   try {
  //     const apiUrl = `${WebApiUrl}/api/ItemMaster/${ItemID}`;
  //     const response = await axios.get(
  //       apiUrl,
  //       {},
  //       { withCredentials: true, credentials: "include" }
  //     );

  //     console.log("Response:", response.data.isSuccess);
  //     if (!response.data.isSuccess) {
  //       // Handle the failure based on your application's requirements
  //       console.error("Update failed:", response.data.errorMessage);
  //     }
  //   } catch (error) {
  //     console.error("Error:", error.message);
  //     // You might want to throw the error or handle it based on your application's requirements
  //     throw error;
  //   }
  // },


  SaveItemAttributeSettings: async (data) => {
    const processedData = data.map((attr) => ({
      sn: attr.sn || 0,
      attributeId: attr.attrId || 0,
      attributeName: attr.attrName || "",
      isActive: Boolean(attr.isActive) || false,
      priority: Number(attr.priority) || 0,
      attributeChilds: attr.attributeChilds,
    }));
    console.log(processedData);
    //debugger;

    try {
      const apiUrl = `${WebApiUrl}/api/ItemMaster/SaveItemAttributeSettings`;
      const response = await axios.post(
        apiUrl, {processedData},
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      console.log("Response:", response);
      return response;
      // setMainDataCallback(response.data.result);
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  GetItemAttributeSettings: async () => {
    try {
      const apiUrl = `${WebApiUrl}/api/ItemMaster/GetItemAttributeSettings`;
      const response = await axios.get(
        apiUrl,
        {},
        {
          withCredentials: true,
          credentials: 'include',
        }
      );
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  updateItemMaster: async (data,
    AttributeListData,
    restoAvailableTime1,
    commaSeparatedTags1,
    isAttributeItemTF,
    checkedItemIds,
    shortDiscription,
    longDescription
  ) => {
    var attributeDataList = [];
    for (var i = 0; i < AttributeListData.length; i++) {
      var obj = {
        itmId: Number(AttributeListData[i].itmId),
        sn: Number(AttributeListData[i].sn),
        qty: Number(AttributeListData[i].qty),
        purchasePrice: Number(AttributeListData[i].purchasePrice),
        salePrice: Number(AttributeListData[i].salePrice),
        mrp: Number(AttributeListData[i].mrp),
        attributes: AttributeListData[i].attributes,
      };
      attributeDataList.push(obj);
    }

    console.log(data);
    console.log(restoAvailableTime1);
    console.log(AttributeListData);


    function parseBoolean(v) {
      if (typeof v === "boolean") {
        return v;
      } else if (typeof v === "string") {
        v = v.toLowerCase().trim();
        return v === "true";
      } else {
        console.log("error", v);
        return false;
      }
    }
    debugger
    console.log(data);
    try {
      const apiUrl = `${WebApiUrl}/api/ItemMaster?ItemMasterId=${data.D1_Id}`;
      const response = await axios.put(
        apiUrl,
        {
          d1_Id: Number(data.D1_Id),
          d2_Id: Number(data.D2_Id),
          isFavorite: parseBoolean(data.IsFavorite),
          isActive: parseBoolean(data.IsActive),
          businessType: Number(data.BusinessType),
          productName: data.ProductName,
          productAlias: data.ProductAlias,
          printName: data.PrintName,
          parentGrp: Number(data.ParentGrp),
          parentGrp2: Number(data.parentGrp2),
          productUnit: Number(data.ProductUnit),
          productAltUnit: Number(data.ProductAltUnit),
          unitConvFactor: Number(data.UnitConvFactor),
          itemDepartment: Number(data.ItemDepartment),
          productType: Number(data.ProductType),
          sku: data.SKU,
          productCategory: Number(data.ProductCategory),
          enableBarcode: parseBoolean(data.EnableBarcode),
          defaultBarcode: data.DefaultBarcode,
          taxCategory1: Number(data.TaxCategory1),
          taxLimit: Number(data.TaxLimit),
          taxCategory2: Number(data.TaxCategory2),
          taxOnMRP: parseBoolean(data.TaxOnMRP),
          hSNCode: data.hsnCode,
          shortDescription: shortDiscription,
          longDescription: longDescription,
          displayOrder: data.DisplayOrder,
          preparationTime: Number(data.PreparationTime),
          mealCourseType: Number(data.MealCourseType),
          restoAvailableTime: restoAvailableTime1,
          openingStock: data.OpeningStock,
          openingStock_barcodeGodown: [
            {
              godownId: 0,
              qty: 0,
              stockValue: 0,
              barcode: "",
              costPrice: 0,
              sellingPrice: 0,
            },
          ],
          openingStockValue: Number(data.OpeningStockValue),
          purchasePrice: Number(data.PurchasePrice),
          costPrice: Number(data.CostPrice),
          saleProfit: Number(data.SaleProfit),
          salePrice: Number(data.SalePrice),
          saleDiscount: Number(data.SaleDiscount),
          mrpPrice: Number(data.MrpPrice),
          purchaseDiscount: Number(data.PurchaseDiscount),
          minSalePrice: Number(data.MinSalePrice),
          branchPrice: Number(data.BranchPrice),
          franchisePrice: Number(data.FranchisePrice),
          normalLoss: Number(data.NormalLoss),
          ecomSalePrice: Number(data.EcomSalePrice),
          stockCheck: Number(data.StockCheck),
          virtualQuantity: Number(data.VirtualQuantity),
          availableQuantity: Number(data.AvailableQuantity),
          minQtyToPurchase: Number(data.MinQtyToPurchase),
          maxQtyToPurchase: Number(data.MaxQtyToPurchase),
          incrementQty: Number(data.IncrementQty),
          iDefaultGodown: Number(data.IDefaultGodown),
          iCateRateA: Number(data.ICateRateA),
          iCateRateB: Number(data.ICateRateB),
          iCateRateC: Number(data.ICateRateC),
          iCateRateD: Number(data.ICateRateD),
          iCateRateE: Number(data.ICateRateE),
          iCateRateF: Number(data.ICateRateF),
          iCateRateG: Number(data.ICateRateG),
          iCateRateH: Number(data.ICateRateH),
          iCateRateI: Number(data.ICateRateI),
          iCateRateJ: Number(data.ICateRateJ),
          loyaltyPoint: Number(data.LoyaltyPoint),
          referalAmountDirect: Number(data.ReferalAmountDirect),
          referalAmountIndirect: Number(data.ReferalAmountIndirect),
          salesManItemIncentive: Number(data.SalesManItemIncentive),
          sellerCommision: Number(data.SellerCommision),
          buyX: Number(data.BuyX),
          getX: Number(data.GetX),
          offerTagInCategory: data.OfferTagInCategory,
          offerTagInProduct: data.OfferTagInProduct,
          schemeType: Number(data.SchemeType),
          enableSerialNo: parseBoolean(data.EnableSerialNo),
          enableBatchNo: parseBoolean(data.EnableBatchNo),
          location: data.Location,
          rackNo: data.RackNo,
          enableStockReorder: parseBoolean(data.EnableStockReorder),
          maxStockLevelQty: Number(data.MaxStockLevelQty),
          minStockLevelQty: Number(data.MinStockLevelQty),
          reorderLevelQty: Number(data.ReorderLevelQty),
          enableLowStockWarning: parseBoolean(data.EnableLowStockWarning),
          isReturnable: parseBoolean(data.IsReturnable),
          isConsumable: parseBoolean(data.IsConsumable),
          isStockable: parseBoolean(data.IsStockable),
          allowRawMaterial: parseBoolean(data.AllowRawMaterial),
          defaultVendor: Number(data.DefaultVendor),
          lens_SPH: Number(data.Lens_SPH),
          lens_CYL: Number(data.Lens_CYL),
          lens_AXIS: Number(data.Lens_AXIS),
          lens_Dia: data.Lens_Dia,
          lens_Add: Number(data.Lens_Add),
          attributeList: attributeDataList,
          lensAttributeList: [
            {
              barcode: 0,
              spH_frm: 0,
              spH_to: 0,
              spH_stp: 0,
              cyL_frm: 0,
              cyL_to: 0,
              cyL_stp: 0,
              axiS_frm: 0,
              axiS_to: 0,
              axiS_stp: 0,
              ad_frm: 0,
              ad_to: 0,
              ad_stp: 0,
              dia: 0,
            },
          ],
          isAttributeItem: parseBoolean(isAttributeItemTF),
          iAttributeParent: Number(data.IAttributeParent),
          drugCompany: data.DrugCompany,
          drugMFDB: data.DrugMFDB,
          drugSaltName: Number(data.DrugSaltName),
          drugManufacturer: Number(data.DrugManufacturer),
          drugStorageType: data.DrugStorageType,
          drugTBItem: Number(data.DrugTBItem),
          drugSchedule_H: parseBoolean(data.DrugSchedule_H),
          drugSchedule_H1: parseBoolean(data.DrugSchedule_H1),
          drugSchedule_C: parseBoolean(data.DrugSchedule_C),
          drugSchedule_C1: parseBoolean(data.DrugSchedule_C1),
          drugSchedule_G: parseBoolean(data.DrugSchedule_G),
          drugAnti_TB: parseBoolean(data.DrugAnti_TB),
          drugNorcotics: parseBoolean(data.DrugNorcotics),

          batchDetails: [
            {
              godownId: 0,
              batchNo: "",
              mfgDate: "2024-02-10",
              expDate: "2024-02-10",
              qty: 0,
              purPrice: 0,
              salePrice: 0,
              mrp: 0,
              costPrice: 0,
              opPack: "",
              opLoose: "",
              opValue: "",
            },
          ],
          availableDateTo: (data.AvailableDateTo),
          availableDatefrom: (data.AvailableDatefrom),
          useInEcom: parseBoolean(data.UseInEcom),
          showOnHomePage: parseBoolean(data.ShowOnHomePage),
          disableBuyButton: parseBoolean(data.DisableBuyButton),
          disableWishListButton: parseBoolean(data.DisableWishListButton),
          visibleIndividually: parseBoolean(data.VisibleIndividually),
          allowCustomerReviews: parseBoolean(data.AllowCustomerReviews),
          allowCustomerRemarks: parseBoolean(data.AllowCustomerRemarks),
          published: parseBoolean(data.Published),
          addasComplementaryItem: parseBoolean(data.AddasComplementaryItem),
          availableForHomeDelivery: parseBoolean(data.AvailableForHomeDelivery),
          availableForTableOrder: parseBoolean(data.AvailableForTableOrder),
          availableForPOS: parseBoolean(data.AvailableForPOS),
          itemAmtInComplementBonusAmt: parseBoolean(
            data.ItemAmtInComplementBonusAmt
          ),
          intermediate: parseBoolean(data.Intermediate),
          popularCategoryPriority: Number(data.PopularCategoryPriority),
          productTag: commaSeparatedTags1,
          specifyIndexNo: Number(data.SpecifyIndexNo),
          measurmentGuideTool: data.MeasurmentGuideTool,
          measurmentHeightImage: data.MeasurmentHeightImage,
          heightTitle: Number(data.HeightTitle),
          height: Number(data.Height),
          measurmentWidthImage: data.MeasurmentWidthImage,
          widthTilte: Number(data.WidthTilte),
          width: Number(data.Width),
          measurmentWeightImage: data.MeasurmentWeightImage,
          weightTitle: Number(data.WeightTitle),
          weight: Number(data.Weight),
          measurmentLengthImage: data.MeasurmentLengthImage,
          lengthTitle: Number(data.LengthTitle),
          length: Number(data.Length),
          enableShipping: parseBoolean(data.EnableShipping),
          cashOnDeliveryAvailable: parseBoolean(data.CashOnDeliveryAvailable),
          freeShipping: parseBoolean(data.FreeShipping),
          shipSeparately: parseBoolean(data.ShipSeparately),
          localShippingCharge: Number(data.LocalShippingCharge),
          zonalShippingCharge: Number(data.ZonalShippingCharge),
          nationalShippingCharge: Number(data.NationalShippingCharge),
          returnApplicationDays: Number(data.ReturnApplicationDays),
          returnPolicyNotes: data.ReturnPolicyNotes,
          tryAtHome: parseBoolean(data.TryAtHome),
          additionShippingCharge: Number(data.AdditionShippingCharge),
          ecomCategoryDetail: checkedItemIds,
          //   [0]
          metaKeywords: data.MetaKeywords,
          metaDescription: data.MetaDescription,
          metaTitle: data.MetaTitle,
          searchEnginFriendlyPageName: data.SearchEnginFriendlyPageName,
          isDeleted: parseBoolean(data.IsDeleted),
        },
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      console.log("Response:", response.data);
      return response.data;
      // setMainDataCallback(response.data.result);
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  // ************************* Create ITEM Master Setting ***************************
  insertItemMasterSetting: async (data, optionalData) => {
    // let data = [];
    // for (var i = 0; i < optionalData.length; i++) {
    //     data.push({
    //         optionalFieldName: optionalData[i].optionalFieldName,
    //         priority: optionalData[i].priority,
    //         isDeleted: false,
    //         isActive: true
    //     });
    // }

    function parseBoolean(v) {
      if (typeof v === "boolean") {
        return v;
      } else if (typeof v === "string") {
        v = v.toLowerCase().trim();
        return v === "true";
      } else {
        console.log("error", v);
        return false;
      }
    }
    try {
      const apiUrl = `${WebApiUrl}/api/ItemMaster/CreateItemMasterSetting`;
      console.log(apiUrl);
      // console.log(settingData);
      const response = await axios.post(
        apiUrl,
        {
          alias: parseBoolean(data.alias),
          printName: parseBoolean(data.printName),
          barcode: parseBoolean(data.barcode),
          defaultBarcode: parseBoolean(data.defaultBarcode),
          galleryImageUpload: parseBoolean(data.galleryImageUpload),
          doubleTax: parseBoolean(data.doubleTax),
          alternateUnit: parseBoolean(data.alternateUnit),
          taxCategory2: parseBoolean(data.taxCategory2),
          shortDescription: parseBoolean(data.shortDescription),
          longDescription: parseBoolean(data.longDescription),
          onlineSale: parseBoolean(data.onlineSale),
          offerNSchemeSetting: parseBoolean(data.offerNSchemeSetting),
          priceNStockDetails: parseBoolean(data.priceNStockDetails),
          costPrice: parseBoolean(data.costPrice),
          extraDetails: parseBoolean(data.extraDetails),
          shippingDetails: parseBoolean(data.shippingDetails),
          spexMeasurement: parseBoolean(data.spexMeasurement),
          ecomBasedExtra: parseBoolean(data.ecomBasedExtra),
          serialBatchNStockSettings: parseBoolean(
            data.serialBatchNStockSettings
          ),
          allowSerialNoWiseDetails: parseBoolean(data.allowSerialNoWiseDetails),
          allowBatchwiseReporting: parseBoolean(data.allowBatchwiseReporting),
          allowToSetMinMaxReorderLevel: parseBoolean(
            data.allowToSetMinMaxReorderLevel
          ),
          allowSetDefaultVendor: parseBoolean(data.allowSetDefaultVendor),
          allowAttributeItemName: parseBoolean(data.allowAttributeItemName),
          allowMultiplePrice: parseBoolean(data.allowMultiplePrice),
          allowMultiGodown: parseBoolean(data.allowMultiGodown),
          optionalField: parseBoolean(data.optionalField),
          productTypeSKU: parseBoolean(data.productTypeSKU),
          allowLensItem: parseBoolean(data.allowLensItem),
          restaurantDetails : parseBoolean(data.restaurantDetails),
          isActive: parseBoolean(data.isActive),
          accountOptionalFields: [
            {
              optionalFieldName: "",
              priority: 0,
              isDeleted: false,
              isActive: true,
            },
          ],
        },
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      console.log("Response:", response.data);
      return true;
    } catch (error) {
      console.error("Error:", error.message);
      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },
  // ************************* Get ITEM Master Setting ***************************

  getItemMasterSetting: async () => {
    try {
      const apiUrl = `${WebApiUrl}/api/ItemMaster/GetItemMasterSetting`;
      const response = await axios.post(
        apiUrl,
        {},
        {
          withCredentials: true,
          credentials: 'include',
        }
      );
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  // ************************************ Get Item Master With Filter *******************
  GetItemMasterWithFilter: async (filteredData) => {
    const filtData = filteredData.map((data) => ({
      fieldName: data.fieldName,
      operatorName: data.operatorName,
      compareValue: data.compareValue
    }));
    try {
      const apiUrl = `${WebApiUrl}/api/ItemMaster/GetItemMasters`;
      const response = await axios.post(
        apiUrl,
        {
          filter: filtData,
          order: [
            {
              propertyName: "d1Id",
              ascending: false
            }
          ],
          pageNumber: 0,
          pageSize: 500000
        },
        {
          withCredentials: true,
          credentials: 'include',
        }
      );
      console.log("Response:", response);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },


    // ************************************ Get Item Master With Filter And Pagination *******************
    GetItemMasterWithFilterAndPagination: async (
      filteredData,
      pageNumber = 0,
      pageSize = 15
    ) => {
      debugger;
      const filtData = filteredData.map((data) => ({
        fieldName: data.fieldName,
        operatorName: data.operatorName,
        compareValue: data.compareValue,
      }));
      try {
        const apiUrl = `${WebApiUrl}/api/ItemMaster/GetItemMasters`;
        const response = await axios.post(
          apiUrl,
          {
            filter: filtData,
            order: [
              {
                propertyName: "d1Id",
                ascending: false,
              },
            ],
            pageNumber,
            pageSize,
          },
          {
            withCredentials: true,
            credentials: "include",
          }
        );
        console.log("Response:", response);
        return response.data.result;
      } catch (error) {
        console.error("Error:", error.message);
      }
    },
  

  // ************************************ Get Item Master With Filter *******************

  EcomItemsVariation: async (ItemID) => {
    try {
      const apiUrl = `${WebApiUrl}/api/ItemMaster/EcomItemsVariation?ItemMasterId=${ItemID}`;
      const response = await axios.get(
        apiUrl,
        {},
        { withCredentials: true, credentials: "include" }
      );

      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
      // You might want to throw the error or handle it based on your application's requirements
      throw error;
    }
  },

  // ************************************ Get Item Master With Filter *******************

  SaveEcomItemsVariation: async (ItemID) => {
    try {
      debugger;
      const apiUrl = `${WebApiUrl}/api/ItemMaster/SaveEcomItemsVariation`;
      const response = await axios.post(
        apiUrl,
        { ItemID },
        { withCredentials: true, credentials: "include" }
      );

      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
      // You might want to throw the error or handle it based on your application's requirements
      throw error;
    }
  },

  // ************************************ Ecom Item Variation List *******************

  EcomItemVariationList: async (ItemID) => {
    try {
      const apiUrl = `${WebApiUrl}/api/ItemMaster/EcomItemVariationList?D1Id=${ItemID}`;
      const response = await axios.get(
        apiUrl,
        {},
        { withCredentials: true, credentials: "include" }
      );

      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
      // You might want to throw the error or handle it based on your application's requirements
      throw error;
    }
  },

  // ************************************ Ecom Item Filter *******************

  EcomItemFilter: async (data) => {
    try {
      debugger;
      const apiUrl = `${WebApiUrl}/api/ItemMaster/EcomItemFilter`;
      const response = await axios.post(
        apiUrl,
        { data },
        { withCredentials: true, credentials: "include" }
      );
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
      // You might want to throw the error or handle it based on your application's requirements
      throw error;
    }
  },

  // ************************************ Get Ecom Item Filter *******************

  GetEcomItemFilter: async (ItemID) => {
    try {
      const apiUrl = `${WebApiUrl}/api/ItemMaster/GetEcomItemFilter?D1Id=${ItemID}`;
      const response = await axios.get(
        apiUrl,
        {},
        { withCredentials: true, credentials: "include" }
      );

      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
      // You might want to throw the error or handle it based on your application's requirements
      throw error;
    }
  },

  // ************************************ Save Item Related Prod Setting *******************

  SaveItemRelatedProdSetting: async (data) => {
    try {
      debugger;
      const apiUrl = `${WebApiUrl}/api/ItemMaster/SaveItemRelatedProdSetting`;
      const response = await axios.put(
        apiUrl,
        { data },
        { withCredentials: true, credentials: "include" }
      );
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
      // You might want to throw the error or handle it based on your application's requirements
      throw error;
    }
  },

  // ************************************ get Item Related Prod Setting *******************

  getItemRelatedProdSetting: async (ItemId) => {
    try {
      const apiUrl = `${WebApiUrl}/api/ItemMaster/getItemRelatedProdSetting?MasterType=220&ItemId=${ItemId}`;
      const response = await axios.get(
        apiUrl,
        {},
        { withCredentials: true, credentials: "include" }
      );

      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
      // You might want to throw the error or handle it based on your application's requirements
      throw error;
    }
  },

    // ************************************ get Lens Prod Setting *******************

    getItemLensProdSetting: async (ItemId) => {
      try {
        const apiUrl = `${WebApiUrl}/api/ItemMaster/getItemRelatedProdSetting?MasterType=222&ItemId=${ItemId}`;;
        const response = await axios.get(
          apiUrl,
          {},
          { withCredentials: true, credentials: "include" }
        );
  
        console.log("Response:", response.data.result);
        return response.data.result;
      } catch (error) {
        console.error("Error:", error.message);
        // You might want to throw the error or handle it based on your application's requirements
        throw error;
      }
    },


    // ************************************ get Item Related Linked Lens ProdSetting *******************

    getItemLinkedProdSetting: async (ItemId) => {
      debugger
      try {
        const apiUrl = `${WebApiUrl}/api/ItemMaster/getItemLinkedProdSetting?MasterType=221&ItemId=${ItemId}`;;
        const response = await axios.get(
          apiUrl,
          {},
          { withCredentials: true, credentials: "include" }
        );
  
        console.log("Response:", response.data.result);
        return response.data.result;
      } catch (error) {
        console.error("Error:", error.message);
        // You might want to throw the error or handle it based on your application's requirements
        throw error;
      }
    },


      // ************************************ Save Item Linked Setting *******************

  SaveItemLinkedSetting: async (data) => {
    try {
      debugger;
      const apiUrl = `${WebApiUrl}/api/ItemMaster/SaveItemLinkedSetting`;
      const response = await axios.put(
        apiUrl,
        { data },
        { withCredentials: true, credentials: "include" }
      );
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
      // You might want to throw the error or handle it based on your application's requirements
      throw error;
    }
  },



    // ************************************ Ecom SEO *******************

    EcomSEO: async (data) => {
      try {
        debugger;
        const apiUrl = `${WebApiUrl}/api/ItemMaster/EcomSEO`;
        const response = await axios.post(
          apiUrl,
          {
            d1_Id: data.D1_Id,
            metaKeywork: data.MetaKeywords,
            metaDescription: data.MetaDescription,
            metaTitle: data.MetaTitle,
            searchEngineFriendlyName: data.SearchEnginFriendlyPageName,
           },
          { withCredentials: true, credentials: "include" }
        );
        console.log("Response:", response.data.result);
        return response.data.result;
      } catch (error) {
        console.error("Error:", error.message);
        // You might want to throw the error or handle it based on your application's requirements
        throw error;
      }
    },
  


     // ************************************ Ecom Items Variation Details *******************

  EcomItemsVariationDetails: async (data) => {
    // Assuming data.published is initially a non-boolean value
    debugger;

    function parseBoolean(v) {
      if (typeof v === "boolean") {
        return v;
      } else if (typeof v === "string") {
        v = v.toLowerCase().trim();
        return v === "true";
      } else {
        console.log("error", v);
        return false;
      }
    }

    data.published = parseBoolean(data.published);
    data.productId = String(data.productId);
    data.showInHome = String(data.showInHome);
    data.stockCheck = Number(data.stockCheck);
    data.availableQty = Number(data.availableQty);
    data.height = Number(data.height);
    data.width = Number(data.width);
    data.weight = Number(data.weight);
    data.length = Number(data.length);
    data.specifyIndexNo = Number(data.specifyIndexNo);
    data.priorityPopulerCategory = Number(data.priorityPopulerCategory);
    try {
      const apiUrl = `${WebApiUrl}/api/ItemMaster/EcomItemsVariationDetails`;
      const response = await axios.post(
        apiUrl,
        { data },
        { withCredentials: true, credentials: "include" }
      );
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
      // You might want to throw the error or handle it based on your application's requirements
      throw error;
    }
  },
  DeleteImage: async (ImageId) => {
    console.log(ImageId);
    debugger;
    try {
      console.log("delete");
      const apiUrl = `${WebApiUrl}/api/ItemMaster/DeleteImage?ImageID=${ImageId}`;
      const response = await axios.delete(apiUrl, {
        withCredentials: true,
        credentials: "include",
      });
      console.log("Delete Response:", response.data.result);
      return true;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  SaveContactLens: async (filterData, D2Id) => {
    try {
      var WebApiUrl = express_server_url;
      WebApiUrl = express_server_url.replace(
        "localhost",
        window.location.hostname
      );
      const apiUrl = `${WebApiUrl}/api/ItemMaster/SaveContactLensProductPwrSetting?D2Id=${D2Id}`;
      const response = await axios.patch(apiUrl, filterData, {
        withCredentials: true,
      });
      // console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  //**********************************Get Contact Lens********************************** */

  GetContactLens: async (D2Id) => {
    debugger
    try {
      const apiUrl = `${WebApiUrl}/api/ItemMaster/GetContactLensProductPwrSetting?D2Id=${D2Id}`;
      const response = await axios.get(
        apiUrl,
        {},
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      console.log("Response:", response);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },


  SetAttributeImage: async (id, data) => {
    debugger;
    try {
      const apiUrl = `${WebApiUrl}/api/ItemMaster/SetAttributeImage?ItemAttributeId=${id}`;
      const response = await axios.post(apiUrl, data, {
        withCredentials: true,
        credentials: "include",
      });
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
      // You might want to throw the error or handle it based on your application's requirements
      throw error;
    }
  },
};

export default ItemMaster;
