import DateTime from "../../Components/DateTime";
import PageTabs from "../../Components/PageTabs";
import React, { useState, useRef, useEffect, useContext } from "react";
import "react-quill/dist/quill.snow.css";
import MainButton from "../../Props/MainButton";
import StatusApi from "../../../API/APIServices/Status";
import MyContext from "../../MyContext";
import AlertBox from "../../Props/AlertBox";
import Loader from "../../Props/Loader";
import { useNavigate } from 'react-router-dom';

function StatusMaster(props) {
  const { OpenAlertBox, alertBox, OpenAlertBoxEdit, alertBoxEdit } =
    useContext(MyContext);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [inputValues, setInputValues] = useState({
    id: "",
    name: "",
    sendSMS: false,
    email: false,
    whatsappMessage: false,
    isActive: true,
  });

  const [errorMessages, setErrorMessages] = useState({
    name: "",
  });

  useEffect(() => {
    // console.log(props.data);
    const fetchData = async () => {
      try {
        if (props.data) {
          try {
            const data = await props.data;
            setInputValues({
              id: data.id,
              name: data.name,
              sendSMS: data.sendSMS,
              email: data.email,
              whatsappMessage: data.whatsappMessage,
              isActive: data.isActive,
            });
          } catch (error) {
            console.log(error);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [props.data]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    // console.log(`Changing ${name} to ${value}`);

    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const toFriendlyName = (fieldName) => {
    return fieldName
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  };

  const validateInput = (fieldName) => {
    // console.log(fieldName);
    const fieldValue = inputValues[fieldName];

    if (
      !fieldValue ||
      (typeof fieldValue === "string" && fieldValue.trim() === "")
    ) {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        [fieldName]: `${toFriendlyName(fieldName)} field is required`,
      }));
      return false;
    }

    return true;
  };
  const fieldNames = ["name"];

  const handleInputChangeActive = (e) => {
    const { name, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : e.target.value;
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [name]: type === "checkbox" ? !prevInputValues[name] : newValue,
    }));

    // setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));
    // console.log(inputValues);
  };



  const SaveBtn = async () => {
    let allValid = true;
    fieldNames.forEach((fieldName) => {
      const isValid = validateInput(fieldName);
      if (!isValid) {
        allValid = false;
      }
    });
    if (allValid) {
      if (props.modelOpen) {
        setLoading(true);
        const isSuccess = await StatusApi.updateStatus(inputValues);
        if (isSuccess) {
          setLoading(false);
          props.fetchData();
          OpenAlertBoxEdit();
          props.setSliderCallBack(false);
        } else {
          alert("error while updating");
        }
      } else {
        setLoading(true);
        const isSuccess = StatusApi.InsertStatus(inputValues);
        if (isSuccess) {
          setLoading(false);
          OpenAlertBox();
          setInputValues({
            id: "",
            name: "",
            sendSMS: "",
            email: "",
            whatsappMessage: "",
            isActive: true,
          });
        } else {
          alert("Error inserting data");
        }
      }
    }
  };

  ///*****************title Start****************************///
  useEffect(() => {
    document.title = "Account Status Master";
  }, []);
  //**********editer*****************//

  ///****************Show Hide Start***************************///

  const [isInputVisiblam1, setInputVisiblam1] = useState(false);
  const handleToggleChangam1 = () => {
    setInputVisiblam1(!isInputVisiblam1);
  };
  const [isInputVisiblam2, setInputVisiblam2] = useState(false);
  const handleToggleChangam2 = () => {
    setInputVisiblam2(!isInputVisiblam2);
  };
  const [isInputVisiblam3, setInputVisiblam3] = useState(false);
  const handleToggleChangam3 = () => {
    setInputVisiblam3(!isInputVisiblam3);
  };
  const [isInputVisiblam4, setInputVisiblam4] = useState(false);
  const handleToggleChangam4 = () => {
    setInputVisiblam4(!isInputVisiblam4);
  };
  const [isInputVisiblam5, setInputVisiblam5] = useState(false);
  const handleToggleChangam5 = () => {
    setInputVisiblam5(!isInputVisiblam5);
  };
  const [isInputVisiblam6, setInputVisiblam6] = useState(false);
  const handleToggleChangam6 = () => {
    setInputVisiblam6(!isInputVisiblam6);
  };
  const [isInputVisiblam7, setInputVisiblam7] = useState(false);
  const handleToggleChangam7 = () => {
    setInputVisiblam7(!isInputVisiblam7);
  };

  ///*******************Show Hide End**********************///

  ///*******************modal box Start*******************///

  const [isModalOpen, setModalOpen] = useState(false);

  const showModal = () => {
    setModalOpen(true);
  };

  const hideModal = () => {
    setModalOpen(false);
  };

  //*******************modal box End******************//

  /************Enter Button Click******************/

  const [inputs, setInputs] = useState({
    input1: "",
    input2: "",
    input3: "",
  });

  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);
  const input5Ref = useRef(null);

  const handleKeyPress = (event, nextInputRef) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      nextInputRef.current.focus();
    }
  };

  return (
    <div>
      <div>
        {[].map((item) => {
          return (
            <div
              key={item}
              id={`step-${item}`}
              style={{
                border: "1px solid white",
                width: "100px",
                height: "100px",
                background: "#0c1d2b",
                borderRadius: "8px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {item}
            </div>
          );
        })}
      </div>

      <div className="panel-heading">
        <div className="panel-head-title">
          <h2 className="panel-title">Status Master</h2>
        </div>

        {/* *******************Top Buttons****************** */}
        <MainButton
          modelOpen={props.modelOpen}
          SaveButton={SaveBtn}
          setSliderCallBack={props.setSliderCallBack}
        />
        {/* *******************Modal Box Open****************** */}

        <div>
          {isModalOpen && (
            <div className="modal">
              <div className="modal-content">
                <div className="modal-title">
                  <h3 className="modal-h">Account Master</h3>
                  <span className="close" onClick={hideModal}>
                    &times;
                  </span>
                </div>
                <div className="modal-content-im">
                  <div className="">
                    <div className="input-box-m1 modal-1">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            type="checkbox"
                            onChange={handleToggleChangam1}
                            checked={isInputVisiblam1}
                          />
                          <span></span>
                        </label>
                        <label className="modify_p_text2">
                          Address Details
                          <i className="fa-solid fa-circle-info ml-10"></i>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="input-box-m1 modal-1">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            type="checkbox"
                            onChange={handleToggleChangam2}
                            checked={isInputVisiblam2}
                          />
                          <span></span>
                        </label>
                        <label className="modify_p_text2">
                          VAT/GST Details
                          <i className="fa-solid fa-circle-info ml-10"></i>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="input-box-m1 modal-1">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            type="checkbox"
                            onChange={handleToggleChangam3}
                            checked={isInputVisiblam3}
                          />
                          <span></span>
                        </label>
                        <div className="modify_p_text2">
                          UPI Payment Details
                          <i className="fa-solid fa-circle-info ml-10"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="input-box-m1 modal-1">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            type="checkbox"
                            onChange={handleToggleChangam4}
                            checked={isInputVisiblam4}
                          />
                          <span></span>
                        </label>
                        <label className="modify_p_text2">
                          Tax details
                          <i className="fa-solid fa-circle-info ml-10"></i>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="input-box-m1 modal-1">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            type="checkbox"
                            onChange={handleToggleChangam5}
                            checked={isInputVisiblam5}
                          />
                          <span></span>
                        </label>
                        <label className="modify_p_text2">
                          Price Details
                          <i className="fa-solid fa-circle-info ml-10"></i>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="input-box-m1 modal-1">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            type="checkbox"
                            onChange={handleToggleChangam6}
                            checked={isInputVisiblam6}
                          />
                          <span></span>
                        </label>
                        <label className="modify_p_text2">
                          Item Setting
                          <i className="fa-solid fa-circle-info ml-10"></i>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="input-box-m1 modal-1">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            type="checkbox"
                            onChange={handleToggleChangam7}
                            checked={isInputVisiblam7}
                          />
                          <span></span>
                        </label>
                        <label className="modify_p_text2">
                          Min Sale Price
                          <i className="fa-solid fa-circle-info ml-10"></i>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <PageTabs
        modelOpen={props.modelOpen}
        setSliderCallBack={props.setSliderCallBack}
        locationTab={"/StatusMaster"}
      />

      <div>
        <DateTime />
      </div>
      <div className="content_box">
        <div className="company-info-top-im">
          <div className="tab-head">
            <div id="tabs">
              <div className="tab-content-box">
                <div className="tab-content-1">
                  <div className="company-info-top-left">
                    <div className="company_info-text-im td-1">
                      General Details
                      <div className="input-box-checkbox1 ">
                        <div className=" td-2">
                          <div className="cl-toggle-switch td-3">
                            <label className="cl-switch">
                              <input
                                type="checkbox"
                                onChange={handleInputChangeActive}
                                name="isActive"
                                checked={inputValues.isActive}
                                value={inputValues.isActive}
                              />
                              <span></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="">
                      <div className="input-box-Im ">
                        <div className="input-box-m2">
                          <input
                            className="modify_im_input mode_category"
                            onChange={handleInputChange}
                            onKeyPress={(e) => handleKeyPress(e, input2Ref)}
                            ref={input1Ref}
                            type="text"
                            required
                            value={inputValues.name}
                            name="name"
                          />
                          <label className="modify_p_text2">
                            Status Name(?){" "}
                            <span className="StarClr">
                              *
                              {errorMessages.name && (
                                <label className="errorMessage">
                                  {errorMessages.name}
                                </label>
                              )}
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="input-box-Im ">
                        <div className="input-box-m2">
                          <select
                            className="modify_im_input mode_category"
                            onChange={handleInputChange}
                            onKeyPress={(e) => handleKeyPress(e, input3Ref)}
                            ref={input2Ref}
                            type="text"
                            required
                            name="sendSMS"
                            value={inputValues.sendSMS}
                          >
                            <option value="false">No</option>
                            <option value="true">Yes</option>
                          </select>
                          <label className="modify_lbl_text3">Send SMS</label>
                        </div>
                      </div>
                      <div className="input-box-Im ">
                        <div className="input-box-m2">
                          <select
                            className="modify_im_input mode_category"
                            onChange={handleInputChange}
                            onKeyPress={(e) => handleKeyPress(e, input3Ref)}
                            ref={input2Ref}
                            type="text"
                            required
                            name="email"
                            value={inputValues.email}
                          >
                            <option value="false">No</option>
                            <option value="true">Yes</option>
                          </select>
                          <label className="modify_lbl_text3">E-mail </label>
                        </div>
                      </div>
                      <div className="input-box-Im ">
                        <div className="input-box-m2">
                          <select
                            className="modify_im_input mode_category"
                            onChange={handleInputChange}
                            onKeyPress={(e) => handleKeyPress(e, input3Ref)}
                            ref={input2Ref}
                            type="text"
                            required
                            name="whatsappMessage"
                            value={inputValues.whatsappMessage}
                          >
                            <option value="false">No</option>
                            <option value="true">Yes</option>
                          </select>
                          <label className="modify_lbl_text3">
                            WhatsApp Message
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="buttom-box">
            <div className="button-btn-1">
              <button className="btn-1">Save</button>
            </div>
            <div className="button-btn-2">
              <button className="btn-2">Reset</button>
            </div>
            <div className="button-btn-3">
              <button className="btn-3" onClick={showModal}>
                <i className="fa-solid fa-gear "></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      {alertBox && (
        <AlertBox
          HeadingTop="Saved"
          Heading="Your Status Group is Saved"
          onYesClick={OpenAlertBox}
          singleButton={true}
          OpenAlertBox={OpenAlertBox}
        />
      )}

      {alertBoxEdit && (
        <AlertBox
          HeadingTop="Updated"
          Heading="Your Status Group is Updated"
          onYesClick={OpenAlertBoxEdit}
          singleButton={true}
          // sliderClose={props.}
          OpenAlertBox={OpenAlertBoxEdit}
        />
      )}
      {loading && <Loader />}
    </div>
  );
}

export default StatusMaster;
