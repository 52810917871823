
import axios from '../axiosConfig';

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const Payment = {

  getPaymentOptions: async (ruppees) => {
    const options = {
      key: 'rzp_test_6gzv7z3KTwC4Gp',
      order_id :'1',
      amount: ruppees + "00",
      currency: 'INR',
      name: 'Techcherry',
      description: 'Software Company',
      handler: function (response) {
       
        alert("Payment is Successful\n" +
          "razorpay_payment_id: " + response.razorpay_payment_id +
          "\nStatus: " + response.status +
          "\nrazorpay_order_id: " + response.razorpay_order_id +
          "\nrazorpay_signature: " + response.razorpay_signature);
      },
      prefill: {
        name: 'Customer Name',
        email: 'customer@example.com',
        contact: '9039024004'
      },
      theme: {
        color: '#3009cc'
      }
    };
    try {
      const apiUrl = `${WebApiUrl}/api/PaymentGateway/getRazorpayOptions`;
      const response = await axios.post(apiUrl, options, {
        withCredentials: true,
        credentials: 'include',
      });
      //debugger;
      console.log('Response:', response.data.data);
      const rzp1 = await new window.Razorpay(response.data.data);
      console.log(rzp1);
      rzp1.open();
      return response.data.data;
    } catch (error) {
      console.error('Error:', error.message);
    }
  },

};

export default Payment;



















// const express = require('express');
// const app = express();

// // Endpoint to fetch Razorpay options
// app.get('/getRazorpayOptions', (req, res) => {
//   // Replace this with your logic to generate Razorpay options
//   const options = {
//     key: 'rzp_test_6gzv7z3KTwC4Gp',
//     amount: '50000',
//     currency: 'INR',
//     name: 'Techcherry',
//     description: 'Software Company',
//     prefill: {
//       name: 'Customer Name',
//       email: 'customer@example.com',
//       contact: '8319871045'
//     },
//     theme: {
//       color: '#3009cc'
//     }
//   };

//   res.json({ options });
// });

// app.listen(3000, () => {
//   console.log('Server is running on port 3000');
// });
