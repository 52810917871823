import DateTime from "../../Components/DateTime";
import PageTabs from "../../Components/PageTabs";
import React, { useState, useRef, useContext, useEffect } from "react";
import "react-quill/dist/quill.snow.css";
import MainButton from "../../Props/MainButton";
import MyContext from "../../MyContext";
import AlertBox from "../../Props/AlertBox";
import Loader from "../../Props/Loader";
import MiscellaneousMasterApi from "../../../API/APIServices/MiscellaneousMaster";
import { useNavigate } from 'react-router-dom';

function MiscellaneousMaster(props) {
  const { OpenAlertBox, alertBox, OpenAlertBoxEdit, alertBoxEdit } = useContext(MyContext);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  console.log(props.controlType)

  const [inputValues, setInputValues] = useState({
    controlType: "",
    name: "",
    printName: "",
    availableFrom: "",
    availableTo: "",
  });


  const [valueComes, setValueComes] = useState(false);

  useEffect(() => {
    try {
      if (props.controlType) {
        setInputValues({
          controlType: props.controlType
        })
        setValueComes(true)
      }
    } catch (error) {
      console.log(error);
    }
  }, [])


  useEffect(() => {
    const fetchData = async () => {
      try {
        if (props.data) {
          try {
            const data = await props.data; // Assuming props.editData is a Promise
            setInputValues({
              id: data.id,
              controlType: data.controlType,
              name: data.name,
              printName: data.printName,
              availableFrom: data.availableFrom,
              availableTo: data.availableTo,
            });
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [props.data]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(`Changing ${name} to ${value}`);
    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

  };

  const handletimeslected = (e) => {
    const val = e.target.value;
    if (val === "170") {
      setAvailabletime(true);
    } else {
      setAvailabletime(false);
    }

    handleInputChange(e);
  };

  const toFriendlyName = (fieldName) => {
    return fieldName
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  };



  const SaveBtn = async () => {

    if (props.modelOpen) {
      setLoading(true);
      const isSuccess =
        await MiscellaneousMasterApi.updateMiscellaneousMaster(inputValues);
      // console.log(isSuccess);
      if (isSuccess) {
        setLoading(false);
        OpenAlertBoxEdit();
        props.fetchData();
        props.setSliderCallBack(false);
      } else {
        alert("error while updating");
      }
    } else {
      setLoading(true);
      const isSuccess = await
        MiscellaneousMasterApi.InsertMiscellaneousMaster(inputValues);
      if (isSuccess) {
        setLoading(false);
        OpenAlertBox();
        setInputValues({
          controlType: "",
          name: "",
          printName: "",
          availableFrom: "",
          availableTo: "",
        });
      } else {
        alert("Error inserting data");
      }
    }

  };

  ///*****************title Start****************************///
  useState(() => {
    document.title = "Miscellaneous Master";
  }, []);

  useEffect(() => {
    try {
      if (inputValues.controlType === 170) {
        setAvailabletime(true);
      }
    } catch (error) {
      alert("Unable to find Control Type")
    }
  });

  ///****************Show Hide Start***************************///

  /************Enter Button Click******************/
  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);
  const input5Ref = useRef(null);
  const input6Ref = useRef(null);

  const handleKeyPress = (event, nextInputRef) => {
    if (event.key === "Enter") {
      event.preventDefault();
      nextInputRef.current.focus();
    }
  };

  const [isAvailabletime, setAvailabletime] = useState(false);

  // const handleSelectChange01 = (event) => {
  //   const selectedValue = event.target.value;
  //   // setSelectedOption(selectedValue);
  //   if (selectedValue === "168") {
  //     setAvailabletime(false);
  //   } else if (selectedValue === "169") {
  //     setAvailabletime(false);
  //   } else if (selectedValue === "170") {
  //     setAvailabletime(true);
  //   } else if (selectedValue === "171") {
  //     setAvailabletime(false);
  //   } else if (selectedValue === "172") {
  //     setAvailabletime(false);
  //   } else if (selectedValue === "173") {
  //     setAvailabletime(false);
  //   }
  // };

  return (
    <div>
      <PageTabs
        locationTab={"/MiscellaneousMaster"}
        modelOpen={props.modelOpen}
        setSliderCallBack={props.setSliderCallBack}
      />
      <div className="panel-heading">
        <div className="panel-head-title">
          <h2 className="panel-title">Miscellaneous Master</h2>
        </div>

        {/* *******************Top Buttons****************** */}

        <MainButton
          modelOpen={props.modelOpen}
          SaveButton={SaveBtn}
          setSliderCallBack={props.setSliderCallBack}
        />

        {/* *******************Modal Box Open****************** */}

        <div></div>
      </div>
      <div>
        <DateTime />
      </div>
      <div className="content_box">
        <div className="company-info-top-im">
          <div className="tab-head">
            <div id="tabs">
              <div className="tab-content-box">
                <div className="tab-content-1">
                  <div className="company-info-top-left">
                    {/* <div className="company_info-text-im">Balance Sheet / Profit Loss Details</div> */}
                    <div className="">
                      <div className="input-box-Im ">
                        {(() => {
                          var Val = isAvailabletime ? input2Ref : input4Ref;
                          return (
                            <div className="input-box-m2">
                              <select
                                className="modify_im_input mode_category"
                                ref={input1Ref}
                                onKeyDown={(event) => handleKeyPress(event, Val)}
                                onChange={handletimeslected}
                                type="text"
                                value={inputValues.controlType}
                                name="controlType"
                                required
                                disabled={valueComes}
                              >
                                <option value="168">Balance Sheet</option>
                                <option value="169">Profit Loss</option>
                                <option value="170">Time Slot</option>
                                <option value="171">Item Preparation Time</option>
                                <option value="172">Work Shop</option>
                                <option value="173">SMS Type</option>
                              </select>
                              <label className="modify_lbl_text3">Type</label>
                            </div>
                          )
                        })()}
                      </div>
                      {isAvailabletime && (
                        <div style={{ display: "flex" }}>
                          <div className="input-box-Im ">
                            <div className="input-box-m2">
                              <input
                                className="modify_im_input mode_category"
                                onChange={handleInputChange}
                                ref={input2Ref}
                                onKeyDown={(event) => handleKeyPress(event, input3Ref)}
                                value={inputValues.availableFrom}
                                name="availableFrom" type="time"
                                required
                              />
                              <label className="modify_lbl_text3">
                                Available Time From
                              </label>
                            </div>
                          </div>
                          <div className="input-box-Im ">
                            <div className="input-box-m2">
                              <input
                                className="modify_im_input mode_category"
                                onChange={handleInputChange}
                                ref={input3Ref}
                                onKeyDown={(event) => handleKeyPress(event, input4Ref)}
                                value={inputValues.availableTo}
                                name="availableTo"
                                type="time"
                                required
                              />
                              <label className="modify_lbl_text3">
                                Available Time To
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="input-box-Im ">
                        <div className="input-box-m2">
                          <input
                            className="modify_im_input mode_category"
                            onChange={handleInputChange}
                            ref={input4Ref}
                            onKeyDown={(event) => handleKeyPress(event, input5Ref)}
                            value={inputValues.name}
                            name="name"
                            type="text"
                            required
                          />
                          <label className="modify_p_text2">
                            {" "}
                            Name{" "}

                          </label>
                        </div>
                      </div>
                      <div className="input-box-Im ">
                        <div className="input-box-m2">
                          <input
                            className="modify_im_input mode_category"
                            onChange={handleInputChange}
                            ref={input5Ref}
                            onKeyDown={(event) => handleKeyPress(event, input6Ref)}
                            value={inputValues.printName}
                            name="printName"
                            type="text"
                            required
                          />
                          <label className="modify_p_text2">
                            Print Name{" "}

                          </label>
                        </div>
                      </div>
                      {/* <div className="HeadContentInputBpx">
                                                {(() => {
                                                    if (customfields.length !== 0) {
                                                        return customfields.map((fields, index) => (
                                                            <div key={fields.id}>
                                                                <div className="input-box-m11" >
                                                                    <input onChange={() => handleToggleChanges(index)} className="modify_im_input mode_limit mode_category" type="time" required />
                                                                    <label className="modifyTextLabel">{fields.FieldName}</label>
                                                                </div>
                                                                <div className="input-box-m11" >
                                                                    <input onChange={() => handleToggleChanges(index)} className="modify_im_input mode_limit mode_category" type="time" required />
                                                                    <label className="modifyTextLabel">{fields.FieldName}</label>
                                                                </div>
                                                            </div>
                                                        ));
                                                    }
                                                })()}
                                            </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="buttom-box">
            <div className="button-btn-1">
              <button className="btn-1">Save</button>
            </div>
            <div className="button-btn-2">
              <button className="btn-2">Reset</button>
            </div>
            <div className="button-btn-3">
              <button className="btn-3">
                <i className="fa-solid fa-gear "></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      {alertBox && (
        <AlertBox
          HeadingTop="Saved"
          Heading="Your Miscellaneous Group is Saved"
          onYesClick={OpenAlertBox}
          singleButton={true}
          OpenAlertBox={OpenAlertBox}
        />
      )}

      {alertBoxEdit && (
        <AlertBox
          HeadingTop="Updated"
          Heading="Your Miscellaneous Group is Updated"
          onYesClick={OpenAlertBoxEdit}
          singleButton={true}
          OpenAlertBox={OpenAlertBoxEdit}
        />
      )}
      {loading && <Loader />}
    </div>
  );
}

export default MiscellaneousMaster;
