import DateTime from "../../Components/DateTime";
import React, { useState, useEffect, useContext, useRef } from "react";
import PageTabs from "../../Components/PageTabs";
import MainButton from "../../Props/MainButton";
import ModalBoxMaster from "../../Props/ModalBoxMaster";
import DeliveryIncentiveApi from "../../../API/APIServices/DeliveryIncentive";
import DataTable from "../TableMaster/DataTable";
import MyContext from "../../MyContext";
import AlertBox from "../../Props/AlertBox";
import Loader from "../../Props/Loader";
import MiscellaneousMasterApi from "../../../API/APIServices/MiscellaneousMaster";
import { useNavigate } from 'react-router-dom';

function DeliveryIncentive() {
    const {
        OpenAlertBoxDelete,
        alertBoxDelete,
        setAlertBoxDelete,
        input1,
        input2,
        input3,
        input4,
    } = useContext(MyContext);

    const navigate = useNavigate();

    const { OpenAlertBox, alertBox, OpenAlertBoxEdit, alertBoxEdit } = useContext(MyContext);

    const [loading, setLoading] = useState(true);

    useState(() => {
        document.title = 'Delivery Incentive Master';
    }, []);

    const [isModalMiscellaneous, setModalMiscellaneous] = useState(false);
    const hideMiscellaneous = () => {
        setModalMiscellaneous(false);
    }
    const showMiscellaneous = () => {
        setModalMiscellaneous(true);
    }

    const [deliveryIncentiveModal, setDeliveryIncentiveModal] = useState(false);

    const hideDeliveryIncentive = () => {
        setDeliveryIncentiveModal(false);
    }
    const showDeliveryIncentive = () => {
        setDeliveryIncentiveModal(true);
    }
    const handleModalClick = (e) => {
        e.stopPropagation();
    };

    //******************************************************//
    const [errorMessages, setErrorMessages] = useState({
        incentiveName: '',
        incentivePoint: '',
    });
    const [inputValues, setInputValues] = useState({
        id: 0,
        incentiveName: '',
        incentivePoint: 0,
        incentiveNameId: 0,
        deliveryIncentiveInTimeSlot: 201,
        incentiveType: 'Percentage',
        isActive: true,
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        console.log(`Changing ${name} to ${value}`);

        if (name == "incentiveName") {
            const incentiveVal = deliveryIncentiveNames.filter(item => item.id == value);
            if (incentiveVal.length > 0) {
                setInputValues((prevValues) => ({
                    ...prevValues,
                    "incentiveNameId": value,
                    "incentiveName": incentiveVal[0].name,
                }))
            }
            else {
                setInputValues((prevValues) => ({
                    ...prevValues,
                    "incentiveNameId": 0,
                    "incentiveName": "",
                }))
            }
            return 0;
        }
        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
        setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));
    }
    const toFriendlyName = (fieldName) => {
        return fieldName
            .replace(/([A-Z])/g, ' $1')
            .replace(/^./, (str) => str.toUpperCase());
    };

    const validateInput = (fieldName) => {
        console.log(fieldName);
        const fieldValue = inputValues[fieldName];

        if (!fieldValue || (typeof fieldValue === 'string' && fieldValue.trim() === '')) {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                [fieldName]: `${toFriendlyName(fieldName)} field is required`,
            }));
            return false;
        }

        return true;
    };
    const fieldNames = ['incentiveName', 'incentivePoint'];

    const SaveBtn = async () => {
        let allValid = true;
        fieldNames.forEach((fieldName) => {
            const isValid = validateInput(fieldName);
            if (!isValid) {
                allValid = false;
            }
        });
        if (allValid) {
            setLoading(true);
            if (Boolean(edit) === true) {
                debugger;
                const isSuccess = await DeliveryIncentiveApi.updateDeliveryIncentive(inputValues);
                fetchData();
                if (isSuccess) {
                    fetchData();
                    setEdit(false);
                    OpenAlertBoxEdit();
                    setLoading(false);
                } else {
                    alert("error while updating");
                }
            }
            else {
                setLoading(true);
                const isSuccess = await DeliveryIncentiveApi.insertDeliveryIncentive(inputValues);
                fetchData();
                if (isSuccess) {
                    fetchData();
                    setLoading(false);
                    OpenAlertBox();
                } else {
                    alert("Error inserting data");
                }
            }
            fetchData();
            setInputValues({
                incentiveName: '',
                incentivePoint: '',
                incentiveNameId: 0,
                deliveryIncentiveInTimeSlot: '201',
                incentiveType: 'Percentage',
                isActive: true,
            });
        }
    }

    // ********************************************List Part ******************************

    const [data1, setData1] = useState([]);

    const columns = [
        { Header: <input type="checkbox" />, accessor: "input" },
        { Header: "SNO", accessor: "sno" },
        { Header: "INCENTIVE NAME", accessor: "incentiveName" },
        { Header: "INCENTIVE POINT", accessor: "incentivePoint" },
        { Header: "TIME SLOT", accessor: "deliveryIncentiveInTimeSlot" },
        { Header: "INCENTIVE TYPE", accessor: "incentiveType" },
        { Header: "ACTION", accessor: "action" },
    ];

    const filterData = [
        { Header: "", accessor: "" },
        { Header: "", accessor: "" },
        { Header: <input type="text" placeholder="Incentive Name" ref={input1} />, accessor: "IncentiveName" },
        { Header: <input type="text" placeholder="Incentive Point" ref={input2} />, accessor: "IncentivePoint" },
        { Header: <input type="text" placeholder="Time Slot" ref={input3} />, accessor: "TimeSlot" },
        { Header: <input type="text" placeholder="IncentiveType" ref={input4} />, accessor: "IncentiveType" },
    ];

    const fetchData = async () => {
        setLoading(true);
        try {
            const val = await DeliveryIncentiveApi.getDeliveryIncentiveFilter();
            console.log(val.length);

            const newData = val.map((item, i) => {

                return {
                    input: <input type="checkbox" />,
                    sno: i + 1,
                    incentiveName: item.incentiveName,
                    incentivePoint: item.incentivePoint,
                    deliveryIncentiveInTimeSlot: (item.deliveryIncentiveInTimeSlot),
                    incentiveType: item.incentiveType,
                    action: (
                        <div className="tablelist-action-box">
                            <i className="fa-regular fa-pen-to-square TableEditIcon" onClick={() => editDeliveryIncentive(item.id)}></i>
                            <i className="fa-regular fa-trash-can TableDelIcon" onClick={() => DeleteDeliveryIncentive(item.id)}></i>
                            <i className="fa-brands fa-square-whatsapp TableWhatsappcon"></i>
                            <i className="fa-solid fa-print TablePrint"></i>
                        </div>
                    ),
                };
            });
            setData1(newData);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const [edit, setEdit] = useState(false);

    const editDeliveryIncentive = async (id) => {
        setEdit(true)
        const dataById = DeliveryIncentiveApi.getDeliveryIncentiveById(id);
        if (dataById) {
            try {
                const editData = await dataById; // Assuming props.editData is a Promise
                console.log("dataaaa" + editData);
                setInputValues({
                    id: editData.id,
                    incentiveName: editData.incentiveName,
                    incentivePoint: editData.incentivePoint,
                    incentiveNameId: editData.incentiveNameId,
                    deliveryIncentiveInTimeSlot: editData.timeSlotId,
                    incentiveType: editData.incentiveType,
                    isActive: true,
                });
                // setInputValues(editData);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
    }

    const [getdeleteId, setDeleteId] = useState(null);

    const DeleteDeliveryIncentive = (id) => {
        setAlertBoxDelete(true);
        setDeleteId(id);
    }

    const DeleteDeliveryIncentiveConfirm = async () => {
        setLoading(true);
        try {
            const val = await DeliveryIncentiveApi.deleteDeliveryIncentive(getdeleteId);
            if (Boolean(val) === true) {
                fetchData();
                setAlertBoxDelete(false);
                setDeleteId(null);
                setLoading(false);
            } else {
                alert("Error Deleting Data");
            }
        } catch (error) {
            console.error("Error deleting account:", error);
        }
    };

    const handleInputChangeActive = (e) => {
        const { name, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : e.target.value;

        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [name]: type === 'checkbox' ? !prevInputValues[name] : newValue,
        }));

        setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));
        console.log(inputValues);
    };

    const [timeSlot, setTimeSLot] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const controlType = 170;
                const data = await MiscellaneousMasterApi.GetMiscellaneousMaster(controlType);
                setTimeSLot(data);
            } catch (error) {
                console.error(error)
                console.log(error);
            }
        };
        fetchData();
    }, []);

    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);
    const input4Ref = useRef(null);

    const handleKeyPress = (event, nextInputRef) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            nextInputRef.current.focus();
        }
    };
    useEffect(() => {
        // Focus on the input element when the component mounts
        input1Ref.current.focus();
    }, []);

    // useEffect(() => {
    //     const handleKeyPress = (event) => {
    //         if (event.key === 'Escape') {
    //             if (isModalMiscellaneous === true) {
    //                 setModalMiscellaneous(false);
    //                 console.log("if");
    //             }
    //             else {
    //                 window.history.back();
    //                 console.log("else");
    //             }
    //         }
    //     };
    //     window.addEventListener('keydown', handleKeyPress);
    //     return () => {
    //         window.removeEventListener('keydown', handleKeyPress);
    //     };
    // });

    function convertTo12HourFormat(twentyFourHourTime) {
        const [hours, minutes] = twentyFourHourTime.split(':').map(Number);

        let period = 'AM';
        let twelveHourHours = hours;

        if (hours >= 12) {
            period = 'PM';
            if (hours > 12) {
                twelveHourHours -= 12;
            }
        }
        if (twelveHourHours === 0) {
            twelveHourHours = 12;
        }
        if (minutes != undefined && minutes != null) {
            const formattedMinutes = minutes === 0 ? '00' : minutes.toString().padStart(2, '0');
            return `${twelveHourHours.toString().padStart(2, '0')}:${formattedMinutes} ${period}`;
        }
        else {
            return `${twelveHourHours.toString().padStart(2, '0')}: 00 ${period}`;
        }
    }

    const timeFormat = (twentyFourHourTime) => {
        const [startTime, endTime] = twentyFourHourTime.split('-');
        const convertedStartTime = convertTo12HourFormat(startTime.trim());
        const convertedEndTime = convertTo12HourFormat(endTime.trim());
        const convertedTimeRange = `${convertedStartTime} - ${convertedEndTime}`;
        return convertedTimeRange;
    }

    const [deliveryIncentiveNames, setDeliveryIncentiveNames] = useState();

    const fetchIncentiveNames = async () => {
        try {
            const data = await DeliveryIncentiveApi.getDeliveryIncentiveNames();
            setDeliveryIncentiveNames(data);
        } catch (error) {
            console.log(error)
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

    const fetchIncentiveMaster = () => {
        fetchIncentiveNames();
    }

    return (
        <div>
            <PageTabs modelOpen={isModalMiscellaneous} setSliderCallBack={setModalMiscellaneous} />
            <div className="panel-heading">
                <div className="panel-head-title">
                    <h2 className="panel-title">Delivery Incentive Master</h2>
                </div >
                {/* *******************Top Buttons****************** */}
                <MainButton SaveButton={SaveBtn} />
                <ModalBoxMaster hideMiscellaneous={hideMiscellaneous} isModalMiscellaneous={isModalMiscellaneous} handleModalClick={handleModalClick} />
                <ModalBoxMaster hideDeliveryIncentive={hideDeliveryIncentive} deliveryIncentiveModal={deliveryIncentiveModal} handleModalClick={handleModalClick} />
            </div>
            <div>
                <DateTime />
            </div>
            <div className="content_box">
                <div className="company-info-top-im">
                    <div className="tab-head">
                        <div id="tabs">
                            <div className="tab-content-box">
                                <div className="tab-content-1">
                                    <div className="company-info-top-right control_flex_m1 ">
                                        <div className="control_radius">
                                            <div className="company_info-text-im td-1">Delivery Incentive
                                                <div className="input-box-checkbox1 ">
                                                    <div className=" td-2">
                                                        <div className="cl-toggle-switch td-3">
                                                            <label className="cl-switch">
                                                                <input type="checkbox" onChange={handleInputChangeActive} name="isActive" checked={inputValues.isActive} value={inputValues.isActive} />
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="company_control">
                                                <div className="stock-im-dep1">
                                                    <div style={{ display: "flex" }} className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1" onClick={fetchIncentiveMaster}>
                                                            <select className="modify_im_input mode_category1" type="text"
                                                                onChange={handleInputChange}
                                                                onKeyPress={(e) => handleKeyPress(e, input2Ref)}
                                                                ref={input1Ref} value={inputValues.incentiveNameId}
                                                                name="incentiveName" required>
                                                                <option value="">Select</option>
                                                                {(() => {
                                                                    if (deliveryIncentiveNames !== null && Array.isArray(deliveryIncentiveNames)) {
                                                                        return deliveryIncentiveNames.map((data) => (
                                                                            <option key={data.id} value={data.id}>
                                                                                {data.name}
                                                                            </option>
                                                                        ))
                                                                    }
                                                                })()}
                                                            </select>
                                                            <label className="modify_p_text2" >Incentive Name
                                                                <span className="StarClr">*
                                                                    {errorMessages.incentiveName && <label className="errorMessage">{errorMessages.incentiveName}</label>}
                                                                </span>
                                                            </label>
                                                        </div>
                                                        <div onClick={showDeliveryIncentive} >
                                                            <i className="fa-solid fa-circle-plus dm_4"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="stock-im-dep1">
                                                    <div style={{ display: "flex" }} className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <select className="modify_im_input mode_category1" type="text" onChange={handleInputChange}
                                                                onKeyPress={(e) => handleKeyPress(e, input3Ref)}
                                                                ref={input2Ref} value={inputValues.deliveryIncentiveInTimeSlot} name="deliveryIncentiveInTimeSlot" required>
                                                                {(() => {
                                                                    if (timeSlot !== null && Array.isArray(timeSlot)) {
                                                                        return timeSlot.map((data) => (
                                                                            <option key={data.id} value={data.id}>
                                                                                {convertTo12HourFormat(data.availableFrom)} To {convertTo12HourFormat(data.availableTo)}
                                                                            </option>
                                                                        ))
                                                                    }
                                                                })()}
                                                            </select>
                                                            <label className="modify_lbl_text3">Delivery Incentive In Time Slot</label>
                                                        </div>
                                                        <div onClick={showMiscellaneous} >
                                                            <i className="fa-solid fa-circle-plus dm_4"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="company_control">
                                                <div className="stock-im-dep1">
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category1" type="text" onChange={handleInputChange} value={inputValues.incentivePoint}
                                                                onKeyPress={(e) => handleKeyPress(e, input4Ref)}
                                                                ref={input3Ref} name="incentivePoint" required />
                                                            <label className="modify_p_text2">Incentive Point
                                                                <span className="StarClr">*
                                                                    {errorMessages.incentivePoint && <label className="errorMessage">{errorMessages.incentivePoint}</label>}
                                                                </span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="stock-im-dep1">
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <select className="modify_im_input mode_category1" type="text" onChange={handleInputChange} value={inputValues.incentiveType}
                                                                onKeyPress={(e) => handleKeyPress(e, input1Ref)}
                                                                ref={input4Ref} name="incentiveType" required>
                                                                <option value="Percentage">Percentage</option>
                                                                <option value="Amount">Amount</option>
                                                            </select>
                                                            <label className="modify_lbl_text3">Incentive Type</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="control_radius">
                                            <div className="company_info-text-im depart_top">Delivery Point Details</div>
                                            <div className="col-sm-12">
                                                <div className="table-resposive" >
                                                    <DataTable filterData={filterData} columns={columns} data={data1} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="buttom-box">
                        <div className="button-btn-1">
                            <button className="btn-1">Save</button>
                        </div>
                        <div className="button-btn-2">
                            <button className="btn-2">Reset</button>
                        </div>
                        <div className="button-btn-3">
                            <button className="btn-3"><i className="fa-solid fa-gear "></i></button>
                        </div>
                    </div>
                </div>
            </div>
            {alertBoxDelete && (
                <AlertBox
                    HeadingTop="DELETE"
                    Heading="Are You sure you want to delete this"
                    OpenAlertBox={OpenAlertBoxDelete}
                    onYesClick={DeleteDeliveryIncentiveConfirm}
                />
            )}
            {alertBox && (
                <AlertBox
                    HeadingTop="Saved"
                    Heading="Your Delivery Incentive is Saved"
                    onYesClick={OpenAlertBox}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBox}
                />
            )}
            {alertBoxEdit && (
                <AlertBox
                    HeadingTop="Updated"
                    Heading="Your Delivery Incentive is Updated"
                    onYesClick={OpenAlertBoxEdit}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBoxEdit}
                />
            )}
            {loading && <Loader />}
        </div >
    );
}


export default DeliveryIncentive;
