import DateTime from "../../Components/DateTime";
import React, { useContext, useEffect, useState, useRef } from "react";
import "react-quill/dist/quill.snow.css";
import PageTabs from "../../Components/PageTabs";
import MainButton from "../../Props/MainButton";
import ToolTipMaster from "../../Props/ToolTipMaster";
import Transporter from "../../../API/APIServices/Transporter";
import MyContext from "../../MyContext";
import AlertBox from "../../Props/AlertBox";
import Loader from "../../Props/Loader";
import { useNavigate } from 'react-router-dom';
import StateInfoApi from "../../../API/APIServices/StateInfo";

function TransportMaster(props) {
  const { OpenAlertBox, alertBox, OpenAlertBoxEdit, alertBoxEdit } = useContext(MyContext);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [inputValues, setInputValues] = useState({
    id: "",
    name: "",
    transporterId: "",
    transporterGSTIN: "",
    address: "",
    city: "",
    pinCode: "",
    phone: "",
    email: "",
    state: "",
    transportationMode: "Road",
    vehicleNo: "",
    vehicleType: "",
    fuelAvgLtrKM: "",
    fuelChargeKM: "",
    docLandingRR_LR: "",
    extraField1: "",
    extraField2: "",
    extraField3: "",
    extraField4: "",
    extraField5: "",
    isActive: true,
  });

  const [errorMessages, setErrorMessages] = useState({
    name: "",
    transporterId: "",
    transporterGSTIN: "",
    fuelAvgLtrKM: "",
    pinCode: "",
    state: "",
  });

  useEffect(() => {
    // console.log(props.data);
    const fetchData = async () => {
      try {
        if (props.data) {
          try {
            const data = await props.data; // Assuming props.editData is a Promise
            setInputValues({
              id: data.id,
              name: data.name || "",
              transporterId: data.transporterId || "",
              transporterGSTIN: data.transporterGSTIN || "",
              address: data.address || "",
              city: data.city || "",
              pinCode: data.pinCode || "",
              phone: data.phone || "",
              email: data.email || "",
              state: data.state || "",
              transportationMode: data.transportationMode || "",
              vehicleNo: data.vehicleNo || "",
              vehicleType: data.vehicleType || "",
              fuelAvgLtrKM: data.fuelAvgLtrKM || "",
              fuelChargeKM: data.fuelChargeKM || "",
              docLandingRR_LR: data.docLandingRR_LR || "",
              extraField1: data.extraField1 || "",
              extraField2: data.extraField2 || "",
              extraField3: data.extraField3 || "",
              extraField4: data.extraField4 || "",
              extraField5: data.extraField5 || "",
              isActive: data.isActive,
            });
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [props.data]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    // console.log(`Changing ${name} to ${value}`);

    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const toFriendlyName = (fieldName) => {
    return fieldName
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  };

  const validateInput = (fieldName) => {
    // console.log(fieldName);
    const fieldValue = inputValues[fieldName];

    if (
      !fieldValue ||
      (typeof fieldValue === "string" && fieldValue.trim() === "")
    ) {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        [fieldName]: `${toFriendlyName(fieldName)} field is required`,
      }));
      return false;
    }

    return true;
  };
  const fieldNames = [
    "name",
    "transporterGSTIN",
    "pinCode",
    "state",
    "fuelAvgLtrKM",
    "transporterId",
  ];

  const handleInputChangeActive = (e) => {
    const { name, type, checked, value } = e.target;
    // console.log(`Changing ${name} to ${value}`);
    const newValue = type === "checkbox" ? checked : e.target.value;
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [name]: type === "checkbox" ? !prevInputValues[name] : newValue,
    }));

    // setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));
    // console.log(inputValues);
  };

  /***********title***************/
  useState(() => {
    document.title = "Transport Master";
  }, []);

  //************modal box************/ /

  const [, setModalOpen] = useState(false);

  const showModal = () => {
    setModalOpen(true);
  };

  //***************{Enter Btn Click}***************//
  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);
  const input5Ref = useRef(null);
  const input6Ref = useRef(null);
  const input7Ref = useRef(null);
  const input8Ref = useRef(null);
  const input9Ref = useRef(null);
  const input10Ref = useRef(null);
  const input11Ref = useRef(null);
  const input12Ref = useRef(null);
  const input13Ref = useRef(null);
  const input14Ref = useRef(null);
  const input15Ref = useRef(null);
  const input16Ref = useRef(null);
  const input17Ref = useRef(null);
  const input18Ref = useRef(null);
  const input19Ref = useRef(null);
  const input20Ref = useRef(null);
  const input21Ref = useRef(null);

  const handleKeyPress = (event, nextInputRef) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      nextInputRef.current.focus();
    }
  };

  const [transportMaster, setTransportMaster] = useState(true);
  const showTransportM = () => {
    setTransportMaster(!transportMaster);
  };

  const SaveBtn = async () => {
    let allValid = true;
    fieldNames.forEach((fieldName) => {
      const isValid = validateInput(fieldName);
      if (!isValid) {
        allValid = false;
      }
    });
    if (allValid) {
      if (props.modelOpen) {
        setLoading(true);
        const isSuccess = await Transporter.updateTransporter(inputValues);
        // console.log(isSuccess);
        if (isSuccess) {
          setLoading(false);
          OpenAlertBoxEdit();
          props.fetchData();
          props.setSliderCallBack(false);
        } else {
          alert("error while updating");
        }
      } else {
        setLoading(true);
        const isSuccess = Transporter.InsertTransporter(inputValues);
        if (isSuccess) {
          setLoading(false);
          OpenAlertBox();
          setInputValues({
            id: "",
            name: "",
            transporterId: "",
            transporterGSTIN: "",
            address: "",
            city: "",
            pinCode: "",
            phone: "",
            email: "",
            state: "",
            transportationMode: "road",
            vehicleNo: "",
            vehicleType: "",
            fuelAvgLtrKM: "",
            fuelChargeKM: "",
            docLandingRR_LR: "",
            extraField1: "",
            extraField2: "",
            extraField3: "",
            extraField4: "",
            extraField5: "",
            isActive: true,
          });
        } else {
          alert("Error inserting data");
        }
      }
    }
  };

  const [stateInfo, setStateInfo] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await StateInfoApi.GetAllState();
                setStateInfo(data);
            } catch (error) {
                console.log(error)
            }
        };
        fetchData();
    }, []);

  return (
    <div>
      <PageTabs
        modelOpen={props.modelOpen}
        setSliderCallBack={props.setSliderCallBack}
        locationTab={"/TransportMaster"}
      />
      <div className="panel-heading">
        <div className="panel-head-title">
          <h2 className="panel-title">Transporter Master</h2>
        </div>
        {/* *******************Top Buttons****************** */}
        <MainButton
          modelOpen={props.modelOpen}
          SaveButton={SaveBtn}
          setSliderCallBack={props.setSliderCallBack}
        />
      </div>
      <div>
        <DateTime />
      </div>
      <div className="content_box">
        <div className="company-info-top-im">
          <div className="tab-head">
            <div id="tabs">
              <div className="tab-content-box">
                <div className="tab-content-1">
                  <div className="company-info-top-left">
                    <div className="company_info-text-im td-1">
                      Transports Details
                      <div className="input-box-checkbox1 ">
                        <div className=" td-2">
                          <div className="ToggleSwitchActive td-3">
                            <label className="cl-switch">
                              <input
                                type="checkbox"
                                onChange={handleInputChangeActive}
                                name="isActive"
                                checked={inputValues.isActive}
                                value={inputValues.isActive}
                              />
                              <span></span>
                            </label>
                          </div>
                          <ToolTipMaster isActiveInactive={true} />
                        </div>
                      </div>
                    </div>
                    {transportMaster && (
                      <div className="">
                        <div className="input-box-Im input-height2">
                          <div className="input-box-m1">
                            <input
                              className="modify_im_input mode_category"
                              type="text"
                              required
                              onChange={handleInputChange}
                              name="name"
                              value={inputValues.name}
                              ref={input1Ref}
                              onKeyDown={(event) => handleKeyPress(event, input2Ref)}
                            />
                            <label className="modify_p_text2">
                              Transporter Name
                              <span className="StarClr">
                                *
                                {errorMessages.name && (
                                  <label className="errorMessage">
                                    {errorMessages.name}
                                  </label>
                                )}
                              </span>
                            </label>
                          </div>
                          <div className="input-box-m1 ">
                            <input
                              className="modify_im_input mode_limit mode_category"
                              type="text"
                              required
                              onChange={handleInputChange}
                              name="transporterId"
                              value={inputValues.transporterId}
                              ref={input2Ref}
                              onKeyDown={(event) => handleKeyPress(event, input3Ref)}
                            />
                            <label className="modify_lbl_text2">
                              Transporter Id
                              <span className="StarClr">
                                *
                                {errorMessages.transporterId && (
                                  <label className="errorMessage">
                                    {errorMessages.transporterId}
                                  </label>
                                )}
                              </span>
                            </label>
                          </div>
                        </div>
                        <div className="input-box-Im input-height2">
                          <div className="input-box-m1">
                            <input
                              className="modify_im_input mode_category"
                              type="text"
                              required
                              onChange={handleInputChange}
                              name="transporterGSTIN"
                              value={inputValues.transporterGSTIN}
                              ref={input3Ref}
                              onKeyDown={(event) => handleKeyPress(event, input4Ref)}
                            />
                            <label className="modify_p_text2">
                              Transporter GSTIN
                              <span className="StarClr">
                                *
                                {errorMessages.transporterGSTIN && (
                                  <label className="errorMessage">
                                    {errorMessages.transporterGSTIN}
                                  </label>
                                )}
                              </span>
                            </label>
                          </div>

                          <div className="input-box-m1">
                            <input
                              className="modify_im_input mode_limit mode_category"
                              type="text"
                              required
                              onChange={handleInputChange}
                              name="docLandingRR_LR"
                              value={inputValues.docLandingRR_LR}
                              ref={input4Ref}
                              onKeyDown={(event) => handleKeyPress(event, input5Ref)}
                            />
                            <label className="modify_lbl_text2">
                              Doc/Landing/RR/LR
                            </label>
                          </div>
                        </div>
                        <div className="input-box-Im input-height2">
                          <div className="input-box-m1">
                            <input
                              className="modify_im_input mode_category"
                              type="text"
                              required
                              onChange={handleInputChange}
                              name="vehicleNo"
                              value={inputValues.vehicleNo}
                              ref={input5Ref}
                              onKeyDown={(event) => handleKeyPress(event, input6Ref)}
                            />
                            <label className="modify_p_text2">
                              Vehicle Number
                            </label>
                          </div>
                          <div className="input-box-m1">
                            <select
                              className="modify_im_input mode_category"
                              type="text"
                              required
                              onChange={handleInputChange}
                              name="transportationMode"
                              value={inputValues.transportationMode}
                              ref={input6Ref}
                              onKeyDown={(event) => handleKeyPress(event, input7Ref)}
                            >
                              <option value="Road">Road</option>
                              <option value="Rail">Rail</option>
                              <option value="Air">Air</option>
                              <option value="Air">Air</option>
                            </select>
                            <label className="modify_lbl_text4">
                              Transportation Mode
                            </label>
                          </div>
                        </div>
                        <div className="input-box-Im input-height2">
                          <div className="input-box-m1">
                            <input
                              className="modify_im_input mode_category"
                              type="number"
                              required
                              onChange={handleInputChange}
                              name="fuelAvgLtrKM"
                              value={inputValues.fuelAvgLtrKM}
                              ref={input7Ref}
                              onKeyDown={(event) => handleKeyPress(event, input8Ref)}
                            />
                            <label className="modify_p_text2">
                              Fuel Avg Ltr(km)
                              <span className="StarClr">
                                *
                                {errorMessages.fuelAvgLtrKM && (
                                  <label className="errorMessage">
                                    {errorMessages.fuelAvgLtrKM}
                                  </label>
                                )}
                              </span>
                            </label>
                          </div>

                          <div className="input-box-m1">
                            <input
                              className="modify_im_input mode_limit mode_category"
                              type="number"
                              required
                              onChange={handleInputChange}
                              name="fuelChargeKM"
                              value={inputValues.fuelChargeKM}
                              ref={input8Ref}
                              onKeyDown={(event) => handleKeyPress(event, input9Ref)}
                            />
                            <label className="modify_lbl_text2">
                              Fuel Charge(km)
                            </label>
                          </div>
                        </div>
                        {(() => {
                          var val = transportMaster ? input10Ref : input4Ref;
                          return (
                            <div className="input-box-Im input-height2">
                              <div className="input-box-m1">
                                <select
                                  className="modify_im_input mode_category"
                                  type="text"
                                  required
                                  onChange={handleInputChange}
                                  name="vehicleType"
                                  value={inputValues.vehicleType}
                                  ref={input9Ref}
                                  onKeyDown={(event) => handleKeyPress(event, val)}
                                >
                                  <option value="Vehicle 1">2 Wheeler</option>
                                  <option value="Vehicle 2">4 Wheeler</option>
                                  {/* <option value="Vehicle 3">EV Bike</option> */}
                                </select>
                                <label className="modify_lbl_text3">
                                  Vehicle Type
                                </label>
                              </div>
                            </div>
                          )
                        })()}
                      </div>
                    )}
                  </div>
                  {transportMaster && (
                    <div
                      className="company-info-top-right"
                      style={{ width: "50%" }}
                    >
                      <div className="company_info-text-im">Phone Details</div>
                      <div className="stock-im-1">
                        <div className="input-box-Im input-height2">
                          <div className="input-box-m1">
                            <input
                              className="modify_im_input mode_category"
                              type="text"
                              required
                              onChange={handleInputChange}
                              name="phone"
                              value={inputValues.phone}
                              ref={input10Ref}
                              onKeyDown={(event) => handleKeyPress(event, input11Ref)}
                            />
                            <label className="modify_p_text2">Phone</label>
                          </div>

                          <div className="input-box-m1">
                            <input
                              className="modify_im_input mode_limit mode_category"
                              type="text"
                              required
                              onChange={handleInputChange}
                              name="email"
                              value={inputValues.email}
                              ref={input11Ref}
                              onKeyDown={(event) => handleKeyPress(event, input12Ref)}
                            />
                            <label className="modify_lbl_text2">E-Mail</label>
                          </div>
                        </div>
                        <div className="input-box-Im input-height2">
                          <div className="input-box-m1">
                            <input
                              className="modify_im_input mode_category"
                              type="text"
                              required
                              onChange={handleInputChange}
                              name="city"
                              value={inputValues.city}
                              ref={input12Ref}
                              onKeyDown={(event) => handleKeyPress(event, input13Ref)}
                            />
                            <label className="modify_p_text2">City</label>
                          </div>
                          <div className="input-box-m1">
                            <input
                              className="modify_im_input mode_limit mode_category"
                              type="text"
                              required
                              onChange={handleInputChange}
                              name="pinCode"
                              value={inputValues.pinCode}
                              ref={input13Ref}
                              onKeyDown={(event) => handleKeyPress(event, input14Ref)}
                            />
                            <label className="modify_lbl_text2">
                              Pincode{" "}
                              <span className="StarClr">
                                *
                                {errorMessages.pinCode && (
                                  <label className="errorMessage">
                                    {errorMessages.pinCode}
                                  </label>
                                )}
                              </span>
                            </label>
                          </div>
                        </div>
                        <div className="input-box-Im input-height2">
                          <div className="input-box-m1">
                            <select className="modify_im_input mode_limit mode_category" onChange={handleInputChange}
                              name="state"
                              value={inputValues.state}
                              ref={input14Ref}
                              onKeyDown={(event) => handleKeyPress(event, input15Ref)} type="text" required>
                              <option Classname="form-control s-1" value=""></option>
                              {(() => {
                                if (stateInfo !== null && Array.isArray(stateInfo)) {  // && Array.isArray(stateInfo)
                                  return stateInfo.map((data) => (
                                    // console.log(data.stateName),
                                    <option key={data.id} value={data.id}>
                                      {data.stateName}
                                    </option>
                                  ))
                                }
                              })()}
                            </select>
                            <label className="modify_p_text2">
                              State{" "}
                              <span className="StarClr">
                                *
                                {errorMessages.state && (
                                  <label className="errorMessage">
                                    {errorMessages.state}
                                  </label>
                                )}
                              </span>
                            </label>
                          </div>
                          <div className="input-box-m1">
                            <input
                              className="modify_im_input mode_limit mode_category"
                              type="text"
                              required
                              onChange={handleInputChange}
                              name="extraField1"
                              value={inputValues.extraField1}
                              ref={input15Ref}
                              onKeyDown={(event) => handleKeyPress(event, input16Ref)}
                            />
                            <label className="modify_lbl_text2">Example1</label>
                          </div>
                        </div>
                        <div className="input-box-Im input-height2">
                          <div className="input-box-m1">
                            <input
                              className="modify_im_input mode_category"
                              type="text"
                              required
                              onChange={handleInputChange}
                              name="extraField2"
                              value={inputValues.extraField2}
                              ref={input16Ref}
                              onKeyDown={(event) => handleKeyPress(event, input17Ref)}
                            />
                            <label className="modify_p_text2">Example2</label>
                          </div>
                          <div className="input-box-m1">
                            <input
                              className="modify_im_input mode_limit mode_category"
                              type="text"
                              required
                              onChange={handleInputChange}
                              name="extraField3"
                              value={inputValues.extraField3}
                              ref={input17Ref}
                              onKeyDown={(event) => handleKeyPress(event, input18Ref)}
                            />
                            <label className="modify_lbl_text2">Example3</label>
                          </div>
                        </div>
                        <div className="input-box-Im input-height2">
                          <div className="input-box-m1">
                            <input
                              className="modify_im_input mode_category"
                              type="text"
                              required
                              onChange={handleInputChange}
                              name="extraField4"
                              value={inputValues.extraField4}
                              ref={input18Ref}
                              onKeyDown={(event) => handleKeyPress(event, input19Ref)}
                            />
                            <label className="modify_p_text2">Example4</label>
                          </div>
                          <div className="input-box-m1">
                            <input
                              className="modify_im_input mode_limit mode_category"
                              type="text"
                              required
                              onChange={handleInputChange}
                              name="extraField5"
                              value={inputValues.extraField5}
                              ref={input19Ref}
                              onKeyDown={(event) => handleKeyPress(event, input20Ref)}
                            />
                            <label className="modify_lbl_text2">Example5</label>
                          </div>
                        </div>

                        <div className="input-box-tab-head">
                          <div className="input-box-m2">
                            <textarea
                              className="modify_im_textarea mode_category2"
                              type="text"
                              // placeholder="Write your address here....."
                              required
                              onChange={handleInputChange}
                              name="address"
                              value={inputValues.address}
                              ref={input20Ref}
                              onKeyDown={(event) => handleKeyPress(event, input21Ref)}
                            />
                            <label className="modify_p_text-fix">Address</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="buttom-box">
            <div className="button-btn-1">
              <button className="btn-1">Save</button>
            </div>
            <div className="button-btn-2">
              <button className="btn-2">Reset</button>
            </div>
            <div className="button-btn-3">
              <button className="btn-3" onClick={showModal}>
                <i className="fa-solid fa-gear "></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      {alertBox && (
        <AlertBox
          HeadingTop="Saved"
          Heading="Your Transporter Group is Saved"
          onYesClick={OpenAlertBox}
          singleButton={true}
          OpenAlertBox={OpenAlertBox}
        />
      )}

      {alertBoxEdit && (
        <AlertBox
          HeadingTop="Updated"
          Heading="Your Transporter Group is Updated"
          onYesClick={OpenAlertBoxEdit}
          singleButton={true}
          OpenAlertBox={OpenAlertBoxEdit}
        />
      )}
      {loading && <Loader />}
    </div>
  );
}

export default TransportMaster;
