import React, { useEffect, useState } from "react";
function AppHeaderDetail({ setLoading, fetchData, title }) {

    useState(() => {
        document.title = title;
    }, []);

    const handleTouchStart = (e) => {
    };

    const handleTouchMove = (e) => {
        setLoading(true)
    };

    const handleTouchEnd = () => {
        fetchData();
        setLoading(false)
    };
    const backBtn = () => {
        window.history.back();
    }

    return (
        <div>
            <div>
                <div className="heading"
                    style={{ display: "flex" }}>
                    <i style={{ fontSize: '20px' }} className="fa-solid fa-arrow-left icon-white" onClick={backBtn}></i>
                    <div className="" onTouchStart={e => { handleTouchStart(e); }}
                        onTouchMove={e => { handleTouchMove(e); }}
                        onTouchEnd={e => { handleTouchEnd(e) }}
                        style={{ alignItems: "baseline", height: "25px", width: "100%" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                            <label className="head-title-ot">{title}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default AppHeaderDetail;
