import axios from '../axiosConfig';

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;
var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const SmsTemplate = {

    insertSmsTemplate: async (data) => {
        debugger
        try {
            const apiUrl = `${WebApiUrl}/api/SmsTemplates`;

            //const apiUrl = `${express_server_url}/api/SmsTemplate`;
            console.log(apiUrl);
            const response = await axios.post(apiUrl, {
                title: data.title,
                templateForSMSAPI: data.templateForSMSAPI,
                templateId: data.templateId,
                queryName: data.queryName,
                fieldName: data.fieldName,
                smsTemplateBody: data.smsTemplateBody,
                templateForWhatsappAPI: data.templateForWhatsappAPI,
                whatsappLanguage: data.whatsappLanguage,
                messageHeaderSection: data.messageHeaderSection,
                messageBodySection: data.messageBodySection,
                smsType: data.smsType,
                templateForFormDetails: data.templateForFormDetails,
                isActive: true,
            }, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return true;
        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },


    MessageTemplate: async (data) => {
        debugger
        try {
            const apiUrl = `${WebApiUrl}/api/SmsTemplates/saveMessageTemplate`;
            console.log(apiUrl);
            const response = await axios.post(apiUrl, {
                SMSTypeId: data.smsType,
                TemplateId: data.template,

                SMSTypeName: data.smsTypeName,
                TemplateName: data.templateName
            }, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return true;
        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },
    // ************************* Get All ***************************
    getSmsTemplate: async () => {
        try {
            const apiUrl = `${WebApiUrl}/api/SmsTemplates/GetSmsTemplate`;
            //const apiUrl = `${express_server_url}/api/SmsTemplate/GetSmsTemplateMasters`;
            const response = await axios.post(apiUrl, {}, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;

        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    // ************************* Delete ****************************
    deleteSmsTemplate: async (SmsTemplateId) => {
        try {
            const apiUrl = `${WebApiUrl}/api/SmsTemplates?SmsTemplateId=${SmsTemplateId}`;

            //const apiUrl = `${express_server_url}/api/SmsTemplate?SmsTemplateId=${SmsTemplateId}`;
            const response = await axios.delete(apiUrl, {
                withCredentials: true,
            });
            console.log('Delete Response:', response.data.result);
            return true;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    // ************************* Get By Id ****************************
    getSmsTemplateById: async (data) => {
        try {
            debugger
            const apiUrl = `${WebApiUrl}/api/SmsTemplates/GetSmsById?SmsTemplateId=${data}`;

            //const apiUrl = `${express_server_url}/api/SmsTemplate?SmsTemplateId=${SmsTemplateId}`;
            const response = await axios.get(apiUrl, {}, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },
    // *************************** Update ***************************

    updateSmsTemplate: async (data) => {
        debugger    
        console.log(data.id)
        try {
            const apiUrl = `${WebApiUrl}/api/SmsTemplates/UpdateSmsTemplate?SmsTemplateId=${data.id}`;

            //const apiUrl = `${express_server_url}/api/SmsTemplate?SmsTemplateId=${data.id}`;
            console.log(apiUrl);
            const response = await axios.put(apiUrl, {
                id: data.id,
                title: data.title,
                templateForSMSAPI: data.templateForSMSAPI,
                templateId: data.templateId,
                queryName: data.queryName,
                fieldName: data.fieldName,
                smsTemplateBody: data.smsTemplateBody,
                templateForWhatsappAPI: data.templateForWhatsappAPI,
                whatsappLanguage: data.whatsappLanguage,
                messageHeaderSection: data.messageHeaderSection,
                messageBodySection: data.messageBodySection,
                smsType: data.smsType,
                templateForFormDetails: data.templateForFormDetails,
                isActive: true,
            }, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return response.data.isSuccess;
        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },

    GetAssignTemplates: async ()=>{
        try {
            const apiUrl = `${WebApiUrl}/api/SmsTemplates/GetAssignTemplates`;
            //const apiUrl = `${express_server_url}/api/SmsTemplate/GetSmsTemplateMasters`;
            const response = await axios.post(apiUrl, {}, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;

        } catch (error) {
            console.error('Error:', error.message);
        }
    }
};

export default SmsTemplate;
