
import ListSaveButton from "../../Components/ListSaveButton";
import PageTabs from "../../Components/PageTabs";
import React, { useState } from "react";
import TableButton from "../../Props/TableButton";

function ProductPriceAccCatWise() {

     /***********title***************/
     useState(() => {
        document.title = 'Product Price Account Category Wise';
    }, []);

     //************Check box************/ /
     const [checkboxes, setCheckboxes] = useState({
        checkbox1: false,
        checkbox2: false,
        checkbox3: false,
        checkbox4: false,
        checkbox5: false,
        checkbox6: false,
        checkbox7: false,

    });

    const handleCheckboxChange = (checkboxName) => {
        setCheckboxes((prevCheckboxes) => ({
            ...prevCheckboxes,
            [checkboxName]: !prevCheckboxes[checkboxName],
        }));
    };

    const handleSelectAll = () => {
        const allChecked = Object.values(checkboxes).every((isChecked) => isChecked);

        setCheckboxes((prevCheckboxes) => {
            const updatedCheckboxes = {};
            for (const checkboxName in prevCheckboxes) {
                updatedCheckboxes[checkboxName] = !allChecked;
            }
            return updatedCheckboxes;
        });
    };
    

     //************End Check box************/ /
    return (
        <div className="" >
            < PageTabs />
            <div className="nav-1" >
                <div className="panel-heading">
                    <h2 className="panel-title">Product Price Account Category Wise</h2>
                    < TableButton />

                </div>
                <div className="panel panel-default">
                    <div className="panel-body">
                        <div className="search_panel_hd1">
                            <div className="search_panel_left">
                                <div className="col-sm-n1">
                                    <div className="group_head">
                                        {/* <svg className="search_panel_icon" aria-hidden="true" viewBox="0 0 24 24"><g><path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path></g></svg> */}
                                        <input placeholder="Product Group" type="text" className="bill_panel_input" />
                                    </div>
                                    <div className="group_head">
                                        {/* <svg className="search_panel_icon" aria-hidden="true" viewBox="0 0 24 24"><g><path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path></g></svg> */}
                                        <input placeholder="Product Name" type="text" className="bill_panel_input" />
                                    </div>
                                </div>
                            </div>
                         
                            <div className="search_panel_right">
                               
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="table-resposive" >
                                <table id="tblTransactionList" className="table_hd1 table-bordered1" frame="box">
                                    <thead textAlign="center" className="thead_scroll_col1" >
                                        <tr className="">
                                            {/* <th><div className="table_head_m1">S.No.</div></th> */}
                                            <th className="td_col6"> <div className="table_head_mmb ">
                                                <div className="table_head_mmb1">S.No.</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col6"> <div className="table_head_nnb">
                                                <div className="table_head_mmb1" style={{ textAlign: "center" }}>Item Name</div>
                                                <div className="table_head_mmb2" style={{ textAlign: "center" }}><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col6"> <div className="table_head_nnb">
                                                <div className="table_head_nnb1" style={{ textAlign: "center" }}>Parent Group</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col6"> <div className="table_head_nhb">
                                                <div className="table_head_mmb1" style={{ textAlign: "center" }}>Sale Price</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col6"> <div className="table_head_nhb">
                                                <div className="table_head_mmb1" style={{ textAlign: "center" }}>Cat 1 Price</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col6"> <div className="table_head_nhb">
                                                <div className="table_head_mmb1">Cat 2 Price</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col6"> <div className="table_head_nhb">
                                                <div className="table_head_mmb1" style={{ textAlign: "center" }}>Cat 3 Price</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col6"> <div className="table_head_nhb">
                                                <div className="table_head_mmb1" style={{ textAlign: "center" }}>Cat 4 Price</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col6"> <div className="table_head_nhb">
                                                <div className="table_head_mmb1" style={{ textAlign: "center" }}>Cat 5 Price</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>
                                            <th className="td_col6"> <div className="table_head_mab">
                                                <div><input type="checkbox" className="check_box" checked={Object.values(checkboxes).every((isChecked) => isChecked)}
                                                onChange={handleSelectAll} name="" id="" /></div>
                                                <div className="table_head_mmbb1" >Select All</div>
                                                <div className="table_head_mmb2"><i className="fa-solid fa-sort-up t_icon1"></i><i className="fa-solid fa-sort-down t_icon2"></i></div>
                                            </div> </th>

                                            <th className="table_edit table_head_mmb1 " style={{ textAlign: "center" }} >Open</th>
                                            {/* <th className="table_delete table_head_mmb1" style={{ textAlign: "center" }} >Delete</th> */}


                                        </tr>
                                    </thead>
                                    <tbody className="tbody_body_m1">
                                        <tr className="table_tbody_tr1">
                                            <td className="td_col6">1</td>
                                            <td className="td_col6" style={{ textAlign: "center" }}>27-10-2023</td>
                                            <td className="td_col6" style={{ textAlign: "center" }}>SALE</td>
                                            <td className="td_col6" style={{ textalign: "start" }}><input className="table-input-box" type="text" name="" id="" /></td>
                                            <td className="td_col6" style={{ textalign: "start" }}><input className="table-input-box" type="text" name="" id="" /></td>
                                            <td className="td_col6" style={{ textalign: "start" }}><input className="table-input-box" type="text" name="" id="" /></td>
                                            <td className="td_col6" style={{ textalign: "start" }}><input className="table-input-box" type="text" name="" id="" /></td>
                                            <td className="td_col6" style={{ textalign: "start" }}><input className="table-input-box" type="text" name="" id="" /></td>
                                            <td className="td_col6" style={{ textalign: "start" }}><input className="table-input-box" type="text" name="" id="" /></td>
                                            <td className="td_col6" style={{ textAlign: "center" }}><input type="checkbox" className="check_box" checked={checkboxes.checkbox1}
                                            onChange={() => handleCheckboxChange('checkbox1')} name="" id="" /></td>
                                            <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                            {/* <td><button className="delete_btn"><i className="fa-solid fa-trash"></i></button></td> */}


                                        </tr>
                                        <tr className="table_tbody_tr1">
                                            <td className="td_col6">2</td>
                                            <td className="td_col6" style={{ textAlign: "center" }}>27-10-2023</td>
                                            <td className="td_col6" style={{ textAlign: "center" }}>SALE</td>
                                            <td className="td_col6" style={{ textalign: "start" }}><input className="table-input-box" type="text" name="" id="" /></td>
                                            <td className="td_col6" style={{ textalign: "start" }}><input className="table-input-box" type="text" name="" id="" /></td>
                                            <td className="td_col6" style={{ textalign: "start" }}><input className="table-input-box" type="text" name="" id="" /></td>
                                            <td className="td_col6" style={{ textalign: "start" }}><input className="table-input-box" type="text" name="" id="" /></td>
                                            <td className="td_col6" style={{ textalign: "start" }}><input className="table-input-box" type="text" name="" id="" /></td>
                                            <td className="td_col6" style={{ textalign: "start" }}><input className="table-input-box" type="text" name="" id="" /></td>
                                            <td className="td_col6" style={{ textAlign: "center" }}> <input type="checkbox" className="check_box" checked={checkboxes.checkbox2}
                                            onChange={() => handleCheckboxChange('checkbox2')} name="" id="" /></td>
                                            <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                            {/* <td><button className="delete_btn"><i className="fa-solid fa-trash"></i></button></td> */}

                                        </tr>
                                        <tr className="table_tbody_tr1">
                                            <td className="td_col6">3</td>
                                            <td className="td_col6" style={{ textAlign: "center" }}>27-10-2023</td>
                                            <td className="td_col6" style={{ textAlign: "center" }}>SALE</td>
                                            <td className="td_col6" style={{ textalign: "start" }}><input className="table-input-box" type="text" name="" id="" /></td>
                                            <td className="td_col6" style={{ textalign: "start" }}><input className="table-input-box" type="text" name="" id="" /></td>
                                            <td className="td_col6" style={{ textalign: "start" }}><input className="table-input-box" type="text" name="" id="" /></td>
                                            <td className="td_col6" style={{ textalign: "start" }}><input className="table-input-box" type="text" name="" id="" /></td>
                                            <td className="td_col6" style={{ textalign: "start" }}><input className="table-input-box" type="text" name="" id="" /></td>
                                            <td className="td_col6" style={{ textalign: "start" }}><input className="table-input-box" type="text" name="" id="" /></td>
                                            <td className="td_col6" style={{ textAlign: "center" }}> <input type="checkbox" className="check_box" checked={checkboxes.checkbox3}
                                            onChange={() => handleCheckboxChange('checkbox3')} name="" id="" /></td>
                                            <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                            {/* <td><button className="delete_btn"><i className="fa-solid fa-trash"></i></button></td> */}

                                        </tr>
                                        <tr className="table_tbody_tr1">
                                            <td className="td_col6">4</td>
                                            <td className="td_col6" style={{ textAlign: "center" }}>27-10-2023</td>
                                            <td className="td_col6" style={{ textAlign: "center" }}>SALE</td>
                                            <td className="td_col6" style={{ textalign: "start" }}><input className="table-input-box" type="text" name="" id="" /></td>
                                            <td className="td_col6" style={{ textalign: "start" }}><input className="table-input-box" type="text" name="" id="" /></td>
                                            <td className="td_col6" style={{ textalign: "start" }}><input className="table-input-box" type="text" name="" id="" /></td>
                                            <td className="td_col6" style={{ textalign: "start" }}><input className="table-input-box" type="text" name="" id="" /></td>
                                            <td className="td_col6" style={{ textalign: "start" }}><input className="table-input-box" type="text" name="" id="" /></td>
                                            <td className="td_col6" style={{ textalign: "start" }}><input className="table-input-box" type="text" name="" id="" /></td>
                                            <td className="td_col6" style={{ textAlign: "center" }}> <input type="checkbox" className="check_box" checked={checkboxes.checkbox4}
                                            onChange={() => handleCheckboxChange('checkbox4')} name="" id="" /></td>
                                            <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                            {/* <td><button className="delete_btn"><i className="fa-solid fa-trash"></i></button></td> */}

                                        </tr>
                                        <tr className="table_tbody_tr1">
                                            <td className="td_col6">5</td>
                                            <td className="td_col6" style={{ textAlign: "center" }}>28-10-2023</td>
                                            <td className="td_col6" style={{ textAlign: "center" }}>SALE</td>
                                            <td className="td_col6" style={{ textalign: "start" }}><input className="table-input-box" type="text" name="" id="" /></td>
                                            <td className="td_col6" style={{ textalign: "start" }}><input className="table-input-box" type="text" name="" id="" /></td>
                                            <td className="td_col6" style={{ textalign: "start" }}><input className="table-input-box" type="text" name="" id="" /></td>
                                            <td className="td_col6" style={{ textalign: "start" }}><input className="table-input-box" type="text" name="" id="" /></td>
                                            <td className="td_col6" style={{ textalign: "start" }}><input className="table-input-box" type="text" name="" id="" /></td>
                                            <td className="td_col6" style={{ textalign: "start" }}><input className="table-input-box" type="text" name="" id="" /></td>
                                            <td className="td_col6" style={{ textAlign: "center" }}> <input type="checkbox" className="check_box" checked={checkboxes.checkbox5}
                                            onChange={() => handleCheckboxChange('checkbox5')} name="" id="" /></td>
                                            <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                            {/* <td><button className="delete_btn"><i className="fa-solid fa-trash"></i></button></td> */}

                                        </tr>
                                        <tr className="table_tbody_tr1">
                                            <td className="td_col6">6</td>
                                            <td className="td_col6" style={{ textAlign: "center" }}>28-10-2023</td>
                                            <td className="td_col6" style={{ textAlign: "center" }}>SALE</td>
                                            <td className="td_col6" style={{ textalign: "start" }}><input className="table-input-box" type="text" name="" id="" /></td>
                                            <td className="td_col6" style={{ textalign: "start" }}><input className="table-input-box" type="text" name="" id="" /></td>
                                            <td className="td_col6" style={{ textalign: "start" }}><input className="table-input-box" type="text" name="" id="" /></td>
                                            <td className="td_col6" style={{ textalign: "start" }}><input className="table-input-box" type="text" name="" id="" /></td>
                                            <td className="td_col6" style={{ textalign: "start" }}><input className="table-input-box" type="text" name="" id="" /></td>
                                            <td className="td_col6" style={{ textalign: "start" }}><input className="table-input-box" type="text" name="" id="" /></td>
                                            <td className="td_col6" style={{ textAlign: "center" }}> <input type="checkbox" className="check_box" checked={checkboxes.checkbox6}
                                            onChange={() => handleCheckboxChange('checkbox6')} name="" id="" /></td>
                                            <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                            {/* <td><button className="delete_btn"><i className="fa-solid fa-trash"></i></button></td> */}

                                        </tr>
                                        <tr className="table_tbody_tr1">
                                            <td className="td_col6">7</td>
                                            <td className="td_col6" style={{ textAlign: "center" }}>20-10-2023</td>
                                            <td className="td_col6" style={{ textAlign: "center" }}>SALE</td>
                                            <td className="td_col6" style={{ textalign: "start" }}><input className="table-input-box" type="text" name="" id="" /></td>
                                            <td className="td_col6" style={{ textalign: "start" }}><input className="table-input-box" type="text" name="" id="" /></td>
                                            <td className="td_col6" style={{ textalign: "start" }}><input className="table-input-box" type="text" name="" id="" /></td>
                                            <td className="td_col6" style={{ textalign: "start" }}><input className="table-input-box" type="text" name="" id="" /></td>
                                            <td className="td_col6" style={{ textalign: "start" }}><input className="table-input-box" type="text" name="" id="" /></td>
                                            <td className="td_col6" style={{ textalign: "start" }}><input className="table-input-box" type="text" name="" id="" /></td>
                                            <td className="td_col6" style={{ textAlign: "center" }}> <input type="checkbox" className="check_box" checked={checkboxes.checkbox7}
                                            onChange={() => handleCheckboxChange('checkbox7')} name="" id="" /></td>
                                            <td><button className="edit_btn"><i className="fa-solid fa-pen-to-square"></i></button></td>
                                            {/* <td><button className="delete_btn"><i className="fa-solid fa-trash"></i></button></td> */}

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="input-remark-01">
                <div className="input-remark-left01">
                    
                    <div className="input-remark-box-mm1 ">
                        <select className="modify_im_input mode_limits mode_category input-so1" type="text" required>
                            <option Classname="form-control s-1" value="">Category 1</option>
                            <option Classname="form-control s-1" value="">Category 2</option>
                            <option Classname="form-control s-1" value="">Category 3</option>
                            <option Classname="form-control s-1" value="">Category 4</option>
                            <option Classname="form-control s-1" value="">Category 5</option>
                        </select>
                    </div>
                    <div className="input-remark-box-mm1 ">
                        <center className="equal">=</center>
                    </div>
                    {/* <div className="input-box-m9 dis-flex padding-03 role_pad{"> */}


                    <div className="input-box-vo1">
                        <input className="modify_im_input mode_category input-so1 mid-size-m01" type="text" required />
                    </div>
                    <div className="input-box-vo1">
                        <div className="input-remark-box-mm1 load_pad ">
                            <select className="modify_im_input mode_limits mode_category input-so1" type="text" required>
                                <option Classname="form-control s-1" value="Pending">SUB</option>
                                <option Classname="form-control s-1" value="Approved">ADD</option>
                                <option Classname="form-control s-1" value="Cancel">MULTIPLY</option>
                                <option Classname="form-control s-1" value="Conv_Pur_Challan">DIVISION</option>
                            </select>
                        </div>
                    </div>
                    <div className="input-box-vo1">
                        <input className="modify_im_input mode_category input-so1 mid-size-m01" type="text" required />
                    </div>
                    <div className="button-mp3">
                        <button id="step-5" className="calculate_btn">
                            Calculate

                            <i className="fa-solid fa fa-calculator im-icon-2"></i>
                        </button>
                    </div>
                    {/* </div> */}
                </div>
                <div className="input-remark-right">
                    <div className="input-remark-m1">
                        <div className="input-box-s11 input-height2">

                            {/* <div className="input-box-m9 dis-flex padding-03 role_pad{">


                                <div className="input-box-vo1">
                                    <input className="modify_im_input mode_category input-so1 mid-size-m01" type="text" required />
                                </div>
                                <div className="input-box-vo1">
                                    <div className="input-remark-box-mm1 ">
                                        <select className="modify_im_input mode_limits mode_category input-so1" type="text" required>
                                            <option Classname="form-control s-1" value="Pending">SUB</option>
                                            <option Classname="form-control s-1" value="Approved">ADD</option>
                                            <option Classname="form-control s-1" value="Cancel">MULTIPLY</option>
                                            <option Classname="form-control s-1" value="Conv_Pur_Challan">DIVISION</option>


                                        </select>
                                    </div>
                                </div>
                                <div className="input-box-vo1">
                                    <input className="modify_im_input mode_category input-so1 mid-size-m01" type="text" required />
                                </div>
                            </div> */}

                            {/* <div className="button-mp3">
                                <button id="step-5" className="calculate_btn">
                                    Calculate

                                    <i className="fa-solid fa fa-calculator im-icon-2"></i>
                                </button>
                            </div> */}
                            <div className="input-box-mo9 dis-flex padding-m03">
                                <span className=" solo4">Index</span>
                                <div className="input-remark-box-mm1 ">
                                    <input className="modify_im_input mode_category input-so1 mid-size-m01" type="text" required />
                                </div>
                                <div className="input-remark-box-mm1 ">
                                    <input className="modify_im_input mode_category input-so1 mid-size-m01" type="text" required />
                                </div>
                                <div className="button-mpo2" >
                                    <button className="show_btn">
                                        Show

                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="button-mp2" >
                    <button className="save_cat_btn">
                        Save Category Price

                    </button>
                </div>
            </div>
            <div className="wrapper">
                <div className="container">
                    <div className=" content-row-1">
                        <div className="class_control">
                            <div className="balance-1">
                                <div className="p-11">
                                    <p className="balance-p1"><i className="fa-solid fa-arrow-up icon_3"></i>Sale</p>
                                </div>
                                <div className="p-12">
                                    <p className="balance-p2"><i className="fa-solid fa-indian-rupee-sign rs_sign"></i>100</p>
                                </div>
                            </div>
                            <div className="balance-1">
                                <div className="p-11">
                                    <p className="balance-p1"> <i className="fa-solid fa-clipboard icon_3"></i> Total</p>
                                </div>
                                <div className="p-12">
                                    <p className="balance-p2"><i className="fa-solid fa-indian-rupee-sign rs_sign"></i>200</p>
                                </div>
                            </div>
                            <div className="balance-1">
                                <div className="p-11">
                                    <p className="balance-p1"><i className="fa-solid fa-arrow-down icon_3"></i>Return</p>
                                </div>
                                <div className="p-12">
                                    <p className="balance-p2"><i className="fa-solid fa-indian-rupee-sign rs_sign"></i>100</p>
                                </div>
                            </div>
                        </div>
                        <div className="class_control_2">
                            <div className="parti_1">
                                <div className="sub_parti_1">
                                    <p className="parti-p-1">Parties</p>
                                </div>
                            </div>
                            <div className="parti_1">
                                <div className="sub_parti_1">
                                    <p className="parti-p-1">Trasaction</p>
                                </div>
                            </div>
                            <div className="parti_1">
                                <div className="sub_parti_1">
                                    <p className="parti-p-1">Items</p>
                                </div>
                            </div>
                        </div>
                        <div className="class_control_4">
                            <div className="col_ms_1">
                                <p className="p_13" >Recent History</p>
                                <div className="con_input_1">
                                    <input type="text" className="input_box_2" placeholder="Search..." />
                                </div>
                            </div>

                            <div className="col_sm_11">
                                <div className="col_sm_21">
                                    <div className="col_sm_12">
                                        <div className="con_name_1">
                                            <p className="con_p_3">Akash kumar</p>
                                        </div>
                                        <div className="con_name_1">
                                            <p className="con_p_1">Sale</p>
                                        </div>
                                        <div className="con_name_1">
                                            <p className="con_p_2">Total</p>
                                        </div>
                                        <div className="con_name_1">
                                            <p className="con_p_4"><i className="fa-solid fa-indian-rupee-sign rs_sign1"></i>1000</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col_sm_13">
                                    <div className="col_sm_12">
                                        <div className="con_total_3">
                                            <p className="con_p_3">Balance</p>
                                        </div>
                                        <div className="con_total_3">
                                            <p className="con_price_p"><i className="fa-solid fa-indian-rupee-sign rs_sign1"></i>1000</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col_sm_14">
                                    <div className="col_sm_12">
                                        <div className="con_sale_2">
                                            <p className="con_p_3">Date</p>
                                        </div>
                                        <div className="con_sale_2">
                                            <p>#1</p>
                                        </div>
                                        <div className="con_name_1 con_name_7">
                                            <i className="fa-solid fa-print"></i>
                                            <i className="fa-solid fa-share"></i>
                                            <i className="fa-solid fa-ellipsis-vertical"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div>
                            < ListSaveButton />
                        </div>

                    </div>
                </div>
            </div>

        </div >



    );
}

export default ProductPriceAccCatWise;
