function ToolTipMaster({
    isActiveInactive, isInfoGodown, isToolDoubleTax, isToolSecondTax, isToolBarcode,
    isToolMultipleAttribute, isToolManagePrice, NoOfPerson, saveNoOfPerson }) {
    //*********************{Button Script}*********************//
    return (
        <div>
            {/* *****************************{Station Master}****************************** */}
            {isActiveInactive && (
                <div className="tooltip-containerform">
                    <span className="tooltipForm">Active/Inactive</span>
                    <span><i className="fa-solid fa-circle-info TextTooltip1"></i></span>
                </div>
            )}

            {/* *****************************{Meal Course Master}****************************** */}
            {isInfoGodown && (
                <div className="tooltip-containerform">
                    <span className="tooltipForm">Setting info for multiple godown</span>
                    <span><i className="fa-solid fa-circle-info TextTooltipWhite"></i></span>
                </div>
            )}
            {isToolDoubleTax && (
                <div className="tooltip-containerform">
                    <span className="tooltipForm">If the item attracts double tax.
                    </span>
                    <span><i className="fa-solid fa-circle-info TextTooltipWhite"></i></span>
                </div>
            )}
            {isToolSecondTax && (
                <div className="tooltip-containerform">
                    <span className="tooltipForm">After what amount will the second tax category be applicable?
                    </span>
                    <span><i className="fa-solid fa-circle-info TextTooltipWhite"></i></span>
                </div>
            )}
            {isToolBarcode && (
                <div className="tooltip-containerform">
                    <span className="tooltipForm">If the item has a predefined barcode and you
                        do not want software genereted barcode
                    </span>
                    <span><i className="fa-solid fa-circle-info TextTooltipWhite"></i></span>
                </div>)}
            {isToolMultipleAttribute && (
                <div className="tooltip-containerform">
                    <span className="tooltipForm">If item has multiple attributes like color, size etc.

                    </span>
                    <span><i className="fa-solid fa-circle-info TextTooltipWhite"></i></span>
                </div>
            )}
            {isToolManagePrice && (
                <div className="tooltip-containerform">
                    <span className="tooltipForm">If you want to manage customer category wise price, Rate A for customer category A,
                        Rate B for customer category B, and like wise.
                    </span>
                    <span><i className="fa-solid fa-circle-info TextTooltipWhite"></i></span>
                </div>
            )}
            {NoOfPerson && (
                <div className="tooltip-containerform" onClick={saveNoOfPerson}>
                    <span className="tooltipForm">Save No of Person</span>
                    <span><i class="fa-solid fa-square-plus mt-2" style={{ marginLeft: "5px", color: "black" }}></i></span>
                </div>
            )}
        </div>
    )
}

export default ToolTipMaster;