import React, { useEffect, useRef, useState, Component } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Mainpage from "../AppRoutes";
import SmallSideBar from "./Components/SmallSideBar";
import "../css/new.css";
import SideBarNew from "./SideNavBar/SideBarNew";
import LogInPanel from "./LogInPage/LogInPanel";
import MyContext from "./MyContext";
import User from "../API/APIServices/User";
import Company from "../API/APIServices/Company";
import UserCredential from "../API/APIServices/UserCredential";
import Authentication from "../API/APIServices/Authentication";
import AlertBoxError from "./Props/AlertBoxError";

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

function HomePage() {
  // {/* *******************Tab****************** */}
  const [leftContainerWidth, setLeftContainerWidth] = useState(55);
  const [alertBoxEdit, setAlertBoxEdit] = useState(false);
  const [alertBox, setAlertBox] = useState(false);
  const [alertBoxDelete, setAlertBoxDelete] = useState(false);
  const [alertBoxErrorInsert, setAlertBoxErrorInsert] = useState(false);
  const [alertBoxErrorUpdate, setAlertBoxErrorUpdate] = useState(false);
  const [openAlertItemExists, setOpenAlertItemExists] = useState(false);
  const [BusinessType, setBusinessType] = useState();

  useEffect(() => {
    console.log(BusinessType);
  }, [BusinessType]);

  const [loginuser, setLoginUser] = useState(true);

  useEffect(() => {
    const url = window.location.href;
    const val = url.split("/")
    //alert(val[3])
    if (val[3] == "LoginPanel" || val[3] == "loginpanel")
      setLoginUser(false);
    else
      setLoginUser(true);
    // const storedArrayString = localStorage.getItem('loginAccountUserData');
    // console.log(storedArrayString);
    // if (storedArrayString != null && storedArrayString != undefined) {
    //   const loginUser = JSON.parse(storedArrayString);
    //   setLoginUser(true);
    // }
    // else {
    //   setLoginUser(false);
    // }
  }, [])


  const handleToggle = () => {
    if (leftContainerWidth === 55) {
      setLeftContainerWidth(260);
    } else {
      setLeftContainerWidth(55);
    }
  };

  const [openSidebar, setOpenSidebar] = useState(true);
  const toggleSidebar = () => {
    setOpenSidebar(!openSidebar);
  };

  const sidebarClassName =
    openSidebar === true ? "small-sidebar" : "small-sidebar-hidden";
  const sidebarIconchnage =
    openSidebar === true ? "rotate-neg180icon" : "rotate-180icon";

  const [loginSuccess, setLoginSuccess] = useState(true);

  const logindone = async () => {
    const data = await Company.getLoginValidation();
    //alert(loginSuccess)
    setLoginSuccess(data);
  };

  useEffect(() => {
    //logindone();
  }, [loginSuccess])

  const OpenAlertBox = () => {
    setAlertBox(!alertBox);
  };

  const OpenAlertBoxDelete = () => {
    setAlertBoxDelete(!alertBoxDelete);
  };

  const OpenAlertBoxEdit = () => {
    setAlertBoxEdit(!alertBoxEdit);
  };
  const OpenAlertBoxErrorInsert = () => {
    setAlertBoxErrorInsert(!alertBoxErrorInsert);
  };

  const OpenAlertBoxErrorUpdate = () => {
    setAlertBoxErrorUpdate(!alertBoxErrorUpdate);
  };

  const OpenAlertBoxWhenItemExists = () => {
    setOpenAlertItemExists(!openAlertItemExists)
  }

  const imageBaseUrl = `${WebApiUrl}/api/ItemMaster/GetImage?D1ItemId=`;

  const input1 = useRef();
  const input2 = useRef();
  const input3 = useRef();
  const input4 = useRef();
  const input5 = useRef();
  const input6 = useRef();
  const input7 = useRef();

  const handleKeyPress = (event) => {
    if (event.altKey && event.key === "1") {
      if (input1.current) {
        input1.current.focus();
      }
    }
    if (event.altKey && event.key === "2") {
      if (input2.current) {
        input2.current.focus();
      }
    }
    if (event.altKey && event.key === "3") {
      if (input3.current) {
        input3.current.focus();
      }
    }
    if (event.altKey && event.key === "4") {
      if (input4.current) {
        input4.current.focus();
      }
    }
    if (event.altKey && event.key === "5") {
      if (input5.current) {
        input5.current.focus();
      }
    }
    if (event.altKey && event.key === "6") {
      if (input6.current) {
        input6.current.focus();
      }
    }
    if (event.altKey && event.key === "7") {
      if (input7.current) {
        input7.current.focus();
      }
    }
  };


  const navigate = useNavigate();
  const location = useLocation();
  const [routeLink, setRouteLink] = useState(location.search);

  // Effect to update routeLink based on location.search changes
  useEffect(() => {
    setRouteLink(location.search);
  }, [location.search]);

  /*useEffect(() => {
    async function formAuthentication() {
      try {
        const url = window.location.pathname;
        const formName = url.substring(url.lastIndexOf('/') + 1);

        if (formName !== "PreviousOrder" && formName !== "LoginPanel" && formName !== "addupdateformname") {
          const filterData = {
            fieldName: "formName",
            operatorName: "equal",
            compareValue: formName,
          };

          const formData = await Authentication.getAuthenticationWithFilter(filterData);
          const length = formData.result ? formData.result.length : 0;
          console.log("formData", formData)

          if (length > 0) {
            navigate(`/${formName}`);
          } else {
            OpenAlertBoxErrorInsert();
            navigate("/PreviousOrder");
          }
        }
      } catch (error) {
        navigate("/PreviousOrder");
      }
    }

    if (routeLink) {
      formAuthentication();
    }
  }, [routeLink, navigate]); */

  // Set default routeLink if empty
  useEffect(() => {
    if (routeLink === "") {
      setRouteLink("/PreviousOrder");
    }
  }, [routeLink]);

  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener("keydown", handleKeyPress);

    // Detach the event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []); // useEffect dependency array is empty to run it only once on mount

  const [isShowTotalAmountInReport, setShowTotalAmountInReport] = useState(false);
  const [isAllowModificationOfVoucher, setAllowModificationOfVoucher] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const storedArrayString = localStorage.getItem('loginAccountUserData');
      const loginUser = JSON.parse(storedArrayString);
      if (loginUser) {
        const data = await UserCredential.getUserCredentialById(loginUser.userId);
        console.log("dataaaa", data)
        console.log("userDataa", data.isTotalAmtInRpt)
        setShowTotalAmountInReport(data.isTotalAmtInRpt);
        setAllowModificationOfVoucher(data.canModifyVoucher);
      }
    }
    fetchData();
  }, [])

  const [appToWebLink, setAppToWebLink] = useState("");
  const [webToAppLink, setWebToAppLink] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const storedArrayString = localStorage.getItem('loginAccountUserData');
      var loginUser;
      if (storedArrayString) {
        loginUser = JSON.parse(storedArrayString);
      }
      try {
        if (loginUser) {
          const data = await Company.GetCompanyById(loginUser.compId)
          setAppToWebLink(data.urL1)
          setWebToAppLink(data.urL2)

          const userData = await UserCredential.getUserCredentialById(loginUser.userId);
          console.log("dataaaa", userData)
          console.log("userDataa", userData.isTotalAmtInRpt)
          setShowTotalAmountInReport(userData.isTotalAmtInRpt);
        }
      }
      catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [])

  return (
    <>
      <MyContext.Provider
        value={{
          alertBox,
          OpenAlertBox,
          OpenAlertBoxDelete,
          alertBoxDelete,
          setAlertBoxDelete,
          OpenAlertBoxEdit,
          alertBoxEdit,
          alertBoxErrorInsert,
          setAlertBoxErrorInsert,
          alertBoxErrorUpdate,
          setAlertBoxErrorUpdate,
          OpenAlertBoxErrorInsert, OpenAlertBoxErrorUpdate,
          OpenAlertBoxWhenItemExists,
          openAlertItemExists,
          setOpenAlertItemExists,
          input1,
          input2,
          input3,
          input4,
          input5,
          input6,
          input7,
          BusinessType,
          setBusinessType,
          setLoginSuccess,
          imageBaseUrl,
          setLoginUser,
          isShowTotalAmountInReport,
          appToWebLink,
          webToAppLink,
          isAllowModificationOfVoucher,
        }}
      >
        {loginSuccess ? (
          <div>
            <>
              <div className="app-container">
                <div className="left-container">
                  {/* <SideBar class_name={`left-container ${leftContainerWidth === 230 ? 'expanded' : ''}`}
                      width={leftContainerWidth}
                      onMouseEnter1={handleLeftContainerHover}
                      onMouseLeave1={handleLeftContainerLeave}
                      handleToggle={handleToggle} /> */}
                  {loginuser == true ?
                    <SideBarNew
                      class_name={`left-container ${leftContainerWidth === 260 ? "expanded" : ""
                        }`}
                      width={leftContainerWidth}
                      handleToggle={handleToggle}
                    />
                    : <div></div>
                  }
                </div>
                <div className="right-container">
                  <div className="mastercomapny-container">
                    <div className="leftmaster-company"></div>
                    <div className="rightmaster-company"></div>
                    <Mainpage />
                  </div>
                </div>

                {loginuser == true ?
                  <div className={sidebarClassName}>
                    {openSidebar && <SmallSideBar />}
                    <div className="inout-smallbar" onClick={toggleSidebar}>
                      <i
                        className={`fa-solid fa-chevron-left ${sidebarIconchnage}`}
                        style={{ color: "black" }}
                      ></i>
                    </div>
                  </div>
                  :
                  <div></div>
                }
              </div>
            </>
          </div>
        ) : (
          <div >
            {!loginSuccess && (
              <div style={{ display: loginSuccess === null ? "none" : "block" }}>
                <LogInPanel logindone={logindone} setLoginUser={setLoginUser} loginuser={loginuser} />
              </div>
            )}
          </div>
        )}

        {alertBoxErrorInsert && (
          <AlertBoxError
            HeadingTop="Warning"
            Heading="YOU DO NOT HAVE ACCESS TO THIS PAGE."
            onYesClick={OpenAlertBoxErrorInsert}
            singleButton={true}
            OpenAlertBox={OpenAlertBoxErrorInsert}
          />
        )}
      </MyContext.Provider>
    </>
  );
}

export default HomePage;
