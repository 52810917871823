import DateTime from "../../Components/DateTime";
import PageTabs from "../../Components/PageTabs";
import React, { useState, useEffect, useRef, useContext } from "react";
import 'react-quill/dist/quill.snow.css'
import MainButton from "../../Props/MainButton";
import TaxCategoryApi from "../../../API/APIServices/TaxCategory";
import AlertBox from "../../Props/AlertBox";
import MyContext from "../../MyContext";
import Loader from "../../Props/Loader";
import { useNavigate } from 'react-router-dom';

function TaxCategory(props) {

    const [loading, setLoading] = useState(false);
    const { OpenAlertBox, alertBox, OpenAlertBoxEdit, alertBoxEdit } = useContext(MyContext);

    const navigate = useNavigate();

    useState(() => {
        document.title = 'Tax Category';
    }, []);

    const inputRefs = {
        input1: useRef(null),
        input2: useRef(null),
        input3: useRef(null),
        input4: useRef(null),
        input5: useRef(null),
        input6: useRef(null),
        input7: useRef(null),
        input8: useRef(null),
        input9: useRef(null),
    };

    const inputOrder = ['input1', 'input2', 'input3', 'input4', 'input5', 'input6', 'input7', 'input8', 'input9'];
    let currentInputIndex = 0;

    // const handleKeyPress = (event) => {
    //     if (event.key === 'Enter') {
    //         event.preventDefault();
    //         const nextInputIndex = (currentInputIndex + 1) % inputOrder.length;
    //         const nextInputName = inputOrder[nextInputIndex];
    //         inputRefs[nextInputName].current.focus();
    //         currentInputIndex = nextInputIndex;
    //     }
    // };

    // **************************************************************
    const [errorMessages, setErrorMessages] = useState({
        name: '',
        localTaxCGST: "",
        localTaxSGST: "",
        centralTaxIGST: "",
        cessTax: "",
    });

    const [inputValues, setInputValues] = useState({
        name: '',
        taxCategoryType: true,
        localTaxCGST: 0,
        localTaxSGST: 0,
        centralTaxIGST: 0,
        cessTax: 0,
        taxOnMRP: true,
        remarks: "",
        isActive: true,
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        console.log(`Changing ${name} to ${value}`);

        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
        setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));
    };

    const handleInputChangeActive = (e) => {
        const { name, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : e.target.value;

        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [name]: type === 'checkbox' ? !prevInputValues[name] : newValue,
        }));

        setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));
        console.log(inputValues);
    };

    const toFriendlyName = (fieldName) => {
        return fieldName
            .replace(/([A-Z])/g, ' $1') // Add space before capital letters
            .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
    };

    const validateInput = (fieldName) => {
        console.log(fieldName);
        if (typeof inputValues[fieldName] === 'string' && inputValues[fieldName].trim() === '') {
            setErrorMessages((prevErrors) => ({ ...prevErrors, [fieldName]: `${toFriendlyName(fieldName)} field is required` }));
            return false;
        }
        return true;
    };
    // const fieldNames = ['name', 'localTaxCGST', 'localTaxSGST', 'centralTaxIGST', 'cessTax'];
    const fieldNames = ['name'];

    const SaveBtn = () => {
        let allValid = true;
        fieldNames.forEach((fieldName) => {
            const isValid = validateInput(fieldName);
            if (!isValid) {
                allValid = false;
            }
        });
        if (allValid) {
            setLoading(true);
            if (Boolean(props.modelOpen) === true) {
                const isSuccess = TaxCategoryApi.updateTaxCategory(inputValues);
                if (isSuccess) {
                    OpenAlertBoxEdit();
                    setLoading(false);
                    props.fetchData()
                    props.setSliderCallBack(false);
                } else {
                    alert("error while updating");
                }
            }
            else {
                setLoading(true);
                const isSuccess = TaxCategoryApi.insertTaxCategory(inputValues);
                if (isSuccess) {
                    setLoading(false);
                    OpenAlertBox();
                } else {
                    alert("Error inserting data");
                }
            }
            setInputValues({
                name: '',
                taxCategoryType: true,
                localTaxCGST: 0,
                localTaxSGST: 0,
                centralTaxIGST: 0,
                cessTax: 0,
                taxOnMRP: true,
                remarks: "",
                isActive: true,
            });
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (props.editData) {
                    try {
                        const data = await props.editData;
                        console.log(data);
                        setInputValues(data);
                    } catch (error) {
                        console.log(error);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [props.editData]);

    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);
    const input4Ref = useRef(null);
    const input5Ref = useRef(null);
    const input6Ref = useRef(null);
    const input7Ref = useRef(null);
    const input8Ref = useRef(null);
    const input9Ref = useRef(null);

    const handleKeyPress = (event, nextInputRef) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            nextInputRef.current.focus();
        }
    };


    return (
        <div>
            < PageTabs locationTab={"/TaxCategory"} modelOpen={props.modelOpen} setSliderCallBack={props.setSliderCallBack} />
            <div className="panel-heading">
                <div className="panel-head-title">
                    <h2 className="panel-title">Tax Category</h2>
                </div >
                < MainButton SaveButton={SaveBtn} modelOpen={props.modelOpen} setSliderCallBack={props.setSliderCallBack} />
            </div>
            <div>
                < DateTime />
            </div>
            <div className="content_box">
                <div className="company-info-top-im">
                    <div className="tab-head">
                        <div id="tabs">
                            <div className="tab-content-box">
                                <div className="tab-content-1">
                                    <div className="company-info-top-left" >
                                        <div className="company_info-text-im td-1">General Details
                                            <div className="input-box-checkbox1 ">
                                                <div className=" td-2">
                                                    <div className="cl-toggle-switch td-3">
                                                        <label className="cl-switch">
                                                            <input type="checkbox" onChange={handleInputChangeActive} name="isActive" checked={inputValues.isActive} value={inputValues.isActive} />
                                                            <span></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="input-box-Im ">
                                                <div className="input-box-m2">
                                                    <input className="modify_im_input mode_category"
                                                        onChange={handleInputChange}
                                                        ref={input1Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input2Ref)}
                                                        type="text" required
                                                        value={inputValues.name}
                                                        name="name" />
                                                    <label className="modify_label_text3">Name
                                                        <span className="StarClr">*
                                                            {errorMessages.name && <label className="errorMessage">{errorMessages.name}</label>}
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="input-box-Im ">

                                                <div className="input-box-m2">
                                                    <select className="modify_im_input mode_category"
                                                        onChange={handleInputChange}
                                                        ref={input2Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input3Ref)}
                                                        type="text" required
                                                        name="taxCategoryType"
                                                        value={inputValues.taxCategoryType}>
                                                        <option value="false">Product</option>
                                                        <option value="true">Service</option>
                                                    </select>
                                                    <label className="modify_lbl_text3">Type</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="company_info-text">Assigned Default VAT/GST Value</div>
                                        <div className="">
                                            <div className="input-box-Im ">
                                                <div className="input-box-m2">
                                                    <input className="modify_im_input mode_category"
                                                        onChange={handleInputChange}
                                                        ref={input3Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input4Ref)}
                                                        type="text" required
                                                        name="localTaxCGST"
                                                        value={inputValues.localTaxCGST} />
                                                    <label className="modify_label_text3">Local Tax 1(CGST)
                                                        {/* <span className="StarClr">*
                                                            {errorMessages.localTaxCGST && <label className="errorMessage">{errorMessages.localTaxCGST}</label>}
                                                        </span> */}
                                                    </label>
                                                </div>

                                            </div>
                                            <div className="input-box-Im ">
                                                <div className="input-box-m2">
                                                    <input className="modify_im_input mode_category"
                                                        onChange={handleInputChange}
                                                        ref={input4Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input5Ref)}
                                                        type="text" required
                                                        name="localTaxSGST"
                                                        value={inputValues.localTaxSGST} />
                                                    <label className="modify_label_text3">Local Tax 2(SGST)
                                                        {/* <span className="StarClr">*
                                                            {errorMessages.localTaxSGST && <label className="errorMessage">{errorMessages.localTaxSGST}</label>}
                                                        </span> */}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="input-box-Im ">
                                                <div className="input-box-m2">
                                                    <input className="modify_im_input mode_category"
                                                        onChange={handleInputChange}
                                                        ref={input5Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input6Ref)}
                                                        type="text" required
                                                        name="centralTaxIGST"
                                                        value={inputValues.centralTaxIGST} />
                                                    <label className="modify_label_text3">Central Tax(IGST)
                                                        {/* <span className="StarClr">*
                                                            {errorMessages.centralTaxIGST && <label className="errorMessage">{errorMessages.centralTaxIGST}</label>}
                                                        </span> */}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="input-box-Im ">
                                                <div className="input-box-m2">
                                                    <input className="modify_im_input mode_category"
                                                        onChange={handleInputChange}
                                                        ref={input6Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input7Ref)}
                                                        type="text" required
                                                        name="cessTax"
                                                        value={inputValues.cessTax} />
                                                    <label className="modify_label_text3">CESS Tax
                                                        {/* <span className="StarClr">*
                                                            {errorMessages.cessTax && <label className="errorMessage">{errorMessages.cessTax}</label>}
                                                        </span> */}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="input-box-Im ">
                                                <div className="input-box-m2">
                                                    <select className="modify_im_input mode_category"
                                                        onChange={handleInputChange}
                                                        ref={input7Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input8Ref)}
                                                        type="text" required
                                                        name="taxOnMRP"
                                                        value={inputValues.taxOnMRP}>
                                                        <option value="false">No</option>
                                                        <option value="true">Yes</option>
                                                    </select>
                                                    <label className="modify_lbl_text3">Tax on MRP</label>
                                                </div>
                                            </div>

                                            <div className="input-box-Im ">
                                                <div className="input-box-m2">
                                                    <textarea className="modify_im_textarea mode_category2"
                                                        onChange={handleInputChange}
                                                        ref={input8Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input1Ref)}
                                                        type="text" required
                                                        name="remarks"
                                                        value={inputValues.remarks} />
                                                    <label className="modify_p_text-fix">Remarks</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="buttom-box">
                        <div className="button-btn-1">
                            <button className="btn-1">Save</button>
                        </div>
                        <div className="button-btn-2">
                            <button className="btn-2">Reset</button>
                        </div>
                        <div className="button-btn-3">
                            <button className="btn-3" ><i className="fa-solid fa-gear "></i></button>
                        </div>
                    </div>
                </div>
            </div>
            {alertBox && (
                <AlertBox
                    HeadingTop="Saved"
                    Heading="Your Tax Category is Saved"
                    onYesClick={OpenAlertBox}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBox}
                />
            )}
            {alertBoxEdit && (
                <AlertBox
                    HeadingTop="Updated"
                    Heading="Your Tax Category is Updated"
                    onYesClick={OpenAlertBoxEdit}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBoxEdit}
                />
            )}
            {loading && <Loader />}
        </div>
    );
}


export default TaxCategory;
