// src/hooks/useIntersectionObserver.js

import { useEffect, useRef } from 'react';

const useIntersectionObserver = (callback, options) => {
    const elementRef = useRef(null);
    const observerRef = useRef(null);

    useEffect(() => {
        if (observerRef.current) observerRef.current.disconnect();

        observerRef.current = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    callback(entry.target);
                }
            });
        }, options);

        if (elementRef.current) observerRef.current.observe(elementRef.current);

        return () => {
            if (observerRef.current) observerRef.current.disconnect();
        };
    }, [callback, options]);

    return elementRef;
};

export default useIntersectionObserver;
