import { useState } from 'react';
import axios from '../axiosConfig';

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;


var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const Account = {

    insertAccount: async (data, optionalData) => {
        console.log(data, optionalData);
        let optionalDataValues = [];
        for (let key in optionalData) {
            optionalDataValues.push({
                optionalFieldName: optionalData[key].optionalFieldName,
                optionalFieldValue: optionalData[key].optionalFieldValue,
                isDeleted: false,
                isActive: Boolean(true)
            });
        }
        try {
            //const apiUrl = `${express_server_url}/api/Account/Account`;
            const apiUrl = `${WebApiUrl}/api/Account/Account`;

            const response = await axios.post(apiUrl, {
                namePrefix: data.namePrefix === null ? "" : data.namePrefix,
                name: data.name === null ? "" : data.name,
                printName: data.printName === null ? "" : data.printName,
                alias: data.alias === null ? "" : data.alias,
                accountType: Number(data.accountType) || 0,
                parentGrp: Number(data.parentGrp) || 0,
                aState: Number(data.aState) || 0,
                aStation: Number(data.aStation) || 0,
                aDefaultSalePerson: Number(data.aDefaultSalePerson) || 0,
                adob: data.adob === null ? "" : data.adob, // "2024-01-31",
                aAge: Number(data.aAge) || 0,
                adom: data.adom === null ? "" : data.adom,  //"2024-01-31",
                aGender: data.aGender === null ? "" : data.aGender,
                aDepartment: Number(data.aDepartment) || 0,
                aDesignation: Number(data.aDesignation) || 0,
                aDrugLicenseNo: data.aDrugLicenseNo === null ? "" : data.aDrugLicenseNo,
                aOpeningBal: Number(data.aOpeningBal) || 0,
                aOpeningCrDr: data.aOpeningCrDr === null ? "" : data.aOpeningCrDr,               //number but pass value as string;
                aContactperson: data.aContactperson === null ? "" : data.aContactperson,
                contactpersonMbl: data.contactpersonMbl === null ? "" : data.contactpersonMbl,
                aCreditlimit: Number(data.aCreditlimit) || 0,
                aCreditperiod: Number(data.aCreditperiod) || 0,
                aAccountCategory: Number(data.aAccountCategory) || 0,
                aEnableLoyalty: Boolean(data.aEnableLoyalty),
                aLoyaltyCardNo: data.aLoyaltyCardNo === null ? "" : data.aLoyaltyCardNo,
                referralBy: Number(data.referralBy) || 0,
                assignUser: Number(data.assignUser) || 0,
                aAddress: data.aAddress === null ? "" : data.aAddress,
                aEnableEmail: data.aEnableEmail === null ? "" : data.aEnableEmail,
                aEmail: data.aEmail === null ? "" : data.aEmail,
                aMobileNo: data.aMobileNo === null ? "" : data.aMobileNo,
                aPincode: data.aPincode === null ? "" : data.aPincode,
                aLocation: data.aLocation === null ? "" : data.aLocation,
                aDistance: data.aDistance === null ? "" : data.aDistance,
                accountDealerType: data.accountDealerType === null ? "" : data.accountDealerType,
                agStINNo: data.agStINNo === null ? "" : data.agStINNo,
                aitPan: data.aitPan === null ? "" : data.aitPan,
                alstNo: data.alstNo === null ? "" : data.alstNo,
                acstNo: data.acstNo === null ? "" : data.acstNo,
                aAadharNo: data.aAadharNo === null ? "" : data.aAadharNo,
                doNotDisturb: data.doNotDisturb === null ? "" : data.doNotDisturb,
                aDefaultTranspoter: Number(data.aDefaultTranspoter) || 0,
                openingCrDr: data.openingCrDr === null ? "" : data.openingCrDr,
                system_defined: Boolean(data.system_defined),
                createdBy: data.createdBy === null ? "" : data.createdBy,
                createdOn: data.createdOn === null ? "" : data.createdOn, //data.createdOn,
                isActive: Boolean(data.isActive),
                password: data.password,
                accOptFieldValues: optionalDataValues === null ? "" : optionalDataValues
            }, {
                withCredentials: true,
                credentials: 'include',
            });
            console.log("Response:", response.data);
            return response.data;

        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },
    // ************************* Get All ***************************
    getAccount: async () => {
        try {
            //const apiUrl = `${express_server_url}/api/Account/GetAccounts`;

            const apiUrl = `${WebApiUrl}/api/Account/GetAccounts`;

            const response = await axios.post(apiUrl, {}, {
                withCredentials: true,
                credentials: 'include',
            });
            console.log('Response:', response.data.result);
            return response.data.result;

        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    // ************************* Get All ***************************
    getAccountFilter: async () => {
        try {
            const apiUrl = `${WebApiUrl}/api/Account/GetAccounts`;

            const response = await axios.post(
                apiUrl,
                {
                    filter: [],
                    order: [
                        {
                            propertyName: "d1Id",
                            ascending: false,
                        },
                    ],
                    pageNumber: 0,
                    pageSize: 500000,
                },
                {
                    withCredentials: true,
                    credentials: "include",
                }
            );
            console.log("Response:", response.data.result);
            return response.data.result;
        } catch (error) {
            console.error("Error:", error.message);
        }
    },

    // ************************* Get Filtered Accounts  ***************************
    getAccountWithFilter: async (filteredData) => {
        const filtData = filteredData.map((data) => ({
            fieldName: data.fieldName,
            operatorName: data.operatorName,
            compareValue: data.compareValue,
        }));
        try {
            const apiUrl = `${WebApiUrl}/api/Account/GetAccounts`;

            const response = await axios.post(
                apiUrl,
                {
                    filter: filtData,
                    order: [
                        {
                            propertyName: "d1Id",
                            ascending: false,
                        },
                    ],
                    pageNumber: 0,
                    pageSize: 500000,
                },
                {
                    withCredentials: true,
                    credentials: "include",
                }
            );
            console.log("Response:", response.data.result);
            return response.data.result;
        } catch (error) {
            console.error("Error:", error.message);
        }
    },

    // ************************* Delete ****************************
    deleteAccount: async (d1Id, d2Id) => {
        try {
            //const apiUrl = `${express_server_url}/api/Account?accountId=${accountId}`;
            const apiUrl = `${WebApiUrl}/api/Account?d1Id=${d1Id}&d2Id=${d2Id}`;
            const response = await axios.delete(apiUrl, {
                withCredentials: true,
                credentials: 'include',
            });
            console.log('Delete Response:', response.data.result);
            return true;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    // ************************* Get By Id ****************************
    getAccountById: async (accountId) => {
        try {
            //const apiUrl = `${express_server_url}/api/Account?accountId=${accountId}`;
            const apiUrl = `${WebApiUrl}/api/Account?accountId=${accountId}`;

            const response = await axios.get(apiUrl, {}, {
                withCredentials: true,
                credentials: 'include',
            });
            // debugger;
            console.log('Response:', response.data.result);
            return response.data.result;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },
    // *************************** Update ***************************
    updateAccount: async (data, optionalData) => {
        let optionalDataValues = [];
        for (let key in optionalData) {
            optionalDataValues.push({
                optionalFieldName: optionalData[key].optionalFieldName,
                optionalFieldValue: optionalData[key].optionalFieldValue,
                isDeleted: false,
                isActive: Boolean(true)
            });
        }
        try {
            //const apiUrl = `${express_server_url}/api/Account?accountId=${data.id}`;
            const apiUrl = `${WebApiUrl}/api/Account?accountId=${data.d1Id}`;
            console.log(apiUrl);
            const response = await axios.put(apiUrl, {
                id: data.d1Id,
                namePrefix: data.namePrefix,
                name: data.name,
                printName: data.printName,
                alias: data.alias,
                accountType: Number(data.accountType),
                parentGrp: Number(data.parentGrp),
                aState: Number(data.aState),
                aStation: Number(data.aStation),
                aDefaultSalePerson: Number(data.aDefaultSalePerson),
                adob: data.adob, // "2024-01-31",
                aAge: Number(data.aAge),
                adom: data.adom,  //"2024-01-31",
                aGender: data.aGender,
                aDepartment: Number(data.aDepartment),
                aDesignation: Number(data.aDesignation),
                aDrugLicenseNo: data.aDrugLicenseNo,
                aOpeningBal: Number(data.aOpeningBal),
                aOpeningCrDr: data.aOpeningCrDr,
                aContactperson: data.aContactperson,
                contactpersonMbl: data.contactpersonMbl,
                aCreditlimit: Number(data.aCreditlimit),
                aCreditperiod: Number(data.aCreditperiod),
                aAccountCategory: Number(data.aAccountCategory),
                aEnableLoyalty: Boolean(data.aEnableLoyalty),
                aLoyaltyCardNo: data.aLoyaltyCardNo,
                referralBy: Number(data.referralBy),
                assignUser: Number(data.assignUser),
                aAddress: data.aAddress,
                aEnableEmail: data.aEnableEmail,
                aEmail: data.aEmail,
                aMobileNo: data.aMobileNo,
                aPincode: data.aPincode,
                aLocation: data.aLocation,
                aDistance: data.aDistance,
                accountDealerType: data.accountDealerType,
                agStINNo: data.agStINNo,
                aitPan: data.aitPan,
                alstNo: data.alstNo,
                acstNo: data.acstNo,
                aAadharNo: data.aAadharNo,
                doNotDisturb: data.doNotDisturb,
                aDefaultTranspoter: Number(data.aDefaultTranspoter),
                openingCrDr: data.openingCrDr,
                system_defined: Boolean(data.system_defined),
                createdBy: (data.createdBy).toString(),
                createdOn: data.createdOn,
                isActive: Boolean(data.isActive),
                password: data.password,
                accOptFieldValues: optionalDataValues
            }, {
                withCredentials: true,
                credentials: 'include',
            });
            console.log("Response:", response.data);
            return response.data
        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },

    // ************************* Get Account Types ***************************
    getAccountTypes: async () => {
        try {
            //const apiUrl = `${express_server_url}/api/Account/GetAccountTypes`;

            const apiUrl = `${WebApiUrl}/api/Account/GetAccountTypes`;
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                credentials: 'include',
            });
            console.log('Response:', response.data.result);
            return response.data.result;

        } catch (error) {
            console.error('Error:', error.message);
        }
    },
    // ************************* Create Account Master Setting ***************************    
    insertAccountMasterSetting: async (settingData, optionalData) => {
        let data = [];
        for (var i = 0; i < optionalData.length; i++) {
            data.push({
                optionalFieldName: optionalData[i].optionalFieldName,
                priority: optionalData[i].priority,
                isDeleted: false,
                isActive: true
            });
        }
        try {
            //const apiUrl = `${express_server_url}/api/Account/CreateAccountMasterSetting`;

            const apiUrl = `${WebApiUrl}/api/Account/CreateAccountMasterSetting`;
            console.log(apiUrl);
            console.log(settingData);
            const response = await axios.post(apiUrl, {
                accountDetails: Boolean(settingData.accountDetails),
                aliasNPrintDetails: Boolean(settingData.aliasNPrintDetails),
                documentDetails: Boolean(settingData.documentDetails),
                transportDetails: Boolean(settingData.transportDetails),
                defaultSalesPerson: Boolean(settingData.defaultSalesPerson),
                optionalField: Boolean(settingData.optionalField),
                isActive: true,
                accountOptionalFields: data //previous data + latest updated data;
            }, {
                withCredentials: true,
                credentials: 'include',
            });
            console.log("Response:", response.data);
            return true;

        } catch (error) {
            console.error("Error:", error.message);
            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },
    // ************************* Get Account Master Setting ***************************
    getAccountMasterSetting: async () => {
        try {
            //const apiUrl = `${express_server_url}/api/Account/GetAccountMasterSetting`;

            const apiUrl = `${WebApiUrl}/api/Account/GetAccountMasterSetting`;
            const response = await axios.post(apiUrl, {}, {
                withCredentials: true,
                credentials: 'include',
            });
            console.log('Response:', response.data.result);
            return response.data.result;

        } catch (error) {
            console.error('Error:', error.message);
        }
    },
    // ************************* Get Account Optional Field ***************************
    getAccountOptionalField: async () => {
        try {
            //const apiUrl = `${express_server_url}/api/Account/GetAccountOptionalField`;

            const apiUrl = `${WebApiUrl}/api/Account/GetAccountOptionalField`;
            const response = await axios.post(apiUrl, {}, {
                withCredentials: true,
                credentials: 'include',
            });
            console.log('Response:', response.data.result);
            return response.data.result;

        } catch (error) {
            console.error('Error:', error.message);
        }
    },
    // *************************** Patch Update Account Partially ***************************
    updateAccountPartially: async (data, d1Id, d2Id) => {

        try {
            //const apiUrl = `${express_server_url}/api/Account/UpdateAccountPartially?d1Id=${d1Id}&d2Id=${d2Id}`;

            const apiUrl = `${WebApiUrl}/api/Account/UpdateAccountPartially?d1Id=${d1Id}&d2Id=${d2Id}`;
            console.log(apiUrl);
            const response = await axios.patch(apiUrl, data, {
                withCredentials: true,
                credentials: 'include',
            });
            console.log("Response:", response.data);
            return response.data.isSuccess
        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },
};

export default Account;



