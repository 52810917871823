import React, { useEffect, useState, useContext, useRef } from "react";
import '../../css/Stylesheet-T.css';
import Loader from "../Props/Loader";
import MyContext from "../MyContext";
import AlertBox from "../Props/AlertBox";
import AlertBoxError from "../Props/AlertBoxError";
import MainButton from './../Props/MainButton';
import LensCreationTable from "./LensCreationTable";
import ItemMasterAPI from "../../API/APIServices/ItemMaster";
import ItemGroupAPI from "../../API/APIServices/ItemGroup";
import LensPowerMasterApi from "../../API/APIServices/LensItemCreation";
import {useNavigate } from 'react-router-dom';

const LensCreation = () => {

    const { OpenAlertBox, alertBox, OpenAlertBoxEdit, alertBoxEdit, alertBoxErrorUpdate, alertBoxErrorInsert, OpenAlertBoxErrorInsert, OpenAlertBoxErrorUpdate } =
        useContext(MyContext);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)

    const [isHovered, setIsHovered] = useState(false);
    const [isHovered1, setIsHovered1] = useState(false);
    const [isHovered2, setIsHovered2] = useState(false);
    const [isHovered3, setIsHovered3] = useState(false);
    const [isHovered4, setIsHovered4] = useState(false);
    const [isHovered5, setIsHovered5] = useState(false);
    const [isHovered6, setIsHovered6] = useState(false);
    const [isHovered7, setIsHovered7] = useState(false);
    const [isHovered8, setIsHovered8] = useState(false);
    const [isHovered9, setIsHovered9] = useState(false);
    const [isHovered10, setIsHovered10] = useState(false);
    const [isHovered11, setIsHovered11] = useState(false);
    const [isHovered12, setIsHovered12] = useState(false);
    const [isHovered13, setIsHovered13] = useState(false);
    const [isHovered14, setIsHovered14] = useState(false);
    const [combinedV, setcombined] = useState([]);

    const [fetchLensPowerData, setfetchLensPowerData] = useState([]);

    const [sphValue, setSphValues] = useState([]);
    const [cylValue, setCylValues] = useState([]);
    const [axisValue, setAxisValues] = useState([]);
    const [addValue, setAddValues] = useState([]);

    const [inputValues, setInputValues] = useState({
        ParentGrp: 0,
        ItemName: "",
        ItemId: 0,
        SPH_Min: 1,
        SPH_Max: 2,
        SPH_Step: 0.25,
        CYL_Min: 1,
        CYL_Max: 2,
        CYL_Step: 0.25,
        Axis: 0,
        Add_Min: 1,
        Add_Max: 2,
        Add_Step: 0.25,
        Eye: 'RL',
    })

    const ShowButtonClick = async () => {

        try {
            const val = await LensPowerMasterApi.getLensItemCreationById(inputValues);
            console.log(val)
            if (val !== null) {
                CreateLensPowerClick();
                setfetchLensPowerData(val);
            }

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }


    const [ItemGroupList, setItemGroupList] = useState([]);
    const [ItemMasterList, setItemMasterList] = useState([]);

    useEffect(() => {
        const fetchItemGroupMaster = async () => {
            try {
                const val = await ItemGroupAPI.GetItemGroup();
                console.log(val);
                setItemGroupList(val);
            } catch (error) {
                console.log(error);
            }
        };

        fetchItemGroupMaster();
    }, []);


    useEffect(() => {
        const fetchItemMaster = async () => {
            try {
                const val = await LensPowerMasterApi.GetLensItemMaster();
                console.log(val)
                setItemMasterList(val);

            } catch (error) {
                console.log(error);
            }
        };

        fetchItemMaster();
    }, []);


    const handleInputChange = (event) => {
        const { name, value } = event.target;
        console.log(`Changing ${name} to ${value}`);

        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));

        //setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: "" }));
    };


    const CreateLensPowerClick = () => {

        if (inputValues != null) {
            const sphMin = parseFloat(inputValues.SPH_Min);
            const sphMax = parseFloat(inputValues.SPH_Max);
            const sphStep = parseFloat(inputValues.SPH_Step);
            var sph = [];
            for (let i = sphMin; i <= sphMax; i += sphStep) {
                sph = [...sph, i.toFixed(2)];
            }
            setSphValues(sph);

            const cylMin = parseFloat(inputValues.CYL_Min);
            const cylMax = parseFloat(inputValues.CYL_Max);
            const cylStep = parseFloat(inputValues.CYL_Step);
            var cyl = [];
            for (let j = cylMin; j <= cylMax; j += cylStep) {
                cyl = [...cyl, j.toFixed(2)];
            }
            setCylValues(cyl);

            var axis = [];
            const valuesArray = inputValues.Axis.toString().split(',').map((value) => value.trim());
            for (let x = 0; x < valuesArray.length; x++) {
                const axisStep = parseFloat(valuesArray[x]);
                //for (let k = 10; k <= axisStep; k += 10) { }
                axis = [...axis, axisStep.toFixed(2)];
                setAxisValues(axis);
            }

            const addMin = parseFloat(inputValues.Add_Min);
            const addMax = parseFloat(inputValues.Add_Max);
            const addStep = parseFloat(inputValues.Add_Step);
            var add = [];
            for (let l = addMin; l <= addMax; l += addStep) {
                add = [...add, l.toFixed(2)];
            }
            setAddValues(add);

            document.getElementById('table').style.display = "block";

            let combined = [];
            axisValue.forEach((axis) => {
                sphValue.forEach((sph) => {
                    cylValue.forEach((cyl) => {
                        combined.push({ sph, cyl, axis });
                    });
                });
            });
            setcombined(combined);
        }

        /*try{
            let combinedLensPower = [];
            addValue.forEach((add) => {
                axisValue.forEach((axis) => {
                    sphValue.forEach((sph) => {
                        cylValue.forEach((cyl) => {
                            combinedLensPower.push({ sph, cyl, axis, add });
                        });
                    });
                });
            });
            console.log(combinedLensPower);
        }catch(e){}*/
    };


    const SaveLensItemData = async () => {


        try {

            let allValid = true;
            /*fieldNames.forEach((fieldName) => {
            const isValid = validateInput(fieldName);
            if (!isValid) {
                allValid = false;
            }
            });*/
            if (allValid) {
                setLoading(true);
                if (false) {

                    const isSuccess = await LensPowerMasterApi.updateLensItemCreation(
                        inputValues,
                    );
                    if (isSuccess) {
                        OpenAlertBoxEdit();
                        setLoading(false);
                    } else {
                        setLoading(false);
                        OpenAlertBoxErrorUpdate()
                    }
                } else {

                    const isSuccess = await LensPowerMasterApi.insertLensItemCreation(
                        inputValues,
                    );
                    if (isSuccess) {
                        setLoading(false);
                        OpenAlertBox();
                    } else {
                        setLoading(false);
                        OpenAlertBoxErrorInsert()
                    }
                }
            }
        } catch (e) { setLoading(false); }
    }


    const [filteredItems, setFilteredItems] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleInputChange1 = (event) => {

        const { name, value } = event.target;
        console.log(`Changing ${name} to ${value}`);

        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));

        setIsDropdownOpen(true);

        // Filter items based on the input value
        const filteredItems = ItemMasterList.filter(item =>
            item.name.toLowerCase().includes(value.toLowerCase())
        );

        setFilteredItems(filteredItems);
    };


    const handleSelection = (a, b) => {
        debugger;
        setInputValues(prevValues => ({
            ...prevValues,
            ItemId: a,
            ItemName: b,
        }));

        setIsDropdownOpen(false);
    };

    const [selectedItemIndex, setSelectedItemIndex] = useState(-1); // Track selected item index
    const [focusedIndex, setFocusedIndex] = useState(-1);

    const handleKeyDown = (event) => {
        if (event.key === "ArrowDown") {
            event.preventDefault();
            setFocusedIndex((prevIndex) => Math.min(prevIndex + 1, filteredItems.length - 1));
        } else if (event.key === "ArrowUp") {
            event.preventDefault();
            setFocusedIndex((prevIndex) => Math.max(prevIndex - 1, -1));
        } else if (event.key === "Enter" && focusedIndex !== -1) {
            event.preventDefault();
            const selectedItem = filteredItems[focusedIndex];
            handleSelection(selectedItem.d2Id, selectedItem.name);
            setFocusedIndex(-1);
        }
    };


    return (
        <div style={{ backgroundColor: "white" }}>
            {/* *******************Top Navbar/Buttons****************** */}
            <div style={{ display: "flex", justifyContent: "space-between", backgroundColor: "#212e4f" }}>
                <div className="panel-head-title">
                    <h2 className="panel-title" style={{ color: "white", fontWeight: "600" }}>Lens Group Creation</h2>
                </div>
                <MainButton
                    SaveButton={SaveLensItemData}
                />
            </div>
            {/* ******************* Body ****************** */}
            <div className="mt-1" style={{ padding: "15px", position: "relative" }}>
                <div style={{ display: "flex", justifyContent: "start" }}>
                    <label>Group Name &nbsp;</label>
                    <select
                        className=""
                        onChange={handleInputChange}
                        value={inputValues.ParentGrp}
                        name="ParentGrp"
                    >
                        <option>-Select-</option>
                        {ItemGroupList !== undefined &&
                            ItemGroupList !== null
                            && ItemGroupList.length > 0 ? (
                            ItemGroupList.map((group, index) => (

                                <option key={index} value={group.d2_Id}>
                                    {group.name}
                                </option>
                            ))
                        ) : (
                            <option value="">
                                No groups available
                            </option>
                        )}
                    </select>
                    <label> &nbsp; Product Name &nbsp;</label>

                    <input
                        onKeyDown={handleKeyDown}
                        type="text"
                        style={{ height: "30px" }}
                        value={inputValues.ItemName}
                        name="ItemName"
                        onChange={handleInputChange1}
                        placeholder="Type to search..."
                    />
                    <ul style={{
                        display: isDropdownOpen ? "block" : "none", position: "absolute", height: "250px", width: "400px",
                        top: "calc(25% + 5px)", left: "calc(35%)", zIndex: 999, backgroundColor: "white",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", overflow: "auto"
                    }}>
                        {filteredItems.map((item, index) => (
                            //console.log(item),
                            <li key={index} onClick={() => handleSelection(item.d2Id, item.name)} style={{ padding: "5px", cursor: "pointer", borderBottom: "1px solid #ccc" }}>
                                {item.name}
                            </li>
                        ))}
                    </ul>

                </div>

                <div className="ToolTipTOp" style={{}}>
                    <div className="input-box-SI-2 ">
                        <div className="input-box-m6">
                            <div className="row mt-2">
                                <label className="col-sm-3 w-8" ></label>
                                <label className="col-sm-3 w-6" >SPH Min</label>
                                <label className="col-sm-3 w-6" >SPH Max</label>
                                <label className="col-sm-3 w-7" >Step</label>
                                <label className="col-sm-3 w-6" >CYL Min</label>
                                <label className="col-sm-3 w-5" >CYL Max</label>
                                <label className="col-sm-3 w-7" >Step</label>
                                <label className="col-sm-3 w-7" >Axis</label>
                                <label className="col-sm-3 w-5" >Add Min</label>
                                <label className="col-sm-3 w-6" >Add Max</label>
                                <label className="col-sm-3 w-6" >Step</label>
                                <label className="col-sm-3 w-5" >Eye(RL/R/L)</label>
                            </div>
                        </div>
                    </div>
                    <div className="input-box-SI-2 mt-1">
                        <div className="input-box-m6">
                            <div className=" ToolBody">
                                <label className="col-sm-3 w-8">Power Range</label>
                                <div class="tooltip6">
                                    <input id="sph_min" className=" table-input box-size1 toolTextWdth"
                                        value={inputValues.SPH_Min}
                                        onChange={handleInputChange}
                                        name="SPH_Min"
                                        onMouseEnter={() => setIsHovered4(true)}
                                        onMouseLeave={() => setIsHovered4(false)} type="number" required />
                                    <div class="tooltiptext">SPH Range -40.00 to +40.00</div>
                                </div>

                                <div class="tooltip6">
                                    <input id="sph_max" className=" table-input box-size1 toolTextWdth"
                                        value={inputValues.SPH_Max}
                                        onChange={handleInputChange}
                                        name="SPH_Max"
                                        onMouseEnter={() => setIsHovered5(true)}
                                        onMouseLeave={() => setIsHovered5(false)} type="number" required />
                                    <div class="tooltiptext">SPH Range -40.00 to +40.00</div>
                                </div>

                                <div class="tooltip6">
                                    <input id="sph_step" className=" table-input box-size1 toolTextWdth"
                                        value={inputValues.SPH_Step}
                                        onChange={handleInputChange}
                                        name="SPH_Step"
                                        onMouseEnter={() => setIsHovered6(true)}
                                        onMouseLeave={() => setIsHovered6(false)} type="number" required />
                                    <div class="tooltiptext">Step 0.25</div>
                                </div>

                                <div class="tooltip6">
                                    <input id="cyl_min" className=" table-input box-size1 toolTextWdth"
                                        value={inputValues.CYL_Min}
                                        onChange={handleInputChange}
                                        name="CYL_Min"
                                        onMouseEnter={() => setIsHovered7(true)}
                                        onMouseLeave={() => setIsHovered7(false)} type="number" required />
                                    <div class="tooltiptext">CYL Range -10.00 to +10.00</div>
                                </div>

                                <div class="tooltip6">
                                    <input id="cyl_max" className=" table-input box-size1 toolTextWdth"
                                        value={inputValues.CYL_Max}
                                        onChange={handleInputChange}
                                        name="CYL_Max"
                                        onMouseEnter={() => setIsHovered8(true)}
                                        onMouseLeave={() => setIsHovered8(false)} type="number" required />
                                    <div class="tooltiptext">CYL Range -10.00 to +10.00</div>
                                </div>

                                <div class="tooltip6">
                                    <input id="cyl_step" className=" table-input box-size1 toolTextWdth"
                                        value={inputValues.CYL_Step}
                                        onChange={handleInputChange}
                                        name="CYL_Step"
                                        onMouseEnter={() => setIsHovered9(true)}
                                        onMouseLeave={() => setIsHovered9(false)} type="number" required />
                                    <div class="tooltiptext">Step 0.25</div>
                                </div>

                                <div class="tooltip6">
                                    <input id="axis" className=" table-input box-size1 toolTextWdth"
                                        value={inputValues.Axis}
                                        onChange={handleInputChange}
                                        name="Axis"
                                        onMouseEnter={() => setIsHovered10(true)}
                                        onMouseLeave={() => setIsHovered10(false)} type="text" required />
                                    <div class="tooltiptext">Axis separated by comma (Ex- 10,20,...etc)</div>
                                </div>

                                <div class="tooltip6">
                                    <input id="add_min" className=" table-input box-size1 toolTextWdth"
                                        value={inputValues.Add_Min}
                                        onChange={handleInputChange}
                                        name="Add_Min"
                                        onMouseEnter={() => setIsHovered11(true)}
                                        onMouseLeave={() => setIsHovered11(false)} type="number" required />
                                    <div class="tooltiptext">Add Range 0.50 to +10.00</div>
                                </div>

                                <div class="tooltip6">
                                    <input id="add_max" className=" table-input box-size1 toolTextWdth"
                                        value={inputValues.Add_Max}
                                        onChange={handleInputChange}
                                        name="Add_Max"
                                        onMouseEnter={() => setIsHovered12(true)}
                                        onMouseLeave={() => setIsHovered12(false)} type="number" required />
                                    <div class="tooltiptext">Add Range 0.50 to +10.00</div>
                                </div>

                                <div class="tooltip6">
                                    <input id="add_step" className=" table-input box-size1 toolTextWdth"
                                        value={inputValues.Add_Step}
                                        onChange={handleInputChange}
                                        name="Add_Step"
                                        onMouseEnter={() => setIsHovered13(true)}
                                        onMouseLeave={() => setIsHovered13(false)} type="number" required />
                                    <div class="tooltiptext">Step 0.25</div>
                                </div>

                                <div class="tooltip6">
                                    <select className="modify_im_input mode_category table-input"
                                        style={{ width: "90px" }}
                                        value={inputValues.Eye}
                                        onChange={handleInputChange}
                                        name="Eye"
                                        onMouseEnter={() => setIsHovered14(true)}
                                        onMouseLeave={() => setIsHovered14(false)} type="text" required>
                                        <option value="RL">RL</option>
                                        <option value="L">L</option>
                                        <option value="R">R</option>
                                    </select>

                                    <div class="tooltiptext">RL/R/L</div>
                                </div>
                                {isHovered14 && <div className="hover-text2" style={{ marginLeft: "61%" }}></div>}


                                <button className="col-sm-3 w-5 ml-10 btn-red table-input box-size1" onMouseEnter={() => setIsHovered3(true)}
                                    onMouseLeave={() => setIsHovered3(false)} onClick={ShowButtonClick} type="number" required style={{ padding: "0px" }}>Show Existing</button>
                                {isHovered3 && <div className="hover-text2" style={{ marginLeft: "61%" }}>Show Lens power details which is save in database</div>}
                            </div>
                        </div>
                    </div>
                </div>
                {/********************* Bottom Buttons ******************/}
                <div style={{ paddingTop: "10px" }}>
                    <div className="space-between">
                        <div className="space-evenly">
                            <input type="checkbox" />
                            <label className="mt-1">&nbsp; Add Barcode with Lens Power</label>
                        </div>
                        <div className="space-evenly">
                            <button className="btn-blue ml-10" onClick={CreateLensPowerClick} style={{ backgroundColor: "darkgreen" }}>Save Lens Power</button>
                            <button className="btn-blue ml-10" style={{ backgroundColor: "red" }}>Delete</button>
                        </div>
                    </div>
                </div>
                <div className="mt-4" id="LensTable" style={{ display: "block" }}>
                    <LensCreationTable
                        combinedV={combinedV}
                        sphValue={sphValue}
                        cylValue={cylValue}
                        axisValue={axisValue}
                        addValue={addValue}
                        fetchLensPowerData={fetchLensPowerData}
                    />
                </div>
            </div>

            {alertBox && (
                <AlertBox
                    HeadingTop="Saved"
                    Heading="Your Item Master is Saved"
                    onYesClick={OpenAlertBox}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBox}
                />
            )}

            {alertBoxEdit && (
                <AlertBox
                    HeadingTop="Updated"
                    Heading="Your Item Master is Updated"
                    onYesClick={OpenAlertBoxEdit}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBoxEdit}
                />
            )}

            {alertBoxErrorInsert && (
                <AlertBoxError
                    HeadingTop="ERROR"
                    Heading="Error While inserting Data"
                    onYesClick={OpenAlertBoxErrorInsert}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBoxErrorInsert}
                />
            )}

            {alertBoxErrorUpdate && (
                <AlertBoxError
                    HeadingTop="ERROR"
                    Heading="Error While Updating Data"
                    onYesClick={OpenAlertBoxErrorUpdate}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBoxErrorUpdate}
                />
            )}
            {loading && <Loader />}
        </div>
    );
};

export default LensCreation;


{/*<select
    className=""
    onChange={handleInputChange}
    value={inputValues.ItemId}
    name="ItemId"
>
    <option>-Select-</option>
    {ItemMasterList !== undefined &&
        ItemMasterList !== null
        && ItemGroupList.length > 0 ? (
        ItemMasterList.map((group, index) => (

            <option key={index} value={group.d2Id}>
                {group.name}
            </option>
        ))
    ) : (
        <option value="">
            No Item available
        </option>
    )}
</select>
*/}