
import DateTime from "../../Inventory/Components/DateTime";
import React, { useState } from "react";
import PageTabs from "../../Inventory/Components/PageTabs";
import moment from "moment";

function ModalKOTItemDetail(props) {
    /***********title***************/
    // useState(() => {
    //     document.title = 'KOT Item Detail';
    // }, []);

    //***************{Array Data}***************//
    // const ItemDetail = Array.from({ length: 5 }, (_, index) => ({
    //     id: index,
    // }));

    const { ItemDetail, closeItemDetailModal } = props

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    function convertTo12HourFormat(twentyFourHourTime) {
        const [hours, minutes] = twentyFourHourTime.split(':').map(Number);

        let period = 'AM';
        let twelveHourHours = hours;

        if (hours >= 12) {
            period = 'PM';
            if (hours > 12) {
                twelveHourHours -= 12;
            }
        }
        if (twelveHourHours === 0) {
            twelveHourHours = 12;
        }
        if (minutes != undefined && minutes != null) {
            const formattedMinutes = minutes === 0 ? '00' : minutes.toString().padStart(2, '0');
            return `${twelveHourHours.toString().padStart(2, '0')}:${formattedMinutes} ${period}`;
        }
        else {
            return `${twelveHourHours.toString().padStart(2, '0')}: 00 ${period}`;
        }
    }

    return (
        <div>
            <PageTabs />
            <div>
                < DateTime />
            </div>
            <div className="content_box">
                <div className="company_control">
                    <div className="col-sm-12">
                        <label className="ans"><span className="ques">Order No :</span>{ItemDetail[0].orderNo}</label><br />
                        <div className="table-resposive" >
                            <table id="tblTransactionList" className="table_ord_mod table-bordered1" frame="box">
                                <thead textAlign="center" className="thead_scroll_orderlist table-tbody th-bg-color" >
                                    <tr className="">
                                        <th className="td_col6"> <div className="table_head_OrderList">
                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Qty</div>
                                        </div> </th>
                                        <th className="td_col6">
                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Item</div>
                                        </th>
                                        <th className="td_col6">
                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Qty</div>
                                        </th>
                                        <th className="td_col6"> <div className="table_head_OrderList">
                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Price</div>
                                        </div> </th>
                                        <th className="td_col6"> <div className="table_head_OrderList">
                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Total Amt</div>
                                        </div> </th>
                                    </tr>
                                </thead>
                                <tbody className="tbody_body_m1 overflow-pymt">
                                    {ItemDetail.map((item, index) => (
                                        <tr className="table_tbody_Order_List">
                                            <td className="td_col6 td_sno-btn" style={{ textAlign: "center" }}>{item.qty}</td>
                                            <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>
                                                <div>
                                                    <label>{item.d2ItemName}</label><br />
                                                    {item.itemConfirmedByName != null && item.itemConfirmedByName != undefined && (
                                                        <span>
                                                            <label className="ques">Confirm By : <span style={{ color: "darkgreen" }}>{item.itemConfirmedByName}</span></label>
                                                            <label className="ques">&nbsp;on <span style={{ color: "darkgreen" }}>{moment.utc(item.itemConfirmedOn1).utcOffset("+05:30").format("DD-MM-YYYY, h:mm A")}</span></label>
                                                        </span>
                                                    )}
                                                </div>
                                            </td>
                                            <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>{item.qty}</td>
                                            <td className="td_col6 td_sno-btn" style={{ textAlign: "center" }}>₹&nbsp;{item.rate} </td>
                                            <td className="td_col6 td_sno-btn" style={{ textAlign: "center" }}>₹&nbsp;{item.qty * item.rate} </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div><br />
                        <div className="space-between">
                            <label className="ans"><span className="ques">Order Date :&nbsp;</span>{formatDate(ItemDetail[0].orderDate)}<br />
                                <span className="ques">Order Time :&nbsp;</span>{convertTo12HourFormat(ItemDetail[0].orderTime)}</label>

                            <button className="redBtn" onClick={closeItemDetailModal} style={{ width: "25%" }}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default ModalKOTItemDetail;
