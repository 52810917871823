import React, { useState, useContext, useEffect } from "react";
import "./MasterSetting.css"
import BillNumberingAPI from "../../../API/APIServices/BillNumbering"
import BillSeriesAPI from "../../../API/APIServices/BillSeriesMaster";
import BillLayoutSettingAPI from "../../../API/APIServices/BillLayoutSetting";
import AccountAPI from "../../../API/APIServices/Account";
import PaymentModeAPI from "../../../API/APIServices/BillPaymentMode";
import AlertBox from "../../Props/AlertBox";
import MyContext from "../../MyContext";
import Loader from "../../Props/Loader";
import { useNavigate } from 'react-router-dom';

function BillSeriesSetting(props) {

    const {
        OpenAlertBoxDelete,
        alertBoxDelete,
        setAlertBoxDelete,
    } = useContext(MyContext);

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const { OpenAlertBox, alertBox, OpenAlertBoxEdit, alertBoxEdit } = useContext(MyContext);

    const [errorMessages, setErrorMessages] = useState({
        name: '',
    });

    //  *******************{Bill Series Master}***********************

    //   const showsubcate = () => {};
    const [SubAddSeries, setSubAddSeries] = useState(false);
    const [isInputDisabled1, setisInputDisabled1] = useState(false);
    const [isInputDisabled2, setisInputDisabled2] = useState(false);
    const [isInputDisabled3, setisInputDisabled3] = useState(false);


    const [isSetBtn, setSetBtn] = useState(true);
    const SettlementDetails = (event) => {
        const selectedValue = event.target.value;

        if (selectedValue === 'true') {
            setSetBtn(true);
        }
        else if (selectedValue === 'false') {
            setSetBtn(false);
        }

    }

    const showSubAdd = (id) => {
        console.log(id)
        setSubAddSeries((prevSubAddSeries) => {
            return prevSubAddSeries === id ? null : id;
        });
    };

    const [, setCustomFields] = useState([]);

    // ************************{Bill Series Select }**************************

    const [isBillLayoutVisible, setBillLayoutVisible] = useState(false);
    const [isCopySeriesSetting, setCopySeriesSetting] = useState(false);
    const [isBillNumberingVisible, setBillNumberingVisible] = useState(false);

    //    const[inputValue3,setInputValues3] = useState();
    const AddCustmField_clicked3 = async (field, id, masterType, billSeriesName) => {
        console.log(field, id, masterType, billSeriesName);
        setAddSeries(false);
        if (field === 'BillNumbering2') {
            setBillNumberingVisible(true);
            setBillLayoutVisible(false);
            setCopySeriesSetting(false);
            document.getElementById("inputField").style.display = "none";
            setBillNumberingInputValues((prevValues) => ({
                ...prevValues,
                "billSeriesId": id,
                "billTypeId": masterType,
                "billSeriesName": billSeriesName,
                "numberingCategory": '',
                "duplicateBillNumber": '',
                "prefixType": '',
                "prefix": '',
                "suffix": '',
                "startingNumber": '',
                "isEndingNumber": true,
                "endingNumber": '',
                "warningBeforeNoOfVouchLeft": '',
                "warningMessage": '',
                "isActive": true,
            }));
            setEditModeBillNumber(false);

            var data = await BillNumberingAPI.getBillNumberingbyId(masterType, id);
            console.log(data)
            if (data != null) {
                setBillNumberingInputValues(data);
                setEditModeBillNumber(true);

                if (data.isEndingNumber === true) {
                    setEndingDetails(true);
                }
                else {
                    setEndingDetails(false);
                }

            }

        } else if (field === 'SetBillLayout') {
            setBillNumberingVisible(false);
            setBillLayoutVisible(true);
            setCopySeriesSetting(false);
            document.getElementById("inputField").style.display = "none";
            setInputLayout((prevValues) => ({
                ...prevValues,
                "seriesId": id,
                "billTypeId": masterType,
                "billSeriesName": billSeriesName,
                "maxDiscLmt": '',
                "itmWiseSalePrcList": true,
                "itmWiseDiscrp": true,
                "allowBrokrage": '',
                "paymentType": 1,
                "separateBillDtl": true,
                "eWayBillTrnsDtl": true,
                "printVchrAftrSaving": true,
                "disblMultplOptForPrint": true,
                "sendEmail": true,
                "sendSms": true,
                "enblPymntSettlmnt": true,
                "isActive": true,
                "extraVoucherFields": [],

            }));
            setInputVoucherExtraField((prevValues) => ({
                ...prevValues,
                "masterType": 186,
                "seriesId": id,
                "vchExFld": "EF",
                "isActive": true,
            }));
            setEditModeLayout(false);

            var data = await BillLayoutSettingAPI.getBillLayoutSettingById(id);
            console.log(data)
            if (data != null && data != "") {
                setInputLayout(data);
                setEditModeLayout(true);

                const data1 = await BillLayoutSettingAPI.getExtraFields(id);
                console.log(data1);
                setExtraFieldNumValue(data1.length);
                createVoucherExtrafiels(data1.length);

                if (data1 && Array.isArray(data1)) {
                    setInputVoucherExtraField(data1.map(item => ({
                        seriesId: item.seriesId || '',
                        vchExFld: "EF",
                        fieldName: item.fieldName || '',
                        isActive: true,
                    })));
                }
            }

        } else if (field === 'CopySeriesSetting') {
            setBillNumberingVisible(false);
            setBillLayoutVisible(false);
            setCopySeriesSetting(true);

        } else {
            setBillNumberingVisible(false);
            setBillLayoutVisible(false);
            setCopySeriesSetting(false);
        }
    };

    // ***********************{Optional Fields}************************

    const [customfields, setCustomefields] = useState([])

    const customFieldAddBtn = (e) => {
        const val = document.getElementById('billSeriesName').value;
        console.log(val);
        if (val.trim() !== '') {
            const newData = { id: customfields.length + 1, FieldName: val }
            setCustomefields([...customfields, newData]);
            // document.getElementById("inputField").style.display = "none";
            document.getElementById('billSeriesName').value = "";
        }
        else {
            alert("Please Enter valid Name", "ok");
        }
    }


    const handleModalClick = (e) => {
        e.stopPropagation();
    };

    // ************************{bill Serie Add Start}******************************

    const [AddSeries, setAddSeries] = useState(false);
    const showAdd = (id) => {
        setAddSeries((preAddSeries) => {
            return preAddSeries === id ? null : id;
        });
    }

    const [subCate1, setSubcate1] = useState(true);
    const [MasterType, setMasterType] = useState(0);

    const handleCheckboxClick = (id, masterType) => {
        console.log(subCate1)
        setMasterType(masterType);
        setSubcate1((PrevsubCate1) => {
            return PrevsubCate1 === id ? null : id;
        });
    };

    const [isInsert, setInsert] = useState(false)
    const AddCustmField_clicked = (masterTypeID) => {
        setInputValues2((prevValues) => ({
            ...prevValues,
            "masterType": masterTypeID,
            "isDefault": false
        }));
        console.log(inputValues2)
        setInsert(true)
        document.getElementById("inputField").style.display = "block";
        setAddSeries(false);
        AddCustmField_clicked3(false)
    }



    const [billSeriesType, setBillSeriesType] = useState(0);

    const [inputValues2, setInputValues2] = useState({
        masterType: 0,
        billSeriesType: true,
        billSeriesName: '',
        isActive: true,
        isDefault: false
    });
    const handleSaveButtonAll = (e) => {
        // customFieldAddBtn(e);
        setBillSeriesType(billSeriesType + 1)
        handleInputChange2(e);

    }

    const handleInputChange2 = (event) => {
        //const { name, value } = event.target;
        const { name, type, value, checked } = event.target;

        let updatedValue;
        if (type === 'checkbox') {
            updatedValue = checked;
        } else {
            updatedValue = value;
        }

        console.log(`Changing ${name} to ${value}`);

        setInputValues2((prevValues) => ({
            ...prevValues,
            [name]: updatedValue,
        }));
        setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));
    };

    /*
    const toFriendlyName2 = (fieldName2) => {
        return fieldName2
            .replace(/([A-Z])/g, ' $1') // Add space before capital letters
            .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
    };

    const validateInput2 = (fieldName2) => {
        console.log(fieldName2);
        const fieldValue = BillNumberingInputValues[fieldName2];

        if (!fieldValue || (typeof fieldValue === 'string' && fieldValue.trim() === '')) {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                [fieldName2]: `${toFriendlyName2(fieldName2)} field is required`,
            }));
            return false;
        }

        return true;
    };
    */

    const SaveBtn2 = async () => {
        debugger;
        setLoading(true);
        if (isInsert === false) {
            console.log("Update")
            const isSuccess = await BillSeriesAPI.updateBillSeriesMaster(inputValues2);
            if (isSuccess) {
                OpenAlertBoxEdit();
                setLoading(false);
                setInsert(true)
                console.log()

            } else {
                alert("error while updating");
            }
            document.getElementById("inputField").style.display = "none";
        }
        else {
            console.log("Insert")
            setLoading(true);
            console.log(inputValues2)
            const isSuccess = await BillSeriesAPI.insertBillSeriesMaster(inputValues2);
            if (isSuccess) {
                setLoading(false);
                OpenAlertBox();

            } else {
                alert("Error inserting data");
            }
            setInputValues2({
                billSeriesName: "",

            });
        }

        // }
        document.getElementById("inputField").style.display = "none";
    }

    // ********************Bill Series************************
    const [BillSeriesSet, setBillSeriesAll] = useState();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const billdata = await BillSeriesAPI.getBillSeriesMaster();
                setBillSeriesAll(billdata);

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    // **************************{Delete}***********************************

    const [getdeleteId, setDeleteId] = useState(null);

    const DeleteBillSeries = (id) => {
        setAlertBoxDelete(true);
        setDeleteId(id);
    }
    const DeleteBillSeriesConfirm = async () => {
        setLoading(true);
        try {
            const val = await BillSeriesAPI.deleteBillSeriesMaster(getdeleteId);
            if (Boolean(val) === true) {
                setAlertBoxDelete(false);
                setDeleteId(null);
                setLoading(false);
            } else {
                alert("Error Deleting Data");
            }
            props.fetchData();
        } catch (error) {
            console.error("Error deleting data:", error);
        }
    };

    // *************************Edit******************************

    const [editData, setEditData] = useState([]);
    const [modelOpen, setModelOpen] = useState(true);
    const editBillSeries = (id) => {
        const data = BillSeriesAPI.getbillSeriesId(id);
        console.log(data);
        setEditData(data);
        toggleSlider();
        setModelOpen(true);
        setBillNumberingVisible(false);
        setBillLayoutVisible(false);
        setCopySeriesSetting(false);
        console.log(modelOpen);
    }

    const [isSliderOpen, setSliderOpen] = useState(false);

    const toggleSlider = () => {
        console.log(editData); // Log the value of editData
        // setSliderOpen(!isSliderOpen);
        document.getElementById("inputField").style.display = "block";
        setAddSeries(false);

    };

    // **************************Fetch**********************************

    useEffect(() => {
        const fetchData = async () => {
            if (editData) {
                try {
                    const data = await editData;
                    console.log(data);
                    setInputValues2(data);
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            }
        };
        fetchData();
    }, [editData]);


    const [inputCategory, setInputCategory] = useState([
        { id: '1', masterType: 29, billType: 'Sale Series', },
        { id: '2', masterType: 30, billType: 'Sale Ret Series', },
        { id: '3', masterType: 31, billType: 'Purchase Series', },
        { id: '4', masterType: 32, billType: 'Purchase Ret Series', },
        { id: '5', masterType: 36, billType: 'Payment Series', },
        { id: '6', masterType: 37, billType: 'Receipt Series', },
        { id: '7', masterType: 38, billType: 'Journal Series', },
        { id: '8', masterType: 39, billType: 'Contra Series', },

    ]);

    const [isBillNumberField, setisBillNumberField] = useState(false);

    const handleCheckBIll = (id) => {
        console.log(isBillNumberField)
        // setisBillNumberField(id);
        setisBillNumberField((PrevisBillNumberField) => {
            return PrevisBillNumberField === id ? null : id;
        });
    };



    // ************************{bill Numbering Start}******************************

    const [isDuplicatBillNumber, setDuplicatBillNumber] = useState(false);
    const [isEditModeBillNumber, setEditModeBillNumber] = useState(false);

    const [BillNumberingInputValues, setBillNumberingInputValues] = useState({
        billTypeId: '',
        billSeriesId: '',
        numberingCategory: '',
        duplicateBillNumber: '',
        prefixType: '',
        prefix: '',
        suffix: '',
        startingNumber: '',
        isEndingNumber: true,
        endingNumber: '',
        warningBeforeNoOfVouchLeft: '',
        warningMessage: '',
        isActive: true,
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        console.log(`Changing ${name} to ${value}`);

        setBillNumberingInputValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
        setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));
        console.log(BillNumberingInputValues)
    };


    const handleInputChangeEcom = (event) => {
        const selectedValue = event.target.value;

        setBillNumberingInputValues((prevValues) => ({
            ...prevValues,
            "numberingCategory": selectedValue,
        }));

        if (selectedValue === '0') {
            setDuplicatBillNumber(false);
            setisInputDisabled1(false);
            setisInputDisabled2(false);
            setisInputDisabled3(false);
        }
        else if (selectedValue === '1') {
            setDuplicatBillNumber(true);
            setisInputDisabled1(true);
            setisInputDisabled2(true);
            setisInputDisabled3(true);
        }
        else if (selectedValue === '2') {
            setDuplicatBillNumber(false);
            setisInputDisabled1(true);
            setisInputDisabled2(true);
            setisInputDisabled3(true);

        }
    }

    const [isPrefixShowHide, setPrefixShowHide] = useState(true);
    const handleInputChangeEcomPrefix = (event) => {
        const selectedValue = event.target.value;

        setBillNumberingInputValues((prevValues) => ({
            ...prevValues,
            "prefixType": selectedValue,
        }));

        if (selectedValue === '0') {
            setPrefixShowHide(true);
        }
        else if (selectedValue === '1') {
            setPrefixShowHide(false);
        }
    }

    const [isEndingDetails, setEndingDetails] = useState(false);
    const handleInputChangeBillEnding = (event) => {
        const selectedValue = event.target.value;

        setBillNumberingInputValues((prevValues) => ({
            ...prevValues,
            "isEndingNumber": selectedValue,
        }));

        if (selectedValue === "true") {
            setEndingDetails(true);
        }
        else if (selectedValue === "false") {
            setEndingDetails(false);
        }
    }

    const toFriendlyName = (fieldName) => {
        return fieldName
            .replace(/([A-Z])/g, ' $1')
            .replace(/^./, (str) => str.toUpperCase());
    };

    const validateInput = (fieldName) => {
        console.log(fieldName);
        const fieldValue = BillNumberingInputValues[fieldName];

        if (!fieldValue || (typeof fieldValue === 'string' && fieldValue.trim() === '')) {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                [fieldName]: `${toFriendlyName(fieldName)} field is required`,
            }));
            return false;
        }

        return true;
    };
    const fieldNames = ['billTypeId', 'billSeriesId'];

    const SaveBtnBillNumbring = async () => {
        debugger;
        console.log(BillNumberingInputValues)
        let allValid = true;
        fieldNames.forEach((fieldName) => {
            const isValid = validateInput(fieldName);
            if (!isValid) {
                allValid = false;
            }
        });
        if (allValid) {
            setLoading(true);
            if (Boolean(isEditModeBillNumber) === true) {

                const data = await BillNumberingAPI.updateBillNumbering(BillNumberingInputValues);
                console.log(data); debugger;
                if (data.isSuccess) {
                    alert("Update successfull");
                } else {
                    alert("error while updating");
                }
            }
            else {
                setLoading(true);
                const data = await BillNumberingAPI.insertBillNumbering(BillNumberingInputValues);
                console.log(data); debugger;
                if (data.isSuccess) {
                    alert("Save successfull");
                } else {
                    alert("error while saving");
                }
            }

            setLoading(false);
            setBillNumberingInputValues({
                billTypeId: '',
                billSeriesId: '',
                numberingCategory: '',
                duplicateBillNumber: '',
                prefixType: '',
                prefix: '',
                suffix: '',
                startingNumber: '',
                isEndingNumber: true,
                endingNumber: '',
                warningBeforeNoOfVouchLeft: '',
                warningMessage: '',
                isActive: true,
            });
        }
    }

    // ************************{bill Numbering End}******************************

    // ************************{bill Layout Start}******************************

    const [isEditModeLayout, setEditModeLayout] = useState(false);

    const [inputLayout, setInputLayout] = useState({
        seriesId: '',
        billTypeId: '',
        billSeriesName: '',
        maxDiscLmt: '',
        itmWiseSalePrcList: true,
        itmWiseDiscrp: true,
        allowBrokrage: '',
        paymentType: 1,
        separateBillDtl: true,
        eWayBillTrnsDtl: true,
        printVchrAftrSaving: true,
        disblMultplOptForPrint: true,
        sendEmail: true,
        sendSms: true,
        enblPymntSettlmnt: true,
        isActive: true,
        extraVoucherFields: [],
    });

    const [inputVoucherExtraField, setInputVoucherExtraField] = useState([{
        masterType: 186,
        seriesId: "",
        vchExFld: "EF",
        fieldName: "",
        isActive: true,
    }]);

    const handleInputLayout2 = (index, event) => {
        const { name, value } = event.target; // Destructure name and value from event.target
        setInputVoucherExtraField((prevValues) => {
            const updatedValues = [...prevValues];
            if (updatedValues[index]) {
                updatedValues[index][name] = value;
                updatedValues[index].seriesId = inputLayout.seriesId;
            }
            return updatedValues; // Return the updated array
        });
    };

    const handleInputLayout = (event) => {
        const { name, value } = event.target;
        console.log(`Changing ${name} to ${value}`);
        setInputLayout((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
        setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));
        console.log(setInputLayout)
    };

    const SaveBtnLayout = async () => {
        setLoading(true);
        console.log(inputLayout)
        //alert(isEditModeLayout)
        if (isEditModeLayout === true) {
            const isSuccess = await BillLayoutSettingAPI.updateBillLayoutSetting(inputLayout, inputVoucherExtraField);
            if (isSuccess) {
                OpenAlertBoxEdit();
            } else {
                alert("Error while updating");
            }
        }
        else {
            console.log(inputVoucherExtraField)
            const isSuccess = await BillLayoutSettingAPI.insertBillLayoutSetting(inputLayout, inputVoucherExtraField);
            if (isSuccess) {
                OpenAlertBox();
            } else {
                alert("Error inserting data");
            }
        }

        setLoading(false);
        // setInputLayout({
        //     seriesId: '',
        //     maxDiscLmt: '',
        //     itmWiseSalePrcList: true,
        //     itmWiseDiscrp: true,
        //     allowBrokrage: '',
        //     paymentType: '',
        //     separateBillDtl: true,
        //     eWayBillTrnsDtl: true,
        //     printVchrAftrSaving: true,
        //     disblMultplOptForPrint: true,
        //     sendEmail: true,
        //     sendSms: true,
        //     enblPymntSettlmnt: true,
        //     extraVoucherFields:[],
        // });
        setExtraFieldNumValue(0);
        createVoucherExtrafiels(0);
        // setInputVoucherExtraField([{
        //     seriesId: "",
        //     vchExFld: "EF",
        //     fieldName: '',
        //     isActive: true,
        // }]);
    }

    const handleMasterSave = (e) => {
        SettlementDetails(e);
        handleInputLayout(e)
    }

    // ************************{bill Layout End}******************************

    //*****************{bill Layout etxra field}*****************//

    const [extraFieldNumValue, setExtraFieldNumValue] = useState("");
    const [data, setData] = useState([]);

    const handleInputChangeA2 = (event) => {
        const value = event.target.value;
        setExtraFieldNumValue(value);
        createVoucherExtrafiels(value);
    };

    const createVoucherExtrafiels = (value) => {

        const newData = Array.from({ length: parseInt(value) }, (_, index) => ({
            id: index + 1,
        }));

        const newData1 = Array.from({ length: parseInt(value) }, (_, index) => ({

            masterType: 186,
            seriesId: "",
            vchExFld: "EF",
            fieldName: "",
        }));


        if (newData.length > 10) {
            window.alert("You can add only 10 fields!!!");
        } else {
            setData(newData);
            setInputVoucherExtraField(newData1)
        }
    }

    //**************{Search Option}********************/

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                window.history.back();
            }
        };
        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    });

    //*******************{Bill Payment Mode}***********************

    const [isEditPaymentMode, setEditPaymentMode] = useState(false);

    const [inputValuesPaymentStatic, setInputValuesPaymentStatic] = useState([
        { id: '1', PaymentMode: 'Mode 1', disabled: "disabled", billSeries: '', assignToShow: '', accountId: '', accountTitle: '' },
        { id: '2', PaymentMode: 'Mode 2', disabled: "disabled", billSeries: '', assignToShow: '', accountId: '', accountTitle: '' },
        { id: '3', PaymentMode: 'Mode 3', disabled: "disabled", billSeries: '', assignToShow: '', accountId: '', accountTitle: '' },
        { id: '4', PaymentMode: 'Mode 4', disabled: "disabled", billSeries: '', assignToShow: '', accountId: '', accountTitle: '' },
    ]);

    const handleInputChangeActivePaymentMode = (index, e) => {
        const { name, checked } = e.target;

        setInputValuesPaymentStatic(prevValues => {
            const updatedInputValues = [...prevValues];
            updatedInputValues[index].assignToShow = checked;
            return updatedInputValues;
        });

        handleInputChangePaymentMode(index, e);
        setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));
    };

    const handleInputChangePaymentMode = (index, e) => {
        const { name, type, value, checked } = e.target; // Destructure name, type, value, and checked from event.target
        // Check the type of input element
        let updatedValue;
        if (type === 'checkbox') {
            updatedValue = checked;
        } else {
            updatedValue = value;
        }
        setInputValuesPaymentStatic((prevValues) => {
            const updatedValues = [...prevValues];
            if (updatedValues[index]) {
                updatedValues[index][name] = updatedValue;
                updatedValues[index].billSeries = inputLayout.seriesId;
            }
            return updatedValues;
        });
    };

    const [PartySettle, setPartySettle] = useState(false);
    const showPaymentMode = async () => {
        console.log(inputLayout);
        setPartySettle(true)

        const data1 = await PaymentModeAPI.getvoucherPaymentModeId(inputLayout.seriesId);
        console.log(data1);
        setEditPaymentMode(true);

        setInputValuesPaymentStatic(prevValues => (
            Array.from({ length: 4 }, (_, index) => {
                if (index < data1.length) {
                    const item = data1[index];
                    return {
                        ...prevValues[index],
                        id: (index + 1),
                        PaymentMode: 'Mode ' + (index + 1),
                        disabled: "disabled",
                        billSeries: item.billSeries,
                        assignToShow: item.assignToShow,
                        accountId: item.accountId,
                        accountTitle: item.accountTitle,
                    };
                } else {
                    return {
                        id: (index + 1),
                        PaymentMode: 'Mode ' + (index + 1),
                        disabled: "disabled",
                        billSeries: '',
                        assignToShow: '',
                        accountId: '',
                        accountTitle: '',
                    };
                }
            })
        ));
    }
    const hideMeal = () => {
        setPartySettle(false);
    }

    const SaveBtnPaymentMode = async () => {
        setLoading(true);
        debugger;
        if (isEditPaymentMode === true) {
            const isSuccess = await PaymentModeAPI.updateVoucherPaymentMode(inputValuesPaymentStatic);
            if (isSuccess) {
                OpenAlertBoxEdit();
            } else {
                alert("error while updating");
            }
        }
        else {
            const isSuccess = await PaymentModeAPI.insertVoucherPaymentMode(inputValuesPaymentStatic);
            if (isSuccess) {
                OpenAlertBox();
            } else {
                alert("Error inserting data");
            }
        }
        setLoading(false);
        setInputValuesPaymentStatic([{
            billSeries: '',
            assignToShow: '',
            accountId: '',
            accountTitle: '',
        }]);
    }

    const [AccountData, setAccountData] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const filterData = [
                    { fieldName: 'parentGroupId', operatorName: 'NotEqual', compareValue:"211" },
                    { fieldName: 'parentGroupId', operatorName: 'NotEqual', compareValue:"212" },
                    { fieldName: 'parentGroupId', operatorName: 'NotEqual', compareValue:"213" }
                ];

                const data = await AccountAPI.getAccountFilter(filterData);
                setAccountData(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);


    return (
        <div>
            <div style={{ display: "flex" }} className="EWayDiv">
                <div style={{ width: "30%", border: "1px solid black", padding: "10px" }}>
                    <div>
                        {inputCategory.map((data) => (
                            //console.log(inputCategory),

                            <div key={data.id} className="">
                                <div style={{ height: "27px" }} className="category-main">
                                    <div className="" >
                                        <label className="InputtxtLabel" name="masterType" value={data.id}
                                            onClick={() => handleCheckboxClick(data.id, data.masterType)}>{data.billType}</label>

                                        <button style={{ position: "relative" }} onClick={() => showAdd(data.id)} class="btn btnTree" >
                                            <i class="fa fa-edit SelectIcon"></i>

                                            {AddSeries === data.id && (
                                                <div class="MSCard2">
                                                    <div class="card-details">
                                                        <p class="text-title " onClick={() => AddCustmField_clicked(data.masterType)}>Add Series</p>
                                                    </div>
                                                </div>
                                            )}
                                        </button>
                                    </div>
                                </div>
                                {subCate1 === data.id && (
                                    <div className="sub-category-box3">
                                        {(() => {

                                            if (BillSeriesSet !== null && Array.isArray(BillSeriesSet)) {
                                                return BillSeriesSet.map((billdata) => {
                                                    console.log("xzz", data.masterType)

                                                    if (billdata.masterType === MasterType) {
                                                        return (
                                                            <div className="sub-category-txt" key={billdata.id}>
                                                                <div style={{ cursor: "pointer" }} className="CategoryBoxPaddingLeft">
                                                                    {billdata.billSeriesName}
                                                                    <button style={{ position: "relative" }} onClick={() => showSubAdd(billdata.id)} className="btn btnTree">
                                                                        <i className="fa fa-edit"></i>
                                                                        {SubAddSeries === billdata.id && (
                                                                            <div className="MScard">
                                                                                <div className="card-details">
                                                                                    <p className="text-title Text_H7" onClick={() => editBillSeries(billdata.id)}>Modify Series</p>
                                                                                    <p className="text-title Text_H7" onClick={() => DeleteBillSeries(billdata.id)}>Delete Series</p>
                                                                                    <p className="text-title Text_H7" onClick={() => AddCustmField_clicked3('BillNumbering2', billdata.id, billdata.masterType, billdata.billSeriesName)}>Set Bill Numbering</p>

                                                                                    <p className="text-title Text_H7" onClick={() => AddCustmField_clicked3('SetBillLayout', billdata.id, billdata.masterType, billdata.billSeriesName)}>Set Bill Layout</p>
                                                                                    <p className="text-title Text_H7" onClick={() => AddCustmField_clicked3('CopySeriesSetting')}>Copy Series Setting</p>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                    return null; // or you can return an empty array or other value based on your use case
                                                });
                                            }
                                        })()}

                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>


                <div style={{ width: "70%", border: "1px solid black", padding: "10px" }}>

                    {/* *******************************{Add Bill Series}********************************* */}
                    <div style={{ display: "none", width: "45%", border: "1px solid gray", borderRadius: "8px" }} id="inputField">
                        <div style={{ padding: "10px" }} >
                            <div style={{ paddingBottom: "2px" }} className="AddBillHeadText" >
                                <span style={{ color: "red", fontSize: "18px", fontWeight: "600" }}>Add Bill Series</span>
                            </div>
                            <hr />
                            <div className="AddBillHeadText">
                                <div style={{ width: "50%" }} > Bill Type :</div>
                                <div style={{ display: "flex", width: "50%" }}>
                                    <input type="text" name="masterType" id={inputValues2.masterType} value={inputValues2.masterType} onChange={handleSaveButtonAll} className="disabledInput modify_im_input " readOnly />
                                </div>
                            </div>
                            <div className="AddBillHeadText">
                                <div style={{ width: "50%" }} > Active/Deactive :</div>
                                <div style={{ display: "flex", width: "50%" }}>
                                    <input type="checkbox" name="isActive" value={inputValues2.isActive} style={{ fontSize: "16px" }}
                                        checked={inputValues2.isActive} onChange={handleSaveButtonAll} />
                                    {/* <span>Select For Deactivate Series</span> */}
                                </div>
                            </div>
                            <div className="AddBillHeadText">
                                <div style={{ width: "50%" }} > Series Name :</div>
                                <div style={{ display: "flex", width: "50%" }}>
                                    <input style={{ backgroundColor: "#0000001a", }} name="billSeriesName" value={inputValues2.billSeriesName}
                                        onChange={handleSaveButtonAll}
                                        className=" modify_im_input" type="text" id="billSeriesName" />
                                    <label ><span className="StarClr">*
                                        {errorMessages.billSeriesName && <label className="errorMessage">{errorMessages.billSeriesName}</label>}
                                    </span></label>
                                </div>
                            </div>
                            <div className="AddBillHeadText">
                                <div style={{ width: "50%" }} >Set as Default Series&nbsp;:</div>
                                <div style={{ display: "flex", width: "50%" }}>
                                    <select className="modify_im_input " name="isDefault" value={inputValues2.isDefault}
                                        onChange={handleSaveButtonAll} style={{ width: "155px" }}
                                        type="text" placeholder="" required>
                                        <option value="false">No</option>
                                        <option value="true">Yes</option>
                                    </select>
                                    <label ></label>
                                </div>
                            </div>
                            <div className="flex-center" >
                                <button style={{ borderRadius: "6px" }} className="Add_btn MasterSettingSaveBtn" onClick={SaveBtn2}>Save</button>
                            </div>
                        </div>
                    </div>

                    {/* *******************************{Bill Numbering}********************************* */}


                    <div style={{ display: isBillNumberingVisible ? 'block' : 'none', width: "100%", borderRadius: "8px" }} id="BillNumbering2">
                        <div>
                            <div style={{ paddingBottom: "2px" }} className="AddBillHeadText" >
                                <span style={{ color: "red", fontSize: "18px", fontWeight: "600", justifyContent: "center" }}>Bill Numbering</span>
                            </div>
                            <div className="HeadContentInputBpx" style={{ paddingBottom: "0px" }}>
                                <div className="input-box-m11">
                                    <div className=" mode_category">
                                        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                            <label class="InputTextBoxLabel5 ">Bill Types:</label>
                                            <input style={{ width: "50%" }}
                                                value={BillNumberingInputValues.billTypeId} onChange={handleInputChange}
                                                className="modify_im_input mode_category disabledInput InputH_6" type="text" disabled />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-box-m11">
                                    <div className=" mode_category">
                                        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                            <label class="InputTextBoxLabel5 ">Series:</label>
                                            <input type="hidden" value={BillNumberingInputValues.billTypeId} onChange={handleInputChange} />
                                            <input style={{ width: "50%" }} value={BillNumberingInputValues.billSeriesName}
                                                onChange={handleInputChange}
                                                className="modify_im_input mode_category disabledInput InputH_6" type="text" disabled />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />

                            <div className="HeadContentInputBpx" style={{ paddingBottom: "0px" }}>
                                <div className="input-box-m11">
                                    <select className="modify_im_input mode_category" name="numberingCategory"
                                        value={BillNumberingInputValues.numberingCategory} onChange={handleInputChangeEcom}
                                        type="text" placeholder="" required>
                                        <option>--Select--</option>
                                        <option value="0">Automatic</option>
                                        <option value="1">Manual</option>
                                        <option value="2">Not Required</option>
                                    </select>
                                    <label class="ItemMasterLabelFix">Numbering Category
                                        <span className="StarClr">*
                                            {errorMessages.numberingCategory && <label className="errorMessage">{errorMessages.numberingCategory}</label>}
                                        </span></label>
                                </div>
                                {isDuplicatBillNumber && (
                                    <div className="input-box-m11">
                                        <select className="modify_im_input mode_category" name="duplicateBillNumber"
                                            value={BillNumberingInputValues.duplicateBillNumber} onChange={handleInputChange}
                                            type="text" placeholder="" required>
                                            <option>--Select--</option>
                                            <option value="0">Worning Only</option>
                                            <option value="1">Don't Allow</option>
                                        </select>
                                        <label class="ItemMasterLabelFix">Duplicate Bill Number</label>
                                    </div>
                                )}
                            </div>

                            <div className="company_info-text-im">Setting For Auto Number :</div>
                            <div className="HeadContentInputBpx" style={{ paddingTop: "0px" }}>
                                <div className="input-box-m11">
                                    <select className="modify_im_input mode_category" name="prefixType"
                                        value={BillNumberingInputValues.prefixType} onChange={handleInputChangeEcomPrefix}
                                        type="text" placeholder="" required>
                                        <option value="0">Manual Prefix</option>
                                        <option value="1">Current Date & Date Wise Count</option>
                                    </select>
                                    <label class="ItemMasterLabelFix">Prefix Types</label>
                                </div>
                                {isPrefixShowHide && (
                                    <div className="input-box-m11">
                                        <input className={isInputDisabled1 ? 'disabledInput modify_im_input mode_category' : 'modify_im_input mode_category'}
                                            name="prefix" value={BillNumberingInputValues.prefix} onChange={handleInputChange} type="text" disabled={isInputDisabled1} required />
                                        <label class="modifyTextLabel">Prefix</label>
                                    </div>
                                )}
                                <div className="input-box-m11">
                                    <input className={isInputDisabled1 ? 'disabledInput modify_im_input mode_category' : 'modify_im_input mode_category'}
                                        name="suffix" value={BillNumberingInputValues.suffix} onChange={handleInputChange} type="text" disabled={isInputDisabled1} required />
                                    <label class="modifyTextLabel">Suffix</label>
                                </div>

                                <div className="input-box-m11">
                                    <input className={isInputDisabled2 ? 'disabledInput modify_im_input mode_category' : 'modify_im_input mode_category'}
                                        name="startingNumber" value={BillNumberingInputValues.startingNumber} onChange={handleInputChange} type="number" disabled={isInputDisabled2} required />
                                    <label class="modifyTextLabel">Starting Number</label>
                                </div>
                                <div className="input-box-m11">
                                    <select className={isInputDisabled3 ? 'disabledInput modify_im_input mode_category' : 'modify_im_input mode_category'}
                                        name="isEndingNumber" value={BillNumberingInputValues.isEndingNumber} onChange={handleInputChangeBillEnding}
                                        type="text" disabled={isInputDisabled3} required>
                                        <option value="false">No</option>
                                        <option value="true">Yes</option>
                                    </select>
                                    <label class="ItemMasterLabelFix">Ending Number(Y/N)</label>
                                </div>
                            </div>
                            {isEndingDetails && (
                                <div>
                                    <div className="company_info-text-im">Ending Details :</div>
                                    <div className="HeadContentInputBpx" style={{ paddingTop: "0px" }}>

                                        <div className="input-box-m11">
                                            <input className={isInputDisabled1 ? ' modify_im_input mode_category' : 'modify_im_input mode_category'}
                                                name="endingNumber" value={BillNumberingInputValues.endingNumber} onChange={handleInputChange} type="text" required />
                                            <label class="modifyTextLabel">Ending Number</label>
                                        </div>

                                        <div className="input-box-m11">
                                            <input className={isInputDisabled2 ? ' modify_im_input mode_category' : 'modify_im_input mode_category'}
                                                name="warningBeforeNoOfVouchLeft" value={BillNumberingInputValues.warningBeforeNoOfVouchLeft} onChange={handleInputChange} type="text" required />
                                            <label class="modifyTextLabel">Warning Before No Of Vouch Left</label>
                                        </div>
                                        <div className="input-box-m11">
                                            <input className={isInputDisabled1 ? ' modify_im_input mode_category' : 'modify_im_input mode_category'}
                                                name="warningMessage" value={BillNumberingInputValues.warningMessage} onChange={handleInputChange} type="text" required />
                                            <label class="modifyTextLabel">Warning Message</label>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="flex-center" >
                                <button style={{ borderRadius: "6px" }} onClick={SaveBtnBillNumbring} className="Add_btn MasterSettingSaveBtn" >Save</button>
                            </div>
                        </div>
                    </div>

                    {/* *******************************{Set Bill Layout}********************************* */}
                    <div style={{ display: isBillLayoutVisible ? 'block' : 'none', width: "80%", borderRadius: "8px" }} id="SetBillLayout">
                        <div >
                            <div style={{ paddingBottom: "2px" }} className="AddBillHeadText" >
                                <span style={{ color: "red", fontSize: "18px", fontWeight: "600", justifyContent: "center" }}>Planning Bill Structure</span>
                            </div>

                            <div className="HeadContentInputBpx" style={{ paddingBottom: "0px" }}>
                                <div className="input-box-m11">
                                    <div className=" mode_category">
                                        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                            <label class="InputTextBoxLabel5 ">Bill Type:</label>
                                            <input type="hidden" name="seriesId" value={inputLayout.seriesId} onChange={handleInputLayout} />
                                            <input style={{ width: "50%" }} className="modify_im_input InputH_6 mode_category disabledInput"
                                                name="" value={inputLayout.billTypeId} type="text" onChange={handleInputLayout} />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-box-m11">
                                    <div className=" mode_category">
                                        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                            <label class="InputTextBoxLabel5 ">Series:</label>
                                            <input style={{ width: "50%" }} className="modify_im_input InputH_6 mode_category disabledInput" type="text"
                                                value={inputLayout.billSeriesName} onChange={handleInputLayout} />
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <>
                                {/* ********************************{Payment Mode UI}********************************* */}
                                {PartySettle && (
                                    <div onClick={hideMeal} className="modal" >
                                        <div onClick={handleModalClick} className="OpticalField-modal PaymentSattlementModal" style={{ width: "auto" }}>
                                            <div className="modal-title">
                                                <span className="close_modal" onClick={hideMeal}>
                                                    &times;
                                                </span>
                                            </div>
                                            <div className="">
                                                <div className="tab-content-modal">
                                                    <div className="company-info-top-modal control_flex_modal item_scroll" style={{ height: "auto" }}>
                                                        <div className="AddBillHeadText" style={{ paddingBottom: "2px" }}>
                                                            <span style={{ color: "red", fontSize: "18px", fontWeight: "600", justifyContent: "center" }}>Party Settlement and Payment Mode</span>
                                                        </div>
                                                        <div className="HeadContentInputBpx" style={{ paddingBottom: "0px" }}>
                                                            <div className="input-box-m11">
                                                                <div className=" mode_category">
                                                                    <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                                                        <label class="InputTextBoxLabel5 ">Bill Type:</label>
                                                                        <input style={{ width: "50%" }} className="modify_im_input InputH_6 mode_category disabledInput"
                                                                            type="text" value={inputLayout.billTypeId} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="input-box-m11">
                                                                <div className=" mode_category">
                                                                    <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                                                        <label class="InputTextBoxLabel5 ">Series:</label>
                                                                        <input style={{ width: "50%" }} className="modify_im_input InputH_6 mode_category disabledInput"
                                                                            type="text" value={inputLayout.billSeriesName} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div className="HeadContentInputBpx" style={{ paddingTop: "0px" }}>
                                                            <div className="table-container-s1"
                                                                style={{ height: "auto", display: "flex", justifyContent: "center" }} >
                                                                <table id="tblTransactionList" className="table_hd1 table-bordered1">
                                                                    <thead textAlign="center" className="thead_scroll_col12" style={{ background: "rgb(33, 46, 79)", color: "white" }}>
                                                                        <tr>
                                                                            <th style={{ width: "150px", textAlign: "center" }}>Mode</th>
                                                                            <th style={{ width: "150px", textAlign: "center" }}>Assign</th>
                                                                            <th style={{ width: "150px", textAlign: "center" }}>Party Name</th>
                                                                            <th style={{ width: "150px", textAlign: "center" }}>Title</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="tbody_body_m1">
                                                                        {inputValuesPaymentStatic.map((data1, index) => (
                                                                            <tr key={data1.id}>
                                                                                <td style={{ textAlign: "center" }}>
                                                                                    {data1.PaymentMode}
                                                                                </td>
                                                                                <td style={{ textAlign: "center" }}>
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        className="checkbox-input tableCardSide"
                                                                                        name="assignToShow"
                                                                                        checked={data1.assignToShow}
                                                                                        onChange={(e) => handleInputChangeActivePaymentMode(index, e)}
                                                                                    />
                                                                                </td>
                                                                                <td style={{ textAlign: "center" }}>
                                                                                    <select type="text"
                                                                                        className="modify_im_input mode_limit mode_category"
                                                                                        onChange={(e) => handleInputChangePaymentMode(index, e)}
                                                                                        name="accountId"
                                                                                        value={data1.accountId}
                                                                                        disabled={!data1.assignToShow || data1.disabled === "enabled"}
                                                                                    >
                                                                                        <option Classname="form-control s-1" >Select Acc</option>
                                                                                        {(() => {
                                                                                            if (AccountData !== null && Array.isArray(AccountData)) {
                                                                                                return AccountData.map((data) => (
                                                                                                    <option key={data.d2Id} value={data.d2Id} >
                                                                                                        {data.name}
                                                                                                    </option>
                                                                                                ))
                                                                                            }
                                                                                        })()}
                                                                                    </select>
                                                                                </td>
                                                                                <td style={{ textAlign: "center" }}>
                                                                                    <input type="text"
                                                                                        className="modify_im_input mode_limit mode_category"
                                                                                        onChange={(e) => handleInputChangePaymentMode(index, e)}
                                                                                        name="accountTitle"
                                                                                        value={data1.accountTitle}
                                                                                        disabled={!data1.assignToShow || data1.disabled === "enabled"}
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        <div style={{ display: "flex", justifyContent: "center", paddingTop: "5px" }}>
                                                            <div className="flex-center" >
                                                                <button onClick={SaveBtnPaymentMode} style={{ borderRadius: "6px" }} className="Add_btn MasterSettingSaveBtn" >Save</button>
                                                            </div>
                                                            <div className="flex-center" >
                                                                <button onClick={hideMeal} style={{ borderRadius: "6px", background: "white", color: "black" }} className="Add_btn MasterSettingSaveBtn" >Back</button>
                                                            </div>
                                                            <span style={{color: "red",paddingLeft: "5px"}}>*Please select checkbox for enter payment mode</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </>

                            <hr />

                            <div style={{ paddingTop: "0px" }} className=" control_flex_m1">
                                <div className="control_radius">
                                    <div className="company_control">
                                        <div className="stock-im-dep1">
                                            <div className="input-box-Imo1 input-height2">
                                                <div className="input-box-mok1">
                                                    <input className="modify_im_input mode_category1"
                                                        type="text" name="vchExFld" required value={extraFieldNumValue}
                                                        onChange={handleInputChangeA2} />
                                                    <label class="modify_p_text2">
                                                        Voucher Wise Extra Field Numbers:
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className="table-container-s1"
                                    style={{ height: "auto" }}
                                >
                                    <table className="table-scrol2" style={{ width: "100%" }}>
                                        <thead style={{ background: "#212e4f" }} className="table-tbody">
                                            <tr className="table-tr2 tabrow-head-padding">
                                                <th className=" table-th-serialno  c-w priority-width">
                                                    <label className="c-w">SN</label>
                                                </th>
                                                <th className="mb_3_attributename c-w">

                                                    <div className="thm2  c-w">
                                                        <label id="itemName" className="c-w">
                                                            Field Name
                                                        </label>
                                                    </div>
                                                </th>
                                                <th className=" table-th-serialno  c-w priority-width">
                                                    <label className="c-w">SN</label>                                                </th>
                                                <th className="mb_3_attributename c-w">

                                                    <div className="thm2  c-w">
                                                        <label id="itemName" className="c-w">
                                                            Field Name
                                                        </label>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="table-container-attribute AddNewItem_scroll addItemTable Field-attmargintop"
                                        >
                                            <div className="HeadContentInputBpx">
                                                {data.map((row, index) => (
                                                    <div className="input-box-m11" key={index}>
                                                        <tr className="Table-Attribute-Field" >
                                                            <td
                                                                className="table-th-serialno srno-flex-attribute priority-width"
                                                                style={{ textAlign: "right" }}
                                                            >
                                                                <div className="serialno_icon">{index + 1}</div>
                                                            </td>
                                                            <td className="mb_3_attributename">
                                                                <input type="hidden" name="seriesId" value={inputVoucherExtraField[index].seriesId} onChange={(e) =>
                                                                    handleInputLayout2(index, e)
                                                                } />

                                                                <input className="table-input-Barcode pd_1"
                                                                    type="text" name="fieldName" value={inputVoucherExtraField[index].fieldName}
                                                                    onChange={(e) =>
                                                                        handleInputLayout2(index, e)
                                                                    }
                                                                />
                                                            </td>
                                                        </tr>
                                                    </div>
                                                ))}
                                            </div>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {/* **************************{2nd part}********************* */}
                            <div className="HeadContentInputBpx" style={{ paddingTop: "0px" }}>
                                <div className="input-box-m11">
                                    <input className={'modify_im_input mode_category'} name="maxDiscLmt" value={inputLayout.maxDiscLmt}
                                        onChange={handleInputLayout} type="text" required />
                                    <label class="modifyTextLabel InputL4">Max Discount Limit (%)</label>
                                </div>
                                <div className="input-box-m11">
                                    <select className={'modify_im_input mode_category'} name="itmWiseSalePrcList" value={inputLayout.itmWiseSalePrcList}
                                        onChange={handleInputLayout} type="text" required>
                                        <option value="false">No</option>
                                        <option value="true">Yes</option>
                                    </select>
                                    <label class="ItemMasterLabelFix InputL4">Item Wise Sale Price List Related to Party</label>
                                </div>
                                <div className="input-box-m11">
                                    <select className={'modify_im_input mode_category'} name="eWayBillTrnsDtl" value={inputLayout.eWayBillTrnsDtl}
                                        onChange={handleInputLayout} type="text" required>
                                        <option value="false">No</option>
                                        <option value="true">Yes</option>
                                    </select>
                                    <label class="ItemMasterLabelFix InputL4">E-Way Bill/Transport Details Window</label>
                                </div>
                                <div className="input-box-m11">
                                    <select className={'modify_im_input mode_category'} name="printVchrAftrSaving" value={inputLayout.printVchrAftrSaving}
                                        onChange={handleInputLayout} type="text" required>
                                        <option value="false">No</option>
                                        <option value="true">Yes</option>
                                    </select>
                                    <label class="ItemMasterLabelFix InputL4">Print Inventory Vchr. After Saving</label>
                                </div>
                                <div className="input-box-m11">
                                    <select className={'modify_im_input mode_category'} name="itmWiseDiscrp" value={inputLayout.itmWiseDiscrp}
                                        onChange={handleInputLayout} type="text" required>
                                        <option value="false">No</option>
                                        <option value="true">Yes</option>
                                    </select>
                                    <label class="ItemMasterLabelFix InputL4">Item Wise Description Window</label>
                                </div>
                                <div className="input-box-m11">
                                    <select className={'modify_im_input mode_category'} name="disblMultplOptForPrint" value={inputLayout.disblMultplOptForPrint}
                                        onChange={handleInputLayout} type="text" required>
                                        <option value="false">No</option>
                                        <option value="true">Yes</option>
                                    </select>
                                    <label class="ItemMasterLabelFix InputL4">Disable Multiple Option For Print Voucher</label>
                                </div>
                                <div className="input-box-m11">
                                    <select className={'modify_im_input mode_category'} name="allowBrokrage" value={inputLayout.allowBrokrage}
                                        onChange={handleInputLayout} type="text" required>
                                        <option value="1">None</option>
                                        <option value="2">Item Wise</option>
                                        <option value="3">Bill Wise</option>
                                    </select>
                                    <label class="ItemMasterLabelFix InputL4">Allow Brokerage</label>
                                </div>
                                <div className="input-box-m11">
                                    <select className={'modify_im_input mode_category'} name="sendEmail" value={inputLayout.sendEmail}
                                        onChange={handleInputLayout} type="text" required>
                                        <option value="false">No</option>
                                        <option value="true">Yes</option>
                                    </select>
                                    <label class="ItemMasterLabelFix InputL4">Send E-mail After Saving</label>
                                </div>
                                <div className="input-box-m11">
                                    <select className={'modify_im_input mode_category'} name="paymentType" value={inputLayout.paymentType}
                                        onChange={handleInputLayout} type="text" required>
                                        <option value="1">PostPaid</option>
                                        <option value="2">Prepaid</option>
                                    </select>
                                    <label class="ItemMasterLabelFix InputL4">Payment Types</label>
                                </div>
                                <div className="input-box-m11">
                                    <select className={'modify_im_input mode_category'} name="sendSms" value={inputLayout.sendSms}
                                        onChange={handleInputLayout} type="text" required>
                                        <option value="false">No</option>
                                        <option value="true">Yes</option>
                                    </select>
                                    <label class="ItemMasterLabelFix InputL4">Send SMS After Saving</label>
                                </div>
                                <div className="input-box-m11">
                                    <select className={'modify_im_input mode_category'} name="separateBillDtl" value={inputLayout.separateBillDtl}
                                        onChange={handleInputLayout} type="text" required>
                                        <option value="false">No</option>
                                        <option value="true">Yes</option>
                                    </select>
                                    <label class="ItemMasterLabelFix InputL4">Separate Billing Details Window</label>
                                </div>
                                <div className="input-box-m11">
                                    <select className={'modify_im_input mode_category'} name="enblPymntSettlmnt" value={inputLayout.enblPymntSettlmnt}
                                        onChange={handleMasterSave}
                                        type="text" required>
                                        <option value="false">No</option>
                                        <option value="true">Yes</option>
                                    </select>
                                    <label class="ItemMasterLabelFix InputL4">Enable Settlement Details Window</label>
                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <div className="flex-center" >
                                    <button style={{ borderRadius: "6px" }} onClick={SaveBtnLayout} className="Add_btn MasterSettingSaveBtn" >Save</button>
                                </div>
                                {isSetBtn && (
                                    <div className="flex-center" >
                                        <button style={{ borderRadius: "6px" }} onClick={showPaymentMode} className="Add_btn MasterSettingSaveBtn" >Set</button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>


                    {/* *******************************{Copy Series Setting}********************************* */}
                    <div style={{ display: isCopySeriesSetting ? 'block' : 'none', width: "80%", borderRadius: "8px" }} id="CopySeriesSetting">
                        <div >
                            <div style={{ paddingBottom: "2px", justifyContent: "center" }} className="AddBillHeadText" >
                                <span style={{ color: "red", fontSize: "18px", fontWeight: "600", justifyContent: "center" }}>Copy Other Series setting in to current series</span>
                            </div>
                            <div className="HeadContentInputBpx" style={{ paddingBottom: "0px" }}>
                                <div className="input-box-m11">
                                    <div className=" mode_category">
                                        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                            <label class="InputTextBoxLabel5 InputL4">Bill Type:</label>
                                            <input style={{ width: "50%" }} className="modify_im_input InputH_6 mode_category disabledInput" type="text" value="Sale" />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-box-m11">
                                    <div className=" mode_category">
                                        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                            <label class="InputTextBoxLabel5 ">Series:</label>
                                            <input style={{ width: "50%" }} className="modify_im_input InputH_6 mode_category disabledInput" type="text" value="SALE" />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <hr />

                            <div className="HeadContentInputBpx" style={{ paddingTop: "0px" }}>
                                <div className="input-box-m11">
                                    <select className={isInputDisabled3 ? 'disabledInput modify_im_input mode_category' : 'modify_im_input mode_category'}
                                        type="text" disabled={isInputDisabled3} required>
                                        <option value="No">No</option>
                                        <option value="Yes">Yes</option>
                                    </select>
                                    <label class="ItemMasterLabelFix">Bill Series</label>
                                </div>
                            </div>
                            <div className="flex-center" >
                                <button style={{ borderRadius: "6px" }} className="Add_btn MasterSettingSaveBtn" >Save</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {alertBox && (
                <AlertBox
                    HeadingTop="Saved"
                    Heading="Your Bill Series Master is Saved"
                    onYesClick={OpenAlertBox}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBox}
                />
            )}
            {alertBoxEdit && (
                <AlertBox
                    HeadingTop="Updated"
                    Heading="Your Bill Series Master is Updated"
                    onYesClick={OpenAlertBoxEdit}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBoxEdit}
                />
            )}
            {alertBoxDelete && (
                <AlertBox
                    HeadingTop="DELETE"
                    Heading="Are You sure you want to delete this"
                    OpenAlertBox={OpenAlertBoxDelete}
                    onYesClick={DeleteBillSeriesConfirm}
                />
            )}
            {loading && <Loader />}
        </div>
    );
}


export default BillSeriesSetting;
