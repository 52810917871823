//import { useState } from "react";
import "./POSInvoice.css";
//import NavBar from '../Components/NavBar';
import { Link } from 'react-router-dom'

const DineIn = () => {

    const handleTableButtonClick = (num) => {
        const id = "table" + num;
        const name = "transaction" + num;
        if (document.getElementById(name).style.display === "block") {
            document.getElementById(id).style.backgroundColor = "#f2f2f2";
            document.getElementById(name).style.display = "none";
        }
        else {
            document.getElementById(id).style.backgroundColor = "goldenrod";
            document.getElementById(name).style.display = "block";
        }
    }
    const handleTableButtonClick1 = (num) => {
        const id = "table1" + num;
        const name = "transaction1" + num;
        if (document.getElementById(name).style.display === "block") {
            document.getElementById(id).style.backgroundColor = "#f2f2f2";
            document.getElementById(name).style.display = "none";
        }
        else {
            document.getElementById(id).style.backgroundColor = "goldenrod";
            document.getElementById(name).style.display = "block";
        }
    }

    return (
        <div>
            {/*<NavBar />*/}
            <div className="scroll-container" style={{ height: "98vh", backgroundColor: "white" }}>
                <div style={{ display: "", justifyContent: "", width: "100%", backgroundColor: "white", paddingBottom: "0px" }}>
                    <label className="mt-2" style={{ fontWeight: "700", marginTop: "0px", fontSize: "18px", color: "#3d470a", padding: "10px", position: "fixed", zIndex: "0" }}>Table View</label>
                    <div style={{ display: "flex", justifyContent: "flex-end", paddingTop: "10px" }}>
                        <i className="fa-solid fa-arrows-rotate mt-3" style={{ cursor: "pointer", color: "black", marginBottom: "10px", marginRight: "5px" }}></i>
                        <button className="OrderNow-btn" style={{ width: "10%", marginBottom: "10px", }}>Delivery</button>
                        <button className="OrderNow-btn" style={{ width: "10%", marginBottom: "10px" }}>Pick Up</button>
                        <button className="OrderNow-btn" style={{ width: "10%", marginBottom: "10px" }}>+ Add Table</button>
                    </div>
                </div>
                <hr />
                <div className="mt-3" style={{ display: "flex", justifyContent: "flex-end" }}>
                    <label style={{ fontWeight: "700", backgroundColor: "lightgray", padding: "10px", borderRadius: "5px" }}>
                        <input type="radio" value="MoveKOT/Items" style={{boxShadow:"none"}} />
                        &nbsp;Move KOT/Items
                    </label>
                    <div className="mt-2" style={{ display: "flex", justifyContent: "space-around" }}>
                        <div style={{ display: "flex" }}>
                            &nbsp;&nbsp;
                            <div className="pos-circle"></div>
                            <label>&nbsp; Blank Table &nbsp;</label>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div className="pos-circle" style={{ backgroundColor: "#34c0ee" }}></div>
                            <label>&nbsp; Running Table &nbsp;</label>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div className="pos-circle" style={{ backgroundColor: "yellowgreen" }}></div>
                            <label>&nbsp; Printed Table &nbsp;</label>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div className="pos-circle" style={{ backgroundColor: "bisque" }}></div>
                            <label>&nbsp; Paid Table &nbsp;</label>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div className="pos-circle" style={{ backgroundColor: "goldenrod" }}></div>
                            <label>&nbsp; Running KOT Table &nbsp;</label>
                        </div>
                    </div>
                </div>
                <div style={{ marginLeft: "15px" }}>
                    <label><u style={{ fontWeight: "700" }}>Ground Floor</u></label>
                    <div style={{ display: "flex", justifyContent: "flex-start", cursor: "pointer" }}>
                        {Array.from({ length: 10 }, (_, i) => (
                            <div className="mt-2" key={i}>
                                <div className="tableNumber" id={"table" + i} onClick={() => handleTableButtonClick(i)}>
                                    <label>{i + 1}</label>
                                    <Link to="/payment">
                                        <i className="fa-solid fa-sack-dollar" id={"transaction" + i} style={{ cursor: "pointer", display: "none", color: "black" }}></i>
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                    <label className="mt-3"><u style={{ fontWeight: "700" }}>Basement</u></label>
                    <div style={{ display: "flex", justifyContent: "flex-start", cursor: "pointer" }}>
                        {Array.from({ length: 10 }, (_, i) => (
                            <div className="tableNumber" id={"table1" + i} onClick={() => handleTableButtonClick1(i)}>
                                <label>{i + 11}</label>
                                <i className="fa-solid fa-sack-dollar" id={"transaction1" + i} style={{ cursor: "pointer", display: "none", color: "black" }}></i>
                            </div>
                        ))}
                    </div>
                    <label className="mt-3"><u style={{ fontWeight: "700" }}>Hall</u></label>
                    <div style={{ display: "flex", justifyContent: "flex-start", cursor: "pointer" }}>
                        {Array.from({ length: 2 }, (_, i) => (
                            <div className="mt-2" key={i}>
                                <div className="tableNumber">Hall {i + 1}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DineIn;
