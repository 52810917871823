
import DateTime from "../Components/DateTime";
import React, { useState } from "react";
import PageTabs from "../Components/PageTabs";

function ModalItemFeedback(props) {
    /***********title***************/
    useState(() => {
        document.title = 'Item Feedback';
    }, []);

    //***************{Array Data}***************//
    // const dataArray = Array.from({ length: 5 }, (_, index) => ({
    //     id: index,
    // }));

    const {ItemDetail , closeItemDetailModal} = props


    return (
        <div>
            <PageTabs />
            <div>
                <DateTime />
            </div>
            <div className="content_box">
                <div className="company_control">
                    <div className="col-sm-12">
                        <div className="table-resposive" >
                            <table id="tblTransactionList" className="table_ord_mod table-bordered1" frame="box">
                                <tbody className="tbody_body_m1 overflow-pymt">
                                    {ItemDetail.map((item, index) => (
                                        <tr className="table_tbody_Order_List">
                                            <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>{item.d2ItemName}</td>
                                            <td className="td_col6 td_sno-btn" style={{ textAlign: "center" }}>
                                                <div className="star-flex-feedback">
                                                    <div>
                                                    {[...Array(5)].map((_, starIndex) => (
                                            <span
                                                key={starIndex}
                                               
                                                // style={{
                                                //     color: starIndex > 3 ? '#ffa500' : starIndex < orderRating ? 'green' : 'grey',
                                                //     cursor: 'pointer', fontSize: "18px"
                                                // }}
                                                style={{
                                                    color: starIndex <item.itemFeedback ? 'darkOrange' : 'grey',
                                                    cursor: 'pointer',
                                                    fontSize: "18px"
                                                }}
                                            >
                                                &#9733;
                                            </span>
                                        ))}
                                                    </div>
                                                    {/* <div>
                                                        <div class="star-fd">★</div>
                                                        <div class="number-fd">1.5</div>
                                                    </div>
                                                    <div>
                                                        <div class="star-fd">★</div>
                                                        <div class="number-fd">2</div>
                                                    </div>
                                                    <div>
                                                        <div class="star-fd">★</div>
                                                        <div class="number-fd">2.5</div>
                                                    </div>
                                                    <div>
                                                        <div class="star-fd">★</div>
                                                        <div class="number-fd">3</div>
                                                    </div>
                                                    <div>
                                                        <div class="star-fd">★</div>
                                                        <div class="number-fd">3.5</div>
                                                    </div>
                                                    <div>
                                                        <div class="star-fd">★</div>
                                                        <div class="number-fd">4</div>
                                                    </div>
                                                    <div>
                                                        <div class="star-fd">★</div>
                                                        <div class="number-fd">4.5</div>
                                                    </div>
                                                    <div>
                                                        <div class="star-fd">★</div>
                                                        <div class="number-fd">5</div>
                                                    </div> */}
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default ModalItemFeedback;
