import DateTime from "../../Components/DateTime";
import PageTabs from "../../Components/PageTabs";
import React, { useState, useRef, useEffect } from "react";
import 'react-quill/dist/quill.snow.css'
import MainButton from "../../Props/MainButton";

function LoyaltyMasterSetting() {
    //*************************title Start***********************//
    useEffect(() => {
        document.title = 'Loyalty Master Setting';
    }, []);

    //**************************title End***********************//

     /************Category box****************/
     const [rows, setRows] = useState(Array(5).fill({}));

     const handleAddRow = () => {
         setRows([...rows, {}]);
     };
 
   
    //  /* *******************Tab****************** */

    const [activeTab, setActiveTab] = useState(0); // Initialize the active tab index

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    const [tabcontent1, setTabContent1] = useState(true);
    const [tabcontent2, setTabContent2] = useState(false);
    const [tabcontent3, setTabContent3] = useState(false);
    const [tabcontent4, setTabContent4] = useState(false);
    const [tabcontent5, setTabContent5] = useState(false);

    const showtabs1 = () => {
        setTabContent1(true)
        setTabContent2(false)
        setTabContent3(false)
        setTabContent4(false)
        setTabContent5(false)
    }

    const showtabs2 = () => {
        setTabContent1(false)
        setTabContent2(true)
        setTabContent3(false)
        setTabContent4(false)
        setTabContent5(false)
    }

    const showtabs3 = () => {
        setTabContent1(false)
        setTabContent2(false)
        setTabContent3(true)
        setTabContent4(false)
        setTabContent5(false)
    }
    const showtabs4 = () => {
        setTabContent1(false)
        setTabContent2(false)
        setTabContent3(false)
        setTabContent4(true)
        setTabContent5(false)
    }
    const showtabs5 = () => {
        setTabContent1(false)
        setTabContent2(false)
        setTabContent3(false)
        setTabContent4(false)
        setTabContent5(true)
    }
    // {/* *******************Tab****************** */}

    //***************{Enter Btn Click}***************//
    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);
    const input4Ref = useRef(null);
    const input5Ref = useRef(null);
    const input6Ref = useRef(null);
    const input7Ref = useRef(null);
    const input8Ref = useRef(null);
    const input9Ref = useRef(null);
    const input10Ref = useRef(null);
    const input11Ref = useRef(null);
    const input12Ref = useRef(null);
    const input13Ref = useRef(null);
    const input14Ref = useRef(null);
    const input15Ref = useRef(null);
    const input16Ref = useRef(null);
    const input17Ref = useRef(null);
    const input18Ref = useRef(null);
    const input19Ref = useRef(null);

    const handleKeyPress = (event, nextInputRef) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            nextInputRef.current.focus();
        }
    };

    //************************Show Hide Start***********************//

    const [isInputVisiblam1, setInputVisiblam1] = useState(false);
    const handleToggleChangam1 = () => {
        setInputVisiblam1(!isInputVisiblam1);
    };
    //*************************Show Hide End***********************//

    //**************************modal box Start**********************//

    const [isModalOpen, setModalOpen] = useState(false);

    const showModal = () => {
        setModalOpen(true);
    };

    const hideModal = () => {
        setModalOpen(false);
    };

    //****************************modal box End******************************//


    return (
        <div>
            < PageTabs />
            <div className="panel-heading">
                <div className="panel-head-title">
                    <h2 className="panel-title">Loyalty Master Setting</h2>
                </div >
                {/* *******************Save Button Start****************** */}

                {/* *******************Top Buttons****************** */}
                <MainButton />

                {/* *******************Modal Box Open****************** */}
                <div>

                    {isModalOpen && (
                        <div className="modal" >
                            <div className="modal-content">
                                <div className="modal-title">
                                    <h3 className="modal-h">Bill Sundry Master</h3>
                                    <span className="close" onClick={hideModal}>
                                        &times;
                                    </span>
                                </div>
                                <div className="modal-content-im">
                                    <div className="">
                                        <div className="input-box-m1 modal-1">
                                            <div className="cl-toggle-switch">
                                                <label className="cl-switch">
                                                    <input type="checkbox" onChange={handleToggleChangam1} checked={isInputVisiblam1} />
                                                    <span></span>
                                                </label>
                                                <label className="modify_p_text2">Address Details<i className="fa-solid fa-circle-info ml-10"></i></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div>
                < DateTime />
            </div>
            <div className="content_box">
                <div className="company-info-top-im">
                    <div className="tab-head">
                        <div id="tabs">
                            <div className="tab-btn-box">
                                <div
                                    className={`tab1 ${activeTab === 5 ? 'active' : ''}`}
                                    onClick={() => { handleTabClick(5); showtabs1() }} >
                                    Membership Card Setting
                                </div>
                                <div
                                    className={`tab1 ${activeTab === 1 ? 'active' : ''}`}
                                    onClick={() => { handleTabClick(1); showtabs2() }} >
                                    Referal Code Setting
                                </div>
                                <div
                                    className={`tab1 ${activeTab === 2 ? 'active' : ''}`}
                                    onClick={() => { handleTabClick(2); showtabs3() }} >
                                    Loyalty Award Occassion
                                </div>
                                <div
                                    className={`tab1 ${activeTab === 3 ? 'active' : ''}`}
                                    onClick={() => { handleTabClick(3); showtabs4() }} >
                                    Tier Loyalty Award Setting
                                </div>
                                <div
                                    className={`tab1 ${activeTab === 4 ? 'active' : ''}`}
                                    onClick={() => { handleTabClick(4); showtabs5() }} >
                                    Membership Account
                                </div>
                            </div>
                            <div className="tab-content-box">
                                {tabcontent1 && (
                                    <div className="tab-content-1">
                                        <div className="company-info-top-left" >
                                            <div className="company_info-text-im gd-1">Loyalty Setting
                                            </div>
                                            <div className="">
                                                <div className="input-box-Im input-height2">
                                                    <div className="input-box-m1">
                                                        <select className="modify_im_input mode_category"
                                                            ref={input1Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input2Ref)} type="text" required>
                                                            <option>Select All</option>
                                                            <option>Transaction</option>
                                                            <option>Product Type</option>
                                                            <option>Product Categoty</option>
                                                            <option>On Part Payment</option>
                                                        </select>
                                                        <label className="modify_lbl_text3">Loyalty Award</label>
                                                    </div>
                                                    <div className="input-box-m1">
                                                        <select className="modify_im_input mode_category" ref={input2Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input3Ref)} type="text" required>
                                                            <option>No Expire</option>
                                                            <option>Use Expire Date</option>
                                                            <option>Expire Date from Transaction</option>
                                                        </select>
                                                        <label className="modify_lbl_text2">Loyalty Expire</label>
                                                    </div>
                                                </div>
                                                <div className="company_info-text-im gd-1">Loyalty Point Convert
                                            </div>
                                                <div className="input-box-Im input-height2">
                                                    <div className="input-box-m1">
                                                        <input className="modify_im_input mode_category"
                                                            ref={input3Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input4Ref)}
                                                            type="number" required />
                                                        <label className="modify_p_text2">Loyalty Point value</label>
                                                    </div>
                                                    <div className="input-box-m1">
                                                        <select className="modify_im_input mode_limit mode_category"
                                                            ref={input4Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input5Ref)}
                                                            type="text" required>
                                                            <option>Taxable Value</option>
                                                            <option>Gross Value</option>
                                                        </select>
                                                        <label className="modify_lbl_text4">Calculate loyalty on</label>
                                                    </div>
                                                </div>
                                                <div className="input-box-Im input-height2">
                                                    <div className="input-box-m1">
                                                        <label className="modify_p_text2" style={{ color: "red" }}>Ex. 10 Loyalty Point Value = 1 currency (Rs., & etc)</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="company-info-top-right" style={{ width: '50%' }} >
                                            <div className="company_info-text-im">Loyalty Details</div>
                                            <div className="stock-im-1">
                                                <div className="input-box-Im input-height2">
                                                    <div className="input-box-m1">
                                                        <select className="modify_im_input mode_category"
                                                            ref={input5Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input6Ref)}
                                                            type="text" required>
                                                            <option>Select All</option>
                                                            <option>Referal</option>
                                                            <option>Full Payment</option>
                                                            <option>Cash on delivery</option>
                                                            <option>Cash or online Payment</option>
                                                        </select>
                                                        <label className="modify_lbl_text3">Loyalty Credit Rule</label>
                                                    </div>
                                                    <div className="input-box-m1">
                                                        <input className="modify_im_input mode_limit mode_category"
                                                            ref={input6Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input7Ref)}
                                                            type="number" required />
                                                        <label className="modify_lbl_text2">No of Day Expire after Transaction</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="input-box-Im input-height2">
                                                <div className="input-box-m1">
                                                    <input className="modify_im_input mode_category"
                                                        ref={input7Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input8Ref)}
                                                        type="number" required />
                                                    <label className="modify_p_text2">Loyalty Amount against point</label>
                                                </div>
                                                <div className="input-box-m1">
                                                
                                                    <select className="modify_im_input mode_limit mode_category"
                                                        ref={input8Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input9Ref)}
                                                        type="text" required>
                                                        <option>Yes</option>
                                                        <option>No</option>
                                                    </select>
                                                    <label className="modify_lbl_text4">Update loyalty point while update voucher</label>
                                                </div>
                                            </div>
                                            <div className="input-box-Im input-height2">
                                                <div className="input-box-m1">
                                                    <select className="modify_im_input mode_category"
                                                        ref={input9Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input10Ref)}
                                                        type="text" required>
                                                        <option>Same Store</option>
                                                        <option>All Store</option>
                                                        <option>All Store With Online Portal</option>
                                                    </select>
                                                    <label className="modify_lbl_text3">Loyalty Used (membership card)</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                                }
                                {tabcontent2 && (
                                    <div className="tab-content-2 mmd">
                                        <div className="company-info-top-left" >
                                            <div className="company_info-text-im gd-1">Loyalty Setting
                                            </div>
                                            <div className="">
                                                <div className="input-box-Im input-height2">
                                                    <div className="input-box-m1">
                                                        <input className="modify_im_input mode_category"
                                                            ref={input10Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input11Ref)}
                                                            type="number" required />
                                                        <label className="modify_p_text2">Referal loyalty Point Value</label>
                                                    </div>
                                                    <div className="input-box-m1">
                                                        <select className="modify_im_input mode_limit mode_category"
                                                            ref={input11Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input12Ref)}
                                                            type="text" required>
                                                            <option>Card User</option>
                                                            <option>Refferal User</option>
                                                        </select>
                                                        <label className="modify_lbl_text4">Referal loyalty Point Earning By</label>
                                                    </div>
                                                </div>
                                                <div className="input-box-Im input-height2">
                                                    
                                                    <div className="input-box-m1">
                                                        <select className="modify_im_input mode_category"
                                                            ref={input12Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input13Ref)}
                                                            type="text" required>
                                                            <option>None</option>
                                                            <option>Applicable</option>
                                                            <option>Not Applicable</option>
                                                        </select>
                                                        <label className="modify_lbl_text3">Loyalty Amt Use On Category</label>
                                                    </div>
                                                   
                                                </div>
                                                <div className="input-box-Im input-height2">
                                                  
                                                </div>
                                            </div>
                                            <div className="company_info-text-im gd-1">Category List
                                            </div>
                                            <div className="input-box-Im input-height2">
                                                <div className="input-box-m1">
                                                    <select className="modify_im_input mode_category"
                                                        ref={input13Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input14Ref)}
                                                        type="text" required>
                                                        <option>None</option>
                                                        <option>Applicable</option>
                                                        <option>Not Applicable</option>
                                                    </select>
                                                    <label className="modify_lbl_text3">Loyalty Amt Use On Item</label>
                                                </div>
                                            </div>

                                            <div className="company_info-text-im gd-1">Item List
                                            </div>
                                            <div className="input-box-Im input-height2">
                                                <div className="input-box-m1">
                                                   
                                                    <label className="modify_p_text2" style={{ color: "red" }}>This option is valid for APP Based user only</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="company-info-top-right" style={{ width: '50%' }} >
                                            <div className="company_info-text-im">Loyalty Details</div>
                                            <div className="stock-im-1">
                                                <div className="input-box-Im input-height2">
                                                    <div className="input-box-m1">
                                                        <select className="modify_im_input mode_category"
                                                            ref={input14Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input15Ref)}
                                                            type="text" required>
                                                            <option>None</option>
                                                            <option>After First Transaction</option>
                                                            <option>Before Transaction</option>
                                                        </select>
                                                        <label className="modify_lbl_text3">Referal loyalty Point Earning</label>
                                                    </div>
                                                    <div className="input-box-m1">
                                                        <input className="modify_im_input mode_limit mode_category"
                                                            ref={input15Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input16Ref)}
                                                            type="number" required />
                                                        <label className="modify_lbl_text2">Point Use In Trans % wise</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="input-box-Im input-height2">
                                                <div className="input-box-m1">
                                                    <input className="modify_im_input mode_category"
                                                        ref={input16Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input17Ref)}
                                                        type="text" required />
                                                    <label className="modify_p_text2">Category</label>
                                                </div>
                                             
                                                <div className="input-box-m1">
                                                    <button className="stock_search">Add </button>
                                                    <button className="stock_set">Remove All </button>
                                                </div>

                                            </div>
                                            <div className="input-box-Im input-height2">
                                                
                                                <div className="input-box-m1">
                                                    <input className="modify_im_input mode_category"
                                                        ref={input17Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input18Ref)}
                                                        type="text" required />
                                                    <label className="modify_p_text2">Items</label>
                                                </div>
                                                <div className="input-box-m1">
                                                    <button className="add_btn1 stock_search">Add </button>
                                                    <button className="remove_btn stock_set">Remove All </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                                }
                                {tabcontent3 && (
                                    <div className="tab-content-3 mmd">
                                        <div className="company-info-top-left" >
                                            <div className="company_info-text-im gd-1">Loyalty Award occassion setting
                                            </div>
                                            <div className="">
                                                <div className="input-box-Im input-height2">
                                                    <div className="input-box-m1">
                                                        <select className="modify_im_input mode_category"
                                                            ref={input18Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input19Ref)}
                                                            type="text" required>
                                                            <option>All Time</option>
                                                            <option>Specific Occaussion</option>
                                                            <option>Specific Occaussion Day in Week</option>
                                                        </select>
                                                        <label className="modify_lbl_text3">Loyalty award occassion setting</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="company-info-top-right" style={{ width: '50%' }} >
                                           
                                            <div className="stock-im-1">
                                            </div>
                                        </div>
                                    </div>
                                )
                                }
                                {tabcontent4 && (
                                    <div className="tab-content-4">
                                        <div className="">
                                            <div className="company-info-top-im">
                                                <div className="company-info-top-left" >
                                                    <div className="company_info-text-im">Tier Loyalty point award setting</div>
                                                    <table className="table-t" >
                                                        <thead className="thead-groupmaster">
                                                            <tr>
                                                                <th className="table-th" style={{ width: "15%" }}>Limit Upto</th>
                                                                <th className="table-th" style={{ width: "28%" }}>Transaction Currency Value</th>
                                                                <th className="table-th" style={{ width: "28%" }}>Currency Units</th>
                                                                <th className="table-th" style={{ width: "28%" }}>Loyalty award Points against Currency Units</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {rows.map((row, rowIndex) => (
                                                                <tr key={rowIndex} >
                                                                    <td style={{ textAlign: "center" }} >
                                                                        <input type="checkbox" name="" id="" />
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            className="table-input-12"
                                                                            type="number"
                                                                            name={`changeFrom-${rowIndex}`}
                                                                        // value={row[`changeFrom-${rowIndex}`] || ""}
                                                                        // onChange={(e) => handleInputChange(rowIndex, e)}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            className="table-input-12"
                                                                            type="number"
                                                                            name={`taxCatg1-${rowIndex}`}
                                                                        // value={row[`taxCatg1-${rowIndex}`] || ""}
                                                                        //onChange={(e) => handleInputChange(rowIndex, e)}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            className="table-input-12"
                                                                            type="number"
                                                                            name={`range-${rowIndex}`}
                                                                        //  value={row[`range-${rowIndex}`] || ""}
                                                                        // onChange={(e) => handleInputChange(rowIndex, e)}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    <div onClick={handleAddRow}></div>
                                                    <div className="input-box-Im input-height2">
                                                        <div className="input-box-m1">
                                                            <label className="modify_p_text2" style={{ color: "red" }}>*Fill atleast one row, it is neccessory for calculate loyalty amt.*</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                                }
                                {tabcontent5 && (
                                    <div className="tab-content-5 ">
                                        <div className="mmd">
                                            <div className="company-info-top-left" >
                                                <div className="company_info-text-im gd-1">Membership Card
                                                </div>
                                                <div className="">
                                                    <div className="input-box-Im input-height2">
                                                        <div className="input-box-m1">
                                                            <input className="modify_im_input mode_category" type="text" required />
                                                            <label className="modify_p_text2">MemberShip Card Id</label>
                                                        </div>
                                                        <div className="input-box-m1">
                                                            <select className="modify_im_input mode_limit mode_category"
                                                                type="text" required>
                                                                <option>1 Star</option>
                                                                <option>2 Star</option>
                                                                <option>3 Star</option>

                                                            </select>
                                                            <label className="modify_lbl_text4">Card Types</label>
                                                        </div>
                                                    </div>
                                                    <div className="input-box-Im input-height2">
                                                        <div className="input-box-m1">
                                                            <input className="modify_im_input mode_category" type="date" required />
                                                            <label className="modify_lbl_text3">Registration Date</label>
                                                        </div>
                                                        <div className="input-box-m1">
                                                            <input className="modify_im_input mode_limit mode_category" type="number" required />
                                                            <label className="modify_lbl_text2">Initial Amount</label>
                                                        </div>
                                                    </div>

                                                    <div className="input-box-Im input-height2">
                                                        <div className="input-box-m1">
                                                            <select className="modify_im_input mode_category" type="text" required>
                                                                <option>Only Card Owner</option>
                                                                <option>Associate Member</option>
                                                            </select>
                                                            <label className="modify_lbl_text3">Redemption Amount By</label>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="company-info-top-right" style={{ width: '50%' }} >
                                                <div className="company_info-text-im">Loyalty Details</div>
                                                <div className="stock-im-1">
                                                    <div className="input-box-Im input-height2">
                                                        <div className="input-box-m1">
                                                            <select className="modify_im_input mode_category" type="text" required>
                                                                <option>Individual User</option>
                                                                <option>Group User</option>
                                                            </select>
                                                            <label className="modify_lbl_text3">Card Use By</label>
                                                        </div>
                                                        <div className="input-box-m1">
                                                            <input className="modify_im_input mode_limit mode_category" type="text" required />
                                                            <label className="modify_lbl_text2">Card Owner</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="input-box-Im input-height2">
                                                    <div className="input-box-m1">
                                                        <input className="modify_im_input mode_category" type="date" required />
                                                        <label className="modify_lbl_text3">Card Expiry Date</label>
                                                    </div>
                                                    <div className="input-box-m1">
                                                        <input className="modify_im_input mode_limit mode_category" type="text" required />
                                                        <label className="modify_lbl_text2">Total Amount</label>
                                                    </div>
                                                </div>
                                                <div className="input-box-Im input-height2">
                                                <div className="input-box-m1">
                                                    <label className="modify_p_text2" style={{ color: "black", fontSize:"18px" }}>Associate Card Member</label>
                                                </div> 
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                                }
                                <div className="tab-content-1">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="buttom-box">
                        <div className="button-btn-1">
                            <button className="btn-1">Save</button>
                        </div>
                        <div className="button-btn-2">
                            <button className="btn-2">Reset</button>
                        </div>
                        <div className="button-btn-3">
                            <button className="btn-3" onClick={showModal}><i className="fa-solid fa-gear "></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}


export default LoyaltyMasterSetting;
