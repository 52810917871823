import { useEffect, useState, useContext } from "react";
import Order from "../../API/APIServices/Order";
import UserCredential from "../../API/APIServices/UserCredential";
import Loader from "../../Inventory/Props/Loader";
import moment from 'moment';
import MyContext from "../../Inventory/MyContext";

const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const DeliveryBoyTrackingReport = () => {

  useState(() => {
    document.title = 'Delivery Boy Tracking Report';
  }, []);

  const { isShowTotalAmountInReport } = useContext(MyContext);
  console.log("isShowTotalAmountInReport", isShowTotalAmountInReport)

  const [loading, setLoading] = useState(false);

  const getCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    let month = now.getMonth() + 1;
    let day = now.getDate();

    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;

    return `${year}-${month}-${day}`;
  };

  const [trackingReport, setTrackingReport] = useState();

  useEffect(() => {
    const fetchTripData = async () => {
      setLoading(true);
      try {
        let filteredData = [{
          fieldName: "string",
          operatorName: "string",
          compareValue: "string"
        }];

        const storedArrayString = localStorage.getItem('loginAccountUserData');
        let loginUser;
        console.log(storedArrayString)
        if (storedArrayString) {
          loginUser = JSON.parse(storedArrayString);
          console.log(loginUser)
          console.log(loginUser.userId)
          if (loginUser.loginType == 562) {
            setDeliveryBoyId(loginUser.userId)
            filteredData = [
              {
                fieldName: "delBoyId",
                operatorName: "Equal",
                compareValue: loginUser.userId
              },
              {
                fieldName: "date",
                operatorName: "Equal",
                compareValue: getCurrentDate(),
              }
            ]
          }
          else {
            filteredData = [
              // {
              //   fieldName: "string",
              //   operatorName: "string",
              //   compareValue: "string"
              // },
              {
                fieldName: "date",
                operatorName: "Equal",
                compareValue: getCurrentDate(),
              }
            ]
          }
        }
        const data = await Order.getDeliveryBoyTrip(filteredData)
        setTrackingReport(data);
        fetchData(data);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    }
    fetchTripData();;
  }, []);

  function convertTo12HourFormat(twentyFourHourTime) {
    if (!twentyFourHourTime) {
      return "";
    }
    const [hours, minutes] = twentyFourHourTime.split(':').map(Number);

    if (isNaN(hours) || isNaN(minutes)) {
      return "";
    }

    let period = 'AM';
    let twelveHourHours = hours;

    if (hours >= 12) {
      period = 'PM';
      if (hours > 12) {
        twelveHourHours -= 12;
      }
    }
    if (twelveHourHours === 0) {
      twelveHourHours = 12;
    }
    return `${twelveHourHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period}`;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if needed
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const imageUrl = `${WebApiUrl}/api/Order/TripImage?imageId=`;

  const timeFormat = (twentyFourHourTime) => {
    const [startTime, endTime] = twentyFourHourTime.split('-');
    const convertedStartTime = convertTo12HourFormat(startTime.trim());
    const convertedEndTime = convertTo12HourFormat(endTime.trim());
    const convertedTimeRange = `${convertedStartTime} - ${convertedEndTime}`;
    return convertedTimeRange;
  }
  const [deliveryBoy, setDeliveryBoy] = useState({});

  useEffect(() => {
    const fetchDeliveryBoy = async () => {
      setLoading(true);
      const filterData = [
        {
          fieldName: "userRole",
          operatorName: "equal",
          compareValue: 562,
        },
      ];

      const deliveryBoyData = await UserCredential.getFilteredUserCredential(
        filterData
      );
      if (deliveryBoyData) {
        setDeliveryBoy(deliveryBoyData);
        console.log(deliveryBoyData);
      } else {
        alert("No delivery boy found");
      }
      setLoading(false)
    };

    fetchDeliveryBoy();
  }, []);

  const [deliveryBoyId, setDeliveryBoyId] = useState("default");
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  useEffect(() => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    setFromDate(`${year}-${month}-${day}`);
    setToDate(`${year}-${month}-${day}`);
    // return `${year}-${month}-${day}`;
  }, [])

  const onDeliveryBoyChange = async (e) => {
    setDeliveryBoyId(e.target.value)
    if (e.target.value === "default") {
      const filterData = [
        {
          fieldName: "string",
          operatorName: "string",
          compareValue: "string",
        },
      ];
      try {
        const data = await Order.getDeliveryBoyTrip(filterData);
        setTrackingReport(data);
        fetchData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      const filterData = [
        {
          fieldName: "delBoyId",
          operatorName: "Equal",
          compareValue: e.target.value,
        },
        {
          fieldName: "date",
          operatorName: "GreaterOrEqualThan",
          compareValue: fromDate,
        },
        {
          fieldName: "date",
          operatorName: "LessOrEqualThan",
          compareValue: toDate,
        },
      ];
      try {
        const data = await Order.getDeliveryBoyTrip(filterData);
        setTrackingReport(data);
        fetchData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const onFromDateChange = async (e) => {
    setFromDate(e.target.value);
    const filterData = [
      {
        fieldName: "date",
        operatorName: "GreaterOrEqualThan",
        compareValue: e.target.value,
      },
      {
        fieldName: "date",
        operatorName: "LessOrEqualThan",
        compareValue: toDate,
      },
    ];
    if (deliveryBoyId != "default") {
      filterData.push(
        {
          fieldName: "delBoyId",
          operatorName: "Equal",
          compareValue: deliveryBoyId,
        },
      )
    }
    try {
      const data = await Order.getDeliveryBoyTrip(filterData);
      setTrackingReport(data);
      fetchData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const onToDateChange = async (e) => {
    setToDate(e.target.value);
    const filterData = [
      {
        fieldName: "date",
        operatorName: "GreaterOrEqualThan",
        compareValue: fromDate,
      },
      {
        fieldName: "date",
        operatorName: "LessOrEqualThan",
        compareValue: e.target.value,
      },
    ];
    if (deliveryBoyId != "default") {
      filterData.push(
        {
          fieldName: "delBoyId",
          operatorName: "Equal",
          compareValue: deliveryBoyId,
        },
      )
    }
    try {
      const data = await Order.getDeliveryBoyTrip(filterData);
      setTrackingReport(data);
      fetchData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const handleBackBtn = () => {
    window.history.back();
  }

  const [orderTotalAmt, setOrderTotalAmt] = useState(0);
  const [incentiveAmt, setIncentiveAmt] = useState(0);

  const fetchData = (trackingReport) => {
    const uniqueTrips = trackingReport.filter((data, index, self) =>
      index === self.findIndex((t) => (
        t.tripNo === data.tripNo
      ))
    );
    const total = trackingReport.reduce((sum, data) => sum + (data.totalAmt), 0);
    setOrderTotalAmt(total.toFixed(2));

    const incentAmt = uniqueTrips.reduce((sum, data) => sum + (data.tripIncentiveAmt), 0);
    setIncentiveAmt(incentAmt.toFixed(2));
  }

  return (
    <div>
      <div className="panel-heading">
        <i className="fa-solid fa-arrow-left icon-white display-none-500" onClick={handleBackBtn} style={{ color: "white", cursor: "pointer", paddingLeft: "15px" }} ></i>
        <h2 className="panel-title" style={{ width: "150%" }}>Order Wise Delivery Tracking Report</h2>
      </div>
      <div
        className="scroll-container"
        style={{ height: "98vh", padding: "10px" }}>
        {/* ************** */}
        <div className="">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <label
              className="fw-600 display-none-on-500"
              style={{
                fontSize: "18px",
                // width: "150px",
                textAlign: "center",
                textDecoration: "underline",
              }}
            >
              ORDER WISE DELIVERY BOY TRACKING REPORT :-
            </label>
            <div className="input-containerEffect display-none-on-500" style={{ margin: "1%" }}>
              <select
                className="modify_im_input mode_limit mode_category"
                onChange={onDeliveryBoyChange}
                style={{ width: "max-content" }}
                value={deliveryBoyId}
              >
                <option value="default">Select Delivery Boy</option>
                {(() => {
                  if (deliveryBoy !== null && Array.isArray(deliveryBoy)) {
                    return deliveryBoy.map((data, index) => (
                      <option key={index} value={data.id}>
                        {data.userName}
                      </option>
                    ));
                  }
                })()}
              </select>
            </div>
            <div className="input-containerEffect mt-3" style={{ margin: "1%" }}>
              <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" value={fromDate} type="date"
                onChange={onFromDateChange} name="orderDate" required />
              <label className="labelEffect1">From Date</label>
            </div>
            <div className="input-containerEffect mt-3" style={{ margin: "1%" }}>
              <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" value={toDate} type="date"
                onChange={onToDateChange} name="orderDate" required />
              <label className="labelEffect1">To Date</label>
            </div>
          </div>
        </div>
        {/* ************** */}
        <div className="mt-2">
          {/****************** Table Order ****************/}
          <div className="table-resposive tb-scroll-width" id="tableorder" style={{ display: "block", padding: "0px", height: "510px" }}>
            <table className="table_ord_mod table-bordered1 table-width-cancel">
              <thead className="table_headerSticky thead_scroll_orderlist ">
                <tr>
                  <th className="td_col6 td-padding-sn"><div class="table_head_mmb1" style={{ textAlign: "center" }}>S.No</div></th>
                  <th className="td_col6 td-padding-sn"><div class="table_head_mmb1" style={{ textAlign: "center" }}>Order Date</div></th>
                  <th className="td_col6 td-padding-sn"><div class="table_head_mmb1" style={{ textAlign: "center" }}>Order No</div></th>
                  <th className="td_col6 td-padding-sn"><div class="table_head_mmb1" style={{ textAlign: "center" }}>Customer Name</div></th>
                  {/* <th className="td_col6 td-padding-sn">Mobile&nbsp;No.</th> */}
                  <th className="td_col6 td-padding-sn"><div class="table_head_mmb1" style={{ textAlign: "center" }}>Total Amt.</div></th>
                  <th className="td_col6 td-padding-sn"><div class="table_head_mmb1" style={{ textAlign: "center" }}>Time&nbsp;Slot</div></th>
                  <th className="td_col6 td-padding-sn"><div class="table_head_mmb1" style={{ textAlign: "center" }}>Kot Dis. Target Time</div></th>
                  <th className="td_col6 td-padding-sn"><div class="table_head_mmb1" style={{ textAlign: "center" }}>Kot Dis. time</div></th>
                  <th className="td_col6 td-padding-sn"><div class="table_head_mmb1" style={{ textAlign: "center" }}>Delivery Dis. Time</div></th>
                  <th className="td_col6 td-padding-sn"><div class="table_head_mmb1" style={{ textAlign: "center" }}>Delivery Boy</div></th>
                  <th className="td_col6 td-padding-sn"><div class="table_head_mmb1" style={{ textAlign: "center" }}>Del Boy Trip No</div></th>
                  <th className="td_col6 td-padding-sn"><div class="table_head_mmb1" style={{ textAlign: "center" }}>Del Boy Incentive Amt</div></th>
                  <th className="td_col6 td-padding-sn"><div class="table_head_mmb1" style={{ textAlign: "center" }}>Trip Start Time</div></th>
                  <th className="td_col6 td-padding-sn"><div class="table_head_mmb1" style={{ textAlign: "center" }}>Del Pref No</div></th>
                  <th className="td_col6 td-padding-sn"><div class="table_head_mmb1" style={{ textAlign: "center" }}>Delivered Time</div></th>
                  <th className="td_col6 td-padding-sn"><div class="table_head_mmb1" style={{ textAlign: "center" }}>Payment Done Time</div></th>
                  <th className="td_col6 td-padding-sn"><div class="table_head_mmb1" style={{ textAlign: "center" }}>Trip Close Date</div></th>
                  <th className="td_col6 td-padding-sn"><div class="table_head_mmb1" style={{ textAlign: "center" }}>Trip Close Time</div></th>
                  <th className="td_col6 td-padding-sn"><div class="table_head_mmb1" style={{ textAlign: "center" }}>Trip Opening Km</div></th>
                  <th className="td_col6 td-padding-sn"><div class="table_head_mmb1" style={{ textAlign: "center" }}>Trip Opening Km Img</div></th>
                  <th className="td_col6 td-padding-sn"><div class="table_head_mmb1" style={{ textAlign: "center" }}>Trip Closing Km</div> </th>
                  <th className="td_col6 td-padding-sn"><div class="table_head_mmb1" style={{ textAlign: "center" }}>Trip Closing Km Img</div></th>
                </tr>
              </thead>
              <tbody className="tbody_body_m1">
                {(() => {
                  if (trackingReport !== null && Array.isArray(trackingReport)) {
                    return trackingReport
                      .slice().reverse()
                      .map((data, rowIndex) => {
                        return (
                          <tr className="table_tbody_Order_List"
                            key={rowIndex}
                            style={{ cursor: "pointer" }}
                          >
                            <td className="td_col6 td-padding-sn" style={{ width: "3%", textAlign: "center" }}> {rowIndex + 1}
                              {/* <button style={{ padding: "5px 10px" }}>{rowIndex + 1}</button> */}
                            </td>
                            <td className="td_col6 td-padding-sn" style={{ width: "7%", textAlign: "center" }} >{formatDate(data.date)} </td>
                            <td className="td_col6 td-padding-sn" style={{ width: "7%", textAlign: "center" }}>{data.orderNo}</td>
                            <td className="td_col6 td-padding-sn" style={{ width: "8%", textAlign: "center" }}> {data.delName} <br />{data.delMobile}</td>
                            <td className="td_col6 td-padding-sn" style={{ width: "12%", textAlign: "center" }}>₹&nbsp;{data.totalAmt}</td>
                            <td className="td_col6 td-padding-sn" style={{ width: "8%", textAlign: "center" }}>
                              {timeFormat(data.deliveryTimeSlot)}
                            </td>
                            <td className="td_col6 td-padding-sn" style={{ width: "8%", textAlign: "center" }}>{data.kotDispatchTargetTime == "00:00:00" ? "00:00:00" : convertTo12HourFormat(data.kotDispatchTargetTime)}</td>
                            <td className="td_col6 td-padding-sn">{data.kotDispatchTime == "00:00:00" ? "00:00:00" : convertTo12HourFormat(data.kotDispatchTime)}</td>
                            <td className="td_col6 td-padding-sn">{data.deliveryBoyDispatchTime == "00:00:00" ? "00:00:00" : convertTo12HourFormat(data.deliveryBoyDispatchTime)}</td>
                            <td className="td_col6 td-padding-sn">{data.delBoyName}</td>
                            <td className="td_col6 td-padding-sn">{data.tripNo}</td>
                            <td className="td_col6 td-padding-sn">{data.orderIncentiveAmt}</td>
                            <td className="td_col6 td-padding-sn">{data.openingTime == "00:00:00" ? "00:00:00" : convertTo12HourFormat(data.openingTime)}</td>
                            <td className="td_col6 td-padding-sn">{data.deliveryPreferenceNo} </td>
                            <td className="td_col6 td-padding-sn">{convertTo12HourFormat(data.deliveredTime)}</td>
                            <td className="td_col6 td-padding-sn">{moment.utc(data.paymentDoneOn).utcOffset("+05:30").format('DD-MM-YYYY hh:mm A')}</td>
                            <td className="td_col6 td-padding-sn">{formatDate(data.closingDate)}</td>
                            <td className="td_col6 td-padding-sn">{data.closingTime == "00:00:00" ? "00:00:00" : convertTo12HourFormat(data.closingTime)}</td>
                            <td className="td_col6 td-padding-sn">{data.openingKM}</td>
                            <td className="td_col6 td-padding-sn">
                              <img className="hover-enlarge-image" style={{ display: data.openingKMImg != null && data.openingKMImg != undefined && data.openingKMImg != 0 ? "block" : "none" }}
                                src={`${imageUrl}${data.openingKMImg}`} alt=""></img>
                            </td>
                            <td className="td_col6 td-padding-sn">{data.closingKM}</td>
                            <td className="td_col6 td-padding-sn">
                              <img className="hover-enlarge-image" style={{ display: data.closingKMImg != null && data.closingKMImg != undefined && data.closingKMImg != 0 ? "block" : "none" }}
                                src={`${imageUrl}${data.closingKMImg}`} alt=""></img>
                            </td>
                          </tr>
                        );
                      });
                  }
                })()}
                {isShowTotalAmountInReport && (
                  <tr className="table_tbody_Order_List"
                    style={{ cursor: "pointer", backgroundColor: "ghostWhite" }} >
                    <td className="td_col6 td-padding-sn td_footer" colSpan={4} style={{ textAlign: "end" }}>Order Amt: &nbsp;</td>
                    <td className="td_col6 td-padding-sn td_footer" colSpan={1}>₹&nbsp;{orderTotalAmt == isNaN ? 0 : orderTotalAmt}</td>
                    <td className="td_col6 td-padding-sn td_footer" colSpan={6} style={{ textAlign: "end" }}>Incentive Amt: &nbsp;</td>
                    <td className="td_col6 td-padding-sn td_footer" colSpan={1}>{incentiveAmt}</td>
                    <td className="td_col6 td-padding-sn td_footer" colSpan={10}></td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>


      {loading && <Loader />}
    </div>
  );
};

export default DeliveryBoyTrackingReport;
