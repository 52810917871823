import DateTime from "../../Components/DateTime";
import PageTabs from "../../Components/PageTabs";
import React, { useState, useRef, useEffect, useContext } from "react";
import Joyride from "react-joyride";
import "react-quill/dist/quill.snow.css";
import ImageUploadWithPreview from "../../Components/ImagePreview";
import BatchWiseModalBox from "../../Props/BatchWiseModalBox";
import ItemAttributeList from "../../Props/ItemAttributeList";
import MainButton from "../../Props/MainButton";
import ModalBoxMaster from "../../Props/ModalBoxMaster";
import PriceList from "../../Props/PriceList";
import ItemWiseBatchList from "../../Props/ItemWiseBatchList";
import MultiGodown from "../../Props/MultiGodown";
import ToolTipMaster from "../../Props/ToolTipMaster";
import TabContent7 from "../../Props/TabContent7";
import TabContent8 from "../../Props/TabContent8";
import CategoryDropdown from "../../Props/Dropdown/CategoryDropdown";
import ItemMasterApi from "../.././../API/APIServices/ItemMaster";
import UnitMasterApi from "../../../API/APIServices/UnitMaster";
import ItemDepartmentApi from "../../../API/APIServices/ItemDepartment";
import MiscellaneousMaster from "../../../API/APIServices/MiscellaneousMaster";
import MealCourse from "../../../API/APIServices/MealCourse";
import AlertBox from "../../Props/AlertBox";
import Loader from "../../Props/Loader";
import MyContext from "../../MyContext";
import TaxCategoryApi from "../../../API/APIServices/TaxCategory";
import ItemGroupAPI from "../../../API/APIServices/ItemGroup";
import CompanyApi from "../../../API/APIServices/Company";
import AlertBoxError from "../../Props/AlertBoxError";
import JoditEditor from "jodit-react";
import { useNavigate } from "react-router-dom";
import ProductVariation from "./ProductVariation";
import TabContent9 from "../../Props/TabContent9";
import MetaContentItemMaster from "../../Props/MetaContentItemMaster";
import ProductFilter from "../../Props/ProductFilter";
import RelatedProducts from "../../Props/RelatedProducts";
import LinkedProduct from "../../Props/LinkedProduct";
import LPProduct from "../../Props/LPProduct";

function ItemMaster(props, { onClick }) {
  console.log(props.ItemMasterList);

  console.log(props.variable)
  useEffect(() => {
    if (props.variable) {
      handleTabClick(5);
      showtabs1();
    }
  }, [props.variable]);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState("");
  const [AttributeListData, setAttributeListData] = useState([]);
  const [AccountGroupList, setAccountGroupList] = useState([]);
  const [UnitMasterList, setUnitMasterList] = useState([]);
  const [ItemDepartmentList, setItemDepartmentList] = useState([]);
  const [PreprationTimeList, setPreprationTimeList] = useState([]);
  const [MealCourseList, setMealCourseList] = useState([]);
  const [TaxCatgorList, setTaxCatgory] = useState([]);
  const [AttributeList, setAttributeList] = useState([]);
  const [commaSeparatedTags, setCommaSeparatedTags] = useState("");
  const [checkedItemIds, setCheckedItemIds] = useState([]);
  const [controlType] = useState(171);
  const [imageIds, setImageIds] = useState([]);
  const [CompanyDetail, setCompanyDetail] = useState({});
  const {
    OpenAlertBox,
    alertBox,
    OpenAlertBoxEdit,
    alertBoxEdit,
    OpenAlertBoxWhenItemExists,
    openAlertItemExists,
    alertBoxErrorUpdate,
    alertBoxErrorInsert,
    OpenAlertBoxErrorInsert,
    OpenAlertBoxErrorUpdate,
  } = useContext(MyContext);

  //***************{Enter Btn Click}***************//
  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);
  const input5Ref = useRef(null);
  const input6Ref = useRef(null);
  const input7Ref = useRef(null);
  const input8Ref = useRef(null);
  const input9Ref = useRef(null);
  const input10Ref = useRef(null);
  const input11Ref = useRef(null);
  const input12Ref = useRef(null);
  const input13Ref = useRef(null);
  const input14Ref = useRef(null);
  const input15Ref = useRef(null);
  const input16Ref = useRef(null);
  const input17Ref = useRef(null);
  const input20Ref = useRef(null);
  const input21Ref = useRef(null);
  const input24Ref = useRef(null);
  const input25Ref = useRef(null);
  const input27Ref = useRef(null);
  const input28Ref = useRef(null);
  const input29Ref = useRef(null);
  const input30Ref = useRef(null);
  const input31Ref = useRef(null);
  const input32Ref = useRef(null);
  const input33Ref = useRef(null);
  const input34Ref = useRef(null);
  const input35Ref = useRef(null);
  const input36Ref = useRef(null);
  const input37Ref = useRef(null);
  const input38Ref = useRef(null);
  const input39Ref = useRef(null);
  const input41Ref = useRef(null);
  const input42Ref = useRef(null);
  const input43Ref = useRef(null);
  const input44Ref = useRef(null);
  const input45Ref = useRef(null);
  const input46Ref = useRef(null);
  const input47Ref = useRef(null);
  const input48Ref = useRef(null);
  const input49Ref = useRef(null);
  const input50Ref = useRef(null);
  const input51Ref = useRef(null);
  const input52Ref = useRef(null);
  const input53Ref = useRef(null);
  const input54Ref = useRef(null);
  const input55Ref = useRef(null);
  const input56Ref = useRef(null);
  const input57Ref = useRef(null);
  const input58Ref = useRef(null);
  const input59Ref = useRef(null);
  const input60Ref = useRef(null);
  const input61Ref = useRef(null);
  const input62Ref = useRef(null);
  const input63Ref = useRef(null);
  const input64Ref = useRef(null);
  const input65Ref = useRef(null);
  const input66Ref = useRef(null);
  const input67Ref = useRef(null);
  const input68Ref = useRef(null);
  const input69Ref = useRef(null);
  const input70Ref = useRef(null);
  const input71Ref = useRef(null);
  const input72Ref = useRef(null);
  const input73Ref = useRef(null);
  const input74Ref = useRef(null);
  const input75Ref = useRef(null);
  const input76Ref = useRef(null);
  const input77Ref = useRef(null);
  const input79Ref = useRef(null);
  const input80Ref = useRef(null);
  const input81Ref = useRef(null);
  const input82Ref = useRef(null);
  const input83Ref = useRef(null);
  const input84Ref = useRef(null);
  const input85Ref = useRef(null);
  const input86Ref = useRef(null);
  const input87Ref = useRef(null);
  const input88Ref = useRef(null);
  const input89Ref = useRef(null);
  const input90Ref = useRef(null);
  const input91Ref = useRef(null);
  const input92Ref = useRef(null);
  const input93Ref = useRef(null);
  const input94Ref = useRef(null);
  const input95Ref = useRef(null);
  const input96Ref = useRef(null);
  const input97Ref = useRef(null);
  const input98Ref = useRef(null);
  const input99Ref = useRef(null);
  const input100Ref = useRef(null);
  const input101Ref = useRef(null);
  const input102Ref = useRef(null);
  const input103Ref = useRef(null);
  const input104Ref = useRef(null);
  const input105Ref = useRef(null);
  const input106Ref = useRef(null);
  const input107Ref = useRef(null);
  const input108Ref = useRef(null);
  const input109Ref = useRef(null);
  const input110Ref = useRef(null);
  const input111Ref = useRef(null);
  const input112Ref = useRef(null);
  const input113Ref = useRef(null);
  const input114Ref = useRef(null);
  const input115Ref = useRef(null);
  const input116Ref = useRef(null);
  const input117Ref = useRef(null);
  const input118Ref = useRef(null);
  const input119Ref = useRef(null);
  const input120Ref = useRef(null);
  const input121Ref = useRef(null);
  const input122Ref = useRef(null);
  const input123Ref = useRef(null);
  // const input124Ref = useRef(null);
  const input125Ref = useRef(null);
  const input126Ref = useRef(null);
  const input127Ref = useRef(null);
  const input128Ref = useRef(null);
  const input129Ref = useRef(null);
  const input130Ref = useRef(null);
  const input131Ref = useRef(null);
  const input132Ref = useRef(null);
  const input133Ref = useRef(null);
  const input134Ref = useRef(null);
  const input135Ref = useRef(null);
  const input136Ref = useRef(null);
  const input137Ref = useRef(null);
  const input138Ref = useRef(null);
  const input139Ref = useRef(null);
  const input140Ref = useRef(null);
  const input141Ref = useRef(null);
  const input142Ref = useRef(null);
  const input143Ref = useRef(null);
  const input144Ref = useRef(null);
  const input145Ref = useRef(null);
  const input146Ref = useRef(null);
  const input147Ref = useRef(null);
  const input148Ref = useRef(null);
  const input149Ref = useRef(null);
  const input150Ref = useRef(null);
  const input151Ref = useRef(null);
  const input152Ref = useRef(null);
  const input153Ref = useRef(null);
  const input154Ref = useRef(null);
  const input155Ref = useRef(null);
  const input156Ref = useRef(null);
  const input157Ref = useRef(null);
  const input158Ref = useRef(null);

  const input200Ref = useRef(null);
  const input201Ref = useRef(null);
  const input202Ref = useRef(null);
  const input203Ref = useRef(null);
  const input204Ref = useRef(null);
  const input205Ref = useRef(null);
  const input206Ref = useRef(null);
  const input207Ref = useRef(null);
  const input208Ref = useRef(null);
  const input209Ref = useRef(null);
  const input210Ref = useRef(null);
  const input211Ref = useRef(null);
  const input212Ref = useRef(null);

  const handleKeyPress = (event, nextInputRef) => {
    if (event.key === "Enter") {
      event.preventDefault();
      nextInputRef.current.focus();
    }
  };

  const [isMealCourse, setMealCourse] = useState(false);
  const [isModalOpen2, setModalOpen2] = useState(false);
  const [isDepartment, setDepartment] = useState(false);
  const [isModalOpenUnit, setModalOpenUnit] = useState(false);
  const [isModalOpenGroupName, setModalOpenGroupName] = useState(false);
  const [ItemGroupList, setItemGroupList] = useState([]);

  const editorSd = useRef(null);
  const editorLd = useRef(null);
  const [shortDiscription, setShortDiscription] = useState("");
  const [longDescription, setLongDescription] = useState("");

  useEffect(() => {
    console.log(shortDiscription);
  }, [shortDiscription]);

  /***********title***************/
  useState(() => {
    document.title = "Item Master";
  }, []);

  const getAttributeList = (data) => {
    //console.log("data attribute", data);
    // Check if all specified fields are empty
    if (
      data[0].qty === "" &&
      data[0].salePrice === "" &&
      data[0].mrp === "" &&
      data[0].purchasePrice === ""
    ) {
      setAttributeListData([]); // Clear the attribute list
    } else {
      setAttributeListData(data); // Set the attribute list with the data
    }
    //console.log("Attribute data in the", AttributeListData);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const arrayString = localStorage.getItem('loginUser');
        const arrayToStore = JSON.parse(arrayString);
        const data = await CompanyApi.GetCompanyById(arrayToStore.compId);
        setCompanyDetail(data)
        let fieldsToOpen = [];
        if (data.isEnableWebsite === true) {
          fieldsToOpen = fieldToBeOpen([data.businessTypes, 844]);
        } else {
          fieldsToOpen = fieldToBeOpen([data.businessTypes]);
        }
        setInputValues((prevValues) => ({
          ...prevValues,
          "businessType": data.businessTypes,
        }));
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const [isAttributeItemTF, setIsAttributeItemTF] = useState();

  useEffect(() => {
    try {
      if (Array.isArray(AttributeListData) && AttributeListData.length === 0) {
        setIsAttributeItemTF(false);
      } else {
        setIsAttributeItemTF(true);
      }
    } catch (error) {
      console.log(error);
    }
  }, [AttributeListData]);
  // console.log(isAttributeItemTF);

  const [updatedData, setUpdatedData] = useState();

  const [errorMessages, setErrorMessages] = useState({
    ProductName: "",
  });
  const fieldNames = ["ProductName"];

  useEffect(() => {
    try {
      var UpdateData1 =
        props.data && props.data.attributeList !== undefined
          ? props.data.attributeList
          : 0;
      setUpdatedData(UpdateData1);
    } catch (error) {
      console.log(error);
    }

    const fetchData = async () => {
      try {
        if (props.data) {
          const CatogoryData = props.data.ecomCategoryDetail;
          setCheckedItemIds(CatogoryData);
          const data = await props.data;
          setShortDiscription(props.data.shortDescription);
          setLongDescription(props.data.LongDescription);
          setSelectedOption(props.data.productType)
          // console.log(data.restoAvailableTime)

          if (data.restoAvailableTime.length === 0) {
            // If no data is available, set a default value or an empty array
            setRestoAvailableTime([
              { availableTimefrom: "", availableTimefrom: "" },
            ]);
          } else {
            // Use the provided data to set restoAvailableTime
            const formattedData = data.restoAvailableTime.map((time) => ({
              availableTimefrom: time.availableTimefrom || "", // Use lowercase 'f'
              availableTimeTo: time.availableTimeTo || "",
            }));
            // console.log(formattedData);
            setRestoAvailableTime(formattedData);
          }
          setCheckedItemIds(data.ecomCategoryDetail);
          setAttributeListData(data.attributeList);
          setCommaSeparatedTags(data.productTag);
          setImageIds(data.images);

          try {
            const data = await props.data; // Assuming props.editData is a Promise
            // console.log(data);
            const contactlens = await ItemMasterApi.GetContactLens(data.d2_Id);
            console.log(contactlens);
            setInputValues({
              D1_Id: data.d1_Id,
              D2_Id: data.d2_Id,
              IsFavorite: data.isFavorite,
              IsActive: data.isActive,
              businessType: data.businessType,
              ProductName: data.productName,
              ProductAlias: data.productAlias,
              PrintName: data.printName,
              ParentGrp: data.parentGrp2,
              ProductUnit: data.productUnit,
              ProductAltUnit: data.productAltUnit,
              UnitConvFactor: data.unitConvFactor,
              ItemDepartment: data.itemDepartment,
              ProductType: data.productType,
              SKU: data.sku,
              ProductCategory: data.productCategory,
              EnableBarcode: data.enableBarcode,
              DefaultBarcode: data.defaultBarcode,
              TaxCategory1: data.taxCategory1,
              TaxLimit: data.taxLimit,
              TaxCategory2: data.taxCategory2,
              TaxOnMRP: data.taxOnMRP,
              HSNCode: data.hsnCode,
              ShortDescription: data.shortDescription,
              LongDescription: data.longDescription,
              DisplayOrder: data.displayOrder,
              PreparationTime: data.preparationTime,
              MealCourseType: data.mealCourse,
              OpeningStock: data.openingStock,
              OpeningStock_barcodeGodown: [
                {
                  GodownId: "",
                  Qty: "",
                  StockValue: "",
                  Barcode: "",
                  CostPrice: "",
                  SellingPrice: "",
                },
              ],
              OpeningStockValue: data.openingStockValue,
              PurchasePrice: data.purchasePrice,
              CostPrice: data.costPrice,
              SaleProfit: data.saleProfit,
              SalePrice: data.salePrice,
              SaleDiscount: data.saleDiscount,
              MrpPrice: data.mrpPrice,
              PurchaseDiscount: data.purchaseDiscount,
              MinSalePrice: data.minSalePrice,
              BranchPrice: data.branchPrice,
              FranchisePrice: data.franchisePrice,
              NormalLoss: data.normalLoss,
              EcomSalePrice: data.ecomSalePrice,
              StockCheck: data.stockCheck,
              VirtualQuantity: data.virtualQuantity,
              AvailableQuantity: data.availableQuantity,
              MinQtyToPurchase: data.minQtyToPurchase,
              MaxQtyToPurchase: data.maxQtyToPurchase,
              IncrementQty: data.incrementQty,
              IDefaultGodown: data.iDefaultGodown,
              ICateRateA: data.iCateRateA,
              ICateRateB: data.iCateRateB,
              ICateRateC: data.iCateRateC,
              ICateRateD: data.iCateRateD,
              ICateRateE: data.iCateRateE,
              ICateRateF: data.iCateRateF,
              ICateRateG: data.iCateRateG,
              ICateRateH: data.iCateRateH,
              ICateRateI: data.iCateRateI,
              ICateRateJ: data.iCateRateJ,
              LoyaltyPoint: data.loyaltyPoint,
              ReferalAmountDirect: data.referalAmountDirect,
              ReferalAmountIndirect: data.referalAmountIndirect,
              SalesManItemIncentive: data.salesManItemIncentive,
              SellerCommision: data.sellerCommision,
              BuyX: data.buyX,
              GetX: data.getX,
              OfferTagInCategory: data.offerTagInCategory,
              OfferTagInProduct: data.offerTagInProduct,
              SchemeType: data.schemeType,
              EnableSerialNo: data.enableSerialNo,
              EnableBatchNo: data.enableBatchNo,
              Location: data.location,
              RackNo: data.rackNo,
              EnableStockReorder: data.enableStockReorder,
              MaxStockLevelQty: data.maxStockLevelQty,
              MinStockLevelQty: data.minStockLevelQty,
              ReorderLevelQty: data.reorderLevelQty,
              EnableLowStockWarning: data.enableLowStockWarning,
              IsReturnable: data.isReturnable,
              IsConsumable: data.isConsumable,
              IsStockable: data.isStockable,
              AllowRawMaterial: data.allowRawMaterial,
              DefaultVendor: data.defaultVendor,
              Lens_SPH: data.lens_SPH,
              Lens_CYL: data.lens_CYL,
              Lens_AXIS: data.lens_AXIS,
              Lens_Dia: data.lens_Dia,
              Lens_Add: data.lens_Add,
              AttributeList: data.attributeList,
              LensAttributeList: [
                {
                  Barcode: 0,
                  SPH_frm: 0,
                  SPH_to: 0,
                  SPH_stp: 0,
                  CYL_frm: 0,
                  CYL_to: 0,
                  CYL_stp: 0,
                  AXIS_frm: 0,
                  AXIS_to: 0,
                  AXIS_stp: 0,
                  AD_frm: 0,
                  AD_to: 0,
                  AD_stp: 0,
                  DIA: 0,
                },
              ],
              IsAttributeItem: data.isAttributeItem,
              IAttributeParent: data.iAttributeParent,
              AvailableDateTo: data.availableDateTo,
              AvailableDatefrom: data.availableDatefrom,
              DrugCompany: data.drugCompany,
              DrugMFDB: data.drugMFDB,
              DrugSaltName: data.drugSaltName,
              DrugManufacturer: data.drugManufacturer,
              DrugStorageType: data.drugStorageType,
              DrugTBItem: data.drugTBItem,
              DrugSchedule_H: data.drugSchedule_H,
              DrugSchedule_H1: data.drugSchedule_H1,
              DrugSchedule_C: data.drugSchedule_C,
              DrugSchedule_C1: data.drugSchedule_C1,
              DrugSchedule_G: data.drugSchedule_G,
              DrugAnti_TB: data.drugAnti_TB,
              DrugNorcotics: data.drugNorcotics,
              BatchDetails: [
                {
                  GodownId: "",
                  BatchNo: "",
                  MfgDate: "2024-02-10",
                  ExpDate: "2024-02-10",
                  Qty: "",
                  PurPrice: "",
                  SalePrice: "",
                  MRP: "",
                  CostPrice: "",
                  OpPack: "",
                  OpLoose: "",
                  OpValue: "",
                },
              ],
              UseInEcom: data.useInEcom,
              ShowOnHomePage: data.showOnHomePage,
              DisableBuyButton: data.disableBuyButton,
              DisableWishListButton: data.disableWishListButton,
              VisibleIndividually: data.visibleIndividually,
              AllowCustomerReviews: data.allowCustomerReviews,
              AllowCustomerRemarks: data.allowCustomerRemarks,
              Published: data.published,
              AddasComplementaryItem: data.addasComplementaryItem,
              AvailableForHomeDelivery: data.availableForHomeDelivery,
              AvailableForTableOrder: data.availableForTableOrder,
              AvailableForPOS: data.availableForPOS,
              ItemAmtInComplementBonusAmt: data.itemAmtInComplementBonusAmt,
              Intermediate: data.intermediate,
              PopularCategoryPriority: data.popularCategoryPriority,
              ProductTag: data.productTag,
              SpecifyIndexNo: data.specifyIndexNo,
              MeasurmentGuideTool: data.measurmentGuideTool,
              MeasurmentHeightImage: data.measurmentHeightImage,
              HeightTitle: data.heightTitle,
              Height: data.height,
              MeasurmentWidthImage: data.measurmentWidthImage,
              WidthTilte: data.widthTilte,
              Width: data.width,
              MeasurmentWeightImage: data.measurmentWeightImage,
              WeightTitle: data.weightTitle,
              Weight: data.weight,
              MeasurmentLengthImage: data.measurmentLengthImage,
              LengthTitle: data.lengthTitle,
              Length: data.length,
              EnableShipping: data.enableShipping,
              CashOnDeliveryAvailable: data.cashOnDeliveryAvailable,
              FreeShipping: data.freeShipping,
              ShipSeparately: data.shipSeparately,
              LocalShippingCharge: data.localShippingCharge,
              ZonalShippingCharge: data.zonalShippingCharge,
              NationalShippingCharge: data.nationalShippingCharge,
              ReturnApplicationDays: data.returnApplicationDays,
              ReturnPolicyNotes: data.returnPolicyNotes,
              TryAtHome: data.tryAtHome,
              AdditionShippingCharge: data.additionShippingCharge,
              EcomCategoryDetail: data.ecomCategoryDetail,
              MetaKeywords: data.metaKeywords,
              MetaDescription: data.metaDescription,
              MetaTitle: data.metaTitle,
              SearchEnginFriendlyPageName: data.searchEnginFriendlyPageName,
              IsDeleted: data.isDeleted,
            });
            //alert(data.productCategory)
            handleProductCategory(data.productCategory);

            // Left Eye Contact Lens

            setLeftEyeInputs({
              sph1: contactlens.l_SPH_From,
              sph2: contactlens.l_SPH_To,
              sph3: contactlens.l_SPH_Interval,
              cyl1: contactlens.l_CYL_From,
              cyl2: contactlens.l_CYL_To,
              cyl3: contactlens.l_CYL_Interval,
              axis1: contactlens.l_AXIS_From,
              axis2: contactlens.l_AXIS_To,
              axis3: contactlens.l_AXIS_Interval,
              bc1: contactlens.l_BC,
              dia1: contactlens.l_Dia,
              add1: contactlens.l_Add_From,
              add2: contactlens.l_Add_To,

            });

            // Right Eye Contact Lens

            setRightEyeInputs({
              sph1: contactlens.r_SPH_From,
              sph2: contactlens.r_SPH_To,
              sph3: contactlens.r_SPH_Interval,
              cyl1: contactlens.r_CYL_From,
              cyl2: contactlens.r_CYL_To,
              cyl3: contactlens.r_CYL_Interval,
              axis1: contactlens.r_AXIS_From,
              axis2: contactlens.r_AXIS_To,
              axis3: contactlens.r_AXIS_Interval,
              bc1: contactlens.r_BC,
              dia1: contactlens.r_Dia,
              add1: contactlens.r_Add_From,
              add2: contactlens.r_Add_To,

            });


          } catch (error) {
            console.error("Error fetching data:", error);
          }
        }

      }
      catch (error) {
        console.log(error)
      }
    };


    fetchData();

    // if(Number(inputValues.TaxCategory1)>0)
    // {
    //   setInputVisible6(true);
    // }
    // else
    // {
    //   setInputVisible6(true);
    // }

  }, [props.data]);

  const [inputValues, setInputValues] = useState({
    D1_Id: 0,
    D2_Id: 0,
    IsFavorite: false,
    IsActive: true,
    businessType: 0,
    ProductName: "",
    ProductAlias: "",
    PrintName: "",
    ParentGrp: 0,
    parentGrp2: 0,
    ProductUnit: 0,
    ProductAltUnit: 0,
    UnitConvFactor: "",
    ItemDepartment: 0,
    ProductType: 0,
    SKU: "",
    ProductCategory: 0,
    EnableBarcode: true,
    DefaultBarcode: "",
    TaxCategory1: 0,
    TaxLimit: 0,
    TaxCategory2: 0,
    TaxOnMRP: true,
    HSNCode: "",
    ShortDescription: "",
    LongDescription: "",
    DisplayOrder: "",
    PreparationTime: true,
    MealCourseType: true,
    OpeningStock: 0,
    OpeningStock_barcodeGodown: [
      {
        GodownId: 0,
        Qty: 0,
        StockValue: 0,
        Barcode: "",
        CostPrice: 0,
        SellingPrice: 0,
      },
    ],
    OpeningStockValue: "",
    PurchasePrice: "",
    CostPrice: "",
    SaleProfit: "",
    SalePrice: "",
    SaleDiscount: "",
    MrpPrice: "",
    PurchaseDiscount: "",
    MinSalePrice: "",
    BranchPrice: "",
    FranchisePrice: "",
    NormalLoss: "",
    EcomSalePrice: "",
    StockCheck: "",
    VirtualQuantity: "",
    AvailableQuantity: "",
    MinQtyToPurchase: "",
    MaxQtyToPurchase: "",
    IncrementQty: "",
    IDefaultGodown: "",
    ICateRateA: "",
    ICateRateB: "",
    ICateRateC: "",
    ICateRateD: "",
    ICateRateE: "",
    ICateRateF: "",
    ICateRateG: "",
    ICateRateH: "",
    ICateRateI: "",
    ICateRateJ: "",
    LoyaltyPoint: "",
    AvailableDateTo: "",
    AvailableDatefrom: "",
    ReferalAmountDirect: "",
    ReferalAmountIndirect: "",
    SalesManItemIncentive: "",
    SellerCommision: "",
    BuyX: "",
    GetX: "",
    OfferTagInCategory: "",
    OfferTagInProduct: "",
    SchemeType: 0,
    EnableSerialNo: "",
    EnableBatchNo: "",
    Location: "",
    RackNo: "",
    EnableStockReorder: "",
    MaxStockLevelQty: "",
    MinStockLevelQty: "",
    ReorderLevelQty: "",
    EnableLowStockWarning: "",
    IsReturnable: "",
    IsConsumable: "",
    IsStockable: "",
    AllowRawMaterial: "",
    DefaultVendor: "",
    Lens_SPH: "",
    Lens_CYL: "",
    Lens_AXIS: "",
    Lens_Dia: "",
    Lens_Add: "",
    LensAttributeList: [
      {
        Barcode: 0,
        SPH_frm: 0,
        SPH_to: 0,
        SPH_stp: 0,
        CYL_frm: 0,
        CYL_to: 0,
        CYL_stp: 0,
        AXIS_frm: 0,
        AXIS_to: 0,
        AXIS_stp: 0,
        AD_frm: 0,
        AD_to: 0,
        AD_stp: 0,
        DIA: 0,
      },
    ],
    IsAttributeItem: true,
    IAttributeParent: 0,
    DrugCompany: "",
    DrugMFDB: "",
    DrugSaltName: 0,
    DrugManufacturer: 0,
    DrugStorageType: "",
    DrugTBItem: 0,
    DrugSchedule_H: true,
    DrugSchedule_H1: true,
    DrugSchedule_C: true,
    DrugSchedule_C1: true,
    DrugSchedule_G: true,
    DrugAnti_TB: true,
    DrugNorcotics: true,
    BatchDetails: [
      {
        GodownId: 0,
        BatchNo: "",
        MfgDate: "2024-02-10",
        ExpDate: "2024-02-10",
        Qty: 0,
        PurPrice: 0,
        SalePrice: 0,
        MRP: 0,
        CostPrice: 0,
        OpPack: "",
        OpLoose: "",
        OpValue: "",
      },
    ],
    UseInEcom: true,
    ShowOnHomePage: true,
    DisableBuyButton: true,
    DisableWishListButton: true,
    VisibleIndividually: true,
    AllowCustomerReviews: true,
    AllowCustomerRemarks: true,
    Published: true,
    AddasComplementaryItem: true,
    AvailableForHomeDelivery: true,
    AvailableForTableOrder: true,
    AvailableForPOS: true,
    ItemAmtInComplementBonusAmt: true,
    Intermediate: true,
    PopularCategoryPriority: 0,
    ProductTag: "",
    SpecifyIndexNo: 0,
    MeasurmentGuideTool: "",
    MeasurmentHeightImage: "",
    HeightTitle: 0,
    Height: 0,
    MeasurmentWidthImage: "",
    WidthTilte: 0,
    Width: 0,
    MeasurmentWeightImage: "",
    WeightTitle: 0,
    Weight: 0,
    MeasurmentLengthImage: "",
    LengthTitle: 0,
    Length: 0,
    EnableShipping: true,
    CashOnDeliveryAvailable: true,
    FreeShipping: true,
    ShipSeparately: true,
    LocalShippingCharge: 0,
    ZonalShippingCharge: 0,
    NationalShippingCharge: 0,
    ReturnApplicationDays: 0,
    ReturnPolicyNotes: "",
    TryAtHome: true,
    AdditionShippingCharge: 0,
    EcomCategoryDetail: [0],
    MetaKeywords: "",
    MetaDescription: "",
    MetaTitle: "",
    SearchEnginFriendlyPageName: "",
    IsDeleted: false,
  });


  const [OpeningStock, setOpeningStock] = useState({
    GodownId: 0,
    Qty: 0,
    StockValue: 0,
    Barcode: "string",
    CostPrice: 0,
    SellingPrice: 0,
  });

  // *********************** item Master Setting *************************

  const [settingInputValues, setSettingInputValues] = useState({
    alias: true,
    printName: true,
    barcode: true,
    defaultBarcode: true,
    galleryImageUpload: true,
    doubleTax: true,
    alternateUnit: true,
    taxCategory2: true,
    shortDescription: true,
    longDescription: true,
    onlineSale: true,
    offerNSchemeSetting: true,
    priceNStockDetails: true,
    costPrice: true,
    extraDetails: true,
    shippingDetails: true,
    spexMeasurement: true,
    ecomBasedExtra: true,
    serialBatchNStockSettings: true,
    allowSerialNoWiseDetails: true,
    allowBatchwiseReporting: true,
    allowToSetMinMaxReorderLevel: true,
    allowSetDefaultVendor: true,
    allowAttributeItemName: true,
    allowMultiplePrice: true,
    allowMultiGodown: true,
    optionalField: true,
    productTypeSKU: true,
    allowLensItem: true,
    restaurantDetails: true,
    isActive: true,
    accountOptionalFields: [
      {
        optionalFieldName: "string",
        priority: 0,
        isDeleted: false,
        isActive: true
      }
    ],
  });

  const handleSettingChange = async (event) => {
    const { name, checked } = event.target;
    console.log(`Changing ${name} to ${checked}`);

    const updatedValues = {
      ...settingInputValues,
      [name]: checked,
    };

    setSettingInputValues(updatedValues);

    await ItemMasterApi.insertItemMasterSetting(updatedValues);

    switch (name) {
      case "alias":
        handleToggleChange7();
        break;
      case "printName":
        handleToggleChange8();
        break;
      case "barcode":
        showSelectBarcode();
        break;
      case "defaultBarcode":
        showDefault();
        break;
      case "galleryImageUpload":
        handleToggleChange1();
        break;
      case "doubleTax":
        handleToggleChange2();
        break;
      case "alternateUnit":
        showAltUnit();
        break;
      case "taxCategory2":
        handleToggleChange10();
        break;
      case "shortDescription":
        handleToggleChange3();
        break;
      case "longDescription":
        handleLongCheck();
        break;
      case "onlineSale":
        handleToggleChange5();
        break;
      case "offerNSchemeSetting":
        showScheme();
        break;
      case "priceNStockDetails":
        handleToggleChange12();
        break;
      case "costPrice":
        showCostP();
        break;
      case "extraDetails":
        showExtraDetail();
        break;
      case "shippingDetails":
        showDeliverySetting();
        break;
      case "spexMeasurement":
        showSpexMeasurement();
        break;
      case "ecomBasedExtra":
        Ecommerce();
        break;
      case "serialBatchNStockSettings":
        handleToggleChange4();
        break;
      case "allowSerialNoWiseDetails":
        handleToggleChange14();
        break;
      case "allowBatchwiseReporting":
        handleToggleChange20();
        break;
      case "allowToSetMinMaxReorderLevel":
        handleToggleChange16();
        break;
      case "allowSetDefaultVendor":
        handleToggleChange15();
        break;
      case "allowLensItem":
        // handleToggleChange15();
        break;
      case "allowAttributeItemName":
        showAttribute();
        break;
      case "allowMultiplePrice":
        showPriceUnit();
        break;
      case "allowMultiGodown":
        showGodown();
        break;
      case "optionalField":
        showOptionalField();
        break;
      case "productTypeSKU":
        ShowProductTypeSKU();
        break;
      case "restaurantDetails":
        Restaurent();
        break;

      default:
        break;
    }
  };

  // *********************** get Master Setting *************************

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await ItemMasterApi.getItemMasterSetting();
        // console.log("data", data);
        setSettingInputValues(data);
        setInputVisible7(data.alias);
        setInputVisible8(data.printName);
        setDefaultBarcode(data.defaultBarcode);
        setInputVisible1(data.galleryImageUpload);
        setInputVisible2(data.doubleTax);
        setAltUnit(data.alternateUnit);
        setInputVisible10(data.taxCategory2);
        setInputVisible3(data.shortDescription);
        setLongDescriptionCheck(data.longDescription);
        setInputVisible5(data.onlineSale);
        setOfferScheme(data.offerNSchemeSetting);
        setInputVisible12(data.priceNStockDetails);
        setCostPrice(data.costPrice);
        setExtraDetail(data.extraDetails);
        setDeliverySetting(data.shippingDetails);
        setSpexMeasurement(data.spexMeasurement);
        setEcommerce(data.ecomBasedExtra);
        setInputVisible4(data.serialBatchNStockSettings);
        setInputVisible14(data.allowSerialNoWiseDetails);
        setInputVisible20(data.allowBatchwiseReporting);
        setInputVisible16(data.allowToSetMinMaxReorderLevel);
        setInputVisible15(data.allowSetDefaultVendor);
        setModalOpenAttribute(data.allowAttributeItemName);
        setRestaurent(data.restaurantDetails);
        setPriceUnit(data.allowMultiplePrice);
        setProductTypeSKU(data.productTypeSKU)
        setMultiGodown(data.allowMultiGodown);
        setOptionalField(data.optionalField);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    // console.log(settingInputValues);
    fetchData();
  }, []);

  // const [imageDataArray, setImageDataArray] = useState([]);

  // const ItemImageUpload = (file, id) => {
  //   // Assuming 'e' is the file object and 'id' is the image ID
  //   const newImageData = {
  //     file,
  //   };

  //   setImageDataArray((prevImageDataArray) => [
  //     ...prevImageDataArray,
  //     newImageData,
  //   ]);

  //   // formdata(e.target.files[0]) if needed
  // };

  useEffect(() => {
    const fetchItemGroupMaster = async () => {
      try {
        const val = await ItemGroupAPI.GetItemGroup();
        console.log(val);
        setItemGroupList(val);
      } catch (error) {
        console.error("Error fetching account groups:", error);
      }
    };

    fetchItemGroupMaster();
  }, [isModalOpenGroupName]);

  useEffect(() => {
    // const fetchAccountGroupMaster = async () => {
    //   try {
    //     const val = await ItemGroupAPI.GetItemGroup();  
    //     console.log(val);
    //     setAccountGroupList(val);   
    //   } catch (error) {
    //     console.error("Error fetching account groups:", error);

    //   }
    // };

    // fetchAccountGroupMaster();

    const fetchUnitMaster = async () => {
      try {
        const val = await UnitMasterApi.getUnitMaster();
        setUnitMasterList(val);
      } catch (error) {
        console.error("Error fetching account groups:", error);
      }
    };
    fetchUnitMaster();

    const fetchItemDepartment = async () => {
      try {
        const val = await ItemDepartmentApi.getItemDepartment();
        setItemDepartmentList(val);
      } catch (error) {
        console.error("Error fetching account groups:", error);
      }
    };
    fetchItemDepartment();

    const fetchPreprationTime = async () => {
      try {
        const val = await MiscellaneousMaster.GetMiscellaneousMaster(171);
        // console.log(val);
        setPreprationTimeList(val);
      } catch (error) {
        console.error("Error fetching account groups:", error);
        // Optionally, you can set some state to indicate an error or display an error message.
      }
    };
    fetchPreprationTime();

    const fetchMealCourse = async () => {
      try {
        const val = await MealCourse.getMealCourse();
        // console.log(val);
        setMealCourseList(val);
      } catch (error) {
        console.error("Error fetching account groups:", error);
        // Optionally, you can set some state to indicate an error or display an error message.
      }
    };
    fetchMealCourse();

    const fetchTaxCatogory = async () => {
      try {
        const val = await TaxCategoryApi.getTaxCategory();
        // console.log("tax", val);
        setTaxCatgory(val);
      } catch (error) {
        console.error("Error fetching account groups:", error);
        // Optionally, you can set some state to indicate an error or display an error message.
      }
    };
    fetchTaxCatogory();

    const fetchAttributeList = async () => {
      try {
        const val = await ItemMasterApi.GetItemAttributeSettings();
        // console.log("Attribut list", val);
        setAttributeList(val);
      } catch (error) {
        console.error("Error fetching account groups:", error);
        // Optionally, you can set some state to indicate an error or display an error message.
      }
    };
    fetchAttributeList();

  }, [isModalOpenUnit, isDepartment, isModalOpen2, isMealCourse]);


  /*-------------- Get tax info ------------------*/
  const [TaxInfoData, setTaxInfoData] = useState({
    localTaxCGST: "",
    localTaxSGST: "",
    centralTaxIGST: "",
    cessTax: "",
    taxOnMRP: true,
  });

  const GetTaxCategoryInfo = async (id) => {
    const data = await TaxCategoryApi.getTaxCategoryById(id);
    // console.log(data);
    setTaxInfoData(data);
  }

  const toFriendlyName = (fieldName) => {
    return fieldName
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  };

  const validateInput = (fieldName) => {
    const fieldValue = inputValues[fieldName];

    if (
      !fieldValue ||
      (typeof fieldValue === "string" && fieldValue.trim() === "")
    ) {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        [fieldName]: `${toFriendlyName(fieldName)} field is required`,
      }));
      return false;
    }

    return true;
  };

  const [ItemImage, setItemImage] = useState([]);

  const ItemImageUpload = (e, indx) => {

    const newItemImage = [...ItemImage];
    // If indx is within bounds of the array, update it; otherwise, push it to the end
    if (indx >= 0 && indx < newItemImage.length) {
      newItemImage[indx] = e;
    } else {
      newItemImage.push(e);
    }
    setItemImage(newItemImage);
  };
  const upload_image = async (Id) => {
    debugger;
    const formData = new FormData();

    ItemImage.forEach((file) => {
      formData.append(`files`, file);
    });

    //formData.append(`files`, ItemImage);
    formData.append(`BranchId`, 1);
    formData.append(`D1ItemId`, Id);
    // console.log(formData);

    try {
      const imageUpload = await ItemMasterApi.UpdateItemMasterImage(formData);
      console.log(imageUpload);

      if (imageUpload) {
        console.log("image uploaded successfully");
      }
    } catch (error) {
      console.error("Error uploading images:", error);
    }
  };

  const initialInputs = {
    sph1: "",
    sph2: "",
    sph3: "",
    cyl1: "",
    cyl2: "",
    cyl3: "",
    axis1: "",
    axis2: "",
    axis3: "",
    bc1: "",
    dia1: "",
    add1: "",
    add2: "",
  };

  const [leftEyeInputs, setLeftEyeInputs] = useState(initialInputs);
  const [rightEyeInputs, setRightEyeInputs] = useState(initialInputs);

  const handleInputContactLensChange = (eye, field, value) => {
    const eyeInputs = eye === "left" ? leftEyeInputs : rightEyeInputs;
    const updatedInputs = {
      ...eyeInputs,
      [field]: value,
    };

    if (eye === "left") {
      setLeftEyeInputs(updatedInputs);
    } else {
      setRightEyeInputs(updatedInputs);
    }
  };

  const SaveContactLens = async (d2_Id) => {
    console.log(leftEyeInputs);
    console.log(rightEyeInputs);

    const filterData = [
      {
        "path": "r_SPH_From",
        "op": "add",
        "from": "string",
        "value": rightEyeInputs.sph1
      },
      {
        "path": "r_SPH_To",
        "op": "add",
        "from": "string",
        "value": rightEyeInputs.sph2
      },
      {
        "path": "r_SPH_Interval",
        "op": "add",
        "from": "string",
        "value": rightEyeInputs.sph3
      },
      {
        "path": "r_CYL_From",
        "op": "add",
        "from": "string",
        "value": rightEyeInputs.cyl1
      },
      {
        "path": "r_CYL_To",
        "op": "add",
        "from": "string",
        "value": rightEyeInputs.cyl2
      },
      {
        "path": "r_CYL_Interval",
        "op": "add",
        "from": "string",
        "value": rightEyeInputs.cyl3
      },
      {
        "path": "r_AXIS_From",
        "op": "add",
        "from": "string",
        "value": rightEyeInputs.axis1
      },
      {
        "path": "r_AXIS_To",
        "op": "add",
        "from": "string",
        "value": rightEyeInputs.axis2
      },
      {
        "path": "r_AXIS_Interval",
        "op": "add",
        "from": "string",
        "value": rightEyeInputs.axis3
      },
      {
        "path": "r_BC",
        "op": "add",
        "from": "string",
        "value": rightEyeInputs.bc1
      },
      {
        "path": "r_Dia",
        "op": "add",
        "from": "string",
        "value": rightEyeInputs.dia1
      },
      {
        "path": "r_Add_From",
        "op": "add",
        "from": "string",
        "value": rightEyeInputs.add1
      },
      {
        "path": "r_Add_To",
        "op": "add",
        "from": "string",
        "value": rightEyeInputs.add2
      },
      {
        "path": "l_SPH_From",
        "op": "add",
        "from": "string",
        "value": leftEyeInputs.sph1
      },
      {
        "path": "l_SPH_To",
        "op": "add",
        "from": "string",
        "value": leftEyeInputs.sph2
      },
      {
        "path": "l_SPH_Interval",
        "op": "add",
        "from": "string",
        "value": leftEyeInputs.sph3
      },
      {
        "path": "l_CYL_From",
        "op": "add",
        "from": "string",
        "value": leftEyeInputs.cyl1
      },
      {
        "path": "l_CYL_To",
        "op": "add",
        "from": "string",
        "value": leftEyeInputs.cyl2
      },
      {
        "path": "l_CYL_Interval",
        "op": "add",
        "from": "string",
        "value": leftEyeInputs.cyl3
      },
      {
        "path": "l_AXIS_From",
        "op": "add",
        "from": "string",
        "value": leftEyeInputs.axis1
      },
      {
        "path": "l_AXIS_To",
        "op": "add",
        "from": "string",
        "value": leftEyeInputs.axis2
      },
      {
        "path": "l_AXIS_Interval",
        "op": "add",
        "from": "string",
        "value": leftEyeInputs.axis3
      },
      {
        "path": "l_BC",
        "op": "add",
        "from": "string",
        "value": leftEyeInputs.bc1
      },
      {
        "path": "l_Dia",
        "op": "add",
        "from": "string",
        "value": leftEyeInputs.dia1
      },
      {
        "path": "l_Add_From",
        "op": "add",
        "from": "string",
        "value": leftEyeInputs.add1
      },
      {
        "path": "l_Add_To",
        "op": "add",
        "from": "string",
        "value": leftEyeInputs.add2
      },
    ]

    await ItemMasterApi.SaveContactLens(filterData, d2_Id);
  }


  const SaveBtn = async () => {
    //console.log(customFieldValues);

    let allValid = true;
    fieldNames.forEach((fieldName) => {
      const isValid = validateInput(fieldName);
      if (!isValid) {
        allValid = false;
      }
    });
    if (allValid) {
      setLoading(true);
      if (props.data) {
        //if (Boolean(props.modelOpen) === true) {
        const isSuccess = await ItemMasterApi.updateItemMaster(
          inputValues,
          AttributeListData,
          restoAvailableTime,
          commaSeparatedTags,
          isAttributeItemTF,
          checkedItemIds,
          shortDiscription,
          longDescription
        );
        console.log(isSuccess);
        if (isSuccess.isSuccess === true) {
          debugger
          await SaveContactLens(isSuccess.result.d2_Id);
          // console.log(isSuccess);
          // console.log(isSuccess.d1_Id);
          upload_image(isSuccess.result.d1_Id);
          OpenAlertBoxEdit();
          setLoading(false);
          props.fetchData();
          props.setSliderCallBack(false);
        }
        else if (isSuccess.errorMessages[0] === "Item already exists.") {
          setLoading(false);
          OpenAlertBoxWhenItemExists()
        } else {
          setLoading(false);
          OpenAlertBoxErrorUpdate();
        }
      } else {
        setLoading(true);
        const isSuccess = await ItemMasterApi.InsertItemMaster(
          inputValues,
          AttributeListData,
          restoAvailableTime,
          commaSeparatedTags,
          isAttributeItemTF,
          checkedItemIds,
          shortDiscription,
          longDescription
        );
        if (isSuccess.isSuccess === true) {
          await SaveContactLens(isSuccess.result.d2_Id);
          await upload_image(isSuccess.result.d1_Id);
          OpenAlertBox();
          setLoading(false);
        } else if (isSuccess.errorMessages[0] === "Item already exists.") {
          setLoading(false);
          OpenAlertBoxWhenItemExists()
        } else {
          setLoading(false);
          OpenAlertBoxErrorInsert();
          //props.setSliderCallBack(false);
        }
      }
    }
  };

  //  /* ******************* {Tab} ****************** */

  const [tabcontent1, setTabContent1] = useState(true);
  const [tabcontent2, setTabContent2] = useState(false);
  const [tabcontent3, setTabContent3] = useState(false);
  const [tabcontent4, setTabContent4] = useState(false);
  const [tabcontent5, setTabContent5] = useState(false);
  const [tabcontent6, setTabContent6] = useState(false);
  const [tabcontent7, setTabContent7] = useState(false);
  const [tabcontent8, setTabContent8] = useState(false);
  const [tabcontent9, setTabContent9] = useState(false);

  const showtabs1 = () => {
    setTabContent1(true);
    setTabContent2(false);
    setTabContent3(false);
    setTabContent4(false);
    setTabContent5(false);
    setTabContent6(false);
    setTabContent7(false);
    setTabContent8(false);
    setTabContent9(false);
  };
  const showtabs2 = () => {
    setTabContent1(false);
    setTabContent2(true);
    setTabContent3(false);
    setTabContent4(false);
    setTabContent5(false);
    setTabContent6(false);
    setTabContent7(false);
    setTabContent8(false);
    setTabContent9(false);
  };
  const showtabs3 = () => {
    setTabContent1(false);
    setTabContent2(false);
    setTabContent3(true);
    setTabContent4(false);
    setTabContent5(false);
    setTabContent6(false);
    setTabContent7(false);
    setTabContent8(false);
    setTabContent9(false);
  };
  const showtabs4 = () => {
    setTabContent1(false);
    setTabContent2(false);
    setTabContent3(false);
    setTabContent4(true);
    setTabContent5(false);
    setTabContent6(false);
    setTabContent7(false);
    setTabContent8(false);
    setTabContent9(false);
  };
  const showtabs5 = () => {
    setTabContent1(false);
    setTabContent2(false);
    setTabContent3(false);
    setTabContent4(false);
    setTabContent5(true);
    setTabContent6(false);
    setTabContent7(false);
    setTabContent8(false);
    setTabContent9(false);
  };
  const showtabs6 = () => {
    setTabContent1(false);
    setTabContent2(false);
    setTabContent3(false);
    setTabContent4(false);
    setTabContent5(false);
    setTabContent6(true);
    setTabContent7(false);
    setTabContent9(false);
    setTabContent8(false);
  };
  const showtabs7 = () => {
    setTabContent1(false);
    setTabContent2(false);
    setTabContent3(false);
    setTabContent4(false);
    setTabContent5(false);
    setTabContent6(false);
    setTabContent7(true);
    setTabContent8(false);
    setTabContent9(false);
  };
  const showtabs8 = () => {
    setTabContent1(false);
    setTabContent2(false);
    setTabContent3(false);
    setTabContent4(false);
    setTabContent5(false);
    setTabContent6(false);
    setTabContent7(false);
    setTabContent8(true);
    setTabContent9(false);
  };

  const showtabs9 = () => {
    setTabContent1(false);
    setTabContent2(false);
    setTabContent3(false);
    setTabContent4(false);
    setTabContent5(false);
    setTabContent6(false);
    setTabContent7(false);
    setTabContent8(false);
    setTabContent9(true);
  };

  // {/* *******************Tab****************** */}
  const handlePrintChange = (e) => {
    handleInputChange(e);
  };

  // {/* *******************show hide****************** */}
  const [isInputVisible, setInputVisible] = useState(false);
  const handleToggleChange = () => {
    setInputVisible(!isInputVisible);
  };
  const [isInputVisible1, setInputVisible1] = useState(false);
  const handleToggleChange1 = () => {
    setInputVisible1(!isInputVisible1);
  };
  const [isInputVisible2, setInputVisible2] = useState(false);
  const handleToggleChange2 = () => {
    setInputVisible2(!isInputVisible2);
  };
  const [isInputVisible3, setInputVisible3] = useState(false);
  const handleToggleChange3 = () => {
    setInputVisible3(!isInputVisible3);
  };
  // const [newValue, setSelectedValue] = useState('');
  const [isInputVisible4, setInputVisible4] = useState(false);

  const handleToggleChange4 = (e) => {
    setInputVisible4(!isInputVisible4);
    setCheckMinMax(!isCheckMinMax);
    if (inputValues.businessType === 846) {   //pharmacy
      setCheckAllowSerialNo(!isCheckAllowSerialNo);
    }
    if (inputValues.businessType === 845) { //restro
      setCheckAllowSerialNo(false);
      setCheckBatchWiseNo(false);
    } else {
      setCheckBatchWiseNo(!isCheckBatchWiseNo);
      setCheckAllowSerialNo(!isCheckAllowSerialNo);
    }
  };

  const [isInputVisible5, setInputVisible5] = useState(false);
  const handleToggleChange5 = () => {
    setInputVisible5(!isInputVisible5);
  };
  const [isInputVisible6, setInputVisible6] = useState(true);
  const handleToggleChange6 = () => {
    setInputVisible6(!isInputVisible6);
  };
  const [isInputVisible7, setInputVisible7] = useState(false);
  const handleToggleChange7 = () => {
    setInputVisible7(!isInputVisible7);
  };
  const [isInputVisible8, setInputVisible8] = useState(false);
  const handleToggleChange8 = () => {
    setInputVisible8(!isInputVisible8);
  };

  const [isInputVisible10, setInputVisible10] = useState(false);
  const handleToggleChange10 = () => {
    setInputVisible10(!isInputVisible10);
  };
  const [isInputVisibleMRP, setInputVisibleMRP] = useState(false);
  const [isInputVisible12, setInputVisible12] = useState(false);

  const handleToggleChange12 = (e) => {
    setInputVisible12(!isInputVisible12);
    //setInputVisibleMRP(!isInputVisibleMRP);
    if (inputValues.businessType === 844) {  //ecom
      setRestaurentEcom(!isRestaurentEcom);
      setStockValue(!isStockValue);
      setStockQty(!isStockQty);
      setInputVisible12(!isInputVisible12);
      setInputVisibleMRP(true);
    } else {
      setInputVisible12(!isInputVisible12);
      setInputVisibleMRP(!isInputVisibleMRP);
    }
  };

  const [isInputVisible13, setInputVisible13] = useState(true);

  const [isInputVisible14, setInputVisible14] = useState(false);

  const handleToggleChange14 = () => {
    setInputVisible14(!isInputVisible14);
  };
  const [isInputVisible15, setInputVisible15] = useState(false);
  const handleToggleChange15 = () => {
    setInputVisible15(!isInputVisible15);
  };
  const [isInputVisible16, setInputVisible16] = useState(false);
  const handleToggleChange16 = () => {
    setInputVisible16(!isInputVisible16);
  };

  const [isInputVisibleLensItem, setInputVisibleLensItem] = useState(false);
  const handleToggleChangeLensItem = () => {
    setInputVisibleLensItem(!isInputVisibleLensItem);
  };
  const [isInputVisible20, setInputVisible20] = useState(false);
  const handleToggleChange20 = () => {
    setInputVisible20(!isInputVisible20);
  };

  // {/* *******************{show hide}****************** */}

  // {/* *******************{modal box}****************** */}
  const [isModalOpen, setModalOpen] = useState(false);
  const showModal = () => {
    setModalOpen(!isModalOpen);
    document.body.style.overflow = isModalOpen ? "hidden" : "auto";
  };
  const hideModal = () => {
    setModalOpen(false);
  };
  // {/* *******************{modal box}****************** */}

  const showModal2 = () => {
    setModalOpen2(true);
  };
  const hideModal2 = () => {
    setModalOpen2(false);
  };


  const showGroupName = () => {
    // console.log(isModalOpenGroupName);
    setModalOpenGroupName(true);
  };
  const hideGroupName = () => {
    setModalOpenGroupName(false);
  };

  const [isModalOpen3, setModalOpen3] = useState(true);
  const hideModal3 = () => {
    setModalOpen3(false);
  };

  const [isModalOpen4, setModalOpen4] = useState(false);
  const handleSelectChange = (event) => {
    const selectedOption = event.target.value;
    if (selectedOption === "true") {
      showModal4(); // Show the modal when "Yes" is selected
    }
    if (selectedOption === "false") {
      showModal41(); // Show the modal when "Yes" is selected
    }
  };

  const showModal4 = () => {
    setModalOpen4(true);
  };
  const showModal41 = () => {
    setModalOpen4(false);
  };

  //*************************{batchWise Modal}*********************
  const [isModalOpenAttribute, setModalOpenAttribute] = useState(false);
  const showAttribute = () => {
    setModalOpenAttribute(!isModalOpenAttribute);
  };

  const showDepartment = () => {
    setDepartment(true);
  };
  const hideDepartment = () => {
    setDepartment(false);
  };
  const showUnit = () => {
    setModalOpenUnit(true);
    document.body.style.overflow = isModalOpenUnit ? "hidden" : "auto";
  };
  const hideUnit = () => {
    setModalOpenUnit(false);
  };

  const [isOptionalField, setOptionalField] = useState(false);
  const showOptionalField = () => {
    setOptionalField(!isOptionalField);
  };

  const [isMultiGodownBlur, setMultiGodownBlur] = useState(false);
  const MultiGodownBlur = () => {
    if (inputValues.businessType === 846) {  //pharmay
      setMultiGodownBlur(false);
    }
    if (inputValues.businessType === 845) {  //restro
      setMultiGodownBlur(true);
    } else {
      setMultiGodownBlur(true);
    }
  };
  const hideMultiGodownBlur = () => {
    setMultiGodownBlur(false);
  };

  const [isMultiGodown, setMultiGodown] = useState(true);
  const showGodown = () => {
    if (inputValues.businessType === 845) { //restro
      setMultiGodown(false);
    }
    if (inputValues.businessType === 846) {  //pharmacy
      setMultiGodown(!isMultiGodown);
    } else {
      setMultiGodown(!isMultiGodown);
    }
  };

  const [isAttributeDetails, setAttributeDetails] = useState(false);
  const showAttributeDetails = () => {
    // debugger;
    console.log("TempArrtibute on Open:   ", AttributeListTemp);
    setAttributeListData(AttributeListTemp);

    if (props.data) {
      if (props.data.iAttributeParent === 0) {
        setAttributeDetails(!isAttributeDetails);
      } else {
        // alert("No Attribute found");
      }
    } else {
      setAttributeDetails(!isAttributeDetails);
    }
  };
  const hideAttributeDetails = () => {
    setAttributeDetails(false);
  };

  const [AttributeListTemp, setAttributeListTemp] = useState([]);
  const saveItemAttrTemporary = () => {
    //debugger;
    console.log("Arrtibute on Save:   ", AttributeListTemp);
    setAttributeDetails(false);
  };


  const [isItemWiseList, setItemWiseList] = useState(false);
  const showModal6 = () => {
    setItemWiseList(true);
  };
  const hideModal6 = () => {
    setItemWiseList(false);
  };

  const handleSelectChange2 = (event) => {
    setSelectedOption(event.target.value);
    if (event.target.value === "true") {
      showModal6(); // Show the modal when "Yes" is selected
    } else if (event.target.value === "false") {
      hideModal6();
    } else {
      setInput1Visible(false);
      setInput2Visible(false);
    }
  };

  const [rows, setRows] = useState(Array(20).fill({}));

  const handleInputChange1 = (index, event) => {
    const { name, value } = event.target;
    const updatedRows = [...rows];
    updatedRows[index][name] = value;
    setRows(updatedRows);
  };

  const [isModalOpen7, setModalOpen7] = useState(false);
  const showModal7 = () => {
    setModalOpen7(true);
  };
  const hideModal7 = () => {
    setModalOpen7(false);
  };

  const showMeal = () => {
    setMealCourse(true);
  };
  const hideMeal = () => {
    setMealCourse(false);
  };

  const handleSelectChange3 = (event) => {
    setSelectedOption(event.target.value);
    if (event.target.value === "true") {
      showModal7();
    } else if (event.target.value === "false") {
      hideModal7();
    } else {
      setInput1Visible(false);
      setInput2Visible(false);
    }
  };

  // {/* *******************{Category}****************** */}

  //***************{Available Time} *******************//
  // const [rowsTime, setRowsTime] = useState(Array(5).fill({}));
  const [rowsTime, setRowsTime] = useState(Array(5).fill({}));

  // const handleAddRowTime = () => {
  //   setRowsTime([...rowsTime, {}]);
  // };

  // const handleDeleteRowTime = (index) => {
  //   const updatedRows = rowsTime.filter((_, i) => i !== index);
  //   setRowsTime(updatedRows);
  // };

  // const handleKeyPress4 = (index, columnName, event) => {
  //   const { key } = event;
  //   if (key === "Enter") {
  //     const columnIndex = columns.indexOf(columnName);
  //     if (columnIndex === columns.length - 1) {
  //       const nextRow = document.getElementsByName(
  //         `${columns[0]}-${index + 1}`
  //       );
  //       if (nextRow.length > 0) {
  //         nextRow[0].focus();
  //       }
  //     } else {
  //       const nextColumnName = columns[columnIndex + 1];
  //       const nextColumn = document.getElementsByName(
  //         `${nextColumnName}-${index}`
  //       );
  //       if (nextColumn.length > 0) {
  //         nextColumn[0].focus();
  //       }
  //     }
  //   }
  // };

  // const [currentDate] = useState(new Date().toISOString().substr(0, 10));

  const [, setNewCategoryName] = useState("");

  // const handleCategoryChange = (event) => {
  //     setSelectedCategory(event.target.value);
  //     const selectedIndex = event.target.selectedIndex;
  //     const options = event.target.options;
  //     if (selectedIndex === options.length - 1) {
  //         showModal2();
  //     }
  // };
  const closeNewCategoryModal = () => {
    setNewCategoryName("");
  };

  // {/* *******************{Category}****************** */}

  const handleTagInput = (e) => {
    setTagInput(e.target.value);
  };

  const handleAddTag = () => {
    if (tagInput.trim() !== "") {
      setTags([...tags, tagInput]);
      setTagInput("");
      setCommaSeparatedTags([...tags, tagInput].join(","));
    }
  };

  const handleDeleteTag = (tagToDelete) => {
    const updatedTags = tags.filter((tag) => tag !== tagToDelete);
    setTags(updatedTags);
  };

  // {/* *******************{Onboarding code}****************** */}

  //**************************{Table}*****************************

  const [taxRows, setTaxRows] = useState([
    { id: 1, TaxName: "", Percent: "", Amount: "" },
    { id: 2, TaxName: "", Percent: "", Amount: "" },
    { id: 3, TaxName: "", Percent: "", Amount: "" },
    { id: 4, TaxName: "", Percent: "", Amount: "" },
    { id: 5, TaxName: "", Percent: "", Amount: "" },
  ]);

  const handleCellKeyDown = (event, rowIndex) => {
    if (event.key === "Enter") {
      const newRow = { id: taxRows.length + 1, content: "" };
      setTaxRows([...taxRows, newRow]);
      const nextRowIndex = rowIndex + 1;
      const nextRowCellId = `cell-${nextRowIndex}-0`;
      const nextRowCell = document.getElementById(nextRowCellId);
      if (nextRowCell) {
        nextRowCell.focus();
      }
    }
  };

  const handleCellChange = (event, rowIndex, colIndex) => {
    const updatedRows = [...taxRows];
    updatedRows[rowIndex] = {
      ...updatedRows[rowIndex],
      content: event.target.value,
    };
    setTaxRows(updatedRows);
  };
  const deleteTaxRow = (rowIndex) => {
    const updatedRows = taxRows.filter((_, index) => index !== rowIndex);
    setTaxRows(updatedRows);
  };

  const [restoAvailableTime, setRestoAvailableTime] = useState([
    { availableTimefrom: "", availableTimeTo: "" },
  ]);
  useEffect(() => {
    // console.log("<------------- RestoAvailableTime  ------------>", restoAvailableTime);
  }, [restoAvailableTime])

  const handleAddRowTime = () => {
    setRestoAvailableTime([
      ...restoAvailableTime,
      { availableTimefrom: "", availableTimeTo: "" },
    ]);
  };

  const handleDeleteRowTime = (index) => {
    if (restoAvailableTime.length > 1) {
      // If there is more than one row, proceed to delete the row at the given index
      const updatedRows = restoAvailableTime.filter((_, i) => i !== index);
      setRestoAvailableTime(updatedRows);
    } else {
      // If there's only one row left, just clear the values of the remaining row
      const clearedRow = { availableTimefrom: "", availableTimeTo: "" };
      setRestoAvailableTime([clearedRow]);
    }
  };


  const handleInputChangeTime2 = (rowIndex, e) => {
    const updatedRows = [...restoAvailableTime];
    updatedRows[rowIndex].availableTimefrom = e.target.value;
    setRestoAvailableTime(updatedRows);
  };

  const handleInputChangeTime = (rowIndex, e) => {
    const updatedRows = [...restoAvailableTime];
    updatedRows[rowIndex].availableTimeTo = e.target.value;
    setRestoAvailableTime(updatedRows);
  };

  //   const handleAddRowTime = () => {
  //     setRestoAvailableTime([...restoAvailableTime, { AvailableTimefrom: '', AvailableTimeTo: '' }]);
  //   };

  //   const handleDeleteRowTime = (rowIndex) => {
  //     const newRestoAvailableTime = [...restoAvailableTime];
  //     newRestoAvailableTime.splice(rowIndex, 1);
  //     setRestoAvailableTime(newRestoAvailableTime);
  //   };

  const [{ run, steps }] = useState({
    run: true,
    steps: [
      {
        content: <h2>Let's begin our journey!</h2>,
        locale: { skip: <strong>SKIP</strong> },
        placement: "center",
        target: "body",
      },
      {
        content: <h2>Upload Item Image!</h2>,
        placement: "bottom",
        target: "#step-1",
        title: "First step",
      },
      {
        content: <h2>Here is second step!</h2>,
        placement: "bottom",
        target: "#step-2",
        title: "Second step",
      },
      {
        content: <h2>Here is third step!</h2>,
        placement: "bottom",
        target: "#step-3",
        title: "Third step",
      },
      {
        content: <h2>Here is fourth step!</h2>,
        placement: "bottom",
        target: "#step-4",
        title: "Fourth step",
      },
      {
        content: <h2>Here is fifth step!</h2>,
        placement: "bottom",
        target: "#step-5",
        title: "Fifth step",
      },
      {
        content: <h2>Here is six step!</h2>,
        placement: "bottom",
        target: "#step-6",
        title: "Six step",
      },
    ],
  });

  // {/* ******************* {Onboarding code} ****************** */}
  const [activeTab, setActiveTab] = useState(5); // Initialize the active tab index

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  //****************** {attribute code} *********************//

  const [numVariations, setNumVariations] = useState(1);
  const [variations, setVariations] = useState([]);
  const handleNumVariationsChange = (event) => {
    const newNumVariations = parseInt(event.target.value);
    if (newNumVariations >= 1) {
      setNumVariations(newNumVariations);
      setVariations(Array(newNumVariations).fill({}));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log(variations);
  };

  const inputRef = useRef(null);
  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.select();
    }
  };

  //*************************{Product/Service}**************************//

  const [selectedOption, setSelectedOption] = useState("single");
  const [isInput1Visible, setInput1Visible] = useState(true);
  const [isInput2Visible, setInput2Visible] = useState(false);

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);

    if (event.target.value === "single") {
      setInput1Visible(true);
      setInput2Visible(false);
    } else if (event.target.value === "bifocal") {
      setInput1Visible(false);
      setInput2Visible(true);
    } else {
      setInput2Visible(false);
    }
  };

  const [isEcommerceVirtual, setEcommerceVirtual] = useState(false);

  const handleStockChange = (e) => {
    var val = e.target.value
    handleCheckStock(val);
    handleInputChange(e);
  };

  const handleCheckStock = (val) => {
    debugger
    setSelectedOption(val);

    if (val === "false") {
      setEcommerceVirtual(false);
    } else if (val === "1") {
      setEcommerceVirtual(true);
    } else if (val === "2") {
      setEcommerceVirtual(false);
    }
  };


  // *******************{Product Types}*************************
  const [isProductvariation, setProductvariation] = useState(false);

  const handleProductTypeChange = (e) => {
    handleProductTypes(e);
    handleInputChange(e);
  };

  useEffect(() => {
    if (props.data) {
      setSelectedOption(props.data.StockCheck)
      const handleProductTypes = () => {
        if (props.data.productType === 3) {
          setProductvariation(true);
        }
        console.log(ProductVariation)
      }
      handleCheckStock(props.data.handleCheckStock)

      const handleProductCategory = () => {

        if (props.data.productCategory == "0") {
          setLPProduct(false);
          setCLPProduct(false);
        } else if (props.data.productCategory == "1") {
          setLPProduct(true);
          setCLPProduct(false);
        } else if (props.data.productCategory == "2") {
          setCLPProduct(true);
          setLPProduct(false);
        }
      };

      handleProductCategory()
      handleProductTypes()
    }
  }, [props.data])

  const handleProductTypes = (event) => {
    setSelectedOption(event.target.value);

    if (event.target.value === "2") {
      setProductvariation(false);
    } else if (event.target.value === "1") {
      setProductvariation(false);
    } else if (event.target.value === "3") {
      setProductvariation(!isProductvariation);
    }
  };

  const [isLPProduct, setLPProduct] = useState(false);
  const [isCLPProduct, setCLPProduct] = useState(false);

  const handleProductCategoryChange = (e) => {
    const value = e.target.value
    handleProductCategory(value);
    handleInputChange(e);
  };

  const handleProductCategory = (value) => {
    debugger;
    setSelectedOption(value);

    if (value == "0") {
      setLPProduct(false);
      setCLPProduct(false);
    } else if (value == "1") {
      setLPProduct(!isLPProduct);
      setCLPProduct(false);
    } else if (value == "2") {
      setCLPProduct(!isCLPProduct);
      setLPProduct(false);
    }
  };

  // *************** {Check Box Enable/Disable} **************

  const [isCheckItemDetail, setCheckItemDetail] = useState(false);

  const [isStockValue, setStockValue] = useState(true);

  const [isStockQty, setStockQty] = useState(true);

  const [isCheckOnlineSale, setCheckOnlineSale] = useState(false);

  const [, setCheckCategoryDetail] = useState(false);

  const [isCheckAllowSerialNo, setCheckAllowSerialNo] = useState(false);

  const [isCheckBatchWiseNo, setCheckBatchWiseNo] = useState(false);

  const [isCheckMinMax, setCheckMinMax] = useState(false);

  const [, setCheckExtraDetail] = useState(false);

  const [isCheckDeliverySetting, setCheckDeliverySetting] = useState(false);

  const [isCheckSpexMeasurement, setCheckSpexMeasurement] = useState(false);

  const [isCheckRestaurentDetail, setCheckRestaurentDetail] = useState(false);

  const [isCheckEcommerce, setCheckEcommerce] = useState(false);

  const [isCheckProductTypeSKU, setCheckProductTypeSKU] = useState(true);

  const [isMultiplePrinceList, setMultiplePrinceList] = useState(true);

  const [isUnit1, setUnit1] = useState(true);
  const [isAltUnit, setAltUnit] = useState(true);
  const showAltUnit = () => {
    setAltUnit(!isAltUnit);
  };

  const [isMedicalStore, setMedicalStore] = useState(false);
  const showMedical = () => {
    setMedicalStore(true);
  };
  // const hideMedical = () =>{
  //     setMedicalStore(false);
  // }

  const [isPacking, setPacking] = useState(true);
  const showPacking = () => {
    setPacking(!isPacking);
  };

  const handlePackingChange = (e) => {
    // showPacking()
    handleInputChange(e);
  };

  const [isStartDate, setStartDate] = useState(true);
  const showStartDate = () => {
    setStartDate(!isStartDate);
  };

  const [isAvailableTime, setAvailableTime] = useState(true);
  // const showAvailableTime = () => {
  //     setAvailableTime(!isAvailableTime);
  // }

  const [isItemDepartment, setItemDepartment] = useState(true);
  const showItemDepartment = () => {
    setItemDepartment(!isItemDepartment);
  };

  const [isProductTypeSKU, setProductTypeSKU] = useState(true);
  const ShowProductTypeSKU = () => {
    setProductTypeSKU(!isProductTypeSKU);
  };

  const [isSaleCategory, setSaleCategory] = useState(true);
  // const ShowSaleCategory = () => {
  //     setSaleCategory(!isSaleCategory);
  // }

  // ********************* {Product Types} **********************
  const [isExtraDetailEcom, setExtraDetailEcom] = useState(true);
  const [isExtraDetail, setExtraDetail] = useState(true);
  const showExtraDetail = () => {
    if (inputValues.businessType === 845) {   //restro
      setExtraDetail(!isExtraDetail);
      setExtraDetailEcom(!isExtraDetailEcom);
    } else {
      setExtraDetail(!isExtraDetail);
    }
  };

  const [isDeliverySetting, setDeliverySetting] = useState(true);
  const showDeliverySetting = () => {
    setDeliverySetting(!isDeliverySetting);
  };

  const [isSpexMeasurement, setSpexMeasurement] = useState(false);
  const showSpexMeasurement = () => {
    setSpexMeasurement(!isSpexMeasurement);
  };

  const [, setInventary] = useState(true);

  const [isOptionalFieldDel, setOptionalFieldDel] = useState(true);

  const [isEcommerce, setEcommerce] = useState(true);
  const Ecommerce = () => {
    setEcommerce(!isEcommerce);
  };

  const [isRestaurent, setRestaurent] = useState(false);
  const Restaurent = () => {
    setRestaurent(!isRestaurent);
  };

  const [isPriceUnit, setPriceUnit] = useState(false);
  const showPriceUnit = () => {
    setPriceUnit(!isPriceUnit);
  };
  const [isRestaurentEcom, setRestaurentEcom] = useState(true);
  const [isLensAllow, setLensAllow] = useState(true);
  // const [isBarcode, setBarcode] = useState(true);

  //   const [inputValues.BusinessType, setinputValues.BusinessType] = useState(false);

  const handleBusinessChange = (e) => {
    handleSelectChange01(e);
    handleInputChange(e);
  };

  const [mataData, setMetadata] = useState({
    D1_Id: 0,
    MetaKeywords: "",
    MetaDescription: "",
    MetaTitle: "",
    SearchEnginFriendlyPageName: ""
  })



  const handleJointInputChange = (event) => {

    const { name, value } = event.target;
    console.log(`Changing ${name} to ${value}`);

    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [name]: value,
      SKU: name === 'ProductName' ? value : prevInputValues.SKU,
    }));
    setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };

  const handleInputChange = (event) => {
    console.log(event.target.value);
    const { name, value } = event.target;
    console.log(`Changing ${name} to ${value}`);

    if (name == "ParentGrp") {
      setInputValues((prevValues) => ({
        ...prevValues,
        [name]: value,
        "parentGrp2": value
      }));
    }
    else {
      setInputValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }

    setMetadata((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

    if (name == "TaxCategory1") {
      GetTaxCategoryInfo(value);
    }

    setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: "" }));
    // console.log(AttributeListData);
  };

  const [isSelectBarcode, setSelectBarcode] = useState(true);

  const showSelectBarcode = () => {
    setSelectBarcode(!isSelectBarcode);
  };


  const handleInputChangeActive = (e) => {
    const { name, type, checked } = e.target;
    console.log(`Changing ${name} to ${checked}`);
    const newValue = type === "checkbox" ? checked : e.target.value;
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [name]: type === "checkbox" ? !prevInputValues[name] : newValue,
    }));

  }


  var selectedValue = "845";
  const handleSelectChange01 = (event) => {

    selectedValue = event.target.value;
    // setinputValues.BusinessType(selectedValue);
    fieldToBeOpen(selectedValue);
  };

  // alert(selectedValue)

  const fieldToBeOpen = (selectedValues) => {
    debugger
    selectedValues.forEach(selectedValue => {
      switch (selectedValue) {
        // My Inventory
        case 841:
          setRestaurentEcom(false);
          setInputVisible5(false);
          setInputVisible4(false);
          setInputVisible1(true);
          setInputVisible13(false);
          setCheckProductTypeSKU(false);
          setEcommerce(false);
          setRestaurent(false);
          setInventary(true);
          setExtraDetail(false);
          setPacking(false);
          setSelectBarcode(true);
          setItemDepartment(false);
          setProductTypeSKU(false);
          setSaleCategory(false);
          setCheckItemDetail(true);
          setCheckOnlineSale(false);
          setCheckCategoryDetail(false);
          setCheckAllowSerialNo(false);
          setCheckBatchWiseNo(false);
          setCheckExtraDetail(false);
          setCheckDeliverySetting(false);
          setCheckSpexMeasurement(false);
          setCheckRestaurentDetail(false);
          setLensAllow(false);
          setOptionalFieldDel(true);
          showMedical(true);
          setMedicalStore(false);
          setInputVisibleLensItem(false);
          setInputVisible1(true);
          setInputVisible2(false);
          setInputVisible6(true);
          setInputVisible7(false);
          setInputVisible10(false);
          setInputVisible15(false);
          setInputVisible12(false);
          setInputVisible14(false);
          setCheckEcommerce(false);
          setInputVisibleMRP(false);
          setPriceUnit(true);
          break;
        // My Optical
        case 842:
          setRestaurentEcom(false);
          setInputVisible5(false);
          setInputVisible4(false);
          setInputVisible1(true);
          setInputVisible13(false);
          setEcommerce(false);
          setRestaurent(false);
          setInventary(false);
          setExtraDetail(false);
          setPacking(false);
          setSelectBarcode(true);
          setItemDepartment(false);
          setProductTypeSKU(false);
          setSaleCategory(false);
          setCheckItemDetail(true);
          setCheckOnlineSale(false);
          setCheckCategoryDetail(false);
          setCheckAllowSerialNo(false);
          setCheckBatchWiseNo(false);
          setCheckExtraDetail(false);
          setCheckDeliverySetting(false);
          setDeliverySetting(false);
          setCheckSpexMeasurement(false);
          setCheckRestaurentDetail(false);
          setCheckProductTypeSKU(false);
          setLensAllow(true);
          setOptionalFieldDel(true);
          setMedicalStore(false);
          setInputVisibleLensItem(false);
          setInputVisible1(true);
          setInputVisible2(false);
          setInputVisible6(true);
          setInputVisible7(false);
          setInputVisible10(false);
          setInputVisible15(false);
          setInputVisible12(false);
          setInputVisible14(false);
          setCheckEcommerce(false);
          setInputVisibleMRP(false);
          setPriceUnit(true);
          break;
        // E-Com
        case 844:
          setRestaurentEcom(false);
          setInputVisible5(true);
          setInputVisible4(false);
          setInputVisible1(true);
          setInputVisible13(true);
          setInputVisible3(true);
          setCheckProductTypeSKU(false);
          setEcommerce(true);
          setRestaurent(true);
          setInventary(false);
          setExtraDetail(true);
          setPacking(false);
          setItemDepartment(false);
          setProductTypeSKU(true);
          setSaleCategory(true);
          setCheckItemDetail(true);
          setCheckOnlineSale(true);
          setCheckCategoryDetail(false);
          setCheckAllowSerialNo(false);
          setCheckBatchWiseNo(false);
          setCheckExtraDetail(true);
          setCheckDeliverySetting(true);
          setCheckSpexMeasurement(true);
          setCheckEcommerce(true);
          setLensAllow(false);
          setOptionalFieldDel(true);
          setStockValue(false);
          setStockQty(false);
          setExtraDetailEcom(true);
          setMedicalStore(false);
          setInputVisibleLensItem(false);
          setInputVisible1(true);
          setInputVisible2(false);
          setInputVisible6(true);
          setInputVisible7(false);
          setInputVisible10(false);
          setInputVisible15(false);
          setInputVisible12(false);
          setInputVisible14(false);
          setInputVisibleMRP(true);
          setPriceUnit(true);
          break;
        //Restaurant
        case 845:
        // Pharmacy
        case 846:
          setRestaurentEcom(false);
          setInputVisible5(false);
          setInputVisible4(false);
          setInputVisible1(true);
          setInputVisible13(false);
          setCheckProductTypeSKU(false);
          setEcommerce(false);
          setInventary(false);
          setExtraDetail(false);
          setPacking(true);
          setItemDepartment(false);
          setProductTypeSKU(false);
          setSaleCategory(false);
          setCheckItemDetail(true);
          setCheckOnlineSale(false);
          setCheckCategoryDetail(false);
          setCheckAllowSerialNo(false);
          setCheckBatchWiseNo(false);
          setCheckExtraDetail(false);
          setCheckDeliverySetting(false);
          setCheckSpexMeasurement(false);
          setCheckRestaurentDetail(false);
          setLensAllow(false);
          setOptionalFieldDel(true);
          showMedical(true);
          setMedicalStore(true);
          setInputVisible20(false);
          setAltUnit(true);
          setMultiGodown(true);
          setInputVisibleLensItem(false);
          setRestaurent(false);
          setInputVisible1(true);
          setInputVisible2(false);
          setInputVisible6(true);
          setInputVisible7(false);
          setInputVisible10(false);
          setInputVisible15(false);
          setInputVisible12(false);
          setInputVisible14(false);
          setCheckEcommerce(false);
          setInputVisibleMRP(false);
          setPriceUnit(true);
          break;
          // Restaurant

          setRestaurentEcom(true);
          setInputVisible5(false);
          setInputVisible4(false);
          setInputVisible1(true);
          setInputVisible13(true);
          setCheckProductTypeSKU(true);
          setEcommerce(false);
          setRestaurent(true);
          setInventary(false);
          setExtraDetail(false);
          setPacking(false);
          setItemDepartment(true);
          setProductTypeSKU(false);
          setSaleCategory(false);
          setAvailableTime(true);
          setCheckItemDetail(true);
          setCheckOnlineSale(true);
          setCheckCategoryDetail(false);
          setCheckAllowSerialNo(false);
          setCheckBatchWiseNo(false);
          setCheckExtraDetail(true);
          setCheckDeliverySetting(true);
          setCheckSpexMeasurement(false);
          setCheckRestaurentDetail(true);
          setCheckEcommerce(false);
          setLensAllow(false);
          setOptionalFieldDel(true);
          setAltUnit(false);
          setUnit1(true);
          setMultiGodown(true);
          setDefaultBarcode(true);
          setMedicalStore(false);
          setInputVisibleLensItem(false);
          setInputVisible1(true);
          setInputVisible2(false);
          setInputVisible6(true);
          setInputVisible7(false);
          setInputVisible10(false);
          setInputVisible15(false);
          setInputVisible12(false);
          setInputVisible14(false);
          setInputVisibleMRP(false);
          setMultiplePrinceList(false);
          setPriceUnit(false);
          setExtraDetailEcom(false);
          break;
        default:
          console.log(`No field configured for value ${selectedValue}`);
          break;
      }
    });
  };


  //*********************{Button Script}*********************//

  // State to track which option is currently shown
  const [showThis, setShowThis] = useState(inputValues.IsFavorite);

  // Function to toggle between options
  const toggleOption = () => {
    setShowThis((prevShowThis) => !prevShowThis);
    setInputValues((prevValues) => ({
      ...prevValues,
      IsFavorite: !prevValues.IsFavorite,
    }));
  };
  // ************************{Modal Back}**************************
  const handleModalClick = (e) => {
    // Prevent the event from propagating to parent elements
    e.stopPropagation();
  };

  // ***********************{Optional Fields}************************

  const [customfields, setCustomefields] = useState([]);

  const customFieldAddBtn = () => {
    const val = document.getElementById("CustomFieldInput").value;
    // console.log(val);
    if (val.trim() !== "") {
      const newData = { id: customfields.length + 1, FieldName: val };
      setCustomefields([...customfields, newData]);
      document.getElementById("inputField").style.display = "none";
      document.getElementById("CustomFieldInput").value = "";
    } else {
      alert("Please Enter valid Name", "ok");
    }
  };

  const AddCustmField_clicked = () => {
    document.getElementById("inputField").style.display = "block";
  };
  const [isInputVisible_0, setInputVisible111] = useState(false);
  const handleToggleChanges = ({ index }) => {
    //const val = "isInputVisible_" + index
    setInputVisible111(!isInputVisible_0);
  };

  const deleteValueById = (id) => {
    setCustomefields((prevState) => prevState.filter((item) => item.id !== id));
  };

  const [ItemMasterActive] = useState(true);

  const [isOfferScheme, setOfferScheme] = useState(true);
  const showScheme = () => {
    setOfferScheme(!isOfferScheme);
  };
  const [isDefaultBarcode, setDefaultBarcode] = useState(true);
  const showDefault = () => {
    setDefaultBarcode(!isDefaultBarcode);
  };
  const [isCostPrice, setCostPrice] = useState(true);
  const showCostP = () => {
    setCostPrice(!isCostPrice);
  };


  const [isLongDescriptionCheck, setLongDescriptionCheck] = useState(true);
  const handleLongCheck = () => {
    setLongDescriptionCheck(!isLongDescriptionCheck);
  };

  // ****************************{Shipping Enabled}******************************
  const [shippingEnabled, setShippingEnabled] = useState("false");

  // State to control the visibility of the modal
  const [isModalOpenEnable, setModalOpenEnable] = useState(true);

  // Function to handle the select change
  const handleSelectChangeShipping = (event) => {
    const selectedValue = event.target.value;
    setShippingEnabled(selectedValue);

    // Open or close the modal based on the selected value
    setModalOpenEnable(selectedValue === "true");
  };

  return (
    <div>
      {isMultiGodown && (
        <div>
          {isMultiGodownBlur && (
            <MultiGodown
              handleInputChange={handleInputChange}
              OpeningStock={OpeningStock}
              setOpeningStock={setOpeningStock}
              handleModalClick={handleModalClick}
              hideModal6={hideMultiGodownBlur}
            />
          )}
        </div>
      )}
      <ModalBoxMaster
        hideMiscellaneous={hideModal2}
        isModalMiscellaneous={isModalOpen2}
        handleModalClick={handleModalClick}
        hideUnit={hideUnit}
        isModalOpenUnit={isModalOpenUnit}
        hideGroupName={hideGroupName}
        isModalOpenGroupName={isModalOpenGroupName}
        hideModal2={hideGroupName}
        hideDepartment={hideDepartment}
        hideMeal={hideMeal}
        isMealCourse={isMealCourse}
        isDepartment={isDepartment}
        controlType={controlType}
      />
      <div>
        {/* <Joyride
          continuous
          callback={() => { }}
          run={run}
          steps={steps}
          hideCloseButton
          scrollToFirstStep
          showSkipButton
          showProgress
        /> */}
        {[].map((item) => {
          return (
            <div
              key={item}
              id={`step-${item}`}
              style={{
                border: "1px solid white",
                width: "100px",
                height: "100px",
                backgroundColor: "#2b2725c9",
                borderRadius: "8px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {item}
            </div>
          );
        })}
      </div>
      {/* <div style={{height:"48px"}}></div> */}
      <div className="panel-heading">
        <div className="panel-head-title">
          <h2 className="panel-title">Item Master</h2>
        </div>
        {/* ************************{Top Buttons}*********************** */}
        <MainButton
          showModal={showModal}
          isBtnSetting={true}
          modelOpen={props.modelOpen}
          SaveButton={SaveBtn}
          setSliderCallBack={props.setSliderCallBack}
          isBtnOpticalField={true}
        />

        {/* ***********************{Modal Box Open}********************** */}

        <PageTabs
          modelOpen={props.modelOpen}
          setSliderCallBack={props.setSliderCallBack}
          locationTab={"/ItemMaster"}
          showAttribute={showAttribute}
          isModalOpenAttribute={isModalOpenAttribute}
          showModal={showModal}
        />
        <div>
          {isModalOpen7 && <BatchWiseModalBox />}
          {isItemWiseList && (
            <ItemWiseBatchList
              handleModalClick={handleModalClick}
              hideModal6={hideModal6}
            />
          )}

          {/* ********************{Item Setting}************************ */}
        </div>
        <div>
          {isModalOpen && (
            <div onClick={hideModal} className="modal">
              <div onClick={handleModalClick} className="modal-content2">
                <div className="modal-title">
                  <h3 className="modal-h">Item Setting</h3>
                  <span className="close" onClick={hideModal}>
                    &times;
                  </span>
                </div>
                <div className="ModalBoxItem_FullHeight">
                  <div className="ModalBoxItem_Left">
                    <div className="modal-content-im ">
                      <div className="">
                        <div className="input-box-m1 modal-2">
                          <div className="cl-toggle-switch">
                            <label className="cl-switch">
                              <input
                                type="checkbox"
                                // onChange={handleToggleChange7}
                                // checked={isInputVisible7}
                                name="alias"
                                value={settingInputValues.alias}
                                onChange={handleSettingChange}
                                checked={settingInputValues.alias}
                              />
                              <span></span>
                            </label>
                            <label className="ModalLabeltext2">
                              Alias{" "}
                              <i className="fa-solid fa-circle-info ml-10"></i>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <div className="input-box-m1 modal-2">
                          <div className="cl-toggle-switch">
                            <label className="cl-switch">
                              <input
                                type="checkbox"
                                // checked={isInputVisible8}
                                // onChange={handleToggleChange8}
                                name="printName"
                                value={settingInputValues.printName}
                                onChange={handleSettingChange}
                                checked={settingInputValues.printName}
                              />
                              <span></span>
                            </label>
                            <label className="ModalLabeltext2">
                              Print Name
                              <i className="fa-solid fa-circle-info ml-10"></i>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <div className="input-box-m1 modal-2">
                          <div className="cl-toggle-switch">
                            <label className="cl-switch">
                              <input
                                type="checkbox"
                                // onChange={showSelectBarcode}
                                // checked={isSelectBarcode}
                                name="barcode"
                                value={settingInputValues.barcode}
                                onChange={handleSettingChange}
                                checked={settingInputValues.barcode}
                              />
                              <span></span>
                            </label>
                            <div style={{ display: "flex" }}>
                              <div>
                                <label className="ModalLabeltext2">
                                  Barcode{" "}
                                  <i className="fa-solid fa-circle-info ml-10"></i>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <div className="input-box-m1 modal-2">
                          <div className="cl-toggle-switch">
                            <label className="cl-switch">
                              <input
                                type="checkbox"
                                // onChange={showDefault}
                                // checked={isDefaultBarcode}
                                name="defaultBarcode"
                                value={settingInputValues.defaultBarcode}
                                onChange={handleSettingChange}
                                checked={settingInputValues.defaultBarcode}
                              />
                              <span></span>
                            </label>
                            <div style={{ display: "flex" }}>
                              <div>
                                <label className="ModalLabeltext2">
                                  Default Barcode
                                </label>
                              </div>
                              <div className="ItemTool2">
                                <ToolTipMaster isToolBarcode={true} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {isCheckProductTypeSKU && (
                        <div className="">
                          <div className="input-box-m1 modal-2">
                            <div className="cl-toggle-switch">
                              <label className="cl-switch">
                                <input
                                  type="checkbox"
                                  // onChange={ShowProductTypeSKU}
                                  // checked={isProductTypeSKU}
                                  name="productTypeSKU"
                                  value={settingInputValues.productTypeSKU}
                                  onChange={handleSettingChange}
                                  checked={settingInputValues.productTypeSKU}
                                />
                                <span></span>
                              </label>
                              <label className="ModalLabeltext2">
                                Product Type/SKU
                                <i className="fa-solid fa-circle-info ml-10"></i>
                              </label>
                            </div>
                          </div>
                        </div>
                      )}

                      {isRestaurentEcom && (
                        <div className="">
                          <div className="input-box-m1 modal-2">
                            <div className="cl-toggle-switch">
                              <label className="cl-switch">
                                <input
                                  type="checkbox"
                                  // onChange={handleToggleChange1}
                                  // checked={isInputVisible1}
                                  name="galleryImageUpload"
                                  onChange={handleSettingChange}
                                  value={settingInputValues.galleryImageUpload}
                                  checked={
                                    settingInputValues.galleryImageUpload
                                  }
                                />
                                <span></span>
                              </label>
                              <label className="ModalLabeltext2">
                                Gallary image Upload
                                <i className="fa-solid fa-circle-info ml-10"></i>
                              </label>
                            </div>
                          </div>
                        </div>
                      )}


                      <div className="">
                        <div className="input-box-m1 modal-2">
                          <div className="cl-toggle-switch">
                            <label className="cl-switch">
                              <input
                                type="checkbox"
                                // checked={isAltUnit}
                                // onChange={showAltUnit}
                                name="alternateUnit"
                                value={settingInputValues.alternateUnit}
                                onChange={handleSettingChange}
                                checked={settingInputValues.alternateUnit}
                              />
                              <span></span>
                            </label>
                            <label className="ModalLabeltext2">
                              Alternate Unit
                              <i className="fa-solid fa-circle-info ml-10"></i>
                            </label>
                          </div>
                        </div>
                      </div>

                      {isCheckRestaurentDetail && (
                        <div className="">
                          <div className="input-box-m1 modal-2">
                            <div className="cl-toggle-switch">
                              <label className="cl-switch">
                                <input
                                  type="checkbox"
                                  // onChange={Restaurent}
                                  // checked={isRestaurent}
                                  name="restaurantDetails"
                                  value={settingInputValues.restaurantDetails}
                                  onChange={handleSettingChange}
                                  checked={settingInputValues.restaurantDetails}
                                />
                                <span></span>
                              </label>
                              <label className="ModalLabeltext2">
                                Restaurant Details
                                <i className="fa-solid fa-circle-info ml-10"></i>
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                      {isInputVisible6 && (
                        <div className="">
                          <div className="input-box-m1 modal-2">
                            <div className="cl-toggle-switch">
                              <label className="cl-switch">
                                <input
                                  type="checkbox"
                                  // onChange={handleToggleChange2}
                                  // checked={isInputVisible2}
                                  name="doubleTax"
                                  value={settingInputValues.doubleTax}
                                  onChange={handleSettingChange}
                                  checked={settingInputValues.doubleTax}
                                />
                                <span></span>
                              </label>
                              <div className="" style={{ display: "flex" }}>
                                <div>
                                  <label className="ModalLabeltext2">
                                    Double Tax
                                  </label>
                                </div>
                                <div className="ItemTool2">
                                  <ToolTipMaster isToolDoubleTax={true} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {isInputVisible6 && (
                        <div className="">
                          <div className="input-box-m1 modal-2">
                            <div className="cl-toggle-switch">
                              <label className="cl-switch">
                                <input
                                  type="checkbox"
                                  // onChange={handleToggleChange10}
                                  // checked={isInputVisible10}
                                  name="taxCategory2"
                                  value={settingInputValues.taxCategory2}
                                  onChange={handleSettingChange}
                                  checked={settingInputValues.taxCategory2}
                                />
                                <span></span>
                              </label>
                              <div style={{ display: "flex" }}>
                                <div>
                                  <label className="ModalLabeltext2">
                                    Tax Category 2{" "}
                                  </label>
                                </div>
                                <div className="ItemTool2">
                                  <ToolTipMaster isToolSecondTax={true} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="">
                        <div className="input-box-m1 modal-2">
                          <div className="cl-toggle-switch">
                            <label className="cl-switch">
                              <input
                                type="checkbox"
                                // onChange={handleToggleChange3}
                                // checked={isInputVisible3}
                                name="shortDescription"
                                value={settingInputValues.shortDescription}
                                onChange={handleSettingChange}
                                checked={settingInputValues.shortDescription}
                              />
                              <span></span>
                            </label>
                            <label className="ModalLabeltext2">
                              Short Description
                              <i className="fa-solid fa-circle-info ml-10"></i>
                            </label>
                          </div>
                        </div>
                      </div>
                      {isInputVisible5 && (
                        <div className="">
                          <div className="input-box-m1 modal-2">
                            <div className="cl-toggle-switch">
                              <label className="cl-switch">
                                <input
                                  type="checkbox"
                                  // onChange={handleLongCheck}
                                  // checked={isLongDescriptionCheck}
                                  name="longDescription"
                                  value={settingInputValues.longDescription}
                                  onChange={handleSettingChange}
                                  checked={settingInputValues.longDescription}
                                />
                                <span></span>
                              </label>
                              <label className="ModalLabeltext2">
                                Long Description
                                <i className="fa-solid fa-circle-info ml-10"></i>
                              </label>
                            </div>
                          </div>
                        </div>
                      )}

                      {isCheckOnlineSale && (
                        <div className="">
                          <div className="input-box-m1 modal-2">
                            <div className="cl-toggle-switch">
                              <label className="cl-switch">
                                <input
                                  type="checkbox"
                                  // onChange={handleToggleChange5}
                                  // checked={isInputVisible5}
                                  name="onlineSale"
                                  value={settingInputValues.onlineSale}
                                  onChange={handleSettingChange}
                                  checked={settingInputValues.onlineSale}
                                />
                                <span></span>
                              </label>
                              <label className="ModalLabeltext2">
                                Online Sale
                                <i className="fa-solid fa-circle-info ml-10"></i>
                              </label>
                            </div>
                          </div>
                        </div>
                      )}

                    </div>
                  </div>
                  <div className="ModalBoxItem_Right">
                    <div className="modal-content-im ">
                      <div className="">
                        <div className="input-box-m1 modal-1">
                          <div className="cl-toggle-switch">
                            <label className="cl-switch">
                              <input
                                type="checkbox"
                                // onChange={showExtraDetail}
                                // checked={isExtraDetail}
                                name="extraDetails"
                                value={settingInputValues.extraDetails}
                                onChange={handleSettingChange}
                                checked={settingInputValues.extraDetails}
                              />
                              <span></span>
                            </label>
                            <label className="ModalLabeltext2">
                              Extra Details
                              <i className="fa-solid fa-circle-info ml-10"></i>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="">
                        <div className="input-box-m1 modal-1">
                          <div className="cl-toggle-switch">
                            <label className="cl-switch">
                              <input
                                type="checkbox"
                                // onChange={showScheme}
                                // checked={isOfferScheme}
                                name="offerNSchemeSetting"
                                value={settingInputValues.offerNSchemeSetting}
                                onChange={handleSettingChange}
                                checked={settingInputValues.offerNSchemeSetting}
                              />
                              <span></span>
                            </label>
                            <label className="ModalLabeltext2">
                              Offer & Scheme Setting
                              <i className="fa-solid fa-circle-info ml-10"></i>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <div className="input-box-m1 modal-2">
                          <div className="cl-toggle-switch">
                            <label className="cl-switch">
                              <input
                                type="checkbox"
                                // onChange={handleToggleChange12}
                                // checked={isInputVisible12}
                                name="priceNStockDetails"
                                value={settingInputValues.priceNStockDetails}
                                onChange={handleSettingChange}
                                checked={settingInputValues.priceNStockDetails}
                              />
                              <span></span>
                            </label>
                            <label className="ModalLabeltext2">
                              Price & Stock Details
                              <i className="fa-solid fa-circle-info ml-10"></i>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <div className="input-box-m1 modal-2">
                          <div className="cl-toggle-switch">
                            <label className="cl-switch">
                              <input
                                type="checkbox"
                                // onChange={showCostP}
                                // checked={isCostPrice}
                                name="costPrice"
                                value={settingInputValues.costPrice}
                                onChange={handleSettingChange}
                                checked={settingInputValues.costPrice}
                              />
                              <span></span>
                            </label>
                            <label className="ModalLabeltext2">
                              Cost Price
                              <i className="fa-solid fa-circle-info ml-10"></i>
                            </label>
                          </div>
                        </div>
                      </div>

                      {isCheckDeliverySetting && (
                        <div className="">
                          <div className="input-box-m1 modal-1">
                            <div className="cl-toggle-switch">
                              <label className="cl-switch">
                                <input
                                  type="checkbox"
                                  // onChange={showDeliverySetting}
                                  // checked={isDeliverySetting}
                                  name="shippingDetails"
                                  value={settingInputValues.shippingDetails}
                                  onChange={handleSettingChange}
                                  checked={settingInputValues.shippingDetails}
                                />
                                <span></span>
                              </label>
                              <label className="ModalLabeltext2">
                                Shipping Details
                                <i className="fa-solid fa-circle-info ml-10"></i>
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                      {isCheckSpexMeasurement && (
                        <div className="">
                          <div className="input-box-m1 modal-1">
                            <div className="cl-toggle-switch">
                              <label className="cl-switch">
                                <input
                                  type="checkbox"
                                  // onChange={showSpexMeasurement}
                                  // checked={isSpexMeasurement}
                                  name="spexMeasurement"
                                  value={settingInputValues.spexMeasurement}
                                  onChange={handleSettingChange}
                                  checked={settingInputValues.spexMeasurement}
                                />
                                <span></span>
                              </label>
                              <label className="ModalLabeltext2">
                                Spex Measurement
                                <i className="fa-solid fa-circle-info ml-10"></i>
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                      {isCheckEcommerce && (
                        <div className="">
                          <div className="input-box-m1 modal-1">
                            <div className="cl-toggle-switch">
                              <label className="cl-switch">
                                <input
                                  type="checkbox"
                                  // onChange={Ecommerce}
                                  // checked={isEcommerce}
                                  name="ecomBasedExtra"
                                  value={settingInputValues.ecomBasedExtra}
                                  onChange={handleSettingChange}
                                  checked={settingInputValues.ecomBasedExtra}
                                />
                                <span></span>
                              </label>
                              <label className="ModalLabeltext2">
                                E-com Base Extra
                                <i className="fa-solid fa-circle-info ml-10"></i>
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                      {isCheckItemDetail && (
                        <div className="">
                          <div className="input-box-m1 modal-2">
                            <div className="cl-toggle-switch">
                              <label className="cl-switch">
                                <input
                                  type="checkbox"
                                  // onChange={handleToggleChange4}
                                  // checked={isInputVisible4}
                                  name="serialBatchNStockSettings"
                                  value={
                                    settingInputValues.serialBatchNStockSettings
                                  }
                                  onChange={handleSettingChange}
                                  checked={
                                    settingInputValues.serialBatchNStockSettings
                                  }
                                />
                                <span></span>
                              </label>
                              <label className="ModalLabeltext2">
                                Serial,Batch & Stock Settings{" "}
                                <i className="fa-solid fa-circle-info ml-10"></i>
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                      {isCheckAllowSerialNo && (
                        <div className="">
                          <div className="input-box-m1 modal-1">
                            <div className="cl-toggle-switch">
                              <label className="cl-switch">
                                <input
                                  type="checkbox"
                                  // onChange={handleToggleChange14}
                                  // checked={isInputVisible14}
                                  name="allowSerialNoWiseDetails"
                                  value={
                                    settingInputValues.allowSerialNoWiseDetails
                                  }
                                  onChange={handleSettingChange}
                                  checked={
                                    settingInputValues.allowSerialNoWiseDetails
                                  }
                                />
                                <span></span>
                              </label>
                              <label className="ModalLabeltext2">
                                Allow Serial No. Wise Details
                                <i className="fa-solid fa-circle-info ml-10"></i>
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                      {isCheckBatchWiseNo && (
                        <div className="">
                          <div className="input-box-m1 modal-1">
                            <div className="cl-toggle-switch">
                              <label className="cl-switch">
                                <input
                                  type="checkbox"
                                  // onChange={handleToggleChange20}
                                  // checked={isInputVisible20}
                                  name="allowBatchwiseReporting"
                                  value={
                                    settingInputValues.allowBatchwiseReporting
                                  }
                                  onChange={handleSettingChange}
                                  checked={
                                    settingInputValues.allowBatchwiseReporting
                                  }
                                />
                                <span></span>
                              </label>
                              <label className="ModalLabeltext2">
                                Allow batch-wise reporting
                                <i className="fa-solid fa-circle-info ml-10"></i>
                              </label>
                            </div>
                          </div>
                        </div>
                      )}

                      {isCheckMinMax && (
                        <div className="">
                          <div className="input-box-m1 modal-1">
                            <div className="cl-toggle-switch">
                              <label className="cl-switch">
                                <input
                                  type="checkbox"
                                  // onChange={handleToggleChange16}
                                  // checked={isInputVisible16}
                                  name="allowToSetMinMaxReorderLevel"
                                  value={
                                    settingInputValues.allowToSetMinMaxReorderLevel
                                  }
                                  onChange={handleSettingChange}
                                  checked={
                                    settingInputValues.allowToSetMinMaxReorderLevel
                                  }
                                />
                                <span></span>
                              </label>
                              <label className="ModalLabeltext2">
                                Allow to set Min/Max/Reorder Level
                                <i className="fa-solid fa-circle-info ml-10"></i>
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="">
                        <div className="input-box-m1 modal-1">
                          <div className="cl-toggle-switch">
                            <label className="cl-switch">
                              <input
                                type="checkbox"
                                // onChange={handleToggleChange15}
                                // checked={isInputVisible15}
                                name="allowSetDefaultVendor"
                                value={settingInputValues.allowSetDefaultVendor}
                                onChange={handleSettingChange}
                                checked={
                                  settingInputValues.allowSetDefaultVendor
                                }
                              />
                              <span></span>
                            </label>
                            <label className="ModalLabeltext2">
                              Allow Set Default Vendor
                              <i className="fa-solid fa-circle-info ml-10"></i>
                            </label>
                          </div>
                        </div>
                      </div>

                      {isLensAllow && (
                        <div className="">
                          <div className="input-box-m1 modal-1">
                            <div className="cl-toggle-switch">
                              <label className="cl-switch">
                                <input
                                  type="checkbox"
                                  // onChange={handleToggleChangeLensItem}
                                  // checked={isInputVisibleLensItem}
                                  name="allowLensItem"
                                  value={settingInputValues.allowLensItem}
                                  onChange={handleSettingChange}
                                  checked={settingInputValues.allowLensItem}
                                />
                                <span></span>
                              </label>
                              <label className="ModalLabeltext2">
                                Allow lens Item
                                <i className="fa-solid fa-circle-info ml-10"></i>
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="">
                        <div className="input-box-m1 modal-1">
                          <div className="cl-toggle-switch">
                            <label className="cl-switch">
                              <input
                                type="checkbox"
                                // onChange={showAttribute}
                                // checked={isModalOpenAttribute}
                                name="allowAttributeItemName"
                                value={
                                  settingInputValues.allowAttributeItemName
                                }
                                onChange={handleSettingChange}
                                checked={
                                  settingInputValues.allowAttributeItemName
                                }
                              />
                              <span></span>
                            </label>
                            <div style={{ display: "flex" }}>
                              <div>
                                <label className="ModalLabeltext2">
                                  Allow Attribute Item Name{" "}
                                </label>
                              </div>
                              <div className="ItemTool4">
                                <ToolTipMaster isToolMultipleAttribute={true} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {isMultiplePrinceList && (
                        <div className="">
                          <div className="input-box-m1 modal-1">
                            <div className="cl-toggle-switch">
                              <label className="cl-switch">
                                <input
                                  type="checkbox"
                                  // onChange={showPriceUnit}
                                  // checked={isPriceUnit}
                                  name="allowMultiplePrice"
                                  value={settingInputValues.allowMultiplePrice}
                                  onChange={handleSettingChange}
                                  checked={settingInputValues.allowMultiplePrice}
                                />
                                <span></span>
                              </label>
                              <div style={{ display: "flex" }}>
                                <div>
                                  <label className="ModalLabeltext2">
                                    Allow Multiple Price
                                  </label>
                                </div>
                                <div className="ItemTool4">
                                  <ToolTipMaster isToolManagePrice={true} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="">
                        <div className="input-box-m1 modal-2">
                          <div className="cl-toggle-switch">
                            <label className="cl-switch">
                              <input
                                type="checkbox"
                                // onChange={showGodown}
                                // checked={isMultiGodown}
                                name="allowMultiGodown"
                                value={settingInputValues.allowMultiGodown}
                                onChange={handleSettingChange}
                                checked={settingInputValues.allowMultiGodown}
                              />
                              <span></span>
                            </label>
                            <div style={{ display: "flex" }}>
                              <div>
                                <label className="ModalLabeltext2">
                                  Allow Multi Godown{" "}
                                </label>
                              </div>
                              <div className="ItemTool2">
                                <ToolTipMaster isInfoGodown={true} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {isOptionalFieldDel && (
                        <div className="">
                          <div className="input-box-m1 modal-1">
                            <div className="cl-toggle-switch">
                              <label className="cl-switch">
                                <input
                                  type="checkbox"
                                  // onChange={showOptionalField}
                                  // checked={isOptionalField}
                                  name="optionalField"
                                  value={settingInputValues.optionalField}
                                  onChange={handleSettingChange}
                                  checked={settingInputValues.optionalField}
                                />
                                <span></span>
                              </label>
                              <label className="ModalLabeltext2">
                                Optional Field
                                <i className="fa-solid fa-circle-info ml-10"></i>
                              </label>
                            </div>
                            {isOptionalField && (
                              <div className="">
                                <div className="input-box-m1 modal-1">
                                  <div
                                    style={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      paddingLeft: "30px",
                                      width: "100%",
                                    }}
                                  >
                                    {(() => {
                                      if (customfields.length !== 0) {
                                        return customfields.map(
                                          (fields, index) => (
                                            <div
                                              className="OptionalHeadTop"
                                              key={fields.id}
                                            >
                                              <div
                                                style={{
                                                  width: "70%",
                                                  display: "flex",
                                                  fontSize: "14px",
                                                }}
                                                className="cakcbox-label-1"
                                              >
                                                <span>{fields.FieldName}</span>
                                              </div>
                                              <div
                                                style={{ width: "20%" }}
                                                className="checkbox-btn-delete"
                                                type="button"
                                                onClick={() =>
                                                  deleteValueById(fields.id)
                                                }
                                              >
                                                <i
                                                  className="fa-regular fa-trash-can"
                                                  style={{
                                                    color: "#f90101",
                                                    cursor: "pointer",
                                                    fontWeight: "600",
                                                    paddingLeft: "10px",
                                                  }}
                                                ></i>
                                              </div>
                                            </div>
                                          )
                                        );
                                      }
                                    })()}
                                  </div>
                                  <hr />
                                  <div
                                    style={{ display: "none" }}
                                    id="inputField"
                                  >
                                    <div className="flex-center">
                                      <input
                                        style={{
                                          backgroundColor: "#0000001a",
                                          color: "white",
                                        }}
                                        type="text"
                                        id="CustomFieldInput"
                                      />
                                      <button
                                        className="Add_btn"
                                        onClick={customFieldAddBtn}
                                      >
                                        Add
                                      </button>
                                    </div>
                                  </div>
                                  <div className="flex-center">
                                    <label
                                      style={{
                                        color: "white",
                                        cursor: "pointer",
                                        fontWeight: "600",
                                      }}
                                      onClick={AddCustmField_clicked}
                                    >
                                      + Add Custom Field into Table
                                    </label>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {isModalOpen3 && (
        <div className="panel-bulk-upload">
          <div className="bulk-upload-m1">
            <div className="button-bulk-1">
              <p className="bulk-upload-p">
                Bulk Upload Items from a Purchase Bill Photo or from Excel
              </p>
            </div>
            <div className="button-bulk-2">
              <button className="item_upload">
                Item Upload
                <i className="fa-solid fa-file-export im-icon-2"></i>
              </button>
            </div>
            <div>
              <span className="close-btn" onClick={hideModal3}>
                &times;
              </span>
            </div>
          </div>
        </div>
      )}
      <div>
        <DateTime />
      </div>
      <div className="content_box">
        <div className="company-info-top-im">
          <div className="tab-head">
            <div id="tabs">
              {isInputVisible5 && (
                <div>
                  {ItemMasterActive && (
                    <div className="tab-btn-box">
                      <div
                        className={`tab  ${activeTab === 5 ? "active" : ""}`}
                        onClick={() => {
                          handleTabClick(5);
                          showtabs1();
                        }}
                      >
                        Product Info
                      </div>
                      {isInputVisible5 && (
                        <div
                          className={`tab ${activeTab === 9 ? "active" : ""}`}
                          onClick={() => {
                            handleTabClick(9);
                            showtabs9();
                          }}
                        >
                          Product attribute
                        </div>
                      )}

                      {isInputVisible5 && (
                        <div
                          className={`tab ${activeTab === 1 ? "active" : ""}`}
                          onClick={() => {
                            handleTabClick(1);
                            showtabs2();
                          }}
                        >
                          SEO
                        </div>
                      )}

                      {isInputVisible5 && (
                        <div
                          className={`tab ${activeTab === 3 ? "active" : ""}`}
                          onClick={() => {
                            handleTabClick(3);
                            showtabs4();
                          }}
                        >
                          Product Filter
                        </div>
                      )}
                      {isInputVisible5 && (
                        <div
                          className={`tab ${activeTab === 4 ? "active" : ""}`}
                          onClick={() => {
                            handleTabClick(4);
                            showtabs5();
                          }}
                        >
                          Related Products
                        </div>
                      )}
                      {isInputVisible5 && (
                        <div>
                          {isProductvariation && (
                            <div
                              className={`tab ${activeTab === 2 ? "active" : ""
                                }`}
                              onClick={() => {
                                handleTabClick(2);
                                showtabs3();
                              }}
                            >
                              Product Variation
                            </div>
                          )}
                        </div>
                      )}
                      {isInputVisible5 && (
                        <div
                          className={`tab ${activeTab === 6 ? "active" : ""}`}
                          onClick={() => {
                            handleTabClick(6);
                            showtabs6();
                          }}
                        >
                          Linked Products
                        </div>
                      )}
                      {isInputVisible5 && (
                        <div>
                          {isLPProduct && (
                            <div
                              className={`tab ${activeTab === 7 ? "active" : ""
                                }`}
                              onClick={() => {
                                handleTabClick(7);
                                showtabs7();
                              }}
                            >
                              LP Products
                            </div>
                          )}
                        </div>
                      )}
                      {isInputVisible5 && (
                        <div>
                          {isCLPProduct && (
                            <div
                              className={`tab ${activeTab === 8 ? "active" : ""
                                }`}
                              onClick={() => {
                                handleTabClick(8);
                                showtabs8();
                              }}
                            >
                              CLP Products
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
              <div className="tab-content-box">
                {tabcontent1 && (
                  <div className="tab-content-1">
                    <div className="company-info-top-left">
                      {/* *********************** {General Details} ********************** */}
                      <div className="company_info-text-im gd-1">
                        General Details
                        <div>
                          {showThis ? (
                            <i
                              onClick={toggleOption}
                              className="fa-solid fa-heart favoriteIcon"
                            ></i>
                          ) : (
                            <i
                              style={{ color: "black" }}
                              onClick={toggleOption}
                              className="fa-regular fa-heart favoriteIcon"
                            ></i>
                          )}
                        </div>
                        <div className="input-box-checkboxItem ">
                          <div className=" td-2">
                            <div className="ToggleSwitchActive td-3">
                              <label className="cl-switch">
                                <input type="checkbox"
                                  onChange={handleInputChangeActive}
                                  name="IsActive"
                                  checked={inputValues.IsActive}
                                  value={inputValues.IsActive}
                                />
                                <span></span>
                              </label>
                            </div>
                            <ToolTipMaster isActiveInactive={true} />
                          </div>
                        </div>
                        <div className="radio-btm-m3 ">
                          {ItemMasterActive && (
                            <div style={{ with: "200px", display: "flex" }}>
                              <div className="ItemGeneralSettingCol">
                                <input
                                  className="radio-btn-n1"
                                  type="radio"
                                  id="Single"
                                  name="lensType"
                                  value="single"
                                  checked={selectedOption === "single"}
                                  onChange={handleRadioChange}
                                />
                                <label className="radio-btn-n2" for="Single">
                                  Product
                                </label>
                              </div>
                              <div className="ItemGeneralSettingCol">
                                <input
                                  className="radio-btn-n1"
                                  type="radio"
                                  id="Progresive"
                                  name="lensType"
                                  value="bifocal"
                                  checked={selectedOption === "bifocal"}
                                  onChange={handleRadioChange}
                                />
                                <label
                                  className="radio-btn-n2"
                                  for="Progresive"
                                >
                                  Service
                                </label>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      {ItemMasterActive && (
                        <div className="">
                          <div className="HeadContentTitle">
                            {/* <div className="input-box-m11">
                              <select
                                className="modify_im_input mode_category"
                                ref={input1Ref}
                                onKeyDown={(event) =>
                                  handleKeyPress(event, input2Ref)
                                }
                                // value={inputValues.BusinessType}
                                // onChange={handleSelectChange01}
                                onChange={handleBusinessChange}
                                value={inputValues.BusinessType}
                                name="BusinessType"
                                type="text"
                                required
                              >
                                <option className="s-1 form-control" value="">
                                  Select All
                                </option>
                                <option className="s-1 form-control" value="0">
                                  My Inventory
                                </option>
                                <option className="s-1 form-control" value="1">
                                  My Optical
                                </option>
                                <option className="s-1 form-control" value="2">
                                  E-Com
                                </option>
                                <option className="s-1 form-control" value="3">
                                  Restaurant
                                </option>
                                <option className="s-1 form-control" value="4">
                                  Pharmacy
                                </option>
                              </select>
                              <label className="modifyTextLabel5">
                                Business Type
                              </label>
                            </div> */}

                            {(() => {
                              let val = "";
                              switch (true) {
                                case isInputVisible7:
                                  val = input3Ref;
                                  break;
                                case isInputVisible8:
                                  val = input4Ref;
                                  break;
                                default:
                                  val = input5Ref;
                              }
                              return (
                                <div className="input-box-m11 input-height">
                                  <input
                                    onBlur={showAttributeDetails}
                                    name="ProductName"
                                    value={inputValues.ProductName}
                                    ref={input2Ref}
                                    onChange={handleJointInputChange}
                                    onKeyDown={(event) =>
                                      handleKeyPress(event, val)
                                    }
                                    className="modify_im_input mode_category"
                                    type="text"
                                    required
                                  />
                                  <label className="modifyTextLabel">
                                    Name&nbsp;<span className="StarClr">*</span>
                                  </label>
                                </div>
                              );
                            })()}
                            {(() => {
                              let val = "";
                              switch (true) {
                                case isInputVisible8:
                                  val = input4Ref;
                                  break;
                                default:
                                  val = input5Ref;
                              }
                              return (
                                <>
                                  {isInputVisible7 && (
                                    <div className="input-box-m11 input-height ">
                                      <input
                                        className="modify_im_input mode_limit mode_category"
                                        ref={input3Ref}
                                        onKeyDown={(event) =>
                                          handleKeyPress(event, val)
                                        }
                                        type="text"
                                        onChange={handleInputChange}
                                        value={inputValues.ProductAlias}
                                        name="ProductAlias"
                                        required
                                      />
                                      <label className="modifyTextLabel">
                                        Alias
                                      </label>
                                    </div>
                                  )}
                                </>
                              );
                            })()}

                            {isInputVisible8 && (
                              <div className="input-box-m11">
                                <input
                                  className="modify_im_input mode_category"
                                  ref={input4Ref}
                                  onKeyDown={(event) =>
                                    handleKeyPress(event, input5Ref)
                                  }
                                  onChange={(e) => handlePrintChange(e)}
                                  value={inputValues.PrintName}
                                  name="PrintName"
                                  type="text"
                                  required
                                />
                                <label className="modifyTextLabel">
                                  Print Name
                                </label>
                              </div>
                            )}
                            <div className="input-box-m11 DirectFlex">
                              <div className="DirectWidth">
                                <select
                                  className="modify_im_input mode_category"
                                  ref={input5Ref}
                                  onChange={handleInputChange}
                                  value={inputValues.ParentGrp}
                                  name="ParentGrp"
                                  onKeyDown={(event) =>
                                    handleKeyPress(event, input6Ref)
                                  }
                                  type="text"
                                  required
                                >
                                  <option value="">--Select--</option>

                                  {ItemGroupList !== undefined &&
                                    ItemGroupList !== null
                                    && ItemGroupList.length > 0 ? (
                                    ItemGroupList.map((group, index) => (
                                      <option key={index} value={group.d2_Id}>
                                        {group.name}
                                      </option>
                                    ))
                                  ) : (
                                    <option value="">
                                      No account groups available
                                    </option>
                                  )}



                                </select>
                                <label className="ItemMasterLabelFix">
                                  Group Name&nbsp;
                                  <span className="StarClr">*</span>
                                </label>
                              </div>
                              <div onClick={showGroupName}>
                                <i className="fa-solid fa-circle-plus dm_4"></i>
                              </div>
                            </div>
                            {isUnit1 && (
                              <div className="input-box-m11 DirectFlex">
                                {(() => {
                                  let val = "";
                                  switch (true) {
                                    case isAltUnit:
                                      val = input7Ref;
                                      break;
                                    case isItemDepartment:
                                      val = input10Ref;
                                      break;
                                    case isProductTypeSKU:
                                      val = input11Ref;
                                      break;
                                    case isSelectBarcode:
                                      val = input14Ref;
                                      break;
                                    case isInputVisible6:
                                      val = input16Ref;
                                      break;
                                    case isStockQty:
                                      val = input68Ref;
                                      break;
                                    default:
                                  }
                                  return (
                                    <div className="DirectWidth">
                                      <select
                                        className="modify_im_input mode_category"
                                        ref={input6Ref}
                                        onKeyDown={(event) =>
                                          handleKeyPress(event, val)
                                        }
                                        onChange={handleInputChange}
                                        value={inputValues.ProductUnit}
                                        name="ProductUnit"
                                        type="text"
                                        placeholder=""
                                        required
                                      >
                                        <option value="">--Select--</option>
                                        {UnitMasterList !== undefined &&
                                          UnitMasterList.length > 0 ? (
                                          UnitMasterList.map((group, index) => (
                                            <option
                                              key={index}
                                              value={group.id}
                                            >
                                              {group.unitName}
                                            </option>
                                          ))
                                        ) : (
                                          <option value="">
                                            No units available
                                          </option>
                                        )}
                                      </select>
                                      <label className="modifyTextLabel5">
                                        Unit&nbsp;
                                        <span className="StarClr">*</span>
                                      </label>
                                    </div>
                                  );
                                })()}
                                <div onClick={showUnit}>
                                  <i className="fa-solid fa-circle-plus dm_4"></i>
                                </div>
                              </div>
                            )}

                            {/* ****************{Attribute Modal }************* */}

                            {isModalOpenAttribute && (
                              <div>
                                {isAttributeDetails && (
                                  <div
                                    onClick={hideAttributeDetails}
                                    className="modal"
                                  >
                                    <div
                                      onClick={handleModalClick}
                                      className="modal-content_Attribute_Fine"
                                    >
                                      <div className="modal-title">
                                        <div>
                                          <h3 className="modal-box-m1">
                                            Item Attribute Master
                                          </h3>
                                        </div>

                                        <div className="table-down-s1">
                                          <div className="table-down-s2">
                                            <button className="tableAttributBtn" onClick={saveItemAttrTemporary}>
                                              Save
                                            </button>
                                          </div>
                                          <div className="table-down-s2">
                                            <button className="tableAttributBtn">
                                              Clean Value
                                            </button>
                                          </div>
                                          {/* <div
                                            onClick={props.handleAddRow}
                                            className="table-down-s2"
                                          >
                                            <button className="tableAttributBtn">
                                              New Variation
                                            </button>
                                          </div>
                                          <div
                                            onClick={hideAttributeDetails}
                                            className="table-down-s2"
                                          >
                                            <button className="tableAttributBtn">
                                              Back
                                            </button>
                                          </div> */}
                                        </div>
                                        <span
                                          className="close-category"
                                          onClick={hideAttributeDetails}
                                        >
                                          &times;
                                        </span>
                                      </div>
                                      <div className="">
                                        <div className="tab-content-1">
                                          <div className=" control_flex_m1 item_scroll2">
                                            <ItemAttributeList
                                              handleAddRow={props.handleAddRow}
                                              AttributeList={AttributeList}
                                              getAttributeList={
                                                getAttributeList
                                              }
                                              updateData={updatedData}
                                              setAttributeListTemp={setAttributeListTemp}
                                              AttributeListTemp={AttributeListTemp}
                                              setAttributeList={setAttributeList}
                                              isAttributeDetails={isAttributeDetails}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}

                            {isAltUnit && (
                              <div
                                style={{ padding: "7px" }}
                                className="input-box-m11 DirectFlex"
                              >
                                <div
                                  style={{ position: "relative" }}
                                  className="input-box-m1_1 h22"
                                >
                                  <select
                                    className="modify_im_input mode_category"
                                    ref={input7Ref}
                                    onKeyDown={(event) =>
                                      handleKeyPress(event, input8Ref)
                                    }
                                    onChange={handleInputChange}
                                    value={inputValues.ProductAltUnit}
                                    name="ProductAltUnit"
                                    type="text"
                                    placeholder=""
                                    required
                                  >
                                    <option value="">--Select--</option>
                                    {UnitMasterList !== undefined &&
                                      UnitMasterList.length > 0 ? (
                                      UnitMasterList.map((group, index) => (
                                        <option key={index} value={group.id}>
                                          {group.unitName}
                                        </option>
                                      ))
                                    ) : (
                                      <option value="">
                                        No units available
                                      </option>
                                    )}
                                  </select>
                                  <label className="modifyTextLabel7">
                                    Alternate Unit
                                  </label>
                                </div>

                                {(() => {
                                  let val = "";
                                  switch (true) {
                                    case isPacking:
                                      val = input9Ref;
                                      break;
                                    case isItemDepartment:
                                      val = input10Ref;
                                      break;
                                    case isProductTypeSKU:
                                      val = input11Ref;
                                      break;
                                    case isSelectBarcode:
                                      val = input14Ref;
                                      break;
                                    default:
                                      val = input9Ref;
                                  }
                                  return (
                                    <div
                                      style={{ position: "relative" }}
                                      className="input-box-m1_2"
                                    >
                                      <input
                                        className="modify_im_input mode_limit mode_category"
                                        ref={input8Ref}
                                        onKeyDown={(event) =>
                                          handleKeyPress(event, val)
                                        }
                                        onChange={handleInputChange}
                                        value={inputValues.UnitConvFactor}
                                        name="UnitConvFactor"
                                        type="number"
                                        required
                                      />
                                      <label className="modifyTextLabel8">
                                        Factor
                                      </label>
                                    </div>
                                  );
                                })()}
                              </div>
                            )}

                            {(() => {
                              let val = "";
                              switch (true) {
                                case isItemDepartment:
                                  val = input10Ref;
                                  break;
                                case isProductTypeSKU:
                                  val = input11Ref;
                                  break;
                                case isSelectBarcode:
                                  val = input14Ref;
                                  break;
                                case isDefaultBarcode:
                                  val = input15Ref;
                                  break;
                                case isStockQty:
                                  val = input68Ref;
                                  break;
                                default:
                              }
                              return (
                                <>
                                  {isPacking && (
                                    <div className="input-box-m11 input-height">
                                      <input
                                        className="modify_im_input mode_category"
                                        ref={input9Ref}
                                        onKeyDown={(event) =>
                                          handleKeyPress(event, val)
                                        }
                                        // onChange={showPacking}
                                        onChange={handlePackingChange}
                                        value={inputValues.isPacking}
                                        name="isPacking"
                                        type="text"
                                        required
                                      />
                                      <label className="modifyTextLabel">
                                        Packing
                                      </label>
                                    </div>
                                  )}
                                </>
                              );
                            })()}

                            {isItemDepartment && (
                              <div className="input-box-m11 DirectFlex">
                                {(() => {
                                  let val = "";
                                  switch (true) {
                                    case isProductTypeSKU:
                                      val = input11Ref;
                                      break;
                                    case isSelectBarcode:
                                      val = input14Ref;
                                      break;
                                    case isDefaultBarcode:
                                      val = input15Ref;
                                      break;
                                    default:
                                  }
                                  return (
                                    <>
                                      <div className="DirectWidth">
                                        <select
                                          className="modify_im_input mode_category"
                                          ref={input10Ref}
                                          onKeyDown={(event) =>
                                            handleKeyPress(event, val)
                                          }
                                          // onChange={showItemDepartment}
                                          onChange={handleInputChange}
                                          value={inputValues.ItemDepartment}
                                          name="ItemDepartment"
                                          type="text"
                                          placeholder=""
                                          required
                                        >
                                          {/* <option value="">--Select--</option>
                                          {ItemDepartmentList !== undefined &&
                                            ItemDepartmentList.length > 0 ? (
                                            ItemDepartmentList.map(
                                              (group, index) => (
                                                <option
                                                  key={index}
                                                  value={group.id}
                                                >
                                                  {group.name}
                                                </option> */}
                                          <option value="">--Select--</option>
                                          {Array.isArray(ItemDepartmentList) && ItemDepartmentList.length > 0 ? (
                                            ItemDepartmentList.map((group, index) => (
                                              <option key={index} value={group.id}>
                                                {group.name}
                                              </option>
                                            )
                                            )
                                          ) : (
                                            <option value="">
                                              No departments available
                                            </option>
                                          )}
                                        </select>
                                        <label className="modifyTextLabel5">
                                          {" "}
                                          Item Department
                                        </label>
                                      </div>
                                    </>
                                  );
                                })()}
                                <div onClick={showDepartment}>
                                  <i className="fa-solid fa-circle-plus dm_4"></i>
                                </div>
                              </div>
                            )}

                            {isProductTypeSKU && (
                              <div className="input-box-m11">
                                <select
                                  className="modify_im_input mode_category"
                                  ref={input11Ref}
                                  onKeyDown={(event) =>
                                    handleKeyPress(event, input12Ref)
                                  }
                                  //   onChange={handleProductTypes}
                                  onChange={handleProductTypeChange}
                                  name="ProductType"
                                  value={inputValues.ProductType}
                                  type="text"
                                  placeholder=""
                                  required
                                >
                                  <option value="">--Select--</option>
                                  <option value="1">Simple Product</option>
                                  <option value="2">Group Product</option>
                                  <option value="3">Variable Product</option>
                                </select>
                                <label className="modifyTextLabel5">
                                  {" "}
                                  Product Types
                                </label>
                              </div>
                            )}
                            {(() => {
                              let val = "";
                              switch (true) {
                                case isSaleCategory:
                                  val = input13Ref;
                                  break;
                                case isSelectBarcode:
                                  val = input14Ref;
                                  break;
                                case isDefaultBarcode:
                                  val = input15Ref;
                                  break;
                                default:
                              }
                              return (
                                <>
                                  {isProductTypeSKU && (
                                    <div className="input-box-m11">
                                      <input
                                        className="modify_im_input mode_category"
                                        ref={input12Ref}
                                        onKeyDown={(event) =>
                                          handleKeyPress(event, val)
                                        }
                                        onChange={handleJointInputChange}
                                        value={inputValues.SKU}
                                        name="SKU"
                                        type="text"
                                        required
                                      />
                                      <label className="modifyTextLabel">SKU</label>
                                    </div>
                                  )}
                                </>
                              );
                            })()}

                            {(() => {
                              let val = "";
                              switch (true) {
                                case isSelectBarcode:
                                  val = input14Ref;
                                  break;
                                case isExtraDetailEcom:
                                  val = input46Ref;
                                  break;
                                default:
                              }
                              return (
                                <>
                                  {isSaleCategory && (
                                    <div className="input-box-m11">
                                      <select
                                        className="modify_im_input mode_category"
                                        ref={input13Ref}
                                        onKeyDown={(event) =>
                                          handleKeyPress(event, val)
                                        }
                                        onChange={handleProductCategoryChange}
                                        value={inputValues.ProductCategory}
                                        name="ProductCategory"
                                        type="text"
                                        required
                                      >
                                        <option value="0">
                                          General Product
                                        </option>
                                        <option value="1">Lens Product</option>
                                        <option value="2">
                                          Contact Lens Product
                                        </option>
                                      </select>
                                      <label className="modifyTextLabel5">
                                        {" "}
                                        Product Category
                                      </label>
                                    </div>
                                  )}
                                </>
                              );
                            })()}

                            {(() => {
                              let val = "";
                              switch (true) {
                                case isDefaultBarcode:
                                  val = input15Ref;
                                  break;
                                case isInputVisible6:
                                  val = input16Ref;
                                  break;
                                case isStockQty:
                                  val = input68Ref;
                                  break;
                                default:
                                  val = input16Ref;
                              }
                              return (
                                <>
                                  {isSelectBarcode && (
                                    <div className="input-box-m11">
                                      <select
                                        className="modify_im_input mode_category"
                                        ref={input14Ref}
                                        onKeyDown={(event) =>
                                          handleKeyPress(event, val)
                                        }
                                        onChange={handleInputChange}
                                        value={inputValues.EnableBarcode}
                                        name="EnableBarcode"
                                        type="text"
                                        required
                                      >
                                        <option value="true">Yes</option>
                                        <option value="false">No</option>
                                      </select>
                                      <label className="modifyTextLabel5">
                                        Barcode
                                      </label>
                                    </div>
                                  )}
                                </>
                              );
                            })()}

                            {(() => {
                              let val = "";
                              switch (true) {
                                case isInputVisible6:
                                  val = input16Ref;
                                  break;
                                case isRestaurent:
                                  val = input27Ref;
                                  break;
                                case isInputVisible14:
                                  val = input32Ref;
                                  break;
                                case isInputVisible4:
                                  val = input34Ref;
                                  break;
                                case isExtraDetailEcom:
                                  val = input46Ref;
                                  break;
                                case isStockQty:
                                  val = input68Ref;
                                  break;

                                default:
                                  val = input16Ref;
                              }
                              return (
                                <>
                                  {isDefaultBarcode && (
                                    <div className="input-box-m11">
                                      <input
                                        className="modify_im_input mode_category"
                                        ref={input15Ref}
                                        onKeyDown={(event) =>
                                          handleKeyPress(event, val)
                                        }
                                        onChange={handleInputChange}
                                        value={inputValues.DefaultBarcode}
                                        name="DefaultBarcode"
                                        type="text"
                                        required
                                      />
                                      <label className="modifyTextLabel">
                                        Default Barcode
                                      </label>
                                    </div>
                                  )}
                                </>
                              );
                            })()}
                          </div>

                          {/* *********************** {Tax Details} ******************** */}

                          <div className="company_info-text-im td-1">
                            Tax Details
                            <div className="input-box-checkbox1 ">
                              <div className=" td-2">
                                <div className="cl-toggle-switch td-3">
                                  <label className="cl-switch">
                                    <input
                                      type="checkbox"
                                      onChange={handleToggleChange6}
                                      checked={isInputVisible6}
                                    />
                                    <span></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          {isInputVisible6 && (
                            <div className="HeadContentInputBpx">
                              {(() => {
                                let val = "";
                                switch (true) {
                                  case isInputVisible2:
                                    val = input17Ref;
                                    break;
                                  case isInputVisible10:
                                    val = input20Ref;
                                    break;
                                  default:
                                    val = input24Ref;
                                }
                                return (
                                  <div className="input-box-m11">
                                    <select
                                      className="modify_im_input mode_category"
                                      ref={input16Ref}
                                      onKeyDown={(event) =>
                                        handleKeyPress(event, val)
                                      }
                                      onChange={handleInputChange}
                                      value={inputValues.TaxCategory1}
                                      name="TaxCategory1"
                                      type="text"
                                      placeholder=""
                                      required
                                    >
                                      <option value="">--Select--</option>
                                      {TaxCatgorList !== undefined &&
                                        TaxCatgorList.length > 0 ? (
                                        TaxCatgorList.map((tax, index) => (
                                          <option key={index} value={tax.id}>
                                            {tax.name}
                                          </option>
                                        ))
                                      ) : (
                                        <option value="">
                                          No tax categories available
                                        </option>
                                      )}
                                    </select>
                                    <label className="ItemMasterLabelFix">
                                      Tax Category 1
                                    </label>
                                  </div>
                                );
                              })()}
                              {(() => {
                                let val = "";
                                switch (true) {
                                  case isInputVisible10:
                                    val = input20Ref;
                                    break;
                                  default:
                                    val = input24Ref;
                                }
                                return (
                                  <>
                                    {isInputVisible2 && (
                                      <div className="input-box-m11">
                                        <input
                                          className="modify_im_input mode_limit mode_category"
                                          ref={input17Ref}
                                          onKeyDown={(event) =>
                                            handleKeyPress(event, val)
                                          }
                                          onChange={handleInputChange}
                                          value={inputValues.isToolDoubleTax}
                                          name="isToolDoubleTax"
                                          type="text"
                                          required
                                        />
                                        <label className="modifyTextLabel">
                                          Double Tax
                                        </label>
                                      </div>
                                    )}
                                  </>
                                );
                              })()}

                              <div className="input-box-m11">
                                <input
                                  className="modify_im_input mode_category disabledInput"
                                  type="text"
                                  value={TaxInfoData.localTaxCGST}
                                  readOnly
                                />
                                <label class="ItemMasterLabelFix">
                                  CGST Tax Rate(Local)
                                </label>
                              </div>
                              {(() => {
                                var Val = isInputVisible10
                                  ? input24Ref
                                  : input25Ref;
                                return (
                                  <>
                                    {isInputVisible10 && (
                                      <div className="input-box-m11">
                                        <select
                                          className="modify_im_input mode_category"
                                          ref={input20Ref}
                                          onKeyDown={(event) =>
                                            handleKeyPress(event)
                                          }
                                          onChange={handleInputChange}
                                          value={inputValues.TaxCategory2}
                                          name="TaxCategory2"
                                          type="text"
                                          placeholder=""
                                          required
                                        >
                                          <option value="">--Select--</option>
                                          {TaxCatgorList !== undefined &&
                                            TaxCatgorList.length > 0 ? (
                                            TaxCatgorList.map((tax, index) => (
                                              <option key={index} value={tax.id}>
                                                {tax.name}
                                              </option>
                                            ))
                                          ) : (
                                            <option value="">
                                              No tax categories available
                                            </option>
                                          )}
                                        </select>
                                        <label className="ItemMasterLabelFix">
                                          Tax Category 2
                                        </label>
                                      </div>
                                    )}
                                  </>
                                );
                              })()}

                              <div className="input-box-m11">
                                <input
                                  className="modify_im_input mode_limit mode_category disabledInput"
                                  type="text"
                                  value={TaxInfoData.localTaxSGST}
                                  readOnly
                                />
                                <label class="ItemMasterLabelFix">
                                  SGST Tax Rate(Local)
                                </label>
                              </div>

                              <div className="input-box-m11">
                                <input
                                  className="modify_im_input mode_category disabledInput"
                                  type="text"
                                  value={TaxInfoData.cessTax}
                                  readOnly
                                />
                                <label class="ItemMasterLabelFix">CESS Tax</label>
                              </div>

                              <div className="input-box-m11">
                                <input
                                  className="modify_im_input mode_category disabledInput"
                                  type="text"
                                  value={TaxInfoData.centralTaxIGST}
                                  readOnly
                                />
                                <label class="ItemMasterLabelFix">
                                  IGST Tax Rate(Central)
                                </label>
                              </div>
                              {(() => {
                                let val = "";
                                switch (true) {
                                  case isInput1Visible:
                                    val = input25Ref;
                                    break;
                                  case isInput2Visible:
                                    val = input25Ref;
                                    break;
                                  case isInputVisible10:
                                    val = input21Ref;
                                    break;
                                  default:
                                    val = input24Ref;
                                }
                                return (
                                  <div className="input-box-m11">

                                    <input
                                      className="modify_im_input mode_category disabledInput"
                                      type="text"
                                      value={TaxInfoData.taxOnMRP ? "Yes" : "No"}
                                      name="taxOnMRP"
                                      readOnly
                                    />
                                    <label class="ItemMasterLabelFix">
                                      Tax On MRP
                                    </label>
                                  </div>
                                );
                              })()}

                              {(() => {
                                let val = "";
                                switch (true) {
                                  case isInputVisible10:
                                    val = input21Ref;
                                    break;
                                  case isRestaurent:
                                    val = input27Ref;
                                    break;
                                  case isInputVisible14:
                                    val = input32Ref;
                                    break;
                                  case isExtraDetailEcom:
                                    val = input46Ref;
                                    break;
                                  case isStockQty:
                                    val = input68Ref;
                                    break;
                                  default:
                                    val = input34Ref;
                                }
                                return (
                                  <div className="input-box-m11">
                                    {isInput1Visible && (
                                      <div className="">
                                        <input
                                          className="modify_im_input mode_limit mode_category"
                                          ref={input25Ref}
                                          onKeyDown={(event) =>
                                            handleKeyPress(event, val)
                                          }
                                          onChange={handleInputChange}
                                          value={inputValues.HSNCode}
                                          name="HSNCode"
                                          type="text"
                                          required
                                        />
                                        <label className="modifyTextLabel">
                                          HSN Code
                                        </label>
                                      </div>
                                    )}
                                    {isInput2Visible && (
                                      <div>
                                        <input
                                          className="modify_im_input mode_limit mode_category"
                                          ref={input25Ref}
                                          onKeyDown={(event) =>
                                            handleKeyPress(event, val)
                                          }
                                          onChange={handleInputChange}
                                          // value={inputValues.}
                                          name="UnitConvFactor"
                                          type="text"
                                          required
                                        />
                                        <label className="modifyTextLabel">
                                          SAC Code
                                        </label>
                                      </div>
                                    )}
                                  </div>
                                );
                              })()}
                            </div>
                          )}

                          {isInputVisible3 && (
                            <div>
                              <div className="company_info-text-im">
                                Short Description&nbsp;
                                <span className="StarClr">*</span>
                              </div>
                              {/* <div className="input-box-editer"> */}
                              <div style={{ width: "100%", marginBottom: "15px" }}>
                                <JoditEditor
                                  ref={editorSd}
                                  value={shortDiscription}
                                  onChange={(newContent) =>
                                    setShortDiscription(newContent)
                                  }
                                />
                                {/* </div> */}
                              </div>
                            </div>
                          )}

                          {isLongDescriptionCheck && (
                            <div>
                              <div className="company_info-text-im">
                                Long Description
                              </div>
                              {/* <div className="input-box-editer"> */}
                              <div style={{ width: "100%", marginBottom: "15px" }}>
                                <JoditEditor
                                  ref={editorLd}
                                  value={longDescription}
                                  onChange={(newContent1) =>
                                    setLongDescription(newContent1)
                                  }
                                />
                              </div>
                            </div>
                            // </div>
                          )}
                          {isRestaurent && (
                            <div>
                              <div className="company_info-text-im td-1">
                                Details
                              </div>
                              <div className="HeadContentInputBpx">
                                <div className="input-box-m11">
                                  <input
                                    className="modify_im_input mode_limit mode_category"
                                    ref={input27Ref}
                                    onKeyDown={(event) =>
                                      handleKeyPress(event, input28Ref)
                                    }
                                    type="text"
                                    required
                                  />
                                  <label className="modifyTextLabel">
                                    Display Order
                                  </label>
                                </div>

                                <div className="input-box-m11 DirectFlex">
                                  <div className="DirectWidth">
                                    <select
                                      className="modify_im_input mode_category"
                                      ref={input28Ref}
                                      onKeyDown={(event) =>
                                        handleKeyPress(event, input29Ref)
                                      }
                                      type="text"
                                      required
                                    >
                                      <option value="">--Select--</option>
                                      {PreprationTimeList !== undefined &&
                                        PreprationTimeList.length > 0 ? (
                                        PreprationTimeList.map(
                                          (group, index) => (
                                            <option
                                              key={index}
                                              value={group.id}
                                            >
                                              {group.name}
                                            </option>
                                          )
                                        )
                                      ) : (
                                        <option value="">
                                          No preparation times available
                                        </option>
                                      )}
                                    </select>
                                    <label className="modifyTextLabel5">
                                      Preparation Time
                                    </label>
                                  </div>

                                  <div onClick={showModal2}>
                                    <i className="fa-solid fa-circle-plus dm_4"></i>
                                  </div>
                                </div>
                                <div className="input-box-m11 DirectFlex">
                                  {(() => {
                                    let val = "";
                                    switch (true) {
                                      case isStartDate:
                                        val = input30Ref;
                                        break;
                                      case isInputVisible4:
                                        val = input34Ref;
                                        break;

                                      case isStockQty:
                                        val = input68Ref;
                                        break;

                                      default:
                                        val = input29Ref;
                                    }
                                    return (
                                      <div className="DirectWidth">
                                        <select
                                          className="modify_im_input mode_category"
                                          ref={input29Ref}
                                          onKeyDown={(event) =>
                                            handleKeyPress(event, val)
                                          }
                                          type="text"
                                          required
                                        >
                                          <option value="">--Select--</option>
                                          {MealCourseList !== undefined &&
                                            MealCourseList.length > 0 ? (
                                            MealCourseList.map(
                                              (group, index) => (
                                                <option
                                                  key={index}
                                                  value={group.id}
                                                >
                                                  {group.name}
                                                </option>
                                              )
                                            )
                                          ) : (
                                            <option value="">
                                              No meal courses available
                                            </option>
                                          )}
                                        </select>
                                        <label className="modifyTextLabel5">
                                          Meal Course Type
                                        </label>
                                      </div>
                                    );
                                  })()}
                                  <div onClick={showMeal}>
                                    <i className="fa-solid fa-circle-plus dm_4"></i>
                                  </div>
                                </div>

                                {CompanyDetail.isEnblItmAvailDateChk === true && (
                                  <>
                                    {isStartDate && (
                                      <div className="input-box-m11 time_bnn2">
                                        <div style={{ display: "flex" }}>
                                          <input
                                            className="modify_im_input mode_limit mode_category"
                                            ref={input30Ref}
                                            onKeyDown={(event) =>
                                              handleKeyPress(event, input31Ref)
                                            }
                                            onChange={handleInputChange}
                                            value={inputValues.AvailableDateTo}
                                            name="AvailableDateTo"
                                            // onChange={showStartDate}
                                            type="date"
                                            required
                                          />
                                          <input
                                            className="modify_im_input mode_limit mode_category"
                                            ref={input31Ref}
                                            onKeyDown={(event) =>
                                              handleKeyPress(event, input32Ref)
                                            }
                                            onChange={handleInputChange}
                                            value={inputValues.AvailableDatefrom}
                                            name="AvailableDatefrom"
                                            type="date"
                                            required
                                          />
                                        </div>
                                        <label className="modifyTextLabel5">
                                          Available Start Date To End:
                                        </label>
                                      </div>
                                    )}
                                  </>
                                )}

                              </div>
                              {CompanyDetail.isEnblItmAvailTimeChk === true && (
                                <div>
                                  {isAvailableTime && (
                                    <div style={{ paddingBottom: "22px" }}>
                                      <div className="company_info-text-im">
                                        Available Time
                                      </div>
                                      <div className="tableOverflowItemMaster">
                                        <table className="table-t">
                                          <thead
                                            style={{ background: "#314989" }}
                                            className="thead-groupmaster"
                                          >

                                            <th
                                              style={{ minWidth: "50px" }}
                                              className="table-thIM1"
                                            >
                                              Sn No
                                            </th>
                                            <th className="table-thIM1">
                                              {" "}
                                              From
                                            </th>
                                            <th className="table-thIM1">To</th>
                                            <th
                                              style={{ minWidth: "55px" }}
                                              className="table-thIM1"
                                            >
                                              Add
                                            </th>
                                            <th
                                              style={{ minWidth: "54px" }}
                                              className="table-thIM1"
                                            >
                                              Delete
                                            </th>

                                          </thead>
                                          <tbody>
                                            {restoAvailableTime.map(
                                              (row, rowIndex) => (
                                                <tr key={rowIndex}>
                                                  <td
                                                    style={{ textAlign: "center" }}
                                                  >
                                                    {rowIndex + 1}
                                                  </td>
                                                  <td style={{ textAlign: "center" }}>
                                                    <input
                                                      className="table-input-12"
                                                      type="time"
                                                      step="1"
                                                      name={`changeFrom-${rowIndex}`}
                                                      value={row.availableTimefrom}
                                                      onChange={(e) =>
                                                        handleInputChangeTime2(
                                                          rowIndex,
                                                          e
                                                        )
                                                      }
                                                    />
                                                  </td>
                                                  <td style={{ textAlign: "center" }}>
                                                    <input
                                                      className="table-input-12"
                                                      type="time"
                                                      step="1"
                                                      name={`taxCatg1-${rowIndex}`}
                                                      value={row.availableTimeTo}
                                                      onChange={(e) =>
                                                        handleInputChangeTime(
                                                          rowIndex,
                                                          e
                                                        )
                                                      }
                                                    />
                                                  </td>
                                                  <td
                                                    style={{ textAlign: "center" }}
                                                  >
                                                    <div
                                                      className=""
                                                      onClick={() =>
                                                        handleAddRowTime()
                                                      }
                                                    >
                                                      <i className="fa-solid fa-square-plus"></i>
                                                    </div>
                                                  </td>
                                                  <td
                                                    style={{ textAlign: "center" }}
                                                  >
                                                    <button
                                                      className="btn-table1"
                                                      onClick={() =>
                                                        handleDeleteRowTime(
                                                          rowIndex
                                                        )
                                                      }
                                                    >
                                                      <i className="fa-solid fa-trash delete-btn"></i>
                                                    </button>
                                                  </td>
                                                </tr>
                                              )
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                          {isInputVisible4 && (
                            <div>
                              <div className="company_info-text-im">
                                Serial, Batch & Stock Settings
                              </div>
                              <div className="HeadContentInputBpx">
                                {(() => {
                                  let val = "";
                                  switch (true) {
                                    case isInputVisible20:
                                      val = input33Ref;
                                      break;
                                    case isInputVisible4:
                                      val = input34Ref;
                                      break;
                                    default:
                                      val = input34Ref;
                                  }
                                  return (
                                    <>
                                      {isInputVisible14 && (
                                        <div className="input-box-m11">
                                          <select
                                            className="modify_im_input mode_category"
                                            ref={input32Ref}
                                            onKeyDown={(event) =>
                                              handleKeyPress(event, val)
                                            }
                                            type="text"
                                            onChange={handleSelectChange3}
                                            required
                                          >
                                            <option value="false">No</option>
                                            <option value="true">Yes</option>
                                          </select>
                                          <label className="modifyTextLabel5">
                                            Serial No. Wise Detail
                                          </label>
                                        </div>
                                      )}
                                    </>
                                  );
                                })()}

                                {isInputVisible20 && (
                                  <div className="input-box-m11">
                                    <div>
                                      <select
                                        className="modify_im_input mode_category "
                                        ref={input33Ref}
                                        onKeyDown={(event) =>
                                          handleKeyPress(event, input34Ref)
                                        }
                                        onChange={handleSelectChange2}
                                        type="text"
                                        required
                                      >
                                        <option value="false">No</option>
                                        <option value="true">Yes</option>
                                      </select>
                                      <label className="modifyTextLabel5">
                                        Batch Wise Detail
                                      </label>
                                    </div>
                                  </div>
                                )}

                                {isInputVisible4 && (
                                  <div className="input-box-m11">
                                    <input
                                      className="modify_im_input mode_category"
                                      ref={input34Ref}
                                      onKeyDown={(event) =>
                                        handleKeyPress(event, input35Ref)
                                      }
                                      type="text"
                                      required
                                      onChange={handleInputChange}
                                      value={inputValues.Location}
                                      name="Location"
                                    />
                                    <label className="modifyTextLabel">
                                      Location
                                    </label>
                                  </div>
                                )}
                                {(() => {
                                  let val = "";
                                  switch (true) {
                                    case isInputVisible16:
                                      val = input36Ref;
                                      break;
                                    default:
                                      val = input41Ref;
                                  }
                                  return (
                                    <div className="input-box-m11">
                                      <input
                                        className="modify_im_input mode_limit mode_category"
                                        ref={input35Ref}
                                        onKeyDown={(event) =>
                                          handleKeyPress(event, val)
                                        }
                                        type="number"
                                        required
                                        onChange={handleInputChange}
                                        value={inputValues.RackNo}
                                        name="RackNo"
                                      />
                                      <label className="modifyTextLabel">
                                        Rack No
                                      </label>
                                    </div>
                                  );
                                })()}

                                {(() => {
                                  let val = "";
                                  switch (true) {
                                    case isModalOpen4:
                                      val = input37Ref;
                                      break;
                                    default:
                                      val = input41Ref;
                                  }
                                  return (
                                    <>
                                      {isInputVisible16 && (
                                        <div className="input-box-m11">
                                          <select
                                            className="modify_im_input mode_category"
                                            ref={input36Ref}
                                            onKeyDown={(event) =>
                                              handleKeyPress(event, val)
                                            }
                                            onChange={handleSelectChange}
                                            type="text"
                                            required
                                          >
                                            <option value="false">No</option>
                                            <option value="true">Yes</option>
                                          </select>
                                          <label className="modifyTextLabel5">
                                            Set Stock Reorder Level
                                          </label>
                                        </div>
                                      )}
                                    </>
                                  );
                                })()}

                                {isModalOpen4 && (
                                  <div className="input-box-m11">
                                    <input
                                      className="modify_im_input mode_limit mode_category"
                                      ref={input37Ref}
                                      onKeyDown={(event) =>
                                        handleKeyPress(event, input38Ref)
                                      }
                                      type="number"
                                      required
                                    />
                                    <label className="modifyTextLabel">
                                      Maximum Level(Qty.)
                                    </label>
                                  </div>
                                )}
                                {isModalOpen4 && (
                                  <div className="input-box-m11">
                                    <input
                                      className="modify_im_input mode_limit mode_category"
                                      ref={input38Ref}
                                      onKeyDown={(event) =>
                                        handleKeyPress(event, input39Ref)
                                      }
                                      type="number"
                                      required
                                    />
                                    <label className="modifyTextLabel">
                                      Minimum Level(Qty.)
                                    </label>
                                  </div>
                                )}
                                {isModalOpen4 && (
                                  <div className="input-box-m11">
                                    <input
                                      className="modify_im_input mode_limit mode_category"
                                      ref={input39Ref}
                                      onKeyDown={(event) =>
                                        handleKeyPress(event, input41Ref)
                                      }
                                      type="text"
                                      required
                                    />
                                    <label className="modifyTextLabel">
                                      Reorder Level(Qty.)
                                    </label>
                                  </div>
                                )}
                                {isModalOpen4 && (
                                  <div className="input-box-m11">
                                    <label
                                      style={{ color: "red" }}
                                      className="modifyTextLabel6"
                                    >
                                      Enable Low Stock Warning
                                    </label>
                                    <div className="cl-toggle-switch">
                                      <label className="cl-switch">
                                        <input
                                          type="checkbox"
                                          onChange={handleToggleChange}
                                          checked={isInputVisible}
                                        />
                                        <span></span>
                                      </label>
                                    </div>
                                  </div>
                                )}
                                <div className="input-box-m11">
                                  <select
                                    className="modify_im_input mode_category"
                                    ref={input41Ref}
                                    onKeyDown={(event) =>
                                      handleKeyPress(event, input42Ref)
                                    }
                                    onChange={handleInputChange}
                                    value={inputValues.IsReturnable}
                                    name="IsReturnable"
                                    type="text"
                                    required
                                  >
                                    <option value="false">No</option>
                                    <option value="true">Yes</option>
                                  </select>
                                  <label className="modifyTextLabel5">
                                    Returnable
                                  </label>
                                </div>
                                <div className="input-box-m11">
                                  <select
                                    className="modify_im_input mode_category"
                                    ref={input42Ref}
                                    onKeyDown={(event) =>
                                      handleKeyPress(event, input43Ref)
                                    }
                                    onChange={handleInputChange}
                                    value={inputValues.IsConsumable}
                                    name="IsConsumable"
                                    type="text"
                                    required
                                  >
                                    <option value="false">No</option>
                                    <option value="true">Yes</option>
                                  </select>
                                  <label className="modifyTextLabel5">
                                    Consumable
                                  </label>
                                </div>

                                <div className="input-box-m11">
                                  <select
                                    className="modify_im_input mode_limit mode_category"
                                    ref={input43Ref}
                                    onKeyDown={(event) =>
                                      handleKeyPress(event, input44Ref)
                                    }
                                    type="text"
                                    required
                                    onChange={handleInputChange}
                                    value={inputValues.IsStockable}
                                    name="IsStockable"
                                  >
                                    <option value="false">No</option>
                                    <option value="true">Yes</option>
                                  </select>
                                  <label className="modifyTextLabel5">
                                    Stockable
                                  </label>
                                </div>
                                {(() => {
                                  let val = "";
                                  switch (true) {
                                    case isInputVisible15:
                                      val = input45Ref;
                                      break;
                                    case isStockQty:
                                      val = input68Ref;
                                      break;
                                    default:
                                  }
                                  return (
                                    <div className="input-box-m11">
                                      <select
                                        className="modify_im_input mode_category"
                                        ref={input44Ref}
                                        onKeyDown={(event) =>
                                          handleKeyPress(event, val)
                                        }
                                        onChange={handleInputChange}
                                        value={inputValues.AllowRawMaterial}
                                        name="AllowRawMaterial"
                                        type="text"
                                        required
                                      >
                                        <option value="false">No</option>
                                        <option value="true">Yes</option>
                                      </select>
                                      <label className="modifyTextLabel5">
                                        Allow Raw Material
                                      </label>
                                    </div>
                                  );
                                })()}
                                {(() => {
                                  let val = "";
                                  switch (true) {
                                    case isExtraDetailEcom:
                                      val = input46Ref;
                                      break;
                                    case isExtraDetail:
                                      val = input47Ref;
                                      break;
                                    case isStockQty:
                                      val = input68Ref;
                                      break;
                                    default:
                                  }
                                  return (
                                    <>
                                      {isInputVisible15 && (
                                        <div className="input-box-m11">
                                          <input
                                            className="modify_im_input mode_limit mode_category"
                                            ref={input45Ref}
                                            onKeyDown={(event) =>
                                              handleKeyPress(event, val)
                                            }
                                            type="text"
                                            required
                                            onChange={handleInputChange}
                                            value={inputValues.DefaultVendor}
                                            name="DefaultVendor"
                                          />
                                          <label className="modifyTextLabel">
                                            Specify Vendor
                                          </label>
                                        </div>
                                      )}
                                    </>
                                  );
                                })()}
                              </div>
                            </div>
                          )}


                          <div>
                            {isExtraDetail && (

                              <div className="company_info-text-im td-1">
                                Extra Details{" "}
                              </div>
                            )}
                            <div>
                              <div className="HeadContentInputBpx">
                                {isExtraDetail && (

                                  <>
                                    {(() => {
                                      let val = "";
                                      switch (true) {
                                        case isExtraDetail:
                                          val = input47Ref;
                                          break;
                                        default:
                                          val = input52Ref;
                                      }
                                      return (
                                        <>
                                          {isExtraDetailEcom && (
                                            <div className="input-box-m11">
                                              <select
                                                className="modify_im_input mode_category"
                                                ref={input46Ref}
                                                onKeyDown={(event) =>
                                                  handleKeyPress(event, val)
                                                }
                                                onChange={handleInputChange}
                                                value={
                                                  inputValues.ShowOnHomePage
                                                }
                                                name="ShowOnHomePage"
                                                type="text"
                                                required
                                              >
                                                <option value="false">
                                                  No
                                                </option>
                                                <option value="true">
                                                  Yes
                                                </option>
                                              </select>
                                              <label className="modifyTextLabel5">
                                                Show on home page
                                              </label>
                                            </div>
                                          )}
                                        </>
                                      );
                                    })()}
                                  </>
                                )}

                                {isExtraDetail && (
                                  <div className="input-box-m11">
                                    <select
                                      className="modify_im_input mode_category"
                                      ref={input47Ref}
                                      onKeyDown={(event) =>
                                        handleKeyPress(event, input48Ref)
                                      }
                                      onChange={handleInputChange}
                                      value={inputValues.DisableBuyButton}
                                      name="DisableBuyButton"
                                      type="text"
                                      required
                                    >
                                      <option value="false">No</option>
                                      <option value="true">Yes</option>
                                    </select>
                                    <label className="modifyTextLabel5">
                                      Disable Buy Button
                                    </label>
                                  </div>
                                )}
                                {isExtraDetail && (
                                  <div className="input-box-m11">
                                    <select
                                      className="modify_im_input mode_category"
                                      ref={input48Ref}
                                      onKeyDown={(event) =>
                                        handleKeyPress(event, input49Ref)
                                      }
                                      type="text"
                                      required
                                      onChange={handleInputChange}
                                      value={inputValues.DisableWishListButton}
                                      name="DisableWishListButton"
                                    >
                                      <option value="false">No</option>
                                      <option value="true">Yes</option>
                                    </select>
                                    <label className="modifyTextLabel5">
                                      Disable wishlist button
                                    </label>
                                  </div>
                                )}
                                {isExtraDetail && (
                                  <div className="input-box-m11">
                                    <select
                                      className="modify_im_input mode_category"
                                      ref={input49Ref}
                                      onKeyDown={(event) =>
                                        handleKeyPress(event, input50Ref)
                                      }
                                      type="text"
                                      required
                                      onChange={handleInputChange}
                                      value={inputValues.VisibleIndividually}
                                      name="VisibleIndividually"
                                    >
                                      <option value="false">No</option>
                                      <option value="true">Yes</option>
                                    </select>
                                    <label className="modifyTextLabel5">
                                      Visible Individually
                                    </label>
                                  </div>
                                )}
                                {isExtraDetail && (
                                  <div className="input-box-m11">
                                    <select
                                      className="modify_im_input mode_category"
                                      ref={input50Ref}
                                      onKeyDown={(event) =>
                                        handleKeyPress(event, input51Ref)
                                      }
                                      onChange={handleInputChange}
                                      value={inputValues.AllowCustomerReviews}
                                      name="AllowCustomerReviews"
                                      type="text"
                                      required
                                    >
                                      <option value="false">No</option>
                                      <option value="true">Yes</option>
                                    </select>
                                    <label className="modifyTextLabel5">
                                      Allow Customer Reviews
                                    </label>
                                  </div>
                                )}
                                {(() => {
                                  let val = "";
                                  switch (true) {
                                    case isExtraDetailEcom:
                                      val = input52Ref;
                                      break;
                                    case isExtraDetail:
                                      val = input53Ref;
                                      break;
                                    default:
                                  }
                                  return (
                                    <>
                                      {isExtraDetail && (
                                        <div className="input-box-m11">
                                          <select
                                            className="modify_im_input mode_category"
                                            ref={input51Ref}
                                            onKeyDown={(event) =>
                                              handleKeyPress(event, val)
                                            }
                                            onChange={handleInputChange}
                                            value={
                                              inputValues.AllowCustomerRemarks
                                            }
                                            name="AllowCustomerRemarks"
                                            type="text"
                                            required
                                          >
                                            <option value="false">No</option>
                                            <option value="true">Yes</option>
                                          </select>
                                          <label className="modifyTextLabel5">
                                            Allow Customer Remarks
                                          </label>
                                        </div>
                                      )}
                                    </>
                                  );
                                })()}
                                {(() => {
                                  let val = "";
                                  switch (true) {
                                    case isExtraDetail:
                                      val = input53Ref;
                                      break;
                                    case isStockQty:
                                      val = input68Ref;
                                      break;
                                    default:
                                      val = input70Ref;
                                  }
                                  return (
                                    <>
                                      {isExtraDetail && (
                                        <div className="input-box-m11">
                                          <select
                                            className="modify_im_input mode_category"
                                            ref={input52Ref}
                                            onKeyDown={(event) =>
                                              handleKeyPress(event, val)
                                            }
                                            onChange={handleInputChange}
                                            value={inputValues.Published}
                                            name="Published"
                                            type="text"
                                            required
                                          >
                                            <option value="false">No</option>
                                            <option value="true">Yes</option>
                                          </select>
                                          <label className="modifyTextLabel5">
                                            Published
                                          </label>
                                        </div>
                                      )}
                                    </>
                                  );
                                })()}
                                {isExtraDetail && (
                                  <div className="input-box-m11">
                                    <select
                                      className="modify_im_input mode_category"
                                      ref={input53Ref}
                                      onKeyDown={(event) =>
                                        handleKeyPress(event, input211Ref)
                                      }
                                      onChange={handleInputChange}
                                      value={inputValues.AddasComplementaryItem}
                                      name="AddasComplementaryItem"
                                      type="text"
                                      required
                                    >
                                      <option value="false">No</option>
                                      <option value="true">Yes</option>
                                    </select>
                                    <label className="modifyTextLabel5">
                                      Add as complementary Item
                                    </label>
                                  </div>
                                )}
                                {isExtraDetail && (
                                  <div className="input-box-m11">
                                    <select
                                      className="modify_im_input mode_category"
                                      ref={input211Ref}
                                      onKeyDown={(event) =>
                                        handleKeyPress(event, input212Ref)
                                      }
                                      type="text"
                                      onChange={handleInputChange}
                                      value={inputValues.AvailableForPOS}
                                      name="AvailableForPOS"
                                      required
                                    >
                                      <option value="false">No</option>
                                      <option value="true">Yes</option>
                                    </select>
                                    <label className="modifyTextLabel5">
                                      Available for POS
                                    </label>
                                  </div>
                                )}
                                {isExtraDetail && (
                                  <div className="input-box-m11">
                                    <select
                                      className="modify_im_input mode_category"
                                      ref={input212Ref}
                                      onKeyDown={(event) =>
                                        handleKeyPress(event, input54Ref)
                                      }
                                      onChange={handleInputChange}
                                      value={
                                        inputValues.AvailableForHomeDelivery
                                      }
                                      name="AvailableForHomeDelivery"
                                      type="text"
                                      required
                                    >
                                      <option value="false">No</option>
                                      <option value="true">Yes</option>
                                    </select>
                                    <label className="modifyTextLabel5">
                                      Available For Home Delivery
                                    </label>
                                  </div>
                                )}
                                {isExtraDetail && (
                                  <div className="input-box-m11">
                                    <select
                                      className="modify_im_input mode_category"
                                      ref={input212Ref}
                                      onKeyDown={(event) =>
                                        handleKeyPress(event, input54Ref)
                                      }
                                      onChange={handleInputChange}
                                      value={
                                        inputValues.AvailableForTableOrder
                                      }
                                      name="AvailableForTableOrder"
                                      type="text"
                                      required
                                    >
                                      <option value="false">No</option>
                                      <option value="true">Yes</option>
                                    </select>
                                    <label className="modifyTextLabel5">
                                      Available For Table Order
                                    </label>
                                  </div>
                                )}
                                {isExtraDetail && (
                                  <div className="input-box-m11">
                                    <select
                                      className="modify_im_input mode_category"
                                      ref={input54Ref}
                                      onKeyDown={(event) =>
                                        handleKeyPress(event, input55Ref)
                                      }
                                      type="text"
                                      required
                                      onChange={handleInputChange}
                                      value={
                                        inputValues.ItemAmtInComplementBonusAmt
                                      }
                                      name="ItemAmtInComplementBonusAmt"
                                    >
                                      <option value="false">No</option>
                                      <option value="true">Yes</option>
                                    </select>
                                    <label className="modifyTextLabel5">
                                      Item amt in complement bonus Amt
                                    </label>
                                  </div>
                                )}
                                {isExtraDetail && (
                                  <div className="input-box-m11">
                                    <select
                                      className="modify_im_input mode_category"
                                      ref={input55Ref}
                                      onKeyDown={(event) =>
                                        handleKeyPress(event, input56Ref)
                                      }
                                      onChange={handleInputChange}
                                      value={inputValues.Intermediate}
                                      name="Intermediate"
                                      type="text"
                                      required
                                    >
                                      <option value="false">No</option>
                                      <option value="true">Yes</option>
                                    </select>
                                    <label className="modifyTextLabel5">
                                      Intermediate
                                    </label>
                                  </div>
                                )}
                                {(() => {
                                  let val = "";
                                  switch (true) {
                                    case isDeliverySetting:
                                      val = input57Ref;
                                      break;
                                    case isStockQty:
                                      val = input68Ref;
                                      break;
                                    default:
                                  }
                                  return (
                                    <>
                                      {isExtraDetail && (
                                        <div className="input-box-m11">
                                          <input
                                            className="modify_im_input mode_limit mode_category"
                                            ref={input56Ref}
                                            onKeyDown={(event) =>
                                              handleKeyPress(event, val)
                                            }
                                            onChange={handleInputChange}
                                            value={
                                              inputValues.PopularCategoryPriority
                                            }
                                            name="PopularCategoryPriority"
                                            type="number"
                                            required
                                          />
                                          <label className="modifyTextLabel">
                                            Popular Category Priority
                                          </label>
                                        </div>
                                      )}
                                    </>
                                  );
                                })()}
                                {isExtraDetail && (
                                  <div className="input-box-m11 tag_dis">
                                    <div style={{ display: "flex" }}>
                                      <div style={{ width: "70%" }}>
                                        <input
                                          className="modify_im_input mode_category"
                                          placeholder="Product Tag"
                                          type="text"
                                          value={tagInput}
                                          onChange={handleTagInput}
                                          required
                                        />
                                      </div>
                                      <div style={{ width: "30%" }}>
                                        <button
                                          className="tag_btn"
                                          onClick={handleAddTag}
                                        >
                                          Add Tag
                                        </button>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        width: "290px",
                                        paddingTop: "2px",
                                      }}
                                    >
                                      {tags.map((tag, index) => (
                                        <div
                                          key={index}
                                          className="tag tag_manage_1"
                                          style={{
                                            display: "flex",
                                            gap: "2px",
                                            padding: "1px 10px",
                                          }}
                                        >
                                          {tag}
                                          <h6
                                            style={{ margin: "0px" }}
                                            className="tag_delete"
                                            onClick={() => handleDeleteTag(tag)}
                                          >
                                            <i
                                              style={{ cursor: "pointer" }}
                                              className="fa-solid fa-trash delete_icon"
                                            ></i>
                                          </h6>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          {isOptionalField && (
                            <div>
                              <div className="company_info-text-im">
                                Optional Field Details
                              </div>
                              <div className="HeadContentInputBpx">
                                {(() => {
                                  if (customfields.length !== 0) {
                                    return customfields.map((fields, index) => (
                                      <div
                                        className="input-box-m11"
                                        key={fields.id}
                                      >
                                        <input
                                          onChange={() =>
                                            handleToggleChanges(index)
                                          }
                                          name={`changeFrom-${fields.FieldName}`}
                                          className="modify_im_input mode_limit mode_category"
                                          type="text"
                                          required
                                        />
                                        <label className="modifyTextLabel">
                                          {fields.FieldName}
                                        </label>
                                      </div>
                                    ));
                                  }
                                })()}
                              </div>
                            </div>
                          )}

                          {isDeliverySetting && (
                            <div>
                              <div className="company_info-text-im td-1">
                                Shipping Detail
                              </div>
                              <div>
                                <div className="HeadContentInputBpx">
                                  <div className="input-box-m11">
                                    <select
                                      className="modify_im_input mode_category"
                                      ref={input57Ref}
                                      onKeyDown={(event) =>
                                        handleKeyPress(event, input58Ref)
                                      }
                                      type="text"
                                      //   value={shippingEnabled}
                                      onChange={handleSelectChangeShipping}
                                      //   onChange={handleInputChange}
                                      value={inputValues.shippingEnabled}
                                      name="shippingEnabled"
                                      required
                                    >
                                      <option value="false">No</option>
                                      <option value="true">Yes</option>
                                    </select>
                                    <label className="modifyTextLabel5">
                                      Shipping Enable
                                    </label>
                                  </div>
                                  {(() => {
                                    let val = "";
                                    switch (true) {
                                      case isModalOpenEnable:
                                        val = input59Ref;
                                        break;
                                      case isStockQty:
                                        val = input68Ref;
                                        break;
                                      default:
                                    }
                                    return (
                                      <div className="input-box-m11">
                                        <select
                                          className="modify_im_input mode_category"
                                          ref={input58Ref}
                                          onKeyDown={(event) =>
                                            handleKeyPress(event, val)
                                          }
                                          onChange={handleInputChange}
                                          value={
                                            inputValues.CashOnDeliveryAvailable
                                          }
                                          name="CashOnDeliveryAvailable"
                                          type="text"
                                          required
                                        >
                                          <option value="false">No</option>
                                          <option value="true">Yes</option>
                                        </select>
                                        <label className="modifyTextLabel5">
                                          Cash On Delivery Available
                                        </label>
                                      </div>
                                    );
                                  })()}

                                  {isModalOpenEnable && (
                                    <div className="input-box-m11">
                                      <select
                                        className="modify_im_input mode_category"
                                        ref={input59Ref}
                                        onKeyDown={(event) =>
                                          handleKeyPress(event, input60Ref)
                                        }
                                        onChange={handleInputChange}
                                        value={inputValues.FreeShipping}
                                        name="FreeShipping"
                                        type="text"
                                        required
                                      >
                                        <option value="false">No</option>
                                        <option value="true">Yes</option>
                                      </select>
                                      <label className="modifyTextLabel5">
                                        Free Shipping
                                      </label>
                                    </div>
                                  )}

                                  {isModalOpenEnable && (
                                    <div className="input-box-m11">
                                      <select
                                        className="modify_im_input mode_category"
                                        ref={input60Ref}
                                        onKeyDown={(event) =>
                                          handleKeyPress(event, input61Ref)
                                        }
                                        onChange={handleInputChange}
                                        value={inputValues.ShipSeparately}
                                        name="ShipSeparately"
                                        type="text"
                                        required
                                      >
                                        <option value="false">No</option>
                                        <option value="true">Yes</option>
                                      </select>
                                      <label className="modifyTextLabel5">
                                        Ship Separately
                                      </label>
                                    </div>
                                  )}
                                  {isModalOpenEnable && (
                                    <div className="input-box-m11">
                                      <input
                                        className="modify_im_input mode_category"
                                        ref={input61Ref}
                                        onKeyDown={(event) =>
                                          handleKeyPress(event, input62Ref)
                                        }
                                        onChange={handleInputChange}
                                        value={inputValues.LocalShippingCharge}
                                        name="LocalShippingCharge"
                                        type="number"
                                        required
                                      />
                                      <label className="modifyTextLabel">
                                        Local Shipping Charge
                                      </label>
                                    </div>
                                  )}
                                  {isModalOpenEnable && (
                                    <div className="input-box-m11">
                                      <input
                                        className="modify_im_input mode_category"
                                        ref={input62Ref}
                                        onKeyDown={(event) =>
                                          handleKeyPress(event, input63Ref)
                                        }
                                        onChange={handleInputChange}
                                        value={inputValues.ZonalShippingCharge}
                                        name="ZonalShippingCharge"
                                        type="number"
                                        required
                                      />
                                      <label className="modifyTextLabel">
                                        Zonal Shipping Charge
                                      </label>
                                    </div>
                                  )}
                                  {isModalOpenEnable && (
                                    <div className="input-box-m11">
                                      <input
                                        className="modify_im_input mode_category"
                                        ref={input63Ref}
                                        onKeyDown={(event) =>
                                          handleKeyPress(event, input64Ref)
                                        }
                                        onChange={handleInputChange}
                                        value={
                                          inputValues.NationalShippingCharge
                                        }
                                        name="NationalShippingCharge"
                                        type="number"
                                        required
                                      />
                                      <label className="modifyTextLabel">
                                        National Shipping charge
                                      </label>
                                    </div>
                                  )}
                                  {isModalOpenEnable && (
                                    <div className="input-box-m11">
                                      <input
                                        className="modify_im_input mode_category"
                                        ref={input64Ref}
                                        onKeyDown={(event) =>
                                          handleKeyPress(event, input65Ref)
                                        }
                                        onChange={handleInputChange}
                                        value={
                                          inputValues.ReturnApplicationDays
                                        }
                                        name="ReturnApplicationDays"
                                        type="number"
                                        required
                                      />
                                      <label className="modifyTextLabel">
                                        Return Applicable Days
                                      </label>
                                    </div>
                                  )}
                                  {isModalOpenEnable && (
                                    <div className="input-box-m11">
                                      <input
                                        className="modify_im_input mode_category"
                                        ref={input65Ref}
                                        onKeyDown={(event) =>
                                          handleKeyPress(event, input66Ref)
                                        }
                                        type="text"
                                        onChange={handleInputChange}
                                        value={inputValues.ReturnPolicyNotes}
                                        name="ReturnPolicyNotes"
                                        required
                                      />
                                      <label className="modifyTextLabel">
                                        Return policy Notes
                                      </label>
                                    </div>
                                  )}
                                  {isModalOpenEnable && (
                                    <div className="input-box-m11">
                                      <select
                                        className="modify_im_input mode_category"
                                        ref={input66Ref}
                                        onKeyDown={(event) =>
                                          handleKeyPress(event, input67Ref)
                                        }
                                        onChange={handleInputChange}
                                        value={inputValues.TryAtHome}
                                        name="TryAtHome"
                                        type="text"
                                        required
                                      >
                                        <option value="false">No</option>
                                        <option value="true">Yes</option>
                                      </select>
                                      <label className="modifyTextLabel5">
                                        Try At Home
                                      </label>
                                    </div>
                                  )}
                                  {isModalOpenEnable && (
                                    <div className="input-box-m11">
                                      <input
                                        className="modify_im_input mode_category"
                                        ref={input67Ref}
                                        onKeyDown={(event) =>
                                          handleKeyPress(event, input68Ref)
                                        }
                                        onChange={handleInputChange}
                                        value={
                                          inputValues.AdditionShippingCharge
                                        }
                                        name="AdditionShippingCharge"
                                        type="number"
                                        required
                                      />
                                      <label className="modifyTextLabel">
                                        Addition Shipping Charge
                                      </label>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>

                    {ItemMasterActive && (
                      <div
                        className="company-info-top-right"
                        style={{ width: "50%" }}
                      >
                        {isInputVisible13 && (
                          <div>
                            <div style={{}}>
                              <div className="company_info-text-im">
                                Category Details
                              </div>
                              <div className="drop-down-new ">
                                <CategoryDropdown
                                  catogorydata={props.data != undefined ? props.data.ecomCategoryDetail : []}
                                  setCheckedItemIds={setCheckedItemIds}
                                  variable={props.variable}
                                />
                              </div>
                            </div>
                          </div>
                        )}

                        <div>
                          <div className="company_info-text-im">
                            Price & Stock Details
                          </div>
                          <div className="HeadContentInputBpx">
                            {isStockQty && (
                              <div className="input-box-m11">
                                <input
                                  onBlur={MultiGodownBlur}
                                  ref={input68Ref}
                                  onKeyDown={(event) =>
                                    handleKeyPress(event, input69Ref)
                                  }
                                  className="modify_im_input mode_category"
                                  type="text"
                                  required
                                  onChange={handleInputChange}
                                  value={inputValues.OpeningStock}
                                  name="OpeningStock"
                                />
                                <label className="modifyTextLabel">
                                  {" "}
                                  Opening Stock(Qty)
                                </label>
                              </div>
                            )}
                            {isStockValue && (
                              <div className="input-box-m11">
                                <input
                                  className="modify_im_input mode_limit mode_category"
                                  ref={input69Ref}
                                  onKeyDown={(event) =>
                                    handleKeyPress(event, input70Ref)
                                  }
                                  onChange={handleInputChange}
                                  value={inputValues.OpeningStockValue}
                                  name="OpeningStockValue"
                                  type="number"
                                  required
                                />
                                <label className="modifyTextLabel">
                                  Opening Stock(Value)
                                </label>
                              </div>
                            )}
                            {(() => {
                              let val = "";
                              switch (true) {
                                case isCostPrice:
                                  val = input71Ref;
                                  break;
                                case isInputVisible12:
                                  val = input72Ref;
                                  break;

                                default:
                                  val = input73Ref;
                              }
                              return (
                                <div className="input-box-m11">
                                  <input
                                    className="modify_im_input mode_category"
                                    ref={input70Ref}
                                    onKeyDown={(event) =>
                                      handleKeyPress(event, val)
                                    }
                                    onChange={handleInputChange}
                                    value={inputValues.PurchasePrice}
                                    name="PurchasePrice"
                                    type="number"
                                    required
                                  />
                                  <label className="modifyTextLabel">
                                    Purchase Price{" "}
                                  </label>
                                </div>
                              );
                            })()}
                            {(() => {
                              let val = "";
                              switch (true) {
                                case isInputVisible12:
                                  val = input72Ref;
                                  break;
                                default:
                                  val = input73Ref;
                              }
                              return (
                                <>
                                  {isCostPrice && (
                                    <div className="input-box-m11">
                                      <input
                                        className="modify_im_input mode_category"
                                        ref={input71Ref}
                                        onKeyDown={(event) =>
                                          handleKeyPress(event, val)
                                        }
                                        onChange={handleInputChange}
                                        value={inputValues.CostPrice}
                                        name="CostPrice"
                                        type="number"
                                        required
                                      />
                                      <label className="modifyTextLabel">
                                        Cost Price{" "}
                                      </label>
                                    </div>
                                  )}
                                </>
                              );
                            })()}
                            {isInputVisible12 && (
                              <div className="input-box-m11">
                                <input
                                  className="modify_im_input mode_limit mode_category"
                                  ref={input72Ref}
                                  onKeyDown={(event) =>
                                    handleKeyPress(event, input73Ref)
                                  }
                                  type="number"
                                  required
                                  onChange={handleInputChange}
                                  value={inputValues.SaleProfit}
                                  name="SaleProfit"
                                />
                                <label className="modifyTextLabel">
                                  Sale Profit %
                                </label>
                              </div>
                            )}
                            {(() => {
                              let val = "";
                              switch (true) {
                                case isInputVisibleMRP:
                                  val = input74Ref;
                                  break;
                                case isInputVisible12:
                                  val = input75Ref;
                                  break;
                                case isPriceUnit:
                                  val = input200Ref;
                                  break;
                                case isOfferScheme:
                                  val = input90Ref;
                                  break;
                                case isMedicalStore:
                                  val = input100Ref;
                                  break;
                                default:
                                  val = input75Ref;
                              }

                              return (
                                <div className="input-box-m11">
                                  <input
                                    className="modify_im_input mode_category"
                                    ref={input73Ref}
                                    onKeyDown={(event) =>
                                      handleKeyPress(event, val)
                                    }
                                    type="number"
                                    onChange={handleInputChange}
                                    value={inputValues.SalePrice}
                                    name="SalePrice"
                                    required
                                  />
                                  <label className="modifyTextLabel">
                                    {" "}
                                    Sale Price
                                  </label>
                                </div>
                              );
                            })()}

                            {isInputVisibleMRP && (
                              <>
                                {(() => {
                                  let val = "";
                                  switch (true) {
                                    case isInputVisible12:
                                      val = input75Ref;
                                      break;
                                    case isPriceUnit:
                                      val = input200Ref;
                                      break;
                                    default:
                                  }
                                  return (
                                    <div className="input-box-m11">
                                      <input
                                        className="modify_im_input mode_limit mode_category"
                                        ref={input74Ref}
                                        onKeyDown={(event) =>
                                          handleKeyPress(event, val)
                                        }
                                        type="number"
                                        required
                                        onChange={handleInputChange}
                                        value={inputValues.MrpPrice}
                                        name="MrpPrice"
                                      />
                                      <label className="modifyTextLabel">
                                        MRP/Old Price
                                      </label>
                                    </div>
                                  );
                                })()}
                              </>
                            )}

                            {isInputVisible12 && (
                              <div className="input-box-m11">
                                <input
                                  className="modify_im_input mode_category"
                                  ref={input75Ref}
                                  onKeyDown={(event) =>
                                    handleKeyPress(event, input76Ref)
                                  }
                                  type="number"
                                  onChange={handleInputChange}
                                  value={inputValues.SaleDiscount}
                                  name="SaleDiscount"
                                  required
                                />
                                <label className="modifyTextLabel">
                                  Sale Discount
                                </label>
                              </div>
                            )}
                            {isInputVisible12 && (
                              <div className="input-box-m11">
                                <input
                                  className="modify_im_input mode_limit mode_category"
                                  ref={input76Ref}
                                  onKeyDown={(event) =>
                                    handleKeyPress(event, input77Ref)
                                  }
                                  type="number"
                                  onChange={handleInputChange}
                                  value={inputValues.PurchaseDiscount}
                                  name="PurchaseDiscount"
                                  required
                                />
                                <label className="modifyTextLabel">
                                  Purchase Discount
                                </label>
                              </div>
                            )}
                            {(() => {
                              let val = "";
                              switch (true) {
                                case isRestaurentEcom:
                                  val = input79Ref;
                                  break;
                                case isInputVisible12:
                                  val = input89Ref;
                                  break;
                                case isOfferScheme:
                                  val = input90Ref;
                                  break;
                                case isPriceUnit:
                                  val = input200Ref;
                                  break;
                                default:
                                  val = input90Ref;
                              }
                              return (
                                <>
                                  {isInputVisible12 && (
                                    <div className="input-box-m11">
                                      <input
                                        className="modify_im_input mode_category"
                                        ref={input77Ref}
                                        onKeyDown={(event) =>
                                          handleKeyPress(event, val)
                                        }
                                        onChange={handleInputChange}
                                        value={inputValues.MinSalePrice}
                                        name="MinSalePrice"
                                        type="number"
                                        required
                                      />
                                      <label className="modifyTextLabel">
                                        Min Sale Price{" "}
                                      </label>
                                    </div>
                                  )}
                                </>
                              );
                            })()}
                            {isRestaurentEcom && (
                              <div className="input-box-m11">
                                <input
                                  className="modify_im_input mode_category"
                                  ref={input79Ref}
                                  onKeyDown={(event) =>
                                    handleKeyPress(event, input80Ref)
                                  }
                                  type="number"
                                  required
                                  onChange={handleInputChange}
                                  value={inputValues.BranchPrice}
                                  name="BranchPrice"
                                />
                                <label className="modifyTextLabel">
                                  Branch Price{" "}
                                </label>
                              </div>
                            )}
                            {isRestaurentEcom && (
                              <div className="input-box-m11">
                                <input
                                  className="modify_im_input mode_category"
                                  ref={input80Ref}
                                  onKeyDown={(event) =>
                                    handleKeyPress(event, input81Ref)
                                  }
                                  onChange={handleInputChange}
                                  value={inputValues.FranchisePrice}
                                  name="FranchisePrice"
                                  type="number"
                                  required
                                />
                                <label className="modifyTextLabel">
                                  Franchise Price{" "}
                                </label>
                              </div>
                            )}
                            {isRestaurentEcom && (
                              <div className="input-box-m11">
                                <input
                                  className="modify_im_input mode_category"
                                  ref={input81Ref}
                                  onKeyDown={(event) =>
                                    handleKeyPress(event, input82Ref)
                                  }
                                  onChange={handleInputChange}
                                  value={inputValues.NormalLoss}
                                  name="NormalLoss"
                                  type="number"
                                  required
                                />
                                <label className="modifyTextLabel">
                                  Normal Loss
                                </label>
                              </div>
                            )}
                            {(() => {
                              let val = "";
                              switch (true) {
                                case isEcommerce:
                                  val = input83Ref;
                                  break;
                                case isInputVisible12:
                                  val = input89Ref;
                                  break;
                                default:
                              }
                              return (
                                <>
                                  {isRestaurentEcom && (
                                    <div className="input-box-m11">
                                      <input
                                        className="modify_im_input mode_category"
                                        ref={input82Ref}
                                        onKeyDown={(event) =>
                                          handleKeyPress(event, val)
                                        }
                                        type="number"
                                        required
                                        onChange={handleInputChange}
                                        value={inputValues.EcomSalePrice}
                                        name="EcomSalePrice"
                                      />
                                      <label className="modifyTextLabel">
                                        Online Sale Price
                                      </label>
                                    </div>
                                  )}
                                </>
                              );
                            })()}
                            {(() => {
                              let val = "";
                              switch (true) {
                                case isEcommerceVirtual:
                                  val = input84Ref;
                                  break;
                                case isEcommerce:
                                  val = input86Ref;
                                  break;
                                default:
                              }
                              return (
                                <>
                                  {isEcommerce && (
                                    <div className="input-box-m11">
                                      <select
                                        className="modify_im_input mode_category"
                                        ref={input83Ref}
                                        onChange={(e) => handleStockChange(e)}
                                        onKeyDown={(event) =>
                                          handleKeyPress(event, val)
                                        }
                                        type="text"
                                        required
                                        value={inputValues.StockCheck}
                                        name="StockCheck"
                                      >
                                        <option value="0">NA</option>
                                        <option value="1">Virtual Qty</option>
                                        <option value="2">Real Qty</option>
                                      </select>
                                      <label className="modifyTextLabel5">
                                        Stock Check
                                      </label>
                                    </div>
                                  )}
                                </>
                              );
                            })()}
                            {isEcommerceVirtual && (
                              <div className="input-box-m11">
                                <input
                                  className="modify_im_input mode_category"
                                  ref={input84Ref}
                                  onKeyDown={(event) =>
                                    handleKeyPress(event, input85Ref)
                                  }
                                  onChange={handleInputChange}
                                  value={inputValues.VirtualQuantity}
                                  type="number"
                                  name="VirtualQuantity"
                                  required
                                />
                                <label className="modifyTextLabel">
                                  Virtual Quantity
                                </label>
                              </div>
                            )}
                            {isEcommerceVirtual && (
                              <div className="input-box-m11">
                                <input
                                  className="modify_im_input mode_category"
                                  ref={input85Ref}
                                  onKeyDown={(event) =>
                                    handleKeyPress(event, input86Ref)
                                  }
                                  onChange={handleInputChange}
                                  value={inputValues.AvailableQuantity}
                                  type="number"
                                  name="AvailableQuantity"
                                  required
                                />
                                <label className="modifyTextLabel">
                                  Available Quantity
                                </label>
                              </div>
                            )}
                            {isEcommerce && (
                              <div className="input-box-m11">
                                <input
                                  className="modify_im_input mode_category"
                                  ref={input86Ref}
                                  onKeyDown={(event) =>
                                    handleKeyPress(event, input87Ref)
                                  }
                                  onChange={handleInputChange}
                                  value={inputValues.MinQtyToPurchase}
                                  name="MinQtyToPurchase"
                                  type="number"
                                  required
                                />
                                <label className="modifyTextLabel">
                                  Min Qty to Purchase
                                </label>
                              </div>
                            )}
                            {isEcommerce && (
                              <div className="input-box-m11">
                                <input
                                  className="modify_im_input mode_category"
                                  ref={input87Ref}
                                  onKeyDown={(event) =>
                                    handleKeyPress(event, input88Ref)
                                  }
                                  type="number"
                                  required
                                  onChange={handleInputChange}
                                  value={inputValues.MaxQtyToPurchase}
                                  name="MaxQtyToPurchase"
                                />
                                <label className="modifyTextLabel">
                                  Max Qty to Purchase
                                </label>
                              </div>
                            )}
                            {isEcommerce && (
                              <div className="input-box-m11">
                                <input
                                  className="modify_im_input mode_category"
                                  ref={input88Ref}
                                  onKeyDown={(event) =>
                                    handleKeyPress(event, input89Ref)
                                  }
                                  type="number"
                                  required
                                  onChange={handleInputChange}
                                  value={inputValues.IncrementQty}
                                  name="IncrementQty"
                                />
                                <label className="modifyTextLabel">
                                  Increment Qty
                                </label>
                              </div>
                            )}
                            {isInputVisible12 && (
                              <div className="input-box-m11">
                                {(() => {
                                  let val = "";
                                  switch (true) {
                                    case isPriceUnit:
                                      val = input200Ref;
                                      break;
                                    case isOfferScheme:
                                      val = input90Ref;
                                      break;
                                    default:
                                  }
                                  return (
                                    <>
                                      {!isMultiGodown && (
                                        <div className="">
                                          <select
                                            className="modify_im_input mode_category "
                                            ref={input89Ref}
                                            onKeyDown={(event) =>
                                              handleKeyPress(event, val)
                                            }
                                            type="text"
                                            required
                                          >
                                            <option value="No">
                                              --Select--
                                            </option>
                                            <option value="Yes">M1</option>
                                          </select>
                                          <label className="modifyTextLabel5">
                                            Godown
                                          </label>
                                        </div>
                                      )}
                                    </>
                                  );
                                })()}
                              </div>
                            )}

                            {isPriceUnit && (
                              <PriceList
                                inputValues={inputValues}
                                handleInputChange={handleInputChange}
                                input200Ref={input200Ref}
                                input201Ref={input201Ref}
                                input202Ref={input202Ref}
                                input203Ref={input203Ref}
                                input204Ref={input204Ref}
                                input205Ref={input205Ref}
                                input206Ref={input206Ref}
                                input207Ref={input207Ref}
                                input208Ref={input208Ref}
                                input209Ref={input209Ref}
                                input210Ref={input210Ref}
                                handleKeyPress={handleKeyPress}
                                input100Ref={input100Ref}
                                isMedicalStore={isMedicalStore}
                                input90Ref={input90Ref}
                                isOfferScheme={isOfferScheme}
                              />
                            )}
                          </div>
                        </div>
                        {isOfferScheme && (
                          <div>
                            <div className="company_info-text-im">
                              Offer & Scheme Details
                            </div>
                            <div className="stock-im-1">
                              <div className="HeadContentInputBpx">
                                <div className="input-box-m11">
                                  <input
                                    className="modify_im_input mode_limit mode_category"
                                    ref={input90Ref}
                                    onKeyDown={(event) =>
                                      handleKeyPress(event, input91Ref)
                                    }
                                    type="number"
                                    required
                                    onChange={handleInputChange}
                                    value={inputValues.LoyaltyPoint}
                                    name="LoyaltyPoint"
                                  />
                                  <label className="modifyTextLabel">
                                    Item Loyalty Point (Customer)
                                  </label>
                                </div>
                                <div className="input-box-m11">
                                  <input
                                    className="modify_im_input mode_category"
                                    ref={input91Ref}
                                    onKeyDown={(event) =>
                                      handleKeyPress(event, input92Ref)
                                    }
                                    type="number"
                                    onChange={handleInputChange}
                                    value={inputValues.ReferalAmountDirect}
                                    name="ReferalAmountDirect"
                                    required
                                  />
                                  <label className="modifyTextLabel">
                                    Referral Amount Direct
                                  </label>
                                </div>

                                <div className="input-box-m11">
                                  <input
                                    className="modify_im_input mode_limit mode_category"
                                    ref={input92Ref}
                                    onKeyDown={(event) =>
                                      handleKeyPress(event, input93Ref)
                                    }
                                    onChange={handleInputChange}
                                    value={inputValues.ReferalAmountIndirect}
                                    name="ReferalAmountIndirect"
                                    type="number"
                                    required
                                  />
                                  <label className="modifyTextLabel">
                                    Referral Amount Indirect
                                  </label>
                                </div>

                                <div className="input-box-m11">
                                  <input
                                    className="modify_im_input mode_category"
                                    ref={input93Ref}
                                    onKeyDown={(event) =>
                                      handleKeyPress(event, input94Ref)
                                    }
                                    type="number"
                                    onChange={handleInputChange}
                                    value={inputValues.SellerCommision}
                                    name="SellerCommision"
                                    required
                                  />
                                  <label className="modifyTextLabel">
                                    Seller Commission (Agent)
                                  </label>
                                </div>
                                {isOfferScheme && (
                                  <div className="input-box-m11">
                                    <input
                                      className="modify_im_input mode_category"
                                      ref={input94Ref}
                                      onKeyDown={(event) =>
                                        handleKeyPress(event, input95Ref)
                                      }
                                      onChange={handleInputChange}
                                      value={inputValues.SalesManItemIncentive}
                                      name="SalesManItemIncentive"
                                      type="number"
                                      required
                                    />
                                    <label className="modifyTextLabel">
                                      Item Incentive (Salesman){" "}
                                    </label>
                                  </div>
                                )}
                                {isOfferScheme && (
                                  <div className="input-box-m11">
                                    <div style={{ display: "flex" }}>
                                      <div>
                                        <input
                                          className="modify_im_input mode_category"
                                          ref={input95Ref}
                                          onKeyDown={(event) =>
                                            handleKeyPress(event, input96Ref)
                                          }
                                          type="number"
                                          onChange={handleInputChange}
                                          value={inputValues.BuyX}
                                          name="BuyX"
                                          required
                                        />
                                        <label className="modifyTextLabel">
                                          Buy X
                                        </label>
                                      </div>
                                      <span
                                        style={{
                                          fontSize: "24px",
                                          textAlign: "center",
                                          color: "#838181",
                                        }}
                                      >
                                        +
                                      </span>
                                      <div>
                                        <input
                                          className="modify_im_input mode_category"
                                          ref={input96Ref}
                                          onKeyDown={(event) =>
                                            handleKeyPress(event, input97Ref)
                                          }
                                          type="number"
                                          required
                                          onChange={handleInputChange}
                                          value={inputValues.GetX}
                                          name="GetX"
                                        />
                                        <label className="modifyTextLabel LeftSetting">
                                          Get X
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {isOfferScheme && (
                                  <div className="input-box-m11">
                                    <input
                                      className="modify_im_input mode_category"
                                      ref={input97Ref}
                                      onKeyDown={(event) =>
                                        handleKeyPress(event, input98Ref)
                                      }
                                      type="text"
                                      required
                                      onChange={handleInputChange}
                                      value={inputValues.OfferTagInCategory}
                                      name="OfferTagInCategory"
                                    />
                                    <label className="modifyTextLabel">
                                      Offer Tag in Category
                                    </label>
                                  </div>
                                )}
                                {isOfferScheme && (
                                  <div className="input-box-m11">
                                    <input
                                      className="modify_im_input mode_category"
                                      ref={input98Ref}
                                      onKeyDown={(event) =>
                                        handleKeyPress(event, input99Ref)
                                      }
                                      type="text"
                                      required
                                      onChange={handleInputChange}
                                      value={inputValues.OfferTagInProduct}
                                      name="OfferTagInProduct"
                                    />
                                    <label className="modifyTextLabel">
                                      Offer Tag in Product
                                    </label>
                                  </div>
                                )}
                                {(() => {
                                  let val = "";
                                  switch (true) {
                                    case isMedicalStore:
                                      val = input101Ref;
                                      break;
                                    case isSpexMeasurement:
                                      val = input110Ref;
                                      break;
                                    default:
                                  }
                                  return (
                                    <>
                                      {isOfferScheme && (
                                        <div className="input-box-m11">
                                          <select
                                            className="modify_im_input mode_category"
                                            ref={input99Ref}
                                            onKeyDown={(event) =>
                                              handleKeyPress(event, val)
                                            }
                                            onChange={handleInputChange}
                                            value={inputValues.SchemeType}
                                            name="SchemeType"
                                            type="text"
                                            placeholder=""
                                            required
                                          >
                                            <option value="">--Select--</option>
                                            <option value="0">
                                              Full Scheme
                                            </option>
                                            <option value="1">
                                              Half Scheme
                                            </option>
                                            <option value="2">
                                              1/3 Scheme
                                            </option>
                                            <option value="3">
                                              Qtr Scheme
                                            </option>
                                            <option value="4">
                                              Whole Scheme
                                            </option>
                                            <option value="5">No Scheme</option>
                                          </select>
                                          <label className="modifyTextLabel5">
                                            Scheme Type
                                          </label>
                                        </div>
                                      )}
                                    </>
                                  );
                                })()}
                              </div>
                            </div>
                          </div>
                        )}
                        {isMedicalStore && (
                          <div style={{ paddingBottom: "10px" }}>
                            <div className="company_info-text-im">
                              Drug Details
                            </div>
                            <div className="HeadContentInputBpx">
                              <div className="input-box-m11">
                                <input
                                  className="modify_im_input mode_category"
                                  ref={input100Ref}
                                  onKeyDown={(event) =>
                                    handleKeyPress(event, input101Ref)
                                  }
                                  type="text"
                                  required
                                />
                                <label className="modifyTextLabel"> Company</label>
                              </div>
                              <div className="input-box-m11">
                                <input
                                  className="modify_im_input mode_category"
                                  ref={input101Ref}
                                  onKeyDown={(event) =>
                                    handleKeyPress(event, input102Ref)
                                  }
                                  type="text"
                                  required
                                />
                                <label className="modifyTextLabel">Mfd. By</label>
                              </div>

                              <div className="input-box-m11 DirectFlex">
                                <div className="DirectWidth">
                                  <select
                                    className="modify_im_input mode_category"
                                    ref={input102Ref}
                                    onKeyDown={(event) =>
                                      handleKeyPress(event, input103Ref)
                                    }
                                    type="text"
                                    placeholder=""
                                    required
                                  >
                                    <option value="">--Select--</option>
                                    <option>Salt Name 1</option>
                                    <option>Salt Name 2</option>
                                  </select>
                                  <label className="modifyTextLabel5">
                                    Salt Name
                                  </label>
                                </div>
                                <div onClick={showModal2}>
                                  <i className="fa-solid fa-circle-plus dm_4"></i>
                                </div>
                              </div>
                              <div className="input-box-m11 DirectFlex">
                                <div className="DirectWidth">
                                  <select
                                    className="modify_im_input mode_category"
                                    ref={input103Ref}
                                    onKeyDown={(event) =>
                                      handleKeyPress(event, input104Ref)
                                    }
                                    type="text"
                                    placeholder=""
                                    required
                                  >
                                    <option value="">--Select--</option>
                                    <option>Manufacturer 1</option>
                                    <option>Manufacturer 2</option>
                                  </select>
                                  <label className="modifyTextLabel5">
                                    Manufacturer
                                  </label>
                                </div>
                                <div onClick={showModal2}>
                                  <i className="fa-solid fa-circle-plus dm_4"></i>
                                </div>
                              </div>

                              <div className="input-box-m11">
                                <select
                                  className="modify_im_input mode_category"
                                  ref={input104Ref}
                                  onKeyDown={(event) =>
                                    handleKeyPress(event, input105Ref)
                                  }
                                  type="text"
                                  placeholder=""
                                  required
                                >
                                  <option value="">--Select--</option>
                                  <option value="Normal">Normal</option>
                                  <option value="Costly">Costly</option>
                                  <option value="8">8°</option>
                                  <option value="24">24°</option>
                                </select>
                                <label className="modifyTextLabel5">
                                  Storage Type
                                </label>
                              </div>
                              <div className="input-box-m11">
                                <select
                                  className="modify_im_input mode_category"
                                  ref={input105Ref}
                                  onKeyDown={(event) =>
                                    handleKeyPress(event, input106Ref)
                                  }
                                  type="text"
                                  placeholder=""
                                  required
                                >
                                  <option value="">--Select--</option>
                                  <option value="Normal">Normal</option>
                                  <option value="TB">TB</option>
                                  <option value="Tramadol">Tramadol</option>
                                </select>
                                <label className="modifyTextLabel5">TB Item</label>
                              </div>
                            </div>
                            <div className="input-box-top-head">
                              <div className="">
                                <div className="TableTopCheckHead">
                                  <div className="flex">
                                    <input
                                      type="checkbox"
                                      className="radio-box-1 box-size1"
                                      ref={input106Ref}
                                      onKeyDown={(event) =>
                                        handleKeyPress(event, input107Ref)
                                      }
                                      id="R"
                                      value="R"
                                      style={{ height: "25px" }}
                                    />
                                    <label
                                      For="R"
                                      className="TableTopChecklabel"
                                    >
                                      &nbsp; Schedule H
                                    </label>
                                  </div>
                                  <div className="flex">
                                    <input
                                      type="checkbox"
                                      className="radio-box-1 box-size1"
                                      ref={input107Ref}
                                      onKeyDown={(event) =>
                                        handleKeyPress(event, input108Ref)
                                      }
                                      id="L"
                                      value="L"
                                      style={{ height: "25px" }}
                                    />
                                    <label
                                      className="TableTopChecklabel"
                                      For="L"
                                    >
                                      &nbsp; Schedule H-1
                                    </label>
                                  </div>
                                  <div className="flex">
                                    <input
                                      type="checkbox"
                                      className="radio-box-1 box-size1"
                                      ref={input108Ref}
                                      onKeyDown={(event) =>
                                        handleKeyPress(event, input109Ref)
                                      }
                                      id="L"
                                      value="L"
                                      style={{ height: "25px" }}
                                    />
                                    <label
                                      className="TableTopChecklabel"
                                      For="L"
                                    >
                                      &nbsp; Schedule C
                                    </label>
                                  </div>
                                  <div className="flex">
                                    <input
                                      type="checkbox"
                                      className="radio-box-1 box-size1"
                                      ref={input109Ref}
                                      onKeyDown={(event) =>
                                        handleKeyPress(event, input110Ref)
                                      }
                                      id="L"
                                      value="L"
                                      style={{ height: "25px" }}
                                    />
                                    <label
                                      className="TableTopChecklabel"
                                      For="L"
                                    >
                                      &nbsp; Schedule C-1
                                    </label>
                                  </div>
                                  <div className="flex">
                                    <input
                                      type="checkbox"
                                      className="radio-box-1 box-size1"
                                      ref={input110Ref}
                                      onKeyDown={(event) =>
                                        handleKeyPress(event, input111Ref)
                                      }
                                      id="L"
                                      value="L"
                                      style={{ height: "25px" }}
                                    />
                                    <label
                                      className="TableTopChecklabel"
                                      For="L"
                                    >
                                      &nbsp; Schedule G
                                    </label>
                                  </div>

                                  <div className="flex">
                                    <input
                                      type="checkbox"
                                      className="radio-box-1 box-size1"
                                      ref={input111Ref}
                                      onKeyDown={(event) =>
                                        handleKeyPress(event, input112Ref)
                                      }
                                      id="L"
                                      value="L"
                                      style={{ height: "25px" }}
                                    />
                                    <label
                                      className="TableTopChecklabel"
                                      For="L"
                                    >
                                      &nbsp; Narcotics
                                    </label>
                                  </div>
                                </div>
                                <div style={{ border: "1px solid gray" }}>
                                  <table className="">
                                    <thead
                                      className="table-tbody"
                                      style={{ backgroundColor: "#e9e5e5" }}
                                    >
                                      <tr className="ItemMastertableHead">
                                        <th className="TableTrFile">
                                          Batch No
                                        </th>
                                        <th className="TableTrFile5">Mfg Dt</th>
                                        <th className="TableTrFile5">Exp Dt</th>
                                        <th className="TableTrFile5">PP</th>
                                        <th className="TableTrFile5">SP</th>
                                        <th className="TableTrFile5">MRP</th>
                                        <th className="TableTrFile5">Cost</th>
                                        <th className="TableTrFile5">
                                          Op Packs
                                        </th>
                                        <th className="TableTrFile5">
                                          Op Loose
                                        </th>
                                        <th className="TableTrFile5">
                                          Op Value
                                        </th>
                                        {/* <th className="table-th-4">Action</th> */}
                                      </tr>
                                    </thead>
                                    <tbody className=" table-container-tbody TableTbodyScroll">
                                      {taxRows.map((row, rowIndex) => (
                                        <tr
                                          style={{ height: "24px" }}
                                          className="table-tr1"
                                          key={rowIndex}
                                        >
                                          <td className="table-td-2">
                                            <input
                                              className="ItemMastertablebody input-box-td1"
                                              type="text"
                                              name={row.batchNo}
                                              value={row.batchNo}
                                            />
                                          </td>
                                          <td className="table-td-2">
                                            <input
                                              className="ItemMastertablebody "
                                              type="text"
                                              name={row.MfgDt}
                                              value={row.MfgDt}
                                            />
                                          </td>
                                          <td className="table-td-2">
                                            <input
                                              className="ItemMastertablebody "
                                              type="text"
                                              name={row.ExpDt}
                                              value={row.ExpDt}
                                            />
                                          </td>
                                          <td className="table-td-2">
                                            <input
                                              className="ItemMastertablebody "
                                              type="text"
                                              name={row.PTS}
                                              value={row.PTS}
                                            />
                                          </td>
                                          <td className="table-td-2">
                                            <input
                                              className="ItemMastertablebody "
                                              type="text"
                                              name={row.PTR}
                                              value={row.PTR}
                                            />
                                          </td>
                                          <td
                                            className="table-td-2"
                                            style={{ width: "110px" }}
                                          >
                                            <input
                                              className="ItemMastertablebody "
                                              type="text"
                                              name={row.MRP}
                                              value={row.MRP}
                                            />
                                          </td>
                                          <td
                                            className="table-td-2"
                                            style={{ width: "110px" }}
                                          >
                                            <input
                                              className="ItemMastertablebody "
                                              type="text"
                                              name={row.Lost}
                                              value={row.Lost}
                                            />
                                          </td>
                                          <td className="table-td-2">
                                            <input
                                              className="ItemMastertablebody "
                                              type="text"
                                              name={row.OpPacks}
                                              value={row.OpPacks}
                                            />
                                          </td>
                                          <td className="table-td-2">
                                            <input
                                              className="ItemMastertablebody "
                                              type="text"
                                              name={row.OpLoose}
                                              value={row.OpLoose}
                                            />
                                          </td>
                                          <td className="table-td-2">
                                            <input
                                              className="ItemMastertablebody "
                                              type="text"
                                              name={row.OpValue}
                                              value={row.OpValue}
                                              onChange={(e) =>
                                                handleCellChange(e, rowIndex, 0)
                                              }
                                              onKeyDown={(e) =>
                                                handleCellKeyDown(e, rowIndex)
                                              }
                                            />
                                          </td>
                                          <td
                                            className=""
                                            style={{ textAlign: "center" }}
                                          >
                                            {/* <button className="btn-table1" onClick={() => deleteTaxRow(rowIndex)}><i className="fa-solid fa-trash delete-btn"></i></button> */}
                                            <i
                                              onClick={() =>
                                                deleteTaxRow(rowIndex)
                                              }
                                              className="fa-solid fa-xmark delete-btn"
                                            ></i>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-around",
                                      fontSize: "12px",
                                    }}
                                  >
                                    <div>Count:-</div>
                                    <div>OP Stock:-</div>
                                    <div> Value:-</div>
                                  </div>
                                  <div className="TableDownDiv">
                                    <div>
                                      <span>PP = Purchase Price,</span>
                                    </div>
                                    <div>SP = Sale Price,</div>
                                    <div>MRP = Price Rate,</div>
                                    <div>Cost = Purchase Price (+Exp).</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {isEcommerce && (
                          <div>
                            {isSpexMeasurement && (
                              <div>
                                <div className="company_info-text-im">
                                  Spex Measurement
                                </div>
                                <div className="input-box-Im input-height2">
                                  <div className="input-box-m1">
                                    <input
                                      className="modify_im_input mode_category"
                                      ref={input110Ref}
                                      onKeyDown={(event) =>
                                        handleKeyPress(event, input111Ref)
                                      }
                                      onChange={handleInputChange}
                                      name="specifyIndexNo"
                                      value={inputValues.SpecifyIndexNo}
                                      type="number"
                                      required
                                    />
                                    <label className="modify_p_text2">
                                      Specify Index No
                                    </label>
                                  </div>
                                  <div className="input-box-m1">
                                    <input
                                      className="modify_im_input mode_limit mode_category"
                                      ref={input111Ref}
                                      onKeyDown={(event) =>
                                        handleKeyPress(event, input112Ref)
                                      }
                                      type="text"
                                      required
                                    />
                                    <label className="modify_lbl_text2">
                                      Measurement Guide Tools
                                    </label>
                                  </div>
                                </div>
                                <div className="input-box-Im input-height2">
                                  <div className="LoadHeadCol1">
                                    <div className="LoadHeadWidth1">
                                      <select
                                        className="modify_im_input mode_category"
                                        ref={input112Ref}
                                        onKeyDown={(event) =>
                                          handleKeyPress(event, input113Ref)
                                        }
                                        type="text"
                                        required
                                      >
                                        <option value="">--Select--</option>
                                      </select>
                                      <label className="modify_lbl_text3">
                                        Measurement Height Image
                                      </label>
                                    </div>
                                    <div className="input-box-m1">
                                      <button
                                        className="LoadProperties1"
                                        ref={input113Ref}
                                        onKeyDown={(event) =>
                                          handleKeyPress(event, input114Ref)
                                        }
                                      >
                                        Load{" "}
                                      </button>
                                    </div>
                                  </div>
                                  <div className="LoadHeadCol2">
                                    <div className="input-box-m1">
                                      <input
                                        className="modify_im_input mode_category"
                                        ref={input114Ref}
                                        onKeyDown={(event) =>
                                          handleKeyPress(event, input115Ref)
                                        }
                                        type="number"
                                        required
                                      />
                                      <label className="modify_p_text2 LeftSide02">
                                        Height Title
                                      </label>
                                    </div>
                                    <div className="input-box-m1">
                                      <input
                                        className="modify_im_input mode_limit mode_category"
                                        ref={input115Ref}
                                        onKeyDown={(event) =>
                                          handleKeyPress(event, input116Ref)
                                        }
                                        type="number"
                                        required
                                      />
                                      <label className="modify_lbl_text2 LeftSide01">
                                        Height
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="input-box-Im input-height2">
                                  <div className="LoadHeadCol1">
                                    <div className=" LoadHeadWidth1">
                                      <select
                                        className="modify_im_input mode_category"
                                        ref={input116Ref}
                                        onKeyDown={(event) =>
                                          handleKeyPress(event, input117Ref)
                                        }
                                        type="text"
                                        required
                                      >
                                        <option value="">--Select--</option>
                                      </select>
                                      <label className="modify_lbl_text3">
                                        Measurement Width Image
                                      </label>
                                    </div>
                                    <div className="input-box-m1">
                                      <button
                                        className="LoadProperties1"
                                        ref={input117Ref}
                                        onKeyDown={(event) =>
                                          handleKeyPress(event, input118Ref)
                                        }
                                      >
                                        Load{" "}
                                      </button>
                                    </div>
                                  </div>
                                  <div className="LoadHeadCol2">
                                    <div className="input-box-m1">
                                      <input
                                        className="modify_im_input mode_category"
                                        ref={input118Ref}
                                        onKeyDown={(event) =>
                                          handleKeyPress(event, input119Ref)
                                        }
                                        type="number"
                                        required
                                      />
                                      <label className="modify_p_text2 LeftSide02">
                                        Width Title
                                      </label>
                                    </div>
                                    <div className="input-box-m1">
                                      <input
                                        className="modify_im_input mode_limit mode_category"
                                        ref={input119Ref}
                                        onKeyDown={(event) =>
                                          handleKeyPress(event, input120Ref)
                                        }
                                        type="number"
                                        required
                                      />
                                      <label className="modify_lbl_text2 LeftSide01">
                                        Width
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="input-box-Im input-height2">
                                  <div className="LoadHeadCol1">
                                    <div className="LoadHeadWidth1">
                                      <select
                                        className="modify_im_input mode_category"
                                        ref={input120Ref}
                                        onKeyDown={(event) =>
                                          handleKeyPress(event, input121Ref)
                                        }
                                        type="text"
                                        required
                                      >
                                        <option value="">--Select--</option>
                                      </select>
                                      <label className="modify_lbl_text3">
                                        Measurement Weight Image
                                      </label>
                                    </div>
                                    <div className="input-box-m1">
                                      <button
                                        className="LoadProperties1"
                                        ref={input121Ref}
                                        onKeyDown={(event) =>
                                          handleKeyPress(event, input122Ref)
                                        }
                                      >
                                        Load{" "}
                                      </button>
                                    </div>
                                  </div>
                                  <div className="LoadHeadCol2">
                                    <div className="input-box-m1">
                                      <input
                                        className="modify_im_input mode_category"
                                        ref={input122Ref}
                                        onKeyDown={(event) =>
                                          handleKeyPress(event, input123Ref)
                                        }
                                        type="number"
                                        required
                                      />
                                      <label className="modify_p_text2 LeftSide02">
                                        Weight Title
                                      </label>
                                    </div>
                                    <div className="input-box-m1">
                                      <input
                                        className="modify_im_input mode_limit mode_category"
                                        ref={input123Ref}
                                        onKeyDown={(event) =>
                                          handleKeyPress(event, input125Ref)
                                        }
                                        type="number"
                                        required
                                      />
                                      <label className="modify_lbl_text2 LeftSide01">
                                        Weight
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="input-box-Im input-height2">
                                  <div className="LoadHeadCol1">
                                    <div className="LoadHeadWidth1">
                                      <select
                                        className="modify_im_input mode_category"
                                        ref={input125Ref}
                                        onKeyDown={(event) =>
                                          handleKeyPress(event, input126Ref)
                                        }
                                        type="text"
                                        required
                                      >
                                        <option value="">--Select--</option>
                                      </select>
                                      <label className="modify_lbl_text3">
                                        Measurement Length Image
                                      </label>
                                    </div>
                                    <div className="input-box-m1">
                                      <button
                                        className="LoadProperties1"
                                        ref={input126Ref}
                                        onKeyDown={(event) =>
                                          handleKeyPress(event, input127Ref)
                                        }
                                      >
                                        Load{" "}
                                      </button>
                                    </div>
                                  </div>
                                  <div className="LoadHeadCol2">
                                    <div className="input-box-m1">
                                      <input
                                        className="modify_im_input mode_category"
                                        ref={input127Ref}
                                        onKeyDown={(event) =>
                                          handleKeyPress(event, input128Ref)
                                        }
                                        type="number"
                                        required
                                      />
                                      <label className="modify_p_text2 LeftSide02">
                                        Length Title
                                      </label>
                                    </div>
                                    <div className="input-box-m1">
                                      <input
                                        className="modify_im_input mode_limit mode_category"
                                        ref={input128Ref}
                                        onKeyDown={(event) =>
                                          handleKeyPress(event, input129Ref)
                                        }
                                        type="number"
                                        required
                                      />
                                      <label className="modify_lbl_text2 LeftSide01">
                                        Length
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        )}

                        {isInputVisible1 && (
                          <>
                            <div>
                              <div className="company_info-text-im">
                                Images Details
                              </div>
                              <div className="input-box-Im1">
                                <p className="modify_p_2"></p>
                                <div className="radio-buttons-container1 m2 overstack">
                                  <ImageUploadWithPreview
                                    id={"Image1"}
                                    ItemImageUpload={(event) =>
                                      ItemImageUpload(event, 0)
                                    }
                                    imageIds={imageIds}
                                    imgIndex={0}
                                    ItemImage={ItemImage}
                                    setItemImage={setItemImage}
                                  />
                                  <ImageUploadWithPreview
                                    id={"Image2"}
                                    ItemImageUpload={(event) =>
                                      ItemImageUpload(event, ItemImage.length)
                                    }
                                    imageIds={imageIds}
                                    imgIndex={1}
                                    ItemImage={ItemImage}
                                    setItemImage={setItemImage}
                                  />
                                  <ImageUploadWithPreview
                                    id={"Image3"}
                                    ItemImageUpload={(event) =>
                                      ItemImageUpload(event, ItemImage.length)
                                    }
                                    imageIds={imageIds}
                                    imgIndex={2}
                                    ItemImage={ItemImage}
                                    setItemImage={setItemImage}
                                  />
                                  <ImageUploadWithPreview
                                    id={"Image4"}
                                    ItemImageUpload={(event) =>
                                      ItemImageUpload(event, ItemImage.length)
                                    }
                                    imageIds={imageIds}
                                    imgIndex={3}
                                    ItemImage={ItemImage}
                                    setItemImage={setItemImage}
                                  />
                                  <ImageUploadWithPreview
                                    id={"Image5"}
                                    ItemImageUpload={(event) =>
                                      ItemImageUpload(event, ItemImage.length)
                                    }
                                    imageIds={imageIds}
                                    imgIndex={4}
                                    ItemImage={ItemImage}
                                    setItemImage={setItemImage}
                                  />
                                  <ImageUploadWithPreview
                                    id={"Image6"}
                                    ItemImageUpload={(event) =>
                                      ItemImageUpload(event, ItemImage.length)
                                    }
                                    imageIds={imageIds}
                                    imgIndex={5}
                                    ItemImage={ItemImage}
                                    setItemImage={setItemImage}
                                  />
                                  <ImageUploadWithPreview
                                    id={"Image7"}
                                    ItemImageUpload={(event) =>
                                      ItemImageUpload(event, ItemImage.length)
                                    }
                                    imageIds={imageIds}
                                    imgIndex={6}
                                    ItemImage={ItemImage}
                                    setItemImage={setItemImage}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="input-box-Im input-height2">
                              <div className="input-box-m1">
                                <div className="input-box-m1 dis_flex btm_im3">
                                  <h6 className="modify_p_text6">Created Date:</h6>
                                  <h6
                                    className="modify_p_text6"
                                    style={{ top: "8px" }}
                                  >
                                    Updated Date:
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                )}

                {tabcontent2 && (
                  <MetaContentItemMaster
                    handleKeyPress={handleKeyPress}
                    inputValues={inputValues}
                    ItemMasterActive={ItemMasterActive}
                    input129Ref={input129Ref}
                    input130Ref={input130Ref}
                    input131Ref={input131Ref}
                    input132Ref={input132Ref}
                    input133Ref={input133Ref}
                    handleInputChange={handleInputChange}
                    mataData={mataData}
                    setMetadata={setMetadata}
                    data={props.data}
                  />
                )}
                {tabcontent3 && (
                  <ProductVariation
                    handleKeyPress={handleKeyPress}
                    handleCheckStock={handleCheckStock}
                    ItemMasterActive={ItemMasterActive}
                    handleInputChange={handleInputChange}
                    isModalOpen2={isModalOpen2}
                    numVariations={numVariations}
                    closeNewCategoryModal={closeNewCategoryModal}
                    handleSubmit={handleSubmit}
                    handleClick={handleClick}
                    handleNumVariationsChange={handleNumVariationsChange}
                    inputRef={inputRef}
                    ItemMasterList={props.ItemMasterList}
                    inputValues={inputValues}
                    input133Ref={input133Ref}
                    input134Ref={input134Ref}
                    input135Ref={input135Ref}
                    input136Ref={input136Ref}
                    input137Ref={input137Ref}
                    input138Ref={input138Ref}
                    input139Ref={input139Ref}
                    input140Ref={input140Ref}
                    input141Ref={input141Ref}
                    input142Ref={input142Ref}
                    input143Ref={input143Ref}
                    input144Ref={input144Ref}
                    input145Ref={input145Ref}
                    input146Ref={input146Ref}
                    input147Ref={input147Ref}
                    input148Ref={input148Ref}
                    input149Ref={input149Ref}
                    input150Ref={input150Ref}
                    input151Ref={input151Ref}
                    input152Ref={input152Ref}
                    input153Ref={input153Ref}
                    input154Ref={input154Ref}
                    input155Ref={input155Ref}
                    input156Ref={input156Ref}
                    input157Ref={input157Ref}
                    input158Ref={input158Ref}
                    data={props.data}
                  />
                )}
                {tabcontent4 && (
                  <ProductFilter ItemMasterActive={ItemMasterActive} data={props.data} setLoading={setLoading} ItemMasterList={props.ItemMasterList} />
                )}
                {tabcontent5 && (
                  <RelatedProducts ItemMasterActive={ItemMasterActive} ItemGroupList={ItemGroupList} setLoading={setLoading} data={props.data} />
                )}
                {tabcontent6 && (
                  <LinkedProduct ItemMasterActive={ItemMasterActive} ItemGroupList={ItemGroupList} setLoading={setLoading} data={props.data} />
                )}
                {tabcontent7 && (
                  <div>
                    {/*<div className="tab-content-5">
                    {ItemMasterActive && <TabContent7 />}
                  </div>*/}
                    < LPProduct ItemMasterActive={ItemMasterActive} ItemGroupList={ItemGroupList} setLoading={setLoading} data={props.data} />
                  </div>
                )}
                {tabcontent8 && (
                  <div>
                    <TabContent8 SaveContactLens={SaveContactLens} leftEyeInputs={leftEyeInputs}
                      rightEyeInputs={rightEyeInputs} handleInputContactLensChange={handleInputContactLensChange}
                      setLeftEyeInputs={setLeftEyeInputs} setRightEyeInputs={setRightEyeInputs} />
                  </div>
                )}
                {tabcontent9 && (
                  <div>
                    <TabContent9
                      ItemMasterList={props.ItemMasterList}
                      data={props.data}
                      setLoading={setLoading}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="buttom-box">
            <div className="button-btn-1">
              <button className="btn-1">Save</button>
            </div>
            <div className="button-btn-2">
              <button className="btn-2">Reset</button>
            </div>
            <div className="button-btn-3">
              <button className="btn-3" onClick={showModal}>
                <i className="fa-solid fa-gear "></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      {alertBox && (
        <AlertBox
          HeadingTop="Saved"
          Heading="Your Item Master is Saved"
          onYesClick={OpenAlertBox}
          singleButton={true}
          OpenAlertBox={OpenAlertBox}
        />
      )}
      {alertBoxEdit && (
        <AlertBox
          HeadingTop="Updated"
          Heading="Your Item Master is Updated"
          onYesClick={OpenAlertBoxEdit}
          singleButton={true}
          OpenAlertBox={OpenAlertBoxEdit}
        />
      )}

      {alertBoxErrorInsert && (
        <AlertBoxError
          HeadingTop="ERROR"
          Heading="Error While inserting Data"
          onYesClick={OpenAlertBoxErrorInsert}
          singleButton={true}
          OpenAlertBox={OpenAlertBoxErrorInsert}
        />
      )}

      {alertBoxErrorUpdate && (
        <AlertBoxError
          HeadingTop="ERROR"
          Heading="Error While Updating Data"
          onYesClick={OpenAlertBoxErrorUpdate}
          singleButton={true}
          OpenAlertBox={OpenAlertBoxErrorUpdate}
        />
      )}

      {openAlertItemExists && (
        <AlertBoxError
          HeadingTop="Item already exists"
          Heading="Try With Different Item Name"
          onYesClick={OpenAlertBoxWhenItemExists}
          singleButton={true}
          OpenAlertBox={OpenAlertBoxWhenItemExists}
        />
      )}

      {loading && <Loader />}
    </div>
  );
}

export default ItemMaster;
