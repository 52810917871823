import React, { Children, useEffect, useState } from "react";
import ItemMasterApi from "../../API/APIServices/ItemMaster";
import ItemMaster from "../../API/APIServices/ItemMaster";
import Loader from "./Loader";

const ProductFilter = (props) => {
  const { ItemMasterActive, ItemMasterList } = props;
  const [itemToShow, setItemToShow] = useState([]);
  const [itemAttributeList, setitemAttributeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAttributeItem, setShowAttributeItem] = useState(false);

  const handleAttribute = async (e) => {
    setLoading(true)
    if (e.target.value === "closeAttribute") {
      setShowAttributeItem(false);
    } else {
      setShowAttributeItem(true);
      debugger
      var itemSavedFilter = await ItemMaster.GetEcomItemFilter(e.target.value)
      if (itemSavedFilter[0].filter.length === 0) {
        const data = await ItemMasterApi.GetItemMasterByD2Id(Number(e.target.value));
        console.log(data)
        if (data) {
          var FilterValue = {
            filters: []
          };

          // Iterate over each object in the data array
          data.itmAttribute.forEach(item => {
            // Create a new filter object based on item properties
            var filter = {
              filterParentId: item.attributeParentId || 0,
              filterParentName: item.attributeParentName || "string",
              filterId: item.attributeId || 0,
              filterName: item.attributeName || "string"
            };
            // Push the filter object into the filters array of FilterValue
            FilterValue.filters.push(filter);
          });

          setData((prevData) => ({
            ...prevData,
            attItmId: e.target.value,
            filters: FilterValue.filters// Ensure this is an array
          }));
          setLoading(false)
        }
      } else {
        if (itemSavedFilter && Array.isArray(itemSavedFilter[0].filter)) {
          // Update the state with the new filter data
          setData((prevData) => ({
            ...prevData,
            attItmId: e.target.value,
            filters: itemSavedFilter[0].filter // Ensure this is an array
          }));
          setLoading(false)
        } else {
          setLoading(false)
          console.error("Fetched filter is not an array:", itemSavedFilter.filter);
        }
      }
      setLoading(false);
    }
    setLoading(false);
  }


  useEffect(() => {
    const fetchAttributeData = async () => {
      setLoading(true);
      if (props.data.iAttributeParent === 0) {
        const filteredData = [
          {
            fieldName: "attributeParentId",
            operatorName: "equal",
            compareValue: props.data.d2_Id
          }
        ]
        var list = await ItemMaster.GetItemMasterWithFilter(filteredData);
        if (list) {
          console.log(list);
          setItemToShow(list);
          setLoading(false);
        }
        setLoading(false);
      }
      else {
        const filteredData = [
          {
            fieldName: "attributeParentId",
            operatorName: "equal",
            compareValue: props.data.iAttributeParent
          }
        ]
        var list = await ItemMaster.GetItemMasterWithFilter(filteredData);
        if (list) {
          console.log(list);
          setItemToShow(list);
          setLoading(false);
        }
        setLoading(false);
      }
    };
    setLoading(false);
    fetchAttributeData();

    Getattribute();
  }, []);
  const Getattribute = async () => {
    var attributeData = await ItemMasterApi.GetItemAttributeSettings();
    if (attributeData) {
      setitemAttributeList(attributeData);
    }
  };




  const [dataArray, setDataArray] = useState([]);
  const [data, setData] = useState()

  useEffect(() => {
    console.log(dataArray);
    console.log(data);
  }, [dataArray, data]);

  const CheckChildrenAttribute = (childId, parentId, childName, parentName) => {
    // Create the new filter object
    const newFilter = {
      filterParentId: parentId,
      filterParentName: parentName, // Assuming this is corrected
      filterId: childId,
      filterName: childName, // Assuming this is corrected
    };

    setData((prevData) => {
      // Check if the filter already exists
      const filterExists = prevData.filters.some(
        (filter) =>
          filter.filterParentId === parentId &&
          filter.filterId === childId
      );

      if (filterExists) {
        // Filter out the existing filter
        const updatedFilters = prevData.filters.filter(
          (filter) =>
            filter.filterParentId !== parentId || filter.filterId !== childId
        );
        return {
          ...prevData,
          filters: updatedFilters,
        };
      } else {
        // Add the new filter
        return {
          ...prevData,
          filters: [...prevData.filters, newFilter],
        };
      }
    });
  };


  const saveFilteredProduct = async () => {
    setLoading(true)
    var dataSave = await ItemMaster.EcomItemFilter(data)
    if (dataSave === true) {
      setLoading(false)
      alert("saved")
    }
    else {
      setLoading(false)
      alert("Error")
    }


  }

  const searchAttributeChild = (e) => {
    const searchTerm = e.target.value.trim().toLowerCase(); // Get trimmed and lowercase search term

    if (searchTerm === '') {
      Getattribute(); // Assuming Getattribute() fetches the original data
      setitemAttributeList(itemAttributeList); // Set original data to state
      return itemAttributeList; // Return original data
    } else {
      const filteredData = itemAttributeList.map(item => ({
        ...item,
        attributeChilds: item.attributeChilds.filter(child =>
          child.attrName.toLowerCase().includes(searchTerm)
        )
      })).filter(item => item.attributeChilds.length > 0);

      setitemAttributeList(filteredData); // Update state with filtered data
      console.log(filteredData); // Log filtered data for debugging or further processing
      return filteredData; // Return filtered data
    }
  }


  return (
    <div className="tab-content-4">
      {ItemMasterActive && (
        <div className="">
          <div className="company-info-top-im">
            <div className="company-info-top-left">
              <div className="company_info-text-im">General Details </div>
              <div className="">
                <div className="input-box-Im">
                  <select
                    className="modify_im_input mode_category"
                    type="text"
                    required
                    onChange={(e) => handleAttribute(e)}
                  >
                    <option value="closeAttribute">--Select--</option>
                    {itemToShow.map((item) => (
                      <option value={item.d1Id}>{item.name}</option>
                    ))}
                  </select>
                  <label className="modify_lbl_text3">Select Variation</label>
                </div>
              </div>
            </div>
            <div className="company-info-top-right" style={{ width: "50%" }}>
              <div className="company_info-text-im">Stock Details</div>

              <div className="input-box-Im">
                <div className="input-box-m1">
                  <input
                    className="modify_im_input mode_category"
                    type="text"
                    required
                    onChange={(e) => searchAttributeChild(e)}
                  />
                  <label className="modify_p_text2">Search Filter</label>
                </div>
                <div className="input-box-m1">
                  <button className="stock_search">Search </button>
                  <button className="stock_set">Set Variation </button>
                </div>
              </div>
            </div>
          </div>
          {showAttributeItem && (
            <>
              {itemAttributeList.map((attribute) => (
                <div>
                  <div className="product-filter-parent-attb">
                    {attribute.attributeName}
                  </div>
                  <div className="product-filter-parent-attb-child-box">
                    {attribute.attributeChilds.map((children) => (
                      <div className="product-filter-parent-attb-child">
                        <input
                          onChange={(e) =>
                            CheckChildrenAttribute(
                              children.attrId,
                              attribute.attributeId,
                              children.attrName,
                              attribute.attributeName
                            )
                          }
                          type="checkbox"
                          name=""
                          id=""
                          style={{ marginRight: "5px" }}
                          // checked={data.filters.filterId}
                          checked={
                            data?.filters?.some(filter => filter.filterId !== undefined && filter.filterId === children.attrId)
                          }
                        />
                        {children.attrName}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </>
          )}

          <div className="ButtonTabSave">
            <button
              id="SaveBtnMain"
              className={`save-button-main-save hovered`}
              onClick={saveFilteredProduct}
            >
              Save/F10
              <i
                id="step-2"
                className="fa-regular fa-floppy-disk im-icon-white"
              ></i>
            </button>
          </div>
        </div>
      )}
      {loading && <Loader />}
    </div>
  );
};

export default ProductFilter;
