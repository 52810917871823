import React, { useEffect, useState, useContext } from "react";
import PageTabs from "../../Components/PageTabs";
import TableButton from "../../Props/TableButton";
import DataTable from "../TableMaster/DataTable";
import AgentApi from '../../../API/APIServices/Agent';
//import UserApi from '../../../API/APIServices/User';
import AgentMaster from "./AgentMaster";
import MyContext from "../../MyContext";
import AlertBox from "../../Props/AlertBox";
import Loader from "../../Props/Loader";
import { useNavigate } from 'react-router-dom';

function AgentBookedByMasterList() {
    const {
        OpenAlertBoxDelete,
        alertBoxDelete,
        setAlertBoxDelete,
        input1,
        input2,
        input3,
        input4,
        input5,
    } = useContext(MyContext);

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    const [data1, setData1] = useState([]);

    const columns = [
        { Header: <input type="checkbox" />, accessor: "input" },
        { Header: "SNO", accessor: "sno" },
        { Header: "NAME", accessor: "name" },
        { Header: "ADDRESS", accessor: "address" },
        { Header: "STATE", accessor: "state" },
        { Header: "EMAIL", accessor: "email" },
        { Header: "BROKERAGE", accessor: "brokerage" },
        { Header: "ACTION", accessor: "action" },
    ];

    const filterData = [
        { Header: "", accessor: "" },
        { Header: "", accessor: "" },
        { Header: <input type="text" placeholder="Name" ref={input1} />, accessor: "Name" },
        { Header: <input type="text" placeholder="Address" ref={input2} />, accessor: "Address" },
        { Header: <input type="text" placeholder="State" ref={input3} />, accessor: "State" },
        { Header: <input type="text" placeholder="Email" ref={input4} />, accessor: "Email" },
        { Header: <input type="text" placeholder="Brokerage" ref={input5} />, accessor: "Brokerage" },
    ];

    const fetchData = async () => {
        setLoading(true);
        try {
            const val = await AgentApi.GetAgentFilter();
            console.log(val.length);

            const newData = val.map((item, i) => {

                return {
                    id: item.id,
                    input: <input type="checkbox" onChange={() => handleCheckboxChange(item.id)} />,
                    sno: i + 1,
                    name: item.name,
                    address: item.address,
                    state: item.state,
                    email: item.email,
                    brokerage: item.brokerage,
                    action: (
                        <div className="tablelist-action-box">
                            <i className="fa-regular fa-pen-to-square TableEditIcon" onClick={() => editClick(item.id)}></i>
                            <i className="fa-regular fa-trash-can TableDelIcon" onClick={() => DeleteAgent(item.id)}></i>
                            <i className="fa-brands fa-square-whatsapp TableWhatsappcon" onClick={() => handleShare(item.id)}></i>
                            <i className="fa-solid fa-print TablePrint"></i>
                        </div>
                    ),
                };
            });
            setData1(newData);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
        //this.forceUpdate(); 
    };

    useEffect(() => {
        fetchData();
    }, []);

    const [editData, setEditData] = useState([]);

    const editClick = (id) => {
        const data = AgentApi.GetAgentById(id);
        console.log(data);
        setEditData(data);
        toggleSlider();
    }

    const [isSliderOpen, setSliderOpen] = useState(false);

    const toggleSlider = () => {
        console.log(editData);
        setSliderOpen(!isSliderOpen);
    };

    const [getdeleteId, setDeleteId] = useState(null);

    const DeleteAgent = (id) => {
        setAlertBoxDelete(true);
        setDeleteId(id);
    }
    const DeleteAgentConfirm = async () => {
        setLoading(true);
        try {
            const val = await AgentApi.deleteAgent(getdeleteId);
            if (Boolean(val) === true) {
                setAlertBoxDelete(false);
                setDeleteId(null);
                setLoading(false);
            } else {
                alert("Error Deleting Data");
            }
            fetchData();
        } catch (error) {
            console.error("Error deleting data:", error);
        }
    };

    const [selectedData, setSelectedData] = useState([]);

    const handleCheckboxChange = (id) => {
        const selectedItem = data1.find(item => item.id === id);

        if (selectedItem) {
            setSelectedData(prevSelectedData => {
                const isSelected = prevSelectedData.some(item => item.id === id);

                if (isSelected) {
                    return prevSelectedData.filter(item => item.id !== id);
                } else {
                    return [...prevSelectedData, selectedItem];
                }
            });
        }
        console.log(selectedData);
        console.log(data1);
    };

    const handleShare = (id) => {
        const listToSend = data1.find(item => item.id === id);
        const whatsappURL = `https://api.whatsapp.com/send?text=${encodeURIComponent(listToSend)}`;
        window.location.href = whatsappURL;
    };

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                window.history.back();
            }
        };
        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    });

    return (
        <div className="">
            <PageTabs locationTab={"/AgentMaster"} modelOpen={true} setSliderCallBack={setSliderOpen} />
            <div className="nav-1">
                <div className="panel-heading">
                    <h2 className="panel-title">Agent/Booked By Master List</h2>
                    <TableButton PageCreate={"/AgentMaster"} filterData={filterData} columns={columns} data={data1} selectedData={selectedData} />
                </div>
                <div id="printData">
                    <DataTable filterData={filterData} columns={columns} data={data1} />
                </div>
                {/* {selectedData.map((data) => {
                    return (
                        <label>{data.name}</label>
                    )
                })} */}
            </div>

            {/******************************* Agent Master Modal*************************/}
            <div className={`slider-container100 ${isSliderOpen ? 'open' : ''}`}>
                <div style={{ padding: "15px" }}>
                    <AgentMaster editData={editData} modelOpen={true} setSliderCallBack={setSliderOpen} fetchData={fetchData} />
                </div>
            </div>
            {alertBoxDelete && (
                <AlertBox
                    HeadingTop="DELETE"
                    Heading="Are You sure you want to delete this"
                    OpenAlertBox={OpenAlertBoxDelete}
                    onYesClick={DeleteAgentConfirm}
                />
            )}
            {loading && <Loader />}
        </div>
    );
}

export default AgentBookedByMasterList;
