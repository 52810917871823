import axios from '../axiosConfig'; // Adjust the import path accordingly

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;


var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const AccountGroup = {
   
   
  GetAccountGroups: async () => {
    try {
      //const apiUrl = `${express_server_url}/api/AccountGroup/GetAccountGroups`;
	  const apiUrl = `${WebApiUrl}/api/AccountGroup/GetAccountGroups`;
      const response = await axios.post(apiUrl, {}, {
        withCredentials: true,
      });
      // console.log(apiUrl);
      console.log('Response:', response.data.result); 
      return response.data.result;
      // setMainDataCallback(response.data.result);
    } catch (error) {
      console.error('Error:', error.message);
    }

  },

  GetAccountGroupsFilter: async () => {
    try {
	  const apiUrl = `${WebApiUrl}/api/AccountGroup/GetAccountGroups`;
      const response = await axios.post(apiUrl, {
        filter: [],
                order: [
                    {
                        propertyName: "id",
                        ascending: false
                    }
                ],
                pageNumber: 0,
                pageSize: 500000
      }, {
        withCredentials: true,
      });
      // console.log(apiUrl);
      console.log('Response:', response.data.result); 
      return response.data.result;
      // setMainDataCallback(response.data.result);
    } catch (error) {
      console.error('Error:', error.message);
    }

  },

  InsertAccountGroup: async (data) => {  
    function parseBoolean(v) {
      if (typeof v === "boolean") {
        return v;
      } else if (typeof v === "string") {
        v = v.toLowerCase().trim();
        return v === "true";
      } else {
        console.log("error", v);
        return false;
      }
    }
    try {
      console.log(data)
      //const apiUrl = `${express_server_url}/api/AccountGroup`;
	   const apiUrl = `${WebApiUrl}/api/AccountGroup`;
      const response = await axios.post(apiUrl, {
        masterType: 1,
        name: data.name,
        printName: data.printName,
        primaryGroup: parseBoolean(data.primaryGroup),
        parentGrp: Number(data.underGroup),
        groupType: Number(data.groupType), 
        isActive: parseBoolean(data.isActive),
      }, {
        withCredentials: true,
        credentials: 'include',
      });
      console.log('Response:', response.data.result);
      return true;
      // setMainDataCallback(response.data.result);
    } catch (error) {
      console.error('Error:', error.message);
    }
  }, 
  

  GetAccountGroupById: async (accountId) => {
    try {
      console.log("get by id")
      //const apiUrl = `${express_server_url}/api/AccountGroup?accountId=${accountId}`;
	  const apiUrl = `${WebApiUrl}/api/AccountGroup?accountId=${accountId}`;
      const response = await axios.get(apiUrl, {}, {
        withCredentials: true,
      });
      console.log('Response:', response.data.result);
      return response.data.result;
    } catch (error) {
      console.error('Error:', error.message);
    }
  },

  // // ************************* Get By Id ****************************
  // GetAgentById: async (id) => {
  //   try {
  //     const apiUrl = `${express_server_url}/api/Agent?agentId=${id}`;
  //     const response = await axios.get(apiUrl, {}, {
  //       withCredentials: true,
  //     });
  //     console.log('Response:', response.data.result);
  //     //setEditCallBack(response.data.result);
  //    return response.data.result;
  //   } catch (error) {
  //     console.error('Error:', error.message);
  //   }
  // },

  
  
  
  deleteAccountGroup: async (accountId) => {
    try {
      console.log("delete")
      //const apiUrl = `${express_server_url}/api/AccountGroup?accountId=${accountId}`;
	  const apiUrl = `${WebApiUrl}/api/AccountGroup?accountId=${accountId}`;
      const response = await axios.delete(apiUrl, {
        withCredentials: true,
      });
      console.log('Delete Response:', response.data.result);
      return true;
    } catch (error) {
      console.error('Error:', error.message);
    }
  },


  updateAccountGroup: async(data)=>{
    function parseBoolean(v) {
      if (typeof v === "boolean") {
        return v;
      } else if (typeof v === "string") {
        v = v.toLowerCase().trim();
        return v === "true";
      } else {
        console.log("error", v);
        return false;
      }
    }
    try {
      
      //const apiUrl = `${express_server_url}/api/AccountGroup?accountId=${data.id}`;
	  const apiUrl = `${WebApiUrl}/api/AccountGroup?accountId=${data.id}`;
      console.log(data)
      const response = await axios.put(apiUrl, {
        id: data.id,
        name: data.name,
        printName: data.printName,
        primaryGroup: parseBoolean(data.primaryGroup),
        parentGrp: Number(data.underGroup),
        groupType: Number(data.groupType), 
        isActive: parseBoolean(data.isActive),
      }, {
        withCredentials: true,
        credentials: 'include',
      });
      console.log('Response:', response.data.result);
       return response.data.isSuccess

      // setMainDataCallback(response.data.result);
    } catch (error) {
      console.error('Error:', error.message);
    }


  }
  
};

export default AccountGroup;
