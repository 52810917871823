import React, { useState, useRef, useEffect, useContext } from "react";
import DateTime from "../../Components/DateTime";
import PageTabs from "../../Components/PageTabs";
import 'react-quill/dist/quill.snow.css'
import MainButton from "../../Props/MainButton";
import ToolTipMaster from "../../Props/ToolTipMaster";
import AccountCategoryApi from "../../../API/APIServices/AccountCategory";
import AlertBox from "../../Props/AlertBox";
import MyContext from "../../MyContext";
import Loader from "../../Props/Loader";
import {useNavigate } from 'react-router-dom';

function AccountCategoryMaster(props) {

    const [loading, setLoading] = useState(false);
    const { OpenAlertBox, alertBox, OpenAlertBoxEdit, alertBoxEdit } = useContext(MyContext);
    const navigate = useNavigate();

    useState(() => {
        document.title = 'Account Category Master';
    }, []);

    const [isModalOpen, setModalOpen] = useState(false);

    const showModal = () => {
        setModalOpen(true);
    };

    const hideModal = () => {
        setModalOpen(false);
    };

    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);
    const input4Ref = useRef(null);
    const input5Ref = useRef(null);

    const handleKeyPress = (event, nextInputRef) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            nextInputRef.current.focus();
        }
    };
    // **********************************************************************
    const [errorMessages, setErrorMessages] = useState({
        name: '',
    });

    const [inputValues, setInputValues] = useState({
        name: '',
        printName: '',
        isDiscountInPercent: true,
        valueOfDiscount: 0,
        isActive: true,
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        console.log(`Changing ${name} to ${value}`);

        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [name]: value,
            printName: name === 'name' ? value : prevInputValues.printName,
        }));
        setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));
    };

    const handleInputChangeActive = (e) => {
        const { name, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : e.target.value;

        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [name]: type === 'checkbox' ? !prevInputValues[name] : newValue,
        }));

        setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));
        console.log(inputValues);
    };

    const toFriendlyName = (fieldName) => {
        return fieldName
            .replace(/([A-Z])/g, ' $1')
            .replace(/^./, (str) => str.toUpperCase());
    };

    const validateInput = (fieldName) => {
        console.log(fieldName);
        const fieldValue = inputValues[fieldName];

        if (!fieldValue || (typeof fieldValue === 'string' && fieldValue.trim() === '')) {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                [fieldName]: `${toFriendlyName(fieldName)} field is required`,
            }));
            return false;
        }

        return true;
    };
    const fieldNames = ['name', 'valueOfDiscount'];

    const SaveBtn = () => {
        let allValid = true;
        fieldNames.forEach((fieldName) => {
            const isValid = validateInput(fieldName);
            if (!isValid) {
                allValid = false;
            }
        });
        if (allValid) {
            setLoading(true);
            if (Boolean(props.modelOpen) === true) {
                const isSuccess = AccountCategoryApi.updateAccountCategory(inputValues);
                if (isSuccess) {
                    OpenAlertBoxEdit();
                    setLoading(false);
                    props.fetchData()
                    props.setSliderCallBack(false);
                } else {
                    alert("error while updating");
                }
            }
            else {
                setLoading(true);
                const isSuccess = AccountCategoryApi.insertAccountCategory(inputValues);
                if (isSuccess) {
                    setLoading(false);
                    OpenAlertBox();
                } else {
                    alert("Error inserting data");
                }
            }
            setInputValues({
                name: '',
                printName: '',
                isDiscountInPercent: '',
                valueOfDiscount: 0,
                isActive: true,
            });
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (props.editData) {
                    try {
                        const data = await props.editData;
                        console.log(data);
                        setInputValues(data);
                    } catch (error) {
                        console.error("Error fetching data:", error);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [props.editData]);

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                window.history.back();
            }
        };
        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    });

    return (
        <div>
            <PageTabs locationTab={"/AccountCategoryMaster"} modelOpen={props.modelOpen} setSliderCallBack={props.setSliderCallBack} />
            <div className="panel-heading">
                <div className="panel-head-title">
                    <h2 className="panel-title">Account Category Master</h2>
                </div>
                <MainButton SaveButton={SaveBtn} modelOpen={props.modelOpen} setSliderCallBack={props.setSliderCallBack} />
                <div>
                    {isModalOpen && (
                        <div className="modal" >
                            <div className="modal-content">
                                <div className="modal-title">
                                    <h3 className="modal-h">Account Master</h3>
                                    <span className="close" onClick={hideModal}>
                                        &times;
                                    </span>
                                </div>
                                <div className="modal-content-im">

                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div>
                < DateTime />
            </div>
            <div className="content_box">
                <div className="company-info-top-im">
                    <div className="tab-head">
                        <div id="tabs">
                            <div className="tab-content-box">
                                <div className="tab-content-1">
                                    <div className="company-info-top-left" >
                                        <div className="company_info-text-im td-1">General Details
                                            <div className="input-box-checkbox1 ">
                                                <div className=" td-2">
                                                    <div className="cl-toggle-switch td-3">
                                                        <label className="cl-switch">
                                                            <input type="checkbox" onChange={handleInputChangeActive} name="isActive" checked={inputValues.isActive} value={inputValues.isActive} />
                                                            <span></span>
                                                        </label>
                                                    </div>
                                                    <ToolTipMaster isActiveInactive={true} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="input-box-Im ">
                                                <div className="input-box-m2">
                                                    <input className="modify_im_input mode_category"
                                                        onChange={handleInputChange}
                                                        onKeyPress={(e) => handleKeyPress(e, input2Ref)}
                                                        ref={input1Ref}
                                                        type="text"
                                                        value={inputValues.name}
                                                        name="name" required />
                                                    <label className="modify_p_text2">Category Name
                                                        <span className="StarClr">*
                                                            {errorMessages.name && <label className="errorMessage">{errorMessages.name}</label>}
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="input-box-Im ">
                                                <div className="input-box-m2">
                                                    <input className="modify_im_input mode_category"
                                                        onChange={handleInputChange}
                                                        onKeyPress={(e) => handleKeyPress(e, input3Ref)}
                                                        ref={input2Ref}
                                                        type="text"
                                                        value={inputValues.printName}
                                                        name="printName" required />
                                                    <label className="modify_p_text2">Print Name</label>
                                                </div>
                                            </div>
                                            <div className="input-box-Im ">
                                                <div className="input-box-m2">
                                                    <select className="modify_im_input mode_category"
                                                        onChange={handleInputChange}
                                                        onKeyPress={(e) => handleKeyPress(e, input4Ref)}
                                                        ref={input3Ref}
                                                        type="text"
                                                        value={inputValues.isDiscountInPercent}
                                                        name="isDiscountInPercent" required>
                                                        <option value="true">Yes</option>
                                                        <option value="false">No</option>
                                                    </select>
                                                    <label className="modify_lbl_text3">Is Discount in percent(%)</label>
                                                </div>
                                            </div>
                                            <div className="input-box-Im ">
                                                <div className="input-box-m2">
                                                    <input className="modify_im_input mode_category"
                                                        onChange={handleInputChange}
                                                        onKeyPress={(e) => handleKeyPress(e, input5Ref)}
                                                        ref={input4Ref}
                                                        type="number"
                                                        value={inputValues.valueOfDiscount}
                                                        name="valueOfDiscount" required />
                                                    <label className="modify_p_text2">Value of Discount
                                                        <span className="StarClr">*
                                                            {errorMessages.valueOfDiscount && <label className="errorMessage">{errorMessages.valueOfDiscount}</label>}
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="buttom-box">
                        <div className="button-btn-1">
                            <button className="btn-1">Save</button>
                        </div>
                        <div className="button-btn-2">
                            <button className="btn-2">Reset</button>
                        </div>
                        <div className="button-btn-3">
                            <button className="btn-3" onClick={showModal}><i className="fa-solid fa-gear "></i></button>
                        </div>
                    </div>
                </div>
            </div>
            {alertBox && (
                <AlertBox
                    HeadingTop="Saved"
                    Heading="Your Account Category is Saved"
                    onYesClick={OpenAlertBox}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBox}
                />
            )}
            {alertBoxEdit && (
                <AlertBox
                    HeadingTop="Updated"
                    Heading="Your Account Category is Updated"
                    onYesClick={OpenAlertBoxEdit}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBoxEdit}
                />
            )}
            {loading && <Loader />}
        </div>
    );
}


export default AccountCategoryMaster;
