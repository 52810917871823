import React, { useEffect, useState } from "react";
import ItemGroup from "../../../API/APIServices/ItemGroup";
import { Link, useNavigate } from 'react-router-dom';

const Dropdownnew = (props) => {
  const [ItemData, setItemData] = useState([]);
  const [openSubCateIds, setOpenSubCateIds] = useState([]);
  const [openChildCateIds, setOpenChildCateIds] = useState([]);
  const [checkedCategories, setCheckedCategories] = useState([]);

  const { setCheckedItemIds, catogorydata, variable } = props;

  const navigate = useNavigate();

  //console.log(variable);

  // useEffect(() => {
  //   console.log("child", openChildCateIds)
  // }, [openChildCateIds]);

  // useEffect(() => {
  //   console.log("main", openSubCateIds);
  // }, [openSubCateIds]);

  // console.log(checkedCategories);
  // console.log("catogorydata 111111111111111111111111111111111111111111111",catogorydata);

  // useEffect(() => {
  //   console.log(checkedCategories);
  // }, [checkedCategories]);


  const fetchData = async () => {
    try {
      const itemGroup = await ItemGroup.GetItemGroupFilter();
      console.log(itemGroup);
      setItemData(itemGroup);
      return itemGroup;
    }
    catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);


  const fetchCheckBoxData = async () => {
    if (variable === true) {
      try {
        //debugger
        // Automatically check categories based on props.catogorydata
        if (catogorydata && catogorydata.length > 0) {
          setCheckedCategories(catogorydata);

          // Open child categories if their parent is checked
          let initialOpenSubCateIds = {}; // Initialize an empty object to store parentGrp IDs
          catogorydata.forEach((categoryId) => { // Loop through each category ID
            initialOpenSubCateIds[categoryId] = true; // Set categoryId as a key in initialOpenSubCateIds with value true
          });
          setOpenSubCateIds(initialOpenSubCateIds); // Set the state with initialOpenSubCateId

          let initialOpenSubCateIds1 = {}; // Initialize an empty object to store parentGrp IDs
          catogorydata.forEach((categoryId) => { // Loop through each category ID
            initialOpenSubCateIds1[categoryId] = true; // Set categoryId as a key in initialOpenSubCateIds with value true
          });
          setOpenChildCateIds(initialOpenSubCateIds1); // Set the state with initialOpenSubCateId
          console.log(initialOpenSubCateIds1);

          const data = await fetchData();

          for (var i = 0; catogorydata.length > i; i++) {

            var d1Id_parentId = data.filter(item => item.d2_Id == catogorydata[i]);
            if (d1Id_parentId[0].primaryGroup === false) {
              var d2Id_parentId = data.filter(item => item.d1_Id == d1Id_parentId[0].parentGrp);
              // console.log("d1Parent", d1Id_parentId);
              // console.log("d2Parent", d2Id_parentId[0].d2_Id);
              // //toggleSubCategory(d2Id_parentId[0].d2_Id);
              setOpenSubCateIds((prevOpenSubCateIds) => ({
                ...prevOpenSubCateIds,
                [d2Id_parentId[0].d2_Id]: true,
              }));
            }
          }
        } else {
        }
      }
      catch (error) {
        console.log(error);
      }
    }
  }
  useEffect(() => {
    fetchCheckBoxData()
  }, [variable,])

  const toggleSubCategory = (parentId, event) => {
    event.preventDefault();
    setOpenSubCateIds((prevOpenSubCateIds) => ({
      ...prevOpenSubCateIds,
      [parentId]: !prevOpenSubCateIds[parentId],
    }));
  };

  const toggleChildCategory = (SubParentId, event) => {
    event.preventDefault();
    setOpenChildCateIds((prevOpenSubCateIds) => ({
      ...prevOpenSubCateIds,
      [SubParentId]: !prevOpenSubCateIds[SubParentId],
    }));
  };

  const handleCheckboxChange = (categoryId, isParent, event) => {

    // toggleSubCategory(categoryId, event);
    setCheckedCategories((prevCheckedCategories) => {
      let updatedCategories;
      // if (isParent) {
      //   setOpenSubCateIds((prev) => ({ ...prev, [categoryId]: true }));
      // }
      if (prevCheckedCategories.includes(categoryId)) {
        updatedCategories = prevCheckedCategories.filter(
          (id) => id !== categoryId
        );
      } else {
        updatedCategories = [...prevCheckedCategories, categoryId];
      }
      console.log(updatedCategories)
      setCheckedItemIds(updatedCategories);

      return updatedCategories;
    });
  };

  const handleCheckboxChange1 = (categoryId, isParent) => {

    setCheckedCategories((prevCheckedCategories) => {
      let updatedCategories;
      // if (isParent) {
      //   setOpenChildCateIds((prev) => ({ ...prev, [categoryId]: true }));
      // }
      if (prevCheckedCategories.includes(categoryId)) {
        updatedCategories = prevCheckedCategories.filter(
          (id) => id !== categoryId
        );
        // Close child categories if unchecking a parent
        // if (isParent) {
        //   setOpenChildCateIds((prev) => ({ ...prev, [categoryId]: false }));
        // }
      } else {
        updatedCategories = [...prevCheckedCategories, categoryId];
        // Open child categories if checking a parent
        // if (isParent) {
        //   setOpenChildCateIds((prev) => ({ ...prev, [categoryId]: true }));
        // }
      }
      setCheckedItemIds(updatedCategories);

      return updatedCategories;
    });
  };


  const handleCheckboxChange2 = (categoryId, isParent) => {
    setCheckedCategories((prevCheckedCategories) => {
      let updatedCategories;
      if (prevCheckedCategories.includes(categoryId)) {
        updatedCategories = prevCheckedCategories.filter(
          (id) => id !== categoryId
        );
        // Close child categories if unchecking a parent
        if (isParent) {
          setOpenChildCateIds((prev) => ({ ...prev, [categoryId]: false }));
        }
      } else {
        updatedCategories = [...prevCheckedCategories, categoryId];
        // Open child categories if checking a parent
        if (isParent) {
          setOpenChildCateIds((prev) => ({ ...prev, [categoryId]: true }));
        }
      }
      setCheckedItemIds(updatedCategories);

      return updatedCategories;
    });
  };

  return (
    <div>
      {ItemData &&
        ItemData.filter(
          (item) =>
            item.primaryGroup &&
            item.useInEcom === true &&
            item.showInMenu === true
        ).map((item, index) => (
          <div key={index}>
            <div className="category-main">
              <input
                type="checkbox"
                checked={checkedCategories.includes(item.d2_Id)}
                onChange={(event) =>
                  handleCheckboxChange(item.d2_Id, true, event)
                }
                id={`cate-${item.d2_Id}`}
              // onClick={(event) => event.stopPropagation()}
              />
              <label
                className="CategoryBoxPaddingL"
                htmlFor={`cate-${item.d1_Id}`}
                onClick={(event) => toggleSubCategory(item.d2_Id, event)}
                style={{ color: "black", fontSize: "16px" }}
              >
                {item.menuName}{/*---- {item.d1_Id}---- {item.d2_Id}*/}
              </label>
            </div>

            {openSubCateIds[item.d2_Id] && (
              <div className="sub-category-box">
                {ItemData.filter(
                  (subItem) =>
                    subItem.parentGrp === item.d1_Id &&
                    subItem.useInEcom === true &&
                    subItem.showInMenu === true
                ).map((subItem, subIndex) => (
                  <div key={subIndex}>
                    <div className="sub-category-txt">
                      <input
                        checked={checkedCategories.includes(subItem.d2_Id)}
                        onChange={() =>
                          handleCheckboxChange1(subItem.d2_Id, true)
                        }
                        type="checkbox"
                        id={`sub-cate-${subItem.d2_Id}`}
                      />
                      <label
                        className="CategoryBoxPaddingL"
                        htmlFor={`sub-cate-${subItem.d2_Id}`}
                        // style={{ color: "gray", fontSize: "15px" }}
                        style={{ color: "#018786", fontSize: "15px" }}
                        onClick={(event) => toggleChildCategory(subItem.d1_Id, event)}
                      >
                        {subItem.menuName}  {/*--- {subItem.d1_Id} --- {subItem.d2_Id}*/}
                      </label>
                    </div>

                    {openChildCateIds[subItem.d1_Id] && (
                      <div className="sub-category-box">
                        {ItemData.filter(
                          (childItem) =>
                            childItem.parentGrp === subItem.d1_Id &&
                            childItem.useInEcom === true &&
                            childItem.showInMenu === true
                        ).map((childItem, childIndex) => (
                          <div key={childIndex} className="child-category-txt">
                            <input
                              checked={checkedCategories.includes(childItem.d2_Id)}
                              onChange={() =>
                                handleCheckboxChange2(childItem.d2_Id, false)
                              }
                              type="checkbox"
                              id={`sub-cate-${childItem.d2_Id}`}
                            />
                            <label
                              className="CategoryBoxPaddingL"
                              htmlFor={`sub-cate-${childItem.d2_Id}`}
                              // style={{color:"#018786",fontSize:"14px"}}
                              style={{ color: "#555555", fontSize: "14px" }}>
                              {childItem.menuName} {/*--- {childItem.d1_Id} --- {childItem.d2_Id}*/}
                            </label>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}

              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default Dropdownnew;
