import PageTabs from "../../Components/PageTabs";
import TableButton from "./../../Props/TableButton";
import AccountGroupApi from "../../../API/APIServices/AccountGroup";
import { useContext, useEffect, useRef, useState } from "react";
import DataTable from "../TableMaster/DataTable";
import AccountGroupMaster from "./AccountGroupMaster";
import MyContext from "../../MyContext";
import AlertBox from "../../Props/AlertBox";
import Loader from "../../Props/Loader";
// import User from "../../../API/APIServices/User";
import {useNavigate } from 'react-router-dom';

function AccountGroupMasterList(props) {
  const navigate = useNavigate();
  const {
    OpenAlertBoxDelete,
    alertBoxDelete,
    setAlertBoxDelete,
    input1,
    input2,
    input3,
    input4,
    input5,
  } = useContext(MyContext);

  const [loading, setLoading] = useState(true);
  
  const [mainData, setMainData] = useState([]);

  const [isSliderOpen, setSliderOpen] = useState(false);

  const toggleSlider = (value) => {
    // console.log(editData);
    setSliderOpen(value);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const val = await AccountGroupApi.GetAccountGroupsFilter();
      // console.log(val.length);
      const newData = val.map((item, i) => {
        return {
          input: <input type="checkbox" />,
          sno: i + 1,
          name: item.name,
          printName: item.printName,
          primaryGroup: String(item.primaryGroup),
          parentGroupName: item.parentGroupName === null ? "-----" : item.parentGroupName,
          groupTypeName: item.groupTypeName === null ? "-----" : item.groupTypeName,
          action: (
            <div className="tablelist-action-box">
              <i
                className="fa-regular fa-pen-to-square TableEditIcon"
                onClick={() => EditAccount(item.id)}
              ></i>
              <i
                className="fa-regular fa-trash-can TableDelIcon"
                onClick={() => DeleteAccount(item.id)}
              ></i>
              <i className="fa-brands fa-square-whatsapp TableWhatsappcon"></i>
              <i className="fa-solid fa-print TablePrint"></i>
            </div>
          ),
        };
      });
      setMainData(newData);
      setLoading(false);
      // console.log(val);
    } catch (error) {
      console.log(error);
  }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [getdeleteId, setDeleteId] = useState(null);

  const DeleteAccount = (id) => {
    // console.log("delete" ,id)
    setAlertBoxDelete(true);
    setDeleteId(id);
  };

  const DeleteAccountConfirm = async () => {
    setLoading(true);
    // console.log("Deleting row with ID:", getdeleteId);
    try {
      const val = await AccountGroupApi.deleteAccountGroup(getdeleteId);
      if (Boolean(val) === true) {
        // alert("Delete data successfully", "Ok");
        setAlertBoxDelete(false);
        setDeleteId(null);
        setLoading(false);
      } else {
        alert("Error Deleting Data");
      }
      fetchData();
    } catch (error) {
      console.error("Error deleting account:", error);
    }
  };

  //  const[data , setData] = useState({})
  const [editData, setEditData] = useState("");

  const EditAccount = (id) => {
    const data = AccountGroupApi.GetAccountGroupById(id);
    // console.log(data);
    setEditData(data);
    toggleSlider(true);
  };

  const columns = [
    { Header: "", accessor: "input" },
    { Header: "SNO", accessor: "sno" },
    { Header: "Name", accessor: "name" },
    { Header: "Print Name", accessor: "printName" },
    { Header: "Primary Group", accessor: "primaryGroup" },
    { Header: "Parent Group", accessor: "parentGroupName" },
    { Header: "Group Type Name", accessor: "groupTypeName" },
    { Header: "Action", accessor: "action" },
  ];

  const filterData = [
    { Header: "", accessor: "" },
    { Header: "", accessor: "" },
    {
      Header: <input type="text" placeholder="Name" ref={input1} />,
      accessor: "Name",
    },
    {
      Header: <input type="text" placeholder="Print Name" ref={input2} />,
      accessor: "printName",
    },
    {
      Header: <input type="text" placeholder="Primary Group" ref={input3} />,
      accessor: "primaryGroup",
    },
    {
      Header: <input type="text" placeholder="Parent Grp" ref={input4} />,
      accessor: "parentGroupName",
    },  {
      Header: <input type="text" placeholder="Group Type Name" ref={input5} />,
      accessor: "groupTypeName",
    },
  ];
  return (
    <>
      <div className="">
        <PageTabs modelOpen={true} setSliderCallBack={setSliderOpen} locationTab={"/AccountGroupMaster"} />
        <div className="nav-1">
          <div className="panel-heading">
            <h2 className="panel-title">Account Group Master List</h2>
            <TableButton
              PageCreate={"/AccountGroupMaster"}
              columns={columns}
              data={mainData}
              filterData={filterData}
            />
          </div>
        </div>
        <DataTable columns={columns} data={mainData} filterData={filterData} />
      </div>

      <div className={`slider-container100 ${isSliderOpen ? "open" : ""}`}>
        <div style={{ padding: "15px" }}>
          <AccountGroupMaster
            data={editData}
            modelOpen={true}
            setSliderCallBack={setSliderOpen}
            fetchData={fetchData}
          />
        </div>
      </div>
      {alertBoxDelete && (
        <AlertBox
          HeadingTop="DELETE"
          Heading="Are You sure you want to delete this"
          OpenAlertBox={OpenAlertBoxDelete}
          onYesClick={DeleteAccountConfirm}
        />
      )}
      {loading && <Loader />}
    </>
  );
}

export default AccountGroupMasterList;
