import React, { useContext, useEffect, useState } from "react";
import LogInMaster from "./LogInMaster";
import OtpVerify from "./OtpVerify";
import CreateBusiness from "./CreateBusiness";
import Company from "../../API/APIServices/Company";
import { useNavigate } from "react-router-dom";
import Loader from "../Props/Loader";
import AlertBox from "../Props/AlertBox";
import MyContext from "../MyContext";

const Login = () => {
  const navigate = useNavigate();
  const [loginMaster, setLoginMaster] = useState(true);
  const [otpVerify, setOtpVerify] = useState(false);
  const [createbusiness, setCreatebusiness] = useState(false);
  const { OpenAlertBoxEdit, alertBoxEdit } =
  useContext(MyContext);

  const [loading, setLoading] = useState(false);

  const gototop = () => {
    setLoginMaster(false);
    setOtpVerify(true);
    setCreatebusiness(false);
  };

  const gotologinpanel = () => {
    setOtpVerify(false);
    setLoginMaster(false);
    setCreatebusiness(true);
  };

  const [inputValues, setInputValues] = useState({
    name: "",
    businessTypes: "",
    countryId: 1101,
    stateId: 8,
    mobileNo: "",
    email: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(`Changing ${name} to ${value}`);

    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const CreateCompany = async () => {
    setLoading(true);
    try {
      const response = await Company.CreateCompany(inputValues);
      if (response === true) {
          setLoading(false);
          OpenAlertBoxEdit();
          navigate("/LogInPanel");
          window.location.reload();
      } else {
        alert("Company Creation Fail");
      }
    } catch (error) {
      console.error("Error creating company:", error);
    }
  };

  return (
    <div>
      {loginMaster && (
        <LogInMaster gototop={gototop} handleInputChange={handleInputChange} />
      )}
      {otpVerify && (
        <OtpVerify
          gotologinpanel={gotologinpanel}
          handleInputChange={handleInputChange}
        />
      )}
      {createbusiness && (
        <CreateBusiness
          handleInputChange={handleInputChange}
          CreateCompany={CreateCompany}
        />
      )}
      {loading && <Loader />}
      {alertBoxEdit && (
        <AlertBox
          HeadingTop="Welcome"
          Heading="Welcome to CRM page"
          onYesClick={OpenAlertBoxEdit}
          singleButton={true}
          OpenAlertBox={OpenAlertBoxEdit}
        />
      )}
    </div>
  );
};

export default Login;
