import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import * as XLSX from 'xlsx';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import Company from "../../API/APIServices/Company";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function TableButton(props) {

    // console.log(props)
    //*********************{Button Script}*********************//
    const [isHovered, setHovered] = useState(false);
    const handleMouseEnter = () => {
        setHovered(true);
    };
    const handleMouseLeave = () => {
        setHovered(false);
    };

    const [isHovered1, setHovered1] = useState(false);
    const handleMouseEnter1 = () => {
        setHovered1(true);
    };
    const handleMouseLeave1 = () => {
        setHovered1(false);
    };
    const [isHovered2, setHovered2] = useState(false);
    const handleMouseEnter2 = () => {
        setHovered2(true);
    };
    const handleMouseLeave2 = () => {
        setHovered2(false);
    };
    const [isHovered3, setHovered3] = useState(false);
    const handleMouseEnter3 = () => {
        setHovered3(true);
    };
    const handleMouseLeave3 = () => {
        setHovered3(false);
    };
    const [isHovered4, setHovered4] = useState(false);
    const handleMouseEnter4 = () => {
        setHovered4(true);
    };
    const handleMouseLeave4 = () => {
        setHovered4(false);
    };
    const [isHovered5, setHovered5] = useState(false);
    const handleMouseEnter5 = () => {
        setHovered5(true);
    };
    const handleMouseLeave5 = () => {
        setHovered5(false);
    };
    const [isHovered6, setHovered6] = useState(false);
    const handleMouseEnter6 = () => {
        setHovered6(true);
    };
    const handleMouseLeave6 = () => {
        setHovered6(false);
    };
    const handleBack = () => {
        window.history.back();
    };
    // const [isSearchButton, setSearchButton] = useState(props.isSearchButton);

    // ************************ Print/Pdf *****************************
    const convertData = () => {
        if (props.selectedData.length !== 0) {
            if (!props.selectedData.length) {
                return [];
            }
            const fields = Object.keys(props.selectedData[0]);
            const filteredFields = fields.slice(2, -1);

            const sortedData = props.selectedData.sort((a, b) => a['sno'] - b['sno']);
            // const sortedData = props.data
            //     .map((item, index) => ({ ...item, 'sno': index + 1 }))
            //     .sort((a, b) => a['sno'] - b['sno']);
            const result = [
                filteredFields,
                ...sortedData.map(row => filteredFields.map(field => row[field]))
            ];
            return result;
        }
        else {
            if (!props.data.length) {
                return [];
            }
            const fields = Object.keys(props.data[0]);
            const filteredFields = fields.slice(2, -1);

            const result = [filteredFields, ...props.data.map(row => filteredFields.map(field => row[field]))];
            return result;
        }
    };
    // const generatePDF = () => {
    //     const aoaData = convertData();
    //     const docDefinition = {
    //         content: [
    //             {
    //                 table: {
    //                     headerRows: 1,
    //                     body: aoaData,
    //                 },
    //             },
    //         ],
    //     };
    //     pdfMake.createPdf(docDefinition).open();
    // };

    const printDataToConsole = () => {
        const aoaData = convertData();
        aoaData.forEach(row => console.log(row.join('\t')));
        //generatePDF() // for pdf

        const printWindow = window.open('', '_blank');
        printWindow.document.write(`
            <html>
                <head>
                    <style>
                        @media print {
                            body * {
                                visibility: hidden;
                            }
                            #printTable, #printTable * {
                                visibility: visible;
                                width:100%;
                            }
                            #printTable {
                                position: absolute;
                                left: 0;
                                top: 0;
                                border:0px solid black;
                            }
                            #td, #th{
                                border:1px solid lightGrey;
                                padding:10px;
                                width:auto;
                                textAlign:center;
                            }
                            #tr{
                                border:2px solid black;
                            }
                        }
                    </style>
                </head>
                <body>
                    <table id="printTable">
                        ${aoaData.map(row => `<tr id="tr">${row.map(cell => `<td id="td">${cell}</td>`).join('')}</tr>`).join('')}
                    </table>
                </body>
            </html>
        `);
        printWindow.print();
        printWindow.document.close();
    };

    // ************************  Excel Sheet *****************************
    const convertDataToAOA = () => {
        if (props.selectedData.length !== 0) {
            if (!props.selectedData.length) {
                return [];
            }
            const fields = Object.keys(props.selectedData[0]);
            const filteredFields = fields.slice(2, -1);

            const sortedData = props.selectedData.sort((a, b) => a['sno'] - b['sno']);
            const result = [
                filteredFields,
                ...sortedData.map(row => filteredFields.map(field => row[field]))
            ];
            return result;
        }
        else {
            if (!props.data.length) {
                return [];
            }
            const fields = Object.keys(props.data[0]);
            const filteredFields = fields.slice(2, -1);

            return [filteredFields, ...props.data.map(row => filteredFields.map(field => row[field]))];
        }
    };

    const handleExportToExcel = () => {
        const ws = XLSX.utils.aoa_to_sheet(convertDataToAOA());
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
        const filename = `table_data_${Date.now()}.xlsx`;
        XLSX.writeFile(wb, filename);
    };

    const ExcelBtnClick = () => {
        handleExportToExcel();
    }

    const [firmName, setFirmName] = useState();
    const [userName, setUserName] = useState();

    useEffect(() => {
        const fetchData = async () => {
            const arrayString = localStorage.getItem('loginUser');
            const arrayToStore = JSON.parse(arrayString);
            setUserName(arrayToStore.userName);
            const data = await Company.GetCompanyById(arrayToStore.compId);
            setFirmName(data.name);
        };

        fetchData();
    }, []);

    return (
        <div className="panel-head-button">
            <div className="navbar-right">
                {/* {isSearchButton && ( */}
                <div className="Tablebutton5" onClick={props.openModal} >
                    <button className={`save-button-main-small ${isHovered5 ? 'hovered' : ''}`}
                        onMouseEnter={handleMouseEnter5} onMouseLeave={handleMouseLeave5}>
                        <i id="step-2" className="fa-solid fa-magnifying-glass IconFontS1" ></i>
                        {isHovered5 && (
                            <div className="hoverSmallButton">
                                <i className="fa-solid fa-sort-up ItemIconHover"></i>
                                Search
                            </div>
                        )}
                    </button>
                </div>
                {/* )} */}
                <div className="Tablebutton5">
                    <button className={`save-button-main-small ${isHovered1 ? 'hovered' : ''}`}
                        onMouseEnter={handleMouseEnter1} onMouseLeave={handleMouseLeave1} onClick={() => window.location.reload()}>
                        <i id="step-2" className="fa-solid fa-rotate IconFontS1" ></i>
                        {isHovered1 && (
                            <div className="hoverSmallButton">
                                <i className="fa-solid fa-sort-up ItemIconHover"></i>
                                Reset/F8
                            </div>
                        )}
                    </button>
                </div>

                <div className="Tablebutton5">
                    <button className={`save-button-main-small ${isHovered2 ? 'hovered' : ''}`}
                        onMouseEnter={handleMouseEnter2} onMouseLeave={handleMouseLeave2} onClick={ExcelBtnClick}>
                        <i id="step-2" className="fa-solid fa-file-excel IconFontS1" ></i>
                        {isHovered2 && (
                            <div className="hoverSmallButton">
                                <i className="fa-solid fa-sort-up ItemIconHover"></i>
                                XLM/F9
                            </div>
                        )}
                    </button>
                </div>
                <div className="Tablebutton5">
                    <button className={`save-button-main-small ${isHovered4 ? 'hovered' : ''}`} //onClick={props.handleExportToPDF}
                        onMouseEnter={handleMouseEnter4} onMouseLeave={handleMouseLeave4} onClick={printDataToConsole}>
                        <i id="step-2" className="fa-solid fa-print IconFontS1" ></i>
                        {isHovered4 && (
                            <div className="hoverSmallButton">
                                <i className="fa-solid fa-sort-up ItemIconHover"></i>
                                Print/F9
                            </div>
                        )}
                    </button>
                </div>
                <div className="Tablebutton5">
                    <button className={`save-button-main-small ${isHovered6 ? 'hovered' : ''}`}
                        onMouseEnter={handleMouseEnter6} onMouseLeave={handleMouseLeave6}>
                        <i id="step-2" className="fa-solid fa-share-nodes IconFontS1" ></i>
                        {isHovered6 && (
                            <div className="hoverSmallButton">
                                <i className="fa-solid fa-sort-up ItemIconHover"></i>
                                Share
                            </div>
                        )}
                    </button>
                </div>
                <Link to={props.PageCreate} onClick={props.showModal} className="button-m5">
                    <button id="SaveBtnMain"
                        className={`save-button-main-save ${isHovered ? 'hovered' : ''}`}
                        onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
                        Create/F2
                        {/* <i id="step-2" className="fa-solid fa-square-plus im-icon-white"></i> */}
                        {isHovered && (
                            <div className="hover-textSave">
                                <i className="fa-solid fa-sort-up ItemIconHover"></i>  Create/F2
                            </div>
                        )}
                    </button>
                </Link>
                <div onClick={handleBack} className="Tablebutton5">
                    <button className={`save-button-main ${isHovered3 ? 'hovered' : ''}`}
                        onMouseEnter={handleMouseEnter3} onMouseLeave={handleMouseLeave3}>
                        <i id="step-2" className="fa-solid fa-arrow-left-long im-IconRight" onClick={handleBack}></i>
                        Back/ESC
                        {isHovered3 && (
                            <div className="hover-textSave">
                                <i className="fa-solid fa-sort-up ItemIconHover"></i>
                                Back/ESC
                            </div>
                        )}
                    </button>
                </div>

                <div className="loggedInDataDiv" >
                    <label className="loggedInDataLabel">Hi,{userName}</label>
                    <label className="loggedInDataLabel">{firmName}</label>
                </div>
            </div>
        </div>
    )
}

export default TableButton;