
import DateTime from "../../Inventory/Components/DateTime";
import React, { useState, useRef, useEffect } from "react";
import PageTabs from "../../Inventory/Components/PageTabs";
import Order from "../../API/APIServices/Order";
import moment from "moment";
import Loader from "../../Inventory/Props/Loader";

function ModalPaymentDetailOrder(props) {
    /***********title***************/
    // useState(() => {
    //     document.title = 'Payment Detail Order';
    // }, []);

    const { ItemDetail, hideModalPaymentDetailorder } = props

    const [loading, setLoading] = useState(false);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    function convertTo12HourFormat(twentyFourHourTime) {
        const [hours, minutes] = twentyFourHourTime.split(':').map(Number);

        let period = 'AM';
        let twelveHourHours = hours;

        if (hours >= 12) {
            period = 'PM';
            if (hours > 12) {
                twelveHourHours -= 12;
            }
        }
        if (twelveHourHours === 0) {
            twelveHourHours = 12;
        }
        if (minutes != undefined && minutes != null) {
            const formattedMinutes = minutes === 0 ? '00' : minutes.toString().padStart(2, '0');
            return `${twelveHourHours.toString().padStart(2, '0')}:${formattedMinutes} ${period}`;
        }
        else {
            return `${twelveHourHours.toString().padStart(2, '0')}: 00 ${period}`;
        }
    }

    const [totalAmt, setTotalAmt] = useState(0);
    const [paymentDetails, setPaymentDetails] = useState();

    useEffect(() => {
        console.log(ItemDetail);
        const fetchData = async () => {
            setLoading(true);
            try {
                const data = await Order.GetPaymentModeWithOrders(ItemDetail[0].ord1Id);
                setPaymentDetails(data);
                const total = data.reduce((total, item) => total + item.paymentAmount, 0)
                setTotalAmt(total)
            }
            catch (error) {
                console.log(error);
            }
            setLoading(false);
        }
        fetchData()
    }, [])

    return (
        <div>
            <PageTabs />
            <div>
                <DateTime />
            </div>
            <div className="content_box">
                <div className="company_control">
                    <div className="col-sm-12">
                        <label className="ans"><span className="ques">Order No :</span>{ItemDetail[0].orderNo}</label><br />
                        <div className="table-resposive" >
                            <table id="tblTransactionList" className="table_ord_mod table-bordered1" frame="box">
                                <thead textAlign="center" className="thead_scroll_orderlist table-tbody th-bg-color" >
                                    <tr className="">
                                        <th className="td_col6 SNO-Width">
                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>S.No.</div>
                                        </th>
                                        <th className="td_col6">
                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Order No</div>
                                        </th>
                                        <th className="td_col6"> <div className="table_head_OrderList">
                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Trans No</div>
                                        </div> </th>
                                        {/* <th className="td_col6"> <div className="table_head_OrderList">
                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Merchant</div>
                                        </div> </th> */}
                                        <th className="td_col6"> <div className="table_head_OrderList">
                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Amt</div>
                                        </div> </th>
                                        <th className="td_col6"> <div className="table_head_OrderList">
                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Mode</div>
                                        </div> </th>
                                        <th className="td_col6"> <div className="table_head_OrderList">
                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Pay. Time</div>
                                        </div> </th>
                                        <th className="td_col6"> <div className="table_head_OrderList">
                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Pay. Received By</div>
                                        </div> </th>
                                        <th className="td_col6"> <div className="table_head_OrderList">
                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Status</div>
                                        </div> </th>
                                    </tr>
                                </thead>
                                <tbody className="tbody_body_m1 overflow-pymt">
                                    {paymentDetails && paymentDetails.map((item, index) => (
                                        <tr className="table_tbody_Order_List">
                                            <td className="td_col6 td_sno-btn">{index + 1}</td>
                                            <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>{item.orderNo}</td>
                                            <td className="td_col6 td_sno-btn" style={{ textAlign: "center" }}></td>
                                            {/* <td className="td_col6 td_sno-btn" style={{ textAlign: "center" }}></td> */}
                                            <td className="td_col6 td_sno-btn" style={{ textAlign: "center" }}>₹&nbsp;{item.paymentAmount}</td>
                                            <td className="td_col6 td_sno-btn" style={{ textAlign: "center" }}>{item.paymentMode}</td>
                                            {/* <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>{moment.utc(item.paymentDoneOn).utcOffset("+05:30").format(' h:mm A')}</td> */}
                                            <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>{moment(item.paymentDoneOn).format('DD-MM-YYYY hh:mm A')}</td>
                                            <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>{item.paymentDoneBy}</td>
                                            <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}>{item.paymentStatus}</td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td style={{ textAlign: "end" }} colSpan={3} className="tdStyle">Total Amt:</td>
                                        <td style={{ textAlign: "center" }} className="tdStyle">₹&nbsp;{totalAmt}</td> {/* Assuming 'amount' is the key for the value 315 */}
                                        <td style={{ textAlign: "center" }} className="tdStyle"></td>
                                        <td style={{ textAlign: "center" }} className="tdStyle"></td>
                                        <td style={{ textAlign: "center" }} className="tdStyle"></td>
                                        <td style={{ textAlign: "center" }} className="tdStyle"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div><br />
                        <div className="space-between">
                            <label className="ans"><span className="ques">Order Date :&nbsp;</span>{formatDate(ItemDetail[0].orderDate)}<br />
                                <span className="ques">Order Time :&nbsp;</span>{convertTo12HourFormat(ItemDetail[0].orderTime)}</label>

                            <button className="redBtn" onClick={hideModalPaymentDetailorder} style={{ width: "25%" }}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
            {loading && <Loader />}
        </div >
    );
}

export default ModalPaymentDetailOrder;
