
import DateTime from "../../Inventory/Components/DateTime";
import React, { useState, useRef, useEffect } from "react";
import PageTabs from "../../Inventory/Components/PageTabs";
import MainButton from "../../Inventory/Props/MainButton";
import 'bootstrap/dist/css/bootstrap.min.css';
import ModalCancellationAnalysisDetail from "./ModalCancellationAnalysisDetail";
import Order from "../../API/APIServices/Order";
import moment from 'moment';
import UserCredential from "../../API/APIServices/UserCredential";
import Loader from "../../Inventory/Props/Loader";

function CancellationReceiverAnalysis() {
    /***********title***************/
    useState(() => {
        document.title = 'Cancellation Receiver Analysis';
    }, []);

    const [loading, setLoading] = useState(true);
    //***************{Enter Btn Click}***************//
    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);
    const input4Ref = useRef(null);
    const input5Ref = useRef(null);
    const input6Ref = useRef(null);

    const handleKeyPress = (event, nextInputRef) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            nextInputRef.current.focus();
        }
    };

    //***************{From Date and To Date}***************//
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');

    const currentDateString = `${year}-${month}-${day}`;

    const [fromDate, setFromDate] = useState(currentDateString);
    const [toDate, setToDate] = useState(currentDateString);
    const [showTable, setShowTable] = useState(false);

    const handleFromDateChange = (event) => {
        setFromDate(event.target.value);
    };

    const handleToDateChange = (event) => {
        setToDate(event.target.value);
    };

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${day}-${month}-${year}`;
    };

    const formatDate2 = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if needed
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };

    const handleSearchClick = () => {
        fetchData();
        console.log(fromDate + "--" + toDate)
        if (fromDate && toDate) {
            setShowTable(true);
        } else {
        }
    };

    const handleBackBtn = () => {
        window.history.back();
    }

    //************{modal box}************/ /

    const [isModalOpenStatus, setModalOpenStatus] = useState(false);

    const [orderId, setOrderId] = useState();

    const showModalStatus = (ord1Id, ord2Id, id) => {
        const val = { ord1Id: ord1Id, ord2Id: ord2Id, itemOrd2Id: id }
        setOrderId(val);
        setModalOpenStatus(true);
    };

    const hideModalStatus = () => {
        setModalOpenStatus(false);
    };

    const [cancelList, setCancelList] = useState();

    const fetchData = async () => {
        setLoading(true)
        const filteredData = [
            // {
            //     "fieldName": "serviceTypeId",
            //     "operatorName": "Equal",
            //     "compareValue": 751
            // },
            // {
            //     "fieldName": "cancelQty",
            //     "operatorName": "NotEqual",
            //     "compareValue": 0
            // },
            {
                "fieldName": "string",
                "operatorName": "string",
                "compareValue": "string"
            },
        ]
        // const data = await Order.getOrderListFilter(filteredData);
        const data = await Order.getAllCancelItemList(filteredData);
        console.log(data)

        const fromDateObj = new Date(fromDate);
        const toDateObj = new Date(toDate);

        const filteredDataByDate = data.filter(item => {
            if (item.cancelRequestedOn !== null) {
                const orderDate = new Date(item.cancelRequestedOn.slice(0, 10));
                return orderDate >= fromDateObj && orderDate <= toDateObj;
            }
            return false;
        });
        console.log(filteredDataByDate)
        setCancelList(filteredDataByDate);
        setLoading(false);
        //setCancelList(data);
    }

    useEffect(() => {
        fetchData();
    }, [])

    const [userList, setUserList] = useState();

    useEffect(() => {
        const fetchData = async () => {
            const data = await UserCredential.getUserCredential();
            setUserList(data);
        }
        fetchData();
    }, [])

    const handleStatusChange = () => {

    }

    return (
        <div>
            < PageTabs />
            <div className="panel-heading">
                <i className="fa-solid fa-arrow-left icon-white display-none-500" onClick={handleBackBtn} style={{ color: "white", cursor: "pointer", paddingLeft: "15px" }} ></i>
                <h2 className="panel-title" style={{ width: "150%" }}>Cancellation&nbsp;Receiver&nbsp;Analysis</h2>
                {/* *******************Top Buttons****************** */}
                <MainButton isBtnSearch={true} handleSearchClick={handleSearchClick} />  {/*PrintIcon={true}*/}
            </div>
            <div>
                {/* <DateTime /> */}
            </div>
            <div className="content_box">
                <div className="tab-content-box tab-patient-top">
                    <div className="tab-content-1">
                        <div className="company-info-top-left1 patient-padding patient-table-width" style={{ width: "100%" }}>
                            <div className="configure_radius">
                                <div className="company_info-text-im td-1">Search By Cancellation Detail</div>
                                <div className="input-order-buttons input-copy-Trans">
                                    <div className="input-remark-left01 input_copy_stock">
                                        <div className="input-containerEffect12 mt-2" style={{ margin: "1%", width: "50%" }}>
                                            <select id="BillType" className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input1Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input2Ref)} style={{ fontSize: "14px", marginTop: "10px" }} required>
                                                <option>Item Cancellation Request</option>
                                            </select>
                                            <label id="input1" className="labelEffect1">Record Type</label>
                                        </div>
                                        <div className="input-containerEffect12 mt-2 input-pur-invoice" style={{ margin: "1%" }}>
                                            <input className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input2Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input3Ref)} type="date" required onChange={handleFromDateChange} style={{ fontSize: "12px", marginTop: "10px" }} />
                                            <label id="input1" className="labelEffect1">Requested From Date&nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </label>
                                        </div>
                                        <div className="input-containerEffect12 mt-2 input-pur-invoice" style={{ margin: "1%" }}>
                                            <input className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input3Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input4Ref)} type="date" required onChange={handleToDateChange} style={{ fontSize: "12px", marginTop: "10px" }} />
                                            <label id="input1" className="labelEffect1">To Date&nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</label>
                                        </div>
                                        <div className="input-containerEffect12 mt-2" style={{ margin: "1%", width: "50%" }}>
                                            <select id="BillType" className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input4Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input5Ref)} style={{ fontSize: "14px", marginTop: "12px" }} required>
                                                <option>--Select Receiver List</option>
                                                {(() => {
                                                    if (userList !== null && Array.isArray(userList)) {
                                                        return userList.map((data) => (
                                                            <option key={data.id} value={data.id}>
                                                                {data.userName}
                                                            </option>
                                                        ))
                                                    }
                                                })()}
                                            </select>
                                            <label id="input1" className="labelEffect1">Request Receiver List</label>
                                        </div>
                                        <div className="input-containerEffect12 mt-2" style={{ margin: "1%", width: "50%" }}>
                                            <select id="BillType" className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" onChange={handleStatusChange} ref={input5Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input6Ref)} style={{ fontSize: "14px", marginTop: "12px" }} required>
                                                <option>--Select--</option>
                                                <option>NA</option>
                                                <option>Request</option>
                                                <option>Approve</option>
                                                <option>Reject</option>
                                            </select>
                                            <label id="input1" className="labelEffect1">Reject/Approve Status</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {showTable && (
                                <div className="configure_radius config-margin-top">
                                    {/* <div className="company_info-text-im td-1">Search By Cancellation Detail</div> */}
                                    <div className="col-sm-12">
                                        <div className="table-resposive tb-scroll-width" style={{ width: "100%", height: "510px" }}>
                                            <table id="tblTransactionList" className="table_ord_mod table-bordered1 table-width-cancel mt-2" frame="box">
                                                <thead textAlign="center" className=" table_headerSticky thead_scroll_orderlist" >
                                                    <tr className="">
                                                        <th className="td_col6 td-padding-sn">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>S.No.</div>
                                                        </th>
                                                        <th className="td_col6 td-padding-sn">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Order No</div>
                                                        </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Request Date</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Request By Name</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Order Date</div>
                                                        </div> </th>
                                                        {/* <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Order No</div>
                                                        </div> </th> */}
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Service Type</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Table No</div>
                                                        </div> </th>
                                                        <th className="td_col6 td-itemname">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Item Name</div>
                                                        </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Item Confirm By</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_nnb1" style={{ textAlign: "center" }}>Kot Status</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_nnb1" style={{ textAlign: "center" }}>Kot Confirm</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_nnb1" style={{ textAlign: "center" }}>Order Qty</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_nnb1" style={{ textAlign: "center" }}>Cancel Qty</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_nnb1" style={{ textAlign: "center" }}>Item Rate</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_nnb1" style={{ textAlign: "center" }}>Total Cancel Qty</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_nnb1" style={{ textAlign: "center" }}>Cancel Status</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_nnb1" style={{ textAlign: "center" }}>Cancel Remarks</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_nnb1" style={{ textAlign: "center" }}>Approval Status</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_nnb1" style={{ textAlign: "center" }}>Approval Remarks</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_nnb1" style={{ textAlign: "center" }}>Approved/Rejected By</div>
                                                        </div> </th>
                                                    </tr>
                                                </thead>

                                                <tbody className="tbody_body_m1">
                                                    {(() => {
                                                        if (cancelList !== null && cancelList.length > 0) {
                                                            return (
                                                                cancelList.slice().reverse().map((data, index) => (
                                                                    <tr className="table_tbody_Order_List">
                                                                        <td className="td_col6 td-padding-sn">{index + 1}<button className="copyBtn-chaitan status-btn" onClick={() => showModalStatus(data.ord1Id, data.ord2Id, data.itemOrd2Id)} >Status</button></td>
                                                                        <td className="td_col6" style={{ textAlign: "center" }}>{data.orderNo}</td>
                                                                        <td className="td_col6" style={{ textAlign: "center" }}>{moment(data.cancelRequestedOn).format('DD-MM-YYYY')}</td>
                                                                        <td className="td_col6" style={{ textAlign: "center" }}>{data.cancelRequestedByName}</td>
                                                                        <td className="td_col6" style={{ textAlign: "center" }}>{moment(data.orderDate).format('DD-MM-YYYY')}</td>
                                                                        {/* <td className="td_col6" style={{ textAlign: "center" }}>{data.orderNo}</td> */}
                                                                        <td className="td_col6" style={{ textAlign: "center" }}>{data.serviceTypeName}</td>
                                                                        <td className="td_col6" style={{ textAlign: "center" }}>{data.tableName}</td>
                                                                        <td className="td_col6" style={{ textAlign: "center" }}>{data.itemName}</td>
                                                                        <td className="td_col6" style={{ textAlign: "center" }}>{data.itemConfirmedByName}</td>
                                                                        <td className="td_col6" style={{ textAlign: "center" }}>{data.kotStatus}</td>
                                                                        <td className="td_col6" style={{ textAlign: "center" }}>{data.kotConfirmedByName}</td>
                                                                        <td className="td_col6" style={{ textAlign: "center" }}>{data.orderQty}</td>
                                                                        <td className="td_col6" style={{ textAlign: "center" }}>{data.cancelRequestQty}</td>
                                                                        <td className="td_col6" style={{ textAlign: "center" }}>₹&nbsp;{data.itemPrice}</td>
                                                                        <td className="td_col6" style={{ textAlign: "center" }}>{data.cancelRequestQty}</td>
                                                                        <td className="td_col6" style={{ textAlign: "center" }}>{data.cancelStatus}</td>
                                                                        <td className="td_col6" style={{ textAlign: "center" }}>{data.cancelRemarks}</td>
                                                                        <td className="td_col6" style={{ textAlign: "center" }}>{data.cancelApprovalStatus}</td>
                                                                        <td className="td_col6" style={{ textAlign: "center" }}>{data.cancelApprovalRemarks}</td>
                                                                        <td className="td_col6" style={{ textAlign: "center" }}>{data.cancelApprovedByName}</td>
                                                                        {/* <td className="td_col6" ><button className="edit_btn"><i class="fa-solid fa-pen-to-square"></i></button></td>
                                                                            <td class="" style={{ textAlign: "center" }}>
                                                                                <button className="btn-table1"><i class="fa-solid fa-trash delete-btn"></i></button>
                                                                            </td> */}
                                                                    </tr>
                                                                ))
                                                            )
                                                        }
                                                    })()}

                                                </tbody>
                                            </table>

                                        </div>
                                        {/*********************** status Modal ********************/}
                                        {isModalOpenStatus && (
                                            <div className="modal" >
                                                <div className="AddNewItem-modal modal-cancellation">
                                                    <div className="modal-title" style={{ margin: "5px 0px" }}>
                                                        <h3 className="modal-OrderLIst modal-color-CAD" style={{ color: "#807373" }}>&nbsp; &nbsp; Cancellation Analysis Detail</h3>
                                                        <span className="close_modal" onClick={hideModalStatus}>
                                                            &times;
                                                        </span>
                                                    </div>
                                                    <hr style={{ padding: "0px", margin: "0px" }} />
                                                    <div className="ModalContentModalSettingP" style={{ paddingTop: "0px" }}>
                                                        <ModalCancellationAnalysisDetail fetchData={fetchData} orderId={orderId} cancelList={cancelList} setModalOpenStatus={setModalOpenStatus} isModalOpenStatus={isModalOpenStatus} />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {/* <div className="buttom-box"> */}
                <div className="floating-button-container-500" onClick={handleSearchClick} style={{ width: "95%", bottom: "20px" }}>
                    <button className="blueBtn" onClick={handleSearchClick}><i class="fa-solid fa-search" style={{ color: "white" }} ></i> Search</button>
                </div>
                {/* <div className="button-btn-3" onClick={handleSearchClick} style={{ width: "100%", backgroundColor: "green" }}>
                            <button className="btn-3" onClick={handleSearchClick}><i class="fa-solid fa-search " ></i></button>
                        </div> */}
                {/* </div> */}
            </div>
            {loading && <Loader />}
        </div >
    );
}


export default CancellationReceiverAnalysis;
