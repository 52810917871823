import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "./../../images/logo.png";
import Loader from "../Props/Loader";

function OtpVerify(props) {

    const inputRefs = [useRef(), useRef(), useRef(), useRef()];
    const [otp, setOtp] = useState(['', '', '', '']);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        try {
            const getOtp = localStorage.getItem("otp");
            if (getOtp) {
                const otpArray = getOtp.split('');
                otpArray.forEach((value, index) => {
                    setTimeout(() => {
                        setOtp(prevState => {
                            const newOTP = [...prevState];
                            newOTP[index] = value;
                            return newOTP;
                        });
                        // Set input field values
                        inputRefs[index].current.value = value;
                        // Move focus to next input field
                        if (index < 3) {
                            inputRefs[index + 1].current.focus();
                        }

                    });
                });
            }
        } catch (error) {
            alert("Unable to get OTP");
        }
    }, []);

    const handleInputChange = (index, value) => {
        const newOTP = [...otp];
        newOTP[index] = value;
        setOtp(newOTP);
        if (value !== "") {
            const nextIndex = index < 3 ? index + 1 : index;
            inputRefs[nextIndex].current.focus();
        }
    };

    const LoginClick = () => {
        const getOtp = localStorage.getItem("otp");
        const combinedString = otp.join("");
        if (getOtp === combinedString) {
            props.gotologinpanel();
        } else {
            alert("Invalid OTP");
        }
    }

    const isAllFieldsFilled = otp.every(value => value !== '');

    return (
        <div className="Formheader">
            <div>
                <div className="form-container">
                    <div className="form-sidebar-yesbm-logo ">
                        <img src={logo} alt="" />
                    </div>

                    <div className="formMainDiv">
                        <div className="Otpform">
                            <div className="Otptitle">OTP</div>
                            <div className="Otptitle">Verification Code</div>
                            <p className="otpMessage">OTP Send to techcherry@gmail.com
                                <Link to="/LogInMaster" className="sign-up-link">Change?</Link> </p>
                            <div className="Otpinputs">
                                <input ref={inputRefs[0]} id="input1" type="text" maxLength="1" onChange={(e) => handleInputChange(0, e.target.value)} />
                                <input ref={inputRefs[1]} id="input2" type="text" maxLength="1" onChange={(e) => handleInputChange(1, e.target.value)} />
                                <input ref={inputRefs[2]} id="input3" type="text" maxLength="1" onChange={(e) => handleInputChange(2, e.target.value)} />
                                <input ref={inputRefs[3]} id="input4" type="text" maxLength="1" onChange={(e) => handleInputChange(3, e.target.value)} />
                            </div>
                        </div>
                        <button onClick={LoginClick} className="form-btn" disabled={!isAllFieldsFilled}>Submit</button>
                    </div>
                    <p className="sign-up-label">
                        By Continuing, you Accept the <span className="sign-up-link">Terms and Conditions</span>
                    </p>
                    <div className="buttons-container">
                    </div>
                </div>
            </div>
            <div>
            </div>
            {loading && <Loader />}
        </div>
    )
}

export default OtpVerify;
