import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./POSInvoice.css";
import HomeDelivery from "./HomeDelivery";
import PickUp from "./OrderPickup";
import POS from "./POS";
import OrderApi from "../../API/APIServices/Order";
import TableApi from "../../API/APIServices/Table";
import TableGroupApi from "../../API/APIServices/TableGroup";
import Order from "../../API/APIServices/Order";
import UserCredential from "../../API/APIServices/UserCredential";
import ItemDetailModalBox from "./ItemDetailModalBox";
import OrderDetailStatusModal from "./OrderDetailStatusModal";
import PaymantModeModel from "./PaymantModeModel";
import BillSeriesMaster from "../../API/APIServices/BillSeriesMaster";
import BillPaymentMode from '../../API/APIServices/BillPaymentMode';
import Loader from "../../Inventory/Props/Loader";
import GstModalOrderView from "./GstModalOrderView";
import Table from "../../API/APIServices/Table";
import Company from "../../API/APIServices/Company";
import moment from "moment";
import MyContext from "../../Inventory/MyContext";
import DeliveryPointName from "../../API/APIServices/DeliveryPointName";

const OrderView = () => {

  useState(() => {
    document.title = 'Order View';
  }, []);

  const { isShowTotalAmountInReport } = useContext(MyContext);

  const navigate = useNavigate();
  const [isPOS, setIsPOS] = useState(false);
  const [isPickUp, setIsPickUp] = useState(false);
  const [isHomeDelivery, setIsHomeDelivery] = useState(false);
  const [isTableOrder, setIsTableOrder] = useState(true);
  const [RemarkId, setRemarkId] = useState([]);

  const RemarkSet = (e, id, rowIndex) => {
    const newRemarkId = e.target.value;
    const newDataArray = [...RemarkId]; // Copy the original array
    newDataArray[rowIndex] = {
      ...newDataArray[rowIndex],
      RemarkId: newRemarkId,
    };
    setRemarkId(newDataArray);
  };

  const remarkUpdate = (id, index) => {
    const dataToUpdate = [
      {
        operationType: 0,
        path: "Remarks",
        op: "Add",
        from: "string",
        value: RemarkId[index].RemarkId,
      },
    ];
    console.log("Data to update order:", dataToUpdate); // Logging for debugging
    try {
      const updateDeliveryBoy = Order.updateOrderPartially(dataToUpdate, id);
      if (updateDeliveryBoy) {
        alert("Updated Remark")
      }
    } catch (error) {
      console.error("Error updating order:", error);
    }
  }

  const [itemList] = useState([
    {
      id: 1,
      itemName: "Tandoori Momos",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQq673pOk1Wllffzp4AEagD_qCN_Dng1qTnKg&usqp=CAU",
      price: 150.0,
    },
    {
      id: 2,
      itemName: "Garlic Bread",
      image:
        "https://w7.pngwing.com/pngs/55/450/png-transparent-garlic-bread-domino-s-pizza-falafel-benefits-of-raw-garlic-baked-goods-food-american-food.png",
      price: 100.0,
    },
    {
      id: 3,
      itemName: "Chilli Mushroom",
      image:
        "https://img-global.cpcdn.com/recipes/9291e0fe9073f60a/1200x630cq70/photo.jpg",
      price: 120.0,
    },
    {
      id: 4,
      itemName: "Hakka Noodles",
      image: "https://m.media-amazon.com/images/I/71dXe+aKzZL.jpg",
      price: 150.0,
    },
    {
      id: 5,
      itemName: "Veg Sandwich",
      image: "https://foodel.in/wp-content/uploads/2023/04/Layer-2-copy.png",
      price: 145.0,
    },
    {
      id: 6,
      itemName: "Spring Roll",
      image:
        "https://www.pngmart.com/files/16/Crispy-Spring-Rolls-Transparent-PNG.png",
      price: 80.0,
    },
    {
      id: 7,
      itemName: "Cheese Pasta",
      image:
        "https://e7.pngegg.com/pngimages/38/556/png-clipart-pasta-pasta-thumbnail.png",
      price: 180.0,
    },
    {
      id: 8,
      itemName: "Aloo Tikki Burger",
      image:
        "https://images.unsplash.com/photo-1586190848861-99aa4a171e90?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8YnVyZ2VyJTIwcG5nfGVufDB8fDB8fHww&w=1000&q=80",
      price: 280.0,
    },
    {
      id: 9,
      itemName: "French Fries",
      image:
        "https://kirbiecravings.com/wp-content/uploads/2019/09/easy-french-fries-1.jpg",
      price: 99.0,
    },
  ]);

  const [loading, setLoading] = useState(false);
  const [orderListHomeDelivery, setOrderListHomeDelivery] = useState();
  const [orderListPickUp, setOrderListPickUp] = useState();
  const [orderListPos, setOrderListPos] = useState();

  const UpdatePaymentPartially = async (e, data) => {
    if (window.confirm("Are you sure you want to change the payment status")) {
      // payment status and paid amount and balance amount  
      console.log(e.target.value)
      console.log(data)

      const updatedValues = [
        {
          operationType: 0,
          path: "PaymentModeId",
          op: "Add",
          from: "string",
          value: e.target.value
        },
        {
          operationType: 0,
          path: "balanceAmt",
          op: "Add",
          from: "string",
          value: "0"
        },
        {
          operationType: 0,
          path: "paymentAmount",
          op: "Add",
          from: "string",
          value: Number(data.confirmTotalAmt) - (Number(data.paymentAmount) + Number(data.o1CancelledAmt))
        },
        {
          operationType: 0,
          path: "paymentStatus",
          op: "Add",
          from: "string",
          value: "Done"
        }
      ]
      await Order.updateOrderPartially(updatedValues, data.ord1Id);
      const filterData1 = [{
        ord1Id: data.ord1Id,
        paymentModeId: e.target.value,
        paymentAmount: Number(data.confirmTotalAmt) - (Number(data.paymentAmount) + Number(data.o1CancelledAmt)),
      }]

      await Order.createPaymentModeWithOrder(filterData1)
      fetchOrderData()
      alert("Updated")
    }
  }
  const [paymentModeDetails, setPaymentModeDetails] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const filteredData = [
        {
          fieldName: "isDefault",
          operatorName: "Equal",
          compareValue: true
        },
        {
          fieldName: "masterType",
          operatorName: "Equal",
          compareValue: 29
        }
      ]
      const data = await BillSeriesMaster.getBillSeriesMasterFiltered(filteredData);
      console.log("data", data);
      if (data.length > 0) {
        const paymentMode = await BillPaymentMode.getvoucherPaymentModeId(data[0].id)
        console.log(paymentMode)
        setPaymentModeDetails(paymentMode);
      }
      else {
        alert("Please select default Bill series Type for Payment Mode");
      }
    }
    fetchData();
  }, [])

  const [deliveryBoy, setDeliveryBoy] = useState({});

  const fetchOrderDataHome = async () => {
    try {
      setLoading(true)
      const filterData = [
        {
          fieldName: "serviceTypeId",
          operatorName: "Equal",
          compareValue: 752, //Home Delivery
        },
        {
          fieldName: "orderDate",
          operatorName: "Equal",
          compareValue: getCurrentDate(),
        }
      ];
      const data = await OrderApi.getOrderListReport(filterData);
      console.log(data);
      setLoading(false)
      setOrderListHomeDelivery(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false)
    }
  };
  const fetchOrderDataPickup = async () => {
    try {
      setLoading(true)
      const filterData = [
        {
          fieldName: "serviceTypeId",
          operatorName: "Equal",
          compareValue: 753,
        },
        {
          fieldName: "orderDate",
          operatorName: "Equal",
          compareValue: getCurrentDate(),
        }
      ];
      const data = await OrderApi.getOrderListReport(filterData);
      console.log(data);
      setLoading(false)
      setOrderListPickUp(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false)
    }
  };
  const fetchOrderDataPOS = async () => {
    try {
      setLoading(true)
      const filterData = [
        {
          fieldName: "serviceTypeId",
          operatorName: "Equal",
          compareValue: 754,
        },
        {
          fieldName: "orderDate",
          operatorName: "Equal",
          compareValue: getCurrentDate(),
        }
      ];
      const data = await OrderApi.getOrderListReport(filterData);
      console.log(data);
      setLoading(false)
      setOrderListPos(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false)
    }
  };

  useEffect(() => {
    // fetchOrderDataHome();
    // fetchOrderDataPickup();
    // fetchOrderDataPOS();
    const fetchDeliveryBoy = async () => {
      const filterData = [
        {
          fieldName: "userRole",
          operatorName: "equal",
          compareValue: 562,
        },
      ];

      const deliveryBoyData = await UserCredential.getFilteredUserCredential(
        filterData
      );
      if (deliveryBoyData) {
        setDeliveryBoy(deliveryBoyData);
        console.log(deliveryBoyData);
      } else {
        alert("No delivery boy found");
      }
    };

    fetchDeliveryBoy();
  }, []);

  // Initialize hoverStates as an empty object
  const [hoverStates, setHoverStates] = useState({});

  const handleMouseEnter = (rowIndex, btnId) => {
    setHoverStates((prev) => ({
      ...prev,
      [rowIndex]: { ...prev[rowIndex], [btnId]: true },
    }));
  };

  const handleMouseLeave = (rowIndex, btnId) => {
    setHoverStates((prev) => ({
      ...prev,
      [rowIndex]: { ...prev[rowIndex], [btnId]: false },
    }));
  };

  const isButtonHovered = (rowIndex, btnId) => {
    return hoverStates[rowIndex]?.[btnId];
  };

  const [Modal, setModal] = useState(false);

  const openModal = async (id) => {
    await handleGetItem(id);
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const [ItemDetailModal, setItemDetailModal] = useState(false);

  const [ItemDetail, setItemDetail] = useState({});

  useEffect(() => {
    console.log(ItemDetail);
  }, [ItemDetail]);

  const openItemDetailModal = async (id) => {
    await handleGetItem(id);
    setItemDetailModal(true);
  };

  const handleGetItem = async (id) => {
    var filterData = {
      fieldName: "ord1Id",
      operatorName: "equal",
      compareValue: id,
    };
    //const orderlistdata = await Order.getOrderListWithFilter(filterData);
    const orderlistdata = await Order.getOrderItemWiseList(id);
    setItemDetail(orderlistdata);
  };

  const closeItemDetailModal = () => {
    setItemDetailModal(false);
  };

  const [PaymentModal, setPaymentModal] = useState(false);
  const [PaymentModalData, setPaymentModalData] = useState([]);
  const [orderId, setOrderId] = useState();

  const openPaymentModal = (ord1Id, orderId) => {
    handleGetPayment(ord1Id)
    setOrderId({ ord1Id: ord1Id, orderNo: orderId });
  };

  const handleGetPayment = async (orderId) => {
    const PaymentData = await Order.GetPaymentModeWithOrders(orderId);
    console.log(PaymentData);
    setPaymentModalData(PaymentData);
    if (PaymentData.length > 0) {
      setPaymentModal(true);
    }
    else {
      alert("No Payment Found")
    }
  };

  const [inputValues, setInputValues] = useState({});

  const updateRemarkPartially = async (id, index) => {
    try {
      console.log(`Updating remark for index ${index}`);
      console.log(inputValues);

      const data = [
        {
          operationType: 0,
          path: "Remarks",
          op: "Add",
          value: inputValues[`remark-${index}`]
        }
      ];

      const updatedata = await OrderApi.updateOrderPartially(data, id);
      if (updatedata) {
        alert("Remark Updated Successfully");
      } else {
        alert("Failed to update remark");
      }
    } catch (error) {
      console.error("Error updating remark:", error);
      alert("An error occurred while updating remark");
    }
  };

  const closePaymentModal = () => {
    setPaymentModal(false);
  };
  const [AddOrderModal, setAddOrderModal] = useState(false);

  const openAddMoreModal = () => {
    setAddOrderModal(true);
  };

  const closeAddOrderModal = () => {
    setAddOrderModal(false);
  };

  //const [FilterName, setFilterName] = useState();

  const itemSelected = (event) => {
    const newValue = event.target.value;
    //setFilterName(newValue);
    console.log(newValue);
  };

  const [GstModal, setGstModal] = useState(false);
  const [ord1Id, setOrd1Id] = useState();

  const openGstModal = async (id) => {
    await handleGetItem(id);
    setOrd1Id(id)
    setGstModal(true);
  };

  const closeGstModal = () => {
    setGstModal(false);
  };

  const applyStyles = (elementId) => {
    const element = document.getElementById(elementId);
    element.style.backgroundColor = "#12234d";
    element.style.border = "1px solid #12234d";
    element.style.color = "white";
    element.style.padding = "10px";
    element.style.borderRadius = "10px";
    element.style.width = "33%";
    element.style.fontWeight = "600";
    //element.classList.add('black-btn');
  };

  const removeStyles = (elementId) => {
    const element = document.getElementById(elementId);
    element.style.backgroundColor = "white";
    element.style.border = "2px solid #12234d";
    element.style.color = "#12234d";
    element.style.padding = "10px";
    element.style.borderRadius = "10px";
    element.style.width = "33%";
    element.style.fontWeight = "600";
  };

  const [serviceTypeId, setServiceTypeId] = useState(751);

  const [getAllServiceType, setAllServiceType] = useState();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const data = await OrderApi.getServiceType();
        setAllServiceType(data);
        setLoading(false)
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false)
      }
    };
    fetchData();
  }, [])

  const OrderWiseButtonClick = (val) => {
    // alert(serviceTypeId);
    setLoading(true);
    const resetAllStyles = () => {
      const buttons = ["HomeDeliveryBtn", "TableOrderBtn", "PickUpBtn", "PosBtn"];
      buttons.forEach((button) => {
        document.getElementById(button).classList.remove("black-btn");
        const buttonStyle = document.getElementById(button).style;
        buttonStyle.backgroundColor = "";
        buttonStyle.border = "";
        buttonStyle.color = "";
      });
    };
    resetAllStyles();

    if (val === "HomeDelivery") {
      document.getElementById("tableorder").style.display = "none";
      document.getElementById("homedelivery").style.display = "block";
      document.getElementById("pickup").style.display = "none";
      document.getElementById("pos").style.display = "none";
      applyStyles("HomeDeliveryBtn");
      removeStyles("TableOrderBtn");
      removeStyles("PickUpBtn");
      removeStyles("PosBtn");
      //fetchOrderData(752);
      setIsPickUp(false);
      setIsHomeDelivery(true);
      setIsTableOrder(false);
      setIsPOS(false);
      setServiceTypeId(752)
      fetchOrderDataHome();
      setLoading(false)
    }
    else if (val === "TableOrder") {
      document.getElementById("tableorder").style.display = "block";
      document.getElementById("homedelivery").style.display = "none";
      document.getElementById("pickup").style.display = "none";
      document.getElementById("pos").style.display = "none";
      applyStyles("TableOrderBtn");
      removeStyles("HomeDeliveryBtn");
      removeStyles("PickUpBtn");
      removeStyles("PosBtn");
      //fetchOrderData(751);
      setIsPickUp(false);
      setIsHomeDelivery(false);
      setIsTableOrder(true);
      setIsPOS(false);
      setServiceTypeId(751);
      fetchOrderData();
      setLoading(false)
    }
    else if (val === "PickUp") {
      document.getElementById("tableorder").style.display = "none";
      document.getElementById("homedelivery").style.display = "none";
      document.getElementById("pickup").style.display = "block";
      document.getElementById("pos").style.display = "none";
      applyStyles("PickUpBtn");
      removeStyles("TableOrderBtn");
      removeStyles("HomeDeliveryBtn");
      removeStyles("PosBtn");
      //fetchOrderData(753);
      setIsPickUp(true);
      setIsHomeDelivery(false);
      setIsTableOrder(false);
      setIsPOS(false);
      setServiceTypeId(753)
      fetchOrderDataPickup();
      setLoading(false)
    }
    else if (val === "POS") {
      document.getElementById("tableorder").style.display = "none";
      document.getElementById("homedelivery").style.display = "none";
      document.getElementById("pickup").style.display = "none";
      document.getElementById("pos").style.display = "block";
      applyStyles("PosBtn");
      removeStyles("PickUpBtn");
      removeStyles("TableOrderBtn");
      removeStyles("HomeDeliveryBtn");
      //fetchOrderData(753);
      setIsPickUp(false);
      setIsHomeDelivery(false);
      setIsTableOrder(false);
      setIsPOS(true);
      setServiceTypeId(754)
      fetchOrderDataPOS();
      setLoading(false)
    }
    // setLoading(false);
  };

  useEffect(() => {
    OrderWiseButtonClick("TableOrder");
  }, [])
  // ***************** Get Order List *******************
  const [orderList, setOrderList] = useState();

  //const [dataToSend, setDataToSend] = useState([]);

  const fetchOrderData = async () => {
    try {
      //const data = await OrderApi.getOrderList();
      const filterData = [
        {
          fieldName: "serviceTypeId",
          operatorName: "Equal",
          compareValue: 751, // Table Order
        },
        {
          fieldName: "orderDate",
          operatorName: "Equal",
          compareValue: getCurrentDate(),
        }
      ];
      const data = await OrderApi.getOrderListReport(filterData);
      //getOrderListByOrderNo(); //By Mamta 28-02-2024
      console.log(data);
      setOrderList(data);
      fetchTotalAmt(data);
      //setDataToSend(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchOrderData();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if needed
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  // ************** Service type ************************
  const [serviceType, setServiceType] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await OrderApi.getServiceType();
        console.log(data);
        setServiceType(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  // ************** Table Group ************************
  const [tableGroup, setTableGroup] = useState();

  useEffect(() => {
    const getTableGroup = async () => {
      try {
        const data = await TableGroupApi.getTableGroup();
        console.log(data);
        setTableGroup(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    getTableGroup();
  }, []);

  // ************** Table ************************
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await Table.getTable();
      setTableData(data);
    }
    fetchData();
  }, [])

  const onFloorSelect = async (e) => {
    const fieldName = "tableGroupId";
    const operatorName = "Equal";
    const compareValue = e.target.value;

    const data = await TableApi.getTableFromTableGroup(
      fieldName,
      operatorName,
      compareValue
    );
    console.log("data", data);
    setTableData([]);
    setTableData(data);

    const filterData = [
      {
        fieldName: "tableGroupId",
        operatorName: "Equal",
        compareValue: e.target.value,
      },
      {
        fieldName: "serviceTypeId",
        operatorName: "Equal",
        compareValue: 751,
      },
      {
        fieldName: "orderDate",
        operatorName: "Equal",
        compareValue: selectedDate,
      }
    ];
  };

  const handlePrintButton = (id) => {
    navigate("/OrderTakerBillPrint", {
      state: {
        id,
      },
    });
  };

  const formatTimestamp = (timestampString) => {
    const timestamp = new Date(timestampString);

    // Formatting the date
    const formattedDate = `${("0" + timestamp.getDate()).slice(-2)}-${("0" + (timestamp.getMonth() + 1)).slice(-2)}-${timestamp.getFullYear()}`;

    // Formatting the time
    const hours = timestamp.getHours();
    const minutes = timestamp.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    const formattedTime = `${hours % 12}:${minutes < 10 ? '0' + minutes : minutes} ${ampm}`;

    // Combining date and time
    const formattedDateTime = `${formattedDate} - ${formattedTime}`;

    return formattedDateTime;
  }

  useEffect(() => {
    const newDataArray = [...RemarkId]; // Copy the original array
    if (orderList) {
      if (orderList.length > 0) {
        // Loop through the orderList to update the remarks in RemarkId
        orderList.forEach((order, index) => {
          const newRemarkId = order.remarks; // Get the remark from orderList
          newDataArray[index] = { ...newDataArray[index], RemarkId: newRemarkId };
        });
        setRemarkId(newDataArray); // Update the RemarkId state
      }
    }
  }, [orderList]);

  const handlePayment = async () => {
    const orderNo = orderId.orderNo;
    const ord1Id = orderId.ord1Id;

    navigate("/Request", {
      state: {
        orderNo,
        ord1Id
      }
    });
  }
  const handlePaymentPay = async (orderNo, ord1Id) => {
    navigate("/Request", {
      state: {
        orderNo,
        ord1Id
      }
    });
  }
  const [showRunningOrderColor, setRunningOrderColor] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedArrayString = localStorage.getItem('loginAccountUserData');
        const loginUser = JSON.parse(storedArrayString);
        const data = await Company.GetCompanyById(loginUser.compId)
        setRunningOrderColor(data.isAllowDiffColorRunningOrder)
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  })

  const handleRefreshBtn = () => {
    if (serviceTypeId == 751)
      OrderWiseButtonClick("TableOrder");
    else if (serviceTypeId == 752)
      OrderWiseButtonClick("HomeDelivery");
    else if (serviceTypeId == 753)
      OrderWiseButtonClick("PickUp");
    else if (serviceTypeId == 754)
      OrderWiseButtonClick("POS");
  }

  function convertTo12HourFormat(twentyFourHourTime) {
    const [hours, minutes] = twentyFourHourTime.split(':').map(Number);

    let period = 'AM';
    let twelveHourHours = hours;

    if (hours >= 12) {
      period = 'PM';
      if (hours > 12) {
        twelveHourHours -= 12;
      }
    }
    if (twelveHourHours === 0) {
      twelveHourHours = 12;
    }
    if (minutes != undefined && minutes != null) {
      const formattedMinutes = minutes === 0 ? '00' : minutes.toString().padStart(2, '0');
      return `${twelveHourHours.toString().padStart(2, '0')}:${formattedMinutes} ${period}`;
    }
    else {
      return `${twelveHourHours.toString().padStart(2, '0')}: 00`;
    }
  }
  const timeFormat = (twentyFourHourTime) => {
    const [startTime, endTime] = twentyFourHourTime.split('-');
    const convertedStartTime = convertTo12HourFormat(startTime.trim());
    const convertedEndTime = convertTo12HourFormat(endTime.trim());
    const convertedTimeRange = `${convertedStartTime} - ${convertedEndTime}`;
    return convertedTimeRange;
  }

  const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const [selectedDate, setSelectedDate] = useState(getCurrentDate());

  // ******************* For Table Order ****************
  const [addFilter, setFilter] = useState([
    {
      fieldName: "serviceTypeId",
      operatorName: "Equal",
      compareValue: 751
    },
    {
      fieldName: "orderDate",
      operatorName: "Equal",
      compareValue: selectedDate
    }
  ]);

  const handleFilterChange = async (event) => {
    setLoading(true)
    const { name, value } = event.target;

    if (name == "orderDate") {
      setSelectedDate(value);
    }
    let updatedFilter = [...addFilter];
    if (name == "tableGroupId") {
      onFloorSelect(event);
    }
    if (value === "default") {
      updatedFilter = updatedFilter.filter(filter => filter.fieldName !== name);
    }
    else {
      let newFilter = {
        fieldName: name,
        operatorName: "Equal",
        compareValue: value
      };
      const orderDateFilters = updatedFilter.filter(filter => filter.fieldName === name);
      const existingFilterWithSameOperator = orderDateFilters.find(filter => filter.operatorName === newFilter.operatorName);

      if (existingFilterWithSameOperator) {
        const indexToUpdate = updatedFilter.findIndex(filter => filter === existingFilterWithSameOperator);
        if (indexToUpdate !== -1) {
          updatedFilter[indexToUpdate] = {
            ...updatedFilter[indexToUpdate],
            compareValue: newFilter.compareValue
          };
        }
      } else {
        updatedFilter.push(newFilter);
      }
    }
    setFilter(updatedFilter);
    const val = await Order.getOrderListReport(updatedFilter);
    setOrderList(val);
    fetchTotalAmt(val);
    setLoading(false);
  };

  // ******************* For Home Delivery ****************
  const [addFilterForHome, setFilterForHome] = useState([
    {
      fieldName: "serviceTypeId",
      operatorName: "Equal",
      compareValue: 752
    }
  ]);

  const handleFilterChangeForHomeDelivery = async (event) => {
    setLoading(true)
    const { name, value } = event.target;
    if (name == "orderDate") {
      setSelectedDate(value);
    }
    let updatedFilter = [...addFilterForHome];

    if (value === "default") {
      updatedFilter = updatedFilter.filter(filter => filter.fieldName !== name);
    }
    else {
      const newFilter = {
        fieldName: name,
        operatorName: "Equal",
        compareValue: value
      };
      const orderDateFilters = updatedFilter.filter(filter => filter.fieldName === name);
      const existingFilterWithSameOperator = orderDateFilters.find(filter => filter.operatorName === newFilter.operatorName);

      if (existingFilterWithSameOperator) {
        const indexToUpdate = updatedFilter.findIndex(filter => filter === existingFilterWithSameOperator);
        if (indexToUpdate !== -1) {
          updatedFilter[indexToUpdate] = {
            ...updatedFilter[indexToUpdate],
            compareValue: newFilter.compareValue
          };
        }
      } else {
        updatedFilter.push(newFilter);
      }
    }
    setFilterForHome(updatedFilter);
    const val = await Order.getOrderListReport(updatedFilter);
    setOrderListHomeDelivery(val);
    setLoading(false);
  };
  // ******************* For Pick Up ****************
  const [addFilterForPickup, setFilterForPickup] = useState([
    {
      fieldName: "serviceTypeId",
      operatorName: "Equal",
      compareValue: 753
    }
  ]);

  const handleFilterChangeForPickup = async (event) => {
    setLoading(true)
    const { name, value } = event.target;
    if (name == "orderDate") {
      setSelectedDate(value);
    }

    let updatedFilter = [...addFilterForPickup];

    if (value === "default") {
      updatedFilter = updatedFilter.filter(filter => filter.fieldName !== name);
    }
    else {
      const newFilter = {
        fieldName: name,
        operatorName: "Equal",
        compareValue: value
      };
      const orderDateFilters = updatedFilter.filter(filter => filter.fieldName === name);
      const existingFilterWithSameOperator = orderDateFilters.find(filter => filter.operatorName === newFilter.operatorName);

      if (existingFilterWithSameOperator) {
        const indexToUpdate = updatedFilter.findIndex(filter => filter === existingFilterWithSameOperator);
        if (indexToUpdate !== -1) {
          updatedFilter[indexToUpdate] = {
            ...updatedFilter[indexToUpdate],
            compareValue: newFilter.compareValue
          };
        }
      } else {
        updatedFilter.push(newFilter);
      }
    }
    setFilterForPickup(updatedFilter);
    const val = await Order.getOrderListReport(updatedFilter);
    setOrderListPickUp(val);
    setLoading(false);
  };

  const [orderTotalAmt, setOrderTotalAmt] = useState(0);
  const [orderCancelAmt, setOrderCancelAmt] = useState(0);
  const [orderBalAmt, setOrderBalAmt] = useState(0);
  const [orderPaidAmt, setOrderPaidAmt] = useState(0);
  const [totalReturn, setTotalReturn] = useState(0);
  const [totalReturnRequest, setTotalReturnRequest] = useState(0);
  const [totalNoOfChild, setTotalNoOfChild] = useState(0);
  const [totalNoOfAdult, setTotalNoOfAdult] = useState(0);

  const fetchTotalAmt = (orderReport) => {
    const total = orderReport.reduce((sum, data) => sum + (data.confirmTotalAmt), 0);
    setOrderTotalAmt(total.toFixed(2));

    const cancelTotal = orderReport.reduce((sum, data) => sum + (data.o1CancelledAmt), 0);
    setOrderCancelAmt(cancelTotal.toFixed(2));

    const balTotal = orderReport.reduce((sum, data) => sum + (data.confirmTotalAmt - (data.o1CancelledAmt + data.paymentAmount)), 0);
    setOrderBalAmt(balTotal.toFixed(2));

    const paidTotal = orderReport.reduce((sum, data) => sum + (data.paymentAmount), 0);
    setOrderPaidAmt(paidTotal.toFixed(2));

    const returnTotal = orderReport.filter(item => item.overallOrderStatus == "Returned");
    setTotalReturn(returnTotal.length)

    const returnRequestTotal = orderReport.filter(item => item.overallOrderStatus == "Return Request");
    setTotalReturnRequest(returnRequestTotal.length)

    const noOfChild = orderReport.reduce((sum, data) => sum + (data.noChildPerson), 0);
    setTotalNoOfChild(noOfChild)

    const noOfAdult = orderReport.reduce((sum, data) => sum + (data.noAdultPerson), 0);
    setTotalNoOfAdult(noOfAdult)
  }

  // ************** Fetch Delivery Point Name ************************
  const [deliveryPointName, setDeliveryPointName] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await DeliveryPointName.getDeliveryPointNames();
        console.log(data);
        setDeliveryPointName(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      <div
        className="scroll-container"
        style={{ height: "98vh", padding: "10px" }}
      >
        <div className="mt-2">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {isTableOrder && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <label
                  className="fw-600"
                  style={{
                    fontSize: "18px",
                    width: "150px",
                    textAlign: "center",
                    // textDecoration: "underline",
                  }}
                >
                  Table Order
                </label>
                <div
                  className="input-containerEffect mt-1"
                  style={{ margin: "1%" }}
                >
                  <select
                    className="modify_im_input mode_limit mode_category"
                    onChange={handleFilterChange}
                    name="tableGroupId"
                    style={{ width: "max-content" }}
                  >
                    <option value="default">Select Group</option>
                    {(() => {
                      if (tableGroup !== null && Array.isArray(tableGroup)) {
                        return tableGroup.map((data, index) => (
                          <option key={index} value={data.id}>
                            {data.name}
                          </option>
                        ));
                      }
                    })()}
                  </select>
                </div>
                <div
                  className="input-containerEffect mt-1"
                  style={{ margin: "1%" }}
                >
                  <select
                    className="modify_im_input mode_limit mode_category"
                    onChange={handleFilterChange}
                    name="tableId"
                    style={{ width: "max-content" }}
                  >
                    <option value="default">Select Table No</option>
                    {(() => {
                      if (tableData !== null && Array.isArray(tableData)) {
                        return tableData.map((data) => (
                          <option key={data.id} value={data.id}>
                            {data.tableName}
                          </option>
                        ));
                      }
                    })()}
                  </select>
                </div>
                <div
                  className="input-containerEffect mt-1"
                  style={{ margin: "1%" }}
                >
                  <input
                    onChange={handleFilterChange}
                    name="orderDate"
                    className="form-control inputEffect modify_im_input"
                    type="date"
                    id="Date"
                    required
                    value={selectedDate}
                  />
                </div>
                {/* <button onClick={handleRefreshBtn} style={{ padding: "5px", borderRadius: "5px", margin: "8px", backgroundColor: "lavender", border: "1px solid #bfbfea" }}>Refresh</button> */}
                <div className="" onClick={handleRefreshBtn} style={{ padding: "0px" }}>
                  <i
                    class="fa-solid fa-arrows-rotate mt-1"
                    style={{ padding: "5px", borderRadius: "5px", margin: "8px", backgroundColor: "lavender", border: "1px solid #bfbfea", cursor: "pointer" }}
                  ></i>
                </div>
              </div>
            )}
            {isHomeDelivery && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <label
                  className="fw-600"
                  style={{
                    fontSize: "18px",
                    width: "200px",
                    textAlign: "center",
                    // textDecoration: "underline",
                  }}
                >
                  Home Delivery
                </label>
                <div
                  className="input-containerEffect mt-1"
                  style={{ margin: "1%" }}
                >
                  <select
                    className="modify_im_input mode_limit mode_category"
                    style={{ width: "max-content" }}
                  >
                    <option value="0">Select</option>
                    {deliveryPointName && deliveryPointName.map((data, index) => (
                      <option value={data.id}>{data.name}</option>
                    ))}
                    {/* <option value="1">Mother's Hut</option> */}
                  </select>
                </div>
                <div
                  className="input-containerEffect mt-1"
                  style={{ margin: "1%" }}
                >
                  <select
                    className="modify_im_input mode_limit mode_category"
                    onChange={handleFilterChangeForHomeDelivery}
                    name="deliveryBoyId"
                    style={{ width: "max-content" }}
                  >
                    <option value="default">Select Delivery Boy</option>
                    {(() => {
                      if (deliveryBoy !== null && Array.isArray(deliveryBoy)) {
                        return deliveryBoy.map((data, index) => (
                          <option key={index} value={data.id}>
                            {data.userName}
                          </option>
                        ));
                      }
                    })()}
                  </select>
                </div>

                <div
                  className="input-containerEffect mt-1"
                  style={{ margin: "1%" }}
                >
                  <select
                    className="modify_im_input mode_limit mode_category"
                    onChange={handleFilterChangeForHomeDelivery}
                    name="paymentStatus"
                    style={{ width: "max-content" }}
                  >
                    <option value="default">Payment Status</option>
                    <option value="Pending">Pending</option>
                    <option value="Done">Done</option>
                  </select>
                </div>
                <div
                  className="input-containerEffect mt-1"
                  style={{ margin: "1%" }}
                >
                  <input
                    onChange={handleFilterChangeForHomeDelivery}
                    name="orderDate"
                    className="form-control inputEffect modify_im_input"
                    type="date"
                    id="Date"
                    value={selectedDate}
                    required
                  />
                </div>
                <div className="" onClick={handleRefreshBtn} style={{ padding: "0px" }}>
                  <i
                    class="fa-solid fa-arrows-rotate mt-1"
                    style={{ padding: "5px", borderRadius: "5px", margin: "8px", backgroundColor: "lavender", border: "1px solid #bfbfea", cursor: "pointer" }}
                  ></i>
                </div>
              </div>
            )}
            {isPickUp && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  width: "55%"
                }}
              >
                <label
                  className="fw-600"
                  style={{
                    fontSize: "18px",
                    width: "80px",
                    textAlign: "center",
                    // textDecoration: "underline",
                  }}
                >
                  Pick Up
                </label>
                <div
                  className="input-containerEffect mt-1"
                  style={{ margin: "1%" }}
                >
                  <input
                    onChange={handleFilterChangeForPickup}
                    name="orderDate"
                    className="form-control inputEffect modify_im_input"
                    type="date"
                    id="Date"
                    required
                    value={selectedDate}
                  />
                </div>
                <div className="" onClick={handleRefreshBtn} style={{ padding: "0px" }}>
                  <i
                    class="fa-solid fa-arrows-rotate mt-1"
                    style={{ padding: "5px", borderRadius: "5px", margin: "8px", backgroundColor: "lavender", border: "1px solid #bfbfea", cursor: "pointer" }}
                  ></i>
                </div>
              </div>
            )}
            {isPOS && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  width: "55%"
                }}
              >
                <label
                  className="fw-600"
                  style={{
                    fontSize: "18px",
                    width: "80px",
                    textAlign: "center",
                    // textDecoration: "underline",
                  }}
                >
                  POS
                </label>
                <div
                  className="input-containerEffect mt-1"
                  style={{ margin: "1%" }}
                >
                  <input
                    onChange={handleFilterChangeForPickup}
                    name="orderDate"
                    className="form-control inputEffect modify_im_input"
                    type="date"
                    id="Date"
                    required
                    value={selectedDate}
                  />
                </div>
                <div className="" onClick={handleRefreshBtn} style={{ padding: "0px" }}>
                  <i
                    class="fa-solid fa-arrows-rotate mt-1"
                    style={{ padding: "5px", borderRadius: "5px", margin: "8px", backgroundColor: "lavender", border: "1px solid #bfbfea", cursor: "pointer" }}
                  ></i>
                </div>
              </div>
            )}
            {/* ****************************** Service Type ********************* */}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "35%",
              }}
            >
              <button
                id="TableOrderBtn"
                className="black-btn"
                style={{ width: "33%" }}
                onClick={() => OrderWiseButtonClick("TableOrder")}
              >
                Table&nbsp;Order
              </button>
              <button
                id="HomeDeliveryBtn"
                className="black-btn"
                style={{ width: "33%" }}
                onClick={() => OrderWiseButtonClick("HomeDelivery")}
              >
                Home&nbsp;Delivery
              </button>
              <button
                id="PickUpBtn"
                className="black-btn"
                style={{ width: "33%" }}
                onClick={() => OrderWiseButtonClick("PickUp")}
              >
                Pick&nbsp;Up
              </button>
              <button
                id="PosBtn"
                className="black-btn"
                style={{ width: "33%" }}
                onClick={() => OrderWiseButtonClick("POS")}
              >
                POS
              </button>
            </div>
          </div>

          {/****************** Table Order ****************/}
          <div id="tableorder" style={{ display: "block", padding: "0px" }}>{/*height: "510px" */}
            <table className="tableStyle mt-4">
              <thead className="table_headerSticky" style={{ top: "-10px" }}>
                <tr>
                  <th className="thStyle">Select</th>
                  <th className="thStyle">Order No.</th>
                  <th className="thStyle">Table No.</th>
                  <th className="thStyle">Mobile&nbsp;No.</th>
                  <th className="thStyle">Total Amt.</th>
                  <th className="thStyle">Order On</th>
                  <th className="thStyle">Delivery On</th>
                  <th className="thStyle">Kot&nbsp;Desc&nbsp;time</th>
                  <th className="thStyle">Mode</th>
                  <th className="thStyle" style={{ width: "15%" }}>
                    Order Status
                  </th>
                  <th className="thStyle" style={{ width: "12%" }}>
                    Payment
                  </th>
                  <th className="thStyle" style={{ width: "7%" }}>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="tbody_body_m1">
                {(() => {
                  if (orderList !== null && Array.isArray(orderList)) {
                    return orderList
                      .slice().reverse()
                      .map((data, rowIndex) => {
                        let backgroundStyle = { backgroundColor: "white" };
                        if (data.runningOrderCounter && data.runningOrderCounter > 0) {
                          if (showRunningOrderColor) {
                            backgroundStyle.backgroundColor = "rgba(255, 192, 203, 0.64)"; // Or "rgb(234, 163, 163)";
                          }
                        }
                        return (
                          <tr
                            key={rowIndex}
                            className="hoverRow table_tbody_Order_List"
                            style={{ ...backgroundStyle, cursor: "pointer" }}
                          >
                            <td className="tdStyle" style={{ width: "3%", textAlign: "center" }}
                              onClick={() => openItemDetailModal(data.ord1Id)} >
                              <button style={{ padding: "5px 10px" }}>{rowIndex + 1}</button>
                            </td>
                            <td
                              className="tdStyle"
                              style={{ width: "7%", textAlign: "center" }}
                            >
                              <div>
                                <label><span style={{ fontWeight: "600" }}>Order No : &nbsp;</span>{data.orderNo}</label>
                                <label><span style={{ fontWeight: "600" }}>NP : &nbsp;</span>{data.noOfPerson}</label>
                                <label><span style={{ fontWeight: "600" }}>NCP : &nbsp;</span>{data.noChildPerson}</label>
                                <label><span style={{ fontWeight: "600" }}>NAP : &nbsp;</span> {data.noAdultPerson}</label>
                              </div>
                            </td>
                            <td className="tdStyle" style={{ width: "7%", textAlign: "center" }}>{data.tableName}</td>
                            <td className="tdStyle" style={{ width: "8%", textAlign: "center" }}> {data.accName} <br />{data.accMobileNo}</td>
                            <td className="tdStyle" style={{ width: "12%", textAlign: "center" }}>
                              <span style={{ fontWeight: "600" }}>Total Amt: &#x20B9;</span>
                              {data.confirmTotalAmt !== null &&
                                data.confirmTotalAmt !== undefined
                                ? data.confirmTotalAmt
                                : 0}
                              {/* {data.grandTotal !== null &&
                                data.grandTotal !== undefined
                                ? data.grandTotal
                                : 0} */}
                              <br />
                              <span style={{ fontWeight: "600" }}>Cancel Amt: &#x20B9;</span>
                              {data.o1CancelledAmt !== null &&
                                data.o1CancelledAmt !== undefined
                                ? data.o1CancelledAmt
                                : 0}
                              <br />
                              <span style={{ fontWeight: "600" }}>Bal. Amt: &#x20B9;</span>
                              {data.confirmTotalAmt !== null &&
                                data.confirmTotalAmt !== undefined
                                ? data.confirmTotalAmt - (data.o1CancelledAmt + data.paymentAmount)
                                : 0}
                              <br />
                              <span style={{ fontWeight: "600" }}>Paid Amt: &#x20B9;</span>
                              {data.paymentAmount !== null &&
                                data.paymentAmount !== undefined
                                ? data.paymentAmount
                                : 0}
                            </td>
                            {/* Bill Amt.{data.grandTotal} <br /> Cancel Amt {data.o1CancelledAmt} || 0 */}
                            <td className="tdStyle" style={{ width: "8%", textAlign: "center" }}>
                              {formatDate(data.orderDate)} <br /> {convertTo12HourFormat(String(data.orderTime))}
                            </td>
                            <td className="tdStyle" style={{ width: "8%", textAlign: "center" }}>
                              {formatDate(data.deliveryDate)} <br /> {timeFormat((data.deliveryTime))}
                            </td>{" "}
                            {/*15:30 to 16:00 */}
                            <td className="tdStyle">{convertTo12HourFormat(String(data.kotDispatchTime))}</td>
                            <td className="tdStyle">Cash</td>
                            <td className="tdStyle">
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <select
                                    className="form-control modify_im_input"
                                    style={{ width: "80%" }}
                                    disabled
                                    value={data.overallOrderStatus}
                                  >
                                    <option value="Confirm">Confirm</option>
                                    <option value="Done">Done</option>
                                    <option value="Pending">Pending</option>
                                    <option value="Cancel">Cancel</option>
                                  </select>
                                  <i
                                    class="fa-solid fa-circle-info mt-1 icon_ov1"
                                    onClick={() => openModal(data.ord1Id)}
                                    style={{
                                      color: "#646464",
                                      cursor: "pointer",
                                      margin: "0 !important",
                                    }}
                                  ></i>
                                </div>
                                <div className="mt-2">
                                  <label style={{
                                    display: data.orderConfirmedByName != "" && data.orderConfirmedByName != null && data.orderConfirmedByName != undefined ? "block" : "none",
                                    fontSize: "14px"
                                  }} >
                                    Confirm By :- <span style={{ fontWeight: "600" }}>{data.orderConfirmedByName}</span><br />
                                    {/* {moment.utc(data.orderConfirmedOn).utcOffset("+05:30").format('DD-MM-YYYY hh:mm A')} */}
                                    {moment(data.orderConfirmedOn).format('DD-MM-YYYY hh:mm A')}
                                    {/* {`${formatTimestamp(data.orderConfirmedOn)}`} */}
                                  </label>
                                  {/* <label>{ data.paymentStatus == "Pending" ? "00:00:00" : moment.utc(data.paymentDoneOn).utcOffset("+05:30").format('hh:mm A') }</label> */}
                                </div>
                              </div>
                            </td>
                            <td className="tdStyle">
                              <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div style={{ display: "grid", alignItems: "baseline", alignContent: "baseline" }}>
                                  <div
                                    className="mt-2"
                                    style={{ display: "flex", alignItems: "center" }}
                                  >
                                    {/* <input
                                type="text"
                                className="form-control"
                                placeholder="Remark"
                                style={{ width: "140px", marginRight: "12px" }}
                                name={`remark-${rowIndex}`}
                                value={inputValues[`remark-${rowIndex}`] || ''}
                                onChange={(event) => handleInputChange(event, rowIndex)}
                              />
                              <i
                                className="fa-solid fa-floppy-disk icon_ov1"
                                style={{ color: "brown", cursor: "pointer" }}
                                onClick={() => updateRemarkPartially(data.ord1Id, rowIndex)}
                              ></i> */}

                                    {/* ******************* Remove Remark (from table order)************************* */}
                                    {/* <input
                                  type="text"
                                  className="form-control remark-ov"
                                  placeholder="Remark"
                                  onChange={(e) =>
                                    RemarkSet(e, data.ord1Id, rowIndex)
                                  }
                                  value={
                                    RemarkId[rowIndex]?.RemarkId
                                  }
                                  style={{ width: "138px", marginRight: "5px" }}
                                />
                                <i
                                  onClick={() => remarkUpdate(data.ord1Id, rowIndex)}
                                  class="fa-solid fa-floppy-disk icon_ov1"
                                  style={{
                                    color: "brown",
                                    cursor: "pointer",
                                    position: "relative",
                                    fontSize: "20px"
                                  }}
                                  onMouseEnter={() =>
                                    handleMouseEnter(rowIndex, "btn10")
                                  }
                                  onMouseLeave={() =>
                                    handleMouseLeave(rowIndex, "btn10")
                                  }
                                >
                                  {isButtonHovered(rowIndex, "btn10") && (
                                    <div className="hover-text">Save Remark</div>
                                  )}
                                </i> */}
                                  </div>

                                  <div className="mt-2" style={{ display: "flex", marginTop: "10px" }}>
                                    <div>
                                      <select
                                        disabled={data.paymentStatus === "Done" ? true : false}
                                        value={data.paymentMode}
                                        onChange={(e) => UpdatePaymentPartially(e, data)}
                                        style={{
                                          width: "110px",
                                          padding: "2px 5px",
                                          textAlign: "center",
                                        }}
                                        className="OrderPayMode form-control mt-1"
                                      > <option >
                                          ---
                                        </option>
                                        {(() => {
                                          if (paymentModeDetails !== null && Array.isArray(paymentModeDetails)) {
                                            return paymentModeDetails.map((data, index) => (
                                              <option key={index} value={data.accountId}>
                                                {data.accountTitle}
                                              </option>
                                            ))
                                          }
                                        })()}
                                      </select>{" "}

                                      <button style={{ margin: "0", width: "110px" }}
                                        className="btn mt-1 btnPaymentStatus-ov"
                                      >
                                        {data.paymentStatus ? data.paymentStatus : "Pending"}
                                      </button>
                                    </div>

                                    <div
                                      className="iconBox-ov"
                                      style={{ marginTop: "20px" }}
                                    >
                                      <i
                                        onMouseEnter={() =>
                                          handleMouseEnter(rowIndex, "btn1")
                                        }
                                        onMouseLeave={() =>
                                          handleMouseLeave(rowIndex, "btn1")
                                        }
                                        class="fa-solid fa-note-sticky icon_ov1"
                                        style={{
                                          color: "indigo",
                                          position: "relative",
                                        }}
                                        onClick={() => openPaymentModal(data.ord1Id, data.orderNo)}
                                      >
                                        {isButtonHovered(rowIndex, "btn1") && (
                                          <div className="hover-text">Payment Details</div>
                                        )}
                                      </i>

                                      <i
                                        onMouseEnter={() =>
                                          handleMouseEnter(rowIndex, "btn2")
                                        }
                                        onMouseLeave={() =>
                                          handleMouseLeave(rowIndex, "btn2")
                                        }
                                        onClick={() => handlePaymentPay(data.orderNo, data.ord1Id)}
                                        class="fa-solid fa-sack-dollar icon_ov1"
                                        style={{
                                          color: "#7c9504",
                                          position: "relative",
                                          // marginLeft: "20px",
                                        }}
                                      >
                                        {isButtonHovered(rowIndex, "btn2") && (
                                          <div className="hover-text">Pay Online</div>
                                        )}
                                      </i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="tdStyle">
                              <div
                                style={{
                                  display: "grid",
                                  justifyContent: "center",
                                  // height: "140px",
                                  height: "80px",
                                  alignItems: "center"
                                }}
                              >
                                {/* <i
                              onMouseEnter={() =>
                                handleMouseEnter(rowIndex, "btn3")
                              }
                              onMouseLeave={() =>
                                handleMouseLeave(rowIndex, "btn3")
                              }
                              class="fa-solid fa-pen-to-square icon_ov1"
                              style={{ color: "black", position: "relative" }}
                            >
                              {isButtonHovered(rowIndex, "btn3") && (
                                <div
                                  className="hover-text"
                                  style={{ marginRight: "3%" }}
                                >
                                  Edit
                                </div>
                              )}
                            </i> */}
                                {/* <i
                              onMouseEnter={() =>
                                handleMouseEnter(rowIndex, "btn4")
                              }
                              onMouseLeave={() =>
                                handleMouseLeave(rowIndex, "btn4")
                              }
                              class="fa-solid fa-circle-plus icon-container icon_ov1"
                              onClick={openAddMoreModal}
                              style={{
                                color: "darkgreen",
                                position: "relative",
                              }}
                            >
                              {isButtonHovered(rowIndex, "btn4") && (
                                <div
                                  className="hover-text"
                                  style={{ marginTop: "2%" }}
                                >
                                  Add
                                </div>
                              )}
                            </i> */}
                                <i
                                  onMouseEnter={() =>
                                    handleMouseEnter(rowIndex, "btn5")
                                  }
                                  onMouseLeave={() =>
                                    handleMouseLeave(rowIndex, "btn5")
                                  }
                                  class="fa-brands fa-google icon_ov1"
                                  onClick={() => openGstModal(data.ord1Id)}
                                  style={{
                                    color: "darkblue",
                                    position: "relative",
                                  }}
                                >
                                  {isButtonHovered(rowIndex, "btn5") && (
                                    <div
                                      className="hover-text"
                                      style={{ marginTop: "4%" }}
                                    >
                                      GST
                                    </div>
                                  )}
                                </i>
                                <div style={{ display: data.paymentStatus == "Done" ? "block" : "none" }}>
                                  <i
                                    onClick={() => handlePrintButton(data.ord1Id)}
                                    onMouseEnter={() =>
                                      handleMouseEnter(rowIndex, "btn6")
                                    }
                                    onMouseLeave={() =>
                                      handleMouseLeave(rowIndex, "btn6")
                                    }
                                    class="fa-solid fa-receipt icon_ov1"
                                    style={{ color: "darkred", position: "relative" }}
                                  // onClick={}
                                  >
                                    {isButtonHovered(rowIndex, "btn6") && (
                                      <div style={{ marginTop: "6%" }}
                                        className="hover-text"
                                      >
                                        Receipt
                                      </div>
                                    )}
                                  </i>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      });
                  }
                })()}
                {isShowTotalAmountInReport && (
                  <tr className="table_tbody_Order_List"
                    style={{ cursor: "pointer", backgroundColor: "ghostWhite" }} >
                    <td className="td_col6 td-padding-sn td_footer" colSpan={2} style={{ textAlign: "end" }}>
                      <span style={{ fontWeight: "600" }}>Total NP:</span>
                      {(totalNoOfAdult + totalNoOfChild)} &nbsp;
                      <br />
                      <span style={{ fontWeight: "600" }}>Total NCP:</span>
                      {totalNoOfChild == isNaN ? 0 : totalNoOfChild} &nbsp;
                      <br />
                      <span style={{ fontWeight: "600" }}>Total NAP:</span>
                      {totalNoOfAdult == isNaN ? 0 : totalNoOfAdult} &nbsp;
                      <br />
                    </td>
                    <td className="td_col6 td-padding-sn td_footer" colSpan={1} style={{ textAlign: "end" }}>

                    </td>
                    <td className="td_col6 td-padding-sn td_footer" colSpan={1} style={{ textAlign: "end" }}></td>
                    <td className="td_col6 td-padding-sn td_footer" colSpan={1}>
                      <span style={{ fontWeight: "600" }}>Total Amt: &#x20B9;</span>
                      {orderTotalAmt == isNaN ? 0 : orderTotalAmt}
                      <br />
                      <span style={{ fontWeight: "600" }}>Cancel Amt: &#x20B9;</span>
                      {orderCancelAmt == isNaN ? 0 : orderCancelAmt}
                      <br />
                      <span style={{ fontWeight: "600" }}>Bal. Amt: &#x20B9;</span>
                      {orderBalAmt == isNaN ? 0 : orderBalAmt}
                      <br />
                      <span style={{ fontWeight: "600" }}>Paid Amt: &#x20B9;</span>
                      {orderPaidAmt == isNaN ? 0 : orderPaidAmt}
                    </td>
                    <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
                    <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
                    <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
                    <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
                    <td className="td_col6 td-padding-sn td_footer" colSpan={1}>
                      <span style={{ fontWeight: "600" }}>Total Returned: </span>
                      {totalReturn}
                      <br />
                      <span style={{ fontWeight: "600" }}>Total Return Request: </span>
                      {totalReturnRequest}
                      <br />
                    </td>
                    <td className="td_col6 td-padding-sn td_footer" colSpan={4}></td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/****************** Home Delivery **************/}

          <div id="homedelivery" style={{ display: "none" }}>
            <HomeDelivery
              deliveryBoy={deliveryBoy}
              orderListHomeDelivery={orderListHomeDelivery}
              setOrderListHomeDelivery={setOrderListHomeDelivery}
              formatDate1={formatDate}
              fetchOrderData={fetchOrderDataHome}
              serviceTypeId={serviceTypeId}
              OrderWiseButtonClick={OrderWiseButtonClick}
              fetchOrderDataHome={fetchOrderDataHome}
              handleRefreshBtn={handleRefreshBtn}
              isShowTotalAmountInReport={isShowTotalAmountInReport}
            />
            {/* <HomeDelivery orderList={orderList}/> */}
          </div>
          {/****************** Pickup *********************/}
          <div id="pickup" style={{ display: "none" }}>
            <PickUp formatDate={formatDate}
              fetchOrderData={fetchOrderDataPickup}
              orderListPickUp={orderListPickUp}
              setOrderListPickUp={setOrderListPickUp}
              serviceTypeId={serviceTypeId}
              handleRefreshBtn={handleRefreshBtn}
              isShowTotalAmountInReport={isShowTotalAmountInReport} />
          </div>
          {/****************** Pos *********************/}
          <div id="pos" style={{ display: "none" }}>
            <POS formatDate={formatDate}
              fetchOrderData={fetchOrderDataPOS}
              orderListPos={orderListPos}
              setOrderListPos={setOrderListPos}
              serviceTypeId={serviceTypeId}
              handleRefreshBtn={handleRefreshBtn}
              isShowTotalAmountInReport={isShowTotalAmountInReport} />
          </div>
        </div>
      </div>
      {/************************** Info Modal ***************/}
      {Modal && (
        <OrderDetailStatusModal
          closeModal={closeModal}
          ItemDetail={ItemDetail}
        />
      )}
      {/************************** Payment Modal ***************/}
      {PaymentModal && (
        <PaymantModeModel handlePayment={handlePayment} PaymentModalData={PaymentModalData}
          closePaymentModal={closePaymentModal} orderList={orderList}
          orderListHomeDelivery={orderListHomeDelivery} orderListPickUp={orderListPickUp}
          serviceTypeId={serviceTypeId} />
      )}
      {/************************** Add More Modal ***************/}
      {AddOrderModal && (
        <div className="modal-back">
          <div className="info-modal" style={{ height: "500px", width: "65%", overflow: "scroll" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "1px 10px",
              }}
            >
              <label className="fw-600" style={{ fontSize: "18px" }}>
                Payment Details
              </label>
              <i
                class="fa-solid fa-xmark xmark"
                onClick={closeAddOrderModal}
              ></i>
            </div>
            <hr />
            <div className="space-between">
              <div className="space-evenly">
                <label className="fw-600">Order No:&nbsp;</label>
                <label className="">2/92007</label>
              </div>
              <div className="space-evenly">
                <label className="fw-600">Order Date:&nbsp;</label>
                <label className="">13/10/2023</label>
              </div>
              <div className="space-evenly">
                <label className="fw-600">Table No:&nbsp;</label>
                <label className="">Pergola 9</label>
              </div>
              <div className="space-evenly">
                <label className="fw-600">Order Time:&nbsp;</label>
                <label className="">10:51</label>
              </div>
            </div>
            <div className="space-evenly mt-4">
              <div className="space-evenly">
                <label className="fw-600 mt-2">Address:&nbsp;</label>
                <textarea
                  className="form-control"
                  style={{ height: "10%" }}
                ></textarea>
              </div>
              <div className="space-evenly">
                <label className="fw-600 mt-2">Mobile&nbsp;No:&nbsp;</label>
                <input
                  type="text"
                  className="form-control"
                  style={{ height: "55%" }}
                />
                &nbsp; &nbsp;
                <input
                  type="text"
                  className="form-control"
                  style={{ height: "55%" }}
                />
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <label className="fw-600 mt-1">Search: &nbsp;</label>
              <select
                className="form-control"
                onChange={itemSelected}
                style={{ width: "max-content" }}
              >
                <option selected disabled>
                  Select
                </option>
                <option value="Beverages">Beverages</option>
                <option value="Dessert">Dessert</option>
                <option value="Soup">Soup</option>
              </select>{" "}
              &nbsp;
              <button
                className="btn"
                style={{
                  backgroundColor: "darkblue",
                  color: "white",
                  fontWeight: "600",
                }}
              >
                Search Button
              </button>
            </div>
            <hr />
            <div
              className="scroll-container"
              style={{ backgroundColor: "ghostwhite" }}
            >
              <div className="gridView mt-3" style={{ height: "35vh" }}>
                {/*{(() => {*/}
                {/*    console.log(itemList)*/}
                {/*    if (itemList != null)*/}
                {itemList.map((items) => (
                  <div
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    <div className="listItem">
                      <img
                        src={items.image}
                        alt="FoodImage"
                        style={{
                          height: "65px",
                          width: "65px",
                          borderRadius: "15px",
                        }}
                      />
                      <div className="mt-2" style={{ marginLeft: "5px" }}>
                        <label className="menuLabel">{items.itemName}</label>
                        <h6
                          style={{
                            fontWeight: "600",
                            color: "#606060",
                            fontSize: "13px",
                          }}
                        >
                          Price: &#x20B9;{items.price}
                        </h6>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <button
                            className="btn"
                            style={{
                              padding: "0px",
                              fontSize: "12px",
                              fontWeight: "12px",
                              height: "25%",
                              width: "50%",
                              backgroundColor: "green",
                              color: "white",
                            }}
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {/*})()}*/}
              </div>
            </div>
            <hr />
            <div
              style={{ position: "absolute", bottom: "10px", right: "10px" }}
            >
              <div
                className="mt-3"
                style={{ display: "flex", justifyContent: "end" }}
              >
                <button className="greyBtn" onClick={closeAddOrderModal}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {/************************** Info Modal ***************/}
      {GstModal && (
        <GstModalOrderView closeGstModal={closeGstModal} ItemDetail={ItemDetail} ord1Id={ord1Id} />
      )}
      {/************************** Item Detail Modal ***************/}
      {ItemDetailModal && (
        <ItemDetailModalBox
          closeItemDetailModal={closeItemDetailModal}
          ItemDetail={ItemDetail}
          setItemDetail={setItemDetail}
          serviceTypeId={serviceTypeId}
        />
      )}
      {loading && <Loader />}
    </div>
  );
};

export default OrderView;
