import React, { useState } from "react";
import './MasterSetting.css';
import { } from 'react-router-dom';
//import 'bootstrap/dist/css/bootstrap.min.css';
import EwayBillSetting from './EwayBillSetting';
import BillSeries from "./BillSeries";
import AttributeField from "../MasterSetting/AttributeField";

const Tab = ({ label, onClick, isActive }) => (
    <div className={`tab ${isActive ? "active" : ""}`} onClick={onClick}>
        {label}
    </div>
);

const Content = ({ children, isActive }) => (
    <div className={`content ${isActive ? "active" : ""}`}>{children}</div>
);

const MasterSetting = () => {

    const [activeTab, setActiveTab] = useState(1);

    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
    };

    return (
        <div style={{ backgroundColor: "white", padding: "10px" }}>
            <div className="panel-heading" style={{ backgroundColor: "#212e4f" }}>
                <div className="panel-head-title">
                    <h2 className="panel-title" style={{ color: "white", fontWeight: "600" }}>Master Settings</h2>
                </div >
            </div>

            <div className="tab-container">
                <div className="tabs">
                    <Tab style={{ color: "white" }}
                        icon=""
                        label="Bill Setting"
                        onClick={() => handleTabClick(1)}
                        isActive={activeTab === 1}
                    />
                    <Tab
                        label="Popup & Search Condition"
                        onClick={() => handleTabClick(2)}
                        isActive={activeTab === 2}
                    />
                    <Tab
                        label="Optional Field"
                        onClick={() => handleTabClick(3)}
                        isActive={activeTab === 3}
                    />
                    <Tab
                        label="Barcode Setting"
                        onClick={() => handleTabClick(4)}
                        isActive={activeTab === 4}
                    />
                    <Tab
                        label="Loyality"
                        onClick={() => handleTabClick(5)}
                        isActive={activeTab === 5}
                    />
                    <Tab
                        label="Cess Setting"
                        onClick={() => handleTabClick(6)}
                        isActive={activeTab === 6}
                    />
                    <Tab
                        label="Attribute Field"
                        onClick={() => handleTabClick(7)}
                        isActive={activeTab === 7}
                    />
                    <Tab
                        label="Stock Out & Sync"
                        onClick={() => handleTabClick(8)}
                        isActive={activeTab === 8}
                    />
                    <Tab
                        label="E-Way bill Settings"
                        onClick={() => handleTabClick(9)}
                        isActive={activeTab === 9}
                    />
                </div>
                <div className="tab-content">
                    {activeTab === 1 && (
                        <Content isActive={activeTab === 1}>
                            <div className="" style={{ backgroundColor: "white" }}>

                                <BillSeries />
                            </div>
                        </Content>
                    )}
                    {activeTab === 2 && (
                        <Content isActive={activeTab === 2}>
                            <div style={{ backgroundColor: "white" }}>

                            </div>
                        </Content>
                    )}
                    {activeTab === 3 && (
                        <Content isActive={activeTab === 3}>
                            <div style={{ backgroundColor: "white" }}>

                            </div>
                        </Content>
                    )}
                    {activeTab === 4 && (
                        <Content isActive={activeTab === 4}>
                            <div style={{ backgroundColor: "white" }}>

                            </div>
                        </Content>
                    )}
                    {activeTab === 5 && (
                        <Content isActive={activeTab === 5}>
                            <div style={{ backgroundColor: "white" }}>

                            </div>
                        </Content>
                    )}
                    {activeTab === 6 && (
                        <Content isActive={activeTab === 6}>
                            <div style={{ backgroundColor: "white" }}>

                            </div>
                        </Content>
                    )}
                    {activeTab === 7 && (
                        <Content isActive={activeTab === 7}>
                            <div style={{ backgroundColor: "white" }}>
                                <AttributeField />
                            </div>
                        </Content>
                    )}
                    {activeTab === 8 && (
                        <Content isActive={activeTab === 8}>
                            <div style={{ backgroundColor: "white" }}>

                            </div>
                        </Content>
                    )}
                    {activeTab === 9 && (
                        <Content isActive={activeTab === 9}>
                            <div style={{ backgroundColor: "white" }}>
                                <EwayBillSetting />
                            </div>
                        </Content>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MasterSetting;
