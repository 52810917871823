import React, { useEffect, useState, useRef, useContext } from "react";
import "./POSInvoice";
//import { Link } from 'react-router-dom';
import PreviousOrder from '../../Inventory/Dashboard/PreviousOrder';
import AccountMaster from '../../Inventory/Master/AccountMaster/AccountMaster';
import OrderFeedback from "../OrderTaker/OrderFeedback";
import ItemGroupApi from '../../API/APIServices/ItemGroup';
import TableGroupApi from '../../API/APIServices/TableGroup';
import TableApi from '../../API/APIServices/Table';
import OrderApi from '../../API/APIServices/Order';
import UserCredentialApi from '../../API/APIServices/UserCredential';
import ItemDepartmentApi from '../../API/APIServices/ItemDepartment';
import AccountApi from "../../API/APIServices/Account";
import ItemMasterApi from "../../API/APIServices/ItemMaster";
import TaxCategoryApi from "../../API/APIServices/TaxCategory";
import BillTypeApi from "../../API/APIServices/BillType";
import BillSeriesMasterApi from "../../API/APIServices/BillSeriesMaster";
import Table from "../../API/APIServices/Table";
import Account from "../../API/APIServices/Account";
import BillSeriesMaster from "../../API/APIServices/BillSeriesMaster";
import BillPaymentMode from "../../API/APIServices/BillPaymentMode";
import Loader from "../../Inventory/Props/Loader";
import MyContext from "../../Inventory/MyContext";
import OrderTakerBillPrint from "../OrderTaker/OrderTakerBillPrint";
import KotPrintPage from "../KotManager/KotPrintPage";
import useIntersectionObserver from "./useIntersectionObserver "
import Order from "../../API/APIServices/Order";
import DeliveryAndTimeSlot from "../../API/APIServices/DeliveryAndTimeSlot";
import PaymantModeModel from "./PaymantModeModel";
import { useLocation } from "react-router-dom";
import moment from "moment";
import BillLayoutSetting from "../../API/APIServices/BillLayoutSetting";
import ToolTipMaster from "../../Inventory/Props/ToolTipMaster";

const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const OrderPage = () => {

    // const imageBaseUrl = `${WebApiUrl}/api/v1/ItemMaster/Image?D1ItemId=`;
    const { imageBaseUrl, isAllowModificationOfVoucher } = useContext(MyContext);
    console.log("isAllowModificationOfVoucher", isAllowModificationOfVoucher);

    const [loading, setLoading] = useState(false);

    const navigate = useLocation();

    useState(() => {
        document.title = 'POS';
    }, []);

    const [orderData, setOrderData] = useState({
        ord1Id: 0,
        orderSeriesId: 0,
        orderNo: "0",
        autoOrderNo: 0,
        serviceTypeId: 0,
        overallOrderStatus: "Confirm",  //confirm only for Confirm & KOT
        orderDate: "",
        orderTime: "",
        orderDoneBy: 0,
        orderDoneOn: "",
        orderConfirmedBy: 0,
        orderConfirmedOn: "",
        orderDoneFrom: "POS",
        d1AccountId: 0,
        d2AccountId: 0,
        billTypeId: 0,
        tableGroupId: 0,
        tableId: 0,
        noOfPerson: 0,
        noOfAdult: 0,
        noOfChild: 0,
        couponCode: "",
        couponCodeAmount: 0,
        deliveryCharges: 0,
        deliveryDate: "",
        deliveryTimeId: 0,
        deliveryStatus: "",
        deliveryBoyId: 0,
        deliveryBoyDispatchTime: "00:00:00",
        deliveryBoyTripNo: 0,
        deliveryBoyTripIncentive: 0,
        deliveryReferenceNo: 0,
        deliveryPreferenceNo: 0,
        deliveryTripStatus: "",
        orderPrintStatus: 0,
        totalAmt: 0,
        initialOrderTotalAmt: 0,
        grandTotalAmt: 0,
        paymentStatus: "Pending",
        paymentAmount: 0,
        returnAmount: 0,
        discountAmount: 0,
        paymentDoneBy: 0,
        paymentDoneOn: "",
        paymentAttemptedCounter: 0,
        paymentMode: 0,
        paymentBank: "",
        paymentRemarks: "",
        remarks: "",
        orderSuggestion: "",
        kotDispatchTargetTime: "",  // deliveryTime
        latitude: "",
        longitude: "",
        currencyId: 0,
        hasRunningOrder: false,
        runningOrderCounter: 0,
        runningOrderTotalAmt: 0,
        kotManagerId: 0,
        deliveryAddress: "",
        name: "",
        mobileNo: "",
        companyGSTNo: "",
        companyDetails: "",
        o1CancelledAmt: 0,
    });

    // const [menuItems, seyMenuItems] = useState([
    //     {
    //         id: 1,
    //         itemName: "Tandoori Momos",
    //         barcode: 'I0001',
    //         image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQq673pOk1Wllffzp4AEagD_qCN_Dng1qTnKg&usqp=CAU",
    //         price: 150.00,
    //         variableItem: true,
    //         deliveryPreference: 1,
    //         quantity: 1,
    //         color: "white"
    //     },
    //     {
    //         id: 2,
    //         itemName: "Garlic Bread",
    //         barcode: 'I0002',
    //         image: "https://w7.pngwing.com/pngs/55/450/png-transparent-garlic-bread-domino-s-pizza-falafel-benefits-of-raw-garlic-baked-goods-food-american-food.png",
    //         price: 100.00,
    //         variableItem: false,
    //         deliveryPreference: 2,
    //         quantity: 1,
    //         color: "white"
    //     },
    //     {
    //         id: 3,
    //         itemName: "Chilli Mushroom",
    //         barcode: 'I0003',
    //         image: "https://img-global.cpcdn.com/recipes/9291e0fe9073f60a/1200x630cq70/photo.jpg",
    //         price: 120.00,
    //         variableItem: false,
    //         deliveryPreference: 3,
    //         quantity: 1,
    //         color: "white"
    //     },
    //     {
    //         id: 4,
    //         itemName: "Hakka Noodles",
    //         barcode: 'I0004',
    //         image: "https://m.media-amazon.com/images/I/71dXe+aKzZL.jpg",
    //         price: 150.00,
    //         variableItem: true,
    //         deliveryPreference: 3,
    //         quantity: 1,
    //         color: "white"
    //     },
    //     {
    //         id: 5,
    //         itemName: "Veg Sandwich",
    //         barcode: 'I0005',
    //         image: "https://images.unsplash.com/photo-1586190848861-99aa4a171e90?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8YnVyZ2VyJTIwcG5nfGVufDB8fDB8fHww&w=1000&q=80",
    //         price: 145.00,
    //         variableItem: false,
    //         deliveryPreference: 2,
    //         quantity: 1,
    //         color: "white"
    //     },
    //     {
    //         id: 6,
    //         itemName: "Spring Roll",
    //         barcode: 'I0006',
    //         image: "https://www.pngmart.com/files/16/Crispy-Spring-Rolls-Transparent-PNG.png",
    //         price: 80.00,
    //         variableItem: false,
    //         deliveryPreference: 1,
    //         quantity: 1,
    //         color: "white"
    //     },
    //     {
    //         id: 7,
    //         itemName: "Cheese Pasta",
    //         barcode: 'I0007',
    //         image: "https://e7.pngegg.com/pngimages/38/556/png-clipart-pasta-pasta-thumbnail.png",
    //         price: 180.00,
    //         variableItem: false,
    //         deliveryPreference: 1,
    //         quantity: 1,
    //         color: "white"
    //     },
    //     {
    //         id: 8,
    //         itemName: "Aloo Tikki Burger",
    //         barcode: 'I0008',
    //         image: "https://images.unsplash.com/photo-1586190848861-99aa4a171e90?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8YnVyZ2VyJTIwcG5nfGVufDB8fDB8fHww&w=1000&q=80",
    //         price: 280.00,
    //         variableItem: false,
    //         deliveryPreference: 2,
    //         quantity: 1,
    //         color: "white"
    //     },
    //     {
    //         id: 9,
    //         itemName: "French Fries",
    //         barcode: 'I0009',
    //         image: "https://kirbiecravings.com/wp-content/uploads/2019/09/easy-french-fries-1.jpg",
    //         price: 99.00,
    //         variableItem: false,
    //         deliveryPreference: 3,
    //         quantity: 1,
    //         color: "white"
    //     },
    //     {
    //         id: 10,
    //         itemName: "Tandoori Momos",
    //         barcode: 'I0010',
    //         image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQq673pOk1Wllffzp4AEagD_qCN_Dng1qTnKg&usqp=CAU",
    //         price: 150.00,
    //         variableItem: true,
    //         deliveryPreference: 3,
    //         quantity: 1,
    //         color: "white"
    //     },
    //     {
    //         id: 11,
    //         itemName: "Garlic Bread",
    //         barcode: 'I0011',
    //         image: "https://w7.pngwing.com/pngs/55/450/png-transparent-garlic-bread-domino-s-pizza-falafel-benefits-of-raw-garlic-baked-goods-food-american-food.png",
    //         price: 100.00,
    //         variableItem: false,
    //         deliveryPreference: 2,
    //         quantity: 1,
    //         color: "white"
    //     },
    //     {
    //         id: 12,
    //         itemName: "Chilli Mushroom",
    //         barcode: 'I0012',
    //         image: "https://img-global.cpcdn.com/recipes/9291e0fe9073f60a/1200x630cq70/photo.jpg",
    //         price: 120.00,
    //         variableItem: false,
    //         deliveryPreference: 1,
    //         quantity: 1,
    //         color: "white"
    //     },
    //     {
    //         id: 13,
    //         itemName: "Hakka Noodles",
    //         barcode: 'I0013',
    //         image: "https://m.media-amazon.com/images/I/71dXe+aKzZL.jpg",
    //         price: 150.00,
    //         variableItem: true,
    //         deliveryPreference: 1,
    //         quantity: 1,
    //         color: "white"
    //     },
    //     {
    //         id: 14,
    //         itemName: "Veg Sandwich",
    //         barcode: 'I0014',
    //         image: "https://images.unsplash.com/photo-1586190848861-99aa4a171e90?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8YnVyZ2VyJTIwcG5nfGVufDB8fDB8fHww&w=1000&q=80",
    //         price: 145.00,
    //         variableItem: false,
    //         deliveryPreference: 2,
    //         quantity: 1,
    //         color: "white"
    //     },
    //     {
    //         id: 15,
    //         itemName: "Spring Roll",
    //         barcode: 'I0015',
    //         image: "https://www.pngmart.com/files/16/Crispy-Spring-Rolls-Transparent-PNG.png",
    //         price: 80.00,
    //         variableItem: false,
    //         deliveryPreference: 3,
    //         quantity: 1,
    //         color: "white"
    //     },
    //     {
    //         id: 16,
    //         itemName: "Cheese Pasta",
    //         barcode: 'I0016',
    //         image: "https://e7.pngegg.com/pngimages/38/556/png-clipart-pasta-pasta-thumbnail.png",
    //         price: 180.00,
    //         variableItem: false,
    //         deliveryPreference: 3,
    //         quantity: 1,
    //         color: "white"
    //     },
    //     {
    //         id: 17,
    //         itemName: "Aloo Tikki Burger",
    //         barcode: 'I0017',
    //         image: "https://images.unsplash.com/photo-1586190848861-99aa4a171e90?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8YnVyZ2VyJTIwcG5nfGVufDB8fDB8fHww&w=1000&q=80",
    //         price: 280.00,
    //         variableItem: false,
    //         deliveryPreference: 2,
    //         quantity: 1,
    //         color: "white"
    //     },
    //     {
    //         id: 18,
    //         itemName: "French Fries",
    //         barcode: 'I0018',
    //         image: "https://kirbiecravings.com/wp-content/uploads/2019/09/easy-french-fries-1.jpg",
    //         price: 99.00,
    //         variableItem: false,
    //         deliveryPreference: 1,
    //         quantity: 1,
    //         color: "white"
    //     },
    // ])

    const [menuItems, setMenuItems] = useState([])
    const [order3, setOrder3] = useState();
    const [order2, setOrder2] = useState([]);

    const [selectedItemIndex, setSelectedItemIndex] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const filteredData = [
                {
                    fieldName: "availableForPOS",
                    operatorName: "Equal",
                    compareValue: true
                }
            ]
            try {
                setLoading(true)
                const data = await ItemMasterApi.GetItemMasterWithFilter(filteredData);
                console.log("Menu Items")
                console.log(data);
                setMenuItems(data);
                setLoading(false)
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false)
            }
        };
        fetchData();
    }, []);

    const [activeButton, setActiveButton] = useState(null);

    const [inputVisible, setInputVisible] = useState(false);

    const handleButtonClick = (buttonId, id, event) => {

        const updatedValues = {
            ...getDeliveryAndTimeSlotId,
            orderType: id,
            deliveryPointName: 1,
            distance: 0,
            isCurrentDate: true,
        };
        setDeliveryAndTimeSlotId((prevState) => ({
            ...prevState,
            orderType: id,
            deliveryPointName: 1,
            distance: 0,
            isCurrentDate: true,
        }));

        const data = fetchTimeSlot(updatedValues);

        if (id != 751) {
            setRunningOrder([])
            fetchOrderData(id)
        }
        else {
            fetchTableGroupData();
            //fetchTableData();
        }
        //alert(buttonId)
        if (buttonId === 2 || buttonId === 3) {
            setInputVisible(true);
            document.getElementById('gridView').style.gridTemplateColumns = "repeat(3, auto)";
        }
        else {
            setInputVisible(false)
            document.getElementById('gridView').style.gridTemplateColumns = "repeat(3, auto)";
        }
        setActiveButton(buttonId);

        if (data) {
            setOrderData((prevValues) => ({
                ...prevValues,
                "serviceTypeId": id,
                "deliveryTimeId": data[0]?.id,
                "ord1Id": 0,
                "tableId": 0,
                "tableGroupId": 0
            }));
        } else {
            setOrderData((prevValues) => ({
                ...prevValues,
                "serviceTypeId": id,
                "ord1Id": 0,
                "tableId": 0,
                "tableGroupId": 0
            }));
        }
        const element = document.getElementById('TableNo')
        if (element) {
            element.style.display = "none";
            document.getElementById('menu-pos').style.width = "100%";
        }
    };

    const [variableId, setVariableId] = useState();

    const handleButtonClickforVariableItem = (index, d2Id) => {
        setSelectedItemIndex(index);
        setVariableId(d2Id)
        console.log("index", index, "d2Id", d2Id);
    };

    const VariableSaveBtn = () => {
        ItemButtonClick(variableId)
        closeVariableItemModal();
    }

    const getVariableStyle = (buttonId) => {
        return {
            backgroundColor: activeButton === buttonId ? 'white' : '#12234d',
            fontWeight: activeButton === buttonId ? '800' : '600',
            color: activeButton === buttonId ? '#12234d' : 'white',
            border: activeButton === buttonId ? '5px solid #12234d' : '3px solid white',
            transition: 'background-color 0.3s, font-weight 0.3s',
            height: 'auto',
            paddingTop: activeButton === buttonId ? '3px' : '4px',
            minWidth: activeButton === buttonId ? '150px' : '150px',
            minHeight: activeButton === buttonId ? '100px' : '100px',
        };
    };
    const getVariableStyleWhite = (buttonId) => {
        return {
            backgroundColor: activeButton === buttonId ? '#12234d' : 'white',
            fontWeight: activeButton === buttonId ? '800' : '600',
            color: activeButton === buttonId ? 'white' : '#12234d',
            border: activeButton === buttonId ? '1px solid white' : '1px solid #12234d',
            transition: 'background-color 0.3s, font-weight 0.3s',
            height: 'auto',
            paddingTop: '5px',
            minWidth: activeButton === buttonId ? '150px' : '150px',
            minHeight: activeButton === buttonId ? '100px' : '100px',

        };
    };

    const getButtonStyle = (buttonId) => {
        return {
            backgroundColor: activeButton === buttonId ? 'white' : '#12234d',
            fontWeight: activeButton === buttonId ? '800' : '600',
            color: activeButton === buttonId ? '#12234d' : 'white',
            border: activeButton === buttonId ? '5px solid #12234d' : '3px solid white',
            transition: 'background-color 0.3s, font-weight 0.3s',
            height: '40px',
            paddingTop: activeButton === buttonId ? '3px' : '4px',
            //':hover': {
            //    backgroundColor: activeButton === buttonId ? 'red' : 'yellow',
            //    fontWeight: '800',
            //}
        };
    };

    // *****************************{Price Update}*****************************
    // const [Price, setPrice] = useState([]);

    const [cartData, setCartData] = useState([]);

    const handleIncrement = (id) => {
        const itemIndex = cartData.findIndex(item => item.d2Id === id);
        const menuIndex = menuItems.findIndex(item => item.d2Id === id);
        if (itemIndex !== -1) {
            const updatedCartData = [...cartData];
            updatedCartData[itemIndex].quantity += 1;
            updatedCartData[itemIndex].mrpPrice = (updatedCartData[itemIndex].quantity + 1) * updatedCartData[itemIndex].salePrice;
            setCartData(updatedCartData);
            // ****************update order2******************
            const updatedOrderData = [...order2];
            updatedOrderData[itemIndex].qty += 1;
            setOrder2(updatedOrderData);
        }
        changePrice(id, menuItems[menuIndex].salePrice * cartData[itemIndex].quantity);
        //console.log(cartData[itemIndex].quantity, menuItems[index].salePrice, index)
    };

    const handleDecrement = (id) => {
        const itemIndex = cartData.findIndex(item => item.d2Id === id);
        const menuIndex = menuItems.findIndex(item => item.d2Id === id);
        if (cartData[itemIndex].quantity > 1) {
            if (itemIndex !== -1) {
                const updatedCartData = [...cartData];
                updatedCartData[itemIndex].quantity -= 1;
                setCartData(updatedCartData);
                // ***************update order2******************
                const updatedOrderData = [...order2];
                updatedOrderData[itemIndex].qty -= 1;
                setOrder2(updatedOrderData);
            }
            changePrice(id, ((menuItems[menuIndex].salePrice * cartData[itemIndex].quantity)));  //- menuItems[menuIndex].salePrice
            console.log(menuItems[menuIndex].salePrice + "---" + cartData[itemIndex].quantity)
        }
        else {
            //changePrice(id, ((menuItems[menuIndex].salePrice * cartData[itemIndex].quantity)));
            deleteCartItemClick(id);
        }
    };

    const onQuantityChange = (event, id) => {
        const data = event.target.value
        const itemIndex = cartData.findIndex(item => item.d2Id === id);
        const menuIndex = menuItems.findIndex(item => item.d2Id === id);
        const updatedCartData = [...cartData];
        updatedCartData[itemIndex].quantity = Number(data);
        setCartData(updatedCartData);

        changePrice(id, menuItems[menuIndex].salePrice * cartData[itemIndex].quantity);

        const updatedOrderData = [...order2];
        updatedOrderData[itemIndex].qty = data;
        setOrder2(updatedOrderData);
        // if (data === "0") {
        //     deleteCartItemClick(id);
        // }
    };

    const changePrice = (id, data) => {
        const itemIndex = cartData.findIndex(item => item.d2Id === id);
        if (cartData[itemIndex].quantity > 0) {
            if (itemIndex !== -1) {
                const updatedCartData = [...cartData];
                updatedCartData[itemIndex].mrpPrice = data;
                setCartData(updatedCartData);
                calculateTotalPrice(updatedCartData);
            }
        }
    }

    const [VariableItemModal, setVariableItemModal] = useState(false)

    const [VariableData, setVariableData] = useState([])

    const openVariableItemModal = async (id) => {
        setLoading(true)
        const filteredData = [
            {
                fieldName: "attributeParentId",
                operatorName: "Equal",
                compareValue: id
            },
            {
                fieldName: "availableForPOS",
                operatorName: "Equal",
                compareValue: true
            },
            // {
            //     fieldName: "isAttributeItem",
            //     operatorName: "Equal",
            //     compareValue: true
            // }
        ]
        try {
            const data = await ItemMasterApi.GetItemMasterWithFilter(filteredData);
            console.log(data);
            setVariableData(data);
            setLoading(false)
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false)
        }
        setVariableItemModal(true)
    }
    const closeVariableItemModal = () => {
        setSelectedItemIndex(null);
        setVariableItemModal(false)
    }

    const handleDelPreferenceChange = (event, id) => {
        console.log(cartData);
        console.log(order2);

        const itemIndex = cartData.findIndex(item => item.d2Id === id);
        console.log("index", itemIndex);
        console.log("id", id);
        console.log(event.target.value);

        if (itemIndex !== -1) {
            const updatedCartData = [...cartData];

            updatedCartData[itemIndex] = {
                ...updatedCartData[itemIndex],
                "preferenceNo": event.target.value
            };
            setCartData(updatedCartData);
        }

        const index = order2.findIndex(item => item.d2Id === id);

        if (itemIndex !== -1) {
            const updatedCartData = [...order2];

            updatedCartData[index] = {
                ...updatedCartData[index],
                "preferenceNo": event.target.value
            };
            setOrder2(updatedCartData);
        }
    }

    const ItemButtonClick = async (id) => {
        console.log(updatedId)
        if (updatedId === true) {
            console.log("Updated");
            setUpdatedId(false);
            //return true;
        }
        // ***************************************
        console.log(menuItems);
        const selectedItem = menuItems.find(item => item.d2Id === id);

        console.log(selectedItem);

        if (selectedItem.attributeParentId === 0 && selectedItem.isAttributeItem === true) {
            openVariableItemModal(selectedItem.d2Id);
            return true;
        }
        if (selectedItem) {
            const itemIndex = cartData.findIndex(item => item.d2Id === id);
            if (itemIndex !== -1) {
                handleIncrement(id);
            } else {
                setCartData(prevCartData => {
                    const updatedOrderStatus = [...prevCartData];
                    const updatedItem = {
                        ...selectedItem,
                        "quantity": 1,
                        "preferenceNo": 0
                    };
                    updatedOrderStatus.push(updatedItem);
                    return updatedOrderStatus;
                });
                console.log(selectedItem);

                var kotStatus = "Received";
                if (selectedItem.preferenceNo === 1) {
                    //kotStatus = "Transferred"
                }
                else {
                    //kotStatus = "Received"
                }
                setOrder3({
                    orderStatus: "Received",
                    orderDateTime: "2024-02-06T09:54:21.043Z"
                })

                if (selectedItem.taxCategory1 !== 0) {
                    const data = await TaxCategoryApi.getTaxCategoryById(selectedItem.taxCategory1)
                    setOrder2([
                        ...order2,
                        {
                            //ord1Id: order2[0].ord1Id,
                            d2Id: selectedItem.d2Id,
                            godownId: selectedItem.defaultGodown,  //will get from Item Master
                            d1ItemId: selectedItem.d1Id,  //will get from Item Master
                            d2ItemId: selectedItem.d2Id,  //will get from Item Master
                            qty: 1,//selectedItem.quantity,
                            rate: selectedItem.salePrice,
                            totalAmt: 1 * selectedItem.salePrice,  //selectedItem.quantity
                            kotStatus: kotStatus,
                            preferenceNo: 0,  //selectedItem.preferenceNo,
                            orderDate: "",
                            orderTime: "",
                            itemSuggestion: "",
                            itemRunningOrderCounter: 0,
                            orderRunningQty: 0,
                            itemRunningQty: orderData.ord1Id !== 0 ? orderData.qty : 0,
                            taxableAmount: 1 * selectedItem.salePrice,
                            cgstPer: data.localTaxCGST,
                            cgstValue: (1 * selectedItem.salePrice) * (data.localTaxCGST / 100),
                            sgstPer: data.localTaxSGST,
                            sgstValue: (1 * selectedItem.salePrice) * (data.localTaxSGST / 100),
                            igstPer: data.centralTaxIGST,
                            igstValue: (1 * selectedItem.salePrice) * (data.centralTaxIGST / 100),
                            cessPer: data.cessTax,
                            cessValue: (1 * selectedItem.salePrice) * (data.cessTax / 100),
                            totalTaxableAmt: 1 * selectedItem.salePrice,
                            isLocal: true,    //true for table order
                            isInclusive: false,
                            hasComplementryItem: selectedItem.addasComplementaryItem,
                            cookStatus: "Pending",
                            itemIncentivePoint: selectedItem.salesManItemIncentive,
                            ord2Id: 0,
                        }
                    ]);
                }
                else {
                    setOrder2([
                        ...order2,
                        {
                            //ord1Id: order2[0].ord1Id,
                            d2Id: selectedItem.d2Id,
                            godownId: selectedItem.defaultGodown,  //will get from Item Master
                            d1ItemId: selectedItem.d1Id,  //will get from Item Master
                            d2ItemId: selectedItem.d2Id,  //will get from Item Master
                            qty: 1,//selectedItem.quantity,
                            rate: selectedItem.salePrice,
                            totalAmt: 1 * selectedItem.salePrice,  //selectedItem.quantity
                            kotStatus: kotStatus,
                            preferenceNo: 0,  //selectedItem.preferenceNo,
                            orderDate: "",
                            orderTime: "",
                            itemSuggestion: "",
                            itemRunningOrderCounter: 0,
                            orderRunningQty: 0,
                            itemRunningQty: orderData.ord1Id !== 0 ? orderData.qty : 0,
                            taxableAmount: 1 * selectedItem.salePrice,
                            cgstPer: 0,
                            cgstValue: 0,
                            sgstPer: 0,
                            sgstValue: 0,
                            igstPer: 0,
                            igstValue: 0,
                            cessPer: 0,
                            cessValue: 0,
                            totalTaxableAmt: 1 * selectedItem.salePrice,
                            isLocal: false,
                            isInclusive: false,
                            hasComplementryItem: false,
                            cookStatus: "Pending",
                            itemIncentivePoint: 0,
                            ord2Id: 0,
                        }
                    ]);
                }
            }
        }
        console.log(order2);
        // const updatedCartData = [...cartData];
        // updatedCartData[itemIndex].quantity += 1;
        // setCartData(updatedCartData);
    }

    const deleteCartItemClick = (id) => {
        console.log(id);
        const updatedCartData = cartData.filter(item => item.d2Id !== id);
        setCartData(updatedCartData);

        const updatedCartData1 = order2.filter(item => item.d2Id !== id);
        setOrder2(updatedCartData1);

        calculateTotalPrice(updatedCartData);
    }

    // const handleTableButtonClick = (num) => {
    //     const id = "table" + num;
    //     //const name = "transaction" + num;
    //     if (document.getElementById(id).style.backgroundColor === "#9acd32") {
    //         document.getElementById(id).style.backgroundColor = "white";
    //     }
    //     else {
    //         document.getElementById(id).style.backgroundColor = "#9acd32";
    //     }
    // }

    const [isSliderOpen, setSliderOpen] = useState(false);

    const toggleSlider = () => {
        setSliderOpen(!isSliderOpen);
    };

    const [CouponModal, setCouponModal] = useState(false)

    const openCouponModal = () => {
        setCouponModal(true)
    }
    const closeCouponModal = () => {
        setCouponModal(false)
    }
    // const [couponCode, setCouponCode] = useState('');

    // const handleInputChange1 = (e) => {
    //     setCouponCode(e.target.value);
    // };
    const [CommentName, setCommentName] = useState();
    const [comments, setComments] = useState([]);
    const [commentModal, setCommentModal] = useState(false);
    const [commentedId, setCommentedId] = useState(null);
    const [selectedComment, setSelectedComment] = useState(null);

    const openCommentModal = (val, id) => {
        console.log(cartData);
        console.log(comments)
        console.log("id", id);
        setCommentModal(true);
        setCommentedId(id);
        setCommentName(val);
        setSelectedComment(comments[id] || '');

        if (input9Ref.current) {
            input9Ref.current.focus();
        }

        const itemIndex = comments.findIndex(item => item.d2Id === id);
        if (itemIndex !== -1) {
            const prevValue = comments[itemIndex].commentName;
            setSelectedComment(prevValue);
            console.log(prevValue);
        }
        else {
            // const itemIndex = orderData.findIndex(item => item.d2Id === id);
            // if (itemIndex !== -1) {
            //     setSelectedComment(orderData[itemIndex].itemSuggestion);
            // }
        }
    };

    useEffect(() => {
        if (commentModal && input9Ref.current) {
            input9Ref.current.focus();
        }
    }, [commentModal]);

    const closeCommentModal = () => {
        setCommentModal(false);
        setSelectedComment(null);
    };

    const handleCommentChange = (event) => {
        const val = event.target.value;
        setSelectedComment(val);
    };

    const saveComment = () => {
        setLoading(true)
        setComments([
            ...comments,
            {
                "d2Id": commentedId,
                "commentName": selectedComment
            }
        ]);
        const itemIndex = order2.findIndex(item => item.d2Id === commentedId);
        console.log("itemIndex", itemIndex);

        if (itemIndex !== -1) {
            const updatedOrder2 = [...order2];
            updatedOrder2[itemIndex] = {
                ...updatedOrder2[itemIndex],
                "itemSuggestion": selectedComment
            };
            setOrder2(updatedOrder2);
            console.log("updatedOrder2");
            console.log(updatedOrder2);
        }
        closeCommentModal();
        setLoading(false)
    };

    const selectStyle = {
        WebkitAppearance: 'none',  // for older versions of Chrome/Safari
        MozAppearance: 'none',     // for older versions of Firefox
        appearance: 'none',        // for modern browsers
    };
    const [AccountMasterModal, setAccountMasterModal] = useState(false)

    const openAccountMasterModal = () => {
        setAccountMasterModal(true);
    }
    const closeAccountMasterModal = () => {
        setAccountMasterModal(false)
    }
    const [FeedbackModal, setFeedbackModal] = useState(false)

    const openFeedbackModal = () => {
        setFeedbackModal(true);
    }
    const closeFeedbackModal = () => {
        setFeedbackModal(false)
    }
    const [showToast, setShowToast] = useState(false);

    const handleMessageBtn = () => {
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 1000);
    };

    const CancelItemBtn = () => {
        alert("Do you really want to Cancel this order", "ok");
    }

    const TableChangeBtn = () => {
        const userConfirmed = window.confirm("Are you sure, you want to change table?");

        if (userConfirmed) {
            alert("Select table number");
            console.log("User confirmed. Changing table...");
        } else {
            console.log("User cancelled. Table remains unchanged.");
        }
    }

    // ************************{Modal Back}**************************
    const handleModalClick = (e) => {
        e.stopPropagation();
    };
    const [itemGroupList, setItemGroupList] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const data = await ItemGroupApi.GetItemGroup();
                console.log(data);
                setItemGroupList(data);
                setLoading(false)
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false)
            }
        };
        fetchData();
    }, []);

    const [tableData, setTableData] = useState();
    const [tableNumberData, setTableNumberData] = useState();

    const fetchTableData = async () => {
        setLoading(true)
        const tableData = await TableApi.getTable();
        setTableNumberData(tableData);
        setLoading(false)
    }

    const onTableGroupSelection = async (e) => {
        setLoading(true)
        const fieldName = "tableGroupId";
        const operatorName = "Equal";
        const compareValue = e.target.value;

        const data = await TableApi.getTableFromTableGroup(fieldName, operatorName, compareValue);
        setTableNumberData(data);

        const updatedItems = data.map(item => {
            if (item.tableStatus === "Free") {
                return { ...item, tableStatus: 'NotSelected' };
            }
            return item;
        });
        setTableData(updatedItems);

        setOrderData((prevValues) => ({
            ...prevValues,
            "tableGroupId": e.target.value,
        }));
        document.getElementById('TableNo').style.display = "block";
        document.getElementById('menu-pos').style.width = "80%";
        setLoading(false)
    }

    const onOrderNoSelection = async (e) => {

        if (e.target.value == "0") {
            ClearDataFunction();
            setRunningOrder([])
            return 0;
        }
        setLoading(true)
        // alert(e.target.value)
        const orderDataaa = await OrderApi.getOrderListByOrd1Id(e.target.value);

        const data = orderDataaa.item1;
        console.log("order1Data", data);

        const updatedValues = {
            ...getDeliveryAndTimeSlotId,
            orderType: orderData.serviceTypeId,
            deliveryPointName: 1,
            distance: 0,
            isCurrentDate: false,
        };

        fetchTimeSlot(updatedValues);

        if (data.length > 0) {
            setRunningOrder([]);

            const newDataItems = data.map((item, index) => ({
                ...item,
                d2Id: item.d2ItemId,
                name: item.d2ItemName,
                barcode: item.defaultBarcode,
                image: "",
                salePrice: item.rate,
                variableItem: true,
                preferenceNo: item.preferenceNo,
                quantity: item.qty,
                color: "white",
            }));

            setComments(prevComments => [
                ...prevComments,
                ...data.map(item => ({
                    "d2Id": item.d2ItemId,
                    "commentName": item.itemSuggestion
                }))
            ]);
            console.log("runningOrder", newDataItems);
            setRunningOrder(newDataItems);

            let total = 0;

            for (let i = 0; i < newDataItems.length; i++) {
                const quantity = newDataItems[i].quantity || 0;
                const price = parseFloat(quantity * newDataItems[i].salePrice);
                if (!isNaN(price)) {
                    total += price;
                }
            }
            for (let i = 0; i < cartData.length; i++) {
                const price = parseFloat(cartData[i].quantity * cartData[i].salePrice);
                if (!isNaN(price)) {
                    total += price;
                }
            }
            setTotalAmount(total);

            setOrderData((prevValues) => ({
                ...prevValues,
                "ord1Id": data[0].ord1Id,
                "noOfChild": data[0].noChildPerson,
                "noOfAdult": data[0].noAdultPerson,
                "orderSuggestion": data[0].orderSuggestion,
                "orderSeriesId": data[0].orderSeriesId,
                "orderNo": data[0].orderNo,
                "autoOrderNo": 0,
                "serviceTypeId": data[0].serviceTypeId,
                "overallOrderStatus": "Confirm",
                "d1AccountId": data[0].d1AccId,
                "d2AccountId": data[0].accId,
                "billTypeId": data[0].billTypeId,
                "tableGroupId": data[0].tableGroupId,
                "tableId": data[0].tableId,
                "noOfPerson": data[0].noOfPerson,
                "totalAmt": data[0].o1TotalAmt,
                "o1CancelledAmt": data[0].o1CancelledAmt,
                // "initialOrderTotalAmt": data[0].initialOrderTotalAmt,
                "grandTotalAmt": data[0].grandTotal,
                "balanceAmt": data[0].balanceAmt,
                "couponCode": data[0].couponCode,
                "couponCodeAmount": data[0].couponCodeAmount,
                "orderDate": data[0].orderDate,
                "orderTime": data[0].orderTime,
                "orderConfirmedBy": data[0].orderConfirmedById,
                "orderConfirmedOn": data[0].orderConfirmedOn,
                "orderDoneFrom": data[0].orderDoneById,
                "deliveryCharges": data[0].deliveryCharges,
                "deliveryDate": data[0].deliveryDate,
                "deliveryTimeId": data[0].deliveryTimeId,
                "deliveryStatus": data[0].deliveryStatus,
                // "orderPrintStatus": data[0].orderPrintStatus,
                "paymentStatus": data[0].paymentStatus,
                "paymentAmount": data[0].paymentAmount,
                "returnAmount": data[0].returnAmount,
                // "discountAmount": data[0].discountAmount,
                "paymentDoneBy": data[0].paymentDoneById,
                "paymentDoneOn": data[0].paymentDoneOn,
                "paymentAttemptedCounter": data[0].paymentAttemptedCounter,
                "paymentMode": data[0].paymentMode,
                "paymentBank": data[0].paymentBank,
                // "paymentRemarks": data[0].paymentRemarks,
                "remarks": data[0].remarks,
                "deliveryBoyId": data[0].deliveryBoyId,
                "kotDispatchTargetTime": data[0].kotDispatchTargetTime,
                "latitude": data[0].latitude,
                "longitude": data[0].longitude,

                "name": data[0].name,
                "mobileNo": data[0].mobileNo,
                "companyGSTNo": data[0].companyGSTNo,
                "companyDetails": data[0].companyDetails,
            }));
            // ******************* SetOrder2 ********************
            setUpdatedId(true);
            setOrder2([
                ...order2,
                {
                    "godownId": data[0].iGodownId,
                    "d1ItemId": 0,
                    "d2ItemId": data[0].d2ItemId,
                    "qty": data[0].qty,
                    "rate": data[0].rate,
                    "totalAmt": data[0].o2TotalAmt,
                    "kotStatus": data[0].o2KotStatus,
                    "preferenceNo": data[0].preferenceNo,
                    "orderDate": data[0].orderDate,
                    "orderTime": data[0].orderTime,
                    "taxableAmount": data[0].o2TotalAmt,
                    "cgstPer": 0,
                    "cgstValue": 0,
                    "sgstPer": 0,
                    "sgstValue": 0,
                    "igstPer": 0,
                    "igstValue": 0,
                    "cessPer": 0,
                    "cessValue": 0,
                    "totalTaxableAmt": 0,
                    "isLocal": true,
                    "isInclusive": true,
                    "itemSuggestion": data[0].itemSuggestion,
                    "itemRunningOrderCounter": data[0].itemRunningOrderCounter,
                    "orderRunningQty": data[0].orderRunningQty,
                    "itemRunningQty": data[0].itemRunningQty,
                    "hasComplementryItem": true,
                    "cookStatus": "string",
                    "itemIncentivePoint": 0,
                    "isActive": data[0].isActive,
                    "color": "grey"
                }
            ]);

            setInputValues({
                name: data[0].accName,
                aMobileNo: data[0].accMobileNo,
                adob: data[0].accDOB,
                adom: data[0].accDOM,
                referralBy: data[0].accReferredById,
                aAddress: data[0].accAddress,
                //createdOn: ''
            })
            setInputItemValue(data[0].accName);
        }
        setLoading(false)

    }

    const onFloorSelect = async (e) => {
        setLoading(true)
        const fieldName = "tableGroupId";
        const operatorName = "Equal";
        const compareValue = e.target.value;

        const data = await TableApi.getTableFromTableGroup(fieldName, operatorName, compareValue);
        setTableNumberData(data);

        setOrderData((prevValues) => ({
            ...prevValues,
            "tableGroupId": e.target.value,
        }));
        setLoading(false)
    }

    const [updatedId, setUpdatedId] = useState(false);
    //const [previousData, setPreviousData] = useState();
    function getCurrentDate() {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');

        const currentDate = `${year}-${month}-${day}`;
        return currentDate;
    }

    const [runningOrder, setRunningOrder] = useState({});

    const onTableSelect = async (val) => {
        setLoading(true)
        const tableId = val;
        // alert(val)
        var tableGroup = 0;
        if (tableId != 0) {
            tableGroup = await Table.getTableById(tableId);
        }
        setOrderData((prevValues) => ({
            ...prevValues,
            "tableId": tableId,
            "tableGroupId": tableGroup.tableGroupId,
        }));

        const todaysDate = await getCurrentDate();

        const filterData = [
            {
                fieldName: "tableId",
                operatorName: "Equal",
                compareValue: Number(val)
            },
            {
                fieldName: "serviceTypeId",
                operatorName: "Equal",
                compareValue: 751
            },
            {
                fieldName: "overallOrderStatus",
                operatorName: "NotEqual",
                compareValue: "Done"
            },
            {
                fieldName: "overallOrderStatus",
                operatorName: "NotEqual",
                compareValue: "Cancel"
            },
            {
                fieldName: "overallOrderStatus",
                operatorName: "NotEqual",
                compareValue: "Delivered"
            },
            // {
            //     fieldName: "orderDate",
            //     operatorName: "Equal",
            //     compareValue: todaysDate   // Today's Date
            // },
            // {
            //     fieldName: "overallOrderStatus",
            //     operatorName: "Equal",
            //     compareValue: "Pending"
            // }
        ]

        // const orderDataReprt = await OrderApi.getOrderReportFilter(filterData);
        // const order1Data = await OrderApi.getOrderListByOrd1Id(orderDataReprt[0]?.ord1Id);

        // const data = order1Data.item1;

        const data = await OrderApi.getOrderListByTableId(tableId);
        console.log("order1Data", data);

        const updatedValues = {
            ...getDeliveryAndTimeSlotId,
            orderType: 751,
            deliveryPointName: 1,
            distance: 0,
            isCurrentDate: false,
        };

        fetchTimeSlot(updatedValues);

        if (data.length > 0) {    //data !== null || 
            //setCartData([]);
            setRunningOrder([]);

            const newDataItems = data.map((item, index) => ({
                ...item,
                d2Id: item.d2ItemId,
                name: item.d2ItemName,
                barcode: item.defaultBarcode,
                image: "",
                salePrice: item.rate, // item.qty * item.rate,
                variableItem: true,
                preferenceNo: item.preferenceNo,
                quantity: item.qty,
                color: "white",
                //(() => {
                // if (item.itemRunningOrderCounter === 0 || item.itemRunningOrderCounter == null || item.itemRunningOrderCounter == undefined) {
                //     return "rgba(255, 10, 10, 0.16)";  //red
                // }else if (item.itemRunningOrderCounter === 0 || item.itemRunningOrderCounter == null || item.itemRunningOrderCounter == undefined) {
                //     return "rgba(10, 14, 255, 0.16)";  //blue
                // } else if (item.itemRunningOrderCounter === 1) {
                //     return "#f0b93080"; //yellow
                // } else if (item.itemRunningOrderCounter === 2) {
                //     return "rgba(227, 19, 175, 0.35)"; //pink
                // } else if (item.itemRunningOrderCounter === 3) {
                //     return "#00800036"; //green
                // } else if (item.itemRunningOrderCounter === 4) {
                //     return "rgba(246, 110, 63, 0.4)"; //orange
                // }
                //return "rgba(255, 10, 10, 0.16)";
                //})(),
            }));

            setComments(prevComments => [
                ...prevComments,
                ...data.map(item => ({
                    "d2Id": item.d2ItemId,
                    "commentName": item.itemSuggestion
                }))
            ]);

            //setPreviousData(newDataItems);
            //setCartData(prevCartData => [...prevCartData, ...newDataItems]);
            console.log("runningOrder", newDataItems);
            setRunningOrder(newDataItems);

            let total = 0;

            for (let i = 0; i < newDataItems.length; i++) {
                const quantity = newDataItems[i].quantity || 0;
                const price = parseFloat(quantity * newDataItems[i].salePrice);
                if (!isNaN(price)) {
                    total += price;
                }
            }
            for (let i = 0; i < cartData.length; i++) {
                const price = parseFloat(cartData[i].quantity * cartData[i].salePrice);
                if (!isNaN(price)) {
                    total += price;
                }
            }
            console.log("total", total);
            setTotalAmount(total);
            // alert(total);
            // setCartData([...cartData, ...newDataItems]);

            console.log("tableGroupId", data[0]?.tableGroupId, "tableId", data[0]?.tableId);

            setOrderData((prevValues) => ({
                ...prevValues,
                "ord1Id": data[0].ord1Id,
                "noOfChild": data[0].noChildPerson,
                "noOfAdult": data[0].noAdultPerson,
                "orderSuggestion": data[0].orderSuggestion,
                "orderSeriesId": data[0].orderSeriesId,
                "orderNo": data[0].orderNo,
                "autoOrderNo": 0,
                "serviceTypeId": data[0].serviceTypeId,
                "overallOrderStatus": "Confirm", //data[0].o3OrderStatus,  //confirm only for Confirm & KOT,
                "d1AccountId": data[0].d1AccId,
                "d2AccountId": data[0].accId,
                "billTypeId": data[0].billTypeId,
                "tableGroupId": data[0].tableGroupId,
                "tableId": data[0].tableId,
                "noOfPerson": data[0].noOfPerson,
                "totalAmt": data[0].o1TotalAmt,
                // "initialOrderTotalAmt": data[0].initialOrderTotalAmt,
                "grandTotalAmt": data[0].grandTotal,
                "o1CancelledAmt": data[0].o1CancelledAmt,
                "balanceAmt": data[0].balanceAmt,
                "couponCode": data[0].couponCode,
                "couponCodeAmount": data[0].couponCodeAmount,
                "orderDate": data[0].orderDate,
                "orderTime": data[0].orderTime,
                "orderConfirmedBy": data[0].orderConfirmedById,
                "orderConfirmedOn": data[0].orderConfirmedOn,
                "orderDoneFrom": data[0].orderDoneById,
                "deliveryCharges": data[0].deliveryCharges,
                "deliveryDate": data[0].deliveryDate,
                "deliveryTimeId": data[0].deliveryTimeId,
                "deliveryStatus": data[0].deliveryStatus,
                // "orderPrintStatus": data[0].orderPrintStatus,
                "paymentStatus": data[0].paymentStatus,
                "paymentAmount": data[0].paymentAmount,
                "returnAmount": data[0].returnAmount,
                // "discountAmount": data[0].discountAmount,
                "paymentDoneBy": data[0].paymentDoneById,
                "paymentDoneOn": data[0].paymentDoneOn,
                "paymentAttemptedCounter": data[0].paymentAttemptedCounter,
                "paymentMode": data[0].paymentMode,
                "paymentBank": data[0].paymentBank,
                // "paymentRemarks": data[0].paymentRemarks,
                "remarks": data[0].remarks,
                "deliveryBoyId": data[0].deliveryBoyId,
                // "deliveryBoyDispatchTime": data[0].deliveryBoyDispatchTime,
                // "deliveryBoyTripNo": data[0].deliveryBoyTripNo,
                // "deliveryBoyTripIncentive": data[0].deliveryBoyTripIncentive,
                // "deliveryReferenceNo": data[0].deliveryReferenceNo,
                // "deliveryPreferenceNo": data[0].deliveryPreferenceNo,
                // "deliveryTripStatus": data[0].deliveryTripStatus,
                "kotDispatchTargetTime": data[0].kotDispatchTargetTime,
                "latitude": data[0].latitude,
                "longitude": data[0].longitude,

                "name": data[0].name,
                "mobileNo": data[0].mobileNo,
                "companyGSTNo": data[0].companyGSTNo,
                "companyDetails": data[0].companyDetails,
                // "currencyId": data[0].currencyId,
            }));
            // ******************* SetOrder2 ********************
            setUpdatedId(true);
            setOrder2([
                ...order2,
                {
                    "godownId": data[0].iGodownId,
                    "d1ItemId": 0,
                    "d2ItemId": data[0].d2ItemId,
                    "qty": data[0].qty,
                    "rate": data[0].rate,
                    "totalAmt": data[0].o2TotalAmt,
                    "kotStatus": data[0].o2KotStatus,
                    "preferenceNo": data[0].preferenceNo,
                    "orderDate": data[0].orderDate,
                    "orderTime": data[0].orderTime,
                    "taxableAmount": data[0].o2TotalAmt,
                    "cgstPer": 0,
                    "cgstValue": 0,
                    "sgstPer": 0,
                    "sgstValue": 0,
                    "igstPer": 0,
                    "igstValue": 0,
                    "cessPer": 0,
                    "cessValue": 0,
                    "totalTaxableAmt": 0,
                    "isLocal": true,
                    "isInclusive": true,
                    "itemSuggestion": data[0].itemSuggestion,
                    "itemRunningOrderCounter": data[0].itemRunningOrderCounter,
                    "orderRunningQty": data[0].orderRunningQty,
                    "itemRunningQty": data[0].itemRunningQty,
                    "hasComplementryItem": true,
                    "cookStatus": "string",
                    "itemIncentivePoint": 0,
                    "isActive": data[0].isActive,
                    "color": "grey"
                    // d2Id: data[0].d2Id,
                    // orderDate: data[0].orderDate,
                    // orderTime: data[0].orderTime,
                    // orderDoneBy: data[0].orderDoneById,
                    // orderDoneOn: data[0].orderDoneOn,
                    // orderConfirmedBy: data[0].orderConfirmedById,
                    // orderConfirmedOn: data[0].orderConfirmedOn,
                    // // orderDoneFrom: "",
                    // // d1AccountId: 0,
                    // d2AccountId: data[0].accId,
                    // billTypeId: data[0].billTypeId,
                    // tableGroupId: data[0].tableGroupId,
                    // tableId: data[0].tableId,
                    // noOfPerson: data[0].noOfPersons,
                    // noOfAdult: data[0].noAdultPersons,
                    // noOfChild: data[0].noChildPersons,
                    // couponCode: "",
                    // couponCodeAmount: 0,
                    // deliveryCharges: data[0].deliveryCharges,
                    // deliveryDate: data[0].deliveredDate,
                    // deliveryTimeId: data[0].deliveryTimeId,
                    // deliveryStatus: data[0].deliveryStatus,
                    // deliveryBoyId: data[0].deliveryBoyId,
                    // deliveryBoyDispatchTime: "00:00:00",
                    // deliveryBoyTripNo: 0,
                    // deliveryBoyTripIncentive: 0,
                    // deliveryReferenceNo: data[0].accReferredById,
                    // deliveryPreferenceNo: data[0].preferenceNo,
                    // deliveryTripStatus: "",
                    // orderPrintStatus: data[0].o3OrderStatus,
                    // totalAmt: data[0].o1TotalAmt,
                    // initialOrderTotalAmt: 0,
                    // grandTotalAmt: data[0].grandTotal,
                    // paymentStatus: data[0].paymentStatus,
                    // paymentAmount: 0,
                    // returnAmount: 0,
                    // discountAmount: 0,
                    // paymentDoneBy: 0,
                    // paymentDoneOn: "",
                    // paymentAttemptedCounter: 0,
                    // paymentMode: data[0].paymentMode,
                    // paymentBank: "",
                    // paymentRemarks: "",
                    // remarks: "",
                    // orderSuggestion: "",
                    // kotDispatchTargetTime: "",  // deliveryTime
                    // latitude: "",
                    // longitude: "",
                    // currencyId: data[0].currencyId,
                    // hasRunningOrder: data[0].hasRunningOrder,
                    // runningOrderCounter: data[0].runningOrderCounter,
                    // runningOrderTotalAmt: data[0].runningOrderTotalAmt,
                    // ord2Id: data[0].ord2Id,
                }
            ]);

            setInputValues({
                name: data[0].accName,
                aMobileNo: data[0].accMobileNo,
                adob: data[0].accDOB,
                adom: data[0].accDOM,
                referralBy: data[0].accReferredById,
                aAddress: data[0].accAddress,
                //createdOn: ''
            })
            setInputItemValue(data[0].accName);
        }
        else {
            console.log("else");
            ClearDataFunction(tableId);
        }
        setLoading(false)
    }

    const [tableGroup, setTableGroup] = useState();

    const fetchTableGroupData = async () => {
        try {
            setLoading(true)
            const data = await TableGroupApi.getTableGroup();
            console.log(data);
            setTableGroup(data);
            setLoading(false)
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false)
        }
    };

    const [orderNoData, setOrderNoData] = useState();

    const fetchOrderData = async (serviceTypeId) => {
        try {
            setLoading(true);
            const filteredData = [
                {
                    fieldName: "serviceTypeId",
                    operatorName: "Equal",
                    compareValue: serviceTypeId
                },
                {
                    fieldName: "overallOrderStatus",
                    operatorName: "NotEqual",
                    compareValue: "Done"
                },
                {
                    fieldName: "overallOrderStatus",
                    operatorName: "NotEqual",
                    compareValue: "Returned"
                },
                {
                    fieldName: "overallOrderStatus",
                    operatorName: "NotEqual",
                    compareValue: "Cancel"
                },
                {
                    fieldName: "overallOrderStatus",
                    operatorName: "NotEqual",
                    compareValue: "Delivered"
                },
                {
                    fieldName: "overallOrderStatus",
                    operatorName: "NotEqual",
                    compareValue: "Return Request"
                },
            ]
            const data = await OrderApi.getOrderReportFilter(filteredData)
            setOrderNoData(data)
            setLoading(false)
        }
        catch (error) {
            console.log(error);
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchOrderData(754);
    }, [])

    const [serviceType, setServiceType] = useState();
    const [billseries, setBillSeries] = useState();
    const [paymentDetails, setPaymentDetails] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const data = await OrderApi.getLastOrderCounterByUser();
                if (data.length > 0) {
                    const orderNo = await OrderApi.getBillSeriesCounter(data[0].orderSeriesId);
                    setOrderData((prevValues) => ({
                        ...prevValues,
                        // "billTypeId": updatedData[0].id, //585
                        "orderSeriesId": data[0].orderSeriesId,
                        "orderNo": (Number(orderNo) + 1),
                        "autoOrderNo": (Number(orderNo) + 1),
                    }));
                    //alert(data[0].orderSeriesId)
                    const paymentMode = await BillPaymentMode.getvoucherPaymentModeId(data[0].orderSeriesId);
                    setPaymentDetails(paymentMode);
                    setLoading(false)
                }
            }
            catch (error) {
                console.log(error);
                setLoading(false)
            }
            setLoading(false)
        }
        fetchData();
    }, [])

    const billSeriesChange = async (val) => {
        setLoading(true)
        const orderNo = await OrderApi.getBillSeriesCounter(val);
        // alert(val)
        setOrderData((prevValues) => ({
            ...prevValues,
            // "billTypeId": updatedData[0].id, //585
            "orderSeriesId": val,
            "orderNo": (Number(orderNo) + 1),
            "autoOrderNo": (Number(orderNo) + 1),
        }));

        const paymentMode = await BillPaymentMode.getvoucherPaymentModeId(val);
        setPaymentDetails(paymentMode);
        console.log(paymentMode);
        setLoading(false)
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const data = await OrderApi.getServiceType();
                setServiceType(data);

                setActiveButton(4);
                setOrderData((prevValues) => ({
                    ...prevValues,
                    "serviceTypeId": data[3].id,
                }));
                setLoading(false)

                const updatedValues = {
                    ...getDeliveryAndTimeSlotId,
                    orderType: data[3].id,
                    deliveryPointName: 1,
                    distance: 0,
                    isCurrentDate: true,
                };
                setDeliveryAndTimeSlotId((prevState) => ({
                    ...prevState,
                    orderType: data[3].id,
                    deliveryPointName: 1,
                    distance: 0,
                    isCurrentDate: true,
                }));

                fetchTimeSlot(updatedValues);

            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false)
            }
        };
        const fetchData1 = async () => {
            setLoading(true)
            try {
                const data = await BillSeriesMaster.getBillSeriesMaster();
                setBillSeries(data);
                setLoading(false)
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false)
            }
        };
        fetchData();
        fetchData1();
    }, []);

    const [searchValue, setSearchValue] = useState('');

    const onHandleKeyPress = (event) => {
        const selectedItem = menuItems.find(item =>
            item.name.toLowerCase() === searchValue.toLowerCase()
        );
        if (event.key === 'Enter') {
            if (selectedItem) {
                const id = selectedItem.id;
                ItemButtonClick(id);
            }
        }
    }
    const onSearchChange = (event) => {
        const searchValue = event.target.value
        setSearchValue(searchValue);

        const filteredItems = menuItems.filter(item =>
            item.name.toLowerCase().includes(searchValue.toLowerCase())
        );
        setFilteredItems(filteredItems);
    }

    const [filteredItems, setFilteredItems] = useState([]);

    useEffect(() => {
        setLoading(true)
        const filteredItems = menuItems.filter(item =>
            item.name.toLowerCase().includes(searchValue.toLowerCase())
        );
        setFilteredItems(filteredItems);
        console.log(filteredItems);
        setLoading(false)
    }, [searchValue, menuItems]);

    const [barcode, setBarcode] = useState('');

    const handleCodeChange = (event) => {
        setBarcode(event.target.value);
    }

    const onHandleCodeKeyPress = (event) => {
        const selectedItem = menuItems.find(item =>
            item.barcode.toLowerCase() === barcode.toLowerCase()
        );
        if (event.key === 'Enter') {
            if (selectedItem) {
                const id = selectedItem.id;
                ItemButtonClick(id);
                console.log(barcode);
            }
        }
    }

    // ********************** Get users *******************
    const [users, setUsers] = useState();

    useEffect(() => {
        const filteredData = [
            {
                fieldName: "userRole",
                operatorName: "Equal",
                compareValue: 563             //Order taker Id 
            }
        ]

        const fetchData = async () => {
            setLoading(true)
            try {
                const data = await UserCredentialApi.getFilteredUserCredential(filteredData);
                console.log("users");
                console.log(data);
                setUsers(data);
                setLoading(false)
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false)
            }
        };
        fetchData();
    }, []);

    // ********************** Get Item Department *******************
    const [itemDepartment, setItemDepartment] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const data = await ItemDepartmentApi.getItemDepartment();
                console.log(data);
                setItemDepartment(data);
                setLoading(false)
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false)
            }
        };
        fetchData();
    }, []);

    const handleItemDepartmentChange = async (e) => {
        const filteredData = [
            {
                fieldName: "itemDepartment",
                operatorName: "Equal",
                compareValue: e.target.value
            },
            {
                fieldName: "availableForPOS",
                operatorName: "Equal",
                compareValue: true
            }
        ]
        const itemData = await ItemMasterApi.GetItemMasterWithFilter(filteredData)
        setMenuItems(itemData);
        // setItemGroupList()
    }
    const [imageSrc, setImageSrc] = useState('');

    const loadImage = (element) => {
        if (element && element.src !== imageSrc) {
            setImageSrc(element.src);
        }
    };

    const imageRef = useIntersectionObserver(loadImage, { threshold: 0.1 });

    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);
    const input4Ref = useRef(null);
    const input5Ref = useRef(null);
    const input6Ref = useRef(null);
    const input7Ref = useRef(null);
    const input8Ref = useRef(null);
    const input9Ref = useRef(null);

    const handleKeyPress = (event, nextInputRef) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            nextInputRef.current.focus();
        }
    };

    const [inputItemValue, setInputItemValue] = useState('');

    const [suggestedItems1, setSuggestedItems1] = useState();

    const [isItemOpen, setIsItemOpen] = useState(false);

    const [ItemSuggestions, setItemSuggestions] = useState();

    //const ItemSuggestions = [{ name: 'user1' }, { name: 'user2' }, { name: 'user3' }, { name: 'user4' }];

    const [itemAvailable, setItemAvailable] = useState(false);

    const handleItemValueChange = (event) => {
        const value = event.target.value;
        setInputItemValue(value);
        setIsItemOpen(true);

        const lowerCaseValue = value.toLowerCase();

        const filteredSuggestions = ItemSuggestions.filter(item =>
            item.parentGroupId == 0 && item.name.toLowerCase().includes(lowerCaseValue)
        );

        setSuggestedItems1(filteredSuggestions);

        setInputItemValue(value);
        console.log(filteredSuggestions);

        if (filteredSuggestions.length > 0) {
            setIsItemOpen(true);
        }
        else {
            setIsItemOpen(false);
            setItemAvailable(true);
            console.log("else");
        }
    };

    const suggestionClose = () => {
        setIsItemOpen(false);
    }

    const handleItemSuggestion = async (ItemSuggestions, id1, id2, event) => {
        setInputItemValue(ItemSuggestions);
        setSuggestedItems1([]);
        setIsItemOpen(false);
        setInputValues((prevValues) => ({
            ...prevValues,
            'name': ItemSuggestions,
        }));

        const data = await AccountApi.getAccountById(id1);
        // alert(data.item1[0].name);
        setInputValues({
            name: data.item1[0].name,
            aMobileNo: data.item1[0].mobileNo,
            adob: data.item1[0].dob,
            adom: data.item1[0].dom,
            aAddress: data.item1[0].address,
            referralBy: data.item1[0].referralById,
        })

        setOrderData((prevValues) => ({
            ...prevValues,
            "d1AccountId": id1,
            "d2AccountId": id2,
            "deliveryAddress": "",
            "name": data.item1[0].name,
            "mobileNo": data.item1[0].mobileNo,
            "companyGSTNo": data.item1[0].gStINNo,
            "companyDetails": data.item1[0].accountDetails,
        }));
        console.log("referral id", data.item1[0].referralById);
    };

    const handleClick = (e) => {
        e.stopPropagation();
    };

    const [inputValues, setInputValues] = useState({
        name: '',
        aMobileNo: '',
        adob: '',
        adom: '',
        referralBy: 0,
        aAddress: '',
        createdOn: ''
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        console.log(`Changing ${name} to ${value}`);

        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));

        if (name === 'name') {
            handleItemValueChange(event);
            setOrderData((prevValues) => ({
                ...prevValues,
                "name": value,
            }));
        }
        else if (name == "aMobileNo") {
            setOrderData((prevValues) => ({
                ...prevValues,
                "mobileNo": value,
            }));
        }
        // if (name === 'noOfAdult' || name === 'noOfChild') {
        //     const val = Number(orderData.noOfAdult) + Number(orderData.noOfChild);
        //     console.log(val +"=" +Number(orderData.noOfAdult), Number(orderData.noOfChild));
        //     setInputValues((prevValues) => ({
        //         ...prevValues,
        //         "noOfPerson": val,
        //     }));
        // }
        else if (name === 'couponCode') {
            setInputValues((prevValues) => ({
                ...prevValues,
                "couponCodeAmount": (orderData.totalAmt * 20 / 100),
            }));
        }
        console.log(inputValues);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        console.log(`Changing ${name} to ${value}`);

        setOrderData((prevValues) => {
            const updatedValues = {
                ...prevValues,
                [name]: value,
            };

            if (name === 'noOfAdult' || name === 'noOfChild') {
                const noOfAdult = name === 'noOfAdult' ? Number(value) : Number(prevValues.noOfAdult);
                const noOfChild = name === 'noOfChild' ? Number(value) : Number(prevValues.noOfChild);
                updatedValues.noOfPerson = noOfAdult + noOfChild;
                console.log(`${updatedValues.noOfPerson} = ${noOfAdult} + ${noOfChild}`);
            }
            else if (name === 'couponCode') {
                updatedValues.couponCodeAmount = (Number(updatedValues.totalAmt) * 20) / 100;
            }

            return updatedValues;
        });
    };
    //const [newOrder, setNewOrder] = useState();

    useEffect(() => {
        const filteredData = [
            {
                fieldName: "IsDefault",
                operatorName: "Equal",
                compareValue: true
            }
        ]
        const fetchData = async () => {
            setLoading(true)
            try {
                const updatedData = await BillTypeApi.GetFilteredBillType(filteredData)
                const billSeries = await BillSeriesMasterApi.getBillSeriesMasterFiltered(filteredData)
                const orderNo = await OrderApi.getBillSeriesCounter(billSeries[0].id);

                setOrderData((prevValues) => ({
                    ...prevValues,
                    "billTypeId": updatedData[0].id, //585
                    "orderSeriesId": billSeries[0].id,
                    "orderNo": (Number(orderNo) + 1),
                    "autoOrderNo": (Number(orderNo) + 1),
                }));
                setLoading(false)
            }
            catch (error) {
                console.log("error", error);
                setLoading(false)
            }
        };
        fetchData();
    }, [])

    const formatDate = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const day = currentDate.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    const formatedTime = () => {
        const currentTime = new Date();
        const hours = currentTime.getHours().toString().padStart(2, '0');
        const minutes = currentTime.getMinutes().toString().padStart(2, '0');
        const seconds = currentTime.getSeconds().toString().padStart(2, '0');

        return `${hours}:${minutes}:${seconds}`;
    }

    const [showPrintModal, setShowPrintModal] = useState(false);

    const closePrintModal = () => {
        setShowPrintModal(false)
    }
    // ************************************** Save Order (Confirm & Kot)***********************************
    const ConfirmAndKot = async () => {
        setLoading(true)
        if (cartData.length == 0) {
            alert("Please add atleast one item");
            setLoading(false)
            return 0;
        }
        // else if (orderData.tableId == 0) {
        //     alert("Please select Table No");
        //     return 0;
        // }
        else if (inputValues.name == "") {
            alert("Please enter customer name");
            setLoading(false)
            input1Ref.current.focus();
            return 0;
        }

        let paymentTotalAmt = 0;

        if (paymentAmtDetails && paymentAmtDetails.length > 0) {
            paymentTotalAmt = paymentAmtDetails.reduce((sum, data) => sum + Number(data.paymentAmount), 0);
        }

        const balanceAmount = orderData.grandTotalAmt - (Number(orderData.o1CancelledAmt) + Number(orderData.paymentAmount));

        let paymentStatus = "Pending";
        // alert(`t-${orderData.grandTotalAmt} & P-${paymentTotalAmt}`)
        if (paymentTotalAmt == balanceAmount) { //orderData.grandTotalAmt
            paymentStatus = "Done";
        }
        else if (paymentTotalAmt > balanceAmount) {
            alert("Payment cannot exceed the Actual Amount");
            setLoading(false);
            return false;
        }

        const BillData = await BillLayoutSetting.getBillLayoutSettingById(orderData.orderSeriesId);
        console.log("BillData", BillData)

        if (BillData && BillData != null && BillData != undefined && BillData.paymentType == 2) {
            if (paymentTotalAmt != balanceAmount) {
                alert(`Please first pay Balance Amount: ₹${balanceAmount}`);
                setLoading(false)
                return false;
            }
        }

        console.log(order2)
        console.log(orderData)

        const formattedDate1 = formatDate();
        const formattedTime = formatedTime();

        const currentDate = new Date();
        const formattedDate = currentDate.toISOString();

        // ******************************
        const filteredData = [
            {
                fieldName: "IsDefault",
                operatorName: "Equal",
                compareValue: true
            }
        ]
        const updatedData = await BillTypeApi.GetFilteredBillType(filteredData)
        // const billSeries = await BillSeriesMasterApi.getBillSeriesMasterFiltered(filteredData)
        const orderNo = await OrderApi.getBillSeriesCounter(orderData.orderSeriesId);
        console.log("billTypeId-", updatedData[0].id)

        // ******************************
        let ord1Id;
        if (itemAvailable !== false) {
            const UpdatedInputValues = {
                ...inputValues,
                "createdOn": formattedDate,
            };
            setInputValues((prevValues) => ({
                ...prevValues,
                "createdOn": formattedDate,
            }));

            console.log("Add Account");
            const data = await AccountApi.insertAccount(UpdatedInputValues);
            setItemAvailable(false);
            debugger;

            console.log(data)
            const dataString = data?.result?.result;
            console.log(dataString);

            let keyValuePairs = [];
            if (dataString) {
                keyValuePairs = dataString.split(',');
            }

            let d1id, d2id;

            keyValuePairs.forEach(pair => {
                try {
                    const [key, value] = pair.split(':');
                    const trimmedKey = key.trim();
                    const trimmedValue = value.trim();
                    if (trimmedKey === 'd1id') {
                        d1id = parseInt(trimmedValue);
                    } else if (trimmedKey === 'd2id') {
                        d2id = parseInt(trimmedValue);
                    }
                }
                catch (error) {
                    console.log(error)
                }
            });
            const accountData = await AccountApi.getAccountById(d1id);

            const updatedValues = {
                ...orderData,
                "d1AccountId": d1id || 0,
                "d2AccountId": accountData.item1[0]?.d2Id, //(accountData.item1).length > 0 ? accountData.item1[0]?.d2Id : 0,
                "name": accountData.item1[0]?.name,
                "mobileNo": accountData.item1[0]?.mobileNo,
                "companyGSTNo": accountData.item1[0]?.gStINNo,
                "companyDetails": accountData.item1[0]?.accountDetails,

                "orderDate": formattedDate1,
                "orderTime": formattedTime,
                "orderDoneOn": formattedDate,
                "orderConfirmedOn": formattedDate,
                "deliveryDate": formattedDate1,
                //"deliveryTime": formattedTime,
                "paymentDoneOn": formattedDate,
                "kotDispatchTargetTime": formattedTime,
                // "orderConfirmedBy": loginUserDetails.id,
            };

            const updatedOrderData = {
                ...updatedValues,
                "billTypeId": updatedData[0].id, //585
                // "orderSeriesId": billSeries[0].id,
                // "orderNo": (Number(orderNo) + 1),
                // "autoOrderNo": (Number(orderNo) + 1),
                "paymentStatus": paymentStatus,
                "paymentAmount": orderData.paymentAmount + paymentTotalAmt,
                // "orderConfirmedBy": loginUserDetails.id,
                // "serviceTypeId": orderData.serviceTypeId,
            };

            const updatedOrders = order2.map(item => ({
                ...item,
                "orderDate": formattedDate1,
                "orderTime": formattedTime
            }));

            // setOrder2((prevValues) => {
            //     const updatedItems = {};
            //     for (const key in prevValues) {
            //         if (prevValues.hasOwnProperty(key)) {
            //             updatedItems[key] = {
            //                 ...prevValues[key],
            //                 "orderDate": formattedDate1,
            //                 "orderTime": formattedTime
            //             };
            //         }
            //     }
            //     return updatedItems;
            // });
            console.log("updatedOrderData");
            console.log(updatedOrderData);

            const updatedOrder3 = {
                "orderStatus": "Received",
                "orderDateTime": formattedDate,
            };

            var success = await OrderApi.insertOrder(updatedOrderData, updatedOrders, updatedOrder3);
            const id = success.result.order1.id;
            const orderNo = success.result.order1.orderNo;
            ord1Id = id;
            if (success.isSuccess === true) {
                alert(id)
                alert(`Thanks for Your Order & Your Order no is ${orderNo}`);
            }
            // ******************** Insert Payment Mode **********************
            if (paymentAmtDetails && paymentAmtDetails.length > 0) {
                const updatedData = paymentAmtDetails.map((item, index) => ({
                    ...item,
                    ord1Id: ord1Id
                })).filter(item => item.paymentAmount != 0);

                setPaymentAmtDetails(updatedData);
                await Order.createPaymentModeWithOrder(updatedData)

            }
            setLoading(false)
            return ord1Id;
        }
        setInputValues({
            name: '',
            aMobileNo: '',
            adob: '',
            adom: '',
            referralBy: '',
            aAddress: ''
        })
        // ***************************
        const updatedValues = {
            ...orderData,
            "orderDate": formattedDate1,
            "orderTime": formattedTime,
            "orderDoneOn": formattedDate,
            "orderConfirmedOn": formattedDate,
            "deliveryDate": formattedDate1,
            //"deliveryTime": formattedTime,
            "paymentDoneOn": formattedDate,
            "kotDispatchTargetTime": formattedTime,
            "billTypeId": updatedData[0].id, //585
            // "orderSeriesId": billSeries[0].id,
            "orderNo": (Number(orderNo) + 1),
            "autoOrderNo": (Number(orderNo) + 1),
            "paymentStatus": paymentStatus,
            "paymentAmount": paymentTotalAmt,
            // "orderConfirmedBy": loginUserDetails.id,
        };

        let total = 0;

        for (let i = 0; i < cartData.length; i++) {
            const price = parseFloat(cartData[i].quantity * cartData[i].salePrice);
            if (!isNaN(price)) {
                total += price;
            }
        }

        let runningQty = 0;

        for (let i = 0; i < cartData.length; i++) {
            const qty = Number(cartData[i].quantity);
            if (!isNaN(qty)) {
                runningQty += qty;
            }
        }

        const updatedOrders = order2.map(item => ({
            ...item,
            "orderDate": formattedDate1,
            "orderTime": formattedTime
        }));

        // setOrderData((prevValues) => ({
        //     ...prevValues,
        //     "orderDate": formattedDate1,
        //     "orderTime": formattedTime,
        //     "orderDoneOn": formattedDate,
        //     "orderConfirmedOn": formattedDate,
        //     "deliveryDate": formattedDate1,
        //     //"deliveryTime": formattedTime,
        //     "paymentDoneOn": formattedDate,
        //     "kotDispatchTargetTime": formattedTime,
        //     "billTypeId": updatedData[0].id, //585
        //     // "orderSeriesId": billSeries[0].id,
        //     "orderNo": (Number(orderNo) + 1),
        //     "autoOrderNo": (Number(orderNo) + 1),
        //     // "orderConfirmedBy": loginUserDetails.id,
        // }));

        // setOrder2((prevValues) => {
        //     const updatedItems = {};
        //     for (const key in prevValues) {
        //         if (prevValues.hasOwnProperty(key)) {
        //             updatedItems[key] = {
        //                 ...prevValues[key],
        //                 "orderDate": formattedDate1,
        //                 "orderTime": formattedTime
        //             };
        //         }
        //     }
        //     return updatedItems;
        // });

        console.log(updatedOrders);
        console.log("Already Existing Account");
        console.log(updatedOrders);

        const updatedItems = updatedOrders.map(item => {
            if (item.ord2Id !== 0) {
                return null;
            } else {
                return item;
            }
        }).filter(Boolean);

        console.log("updatedValues")
        console.log(updatedValues)

        // *************** Update Account Partially **********************
        const filterData = [
            {
                "operationType": 0,
                "path": "Name",
                "op": "Add",
                "from": "string",
                "value": inputValues.name
            },
            {
                "operationType": 0,
                "path": "AMobileNo",
                "op": "Add",
                "from": "string",
                "value": inputValues.aMobileNo
            },
            {
                "operationType": 0,
                "path": "ADOM",
                "op": "Add",
                "from": "string",
                "value": inputValues.adom
            },
            {
                "operationType": 0,
                "path": "ADob",
                "op": "Add",
                "from": "string",
                "value": inputValues.adob
            },
            {
                "operationType": 0,
                "path": "ReferralBy",
                "op": "Add",
                "from": "string",
                "value": inputValues.referralBy
            },
        ]
        await Account.updateAccountPartially(filterData, orderData?.d1AccountId, orderData?.d2AccountId);

        // ********************* Running Order ****************************

        if (orderData.ord1Id !== 0 && orderData.ord1Id !== undefined) {

            const runningData = runningOrder && runningOrder.slice().reverse();
            console.log("runningData", runningData)
            console.log("update", orderData.ord1Id);

            const updatedValuesForUpdate = {
                ...orderData,
                "orderDate": formattedDate1,
                "orderTime": formattedTime,
                "orderDoneOn": formattedDate,
                "orderConfirmedOn": formattedDate,
                "deliveryDate": formattedDate1,
                //"deliveryTime": formattedTime,
                "paymentDoneOn": formattedDate,
                "kotDispatchTargetTime": formattedTime,
                // "orderConfirmedBy": loginUserDetails.id,
                "hasRunningOrder": true,
                "runningOrderTotalAmt": (Number(runningData[0]?.runningOrderTotalAmt ?? 0) + total),
                "runningOrderCounter": (Number(runningData[0]?.runningOrderCounter ?? 0) + 1),
            };

            const updatedOrder1 = updatedItems.map(item => ({
                ...item,
                "ord1Id": orderData.ord1Id,
                "itemRunningOrderCounter": (Number(runningData[0]?.itemRunningOrderCounter ?? 0) + 1),
                "orderRunningQty": runningQty,
                "itemRunningQty": item.qty,
            }));

            const updatedOrder3 = {
                "orderStatus": "Received",
                "orderDateTime": formattedDate,
            };

            const success = await OrderApi.updateOrder(updatedValuesForUpdate, updatedOrder1, updatedOrder3);

            const filterData = [{
                operationType: 0,
                path: "runningOrderCounter",
                op: "Add",
                from: "string",
                value: (Number(runningData[0]?.runningOrderCounter ?? 0) + 1),
            }]

            await OrderApi.updateOrderPartially(filterData, orderData.ord1Id)

            if (success === true) {
                setRunningOrder([])
                alert("Your Order has been Updated");
            }
        }
        else {
            console.log("insert");
            console.log(updatedOrders);
            console.log(updatedValues);

            const updatedOrder3 = {
                "orderStatus": "Received",
                "orderDateTime": formattedDate,
            };

            const success = await OrderApi.insertOrder(updatedValues, updatedOrders, updatedOrder3);
            const id = success.result.order1.id;
            const orderNo = success.result.order1.orderNo;
            ord1Id = id;
            const filteredData = [{
                operationType: 0,
                path: "tableStatus",
                op: "Add",
                from: "string",
                value: "Busy"
            }]
            if (orderData.serviceTypeId == 751) {
                await Table.updateTablePartially(filteredData, updatedValues.tableId);
            }
            if (success.isSuccess === true) {
                alert(`Thanks for Your Order & Your Order no is ${orderNo}`);
            }
            // ******************** Insert Payment Mode **********************
            if (paymentAmtDetails && paymentAmtDetails.length > 0) {
                const updatedData = paymentAmtDetails.map((item, index) => ({
                    ...item,
                    ord1Id: ord1Id
                })).filter(item => item.paymentAmount != 0);

                setPaymentAmtDetails(updatedData);
                await Order.createPaymentModeWithOrder(updatedData)
            }
        }
        ClearDataFunction()
        setLoading(false)
        return ord1Id;
    }

    const [orderListData, setOrderListData] = useState([]);

    const SaveAndPrint = async () => {
        try {
            setLoading(true);
            const ord1Id = await ConfirmAndKot();
            // alert(ord1Id);
            const filterData = {
                fieldName: "ord1Id",
                operatorName: "equal",
                compareValue: ord1Id,
            };
            if (ord1Id != null && ord1Id != undefined && ord1Id != 0 && ord1Id != "") {
                const data = await OrderApi.getOrderListByOrd1Id(ord1Id);
                // alert(ord1Id)
                console.log("SaveAndKot", data.item1)
                setOrderListData(data.item1);
                setShowPrintModal(true);
            }
            setLoading(false);
        }
        catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    const SavePaymentBtn = async () => {
        setLoading(true);
        if (paymentAmtDetails.length == 0) {
            alert("Please enter Payment Amount")
            setLoading(false);
            return 0;
        }
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString();

        const storedArrayString = localStorage.getItem('loginAccountUserData');
        const loginUser = JSON.parse(storedArrayString);

        let paymentTotalAmt = 0;

        if (paymentAmtDetails && paymentAmtDetails.length > 0) {
            paymentTotalAmt = paymentAmtDetails.reduce((sum, data) => sum + Number(data.paymentAmount), 0);
        }


        const balanceAmount = orderData.grandTotalAmt - (Number(orderData.o1CancelledAmt) + Number(orderData.paymentAmount));

        let paymentStatus = "Pending";
        if (paymentTotalAmt == balanceAmount) { //orderData.grandTotalAmt
            paymentStatus = "Done";

            setOrderData((prevValues) => ({
                ...prevValues,
                paymentStatus: "Done",
            }));
        }
        else if (paymentTotalAmt > balanceAmount) {
            alert(`Payment cannot exceed the Balance Amount (₹${balanceAmount})`);
            setLoading(false);
            return false;
        }

        if (paymentAmtDetails && paymentAmtDetails.length > 0) {
            const updatedData = paymentAmtDetails.map((item, index) => ({
                ...item,
                ord1Id: orderData.ord1Id
            })).filter(item => item.paymentAmount != 0);

            setPaymentAmtDetails(updatedData);
            await Order.createPaymentModeWithOrder(updatedData)
        }

        const updatedValues = [
            {
                operationType: 0,
                path: "paymentStatus",
                op: "Add",
                from: "string",
                value: paymentStatus
            },
            {
                operationType: 0,
                path: "paymentAmount",
                op: "Add",
                from: "string",
                value: orderData.paymentAmount + paymentTotalAmt
            },
            {
                operationType: 0,
                path: "PaymentDoneOn",
                op: "Add",
                from: "string",
                value: formattedDate
            },
            {
                operationType: 0,
                path: "PaymentDoneBy",
                op: "Add",
                from: "string",
                value: loginUser.userId
            },
        ]
        await OrderApi.updateOrderPartially(updatedValues, orderData.ord1Id);

        setOrderData((prevValues) => ({
            ...prevValues,
            "paymentStatus": paymentStatus,
            "paymentAmount": paymentTotalAmt,
        }));
        setLoading(false);
        alert("Updated Payment Status");
    }

    const [showBillPrint, setShowBillPrint] = useState(false);

    const closeBillPrint = () => {
        setShowBillPrint(false)
    }

    const BillPrintBtn = async () => {
        try {
            setLoading(true)
            if (orderData.ord1Id != null && orderData.ord1Id != undefined && orderData.ord1Id != 0 && orderData.ord1Id != "") {
                try {
                    const data = await OrderApi.getOrderListByOrd1Id(orderData.ord1Id);
                    // alert(orderData.ord1Id)
                    console.log("Print", data.item1)
                    setOrderListData(data.item1);
                    setShowBillPrint(true);
                }
                catch (error) {
                    console.log(error)
                }
                try {
                    const filteredData = [{
                        operationType: 0,
                        path: "orderPrintStatus",
                        op: "Add",
                        from: "string",
                        value: true
                    }]
                    await OrderApi.updateOrderPartially(filteredData, orderData.ord1Id)
                }
                catch (error) {
                    console.log(error);
                }
            }
            setLoading(false);
        }
        catch (error) {
            console.log(error);
            setLoading(false);
        }
    }
    // ****************************** Order Done ****************************
    const OrderDoneBtn = async () => {
        setLoading(true);
        const balanceAmount = orderData.grandTotalAmt - (Number(orderData.o1CancelledAmt) + Number(orderData.paymentAmount));

        if (balanceAmount != 0) {
            alert(`Please Pay Balance Amount ₹${balanceAmount}`);
            setLoading(false)
            return false;
        }
        else {
            const currentDate = new Date();
            const formattedDate = currentDate.toISOString();

            const storedArrayString = localStorage.getItem('loginAccountUserData');
            const loginUser = JSON.parse(storedArrayString);

            const updatedValues = [
                {
                    operationType: 0,
                    path: "overallOrderStatus",
                    op: "Add",
                    from: "string",
                    value: "Done"
                },
                {
                    operationType: 0,
                    path: "orderDoneBy",
                    op: "Add",
                    from: "string",
                    value: loginUser.userId
                },
                {
                    operationType: 0,
                    path: "orderDoneOn",
                    op: "Add",
                    from: "string",
                    value: formattedDate
                },
                {
                    operationType: 0,
                    path: "orderPrintStatus",
                    op: "Add",
                    from: "string",
                    value: false
                }
            ]
            await OrderApi.updateOrderPartially(updatedValues, orderData.ord1Id);

            // ************************ Make Table Free *******************
            if (orderData.serviceTypeId == 751) {
                const filteredData = [{
                    operationType: 0,
                    path: "tableStatus",
                    op: "Add",
                    from: "string",
                    value: "Free"
                }]
                await Table.updateTablePartially(filteredData, orderData.tableId);
            }
            setLoading(false);
            alert("Updated Status to Done")
            ClearDataFunction(0)
        }
    }

    const ClearDataFunction = (tableId) => {
        setOrderData({
            ord1Id: 0,
            orderSeriesId: 0,
            orderNo: "0",
            autoOrderNo: 0,
            serviceTypeId: 0,
            overallOrderStatus: "Confirm",  //confirm only for Confirm & KOT
            orderDate: "",
            orderTime: "",
            orderDoneBy: 0,
            orderDoneOn: "",
            orderConfirmedBy: 0,
            orderConfirmedOn: "",
            orderDoneFrom: "POS",
            d1AccountId: 0,
            d2AccountId: 0,
            billTypeId: 0,
            tableGroupId: 0,
            tableId: tableId,
            noOfPerson: 0,
            noOfAdult: 0,
            noOfChild: 0,
            couponCode: "",
            couponCodeAmount: 0,
            deliveryCharges: 0,
            deliveryDate: "",
            deliveryTimeId: 0,
            deliveryStatus: "",
            deliveryBoyId: 0,
            deliveryBoyDispatchTime: "00:00:00",
            deliveryBoyTripNo: 0,
            deliveryBoyTripIncentive: 0,
            deliveryReferenceNo: 0,
            deliveryPreferenceNo: 0,
            deliveryTripStatus: "",
            orderPrintStatus: 0,
            totalAmt: 0,
            initialOrderTotalAmt: 0,
            grandTotalAmt: 0,
            paymentStatus: "Pending",
            paymentAmount: 0,
            returnAmount: 0,
            discountAmount: 0,
            paymentDoneBy: 0,
            paymentDoneOn: "",
            paymentAttemptedCounter: 0,
            paymentMode: 0,
            paymentBank: "",
            paymentRemarks: "",
            remarks: "",
            orderSuggestion: "",
            kotDispatchTargetTime: "",  // deliveryTime
            latitude: "",
            longitude: "",
            currencyId: 0,
            hasRunningOrder: false,
            runningOrderCounter: 0,
            runningOrderTotalAmt: 0,
        });
        setComments([]);
        setOrder2([]);
        setCartData([]);
        setInputItemValue('');
        setInputValues({
            name: '',
            aMobileNo: '',
            adob: '',
            adom: '',
            referralBy: '',
            aAddress: ''
        });
        setTotalAmount(0);
    }
    const [currentDate, setCurrentDate] = useState();
    const [currentTime, setCurrentTime] = useState();

    // **************** Current Date & Time *************************
    useEffect(() => {
        setLoading(true)
        const currentDate = new Date();
        const day = currentDate.getDate().toString().padStart(2, '0');
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const year = currentDate.getFullYear().toString();
        const formattedDate = `${day}/${month}/${year}`;
        setCurrentDate(formattedDate);

        const currentTime = new Date();
        let hours = currentTime.getHours();
        const minutes = currentTime.getMinutes().toString().padStart(2, '0');
        let period = 'AM';
        if (hours > 12) {
            hours -= 12;
            period = 'PM';
        } else if (hours === 12) {
            period = 'PM';
        } else if (hours === 0) {
            hours = 12;
        }
        const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes} ${period}`;
        setCurrentTime(formattedTime);

        setOrderData((prevValues) => ({
            ...prevValues,
            "orderConfirmedOn": currentDate.toISOString(),
        }));

        setLoading(false)
    }, [])
    // ********************** Order & Payment Done ***********************
    const OrderAndPaymentDone = async () => {
        setLoading(true)
        const date = new Date();
        const currentDate = date.toISOString();

        const updatedValues = [
            {
                operationType: 0,
                path: "overallOrderStatus",
                op: "Add",
                from: "string",
                value: "Done"
            },
            {
                operationType: 0,
                path: "paymentStatus",
                op: "Add",
                from: "string",
                value: "Done"
            },
            {
                operationType: 0,
                path: "paymentDoneBy",
                op: "Add",
                from: "string",
                value: loginUserDetails.id
            },
            {
                operationType: 0,
                path: "paymentDoneOn",
                op: "Add",
                from: "string",
                value: currentDate,
            }
        ]
        const success = await OrderApi.updateOrderPartially(updatedValues, orderData.orderNo);

        const filteredData = [{
            operationType: 0,
            path: "tableStatus",
            op: "Add",
            from: "string",
            value: "Free"
        }]
        await Table.updateTablePartially(filteredData, orderData.tableId);

        if (success === true) {
            alert("Success");
        }
        setLoading(false)
    }
    // **************** Get Accounts **************
    const [account, setAccount] = useState([]);

    const fetchData = async () => {
        try {
            setLoading(true)
            const data = await AccountApi.getAccount();
            //console.log(data);
            const accounts = data
                .filter(item => item.parentGroupId == 0)
                .map(item => ({
                    d2Id: item.d2Id,
                    name: item.name,
                    aMobileNo: item.mobileNo,
                    adob: item.dob,
                    adom: item.dom,
                    aAddress: item.address,
                    referralBy: item.referralById || 0,
                }));
            console.log(accounts);
            setAccount(accounts);
            setItemSuggestions(data);
            setLoading(false)
            //console.log("referralBy", data.item[0].referralById)
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false)
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    // ************************* Login User Details ***********************
    const [loginUserDetails, setLoginUserDetails] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            const storedArrayString = localStorage.getItem('loginUser');
            const loginUser = JSON.parse(storedArrayString);
            setLoginUserDetails(loginUser);
            console.log("userName");
            console.log(loginUser.userName);

            setOrderData((prevValues) => ({
                ...prevValues,
                "orderConfirmedBy": loginUser.userId,
            }));
            setLoading(false)
        };
        fetchData();
    }, []);

    const handleOrderTakerSelect = (e) => {
        const val = e.target.value;
        if (val == "default") {
            setOrderData((prevValues) => ({
                ...prevValues,
                "orderConfirmedBy": loginUserDetails.userId,
            }));
        }
        else {
            setOrderData((prevValues) => ({
                ...prevValues,
                "orderConfirmedBy": val,
            }));
        }
    }

    const itemGroupClick = (id) => {
        // alert(id)
        const filteredItems = menuItems.filter(item => item.parentGrpId2 === id)
        setFilteredItems(filteredItems);
        console.log(filteredItems);
    }

    const handleFocus = (event) => {
        event.target.select();
    };

    const [highlightedIndex, setHighlightedIndex] = useState(-1);

    const handleKeyPressForSuggestion = (event) => {
        if (isItemOpen) {
            if (event.key === 'ArrowDown') {
                setHighlightedIndex((prevIndex) =>
                    prevIndex === suggestedItems1.length - 1 ? 0 : prevIndex + 1
                );
            } else if (event.key === 'ArrowUp') {
                setHighlightedIndex((prevIndex) =>
                    prevIndex <= 0 ? suggestedItems1.length - 1 : prevIndex - 1
                );
            } else if (event.key === 'Enter' && highlightedIndex >= 0) {
                const selectedSuggestion = suggestedItems1[highlightedIndex];
                handleItemSuggestion(
                    selectedSuggestion.name,
                    selectedSuggestion.d1Id,
                    selectedSuggestion.d2Id,
                    event
                );
            }
        }
    };

    const [paymentAmtDetails, setPaymentAmtDetails] = useState([]);

    const handlePaymentChange = (e, accountId) => {
        const { value } = e.target;
        const parsedValue = parseFloat(value);

        setPaymentAmtDetails((prevData) => {
            let updatedData;
            const existingIndex = prevData.findIndex(item => item.paymentModeId === accountId);

            if (parsedValue === 0) {
                updatedData = prevData.filter(item => item.paymentModeId !== accountId);
            } else if (existingIndex !== -1) {
                updatedData = [...prevData];
                updatedData[existingIndex] = {
                    ...updatedData[existingIndex],
                    paymentAmount: value,
                };
            } else {
                updatedData = [
                    ...prevData,
                    {
                        ord1Id: 0,
                        paymentModeId: accountId,
                        paymentAmount: value,
                        remarks: '',
                    },
                ];
            }

            const totalPaymentAmount = updatedData.reduce((sum, data) => sum + parseFloat(data.paymentAmount), 0);

            const paymentStatus = totalPaymentAmount === totalAmount ? "Done" : "Pending";
            // setOrderData((prevValues) => ({
            //     ...prevValues,
            //     paymentStatus,
            // }));

            return updatedData;
        });
    };

    const [showInTable, setShowInTable] = useState(false);

    useEffect(() => {
        if (orderData.serviceTypeId == 751) {
            setShowInTable(true);
        }
        else {
            setShowInTable(false);
        }
    }, [orderData])

    const [showInOtherServiceType, setShowInOtherServiceType] = useState(false);

    useEffect(() => {
        if (orderData.serviceTypeId != 751) {
            setShowInOtherServiceType(true);
        }
        else {
            setShowInOtherServiceType(false);
        }
    }, [orderData])



    const handleTableSelect = (id, tableStatus) => {
        //alert(tableStatus)
        if (tableStatus == "Busy") {
            onTableSelect(id);
        }
        else {
            const updatedItems = tableData.map(item => {
                if (item.id === id) {
                    return { ...item, tableStatus: 'Selected' };
                } else if (item.tableStatus != "Busy") {
                    return { ...item, tableStatus: 'NotSelected' };
                }
                return item;
            });
            setTableData(updatedItems);
            setRunningOrder([]);

            setInputValues({
                name: "",
                aMobileNo: "",
                adob: "",
                adom: "",
                aAddress: "",
                referralBy: "",
            })

            setOrderData((prevValues) => ({
                ...prevValues,
                "ord1Id": 0,
                "tableId": id,
                "hasRunningOrder": false,
                "runningOrderCounter": 0,
                "runningOrderTotalAmt": 0,
                "d1AccountId": 0,
                "d2AccountId": 0,
                "orderDoneBy": 0,
                "paymentStatus": "Pending",
                "paymentAmount": 0,
                "paymentDoneBy": 0,
                "paymentMode": 0,
                "hasRunningOrder": false,
                "runningOrderCounter": 0,
                "runningOrderTotalAmt": 0,
            }));
            setInputItemValue("")

            const updatedValues = {
                ...getDeliveryAndTimeSlotId,
                orderType: 751,
                deliveryPointName: 1,
                distance: 0,
                isCurrentDate: true,
            };
            setDeliveryAndTimeSlotId((prevState) => ({
                ...prevState,
                orderType: 751,
                deliveryPointName: 1,
                distance: 0,
                isCurrentDate: true,
            }));
            fetchTimeSlot(updatedValues);

        }
    }

    // ****************** Price Calculate ******************
    const [totalAmount, setTotalAmount] = useState(0);

    const calculateTotalPrice = (cartData) => {
        // if (cartData && cartData.length > 0) {
        setLoading(true);
        let total = 0;

        for (let i = 0; i < runningOrder.length; i++) {
            const price = parseFloat(runningOrder[i].quantity * runningOrder[i].salePrice);
            if (!isNaN(price)) {
                total += price;
            }
        }

        for (let i = 0; i < cartData.length; i++) {
            const price = parseFloat(cartData[i].quantity * cartData[i].salePrice);
            if (!isNaN(price)) {
                total += price;
            }
        }

        setTotalAmount(total);

        setOrderData((prevValues) => ({
            ...prevValues,
            totalAmt: total,
            initialOrderTotalAmt: total,
            grandTotalAmt: total,
        }));
        setLoading(false);
        console.log("total", total);
        // }
    }

    useEffect(() => {
        calculateTotalPrice(cartData);
    }, [cartData]);

    function convertTo12HourFormat(twentyFourHourTime) {
        const [hours, minutes] = twentyFourHourTime.split(':').map(Number);

        let period = 'AM';
        let twelveHourHours = hours;

        if (hours >= 12) {
            period = 'PM';
            if (hours > 12) {
                twelveHourHours -= 12;
            }
        }

        if (twelveHourHours === 0) {
            twelveHourHours = 12;
        }
        if (minutes != undefined && minutes != null) {
            const formattedMinutes = minutes === 0 ? '00' : minutes.toString().padStart(2, '0');
            return `${twelveHourHours.toString().padStart(2, '0')}:${formattedMinutes} ${period}`;
        }
        else {
            return `${twelveHourHours.toString().padStart(2, '0')}: 00 ${period}`;
        }
    }

    // ************************* Delivery Time Slot ********************
    const [getDeliveryAndTimeSlotId, setDeliveryAndTimeSlotId] = useState({
        deliveryPointName: 1,
        orderType: orderData.serviceTypeId,
        distance: 0,
        isCurrentDate: false,
    });

    const [AllocatedTimeSlots, setAllocatedTimeSlots] = useState([]);

    const fetchTimeSlot = async (updatedValues) => {
        try {
            const data = await DeliveryAndTimeSlot.getAllottedDeliveryTimeSlots(updatedValues);
            setAllocatedTimeSlots(data);
            console.log("timeSlot", data);
            return data;
        }
        catch (error) {
            console.log(error);
            return false;
        }
    }

    const [paymentDetailModalPopup, setPaymentDetailModal] = useState(false)

    const openPaymentDetailsModal = async () => {
        const data = await handleGetPayment(orderData.ord1Id)
        if (data)
            setPaymentDetailModal(true)
    }

    const closePaymentDetailsModal = () => {
        setPaymentDetailModal(false)
    }
    const [PaymentModalData, setPaymentModalData] = useState([]);

    const handleGetPayment = async (orderId) => {
        const PaymentData = await Order.GetPaymentModeWithOrders(orderId);
        console.log(PaymentData);
        setPaymentModalData(PaymentData);
        if (PaymentData.length > 0) {
            return true
        }
        else {
            alert("No Payment Found")
            return false
        }
    };

    const handlePayment = async (rupeeAmt, orderNo, ord1Id) => {
        navigate("/Request", {
            state: {
                orderNo,
                ord1Id
            }
        });
    }

    const saveNoOfPerson = async () => {
        setLoading(true)
        const updatedValues = [
            {
                operationType: 0,
                path: "noOfAdult",
                op: "Add",
                from: "string",
                value: orderData.noOfAdult
            },
            {
                operationType: 0,
                path: "noOfChild",
                op: "Add",
                from: "string",
                value: orderData.noOfChild
            },
            {
                operationType: 0,
                path: "noOfPerson",
                op: "Add",
                from: "string",
                value: (orderData.noOfAdult + orderData.noOfChild)
            },
        ]
        await OrderApi.updateOrderPartially(updatedValues, orderData.ord1Id);
        setLoading(false)
        alert("Updated")
    }

    return (
        <div>
            <div className="" style={{ height: "99vh" }} onClick={suggestionClose}>
                <div style={{ height: "100vh", display: "flex", justifyContent: "start" }}>
                    {/************************** Left Sidebar******************/}
                    <div className="" style={{ width: "18%", borderRight: "2px solid darkgrey", }}>
                        <select className="form-control" onChange={handleItemDepartmentChange}
                            style={{ backgroundColor: "black", border: "1px solid white", borderRadius: "0px", fontWeight: "600", color: "white", cursor: "pointer", padding: "7px" }}>
                            <option style={{ backgroundColor: "white", padding: "15px", color: "black" }} value="default">Select Favourite Items</option>
                            {(() => {
                                if (itemDepartment !== null && Array.isArray(itemDepartment)) {
                                    return itemDepartment.map((data) => (
                                        <option key={data.id} value={data.id}>
                                            {data.name}
                                        </option>
                                    ))
                                }
                            })()}
                            {/* <option style={{ backgroundColor: "white", padding: "15px", color: "black" }}>Blast of Love</option>
                            <option style={{ backgroundColor: "white", padding: "15px", color: "black" }}>Build your own taste</option>
                            <option style={{ backgroundColor: "white", padding: "15px", color: "black" }}>Family Combos</option>
                            <option style={{ backgroundColor: "white", padding: "15px", color: "black" }}>Golden Delights</option>
                            <option style={{ backgroundColor: "white", padding: "15px", color: "black" }}>Paneer Lover</option>
                            <option style={{ backgroundColor: "white", padding: "15px", color: "black" }}>Veggie Lover</option>
                            <option style={{ backgroundColor: "white", padding: "15px", color: "black" }}>Wednesday & Saturday Offers</option> */}
                        </select>
                        <div className="leftSideMenu">
                            <div style={{ backgroundColor: "lightgray" }}>
                                {(() => {
                                    if (itemGroupList !== null && Array.isArray(itemGroupList)) {
                                        return itemGroupList
                                            .filter(item => item.primaryGroup == false)
                                            .map((list) => (
                                                <div className="menuList" style={{}} onClick={() => itemGroupClick(list.d2_Id)}>
                                                    <div style={{ padding: "10px 14px", }}>
                                                        <label key={list.id} style={{ color: "black", fontWeight: "600", fontSize: "14px" }}>
                                                            {list.name}
                                                        </label>
                                                    </div>
                                                    <hr style={{ margin: "5px" }} />
                                                </div>
                                            ))
                                    }
                                })()}
                            </div>
                        </div>
                    </div>
                    {/************************** Menu Item ******************/}
                    <div className="menuContainer" style={{ height: "100vh" }}>
                        <div className=" " style={{ backgroundColor: "#f0f0f0", textAlign: "center" }}>
                            <div style={{ display: "flex", justifyContent: "space-evenly", backgroundColor: "black", padding: "5px", width: "100%", height: "40px" }}>

                                <input type="search" className="form-control" placeholder="Search" style={{ width: "100%" }} value={searchValue}
                                    onKeyDown={(event) => onHandleKeyPress(event)} onChange={onSearchChange} />

                                <input type="text" className="form-control" placeholder="Code" style={{ marginLeft: "5px", width: "100%" }}
                                    onKeyDown={(event) => onHandleCodeKeyPress(event)} onChange={handleCodeChange} />

                                {showInTable && (
                                    <select className="ques form-control " onChange={onTableGroupSelection}
                                        style={{ selectStyle, border: "2px solid transparent", marginLeft: "4px", backgroundColor: "white", width: "100%", padding: "2px 8px", color: "#62686d" }}>
                                        <option disabled selected value="0">Select Floor</option>
                                        {(() => {
                                            if (tableGroup !== null && Array.isArray(tableGroup)) {
                                                return tableGroup.map((data) => (
                                                    <option key={data.id} value={data.id}>
                                                        {data.name}
                                                    </option>
                                                ))
                                            }
                                        })()}
                                    </select>
                                )}
                                {
                                    // ((orderData.serviceTypeId == 752 || orderData.serviceTypeId == 753 || orderData.serviceTypeId == 754) && isAllowModificationOfVoucher != false) ||
                                    //     (orderData.serviceTypeId != 752 && orderData.serviceTypeId != 753 || orderData.serviceTypeId != 754) ? (
                                    showInOtherServiceType && isAllowModificationOfVoucher != false && (
                                        <select className="ques form-control" onChange={onOrderNoSelection}
                                            style={{
                                                selectStyle, border: "2px solid transparent", marginLeft: "4px",
                                                backgroundColor: "white", width: "100%", padding: "2px 8px", color: "#62686d"
                                            }}>
                                            <option value="0">Select Order No</option>
                                            {orderNoData !== null && Array.isArray(orderNoData) &&
                                                orderNoData.map((data) => (
                                                    <option key={data.ord1Id} value={data.ord1Id}>
                                                        {data.name} -- Ord No.{data.orderNo}
                                                    </option>
                                                ))}
                                        </select>
                                    )
                                    // ) : null
                                }

                            </div>
                            <div className="space-between menu-pos">
                                <div className="gridView-pos mt-3" id="menu-pos" style={{ width: "100%" }}>
                                    {filteredItems.filter((parentAttribute) =>
                                        parentAttribute.attributeParentId === 0)
                                        .map((item) => (
                                            // console.log(item.attributeParentId),
                                            <div style={{ display: "flex", justifyContent: "space-evenly" }} onClick={() => ItemButtonClick(item.d2Id)} key={item.id}>
                                                <div className="menuItem" style={{ maxHeight: '110px' }}>
                                                    {/* <div>{item.images}</div> */}
                                                    {/* <img src={`${imageUrl}${item.images}`} */}
                                                    {/* <img src={`${imageBaseUrl}${item.images}`} ref={imageRef} */}
                                                    <img //src=""
                                                        src={`${imageBaseUrl}${item.images}`}
                                                        ref={imageRef}
                                                        alt="" style={{ height: "65px", width: "65px", borderRadius: "15px", border: "1px solid lightGrey" }} />
                                                    <div className="mt-2" style={{ marginLeft: "5px" }}>
                                                        <label className="menuLabel">{item.name}</label>
                                                        <h6 style={{ fontWeight: "600", color: "#606060", fontSize: "13px" }}>Price: &#x20B9;{item.salePrice}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                                {/* ***************** Table List ********************** */}
                                {showInTable && (
                                    <div id="TableNo" style={{ display: "none" }}>
                                        <div className="flex-start mt-3" style={{ cursor: "pointer", flexDirection: "column" }}>
                                            {(() => {
                                                if (tableData !== null && Array.isArray(tableData)) {
                                                    return tableData.map((data, i) => (
                                                        <div className="mt-2" key={i}>
                                                            <div
                                                                className="tableNumber-pos"
                                                                style={{
                                                                    backgroundColor: data.tableStatus === "Busy"
                                                                        ? (data.orderPrintStatus === true) ? "#34c0ee" : "yellowgreen" : data.tableStatus === "Selected"
                                                                            ? "#ffe4c4" : "white"
                                                                }}
                                                                onClick={() => handleTableSelect(data.id, data.tableStatus)}
                                                            >
                                                                <label>{data.tableNumber}</label>
                                                            </div>
                                                        </div>
                                                    ));
                                                }
                                            })()}
                                        </div>
                                    </div>
                                )}
                            </div>
                            {/* *********** Item Footer ****** */}
                            <div className="mt-1" style={{ padding: "5px 2px", border: "1px solid lightgrey" }}>
                                <div className=" space-evenly mt-2" style={{}}>
                                    <div style={{ display: "flex" }}>
                                        <div className="pos-circle mt-1" style={{ backgroundColor: "white", border: "1px solid #b3adad" }}></div>
                                        <label className="ques">&nbsp; Free &nbsp;</label>
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <div className="pos-circle mt-1" style={{ backgroundColor: "bisque", border: "1px solid #b3adad" }}></div>
                                        <label className="ques">&nbsp; Selected &nbsp;</label>
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <div className="pos-circle mt-1" style={{ backgroundColor: "yellowGreen" }}></div>
                                        <label className="ques">&nbsp; Busy &nbsp;</label>
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <div className="pos-circle mt-1" style={{ backgroundColor: "#34c0ee" }}></div>
                                        <label className="ques">&nbsp; Printed &nbsp;</label>
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <div className="pos-circle mt-1" style={{ backgroundColor: "goldenrod" }}></div>
                                        <label className="ques">&nbsp; Running KOT &nbsp;</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/******************************************************************* Cart ******************************************************************/}
                    <div className="" style={{ width: "42%", borderLeft: "2px solid darkgrey", paddingLeft: "0px" }}>
                        <div style={{ display: "flex", justifyContent: "space-evenly", width: "100%", backgroundColor: "black", padding: "0px", borderRadius: "10px", position: "sticky", zIndex: "1" }}>
                            {(() => {
                                if (serviceType !== null && Array.isArray(serviceType)) {
                                    return serviceType.map((data, index) => (
                                        <button className="serviceTypeBtnActive" style={getButtonStyle(index + 1)} key={data.id}
                                            onClick={() => handleButtonClick((index + 1), data.id)}>
                                            {data.name}
                                        </button>
                                    ))
                                }
                            })()}
                        </div>
                        <div className="cartScroll" style={{ marginLeft: "00px", }}>
                            <div style={{ display: "flex", justifyContent: "flex-start", backgroundColor: "#f2f2f2" }}>
                                <div className="paymentDiv-pos" style={{}}>
                                    <i className="fa-solid fa-burger payemntIcon"></i>
                                </div>
                                <div className="paymentDiv-pos space-between multiplePosDiv" style={{ flexDirection: "unset" }}>
                                    <div className="">
                                        <div className="flex-start" style={{ padding: "2px" }}>
                                            <label className="ques">Date : &nbsp;</label>
                                            <label className="ans">{currentDate} {currentTime}</label>
                                        </div>
                                        <div className="flex-start" style={{ padding: "2px" }}>
                                            <label className="ques">Name : &nbsp;</label>
                                            <label className="ans">{loginUserDetails.userName ? loginUserDetails.userName : 'Loading...'}</label>
                                        </div>
                                        <div className="flex-start" style={{ padding: "2px" }}>
                                            {/* <label className="ques">Counter No : &nbsp;</label> */}
                                            <select className="ques POS_Select_im_input" onChange={(e) => billSeriesChange(e.target.value)}
                                                value={orderData.orderSeriesId}
                                                style={{ selectStyle, backgroundColor: "transparent", width: "auto", paddingLeft: "2px", fontSize: "14px", padding: "1px" }}>
                                                <option disabled selected>Select Bill Series</option>
                                                {(() => {
                                                    if (billseries !== null && Array.isArray(billseries)) {
                                                        return billseries
                                                            .map((data) => (
                                                                <option key={data.id} value={data.id}>
                                                                    {data.billSeriesName}
                                                                </option>
                                                            ))
                                                    }
                                                })()}
                                            </select>
                                            <label className="ans"> : {orderData.orderNo}</label>              {/*Counter  ,   BillSeries*/}
                                        </div>
                                    </div>
                                    <div>
                                        <div className="flex-end">
                                            <i className="fa-solid fa-c" style={{ color: "rgb(87, 86, 86)" }} onClick={CancelItemBtn}></i> &nbsp; &nbsp;
                                            <i className="fa-solid fa-couch" style={{ color: "rgb(87, 86, 86)" }} onClick={TableChangeBtn}></i>
                                        </div>
                                        <div className="flex-end">
                                            <label className="ques">Captain : &nbsp;</label>
                                            <select className="ans  POS_Select_im_input" onChange={handleOrderTakerSelect} value={orderData.orderConfirmedBy}
                                                style={{ selectStyle, backgroundColor: "transparent", width: "50%", paddingLeft: "2px", fontSize: "14px", padding: "1px" }}>
                                                <option value="default">Select Name</option>
                                                {/* <option value="563">Order Taker</option> */}
                                                {(() => {
                                                    if (users !== null && Array.isArray(users)) {
                                                        return users
                                                            // .filter(item => item.loginType == 563)
                                                            .map((data) => (
                                                                <option key={data.id} value={data.id}>
                                                                    {data.userName}
                                                                </option>
                                                            ))
                                                    }
                                                })()}
                                            </select>
                                        </div>
                                        {orderData.serviceTypeId == 751 && (
                                            <div className="flex-end">
                                                <select className="ques  POS_Select_im_input" onChange={onFloorSelect} value={orderData.tableGroupId} disabled={true}
                                                    style={{ selectStyle, border: "1px solid transparent", backgroundColor: "transparent", width: "50%", paddingLeft: "2px", fontSize: "14px", textAlign: "end", padding: "1px" }}>
                                                    <option value="0">Floor</option>
                                                    {(() => {
                                                        if (tableGroup !== null && Array.isArray(tableGroup)) {
                                                            return tableGroup.map((data) => (
                                                                <option key={data.id} value={data.id}>
                                                                    {data.name}
                                                                </option>
                                                            ))
                                                        }
                                                    })()}
                                                </select>
                                                <label>:</label>
                                                <select className="ans  POS_Select_im_input" onChange={(e) => onTableSelect(e.target.value)} value={orderData.tableId} disabled={true}
                                                    style={{ selectStyle, border: "1px solid transparent", backgroundColor: "transparent", width: "50%", paddingLeft: "2px", fontSize: "14px", padding: "1px" }}>
                                                    <option style={{ appearance: 'none', background: "" }} value="0">Table No.</option>
                                                    {(() => {
                                                        if (tableNumberData !== null && Array.isArray(tableNumberData)) {
                                                            return tableNumberData.map((data) => (
                                                                <option key={data.id} value={data.id}>
                                                                    {data.tableName}
                                                                </option>
                                                            ))
                                                        }
                                                    })()}
                                                </select>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/* ********************* {User details} *********************** */}
                            <div id="userDetails" style={{ backgroundColor: "white", padding: "0px 0px" }}>
                                <div className=" input-height2  space-between" style={{ width: "100%" }}>
                                    <div className="gridView3" id="gridView">
                                        <div style={{ position: "relative" }} onClick={handleClick}>
                                            <div className="input-containerEffect mt-3" style={{ margin: "1%" }}>
                                                <input
                                                    className="form-control inputEffect modify_im_input mode_limit mode_category input-s1"
                                                    type="text" name="name" required
                                                    onChange={handleInputChange}
                                                    value={inputItemValue}
                                                    ref={input1Ref}
                                                    onKeyDown={(event) => handleKeyPressForSuggestion(event, input2Ref)}
                                                />
                                                <label id="input1" className="labelEffect1">Name</label>
                                            </div>
                                            {isItemOpen && (
                                                <div style={{ position: "absolute", zIndex: "1", top: "42px", minWidth: "200px", maxHeight: "350px", backgroundColor: "" }}>
                                                    <div className="form-control dropdown-c scroll-container" style={{ backgroundColor: "ghostWhite", maxHeight: "350px", padding: "0px" }}>
                                                        {suggestedItems1
                                                            // .filter(item => item.parentGroupId === 0)
                                                            .map((suggestion, index) => (
                                                                <div style={{ backgroundColor: highlightedIndex === index ? '#dcdcf4' : 'transparent', padding: "0px", marginLeft: "5px" }}>
                                                                    <div className="" style={{ marginTop: "5px", display: "flex", justifyContent: "center", padding: "10px" }} key={index}>
                                                                        <label onClick={(e) => handleItemSuggestion(suggestion.name, suggestion.d1Id, suggestion.d2Id, e)}
                                                                            value={suggestion.d1Id} name="name" style={{ textAlign: "center", fontWeight: "500" }}>
                                                                            {suggestion.name}
                                                                        </label>
                                                                    </div>
                                                                    <hr style={{ padding: "0px", margin: "0px" }} />
                                                                </div>
                                                            ))}
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        <div className="input-containerEffect mt-3" style={{ margin: "1%" }}>
                                            <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text"
                                                ref={input2Ref} onKeyDown={(event) => handleKeyPress(event, input3Ref)}
                                                name="aMobileNo" onChange={handleInputChange} value={inputValues.aMobileNo} required />
                                            <label id="input1" className="labelEffect1">Mobile No.</label>
                                        </div>
                                        <div className="input-containerEffect mt-3" style={{ margin: "1%" }}>
                                            <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="date"
                                                ref={input3Ref} onKeyDown={(event) => handleKeyPress(event, input4Ref)}
                                                name="adob" onChange={handleInputChange} value={inputValues.adob} required />
                                            <label id="input1" className="labelEffect1">Date of Birth &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</label>
                                        </div>

                                        <div className="input-containerEffect mt-3" style={{ margin: "1%" }}>
                                            <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="date"
                                                ref={input4Ref} onKeyDown={(event) => handleKeyPress(event, input5Ref)}
                                                name="adom" onChange={handleInputChange} value={inputValues.adom} required />
                                            <label id="input1" className="labelEffect1">Date Of Anniversary &nbsp; &nbsp; &nbsp;</label>
                                        </div>

                                        {/* <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text"
                                                ref={input5Ref} onKeyDown={(event) => handleKeyPress(event, input6Ref)}
                                                name="referralBy" onChange={handleInputChange} value={inputValues.referralBy} required /> */}

                                        {(() => {
                                            var val = orderData.serviceTypeId === 751 ? input1Ref : input6Ref;
                                            //console.log("orderData", orderData);
                                            return (
                                                <div className="input-containerEffect mt-3" style={{ margin: "1%" }}>
                                                    <select className="form-control inputEffect modify_im_input mode_limit mode_category input-s1"
                                                        ref={input5Ref} onKeyDown={(event) => handleKeyPress(event, val)}
                                                        name="referralBy" onChange={handleInputChange} value={inputValues.referralBy} required>
                                                        <option value="0">Select Referred By</option>
                                                        {(() => {
                                                            if (account !== null && Array.isArray(account)) {
                                                                return account
                                                                    //.filter(item => item.parentGroupId == 0)
                                                                    .map((data) => (
                                                                        //console.log(data),
                                                                        <option key={data.id} value={data.d2Id}>
                                                                            {data.name}
                                                                        </option>
                                                                    ))
                                                            }
                                                        })()}
                                                    </select>
                                                    <label id="input1" className="labelEffect1">Referred By</label>
                                                </div>
                                            )
                                        })()}

                                        {inputVisible && (
                                            <div className="input-containerEffect mt-3" id="DeliveryDate" style={{ margin: "1%", }}>
                                                <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="date"
                                                    ref={input6Ref} onKeyDown={(event) => handleKeyPress(event, input7Ref)} required />
                                                <label id="input1" className="labelEffect1">Delivery Date &nbsp; &nbsp; &nbsp; </label>
                                            </div>
                                        )}
                                        {inputVisible && (
                                            <div className="input-containerEffect mt-3" id="DeliveryTime" style={{ margin: "1%", }}>
                                                <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text"
                                                    ref={input7Ref} onKeyDown={(event) => handleKeyPress(event, input8Ref)} required />
                                                <label id="input1" className="labelEffect1">Delivery Time</label>
                                            </div>
                                        )}

                                        {inputVisible && (
                                            <div className="input-containerEffect mt-3" style={{ margin: "1%" }}>
                                                <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text"
                                                    ref={input8Ref} //onKeyDown={(event) => handleKeyPress(event, input9Ref)}
                                                    name="aAddress" onChange={handleInputChange} value={inputValues.aAddress} required />
                                                <label id="input1" className="labelEffect1">Location </label>
                                            </div>
                                        )}
                                    </div>

                                    <div className="flex-start" style={{ flexDirection: "column", }}>
                                        <i className="fa-solid fa-circle-plus mt-1" onClick={openAccountMasterModal} style={{ color: "rgb(19, 129, 196)" }}></i>
                                        <i className="fa-solid fa-clock-rotate-left mt-1" onClick={toggleSlider} style={{ color: "darkgreen" }}></i>
                                        <i className="fa-solid fa-star mt-1" style={{ color: "orange" }} onClick={openFeedbackModal}></i>
                                        <i className="fa-solid fa-envelope mt-1" style={{ color: "darkred" }} onClick={handleMessageBtn}></i>
                                    </div>
                                </div>
                            </div>
                            {/****************************************************/}
                            <div className="cartItem">
                                {(() => {
                                    if (cartData != null && cartData.length > 0 || (runningOrder != null && runningOrder.length > 0)) {
                                        return (
                                            <table className="tableStyle-pos" style={{}}>
                                                <thead style={{ backgroundColor: "#d3d3d34f", border: "1px solid lightgrey" }}>
                                                    <tr>
                                                        <th className="" style={{ width: "5%", textAlign: "center", fontSize: "14px" }}> </th>
                                                        <th className="" style={{ width: "40%", textAlign: "center", fontSize: "14px" }}>Item Name</th>
                                                        <th className="" style={{ width: "15%", textAlign: "center", fontSize: "14px" }}>Del. Pref.</th>
                                                        <th className="" style={{ width: "25%", textAlign: "center", fontSize: "14px" }}>Quantity</th>
                                                        <th className="" style={{ width: "15%", textAlign: "center", fontSize: "14px" }}>Price</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {/* ********************* Running Order ******************************* */}
                                                    {runningOrder !== null && runningOrder.length > 0 && (
                                                        <>
                                                            {Array.from(new Set(runningOrder.map(data => data.itemRunningOrderCounter))).map((counter, index) => (
                                                                <React.Fragment key={index}>
                                                                    <tr style={{ backgroundColor: "rgba(191, 191, 191, 0.53)" }}>
                                                                        <td colSpan={5} style={{ fontWeight: "600", fontSize: "14px", padding: "5px" }}>Kot Running Order {counter + 1}</td>
                                                                    </tr>
                                                                    {runningOrder
                                                                        .filter(data => data.itemRunningOrderCounter === counter)
                                                                        .map((data, idx) => (
                                                                            <tr className="POS_Table_Tr1" key={idx} style={{ backgroundColor: data.color }}>
                                                                                <td className="POS_Table_Td1" >
                                                                                    <div className="POS_Table_Td2">
                                                                                        <label className="POS_Table_Label1"></label>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="POS_Table_Td3" onClick={() => openCommentModal(data.name, data.d2Id, data.d1ItemId)}>
                                                                                    {data.name}<i className="fa-solid fa-pen Icon_Pen_POS"></i>
                                                                                </td>
                                                                                <td className="" style={{ textAlign: "center" }}>
                                                                                    <input className="POS_Table_Input_Text hide-spinner" type="number" name="preferenceNo" value={data.preferenceNo >= 0 ? data.preferenceNo : 0} />
                                                                                </td>
                                                                                <td className="" style={{ textAlign: "left" }}>
                                                                                    <div className="mt-1">
                                                                                        <div className="quantity" style={{ justifyContent: "flex-start", marginLeft: "10px" }}>
                                                                                            <button style={{ paddingBottom: "4px" }} className="quantity__minus">-</button>
                                                                                            <input className="quantity__input" type="text" value={data.quantity} />
                                                                                            <button className="quantity__plus">+</button>
                                                                                            <label style={{ fontSize: "12px" }}>&nbsp; x &#x20B9; {data.salePrice}&nbsp; &nbsp; </label>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="" style={{ textAlign: "center", fontSize: "13px" }}>
                                                                                    &#x20B9; {(data.quantity === 0 ? 1 : data.quantity) * data.salePrice}
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                </React.Fragment>
                                                            ))}
                                                        </>
                                                    )}

                                                    {/* ********************************** New Order ************************************** */}
                                                    {cartData !== null && cartData.length > 0 && (
                                                        <tr style={{ backgroundColor: "rgba(191, 191, 191, 0.53)" }}>
                                                            <td colSpan={5} style={{ fontWeight: "600", fontSize: "14px", padding: "5px" }}>New KOT</td>
                                                        </tr>
                                                    )}
                                                    {cartData !== null && cartData.length > 0 && cartData.map((data, index) => (
                                                        <>
                                                            <tr className="POS_Table_Tr1" key={index} style={{ backgroundColor: data.color }}>
                                                                <td className="POS_Table_Td1" >
                                                                    <div className="POS_Table_Td2" onClick={() => deleteCartItemClick(data.d2Id)}>
                                                                        <label className="POS_Table_Label1" onClick={() => deleteCartItemClick(data.d2Id)}>X</label>
                                                                    </div>
                                                                </td>
                                                                <td className="POS_Table_Td3" onClick={() => openCommentModal(data.name, data.d2Id, data.d1ItemId)}>{data.name}<i className="fa-solid fa-pen Icon_Pen_POS"></i></td>
                                                                <td className="" style={{ textAlign: "center" }}>
                                                                    <input className="POS_Table_Input_Text hide-spinner" type="number" name="preferenceNo" onFocus={(e) => handleFocus(e)}
                                                                        value={data.preferenceNo >= 0 ? data.preferenceNo : 0} onChange={(e) => handleDelPreferenceChange(e, data.d2Id)} />
                                                                </td>
                                                                <td className="" style={{ textAlign: "left" }}>
                                                                    <div className="mt-1">
                                                                        {/* <div className="" style={{ display: "flex", justifyContent: "center" }}>
                                                                        <button className="preferenceInput increDecreBtn" onClick={() => handleDecrement()}>-</button>
                                                                        <input onChange={handleChange} min={1} value={IncrementValue} className="preferenceInput bold" style={{ width: "20%", margin: "0%", paddingLeft: "1px", borderColor: "darkgray", textAlign: "center" }} />
                                                                        <button className="preferenceInput increDecreBtn" onClick={() => handleIncrement()}>+</button>
                                                                    </div> */}
                                                                        <div className="quantity" style={{ justifyContent: "flex-start", marginLeft: "10px" }}>
                                                                            <button
                                                                                style={{ paddingBottom: "4px" }}
                                                                                className="quantity__minus"
                                                                                onClick={() => handleDecrement(data.d2Id)}
                                                                            >
                                                                                -
                                                                            </button>
                                                                            <input className="quantity__input"
                                                                                type="text"
                                                                                onChange={(event) => onQuantityChange(event, data.d2Id)}
                                                                                value={data.quantity}

                                                                            />
                                                                            <button className="quantity__plus"
                                                                                onClick={() => handleIncrement(data.d2Id)}
                                                                            >  + </button>
                                                                            <label style={{ fontSize: "12px" }}>&nbsp; x &#x20B9; {data.salePrice}&nbsp; &nbsp; </label>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className="" style={{ textAlign: "center", fontSize: "13px" }}>&#x20B9; {(data.quantity == 0 ? 1 : data.quantity) * data.salePrice}</td>
                                                                {/* <td className="" style={{ textAlign: "center", fontSize: "13px" }}>{data.newTotal} &#x20B9; {data.quantity * data.salePrice}</td> */}
                                                            </tr>
                                                        </>
                                                    ))}
                                                </tbody>
                                            </table>
                                        );
                                    }
                                    else {
                                        return <div>
                                            <hr />
                                            <p style={{ fontWeight: "700", textAlign: "center", marginTop: "25px" }}>No items in the cart.</p></div>;
                                    }
                                })()}
                            </div>
                            <div style={{}}>
                                <div className="space-between" style={{ padding: "1px 15px", backgroundColor: "ghostwhite", borderTop: "1px solid lightgrey", alignItems: "" }}>
                                    <div className="flex">
                                        <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "25%" }}>
                                            <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text" required />
                                            <label id="input1" className="labelEffect1">Bill&nbsp;Split(Person) </label>
                                        </div>
                                        <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "25%" }}>
                                            <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text"
                                                name="noOfAdult" onFocus={(e) => handleFocus(e)} onChange={handleChange} value={orderData.noOfAdult} required />
                                            <label id="input1" className="labelEffect1">No&nbsp;of&nbsp;Adult</label>
                                        </div>
                                        <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "25%" }}>
                                            <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text"
                                                value={orderData.noOfChild} onFocus={(e) => handleFocus(e)} onChange={handleChange} name="noOfChild" required />
                                            <label id="input1" className="labelEffect1" >No&nbsp;of&nbsp;Child</label>
                                        </div>
                                        {orderData.ord1Id !== 0 && orderData.ord1Id !== undefined && orderData.ord1Id !== null && orderData.ord1Id !== ""
                                            && (
                                                // <i class="fa-solid fa-floppy-disk mt-2" onClick={() => saveNoOfPerson()} style={{ marginLeft: "5px" }}></i>
                                                <ToolTipMaster NoOfPerson={true} saveNoOfPerson={saveNoOfPerson} />
                                            )
                                        }
                                    </div>
                                    <div className="flex">
                                        <label style={{ color: "black", fontWeight: "700" }}>Total:&nbsp;&#x20B9;</label>
                                        <label style={{ color: "black", fontWeight: "700" }} value={totalAmount}>{totalAmount}</label> {/*id="Total"*/}
                                    </div>
                                </div>

                                <div className="space-between" style={{ width: "100%", backgroundColor: "#f8f8ff", padding: "1px 20px", borderTop: "1px solid lightgrey" }}>
                                    <div className="" style={{ width: "50%", fontSize: "15px" }}>
                                        <div className="flex-start">
                                            <label className="ques">Delivery&nbsp;Time&nbsp;:&nbsp;</label>
                                            {/* <input className="smallSelectBox" style={{ border: "1px solid transparent " }} type="text" //type="time" 
                                                name="deliveryTimeId" onChange={handleChange} value={orderData.deliveryTimeId} /> */}
                                            <select className="posSelectBox"
                                                name="deliveryTimeId"
                                                disabled={true}
                                                value={orderData.deliveryTimeId}>
                                                {(() => {
                                                    if (AllocatedTimeSlots && Array.isArray(AllocatedTimeSlots) && AllocatedTimeSlots.length > 0) {
                                                        return AllocatedTimeSlots.map((data, index3) => (
                                                            <option key={index3} value={data.id}>
                                                                {convertTo12HourFormat(data.availableFrom)} to {convertTo12HourFormat(data.availableTo)}
                                                            </option>
                                                        ));
                                                    } else {
                                                        return <option value=""></option>;
                                                    }
                                                })()}
                                            </select>
                                        </div>
                                        <div className="flex-start">
                                            <label className="ques">Order Status : &nbsp;</label>
                                            <label type="text" value="" name="overallOrderStatus">{orderData.overallOrderStatus}</label>
                                            {/* <select className="smallSelectBox" value={orderData.overallOrderStatus} onChange={handleChange} name="overallOrderStatus">
                                                <option value="Pending">Pending</option>
                                                <option value="Delivered">Delivered</option>
                                                <option value="Confirmed">Confirmed</option>
                                            </select> */}
                                        </div>
                                        <div className="flex-start">
                                            <label className="ques">Payment Status : &nbsp;</label>
                                            <label value="" name="paymentStatus">{orderData.paymentStatus}</label>
                                            {orderData.ord1Id !== 0 && orderData.ord1Id !== undefined && orderData.ord1Id !== null && orderData.ord1Id !== ""
                                                && (
                                                    <i class="fa-regular fa-credit-card leftSideIcon" onClick={() => openPaymentDetailsModal()} style={{ marginLeft: "5px" }}></i>
                                                )
                                            }
                                            {/* <select className="smallSelectBox">
                                                <option>Pending</option>
                                                <option>Delivered</option>
                                                <option>Confirmed</option>
                                            </select> */}
                                        </div>
                                        <div className="flex-start">
                                            <label className="ques">Kot Manager : &nbsp;</label>
                                            <label className="ans">{loginUserDetails.userName ? loginUserDetails.userName : 'Loading...'}</label>
                                            {/* <select className="smallSelectBox">
                                                <option>Ram</option>
                                                <option>Shyam</option>
                                            </select> */}
                                        </div>
                                        <div className="flex-start">
                                            <label className="ques">Confirm&nbsp;On&nbsp;:&nbsp;</label>
                                            {/* <input type="text" value={currentDate} className="smallSelectBox" style={{ border: "1px solid transparent" }} /> */}
                                            <input type="text" value={moment.utc(orderData.orderConfirmedOn).utcOffset("+05:30").format('DD-MM-YYYY hh:mm A')} className="smallSelectBox" style={{ border: "1px solid transparent" }} />
                                        </div>
                                        <div className="flex-start">
                                            <label className="ques">Confirm By : &nbsp;</label>
                                            <label className="ans">{loginUserDetails.userName ? loginUserDetails.userName : 'Loading...'}</label>
                                            {/* <select className="smallSelectBox">
                                                <option>Ram</option>
                                                <option>Shyam</option>
                                            </select> */}
                                        </div>
                                        <div id="comment" style={{ width: '95%' }}>
                                            <div className="input-containerEffect" style={{ padding: "0px", width: "100%" }}>
                                                {/* <label className="fw-600">Order wise Comment</label> */}
                                                <textarea className=" pos_AreaText_input" placeholder="Order Wise Comment.." style={{ height: "30px" }}
                                                    name="orderSuggestion" onChange={handleChange} value={orderData.orderSuggestion}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ width: "45%", fontSize: "14px" }}>
                                        <div className="flex-end mt-1">
                                            <label className="ques">Loyality &nbsp;</label>
                                            <div className="flex-end" style={{ width: "50%" }}>
                                                <div className="image-input-container" style={{ width: "100%" }}>
                                                    <input style={{ height: "20px" }} type="text" placeholder="" className=" modify_im_input12 mode_limit mode_category" />
                                                    <button className=" apply-button input-image" style={{ width: "30%" }}>Use</button> {/*onClick={handleApplyCoupon}*/}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-end mt-1">
                                            <label className="ques">Coupon &nbsp; </label>
                                            <div className="flex-end" style={{ width: "50%" }}>
                                                <div className="image-input-container" style={{ width: "100%" }}>
                                                    <input style={{ height: "20px" }} type="text" //value={couponCode} 
                                                        placeholder="Coupon" value={orderData.couponCode} onChange={handleChange} name="couponCode"
                                                        className=" modify_im_input12 mode_limit mode_category" />
                                                    <button className=" apply-button input-image" onClick={openCouponModal} style={{ width: "30%" }}>View</button> {/*onClick={handleApplyCoupon}*/}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-end mt-1">
                                            <label className="ques">Discount &nbsp; </label>
                                            <div className="flex-end" style={{ width: "50%" }}>
                                                <div className="image-input-container" style={{ width: "100%" }}>
                                                    <input style={{ height: "20px" }} type="text" className=" modify_im_input12 mode_limit mode_category" />
                                                </div>
                                            </div>
                                        </div>
                                        {paymentDetails && paymentDetails.map((data, index) => (
                                            <div key={index} className="flex-end mt-1">
                                                <label className="ques">{data.accountTitle} &nbsp;</label>
                                                <div className="flex-end" style={{ width: "50%" }}>
                                                    <div className="image-input-container" style={{ width: "100%" }}>
                                                        <input
                                                            style={{ height: "20px" }}
                                                            type="number"
                                                            className="modify_im_input12 mode_limit mode_category"
                                                            onChange={(e) => handlePaymentChange(e, data.accountId)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                            {paymentDetails && paymentDetails.length > 0 && orderData.paymentStatus != "Done" && orderData.ord1Id !== 0 && orderData.ord1Id !== undefined && orderData.ord1Id !== null && orderData.ord1Id !== ""
                                                && (
                                                    <button className="pos_Small_SaveBtn mt-1" onClick={SavePaymentBtn}>Save</button>
                                                )
                                            }
                                        </div>
                                        {/* <div className="flex-end mt-1">
                                            <label className="ques">Card &nbsp; </label>
                                            <div className="flex-end" style={{ width: "50%" }}>
                                                <div className="image-input-container" style={{ width: "100%" }}>
                                                    <input style={{ height: "20px" }} type="text" className=" modify_im_input12 mode_limit mode_category" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-end mt-1">
                                            <label className="ques">UPI &nbsp;</label>
                                            <div className="flex-end" style={{ width: "50%" }}>
                                                <div className="image-input-container" style={{ width: "100%" }}>
                                                    <input style={{ height: "20px" }} type="text" className=" modify_im_input12 mode_limit mode_category" />
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="flex-end">
                                            <label className="ques" style={{ color: "darkred" }}>
                                                Pay Amt :- <span style={{ color: "black" }}>₹&nbsp;{orderData && orderData.paymentAmount || 0}</span>,
                                                Due Amt :- <span style={{ color: "black" }}>₹&nbsp;{
                                                    (orderData &&
                                                        (orderData.grandTotalAmt - (Number(orderData.o1CancelledAmt) + Number(orderData.paymentAmount))) || 0)
                                                }.</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="" style={{ height: "40px", backgroundColor: "black", display: "flex", justifyContent: "space-evenly", position: "sticky", zIndex: "1" }}>
                            {/* <button className="cart-btn" style={{ width: "25%" }}>Save</button> */}
                            {/* <button className="serviceTypeBtnActive" style={{ width: "50%" }} onClick={OrderAndPaymentDone}>Order & Payment Done</button> */}
                            <button className="OrderNow-btn" style={{ width: "100%" }} onClick={() => ConfirmAndKot()}>Confirm & KOT</button> {/*KOT & Print */}
                            <button className="OrderNow-btn" style={{ width: "100%" }} onClick={() => SaveAndPrint(true)}>Confirm & Print</button>
                            {orderData.ord1Id !== 0 && orderData.ord1Id !== undefined && orderData.ord1Id !== null && orderData.ord1Id !== ""
                                && (
                                    <>
                                        <button className="OrderNow-btn" style={{ width: "100%" }} onClick={BillPrintBtn}>Print</button>
                                        <button className="OrderNow-btn" style={{ width: "100%" }} onClick={OrderDoneBtn}>Order Done</button>
                                    </>
                                )}
                        </div>
                    </div>
                </div>
            </div>
            {/******************************* Print Modal *************************/}
            {showPrintModal && (
                <div>
                    <div className="modal-back" onClick={closeCouponModal}>
                        <div className="billPrintModal" style={{}}>
                            <div style={{ padding: "5px", backgroundColor: "white" }}>
                                <OrderTakerBillPrint id={orderListData && orderListData[0].ord1Id} closePrintModal={closePrintModal} backBtn={"modal"} />

                                {orderListData && orderListData.map((item, index) => (
                                    <div style={{ marginTop: "30px" }}>
                                        <KotPrintPage orderNo={item.orderNo} itemName={item.d2ItemName} preferenceNo={item.preferenceNo} qty={item.qty} sku={item.sku} ord2Id={item.ord2Id} backBtn={"none"} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/************************** Payment Detail Modal ***************/}
            {
                paymentDetailModalPopup && (
                    <div className="">
                        <PaymantModeModel handlePayment={handlePayment}
                            PaymentModalData={PaymentModalData}
                            closePaymentModal={closePaymentDetailsModal} />
                    </div>
                )
            }
            {/******************************* Bill Modal *************************/}
            {showBillPrint && (
                <div>
                    <div className="modal-back" onClick={closeBillPrint}>
                        <div className="billPrintModal" style={{}}>
                            <div style={{ padding: "5px", backgroundColor: "white" }}>
                                <OrderTakerBillPrint id={orderListData && orderListData[0]?.ord1Id} closePrintModal={closeBillPrint} backBtn={"modal"} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/******************************* Toast Message *************************/}
            {showToast && (
                <div className="">
                    <div className="messageDiv" style={{ height: "auto", width: "auto", padding: "15px" }}>
                        <label style={{ color: "white" }}> Message Send Successfully !!</label>
                    </div>
                </div>
            )}
            {/******************************* History Slider *************************/}
            <div onClick={toggleSlider} className={`slider-container ${isSliderOpen ? 'open' : ''}`}>
                <div onClick={handleModalClick} style={{ padding: "15px" }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <label></label>
                        <label style={{ cursor: "pointer", fontSize: "24px" }} onClick={toggleSlider}>x</label>
                    </div>
                    <PreviousOrder />
                </div>
            </div>

            {/************************* Comment Modal *****************************/}
            {commentModal && (
                <div className="modal-back">  {/*onClick={closeCommentModal}*/}
                    <div className="partyDetail-modal" style={{ height: "auto", backgroundColor: "white", width: "25%", padding: "15px" }}>
                        <div>
                            <div style={{ display: "flex", justifyContent: "space-between", position: "relative", alignItems: "baseline" }}>
                                <label>Add Comment for {CommentName}</label>    {/*{CommentName}*/}
                                <label style={{ cursor: "pointer", fontSize: "24px" }} onClick={closeCommentModal}>x</label>
                            </div>
                            <hr />
                            <div className="">
                                <div id="comment" style={{ backgroundColor: "white" }}>
                                    <div className="input-containerEffect" style={{ padding: "10px", width: "100%" }}>
                                        <textarea className="form-control" ref={input9Ref} placeholder="Add Item Comment.." value={selectedComment} onChange={handleCommentChange}></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex-end" style={{ padding: "10px" }}>
                            <button className="greenBtn" style={{ width: "22%" }} onClick={saveComment}>Save</button>
                            <button className="redBtn" style={{ width: "22%" }} onClick={closeCommentModal}>Close</button>
                        </div>
                    </div>
                </div>
            )}
            {/******************************* Coupon Modal*************************/}
            {CouponModal && (
                <div className="modal-back" onClick={closeCouponModal}>
                    <div onClick={handleModalClick} className="partyDetailPosModal" style={{ height: "auto", width: "35%" }}>
                        <div style={{ padding: "15px" }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <label style={{ fontWeight: "600", color: "black" }}>View Coupon</label>
                                <label style={{ cursor: "pointer", fontSize: "24px", alignItems: "baseline" }} onClick={closeCouponModal}>x</label>
                            </div>
                            <hr />
                            <div style={{ margin: "10px", padding: "0px 10px" }}>
                                <input type="text" placeholder="Enter Coupon" style={{ width: "100%", padding: '5px', borderRadius: "5px" }} />
                                <div className="couponBox mt-2">
                                    <div className="couponLeftSide">
                                        <label className="rotated-text" style={{ color: "white", fontWeight: "600" }}>20% OFF</label>
                                    </div>
                                    <div className="" style={{ padding: "1px 10px", width: "100%" }}>
                                        <div className="space-between">
                                            <label className="couponName">FRAMEFREE</label>
                                            <button className="apply-button" style={{ padding: "5px" }}>Apply</button>
                                        </div>
                                        <div>
                                            <label style={{ color: "grey" }}>Save &#x20B9;50 on this order.</label><br />
                                            <label style={{ color: "grey" }}>Use code FRAMEFREE & get flat 20% off on this order.</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="couponBox mt-3">
                                    <div className="couponLeftSide">
                                        <label className="rotated-text" style={{ color: "white", fontWeight: "600" }}>20% OFF</label>
                                    </div>
                                    <div className="" style={{ padding: "1px 10px", width: "100%" }}>
                                        <div className="space-between">
                                            <label className="couponName">FRAMEFREE</label>
                                            <button className="apply-button" style={{ padding: "5px" }}>Apply</button>
                                        </div>
                                        <div>
                                            <label style={{ color: "grey" }}>Save &#x20B9;50 on this order.</label><br />
                                            <label style={{ color: "grey" }}>Use code FRAMEFREE & get flat 20% off on this order.</label>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <hr style={{ padding: "0px", margin: "5px" }} />
                            <div className="flex-end" style={{ padding: "0px" }}>
                                <button className="greenBtn" onClick={closeCouponModal} style={{ width: "20%" }}>Apply</button>
                                <button className="redBtn" onClick={closeCouponModal} style={{ width: "20%" }}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/************************** Variable Modal ***************/}
            {VariableItemModal && (
                <div className="modal-back">
                    <div className="variableItem-modal">
                        <i onClick={closeVariableItemModal} style={{ position: "absolute", right: "12px" }} id="modal_box_close" className="fa-sharp fa-solid fa-xmark" ></i>
                        <h1 style={{ fontSize: "22px", paddingLeft: "10px", color: "#6a6666" }} className="c-modal-txt">Variable Item</h1>
                        <hr />
                        <div className="space-evenly" style={{ padding: "10px" }}>
                            <div className="gridView3">
                                {/* {(() => {
                                    if (VariableData !== null && Array.isArray(VariableData)) {
                                        return VariableData.map((data) => (
                                            <div style={{ margin: "5px" }}>
                                                <div className="variationDiv" style={getButtonStyle1(4)}
                                                    onClick={() => handleButtonClickforVariableItem(4)}>
                                                    <label className="ques1">{data.name} </label><br />
                                                    <label className="ques1">&#x20B9; {data.salePrice}</label>
                                                </div>
                                            </div>
                                        ))
                                    }
                                })()} */}
                                {VariableData !== null && Array.isArray(VariableData) && VariableData.map((data, index) => (
                                    <div key={index} style={{ margin: "5px" }}>
                                        <div
                                            className="variationDiv"
                                            style={index === selectedItemIndex ? getVariableStyle() : getVariableStyleWhite()}
                                            onClick={() => handleButtonClickforVariableItem(index, data.d2Id)}
                                        >
                                            <label className="ques1">{data.name} </label><br />
                                            <label className="ques1">&#x20B9; {data.salePrice}</label>
                                        </div>
                                    </div>
                                ))}

                            </div>

                            {/* <div className="gridView3">
                                <div className="variationDiv" style={getButtonStyle1(4)}
                                    onClick={() => handleButtonClickforVariableItem(4)}>
                                    <label className="ques1">Small </label><br />
                                    <label className="ques1">&#x20B9; 100.00</label>
                                </div>
                                <div className="variationDiv" style={getButtonStyle1(5)}
                                    onClick={() => handleButtonClickforVariableItem(5)}>
                                    <label className="ques1">Medium </label><br />
                                    <label className="ques1">&#x20B9; 100.00</label>
                                </div>
                                <div className="variationDiv" style={getButtonStyle1(6)}
                                    onClick={() => handleButtonClickforVariableItem(6)}>
                                    <label className="ques1">Large </label><br />
                                    <label className="ques1">&#x20B9; 100.00</label>
                                </div>
                            </div> */}
                        </div>
                        <hr />
                        <div className="flex-end" style={{ paddingBottom: "15px" }}>
                            <button className="redBtn" onClick={closeVariableItemModal} style={{ width: "20%", backgroundColor: "#d03a3af2" }}> Cancel </button>
                            <button className="greenBtn" onClick={VariableSaveBtn} style={{ width: "20%" }}> Save </button>
                        </div>
                    </div>
                </div>
            )}
            {/************************* Account Master Modal *****************************/}
            {AccountMasterModal && (
                <div className="modal-back" onClick={closeAccountMasterModal}>
                    <div onClick={handleModalClick} className="partyDetail-modal" style={{ height: "auto", backgroundColor: "white", width: "85%", padding: "15px" }}>
                        <div style={{ display: "flex", justifyContent: "flex-end", position: "sticky" }}>
                            <label style={{ cursor: "pointer", fontSize: "24px" }} onClick={closeAccountMasterModal}>x</label>
                        </div>
                        <div className="Pos_invoice_scroll_AM" >
                            <AccountMaster />
                        </div>
                    </div>
                </div>
            )}
            {/************************* Feedback Modal *****************************/}
            {FeedbackModal && (
                <div className="modal-back" onClick={closeFeedbackModal}>
                    <div onClick={handleModalClick} className="partyDetail-modal Pos_invoice_scroll" style={{ height: "auto", backgroundColor: "white", width: "60%", padding: "15px" }}>
                        <div style={{}}>
                            <div className="CloseHeadBtn">
                                <spam className="CrossBtnBack" onClick={closeFeedbackModal}>x</spam>
                            </div>
                            <OrderFeedback closeFeedbackModal={closeFeedbackModal} serviceTypeId={orderData.serviceTypeId} />
                        </div>
                    </div>
                </div>
            )}
            {/* {previousData.map((data, index) => (
                              <tr className="POS_Table_Tr1" key={index} style={{ backgroundColor: data.color }}>
                                  <td className="POS_Table_Td1" >
                                      <div className="POS_Table_Td2" onClick={() => deleteCartItemClick(data.id)}>
                                          <label className="POS_Table_Label1" onClick={() => deleteCartItemClick(data.id)}>X</label>
                                      </div>
                                  </td>
                                  <td className="POS_Table_Td3" onClick={() => openCommentModal(data.name, data.id)}>{data.name}<i className="fa-solid fa-pen Icon_Pen_POS"></i></td>
                                  <td className="" style={{ textAlign: "center" }}><input className="POS_Table_Input_Text" type="text" value={data.deliveryPreference} /></td>
                                  <td className="" style={{ textAlign: "center" }}>
                                      <div className="mt-1">
    
                                          <div className="quantity" >
                                              <button
                                                  style={{ paddingBottom: "4px" }}
                                                  className="quantity__minus"
                                                  onClick={() => handleDecrement(data.id)}
                                              >
                                                  -
                                              </button>
                                              <input className="quantity__input"
                                                  type="text"
                                                  onChange={(event) => onQuantityChange(event, data.id)}
                                                  value={data.quantity}
    
                                              />
                                              <button className="quantity__plus"
                                                  onClick={() => handleIncrement(data.id)}
                                              >  + </button>
                                          </div>
    
                                      </div>
                                  </td>
                                  <td className="" style={{ textAlign: "center", fontSize: "13px" }}> {data.newTotal}&#x20B9; {data.price}</td>
                              </tr>
            ))} */}
            {loading && <Loader />}
        </div>
    );
};

export default OrderPage;

