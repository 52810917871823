import axios from '../axiosConfig';

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const Query = {

    insertQuery: async (data) => {
        
        try {
            const apiUrl = `${WebApiUrl}/api/Query/SaveQuery`;

            //const apiUrl = `${express_server_url}/api/Query`;
            console.log(apiUrl);
            const response = await axios.post(apiUrl, {
                title: data.title,
                query: data.query,
                query2: data.query2,
                query3: data.query3,
                isActive: true,
            }, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return true;
        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },
    // ************************* Get All ***************************
    getQuery: async () => {
        
        try {
            const apiUrl = `${WebApiUrl}/api/Query/GetQueryNameMasters`;
            //const apiUrl = `${express_server_url}/api/Query/GetQueryMasters`;
            const response = await axios.post(apiUrl, {}, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;

        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    // ************************* Get By Id ****************************
    getQueryById: async (QueryId) => {
        try {
            const apiUrl = `${WebApiUrl}/api/Query/GetQueryById?QueryId=${QueryId}`;
            const response = await axios.get(apiUrl, {}, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    // ************************* Get By Id ****************************
    getQueryColumnName: async (QueryId) => {
        try {
            const apiUrl = `${WebApiUrl}/api/Query/getQueryColumnName?QueryId=${QueryId}`;
            const response = await axios.get(apiUrl, {}, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    // ************************* Delete ****************************
    deleteQuery: async (data) => {
        
        try {
            const apiUrl = `${WebApiUrl}/api/Query/DeleteQuery?QueryId=${data.id}`;

            //const apiUrl = `${express_server_url}/api/Query?QueryId=${QueryId}`;
            const response = await axios.delete(apiUrl, {
                withCredentials: true,
            });
            console.log('Delete Response:', response.data.result);
            return true;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },
    
    // *************************** Update ***************************

    updateQuery: async (data) => {
        
        console.log(data.id)
        try {
            const apiUrl = `${WebApiUrl}/api/Query/Query?QueryId=${data.id}`;

            //const apiUrl = `${express_server_url}/api/Query?QueryId=${data.id}`;
            console.log(apiUrl);
            const response = await axios.put(apiUrl, {
                id: data.id,
                title: data.title,
                query: data.query,
                query2: data.query2,
                query3: data.query3,
                isActive: true,
            }, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return response.data.isSuccess;
        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },
};

export default Query;
