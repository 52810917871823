import logo from "./../../images/logo.png";
import React, { useState } from 'react';
import Email from "../../API/APIServices/Email";

function LogInMaster(props) {

    const [buttonClicked, setButtonClicked] = useState(false);

    const handleButtonClick = () => {
        // Set the buttonClicked state to true when the button is clicked
        setButtonClicked(true);
    };
    const handleButtonDelete = () =>{
        setButtonClicked(false);
    }

    const [emailId, setEmailId] = useState();
    const handleEmailChange=(event)=>{
        setEmailId(event.target.value);
        props.handleInputChange(event);
    }
    const [otp, setOtp] = useState('');
    const generateOTP = async()=>{
        const digits = '0123456789';
        let OTP = '';
        for (let i = 0; i < 4; i++) {
          OTP += digits[Math.floor(Math.random() * 10)];
        }
        setOtp(OTP);

        // await Email.SendOTP(emailId, OTP);
        localStorage.setItem("otp", OTP);
        props.gototop()
    }

    return (
        <div className="Formheader git check">
            <div>
                <div className="form-container">
                    <div className="form-sidebar-yesbm-logo ">
                        <img src={logo} alt="" />
                    </div>
                    <p className="FormTitle">Let's Register Now !</p>
                    <div className="formMainDiv">

                        <div className="InputTextD">
                            <input type="text" className="FormInputBox" name="email" placeholder="" required onChange={handleEmailChange} />
                            <label className="formTextLabelUser ">Enter Email</label>
                        </div>
                       
                          {buttonClicked && (
                        <div className="InputTextD">
                            <input type="text" className="FormInputBox  " placeholder="" required />
                            <label className="formTextLabelUser ">Referral Code</label>
                            <span onClick={handleButtonDelete} className="IconDelRef">x</span>

                        </div> )}
                        {!buttonClicked && (
                        <p className="page-link">
                            <span className="page-link-label" onClick={handleButtonClick}>Have A Referral Code?</span>
                        </p> )}
                        {/* <Link style={{ width: "100%" }} to="/OtpVerify"> */}
                            <button onClick={generateOTP} className="form-btn">Login</button>
                        {/* </Link> */}
                        
                    </div>
                    <p className="sign-up-label">
                        Have A Backup?<span className="sign-up-link">Restore it</span>
                    </p>
                    <div className="buttons-container">


                    </div>
                </div>
            </div>
            <div>
            </div>
        </div>
    )
}

export default LogInMaster;
