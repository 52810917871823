import DateTime from "../../Components/DateTime";
import PageTabs from "../../Components/PageTabs";
import React, { useState, useRef, useEffect, useContext } from "react";
import 'react-quill/dist/quill.snow.css'
import MainButton from "../../Props/MainButton";
import AgentApi from "../../../API/APIServices/Agent";
import StateInfoApi from "../../../API/APIServices/StateInfo";
import AlertBox from "../../Props/AlertBox";
import MyContext from "../../MyContext";
import Loader from "../../Props/Loader";
import { useNavigate } from 'react-router-dom';

function TableMaster(props) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { OpenAlertBox, alertBox, OpenAlertBoxEdit, alertBoxEdit } = useContext(MyContext);

    useState(() => {
        document.title = 'Agent Master';
    }, []);

    const [isInputVisiblam1, setInputVisiblam1] = useState(false);
    const handleToggleChangam1 = () => {
        setInputVisiblam1(!isInputVisiblam1);
    };

    const [isModalOpen, setModalOpen] = useState(false);
    const ModalToggle = () => {
        setModalOpen(!isModalOpen);
    };

    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);
    const input4Ref = useRef(null);
    const input5Ref = useRef(null);
    const input6Ref = useRef(null);
    const input7Ref = useRef(null);
    const input8Ref = useRef(null);
    const input9Ref = useRef(null);
    const input10Ref = useRef(null);
    const input11Ref = useRef(null);
    const input12Ref = useRef(null);

    const handleKeyPress = (event, nextInputRef) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            nextInputRef.current.focus();
        }
    };
    useEffect(() => {
        input1Ref.current.focus();
    }, []);

    const handleModalClick = (e) => {
        e.stopPropagation();
    };
    // **********************************************************************
    const [errorMessages, setErrorMessages] = useState({
        name: '',
    });

    const [inputValues, setInputValues] = useState({
        name: '',
        printName: '',
        address: '',
        mobileNo: '',
        state: '',
        telephoneNo: '',
        email: '',
        brokerageMode: 'Percentage',
        brokerage: '',
        freezeBrokerage: 'Yes',
        isConsultant: 'Yes',
        isActive: true,
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        console.log(`Changing ${name} to ${value}`);

        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [name]: value,
            printName: name === 'name' ? value : prevInputValues.printName,
        }));
        setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));
    };

    const handleInputChangeActive = (e) => {
        const { name, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : e.target.value;

        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [name]: type === 'checkbox' ? !prevInputValues[name] : newValue,
        }));

        setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));
        console.log(inputValues);
    };

    const toFriendlyName = (fieldName) => {
        return fieldName
            .replace(/([A-Z])/g, ' $1')
            .replace(/^./, (str) => str.toUpperCase());
    };

    const validateInput = (fieldName) => {
        console.log(fieldName);
        const fieldValue = inputValues[fieldName];

        if (!fieldValue || (typeof fieldValue === 'string' && fieldValue.trim() === '')) {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                [fieldName]: `${toFriendlyName(fieldName)} field is required`,
            }));
            return false;
        }

        return true;
    };
    const fieldNames = ['name', 'brokerage'];

    const SaveBtn = () => {
        let allValid = true;
        fieldNames.forEach((fieldName) => {
            const isValid = validateInput(fieldName);
            if (!isValid) {
                allValid = false;
            }
        });
        if (allValid) {
            setLoading(true);
            if (Boolean(props.modelOpen === true)) {
                const isSuccess = AgentApi.updateAgent(inputValues);
                if (isSuccess) {
                    OpenAlertBoxEdit();
                    setLoading(false);
                    props.fetchData()
                    // props.modelOpen(false);
                    props.setSliderCallBack(false);
                } else {
                    alert("error while updating");
                }
            }
            else {
                setLoading(true);
                const isSuccess = AgentApi.InsertAgent(inputValues);
                if (isSuccess) {
                    setLoading(false);
                    OpenAlertBox();
                } else {
                    alert("Error inserting data");
                }
            }
            setInputValues({
                name: "",
                printName: "",
                address: '',
                mobileNo: '',
                state: '',
                telephoneNo: '',
                email: '',
                brokerageMode: 'Percentage',
                brokerage: '',
                freezeBrokerage: 'Yes',
                isConsultant: 'Yes',
                isActive: true,
            });
        }
    }

    const [stateInfo, setStateInfo] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await StateInfoApi.GetAllState();
                setStateInfo(data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            if (props.editData) {
                try {
                    const data = await props.editData; // Assuming props.editData is a Promise
                    console.log(data);
                    setInputValues(data);
                } catch (error) {
                    console.log(error);
                }
            }
        };
        fetchData();
    }, [props.editData]);

    const [settingInputValues, setSettingInputValues] = useState({
        addressDetails: false,

    });

    const handleSettingChangeAgent = (event) => {
        const { name, checked } = event.target;
        console.log(`Changing ${name} to ${checked}`);
        const updatedValues = {
            ...settingInputValues,
            [name]: checked,
        };

        setSettingInputValues(updatedValues);

        AgentApi.insertCreateAgentMasterSetting(updatedValues);

        switch (name) {
            case "addressDetails":
                handleToggleChangam1();
                break;
            default:
                break;
        }
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await AgentApi.getGetAgentMasterSettings();
                console.log(data);
                setSettingInputValues(data);
                setInputVisiblam1(data.addressDetails)
            } catch (error) {
                console.log(error);
            }
        }
        console.log(settingInputValues);
        fetchData();
    }, []);


    return (
        <div>
            < PageTabs locationTab={"/AgentMaster"} modelOpen={props.modelOpen} setSliderCallBack={props.setSliderCallBack} showModal={ModalToggle} />
            <div className="panel-heading">
                <div className="panel-head-title">
                    <h2 className="panel-title">Agent/Booked By Master</h2>
                </div >
                {/* **********************{Save Button}*********************** */}
                < MainButton showModal={ModalToggle} isBtnSetting={true} SaveButton={SaveBtn} modelOpen={props.modelOpen} setSliderCallBack={props.setSliderCallBack} />
            </div>
            <div>
                {isModalOpen && (
                    <div onClick={ModalToggle} className="modal" >
                        <div onClick={handleModalClick} className="modal-content">
                            <div className="modal-title">
                                <h3 className="modal-h">Agent Master</h3>
                                <span className="close" onClick={ModalToggle}>
                                    &times;
                                </span>
                            </div>
                            <div className="modal-content-im">
                                <div className="">
                                    <div className="input-box-m1 modal-1">
                                        <div className="cl-toggle-switch">
                                            <label className="cl-switch">
                                                <input type="checkbox" value={settingInputValues.addressDetails} name="addressDetails"
                                                    onChange={handleSettingChangeAgent} checked={settingInputValues.addressDetails} />
                                                <span></span>
                                            </label>
                                            <label className="ModalLabeltext2">Address Details<i className="fa-solid fa-circle-info ml-10"></i></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div>
                < DateTime />
            </div>
            <div className="content_box">
                <div className="company-info-top-im">
                    <div className="tab-head">
                        <div id="tabs">
                            <div className="tab-content-box">
                                <div className="tab-content-1">
                                    <div className="company-info-top-left" >
                                        <div className="company_info-text-im td-1">General Details
                                            <div className="input-box-checkbox1 ">
                                                <div className=" td-2">
                                                    <div className="cl-toggle-switch td-3">
                                                        <label className="cl-switch">
                                                            <input type="checkbox" onChange={handleInputChangeActive} name="isActive" checked={inputValues.isActive} value={inputValues.isActive} />
                                                            <span></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="input-box-Im input-height2">
                                                <div className="input-box-m1">
                                                    <input className="modify_im_input mode_category"
                                                        onChange={handleInputChange}
                                                        ref={input1Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input2Ref)}
                                                        value={inputValues.name}
                                                        name="name"
                                                        type="text" required />
                                                    <label className="modify_p_text2">Name&nbsp;
                                                        <span className="StarClr">*
                                                            {errorMessages.name && <label className="errorMessage">{errorMessages.name}</label>}
                                                        </span>
                                                    </label>
                                                </div>
                                                {(() => {
                                                    var val = isInputVisiblam1 ? input3Ref : input8Ref;
                                                    return (
                                                        <div className="input-box-m1 ">
                                                            <input className="modify_im_input mode_limit mode_category"
                                                                onChange={(e) => handleInputChange(e)} ref={input2Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, val)}
                                                                value={inputValues.printName}
                                                                name="printName" type="text" required />
                                                            <label className="modify_lbl_text2">Print Name

                                                            </label>
                                                        </div>
                                                    )
                                                })()}

                                            </div>
                                            {isInputVisiblam1 && (
                                                <div>
                                                    <div className="company_info-text-im">Address Details
                                                    </div>
                                                    <div className="input-box-tab-head">
                                                        <div className="input-box-m2">
                                                            <textarea className="modify_im_textarea mode_category2"
                                                                onChange={handleInputChange}
                                                                ref={input3Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, input4Ref)}
                                                                value={inputValues.address}
                                                                name="address"
                                                                type="text" placeholder="" required />
                                                            <label className="modify_p_text-fix">Address</label>
                                                        </div>
                                                    </div>
                                                    <div className="input-box-Im input-height2">
                                                        <div className="input-box-m1">
                                                            <input className="modify_im_input mode_category" type="text" required
                                                                value={inputValues.mobileNo} name="mobileNo" ref={input4Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, input5Ref)} onChange={handleInputChange} />
                                                            <label className="modify_p_text2">Mobile No.</label>
                                                        </div>
                                                        <div className="input-box-m1">
                                                            <select className="modify_im_input mode_limit mode_category" ref={input5Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, input6Ref)} onChange={(e) => handleInputChange(e)} type="text" required value={inputValues.state} name="state">
                                                                <option Classname="form-control s-1" value="">Select state</option>
                                                                {(() => {
                                                                    if (stateInfo !== null && Array.isArray(stateInfo)) {  // && Array.isArray(stateInfo)
                                                                        return stateInfo.map((data) => (
                                                                            // console.log(data.stateName),
                                                                            <option key={data.id} value={data.id}>
                                                                                {data.stateName}
                                                                            </option>
                                                                        ))
                                                                    }
                                                                })()}
                                                            </select>
                                                            <label className="modify_lbl_text4">State</label>
                                                        </div>
                                                    </div>

                                                    <div className="input-box-Im input-height2">
                                                        <div className="input-box-m1">
                                                            <input className="modify_im_input mode_category" type="text" required ref={input6Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, input7Ref)}
                                                                value={inputValues.telephoneNo} name="telephoneNo" onChange={handleInputChange} />
                                                            <label className="modify_p_text2">Tel No.</label>
                                                        </div>
                                                        <div className="input-box-m1">
                                                            <input className="modify_im_input mode_limit mode_category" ref={input7Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, input8Ref)} type="text" required
                                                                value={inputValues.email} name="email" onChange={handleInputChange} />
                                                            <label className="modify_lbl_text2">E-Mail</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                    <div className="company-info-top-right" style={{ width: '50%' }} >
                                        <div className="company_info-text-im">Brokerage Details</div>
                                        <div className="stock-im-1">
                                            <div className="input-box-Im input-height2">
                                                <div className="input-box-m1">
                                                    <select className="modify_im_input mode_category" ref={input8Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input9Ref)} onChange={handleInputChange} type="text" required value={inputValues.brokerageMode} name="brokerageMode">
                                                        {/* <option value="" disabled selected>Select</option> */}
                                                        <option value="Percentage">Percentage</option>
                                                        <option value="AbsoluteAmt">Absolute Amount</option>
                                                    </select>
                                                    <label className="modify_lbl_text3">Brokerage Mode
                                                        {/* <span className="StarClr">*
                                                            {errorMessages.brokerageMode && <label className="errorMessage">{errorMessages.brokerageMode}</label>}
                                                        </span> */}
                                                    </label>
                                                </div>
                                                <div className="input-box-m1">
                                                    <input className="modify_im_input mode_category" ref={input9Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input10Ref)} onChange={handleInputChange} type="number" required value={inputValues.brokerage} name="brokerage" />
                                                    <label className="modify_lbl_text2">Brokerage
                                                        <span className="StarClr">*
                                                            {errorMessages.brokerage && <label className="errorMessage">{errorMessages.brokerage}</label>}
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="input-box-Im input-height2">

                                                <div className="input-box-m1">
                                                    <select className="modify_im_input mode_category" ref={input10Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input11Ref)} type="text" onChange={handleInputChange} required value={inputValues.freezeBrokerage} name="freezeBrokerage">
                                                        <option>Yes</option>
                                                        <option>No</option>
                                                    </select>
                                                    <label className="modify_lbl_text3">Freeze Brokerage</label>
                                                </div>

                                                <div className="input-box-m1">
                                                    <select className="modify_im_input mode_category" ref={input11Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input1Ref)} type="text" onChange={handleInputChange} required value={inputValues.isConsultant} name="isConsultant">
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">No</option>
                                                    </select>
                                                    <label className="modify_lbl_text4">Is Consultant</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="buttom-box">
                        <div className="button-btn-1">
                            <button className="btn-1">Save</button>
                        </div>
                        <div className="button-btn-2">
                            <button className="btn-2">Reset</button>
                        </div>
                        <div className="button-btn-3">
                            <button className="btn-3" onClick={ModalToggle}><i className="fa-solid fa-gear "></i></button>
                        </div>
                    </div>
                </div>
            </div>
            {alertBox && (
                <AlertBox
                    HeadingTop="Saved"
                    Heading="Your Agent is Saved"
                    onYesClick={OpenAlertBox}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBox}
                />
            )}
            {alertBoxEdit && (
                <AlertBox
                    HeadingTop="Updated"
                    Heading="Your Agent is Updated"
                    onYesClick={OpenAlertBoxEdit}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBoxEdit}
                />
            )}
            {loading && <Loader />}
        </div >
    );
}


export default TableMaster;
